import React from "react";
import moment from "moment";
import axios from "axios";
import "./template.css";

function KurdishTemplateJ(props) {
  const [prePaymentText, setPrePaymentText] = React.useState(0);
  const [keyRecieveText, setKeyRecieveText] = React.useState(0);
  const [unitPrice, setUnitPrice] = React.useState(0);
  const [pageNumberText, setPageNumberText] = React.useState(0);

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
      .get(
        `${process.env.REACT_APP_URL}/Dashboard/NumberToTextKu?number=${props.data.prePaymentPrice}`
      )
      .then((resp) => {
        setPrePaymentText(resp.data);
      })
      .catch((error) => {
        // console.log(error)
      });
    axios
      .get(
        `${process.env.REACT_APP_URL}/Dashboard/NumberToTextKu?number=${props.data.keyRecevePrice}`
      )
      .then((resp) => {
        setKeyRecieveText(resp.data);
      })
      .catch((error) => {
        // console.log(error)
      });
    axios
      .get(
        `${process.env.REACT_APP_URL}/Dashboard/NumberToTextKu?number=${props.data.buyingPrice}`
      )
      .then((resp) => {
        setUnitPrice(resp.data);
      })
      .catch((error) => {
        // console.log(error)
      });
    axios
      .get(
        `${process.env.REACT_APP_URL}/Dashboard/NumberToTextKu?number=${props.pageNumber}&suffix=false`
      )
      .then((resp) => {
        setPageNumberText(resp.data);
      })
      .catch((error) => {
        // console.log(error)
      });
  }, [props]);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });

  function numToOrderString(num) {
    switch (num) {
      case 1:
        return "یەکەم";
      case 2:
        return "دووەم";
      case 3:
        return "سێیەم";
      case 4:
        return "چوارەم";
      case 5:
        return "پێنجەم";
      case 6:
        return "شەشەم";
      case 7:
        return "حەوتەم";
      case 8:
        return "هەشتەم";
      case 9:
        return "نۆیەم";
      case 10:
        return "دەیەم";
      case 11:
        return "یازدە";
      case 12:
        return "دوازده";
      case 13:
        return "سێزدە";
      case 14:
        return "چواردە";
      case 15:
        return "پازدە";
      case 16:
        return "شازدە";
      case 17:
        return "حەڤدە";
      case 18:
        return "هەژدە";
      case 19:
        return "نۆزدە";
      case 20:
        return "بیست";
      case 20:
        return "بیست";
      case 21:
        return "بیست و یەك";
      case 22:
        return "بست و دوو";
      case 23:
        return "بیست و سێ";
      case 24:
        return "بیست و چوار";
      case 25:
        return "بیست و پێنج";
      case 26:
        return "بیست و شەش";
      case 27:
        return "بیست و حەوت";
      case 28:
        return "بیست و هەشت";
      case 29:
        return "بیست و نۆ";
      case 30:
        return "سی";
      case 31:
        return "سی و یەك";
      case 32:
        return "سی و دوو";
      case 33:
        return "سی و سێ";
      case 34:
        return "سی و چوار";
      case 35:
        return "سی و پێنج";
      case 36:
        return "سی و شەش";
      case 37:
        return "سی و حەوت";
      case 38:
        return "سی و هەشت";
      case 39:
        return "سی و نۆ";
      case 40:
        return "چل";
      case 41:
        return "چل و یەك";
      case 42:
        return "چل و دوو";
      case 43:
        return "چل و سێ";
      case 44:
        return "چل و چوار";
      case 45:
        return "چل و پێنج";
      case 46:
        return "چل وشەش";
      case 47:
        return "چل و حەوت";
      case 48:
        return "چل و هەشت";
      case 49:
        return "چل و نۆ";
      case 50:
        return "پەنجا";
      case 51:
        return "پەنجاو یەك";
      case 52:
        return "پەنجاو دوو";
      case 53:
        return "پەنجاو سێ";
      case 54:
        return "پەنجاو چوار";
      case 55:
        return "پەنجاو پێنج";
      case 56:
        return "پەنجاو شەش";
      case 57:
        return "پەنجاو حەوت";
      case 58:
        return "پەنجاو هەشت";
      case 59:
        return "پەنجاو نۆ";
      case 60:
        return "شەست";
      default:
        return "شەست +";
    }
  }
  var bulletclasses = [
    "ا",
    "ب",
    "پ",
    "ت",
    "ث",
    "ج",
    "چ",
    "ح",
    "خ",
    "د",
    "ذ",
    "ر",
    "ز",
    "ژ",
    "ص",
    "ض",
    "ط",
    "ظ",
    "ع",
    "غ",
    "ف",
    "ق",
    "ک",
    "گ",
    "ل",
    "م",
    "ن",
    "و",
    "ه",
    "ی",
  ];
  return (
    <div
      className="contractWrapper"
      style={{
        textAlign: "right",
      }}
    >
      <h4 style={{ textAlign: "center" }} class="p1" dir="rtl">
        گرێبەستى كڕین و فرۆشتنى یەكەى نیشتەجێ
      </h4>
      <b>
        <p style={{ lineHeight: 2 }} class="p2" dir="rtl">
          <span class="s2">ژمارەى</span> <span class="s2">گرێبەست</span> :
          {" " + props.data.contractNumber}
          <span class="Apple-converted-space">
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp;{" "}
          </span>
          <span class="s2">بەروار</span> :{" "}
          <span class="Apple-converted-space">
            &nbsp;{moment(props.data.buyingDate).format("YYYY/MM/DD")}
          </span>
        </p>
        <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
          یەكەم<span class="s1"> / </span>لایەنەكان
        </p>
      </b>
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        لایەنى یەكەم<span class="s1"> ( </span>فرۆشیار
        <span class="s1"> ) : </span>كۆمپانیاى شجرە الزیتون بۆ بازرگانى گشتى و
        وەبەرهێنانى خانووبەرە و پیشەسازى
      </p>
      <p style={{ lineHeight: 2 }} class="p2" dir="rtl">
        <span class="s2">بەشى</span> <span class="s2">فرۆشتن</span> :
        <span class="Apple-converted-space">&nbsp; </span>
        <span class="s2">ژمارەى</span> <span class="s2">مۆبایل</span> :{" "}
        <span class="Apple-converted-space">&nbsp; </span>07503005003 -
        07503005004
      </p>
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        لایەنى دووەم<span class="s1"> ( </span>كڕیار
        <span class="s1"> ) : </span>بەڕێز
        <span class="s1">
          <span class="Apple-converted-space">
            &nbsp;:{" "}
            {props.data.customer &&
              props.data.customer.firstName +
                " " +
                props.data.customer.middleName +
                " " +
                props.data.customer.lastName}{" "}
          </span>
        </span>
      </p>
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        ناونیشان
        <span class="s1">
          {" "}
          : {props.data.customer && props.data.customer.address}
        </span>
      </p>
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        ژمارەى مۆبایل
        <span class="s1">
          :{" "}
          {props.data.customer &&
            `${props.data.customer.phone} - ${
              props.data.customer.phone2 && props.data.customer.phone2
            }`}
        </span>
      </p>
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        بەڵگەنامەكان
        <span class="s1"> :</span>
      </p>
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        ژمارەى ناسنامەى بارى شارستانى / کارتی نیشتمانی
        <span class="s1">
          {" "}
          : {props.data.customer && props.data.customer.idcard}
        </span>
      </p>
      <p style={{ clineHeight: 2 }} class="p1" dir="rtl">
        ژمارەى ڕەگەزنامەى عیراقى
        <span class="s1">
          {" "}
          :{props.data.customer && props.data.customer.jnsia}
        </span>
      </p>
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        ژمارەى كارتى زانیارى
        <span class="s1">
          {" "}
          : {props.data.customer && props.data.customer.informationCard}
        </span>
      </p>
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        ژمارەى پسوولەى خۆراك
        <span class="s1">
          {" "}
          : {props.data.customer && props.data.customer.foodCard}
        </span>
      </p>
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        ژمارەى كارتى نشینگە<span class="s1"> ( </span>اقامە
        <span class="s1">
          {" "}
          ) : {props.data.customer && props.data.customer.placementCard}
        </span>
      </p>
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        پشتگیرى ئاسایش<span class="s1"> ( </span>تأیید اسایش
        <span class="s1">
          {" "}
          ) : {props.data.customer && props.data.customer.securitySupport}
        </span>
      </p>
      <p style={{ lineHeight: 2, marginBottom: 116 }} class="p1" dir="rtl">
        <span class="s1">( 4 ) </span>چوار وێنەى ڕەنگاوڕەنگ
        <span class="s1"> .</span>
      </p>
      <b>
        <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
          دووەم<span class="s1"> / </span>زانیارییەكانى پڕۆژە
          <span class="s1"> :</span>
        </p>
      </b>
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        <span class="s1"></span>١- ناوی پڕۆژە<span class="s1"> : ( </span>
        پڕۆژەى زەیتون پڵەس سیتى<span class="s1"> ) .</span>
      </p>
      <p style={{ lineHeight: 2 }} class="p2" dir="rtl">
        <span class="s2">٢-ژمارەی مۆڵەتی وەبەرهێنان</span> : ( 221 ){" "}
        <span class="s2">لە</span> ( 29/12/2009 ) .
      </p>
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        <span class="s1">٣- </span>شوێنى پڕۆژە<span class="s1"> : </span>
        پارێزگاى هەولێر<span class="s1"> / </span>ناحیەى دارەتوو
        <span class="s1">
          {" "}
          .{" "}
          <span class="Apple-converted-space">&nbsp; &nbsp; &nbsp; &nbsp;</span>
        </span>
      </p>
      <b>
        <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
          سێ یەم<span class="s1"> / </span>بابەتى گرێبەست
          <span class="s1"> : </span>
        </p>
      </b>
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        كۆمپانیاى<span class="s1"> (</span> شجرة الزيتون بۆ بازرگانى گشتى و
        وەبەرهێنانى خانووبەرە و پێشەسازى<span class="s1">) </span>بە پێى مۆڵەتى
        وەبەرهێنان بە ژمارە<span class="s1"> (221) </span>لە بەروارى
        <span class="s1"> (29/12/2009) </span>كە دەرچووە لە دەستەى وەبەرهێنانى
        حكومەتى هەرێمى كوردستان هەروەها پاڵپشت بە بڕیارى ئەنجوومەنى بالاى
        وەبەرهێنان ژمارە<span class="s1"> (499) </span>لە بەروارى
        <span class="s1"> (23/4/2019) </span>، هەڵدەستێت بە دروست كردنى یەكەى
        نیشتەجێ ى لە شوێنى تەرخان كراو بۆ پڕۆژەكە لە پارێزگاى هەولێر
        <span class="s1"> / </span>ناحیەى دارەتوو ، وە فرۆشتنى بە هاووڵاتیان وە
        لە سەر ئارەزووى لایەنى دووەم وە ڕازى بوونى دواى تەماشا كردنى و تێڕوانینى
        نەخشەى یەكەكە وە هەروەها نەخشەى شوێنى پڕۆژەكە
        <span class="s1">
          {" "}
          ( SITE PLAN ) وپارچە زەوییەكە وە نرخى یەكەكە و چۆنیەتى دانى تێچووى وە
          ڕازى بوونى لایەنى یەكەم بەم فرۆشتنە،
          <br />
        </span>
        هەردوو لایەنى فەرمى گرێبەستەكە ڕێكەوتن لە سەر پابەند بوونى یاسایى بە
        ناوەڕۆكى گرێبەستەكە بەم شێوەیەى خوارەوە<span class="s1"> :</span>
      </p>
      <p class="p1" dir="rtl" style={{ lineHeight: 2 }}>
        <span class="s1">1) </span>لایەنى یەكەم هەڵسا بە فرۆشتنى یەكەى نیشتەجێ ى
        ژمارە
        <span class="s1">
          {" "}
          ({props.data.property && props.data.property.name}){" "}
        </span>
        لە زۆنى
        <span class="s1">
          {" "}
          ({props.data.property && props.data.property.zone}){" "}
        </span>
        لە جادەى
        <span class="s1">
          {" "}
          ({props.data.property && props.data.property.streetNumber}){" "}
        </span>
        لەبلوكی
        <span class="s1">
          {" "}
          ({props.data.property && props.data.property.block})
        </span>
        بە لایەنى دووەم كە دروست كراوە لە پڕۆژەى
        <span class="s1"> ( </span>شارى زەیتون پڵەس سیتى
        <span class="s1"> ) </span>كە دەكەوێتە سەر پارچەیەك لە پارچە زەوییەكانى
        ناو پڕۆژەى شارى زەیتون بە ڕووبەرى
        <span class="s1">
          {" "}
          ({props.data.property && props.data.property.area}){" "}
        </span>
        دووجا وە پێكهاتووە لە دوو نهۆم هەروەكو لە نەخشەى بیناى هاوپێچدا هاتووە،
        وە ژمارەى موڵكەكە دەچەسپێ بەشێوەى كۆتایى لە دواى تەواو كردنى كردارى
        جیاكردنەوە و یەك بوون و تۆماركردنى لە فەرمانگەى تۆمارگاى خانووبەرەى
        تایبەتمەند بە شێوەى فەرمى
      </p>
      <b>
        <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
          پێكهاتەكانى خانوى نیشتەجێبون<span class="s1">:-</span>
        </p>
      </b>
      <p style={{ lineHeight: 2 }} class="p2" dir="rtl">
        <span class="s2">ا</span>- RAFT FOUNDATION
      </p>
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        ب<span class="s1">- </span>پەنجەرەكان لە ئەلەمنیوم دروستكراوە
        <span class="s1">.</span>
      </p>
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        ج<span class="s1">- </span>سەقف<span class="s1"> (</span>جبسن بورد
        <span class="s1">).</span>
      </p>
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        د<span class="s1">- </span>پێشەوەی خانوو<span class="s1"> (</span>واجهە
        فوكا<span class="s1">).</span>
      </p>
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        ه<span class="s1">- </span>كاشى زەوى جۆری پورسەلین
        <span class="s1">.</span>
      </p>
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        و<span class="s1">- </span>دەرگاكان دروستكراوى توركى یە
        <span class="s1">.</span>
      </p>
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        <span class="s1">2) </span>كریار دەبێت خەرجى خزمەتگوزارى بدات وە بە
        پێچەوانە كومپانیا دەتوانێت خزمەتگوزارى رابگرێت<span class="s1">.</span>
      </p>
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        <span class="s1">3) </span>نابێت كریار گورانكارى لە دیوى دەرەوەى
        خانوەكەدا بكات كەبێتە هوى تێكدانى جوانى پروژەكە بەتایبەتى لاى پێشەوە
        <span class="s1">.</span>
      </p>
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        <span class="s1">4) </span>نرخى یەكەكە
        <span class="s1">
          {" "}
          (
          {props.data &&
            `${formatter.format(props.data.buyingPrice)} ${unitPrice}`}
          ){" "}
        </span>
        وە بەم شێوەیەى خوارەوە لایەنى دووەم پارەكە دەداتە لایەنى یەكەم
        <span class="s1">:-</span>
      </p>
      <b>
        <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
          چوارەم<span class="s1"> / </span>مەرجەكانى پارەدان
          <span class="s1"> :</span>
        </p>
      </b>
      <ul class="ul1" style={{ listStyleType: "none" }}>
        <li class="p1" dir="rtl">
          بڕى
          <span class="s1">
            {" "}
            ({formatter.format(props.data.prePaymentPrice)})
          </span>{" "}
          {prePaymentText} لە گەڵ واژوو كردنى گرێبەستى كڕین و فرۆشتن لە نێَوان
          هەردوولا دا واتە لایەنى دووەم دەداتە لایەنى یەكەم لە بەروارى
          <span class="s1">
            {moment(props.data.prePaymentDate).format("YYYY/MM/DD")}.
          </span>
        </li>

        <li className="p1" dir="rtl">
          <br />
          لەکاتی تەواوبوونی هەیکەلی خانووی ژمارە (
          {props.data.property && props.data.property.name}) واتە دوای
          تەواوبوونی کاری کونکریت بۆ سەربانی یەکەم و سەربانی دووەم ، ئەوا
          پێویستە لەسەر لایەنی دووەم بڕی گوژمەی (
          {props.isItPayment &&
            formatter.format(props.isItPayment.currentPayPrice)}
          ) {props.isItPayment && props.isItPayment.kuPriceText} بداتە لایەنی
          یەکەم (کۆمپانیا)
        </li>

        {props.data.paymentProcess &&
          props.data.paymentProcess
            .filter((el) => el.isItKeyPayment === false)
            .map((el, key) => {
              return (
                <li class="p1" dir="rtl">
                  <br /> {bulletclasses[key]}) قستى {numToOrderString(key + 1)}{" "}
                  {props.removeAfterMonth !== true &&
                    `
                  دواى 
                  ${Math.ceil(
                    moment(el.currentPayDate).diff(
                      moment(props.data.prePaymentDate),
                      "months",
                      true
                    )
                  )}
                  مانگ لە بەروارى واژووكردنى گرێبەست 
                  `}
                  كە دەكاتە بڕى
                  <span class="s1">
                    {" "}
                    ({formatter.format(el.currentPayPrice)}){" "}
                  </span>
                  {el.kuPriceText} لە بەروارى
                  <span class="s1">
                    {" "}
                    {moment(el.currentPayDate).format("YYYY-MM-DD")}
                  </span>
                  <br />
                </li>
              );
            })}
      </ul>

      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        لەكاتى تەواوبونى یەكەى نیشتەجێ بوون بەماوەیەكى كەمتر ئەوا دەبێت لایەنى
        دووەم لەگەل وەرگرتنى كلیلى خانوو برى پارەى پاشەكى بە گوژمەی
        <span class="s1">
          {" "}
          ({formatter.format(props.data.keyRecevePrice)}){" "}
        </span>
        {keyRecieveText}
        <span class="s1"> </span>بداتە لایەنى یەكەم
        <span class="s1"> (</span>كومپانیا<span class="s1">), </span>ئینجا
        پێویستە لەسەر لایەنى دووەم پابەند بێت بەپێدانى گشت قستەكان بە لایەنى
        یەكەم كە لە برگەى چوارەم دا روون كراوەتەوە<span class="s1"> , </span>
        واتە نابێت هیچ پارەیەك لە ئەستوى لایەنى دووەم بمێنێت
        <span class="s1">.</span>
      </p>
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        <span class="s1">5) </span>ئەم گرێبەستە بەڵگەى دەستەبەرى
        <span class="s1"> ( </span>ضمان<span class="s1"> )</span>ە بۆ لایەنى
        دووەم كە ماف و خاوەندارێتى پێ دەبەخشێ<span class="s1"> .</span>
      </p>
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        <span class="s1">6) </span>ڕادەست كردنى یەكەى نیشتەجێ لە دواى
        <span class="s1"> (36) </span>مانگ<span class="s1"> (</span>روژى كاركردن
        <span class="s1">) </span>دەبێت لە بەروارى
        <span class="s1"> 1/1/2020.</span>
      </p>
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        <span class="s1">7) </span>لە كاتى دوا كەوتنى لایەنى دووەم لە دانى قستى
        داوا لێكراو بە گوێرەى خشتەى ئەم گرێبەستە وە دواى ئاگادار كردنەوەى لە
        لایەن كۆمپانیاوە ، پێویستە لە سەر لایەنى دووەم بۆ هەر ڕۆژێك دوا كەوتن
        ئەوا ڕۆژانە بڕى<span class="s1"> (100$) </span>سەد دۆلارى ئەمریكى بدات
        بە لایەنى یەكەم ، ئەمەش بەردەوام دەبێت بۆ ماوەى یەك مانگ وە لە دواى ئەم
        ماوەیە لایەنى یەكەم بۆى هەیە بە ئاگادار كردنەوەى بەڕێوەبەرایەتى گشتى
        وەبەرهێنان<span class="s1"> / </span>هەولێر ، وە هەروەها ئاگادار
        كردنەوەى لایەنى دووەم ، ئەوا لایەنى یەكەم تاك لایەنە گرێبەستەكە
        هەڵبوەشێنێتەوە و یەكەكە بفرۆشێتە كەسێكى تر ئەمەش ڕەزامەندى هەردوو لایەنى
        فەرمى ئەم گرێبەستەى لە سەرە<span class="s1"> .</span>
      </p>
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        <span class="s1">8) </span>ئەگەر لایەنى دووەم داواى هەڵوەشاندنەوەى
        گرێبەستەكە بكات بە هەر هۆكارێك بێت ئەوا پێویستە لە سەر لایەنى دووەم
        ڕێژەى<span class="s1"> (30 %) </span>سى لە سەد لە نرخى یەكەكە وەك سزاى
        بژاردن<span class="s1"> (</span>غرامە<span class="s1">) </span>بدات بە
        لایەنى یەكەم بە مەبەستى قەرەبووى خەرجییەكانى كارگێرى و خزمەتگوزارى
        پەیوەست بە ڕێكارەكانى گرێبەست ، وە پاشماوەى پارەكەى بۆ دەگەڕێندرێتەوە لە
        دواى فرۆشتنى یەكەكە بە كەسێكى تر<span class="s1">.</span>
      </p>
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        <span class="s1">9) </span>لایەنى دووەم لە كاتى نەدانى پشكى شایستەى سەر
        شانى بە لایەنى یەكەم<span class="s1"> ( </span>كۆمپانیا
        <span class="s1"> ) </span>لە ماوەى یەك مانگدا لە بەروارى شایستە بوون ،
        ئەمە بە ڕاوەستانى كۆتایى هەژمار دەكرێت وە ئەم گرێبەستە بە هەڵوەشاوە
        هەژمار دەكرێت وە هەردوو لایەنى ئەم گرێبەستە ڕازین بەم هەڵوەشاندنەوەیە ،
        لەم حاڵەتەدا لایەنى دووەم بژاردەى دارایى بە ڕێژەى
        <span class="s1"> (30%) </span>سى لە سەد لە نرخى یەكەكە لە ئەستۆ دەگرێت
        وەك قەرەبوو لە خەرجییەكانى كارگێرى و ئەو زەرەرانەى كە بە لایەنى یەكەم
        دەكەوێت ، وە پاشماوەى پارەكە بۆ<span class="s1"> ( </span>لایەنى دووەم
        <span class="s1"> )</span>دەگەڕێتەوە لە دواى فرۆشتنى یەكەكە بە كڕیارى
        تازە ، وە لایەنى یەكەم<span class="s1"> ( </span>كۆمپانیا
        <span class="s1"> ) </span>بۆى هەیە ئەم ڕێكارانە ئەنجام بدات
        <span class="s1"> .</span>
      </p>
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        <span class="s1">10) </span>لە حاڵەتى دوا كەوتنى لایەنى یەكەم
        <span class="s1"> ( </span>كۆمپانیا<span class="s1"> ) </span>لە ڕادەست
        كردنى یەكەى نیشتەجێ بە لایەنى دووەم<span class="s1"> (</span>كڕیار
        <span class="s1">) </span>لە ماوەى دیارى كراوى خۆیدا هەر وەكو لە خاڵى
        <span class="s1"> ( 6 ) </span>شەشەمی ئەم گرێبەستەدا هاتووە ، وە
        كەمتەرخەمى لە لایەن لایەنى یەكەم بوو بەڵام بەدەر لە بارودوخى نالەبار
        <span class="s1"> ( </span>الضروف القاهرە<span class="s1"> ) </span>ئەوا
        لایەنى یەكەم پابەندە بە دانى بڕى<span class="s1"> (100$) </span>سەد
        دۆلارى ئەمریكى ڕۆژانە بە لایەنى دووەم<span class="s1"> ( </span>كڕیار
        <span class="s1"> ) </span>تا دەگاتە ڕێژەى<span class="s1"> (5%) </span>
        پێنج لە سەدى نرخى یەكەكە وە ئەم پێ بژاردنە<span class="s1"> (</span>
        غرامە<span class="s1">) </span>یە لە گەڵ دانى قستى دوایى و كۆتایى یەكلا
        دەكرێتەوە<span class="s1">.</span>
      </p>
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        <span class="s1">11)</span>لایەنى یەكەم یەكە نیشتەجێ یەكە ڕادەستى لایەنى
        دووەم<span class="s1"> ( </span>كڕیار<span class="s1"> ) </span>دەكات بە
        پێ ى بەندەكانى ئەم گرێبەستە وە لایەنى دووەم پابەندە بە وەرگرتنى یەكەكە
        وە واژووى لە سەر فۆرمى وەرگرتنى یەكەكە بكات پێش وادەى تەواو بوونى ماوەى
        ئەم گرێبەستە<span class="s1"> , </span>لە گەڵ ڕەچاو كردنى نیشتەجێ بوون
        بە پێ ى ڕێنماییەكانى بەڕێوەبەرایەتى گشتى وەبەرهێنانى هەولێر
        <span class="s1"> .</span>
      </p>
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        <span class="s1">12) </span>لایەنى دووەم گشت خەرجییەكان و ڕسومات و
        كرێیەكان و ماندووێتى پەیوەست بە فەرمانگەى تۆمار كردنى خانووبەرە
        تایبەتمەندەكان و دام و دەزگا پەیوەندیدارەكان لە ئەستۆ دەگرێت لە
        چوارچێوەى ئەم گرێبەستە<span class="s1">.</span>
      </p>
      {/* <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        <span class="s1">13) </span>لایەنى دووەم بۆى نیە
        <span class="s1"> (</span>تنازول<span class="s1">) </span>لەم گرێبەستە
        بكات بۆ كەسێكى تر یاخود لایەنێكى تر بە بێ ئاگادارى و وەرگرتنى ڕەزامەندى
        پێشوەختەى لایەنى یەكەم<span class="s1"> (</span>كۆمپانیا
        <span class="s1">) </span>بە شێوەى نووسین ، وە پێویستە لە سەر لایەنى
        دووەم نرخى یەكەكەى بە تەواوى دابێت بە لایەنى یەكەم
        <span class="s1"> (</span>كۆمپانیا<span class="s1">).</span>
      </p> */}
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        <span class="s1">13) </span>لایەنى دووەم بۆى نیە
        <span class="s1"> (</span>تنازول<span class="s1">) </span>لەم گرێبەستە
        بكات بۆ كەسێكى تر یاخود لایەنێكى تر بە بێ ئاگادارى و وەرگرتنى ڕەزامەندى
        پێشوەختەى لایەنى یەكەم<span class="s1"> (</span>كۆمپانیا
        <span class="s1">) </span>بە شێوەى نووسین ،وە پێویستە لەسەر لایەنی دووەم
        تا وەکو بەرواری فرۆشتن یەکەکە گشت پابەندبونە داراییەکان بەرامبەر بە
        لایەنی یەکەم ( کۆمپانیا )جێبەجێ بکات وە لە ھەمان کاتدا پێویستە لەسەر
        لایەنی دووەم و کڕیاری نوێ پێکەوە سەردانی کۆمپانیا بکەن بە مەبەستی
        ئاگادار بوون لە ناوەڕۆکی گرێبەستەکە وە ئەنجامدانی گرێبەستی نوێ لە لایەن
        کۆمپانیا بۆ کڕیاری نوێ، بە پێچەوانەوە لایەنی دووەم لە ھەموو ڕوویەکەوە
        بەرپرسیارە.
      </p>

      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        <span class="s1">14) </span>تۆمار كردنى یەكەى نیشتەجێ بە ناوى لایەنى
        دووەم ئەنجام نادرێت ، تەنها دواى تەواو بوونى بیناى یەكەكە و دانى هەموو
        نرخى یەكەكە لە لایەن لایەنى دووەمەوە<span class="s1"> (</span>كڕیار
        <span class="s1">) </span>ەوە بە لایەنى یەكەم<span class="s1">.</span>
      </p>
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        <span class="s1">15) </span>ماوەى چاككردنەوە<span class="s1"> (</span>
        الصیانە<span class="s1">) </span>ى یەكەكە ساڵێك دەبێت لە دواى بەروارى
        تەواوبوونی یەكەكە لە لایەن لایەنى یەکەمەوە ، وە لایەنى یەكەم بەرپرسیارە
        لەو زەرەرەى بەر یەكەكە<span class="s1"> (</span>بیناكە
        <span class="s1">) </span>كەوتبێ لە ئەنجامى جێبەجێ كردنى، بەڵام هەر جۆرە
        زەرەرێك لە كاتى خراپ بە كارهێنانى یەكە نیشتەجێ یەكە لە لایەن لایەنى
        دووەم ئەوا خودى خۆى بەرپرسیار دەبێت و هیچ خەرجییەك ناكەوێتە ئەستۆى
        لایەنى یەكەم<span class="s1"> (</span>كۆمپانیا
        <span class="s1">) .</span>
      </p>
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        <span class="s1">16) </span>لە كاتى ڕوودانى هەر جۆرە ناكۆكییەك لە نێوان
        لایەنە فەرمییەكانى ئەم گرێبەستە ئەوا سەرەتا بە شێوەى دانوستاندن چارەسەر
        دەكرێت ، بەڵام ئەگەر هاتوو چارەسەر نەكرا ئەوا دادگاى بەرایى هەولێر
        لایەنى تایبەتمەند و یەكلاكەرەوە دەبێت بۆ یەكلا كردنەوەى كێشەى نێوان
        هەردوولایەنى فەرمى ئەم گرێبەستە<span class="s1">.</span>
      </p>
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        ئەم گرێبەستە لە ناو و ناونیشان و پێشەكى و<span class="s1"> (16) </span>
        شازدە خاڵ و<span class="s1"> ({props.pageNumber}) </span>(
        {pageNumberText}) لاپەڕە پێكدێت ، كەوا هەر
        <span class="s1"> ({props.pageNumber}) </span>({pageNumberText}) لاپەڕە
        بە ڕەسەن هەژمار دەكرێن و هەمان هێزى یاسایى یان هەیە لە ڕووى یاساییەوە ،
        هەروەها دواى خوێندنەوەى وورد و تێگەیشتنى هەردوو لایەنى فەرمى لە سەر كۆى
        ناوەڕۆكى ئەم گرێبەستە ، گرێبەستەكە لە شارى هەولێر لە بەروارى
        <span class="s6">
          {" "}
          ({moment(props.data.buyingDate).format("YYYY-MM-DD")})&nbsp;
        </span>
        واژوو كرا لە نێوان هەردوو لایەن<span class="s1">.</span>
      </p>
      <p style={{ lineHeight: 2 }} class="p3">
        &nbsp;
      </p>
      <div
        style={{ display: "flex", justifyContent: "space-around", height: 120 }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            justifyContent: "space-between",
          }}
        >
          <b>
            <span class="s2">لایەنى یەكەم</span>
          </b>
          <span class="s2">كۆمپانیاى شجرە الزیتون</span>{" "}
          <span class="s2">
            بۆ بازرگانى گشتى و وەبەرهێنانى خانووبەرە و پیشەسازى
          </span>
          <span class="s2">:واژوو</span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <b>
            <span class="s2">لایەنى دووەم</span>
          </b>
          <span class="s2">
            ناو:{" "}
            {props.data.customer &&
              `${props.data.customer.firstName} ${props.data.customer.middleName} ${props.data.customer.lastName}`}{" "}
          </span>{" "}
          <span class="s2">
            ناونیشان: {props.data.customer && props.data.customer.address}
          </span>
          <span class="s2">:واژوو</span>
        </div>
      </div>
      <p style={{ lineHeight: 2 }} class="p3">
        &nbsp;
      </p>
      <b>
        <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
          هاوپێچ<span class="s1"> :-</span>
        </p>
      </b>
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        <span class="s1">1 ) </span>نەخشەى پڕۆژە<span class="s1"> .</span>
      </p>
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        <span class="s1">2 ) </span>نەخشەى یەكەكە<span class="s1"> .</span>
      </p>
      <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
        <span class="s1">3 ) </span>بەڵگەنامەكان<span class="s1"> ( </span>
        ناسنامەى بارى شارستانى ، ڕەگەزنامەى عێراقى ، كارتى زانیارى ، پسوولەى
        خوراك ، پاسپۆرت ، كارتى نشینگە ، پشتگیرى ئاسایش ، پشتگیرى موختار
        <span class="s1"> ).</span>
      </p>
      <p style={{ lineHeight: 2 }} class="p3">
        &nbsp;
      </p>
      <div style={{ marginRight: "30%" }}>
        <p style={{ lineHeight: 2 }} class="p1" dir="rtl">
          بەڕێوەبەرایەتى گشتى وەبەرهێنان<span class="s1"> / </span>هەولێر
        </p>
        <p style={{ lineHeight: 2 }} class="p2" dir="rtl">
          ( <span class="s2">پەسەند</span> <span class="s2">كرا</span> ){" "}
          <span class="s2">لە</span> (
          <span class="Apple-converted-space">&nbsp; &nbsp; </span>/
          <span class="Apple-converted-space">&nbsp; &nbsp; </span>/{" "}
          <span class="Apple-converted-space">&nbsp; &nbsp; </span>2020 )
        </p>
      </div>
    </div>
  );
}

export default KurdishTemplateJ;
