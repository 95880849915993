import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  TopToolbar,
  sanitizeListRestProps,
  CreateButton,
  EditButton,
  ShowButton,
  EmailField,
  FunctionField,
  Create,
  SimpleForm,
  ReferenceInput,
  useNotify,
  SelectInput,
  DateInput,
  TextInput,
  Edit,
  CheckboxGroupInput,
  NumberField,
  FileField,
  FileInput,
  Show,
  SimpleShowLayout,
  DateField,
  NumberInput,
  required,
  TabbedForm,
  FormTab,
  ImageField,
  ImageInput,
  useListContext,
  Pagination,
  Filter,
} from "react-admin";
import axios from "axios";
import moment from "moment";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from "react-bootstrap/Button";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import "./invoice.css";
import ZaitonLogo from '../assets/zaiton-plus-logo.jpg'

const CustomerFilter = (props) => {
  return (
    <NoPrint>
      <Filter {...props}>
        <TextInput label="Expense Type" source="expenseType" alwaysOn />
        <TextInput label="Note" source="note" />
        <TextInput label="User" source="user" />
        <DateInput label="Start Date" source="startDate" />
        <DateInput label="End Date" source="endDate" />
      </Filter>
    </NoPrint>
  );
};
const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  return (
    <NoPrint>
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <Button
          onClick={() => {
            window.print();
          }}
        >
          Print Report
        </Button>
        {filters &&
          React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
          })}
        <CreateButton basePath={basePath} />
      </TopToolbar>
    </NoPrint>
  );
};
const PostPagination = (props) => (
  <NoPrint>
    <Pagination rowsPerPageOptions={[10, 25, 50, 100, 150]} {...props} />
    <Total props={props && props} />
  </NoPrint>
);

const Total = (props) => {
  let totals = props.props && props.props.data;
  let count = totals && Object.keys(totals).length;
  return null;
};

export const SourceList = (props) => {
  const role = localStorage.getItem("role");
  
  return (
          <List
            {...props}
            // pagination={<PostPagination />}
            // filters={<CustomerFilter />}
            // actions={<ListActions />}
          >
            <Datagrid isRowSelectable={ record => record.name !== 'Organic' }>
              <TextField source="name"/>
              <TextField source="note"/>
              {(role === 'Admin' || role === 'Accountant') && <FunctionField render={record => (record.name !== 'Organic') && <EditButton to={`/LeadSources/${record.id}`} />} />}
            </Datagrid>
          </List>
  );
};
export const SourceShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name"/>
      <TextField source="note"/>
    </SimpleShowLayout>
  </Show>
);

export const SourceCreate = (props) => {
  return (
    <Create {...props} >
      <SimpleForm redirect="list">
        <TextInput source="name" validate={[required()]}/>
        <TextInput source="note" multiline/>
      </SimpleForm>
    </Create>
  );
};

export const SourceEdit = (props) => (
  <Edit {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" validate={[required()]}/>
      <TextInput source="note" multiline/>
    </SimpleForm>
  </Edit>
);
