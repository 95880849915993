import * as React from "react";
import { fetchUtils, Admin, Resource, Title } from "react-admin";
import {
  ProperityList,
  ProperityCreate,
  ProperityEdit,
  PropertyShow,
} from "./components/Properities";
import {
  CustomerList,
  CustomerEdit,
  CustomerCreate,
  CustomerShow,
} from "./components/customers";
import {
  ExpenceList,
  ExpenceCreate,
  ExpenceShow,
  ExpenseEdit,
} from "./components/expences";
import {
  LeadCustomersList,
  LeadCustomersCreate,
  LeadCustomersEdit,
  LeadCustomersShow,
} from "./components/leadCustomers";
import {
  LeadVisitsList,
  LeadVisitsCreate,
  LeadVisitsEdit,
  LeadVisitsShow,
} from "./components/leadVisits";
import {
  SourceList,
  SourceCreate,
  SourceEdit,
  SourceShow,
} from "./components/sources";
import { TransactionList } from "./components/transactionInfo";
import { UpcomingPaymentList } from "./components/upcomingPayments";
import {
  ExpenseTypeList,
  ExpenseTypeCreate,
  ExpenseTypeShow,
} from "./components/expenseType";
import {
  CancelledContractList,
  CancelledContractShow,
} from "./components/cancelledContracts";
import {
  BuyContractList,
  BuyContractShow,
  BuyContractEdit,
} from "./components/buyContracts";
import {
  FinishedContractsList,
  FinishedContractsShow,
} from "./components/finishedContracts";
import {
  ReservedPropertiesList,
  ReservedPropertiesCreate,
} from "./components/reservedProperties";
import {
  UserNamesAndPassesList,
  UserNamesAndPassesEdit,
  ChangePassword
} from "./components/userName&Pass";
import { TransferedContractList, TransferedContractShow } from "./components/transferedContracts";
import { InvoiceList } from "./components/invoice";
import PostIcon from "@material-ui/icons/Book";
import UserIcon from "@material-ui/icons/Group";
import ContractIcon from "@material-ui/icons/Payment";
import InvoiceIcon from "@material-ui/icons/Receipt";
import AssessmentIcon from "@material-ui/icons/Assessment";
import MoneyRoundedIcon from "@material-ui/icons/MoneyRounded";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import LocalAtmRoundedIcon from "@material-ui/icons/LocalAtmRounded";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import FlipToFrontIcon from "@material-ui/icons/FlipToFront";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import NextWeekIcon from '@material-ui/icons/NextWeek';
import LinkIcon from '@material-ui/icons/Link';
import Dashboard from "./Dashboard";
import Reports from "./components/Reports";
import SecurityCheckPoint from "./components/securityCheckPoint";
import authProvider from "./authProvider";
import jsonServerProvider from "ra-data-json-server";
import ArabicMessages from "ra-language-arabic";
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import Layout from './layout/AppBar';

const messages = {
  kr: {
    ra: {
      action: {
        edit: "گۆڕین",
        create: "نوێ",
        add_filter: "فلتەر",
      },
      navigation: {
        page_rows_per_page: "ژمارەی ئەنجامەکان",
        page_range_info: "",
        next: "دواتر",
        prev: "پێشتر",
      },
    },
    resources: {
      customers: {
        name: "كڕیار |||| كڕیارەکان",
        fields: {
          firstName: "ناوی یەکەم",
          middleName: "ناوی دووەم",
          lastName: "ناوی سێیەم",
          nationality: "نەتەوە",
          email: "ئیمەیل",
          phone: "ژمارەی تەلەفۆن",
        },
      },
      Properties: {
        name: "خانووەکان",
        fields: {
          name: "ناو",
          block: "بلۆک",
          zone: "زۆن",
          initialPrice: "نرخ",
          streetNumber: "ژمارەی شەقام",
          area: "ڕووبەر",
          numberOfRooms: "ژمارەی ژوورەکان",
          floors: "ژمارەی نهۆمەکان",
          isSold: "فرۆشراوە",
        },
      },
    },
  },
  ar: ArabicMessages,
  en: englishMessages,
};
const i18nProvider = polyglotI18nProvider((locale) => messages[locale]);
const role = localStorage.getItem("role");
const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("token");

  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};
const dataProvider = jsonServerProvider(
  process.env.REACT_APP_URL + "",
  httpClient
);

const App = () => (
  <Admin
    dashboard={(role !== 'Security' && role !== 'PhotoGrapher' && role !== '6' && role !== 'Seller') && Dashboard}
    authProvider={authProvider}
    dataProvider={dataProvider}
    i18nProvider={i18nProvider}
    locale="kr"
    layout={Layout}
  >
    <Resource
      name="Properties"
      list={role !== 'Security' && role !== 'Seller' && ProperityList}
      edit={(role !== 'Security' && role === 'Admin' || role === 'Accountant' || role === 'PhotoGrapher' || role === '6') && ProperityEdit}
      create={(role !== 'Security' && role === 'Admin' || role === 'Accountant') && (role !== 'PhotoGrapher' && role !== '6') && ProperityCreate}
      show={(role !== 'Security' && role !== 'PhotoGrapher' && role !== '6') && PropertyShow}
      icon={PostIcon}
      display={false}
    />
    <Resource name="Properties/GetNotSold" />
    <Resource name="Properties/GetContracts" />
    <Resource
      name="PropertyHolds"
      options={{ label: "Reserved Properties" }}
      list={role !== 'Security' && role !== 'Seller' && (role !== 'PhotoGrapher' && role !== '6') && ReservedPropertiesList}
      create={(role !== 'Security' && role === 'Admin' || role === 'Accountant') && (role !== 'PhotoGrapher' && role !== '6') && ReservedPropertiesCreate}
      icon={RemoveCircleOutlineIcon}
    />
    <Resource
      name="Customers/GetUserAndPass"
      options={{ label: "User Login Info" }}
      list={role !== 'Security' && role !== 'Seller' && (role !== 'PhotoGrapher' && role !== '6') && UserNamesAndPassesList}
      edit={(role !== 'Security' && role === 'Admin') && (role !== 'PhotoGrapher' && role !== '6') && UserNamesAndPassesEdit}
      show={ (role !== 'Security' && role !== 'PhotoGrapher' && role !== '6') && ChangePassword}
      icon={LockOpenIcon}
    />
    <Resource
      name="Customers"
      list={role !== 'Security' && role !== 'Seller' && (role !== 'PhotoGrapher' && role !== '6') && CustomerList}
      edit={(role !== 'Security' && role === 'Admin' || role === 'Accountant') && (role !== 'PhotoGrapher' && role !== '6') && CustomerEdit}
      create={(role !== 'Security' && role === 'Admin' || role === 'Accountant') && (role !== 'PhotoGrapher' && role !== '6') && CustomerCreate}
      icon={AssignmentIndIcon}
      show={CustomerShow}
    />
    <Resource
      name="PaymentMethodProcedures"
      list={(role !== 'Security' && role !== 'Seller' && role !== 'Viewer') && (role !== 'PhotoGrapher' && role !== '6') && InvoiceList}
      options={{ label: "New Contract" }}
      icon={InvoiceIcon}
    />
    <Resource
      name="BuyContracts"
      options={{ label: "Contracts" }}
      list={role !== 'Security' && role !== 'Seller' && (role !== 'PhotoGrapher' && role !== '6') && BuyContractList}
      show={(role !== 'Security' && role === 'Admin' || role === 'Accountant' || role === 'Viewer') && (role !== 'PhotoGrapher' && role !== '6') && BuyContractShow}
      edit={(role !== 'Security' && role === 'Admin' || role === 'Accountant') && (role !== 'PhotoGrapher' && role !== '6') && BuyContractEdit}
      icon={ContractIcon}
    />
    <Resource
      name="finishContracts"
      options={{ label: "Finished Contracts" }}
      list={role !== 'Security' && role !== 'Seller' && (role !== 'PhotoGrapher' && role !== '6') && FinishedContractsList}
      show={(role !== 'Security' && role === 'Admin' || role === 'Accountant') && (role !== 'PhotoGrapher' && role !== '6') && FinishedContractsShow}
      icon={AssignmentTurnedInIcon}
    />
    <Resource
      name="CancelledContracts"
      options={{ label: "Cancelled Contracts" }}
      show={(role !== 'Security' && role === 'Admin' || role === 'Accountant') && (role !== 'PhotoGrapher' && role !== '6') && CancelledContractShow}
      list={role !== 'Security' && role !== 'Seller' && (role !== 'PhotoGrapher' && role !== '6') && CancelledContractList}
      icon={CancelPresentationIcon}
    />
    <Resource
      options={{ label: "TransferedContracts" }}
      name="TransferedContracts"
      list={role !== 'Security' && role !== 'Seller' && (role !== 'PhotoGrapher' && role !== '6') && TransferedContractList}
      show={role !== 'Security' && role !== 'Seller' && (role !== 'PhotoGrapher' && role !== '6') && TransferedContractShow}
      icon={FlipToFrontIcon}
    />
    <Resource
      name="Expenses/GetAllInfo"
      options={{ label: "Transactions" }}
      list={role !== 'Security' && role !== 'Seller' && (role !== 'PhotoGrapher' && role !== '6') && TransactionList}
      icon={AccountBalanceWalletIcon}
    />
    <Resource
      name="Expenses/GetAllUpcomingPyments"
      options={{ label: "Upcoming Payments" }}
      list={role !== 'Security' && role !== 'Seller' && (role !== 'PhotoGrapher' && role !== '6') && UpcomingPaymentList}
      icon={NextWeekIcon}
    />
    <Resource
      name="Expenses"
      list={role !== 'Security' && role !== 'Seller' && (role !== 'PhotoGrapher' && role !== '6') && ExpenceList}
      show={ (role !== 'Security' && role !== 'PhotoGrapher' && role !== '6') && ExpenceShow}
      create={(role !== 'Security' && role === 'Admin' || role === 'Accountant') && (role !== 'PhotoGrapher' && role !== '6') && ExpenceCreate}
      edit={(role !== 'Security' && role === 'Admin' || role === 'Accountant') && (role !== 'PhotoGrapher' && role !== '6') && ExpenseEdit}
      icon={MoneyRoundedIcon}
    />
    <Resource
      name="ExpenseTypes"
      options={{ label: "Expense Types" }}
      list={role !== 'Security' && role !== 'Seller' && (role !== 'PhotoGrapher' && role !== '6') && ExpenseTypeList}
      show={ (role !== 'Security' && role !== 'PhotoGrapher' && role !== '6') && ExpenseTypeShow}
      create={(role !== 'Security' && role === 'Admin' || role === 'Accountant') && (role !== 'PhotoGrapher' && role !== '6') && ExpenseTypeCreate}
      icon={LocalAtmRoundedIcon}
    />
    <Resource
      name="Reports"
      list={ (role !== 'Security' && role !== 'PhotoGrapher' && role !== '6') && Reports}
      icon={AssessmentIcon}
    />
    <Resource
      name="Dashboard/GetContractInfo"
      options={{ label: "Security" }}
      list={(role === 'Security' ) && SecurityCheckPoint}
      icon={AssessmentIcon}
    />
    <Resource
      name="LeadCustomers"
      options={{ label: "Lead Customers" }}
      list={ (role !== 'Security' && role !== 'PhotoGrapher' && role !== '6') &&LeadCustomersList}
      show={role !== 'Security' && role !== 'Viewer' && (role !== 'PhotoGrapher' && role !== '6') && LeadCustomersShow}
      create={role !== 'Security' && role !== 'Viewer' && (role !== 'PhotoGrapher' && role !== '6') && LeadCustomersCreate}
      edit={role !== 'Security' && role !== 'Viewer' && (role !== 'PhotoGrapher' && role !== '6') && LeadCustomersEdit}
      icon={UserIcon}
    />
    <Resource
      name="LeadVisits"
      options={{ label: "Lead Visits" }}
      list={(role !== 'Security' && role !== 'PhotoGrapher' && role !== '6') && LeadVisitsList}
      show={role !== 'Security' && role !== 'Viewer' && (role !== 'PhotoGrapher' && role !== '6') && LeadVisitsShow}
      create={role !== 'Security' && role !== 'Viewer' && (role !== 'PhotoGrapher' && role !== '6') && LeadVisitsCreate}
      edit={role !== 'Security' && role !== 'Viewer' && (role !== 'PhotoGrapher' && role !== '6') && LeadVisitsEdit}
      icon={AddLocationIcon}
    />
    <Resource
      name="LeadSources"
      options={{ label: "Lead Sources" }}
      list={(role !== 'Security' && role !== 'PhotoGrapher' && role !== '6') && SourceList}
      show={role !== 'Security' && role !== 'Viewer' && (role !== 'PhotoGrapher' && role !== '6') && SourceShow}
      create={role !== 'Security' && role !== 'Viewer' && (role !== 'PhotoGrapher' && role !== '6') && SourceCreate}
      edit={role !== 'Security' && role !== 'Viewer' && (role !== 'PhotoGrapher' && role !== '6') && SourceEdit}
      icon={LinkIcon}
    />
  </Admin>
);
export default App;
