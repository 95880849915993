import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  TopToolbar,
  sanitizeListRestProps,
  FunctionField,
  DateInput,
  NumberField,
  DateField,
  useListContext,
  Filter,
} from "react-admin";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import PrintProvider, {NoPrint, Print} from "react-easy-print";
import "./invoice.css";

const CustomFilter = (props) => {
  return (
    <NoPrint>
      <Filter {...props}>
        <DateInput
            label="start Date"
            source="startDate"
            alwaysOn
        />
        <DateInput
            label="end Date"
            source="endDate"
            alwaysOn
        />
      </Filter>
    </NoPrint>
  );
};
const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  return (
    <NoPrint force>
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <Button
          onClick={() => {
            window.print();
          }}
        >
          Print Report
        </Button>
        {filters &&
          React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
          })}
      </TopToolbar>
    </NoPrint>
  );
};

const postRowStyle = (record, index) => ({
  backgroundColor: record.isLost ? "tomato" : "#efe ",
});

export const UpcomingPaymentList = (props) => {
  const [total, setTotal] = React.useState(0);
  const [positive, setPositive] = React.useState(0);
  const [negative, setNegative] = React.useState(0);

  let lost = 0;
  let got = 0;
  React.useEffect(() => {
    setTotal(positive - negative);
  }, [positive, negative]);

  return (
    <div id="expenses">
      <PrintProvider>
        <Print single={true} name="foo">
          <InputGroup className="mb-3" style={{ marginTop: 10 }}>
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">Total</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder="Total"
              aria-label="Total"
              aria-describedby="basic-addon1"
              value={`$${total}`}
              disabled={true}
            />
          </InputGroup>
          <List
            {...props}
            // filterDefaultValues={{
            //   startDate: moment().format("YYYY-MM-DD"),
            //   endDate: moment().add(7, "days").format("DD-MM-YYYY"),
            // }}
            title="Upcoming Payments"
            pagination={false}
            filters={<CustomFilter />}
            actions={<ListActions />}
          >
            <Datagrid rowClick="show" rowStyle={postRowStyle}>
              <TextField source="contractNumber" label="Contract Number"/>
              <DateField source="date" />
              <FunctionField
                render={(record) => {
                  record.isLost && (lost = lost + record.price);
                  record.isLost && setNegative(lost);
                }}
              />
              <FunctionField
                render={(record) => {
                  !record.isLost && (got = got + record.price);
                  !record.isLost && setPositive(got);
                }}
              />
              <NumberField
                source="price"
                options={{ style: "currency", currency: "USD" }}
              />
              <TextField source="type" />
              <TextField source="detail" />
              <TextField source="phone" />
            </Datagrid>
          </List>
        </Print>
      </PrintProvider>
    </div>
  );
};
