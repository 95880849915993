import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  Show,
  DateField,
  DateInput,
  Pagination,
  SimpleShowLayout,
  ImageField,
  useListContext,
  TopToolbar,
  sanitizeListRestProps, FunctionField,
} from "react-admin";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import "./invoice.css";
import Button from "react-bootstrap/Button";

const CancelledContractFilter = (props) => (
  <NoPrint>
    <Filter {...props}>
      <DateInput label="Start Date" source="startDate" alwaysOn />
      <DateInput label="End Date" source="endDate" alwaysOn />
    </Filter>
  </NoPrint>
);
const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  return (
    <NoPrint>
      <TopToolbar {...sanitizeListRestProps(rest)}>
        {filters &&
          React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
          })}

        <Button
          onClick={() => {
            window.print();
          }}
        >
          Print Report
        </Button>
      </TopToolbar>
    </NoPrint>
  );
};
const PostPagination = (props) => (
  <NoPrint>
    <Pagination rowsPerPageOptions={[10, 25, 50, 100, 150]} {...props} />
  </NoPrint>
);

export const CancelledContractList = (props) => (
  <PrintProvider>
    <Print single={true} name="foo">
      <div id="expenses">
        <List
          {...props}
          title="Cancelled Contracts"
          pagination={<PostPagination />}
          actions={<ListActions />}
          filters={<CancelledContractFilter />}
        >
          <Datagrid rowClick="show">
            <TextField
              label="Contract Number"
              source="buyContract.id"
            />
            <FunctionField
                label="Customer Name"
                render={(record) =>
                    `${record.buyContract.customer.firstName} ${record.buyContract.customer.middleName} ${record.buyContract.customer.lastName}`
                }
            />
            <TextField source="buyContract.property.name" label={`Property Name`} />
            <TextField source="returnedPrice" />
            <TextField source="remainPrice" />
            <DateField source="date" />
            <TextField source="note" />
          </Datagrid>
        </List>
      </div>
    </Print>
  </PrintProvider>
);

export const CancelledContractShow = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="buyContractId" />
        <TextField source="returnedPrice" />
        <DateField source="date" />
        <TextField source="remainPrice" />
        <TextField source="note" />
        <ImageField source="attachment" title="title" />
      </SimpleShowLayout>
    </Show>
  );
};
