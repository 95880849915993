import React, { useState, useEffect } from 'react';
import axios from 'axios'
import Table from "react-bootstrap/Table";
import moment from 'moment'

function DailyLeadVisits(props) {
    const [todayVisits, setTodayVisits] = useState([]);

    useEffect(() => {
        if (props.currentTab === 'dailyLeadVisits') {
            const token = localStorage.getItem("token");
            axios.defaults.headers.common = {Authorization: `Bearer ${token}`};
            axios.get(`${process.env.REACT_APP_URL}/LeadVisits?startDate=${props.startDate}&endDate=${props.endDate}&_start=0&_end=1000`)
                .then((resp) => {
                    setTodayVisits(resp.data)
                });
        }
      }, [props.startDate, props.endDate, props.currentTab]);

    return (
        <div style={{margin: 5}}>
            <Table striped bordered hover>
            <thead>
                <tr>
                <th>#</th>
                <th>Name</th>
                <th>Phone No.</th>
                <th>Men</th>
                <th>Women</th>
                <th>Total</th>
                <th>9:00 - 12:00</th>
                <th>12:00 - 14:00</th>
                <th>14:00 - 17:00</th>
                <th>Source</th>
                <th>Time</th>
                <th>Note</th>
                </tr>
            </thead>
            <tbody>
                {
                    todayVisits.map((visit, key) => {
                        return <tr key={key}>
                            <td>{key + 1}</td>
                            <td>{visit.leadCustomerName}</td>
                            <td>{visit.leadCustomerPhone}</td>
                            <td>{visit.males}</td>
                            <td>{visit.females}</td>
                            <td>{visit.females + visit.males}</td>
                            {/* <td>{moment(visit.visitDate).isBetween(moment(visit.visitDate).format('YYYY-MM-DDT09:00:00'), moment(visit.visitDate).format('YYYY-MM-DDT12:00:00')) && (visit.females + visit.males)}</td> */}
                            <td>{moment(visit.visitDate).isBetween(moment(visit.visitDate).format('YYYY-MM-DDT00:00:00'), moment(visit.visitDate).format('YYYY-MM-DDT12:00:00')) && (visit.females + visit.males)}</td>
                            <td>{moment(visit.visitDate).isBetween(moment(visit.visitDate).format('YYYY-MM-DDT12:00:00'), moment(visit.visitDate).format('YYYY-MM-DDT14:00:00')) && (visit.females + visit.males)}</td>
                            <td>{moment(visit.visitDate).isBetween(moment(visit.visitDate).format('YYYY-MM-DDT14:00:00'), moment(visit.visitDate).format('YYYY-MM-DDT23:59:59')) && (visit.females + visit.males)}</td>
                            {/* <td>{moment(visit.visitDate).isBetween(moment(visit.visitDate).format('YYYY-MM-DDT14:00:00'), moment(visit.visitDate).format('YYYY-MM-DDT17:00:00')) && (visit.females + visit.males)}</td> */}
                            <td>{visit.sourceName}</td>
                            <td>{moment(visit.visitDate).format('HH:mm')}</td>
                            <td>{visit.note}</td>
                        </tr>
                    })
                }
                <tr>
                    <td>Total</td>
                    <td></td>
                    <td></td>
                    <td>{todayVisits.map(visit => visit.males).reduce((a,b) => a + b, 0)}</td>
                    <td>{todayVisits.map(visit => visit.females ).reduce((a,b) => a + b, 0)}</td>
                    <td>{todayVisits.map(visit => visit.females + visit.males).reduce((a,b) => a + b, 0)}</td>
                    {/* <td>{todayVisits.map(visit => (moment(visit.visitDate).isBetween(moment(visit.visitDate).format('YYYY-MM-DDT09:00:00'), moment(visit.visitDate).format('YYYY-MM-DDT12:00:00')) && (visit.females + visit.males))).reduce((a,b) => a + b, 0)}</td> */}
                    <td>{todayVisits.map(visit => (moment(visit.visitDate).isBetween(moment(visit.visitDate).format('YYYY-MM-DDT00:00:00'), moment(visit.visitDate).format('YYYY-MM-DDT12:00:00')) && (visit.females + visit.males))).reduce((a,b) => a + b, 0)}</td>
                    <td>{todayVisits.map(visit => (moment(visit.visitDate).isBetween(moment(visit.visitDate).format('YYYY-MM-DDT12:00:00'), moment(visit.visitDate).format('YYYY-MM-DDT14:00:00')) && (visit.females + visit.males))).reduce((a,b) => a + b, 0)}</td>
                    <td>{todayVisits.map(visit => (moment(visit.visitDate).isBetween(moment(visit.visitDate).format('YYYY-MM-DDT14:00:00'), moment(visit.visitDate).format('YYYY-MM-DDT23:59:59')) && (visit.females + visit.males))).reduce((a,b) => a + b, 0)}</td>
                    {/* <td>{todayVisits.map(visit => (moment(visit.visitDate).isBetween(moment(visit.visitDate).format('YYYY-MM-DDT14:00:00'), moment(visit.visitDate).format('YYYY-MM-DDT17:00:00')) && (visit.females + visit.males))).reduce((a,b) => a + b, 0)}</td> */}
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
            </Table>
        </div>
    )
}

export default DailyLeadVisits
