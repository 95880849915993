import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  EmailField,
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  DateInput,
  TextInput,
  Edit,
  CheckboxGroupInput,
  FileField,
  regex,
  Show,
  SimpleShowLayout,
  DateField,
  TabbedForm,
  FormTab,
  ImageField,
  RadioButtonGroupInput,
  ImageInput,
  required,
  email,
  Filter,
  downloadCSV,
  useNotify,
  FunctionField,
} from "react-admin";
import axios from "axios";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Badge from "react-bootstrap/Badge";
import jsonExport from 'jsonexport/dist';
import moment from "moment";

const CustomerFilter = (props) => (
  <Filter {...props}>
    <TextInput label="First Name" source="firstName" alwaysOn />
    <TextInput label="Phone" source="phone" />
    <TextInput label="How Came" source="HowCame" />
    <TextInput label="Middle Name" source="middleName" />
  </Filter>
);

export const CustomerList = (props) => {
  const role = localStorage.getItem("role");
    const exporter = (data) => {
        const dataForExport = data.map(data => {
            let {
                id, firstName, middleName,
                lastName, nationality, email,
                phone, howCame
            } = data;
            let newPhone = phone.replaceAll('+964', '0').toString();
            return {id, firstName, middleName, lastName, nationality, email, newPhone, howCame};
        });
        jsonExport(dataForExport, {
            headers: [
                'id', 'firstName', 'middleName',
                'lastName', 'nationality', 'email',
                'newPhone', 'howCame'
            ],
            rename: ['Id', 'First Name', 'Middle Name', 'Last Name', 'Nationality', 'Email', 'Phone', 'How Came']
        }, (err, csv) => {
            downloadCSV("\ufeff"+ csv, 'customers.' + moment(new Date()).format('YYMMDDHHmmssa'));
        });
    }

  return(
  <List {...props} filters={<CustomerFilter />} exporter={exporter}>
    <Datagrid rowClick="show" >
      <TextField source="firstName" />
      {/* <FunctionField
          label="First Name"
          sortBy="id"
          sortByOrder="DESC"
          render={record => `${record.firstName} `}
      /> */}
      <TextField source="middleName" />
      {/* <ReferenceField label="id" source="id" reference="Customers" sortByOrder="DESC" sortBy="id">
          <TextField source="firstName" />
      </ReferenceField> */}
      <TextField source="lastName" />
      {/* <TextField source="dob" /> */}
      <TextField source="nationality" />
      {/* <TextField source="address" /> */}
      <TextField source="email" />
      <TextField source="phone" />
      <TextField label="How Came" source="howCame" />
      {/* <TextField source="phone2" /> */}
      {/* <TextField source="idcard" /> */}
      {/* <TextField source="foodCard" /> */}
      {/* <TextField source="placementCard" /> */}
      {/* <TextField source="securitySupport" /> */}
      {/* <TextField source="note" /> */}
      {/* <TextField source="createdAt" /> */}
      {/* <TextField source="updatedAt" /> */}
      {/* <TextField source="appUserId" /> */}
      {/* <TextField source="userType" /> */}
      {/* <TextField source="isActive" /> */}
      {/* <ReferenceField source="id" reference="users">
        <TextField source="name" />
      </ReferenceField> */}
      {(role === 'Admin' || role === 'Accountant') && <EditButton />}
    </Datagrid>
  </List>
)};

export const CustomerShow = (props) => (
  <div style={{ display: "flex", width: "100%" }}>
    <Show {...props} style={{ width: "50%" }}>
      <SimpleShowLayout>
        <TextField source="firstName" />
        <TextField source="middleName" />
        <TextField source="lastName" />
        <DateField source="dob" />
        <TextField source="nationality" />
        <TextField source="address" />
        <TextField source="email" />
        <TextField source="phone" />
        <TextField source="phone2" />
        <TextField source="idcard" />
        <TextField source="foodCard" />
        <TextField source="placementCard" />
        <TextField source="informationCard" />
        <TextField source="securitySupport" />
        <TextField source="note" multiline />
        <TextField source="createdAt" />
        <DateField source="updatedAt" />
        <TextField source="appUserId" />
        <TextField source="userType" />
        <TextField source="isActive" />
        <TextField source="howCame" />
        <TextField source="jnsia" />
      </SimpleShowLayout>
    </Show>
    <Show {...props} style={{ width: "50%", marginLeft: 10 }}>
      <SimpleShowLayout>
        <FunctionField
          label="Properties"
          render={(record) =>
            record.buyContract.map((el) => `${el.property.name}, `)
          }
        />
      </SimpleShowLayout>
    </Show>
  </div>
);

const validateCustomerCreation = (values) => {
  const errors = {};
  if (!values.informationCard && !values.placementCard) {
    errors.informationCard = [
      "At least one of Placement Card Or Information Card must be inserted!",
    ];
  }
  return errors;
};

export const CustomerCreate = (props) => {
  const notify = useNotify();
  // const redirect = useRedirect();
  const onFailure = (error) => {
    if (error.status == 304) {
      notify("Emails Exists Already!", "error");
    } else if (error.status == 305) {
      notify("Phone Exists Already!", "error");
    }
  };
  return (
    <Create {...props} onFailure={onFailure}>
      <SimpleForm redirect="list" validate={validateCustomerCreation}>
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ marginRight: 55 }}>
            <TextInput
              source="firstName"
              fullWidth={true}
              validate={[required()]}
            />
            <TextInput
              source="middleName"
              fullWidth={true}
              validate={[required()]}
            />
            <TextInput
              source="lastName"
              fullWidth={true}
              validate={[required()]}
            />
            <DateInput source="dob" label="Date of Birth" fullWidth={true} />
            <TextInput source="nationality" fullWidth={true} />
            <TextInput source="address" fullWidth={true} />
          </div>
          <div style={{ marginRight: 55 }}>
            <TextInput source="email" fullWidth={true} validate={[email()]} />
            <TextInput
              source="phone"
              fullWidth={true}
              validate={[
                required(),
                regex(
                  /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                  "Invalid Phone Number!"
                ),
              ]}
            />
            <TextInput
              source="phone2"
              validate={[
                regex(
                  /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                  "Invalid Phone Number!"
                ),
              ]}
              fullWidth={true}
            />
            <TextInput
              source="idcard"
              fullWidth={true}
              validate={[required()]}
            />
            <TextInput source="foodCard" fullWidth={true} />
            <TextInput source="jnsia" fullWidth={true} />
          </div>
          <div>
            <TextInput source="placementCard" fullWidth={true} />
            <TextInput source="informationCard" fullWidth={true} />
            <TextInput source="securitySupport" fullWidth={true} />
            <TextInput source="note" multiline fullWidth={true} />
            <TextInput source="howCame" multiline fullWidth={true} />
            <TextInput
              source="userType"
              fullWidth={true}
              value={"Customer"}
              style={{ display: "none" }}
            />
            <RadioButtonGroupInput
              source="male"
              label="Gender"
              validate={[required()]}
              choices={[
                { id: true, name: "Male" },
                { id: false, name: "Female" },
              ]}
            />
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};

const CustomerTitle = ({ record }) => {
  return (
    <span>
      Customer {record ? `"${record.firstName} ${record.lastName}"` : ""}
    </span>
  );
};

export const CustomerEdit = (props) => {
  const [file, setFile] = React.useState();
  const [fileName, setFileName] = React.useState();
  const [id, setId] = React.useState(props.id);
  const [customerAttachments, setCustomerAttachments] = React.useState([]);
  const [attachmentsModified, setAttachmentsModified] = React.useState(0);
  const [isSharable, setIsSharable] = React.useState(false);
  const [is360, setIs360] = React.useState(false);

  const fileSelectHandler = (e) => {
    setFileName(e.target.files[0].name);
    getBase64(e.target.files[0]);
  };

  const downloadStyles = {
    width: 85,
    backgroundColor: "lightblue",
    color: "#fff",
    textAlign: "center",
    fontWeight: "bolder",
    paddingTop: 1,
    paddingBottom: 2,
    border: "none",
  };
  const sharableStyles = {
    width: 75,
    backgroundColor: "darkgreen",
    color: "white",
    textAlign: "center",
    fontWeight: "bolder",
    border: "none",
  };
  const deleteStyles = {
    width: 75,
    backgroundColor: "#F32013",
    color: "white",
    textAlign: "center",
    fontWeight: "bolder",
    border: "none",
    borderBottomRightRadius: 5,
  };
  const printStyles = {
    width: 65,
    backgroundColor: "lightblue",
    color: "white",
    textAlign: "center",
    fontWeight: "bolder",
    border: "none",
    borderBottomLeftRadius: 5,
  };

  const images = customerAttachments.map((customerAttachment, key) => {
    return (
      customerAttachment && (
        <div key={key} style={{ margin: 10 }}>
          <div style={{ width: 300, height: 200, borderStyle: 'solid', borderWidth: 1, borderColor: '#777', padding: 5 }}>
              {customerAttachment.is360 && <Badge style={{position: 'absolute'}} variant="primary">360</Badge>}
            <img
              src={customerAttachment.link}
              alt={customerAttachment.name}
              style={{
                width: "100%",
                height: "100%",
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
                objectFit: "scale-down",
              }}
            />
          </div>
          <iframe
            id={`iframe${key}`}
            style={{
              height: 0,
              width: 0,
              position: "absolute",
              display: "none",
            }}
          >
            <div style={{ width: "100%", height: "100%" }}>
              <img
                src={customerAttachment.link}
                alt={customerAttachment.name}
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "scale-down",
                }}
              />
            </div>
          </iframe>
          <div style={{ display: "flex" }}>
            <button style={printStyles} onClick={() => printImage(key)}>
              Print
            </button>
            <button
              style={sharableStyles}
              onClick={(e) => {
                axios
                  .post(
                    `${process.env.REACT_APP_URL}/CustomersAttachments/ToggleShareable?id=${customerAttachment.id}`
                  )
                  .then(() => {
                    setAttachmentsModified(attachmentsModified + 1);
                  });
              }}
            >
              {customerAttachment.shareableForCustomer ? "Unshare" : "Share"}
            </button>
            <a
              href={customerAttachment.link}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <div style={downloadStyles}>Download</div>
            </a>
            <button
              style={deleteStyles}
              onClick={() => deleteImage(customerAttachment.id)}
            >
              Delete
            </button>
          </div>
        </div>
      )
    );
  });

  const printImage = (imageId) => {
    var print = document.getElementById(`iframe${imageId}`).contentWindow;
    print.document.open();
    print.document.write(document.getElementById(`iframe${imageId}`).innerHTML);
    // print.document.write(content.innerHTML);
    print.document.close();
    print.focus();
    print.print();
  };

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
      .get(`${process.env.REACT_APP_URL}/Customers/${id}`)
      .then((resp) => {
        setCustomerAttachments(resp.data.customersAttachment);
      });
  }, [attachmentsModified]);
  function handleSubmit(e) {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    e.preventDefault();

    axios
      .post(process.env.REACT_APP_URL + "/CustomersAttachments", {
        src: file,
        title: fileName,
        id: id,
        isSharable: isSharable,
        is360: is360,
      })
      .then((res) => {
        setAttachmentsModified(attachmentsModified + 1);
        setFile();
        setFileName();
        setIsSharable(false);
        setIs360(false);
        document.getElementById("fileSelector").value = "";
      });
  }
  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setFile(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  function deleteImage(id) {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
      .delete(`${process.env.REACT_APP_URL}/CustomersAttachments/${id}`)
      .then((res) => setAttachmentsModified(attachmentsModified + 1));
  }

  return (
    <Edit title={<CustomerTitle />} {...props}>
      <TabbedForm>
        <FormTab label="Customer">
          <div style={{ display: "flex", width: "100%" }}>
            <div style={{ marginRight: 55 }}>
              <TextInput
                source="firstName"
                fullWidth={true}
                validate={[required()]}
              />
              <TextInput
                source="middleName"
                fullWidth={true}
                validate={[required()]}
              />
              <TextInput
                source="lastName"
                fullWidth={true}
                validate={[required()]}
              />
              <DateInput source="dob" label="Date of Birth" fullWidth={true} />
              <TextInput source="nationality" fullWidth={true} />
              <TextInput source="address" fullWidth={true} />
            </div>
            <div style={{ marginRight: 55 }}>
              <TextInput source="email" fullWidth={true} validate={[email()]} />
              <TextInput
                source="phone"
                fullWidth={true}
                validate={[
                  required(),
                  regex(
                    /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                    "Invalid Phone Number!"
                  ),
                ]}
              />
              <TextInput
                source="phone2"
                validate={[
                  regex(
                    /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                    "Invalid Phone Number!"
                  ),
                ]}
                fullWidth={true}
              />
              <TextInput source="idcard" fullWidth={true} />
              <TextInput source="foodCard" fullWidth={true} />
              <TextInput source="jnsia" fullWidth={true} />
            </div>
            <div>
              <TextInput source="placementCard" fullWidth={true} />
              <TextInput source="informationCard" fullWidth={true} />
              <TextInput source="securitySupport" fullWidth={true} />
              <TextInput source="note" multiline fullWidth={true} />
              <TextInput source="howCame" multiline fullWidth={true} />
              <TextInput
                source="userType"
                fullWidth={true}
                value={"Customer"}
                style={{ display: "none" }}
              />
              <RadioButtonGroupInput
                source="male"
                label="Gender"
                choices={[
                  { id: true, name: "Male" },
                  { id: false, name: "Female" },
                ]}
              />
            </div>
          </div>
        </FormTab>
        <FormTab label="Attachment">
          {/* <FileInput multiple={true} source="files" label="Related files">
          <FileField source="src" title="title" />
        </FileInput> */}
          <form style={{ width: "100%" }} onSubmit={handleSubmit}>
            <div style={{ display: "flex", width: "100%" }}>
              <input
                style={{
                  margin: 5,
                  borderColor: "red",
                  borderWidth: 1,
                  borderRadius: 4,
                  padding: 5,
                }}
                type="file"
                id="fileSelector"
                onChange={fileSelectHandler}
              />
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <InputGroup style={{ width: 200, marginTop: 8 }} className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Checkbox
                      aria-label="Checkbox for following text input"
                      checked={isSharable}
                      onChange={(e) =>
                        isSharable ? setIsSharable(false) : setIsSharable(true)
                      }
                    />
                  </InputGroup.Prepend>
                  <FormControl
                    aria-label="Text input with checkbox"
                    placeholder="Is Sharable"
                    disabled={true}
                  />
                </InputGroup>
                <InputGroup style={{ width: 200, marginTop: 8 }} className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Checkbox
                      aria-label="Checkbox for following text input"
                      checked={is360}
                      onChange={(e) =>
                        setIs360(!is360)
                      }
                    />
                  </InputGroup.Prepend>
                  <FormControl
                    aria-label="Text input with checkbox"
                    placeholder="Is 360"
                    disabled={true}
                  />
                </InputGroup>
              </div>
            </div>
            <input
              style={{
                margin: 5,
                border: "none",
                borderRadius: 4,
                padding: 5,
                paddingLeft: 20,
                paddingRight: 20,
              }}
              type="submit"
              value="send"
            />
          </form>
          <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
            {images}
          </div>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
