import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  TopToolbar,
  sanitizeListRestProps,
  CreateButton,
  EditButton,
  ShowButton,
  DateTimeInput,
  FunctionField,
  Create,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  DateInput,
  TextInput,
  Edit,
  CheckboxGroupInput,
  NumberField,
  FileField,
  FileInput,
  Show,
  SimpleShowLayout,
  DateField,
  NumberInput,
  required,
  TabbedForm,
  FormTab,
  ImageField,
  ImageInput,
  useListContext,
  Pagination,
  Filter,
} from "react-admin";
import axios from "axios";
import Button from "react-bootstrap/Button";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import "./invoice.css";
import { useFormState } from 'react-final-form';

const CustomerFilter = (props) => {
  return (
    <NoPrint>
      <div style={{display: 'flex', alignItems: 'center'}}>
      <Filter {...props}>
        <TextInput label="Name or Phone" source="customerName" alwaysOn />
        <DateInput label="Start Date" source="startDate" alwaysOn/>
        <DateInput label="End Date" source="endDate" alwaysOn/>
      </Filter>
      {(props.filterValues.customerName || props.filterValues.startDate || props.filterValues.endDate) && <Button style={{height: 40}} onClick={() => props.setFilters({
              customerName: '',
              startDate: '',
              endDate: '',
        })}>Clear fields</Button>}
      </div>
    </NoPrint>
  );
};
const ListActions = (props) => {
  const role = localStorage.getItem("role");
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  return (
    <NoPrint>
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {/* <Button
          onClick={() => {
            window.print();
          }}
        >
          Print Report
        </Button> */}
        {/* {filters &&
          React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
          })} */}
        {(role === 'Admin' || role === 'Accountant') && <CreateButton basePath={basePath} />}
      </TopToolbar>
    </NoPrint>
  );
};
const PostPagination = (props) => (
  <NoPrint>
    <Pagination rowsPerPageOptions={[10, 25, 50, 100, 150]} {...props} />
    <Total props={props && props} />
  </NoPrint>
);

const Total = (props) => {
  let totals = props.props && props.props.data;
  let count = totals && Object.keys(totals).length;
  return null;
};

export const LeadVisitsList = (props) => {
  const role = localStorage.getItem("role");

  return (
    <List
    {...props}
    // pagination={<PostPagination />}
    filters={<CustomerFilter />}
    actions={<ListActions />}
    >
    <Datagrid rowClick="show" >
      <TextField label={'Name'} source="leadCustomerName"/>
      <TextField label={'Phone'} source="leadCustomerPhone"/>
      <DateField source="visitDate"/>
      <NumberField source="males"/>
      <NumberField source="females"/>
      <TextField source="newState"/>
      <TextField source="note"/>
      {(role === 'Admin' || role === 'Accountant') && <EditButton />}
    </Datagrid>
    </List>
  );
};

export const LeadVisitsShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
        <DateField source="visitDate"/>
        <NumberField source="males"/>
        <NumberField source="females"/>
        <TextField source="newState"/>
        <TextField source="note"/>
    </SimpleShowLayout>
  </Show>
);

  const OldState = props => {
    const [oldState, setOldState] = React.useState('')
    const { values } = useFormState();

    React.useEffect(() => {
      const token = localStorage.getItem("token");
      axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
      values.leadCustomerId && axios
        .get(`${process.env.REACT_APP_URL}/LeadCustomers/${values.leadCustomerId}`)
        .then((resp) => {
          setOldState(resp.data.state)
        });
    }, [values]);

    return (
      <TextInput value={oldState} label="Old State" fullWidth={true} disabled={true}/>
    );
};
export const LeadVisitsCreate = (props) => {

  return (
    <Create {...props} >
      <SimpleForm redirect="list">
      <div style={{display: 'flex', width: '100%'}}>
            <div style={{ marginRight: 55 }}>
                <ReferenceInput fullWidth={true} label="Lead Customers" source="leadCustomerId" reference="LeadCustomers" validate={[required()]}>
                    <AutocompleteInput optionText={record => `${record.name} || ${record.phone}`} />
                </ReferenceInput>
                <SelectInput source="newState" fullWidth={true} choices={[
                    { id: 'new', name: 'New' },
                    { id: 'qualified', name: 'Qualified' },
                    { id: 'discussion', name: 'Discussion' },
                    { id: 'negotiation', name: 'Negotiation' },
                    { id: 'won', name: 'Won' },
                    { id: 'lost', name: 'Lost' },
                ]} validate={[required()]} />
                <OldState />
                <TextInput source="note" multiline fullWidth={true} />
            </div>
            <div>
                <DateTimeInput source="visitDate" fullWidth={true} validate={[required()]}/>
                <NumberInput source="males" fullWidth={true} defaultValue={0} validate={[required()]}/>
                <NumberInput source="females" fullWidth={true} defaultValue={0} validate={[required()]}/>
            </div>
        </div>
      </SimpleForm>
    </Create>
  );
};

export const LeadVisitsEdit = (props) => (
  <Edit {...props}>
    <SimpleForm redirect="list">
      <div style={{display: 'flex', width: '100%'}}>
            <div style={{ marginRight: 55 }}>
                <ReferenceInput fullWidth={true} label="Lead Customer" source="leadCustomerId" reference="LeadCustomers" perPage="5000">
                    <AutocompleteInput optionText="name" disabled='true'/>
                </ReferenceInput>
                <SelectInput source="newState" fullWidth={true} choices={[
                    { id: 'new', name: 'New' },
                    { id: 'qualified', name: 'Qualified' },
                    { id: 'discussion', name: 'Discussion' },
                    { id: 'negotiation', name: 'Negotiation' },
                    { id: 'won', name: 'Won' },
                    { id: 'lost', name: 'Lost' },
                ]} />
                <OldState />
                <TextInput source="note" multiline fullWidth={true}/>
            </div>
            <div>
                <DateTimeInput type="datetime-local" source="visitDate" fullWidth={true}/>
                <NumberInput source="males" fullWidth={true}/>
                <NumberInput source="females" fullWidth={true}/>
            </div>
        </div>
    </SimpleForm>
  </Edit>
);
