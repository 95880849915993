import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  Filter,
  TextInput,
  FunctionField,
  EditButton,
  Edit,
  DateField,
  DateInput,
  Pagination,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  CreateButton,
  useNotify,
} from "react-admin";
import ZaitonLogo from "../assets/zaiton-plus-logo.jpg";
import axios from "axios";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import ProgressBar from "react-bootstrap/ProgressBar";
import moment from "moment";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import "./invoice.css";
import KurdishTemplate from "./templates/KurdishContract";
import ArabicTemplate from "./templates/ArabicContract";
import Modal from "react-bootstrap/Modal";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MaterialTextField from "@material-ui/core/TextField";
import ToggleButton from "react-bootstrap/ToggleButton";
import swal from 'sweetalert';
import KurdishTemplateJ from "./templates/KurdishContractJ";
import ArabicTemplateJ from "./templates/ArabicContractJ";

const BuyContractFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Property Name" source="Name" alwaysOn />
    <TextInput label="First Name" source="firstName" />
    <TextInput label="Second Name" source="secondName" />
    <TextInput label="Note" source="note" />
    <DateInput label="Start Date" source="startDate" alwaysOn/>
    <DateInput label="End Date" source="endDate" alwaysOn/>
  </Filter>
);

const PostPagination = (props) => (
  <NoPrint>
    <Pagination rowsPerPageOptions={[10, 25, 50, 100, 150]} {...props} />
  </NoPrint>
);

const ContractActions = (props) => {
  const role = localStorage.getItem("role");
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  return (
    <NoPrint>
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <Button
          onClick={() => {
            window.print();
          }}
        >
          Print Report
        </Button>
        {filters &&
          React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
          })}
        {(role === 'Admin' || role === 'Accountant') && <CreateButton basePath={basePath} />}
      </TopToolbar>
    </NoPrint>
  );
};

export const BuyContractList = (props) => {
  const role = localStorage.getItem("role");

  return (
    <div id="buyContracts">
    <PrintProvider>
    <Print single={true} name="foo">
    <List {...props} filters={<BuyContractFilter />} title="Contracts"  actions={<ContractActions />} pagination={<PostPagination />}>
      <Datagrid rowClick="show" isRowSelectable={(record) => (localStorage.getItem('role') !== 'Viewer') && record && moment().diff(record.createdAt, 'hours') <= 24}>
        <TextField source="contractNumber" />
        <TextField source="property.name" label="Property Name" />
        <FunctionField
          label="Customer Name"
          render={(record) =>
            `${record.customer.firstName} ${record.customer.middleName}`
          }
        />
        <DateField source="buyingDate" />
        <FunctionField
          label="Payment Progress"
          render={(record) => (
            <ProgressBar
              now={
                (record.paymentProcess.filter((e) => e.isPayed === true).length /
                  record.paymentProcess.length) *
                100
              }
              label={`${Math.floor(
                (record.paymentProcess.filter((e) => e.isPayed === true).length /
                  record.paymentProcess.length) *
                  100
              )}%`}
            />
          )}
        />
        <NumberField source="buyingPrice" options={{ style: "currency", currency: "USD" }}/>
        {/* <BooleanField source="isLoanDone" /> */}
        <TextField source="note" />
        {(role === 'Admin' || role === 'Accountant') && <EditButton
          label="Cancel Contract"
          style={{ color: "tomato" }}
          icon={false}
        />}
        {/* <FunctionField
          label="Cancel Contract"
          render={(record) =>
            !record.isLoanDone && (
              <EditButton
                label="Cancel Contract"
                style={{ color: "red" }}
                icon={false}
              />
            )
          }
        /> */}
      </Datagrid>
    </List>
    </Print>
    </PrintProvider>
    </div>
  )};

const ListActions = (props) => {
  const [show, setShow] = React.useState(false);
  const [file, setFile] = React.useState();
  const [newCustomerId, setNewCustomerId] = React.useState();
  const [customerArr, setCustomerArr] = React.useState([]);
  const [date, setDate] = React.useState(moment().format("YYYY-MM-DD"));
  const [note, setNote] = React.useState("");

  const fileSelectHandler = (e) => {
    getBase64(e.target.files[0]);
  };

  const role = localStorage.getItem("role");

  const notify = useNotify();
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios.get(process.env.REACT_APP_URL + "/Customers?_end=1000").then((resp) => {
      setCustomerArr(
        resp.data.map((properity, key) => {
          return {
            id: properity.id,
            name: `${properity.firstName} ${properity.middleName} ${properity.lastName}`,
            phone: properity.phone,
          };
        })
      );
    });
  }, []);

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setFile(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  return (
    <div>
      {(role === 'Admin' || role === 'Accountant') && <Button
        variant="warning"
        style={{ marginRight: 5, color: "white" }}
        onClick={() => {
          setShow(true);
        }}
      >
        Transfere Contract
      </Button>}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Transfere Contract
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Autocomplete
            required={true}
            id="combo-box-demo"
            options={customerArr}
            getOptionLabel={(option) => {
              setNewCustomerId(option.id);
              return `${option.name} | ${option.phone}`;
            }}
            style={{ width: "100%", marginBottom: 10 }}
            renderInput={(params) => (
              <MaterialTextField
                {...params}
                label="Customer"
                variant="outlined"
              />
            )}
          />
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">date</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              required={true}
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </InputGroup>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>Note</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              as="textarea"
              aria-label="Note"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </InputGroup>
          <input
            style={{
              margin: 5,
              borderColor: "red",
              borderWidth: 1,
              borderRadius: 4,
              padding: 5,
            }}
            type="file"
            id="fileSelector"
            onChange={fileSelectHandler}
          />
          <Button
            style={{ marginTop: 10 }}
            onClick={() => {
              axios
                .post(
                  process.env.REACT_APP_URL + "/TransferedContracts",
                  {
                    newCustomerId: newCustomerId,
                    transferDate: date,
                    note,
                    attachment: "",
                    oldContractId: props.id,
                  }
                )
                .then((res) => {
                  if (res.status >= 200 && res.status < 300) {
                    window.open(`#/BuyContracts`, "_self");
                  }
                })
                .catch((err) => notify("Could'nt Propceed!"));
            }}
          >
            Transfere
          </Button>
        </Modal.Body>
      </Modal>
      <Button
        onClick={() => {
          window.print();
        }}
      >
        Print Contract
      </Button>
    </div>
  );
};

export const BuyContractShow = (props) => {
  Date.prototype.toDateInputValue = function () {
    var local = new Date(this);
    local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
    return local.toJSON().slice(0, 10);
  };

  const [rows, setRows] = React.useState();
  const [periodOfInstallment, setPeriodOfInstallment] = React.useState(4);
  const [preInstallment, setPreInstallment] = React.useState(35);
  const [installments, setInstallments] = React.useState(50);
  const [getKeys, setGetKeys] = React.useState(15);
  const [properitiesArr, setProperitiesArr] = React.useState([]);
  const [properitiesData, setProperitiesData] = React.useState([]);
  const [customerArr, setCustomerArr] = React.useState([]);
  const [customerData, setCustomerData] = React.useState([]);
  const [properityData, setProperityData] = React.useState(0);
  const [defaultDate, setDefaultDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const [buyContractId, setBuyContractId] = React.useState(-1);
  const [note, setNote] = React.useState("");
  const [buyType, setBuyType] = React.useState("installment");
  const [customer, setCustomer] = React.useState("");
  const [customerId, setCustomerId] = React.useState();
  const [properity, setProperity] = React.useState("");
  const [pageNumber, setPageNumber] = React.useState(7);
  const [data, setData] = React.useState([]);
  const [extraPrice, setExtraPrice] = React.useState(0);
  const [extraNote, setExtraNote] = React.useState("");
  const [prePaid, setPrePaid] = React.useState(false);
  const [prePaymentText, setPrePaymentText] = React.useState(0);
  const [openSmSm, setOpenSmSm] = React.useState(false);
  const [preNote, setPreNote] = React.useState("");
  const [arabicInvoice, setArabicInvoice] = React.useState(false);
  const [isItPayment, setIsItPayment] = React.useState(null);
  const [isItKey, setIsItKey] = React.useState(null);
  const [changeStructureDate, setChangeStructureDate] = React.useState(true);
  const [changeKeyDate, setChangeKeyDate] = React.useState(true);

  const keyValue = (properityData.initialPrice / 100) * getKeys;
  const prePayValue = (properityData.initialPrice / 100) * preInstallment;
  const eachInstallment =
    (properityData.initialPrice - (keyValue + prePayValue)) / rows;
  React.useEffect(() => {
    setInstallments(100 - (preInstallment + getKeys));
  }, [preInstallment]);
  React.useEffect(() => {
    setInstallments(100 - (preInstallment + getKeys));
  }, [getKeys]);

  const arrLength = [];
  for (let index = 0; index < rows; index++) {
    arrLength[index] = index;
  }
  const arr = ["first", "second", "third", "fourth"];

  const renameKeys = (keysMap, obj) =>
    Object.keys(obj).reduce(
      (acc, key) => ({
        ...acc,
        ...{ [keysMap[key] || key]: obj[key] },
      }),
      {}
    );

  React.useEffect(async () => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    await axios
      .get(`${process.env.REACT_APP_URL}/BuyContracts/${props.id}`)
      .then((resp) => {
        setData(resp.data);
        setOpenSmSm(resp.data.isPrePaymentPayed);

        let isItPrePayment = resp.data.paymentProcess.find(x => x.isItPrePayment.toString() === 'true');
        if (isItPrePayment) {
          let isItPrePaymentIndex = resp.data.paymentProcess.findIndex(x => x.isItPrePayment.toString() === 'true');
          resp.data.paymentProcess.splice(isItPrePaymentIndex, 1);
          setIsItPayment(isItPrePayment);
        }

        let isItKeyPayment = resp.data.paymentProcess.find(x => x.isItKeyPayment.toString() === 'true');
        if (isItKeyPayment) {
          let isItKeyPaymentIndex = resp.data.paymentProcess.findIndex(x => x.isItKeyPayment.toString() === 'true');
          resp.data.paymentProcess.splice(isItKeyPaymentIndex, 1);
          setIsItKey(isItKeyPayment);
        }

      });
  }, []);
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
      .get(
        `${process.env.REACT_APP_URL}/Dashboard/NumberToTextKu?number=${data.prePaymentPrice}`
      )
      .then((resp) => {
        setPrePaymentText(resp.data);
      });
    setRows(data.paymentProcess && data.paymentProcess.length - 1);
  }, [data]);
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    if(arabicInvoice) {
      axios
      .get(
        `${process.env.REACT_APP_URL}/Dashboard/NumberToTextAr?number=${data.prePaymentPrice}`
      )
      .then((resp) => {
        setPrePaymentText(resp.data);
      });
    } else {
    axios
      .get(
        `${process.env.REACT_APP_URL}/Dashboard/NumberToTextKu?number=${data.prePaymentPrice}`
      )
      .then((resp) => {
        setPrePaymentText(resp.data);
      });
    }
  }, [arabicInvoice]);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const role = localStorage.getItem('role')
  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  let isGrayOut = false;
  return (
    <PrintProvider>
      <List
        empty={false}
        {...props}
        pagination={false}
        actions={<ListActions id={props.id} />}
      >
        <div id="divcontents">
          <Print single={true} name="foo">
            <Print single={true} name="foo">
              <div style={{ display: "flex", width: "100%" }}>
                <div
                  style={{
                    height: 150,
                    width: 150,
                    marginRight: 10,
                    marginLeft: 10,
                    marginTop: 30,
                  }}
                >
                  <img
                    src={ZaitonLogo}
                    alt=""
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
                <div style={{ width: "85%" }}>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "50%" }}>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            Name
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          placeholder="Name"
                          value={data.property && data.property.name}
                          type="text"
                          disabled={true}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            Zone
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          placeholder="Zone"
                          value={data.property && data.property.zone}
                          type="text"
                          disabled={true}
                        />
                      </InputGroup>
                    </div>
                    <div style={{ width: "50%", marginLeft: 20 }}>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            Area
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          placeholder="Area"
                          value={data.property && `${data.property.area}`}
                          type="text"
                          disabled={true}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            Street
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          placeholder="Street Width"
                          value={
                            data.property && `${data.property.streetWidth}m`
                          }
                          type="text"
                          disabled={true}
                        />
                        <FormControl
                          placeholder="Street Number"
                          value={data.property && data.property.streetNumber}
                          type="text"
                          disabled={true}
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "50%" }}>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            Full Name
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          placeholder="Full Name"
                          value={
                            data.customer &&
                            `${data.customer.firstName} ${data.customer.middleName} ${data.customer.lastName}`
                          }
                          type="text"
                          disabled={true}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            phone
                          </InputGroup.Text>
                        </InputGroup.Prepend>

                        <FormControl
                          placeholder="Phone"
                          value={data.customer && data.customer.phone}
                          type="text"
                          disabled={true}
                        />
                      </InputGroup>
                    </div>
                    <div style={{ width: "50%", marginLeft: 20 }}>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            Address
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          placeholder="Address"
                          value={data.customer && data.customer.address}
                          type="text"
                          disabled={true}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            phone
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          placeholder="phone"
                          value={data.customer && data.customer.phone2}
                          type="text"
                          disabled={true}
                        />
                      </InputGroup>
                    </div>
                  </div>
                </div>
              </div>
              <InputGroup
                className="mb-3"
                style={{ width: "99%", margin: "auto" }}
              >
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">
                    Contract Number
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  placeholder="Contract Number"
                  value={data.contractNumber}
                  type="text"
                />
                <ToggleButton
                  style={{ marginLeft: 5, marginBottom: 0 }}
                  type="checkbox"
                  checked={arabicInvoice}
                  onChange={() => setArabicInvoice(!arabicInvoice)}
                >
                  {" "}
                  Arabic Invoice
                </ToggleButton>
              </InputGroup>
            </Print>

            <div style={{ width: "99%", margin: "auto" }}>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">
                    Property Price
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  placeholder="Price"
                  value={data && data.buyingPrice && numberWithCommas(data.buyingPrice)}
                  // type="number"
                  disabled={true}
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">
                    No. of Installments
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  type="number"
                  disabled={true}
                  value={rows}
                  onChange={(e) => setRows(e.target.value)}
                />
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">
                    Period of Installments
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  type="number"
                  disabled={true}
                  value={periodOfInstallment}
                  onChange={(e) => setPeriodOfInstallment(e.target.value)}
                />
              </InputGroup>
            </div>
            <Table
              bordered
              hover
              style={{ width: "99%", margin: "auto", marginBottom: 15 }}
            >
              <thead>
                <tr>
                  <th>Installment</th>
                  <th>Price</th>
                  <th>Date</th>
                  <th className="noPrint">Is Paid</th>
                  <th className="noPrint">Action</th>
                </tr>
              </thead>
              <tbody>
                <td>Pre Payment</td>
                <td>
                  <InputGroup className="mb-3" style={{ marginRight: 5 }}>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">{data && data.prePaymentPrice && `$${numberWithCommas(data.prePaymentPrice)}`}</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      name="payPrice"
                      disabled={true}
                      value={prePaymentText}
                    />
                  </InputGroup>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>Note</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      as="textarea"
                      aria-label="Note"
                      disabled={data.isPrePaymentPayed || prePaid}
                      value={data.prePaymentNote}
                      id={`prePaymentNote`}
                      onChange={(e) => setPreNote(e.target.value)}
                    />
                  </InputGroup>
                </td>
                <td>
                  <InputGroup className="mb-3" style={{ marginLeft: 5 }}>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">date</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      type="date"
                      name="payDate"
                      disabled={true}
                      value={moment(data.buyingDate).format("YYYY-MM-DD")}
                    />
                  </InputGroup>
                </td>
                <td className="noPrint">
                  {(role !== 'Viewer') && <Button
                    style={{ marginLeft: 15 }}
                    disabled={data.isPrePaymentPayed || prePaid}
                    onClick={() => {
                      setOpenSmSm(true);

                      swal({
                        text: 'Please Enter your password.',
                        content: "input",
                        button: {
                          text: "Proceed!",
                          closeModal: false,
                        },
                      })
                      .then((pass) => {
                        axios
                          .post(process.env.REACT_APP_PATH + "/login", {
                            userName: localStorage.getItem('userName') ? localStorage.getItem('userName') : "",
                            password: pass
                          })
                          .then((resp) => {
                            if(resp.status >= 200 && resp.status < 300) {
                              swal({
                                title: "Are you sure?",
                                text: `An Installment of Property ${data.property.name} will be Paid, Attention: Paid Installments Cannot be Unpaid!`,
                                icon: "warning",
                                buttons: true,
                                // dangerMode: true,
                              })
                              .then((willDelete) => {
                                if (willDelete) {
                                  axios
                                  .post(
                                    `${process.env.REACT_APP_URL}/BuyContracts/PayPrePaid?buyContractId=${
                                      data.id
                                    }&note=${
                                      document.getElementById("prePaymentNote").value
                                    }&PrePaymentAccountant=${localStorage.getItem('name')}`
                                  )
                                  .then((res) => {
                                    if (res.status >= 200 && res.status < 300) {
                                      setPrePaid(true);
                                      swal("Payment Accomplished Successfully!", {
                                        icon: "success",
                                      });
                                    }
                                  })
                                  .catch((error) => {
                                    setOpenSmSm(false);
                                    swal("Couldn't Proceed Payment", {
                                      icon: 'error'
                                    });
                                  });
                                } else {
                                  swal("Payment Dismised!");
                                }
                              });
                            }
                          })
                          .catch(err => swal("Authentication Failed!", {
                            icon: 'error'
                          }))
                      })

                    }}
                  >
                    Pay
                  </Button>}
                </td>
                <td className="noPrint">
                  <iframe
                    id="ifmcontentstoprint"
                    style={{
                      height: 0,
                      width: 0,
                      position: "absolute",
                      display: "none",
                    }}
                  >
                    <div id={`cachFrame`}>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'darkgreen'}}>
                      <div style={{width: '42%', fontFamily: 'monospace'}}><p style={{letterSpacing: -1}}>Shajaratalzaiton Company For General Trading</p> <p style={{letterSpacing: -0.3}}>and Real Estate and Industial Investment</p></div>
                        <div style={{width: '20%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start'}}>
                          <div
                            style={{
                              height: 100,
                              width: 100,
                            }}
                          >
                            <img
                              src={ZaitonLogo}
                              alt=""
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>
                        </div>

                        <div style={{width: '30%'}}><p>شركة شجرة الزيتون للتجارة العامة والاستثمارات العقارية و الصناعية</p></div>
                      </div>
                      <div style={{display: 'flex', justifyContent: 'space-between', color: 'darkgreen', backgroundColor: 'rgba(143,188,143, 0.5)',marginTop: 5, height: 30, borderTopStyle: 'solid', borderTopColor: 'green', borderTopWidth: 2,borderBottomColor: 'green', borderBottomStyle: 'solid', borderBottomWidth: 2}}>
                        <p style={{margin: 3, padding: 3}}>Mob.:0750 300 5003 - 0750 300 5004</p>
                        <p style={{margin: 3, padding: 3}}>E-mail: zaiton.plus.city@gmail.com</p>
                      </div>
                      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <div>
                          <div style={{border: '1px solid darkgreen', width: 140}}><p style={{borderRight: '1px solid darkgreen', margin: 3, padding: 3, width: 30}}>دینار</p></div>
                          <div style={{border: '1px solid darkgreen', width: 140}}><p style={{borderRight: '1px solid darkgreen', margin: 3, padding: 3, width: 30}}>دۆلار</p></div>
                        </div>
                        <div style={{textAlign: 'center', lineHeight: 0.5}}>
                          <p>پسولەی وەرگرتن</p>
                          <p><u>Receipt Voucher</u></p>
                        </div>
                        <div style={{textAlign: 'right'}}>
                          <p><span style={{color: 'red'}}>z{data.id}</span> :ژمارەی پسوولە</p>
                          <p>{moment(data.buyingDate).format("YYYY-MM-DD")} :ڕێکەوت</p>
                        </div>
                      </div>
                      <div style={{lineHeight: 0.5}}>
                        <div style={{display: "flex", justifyContent: 'space-between'}}>
                          <p>Received From: {data.customer && `${data.customer.firstName} ${data.customer.middleName} ${data.customer.lastName}`}</p>
                          <p>:(وەرگیرا لە (استلمت من</p>
                        </div>
                        <div style={{display: "flex", justifyContent: 'space-between'}}>
                          <p>Amount: {`$${data.prePaymentPrice} - ${prePaymentText}`}</p>
                          <p>:(بڕی (المبلغ</p>
                        </div>
                        <div style={{display: "flex", justifyContent: 'space-between'}}>
                          <p>Purpose of receipt:</p>
                          <p>:(بە مەبەستی (و ذلك عن</p>
                        </div>
                        <div style={{display: "flex", justifyContent: 'space-between'}}>
                          <p>Note: {`${
                              document.getElementById("prePaymentNote") &&
                              document.getElementById("prePaymentNote").value
                            }`}</p>
                          <p>:(تێبینیی (الملاحظة</p>
                        </div>
                      </div>
                      <br />
                      <div style={{display: 'flex', justifyContent: 'space-around'}}>
                        <div style={{textAlign: 'center', lineHeight: 0.5}}>
                          <p>واژووی پێدەر</p>
                          <p>
                            {data.customer &&
                           `${data.customer.firstName} ${data.customer.middleName} ${data.customer.lastName}`}
                          </p>
                        </div>
                        <div style={{textAlign: 'center', lineHeight: 0.5}}>
                          <p>واژووی وەرگر</p>
                          <p>{data.prePaymentAccountant}</p>
                        </div>
                      </div>
                    </div>
                  </iframe>
                  <Button
                    disabled={!openSmSm}
                    style={{ marginLeft: 15 }}
                    onClick={() => {
                      var pri = document.getElementById("ifmcontentstoprint")
                        .contentWindow;
                      pri.document.open();
                      pri.document.write(
                        document.getElementById(`cachFrame`).innerHTML
                      );
                      pri.document.write(
                        "<hr />"
                      );
                      pri.document.write(
                        document.getElementById(`cachFrame`).innerHTML
                      );
                      pri.document.close();
                      pri.focus();
                      pri.print();
                    }}
                  >
                    print
                  </Button>
                </td>
                {data.paymentProcess &&
                  data.paymentProcess.map((el, key) => {
                    return (
                        <tr id={`rowNumber${key + 1}`}>
                          <td>{`${key + 1}`}<i className='payed'>{el.isPayed && ' - Payed'}</i></td>
                          <td colSpan="1">
                            <InputGroup
                                className="mb-3"
                                style={{ marginRight: 5 }}
                            >
                              <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">
                                  {el.currentPayPrice && `$${numberWithCommas(el.currentPayPrice)}`}
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                  name="payPrice"
                                  disabled={true}
                                  value={!arabicInvoice ? el.kuPriceText : el.arPriceText}
                              />
                            </InputGroup>
                            <InputGroup style={{ display: isGrayOut && "none" }}>
                              <InputGroup.Prepend>
                                <InputGroup.Text>Note</InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                  as="textarea"
                                  aria-label="Note"
                                  disabled={el.isPayed}
                                  value={el.note}
                                  id={`note${key}`}
                                  onChange={(e) => setNote(e.target.value)}
                              />
                            </InputGroup>
                            <hr style={{ display: isGrayOut && "none" }}/>
                            <InputGroup
                                style={{
                                  display: isGrayOut && "none",
                                  marginTop: 15,
                                }}
                                className="mb-3"
                            >
                              <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">
                                  Partial Payment Price
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                  placeholder="Partial Payment Price"
                                  type="number"
                                  disabled={el.isPayed || el.partialPrice > 0}
                                  id={`partialPrice${key}`}
                                  value={el.partialPrice}
                                  // onChange={(e) => setExtraPrice(e.target.value)}
                              />
                            </InputGroup>
                            <InputGroup style={{ display: isGrayOut && "none" }}>
                              <InputGroup.Prepend>
                                <InputGroup.Text>
                                  Partial Payment Note
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                  as="textarea"
                                  aria-label="Note"
                                  disabled={el.isPayed || el.partialPrice > 0}
                                  value={el.partialNote}
                                  id={`partialNote${key}`}
                                  // onChange={(e) => setExtraNote(e.target.value)}
                              />
                            </InputGroup>
                            <InputGroup
                                className="mb-3"
                                style={{ marginTop: 15,display: isGrayOut && "none" }}
                            >
                              <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">
                                  Partial Deadline
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                  type="date"
                                  disabled={el.isPayed || el.partialPrice > 0}
                                  name="payDate"
                                  id={`partialDeadline${key}`}
                                  defaultValue={moment(el.partialDeadline).format(
                                      "YYYY-MM-DD"
                                  )}
                              />
                            </InputGroup>
                          </td>
                          <td>
                            <InputGroup
                                className="mb-3"
                                // style={{ marginLeft: 5 }}
                            >
                              <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">
                                  Date
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                  type="date"
                                  name="payDate"
                                  disabled={true}
                                  value={moment(el.currentPayDate).format(
                                      "YYYY-MM-DD"
                                  )}
                              />
                            </InputGroup>
                            <InputGroup
                                style={{ display: isGrayOut && "none" }}
                                className="mb-3"
                            >
                              <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">
                                  Extra Price
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                  placeholder="Extra Price"
                                  type="number"
                                  disabled={el.isPayed}
                                  id={`extraPrice${key}`}
                                  value={el.extraPrice}
                                  onChange={(e) => setExtraPrice(e.target.value)}
                              />
                            </InputGroup>
                            <InputGroup style={{ display: isGrayOut && "none" }}>
                              <InputGroup.Prepend>
                                <InputGroup.Text>Note</InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                  as="textarea"
                                  aria-label="Note"
                                  disabled={el.isPayed}
                                  value={el.extraNote}
                                  id={`extraNote${key}`}
                                  onChange={(e) => setExtraNote(e.target.value)}
                              />
                            </InputGroup>

                            <InputGroup
                                className="mb-3"
                                style={{
                                  marginRight: 5,
                                  marginTop: 15,
                                  display: isGrayOut && "none",
                                }}
                            >
                              <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">
                                  Paid By
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                  id={`paidBy${key}`}
                                  disabled={el.isPayed}
                                  defaultValue={`${data.customer.firstName} ${data.customer.middleName} ${data.customer.lastName}`}
                                  value={el.payedBy}
                              />
                            </InputGroup>
                          </td>
                          <td className="noPrint">
                            {el.isPayed ? (
                                <InputGroup
                                    style={{ width: 100, margin: 0 }}
                                    className="mb-3"
                                >
                                  <InputGroup.Prepend>
                                    <InputGroup.Checkbox
                                        style={{ width: 20 }}
                                        aria-label="Checkbox for following text input"
                                        //   value={buyContract.isPayed}
                                        defaultChecked={el.isPayed}
                                    />
                                  </InputGroup.Prepend>
                                  <FormControl
                                      style={{ padding: 10 }}
                                      aria-label="Text input with checkbox"
                                      value={el.isPayed && "Paid"}
                                  />
                                </InputGroup>
                            ) : (
                                <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                >
                                  {(role !== 'Viewer') && <Button
                                      style={{ marginLeft: 15 }}
                                      disabled={isGrayOut}
                                      onClick={() => {
                                        swal({
                                          text: 'Please Enter your password.',
                                          content: "input",
                                          button: {
                                            text: "Proceed!",
                                            closeModal: false,
                                          },
                                        })
                                            .then((pass) => {
                                              axios
                                                  .post(process.env.REACT_APP_PATH + "/login", {
                                                    // userName: 'adnan',
                                                    userName: localStorage.getItem('userName') ? localStorage.getItem('userName') : "",
                                                    password: pass
                                                  })
                                                  .then((resp) => {
                                                    if(resp.status >= 200 && resp.status < 300) {
                                                      swal({
                                                        title: "Are you sure?",
                                                        text: `An Installment of Property ${data.property.name} will be Paid with a price of ${el.currentPayPrice}, Attention: Paid Installments Cannot be Unpaid!`,
                                                        icon: "warning",
                                                        buttons: true,
                                                        // dangerMode: true,
                                                      })
                                                          .then((willDelete) => {
                                                            if (willDelete) {
                                                              axios
                                                                  .put(
                                                                      `${process.env.REACT_APP_URL}/PaymentProcesses/${el.id}`,
                                                                      {
                                                                        id: el.id,
                                                                        buyContractId: el.buyContractId,
                                                                        currentPayDate: el.currentPayDate,
                                                                        currentPayPrice: el.currentPayPrice,
                                                                        kuPriceText: el.kuPriceText,
                                                                        arPriceText: el.arPriceText,
                                                                        nextPayDate: el.nextPayDate,
                                                                        nextPayPrice: el.nextPayPrice,
                                                                        note: document.getElementById(
                                                                            `note${key}`
                                                                        ).value,
                                                                        attachment: el.attachment,
                                                                        isPayed: true,
                                                                        isItKeyPayment: el.isItKeyPayment,
                                                                        extraPrice: document.getElementById(
                                                                            `extraPrice${key}`
                                                                        ).value,
                                                                        extraNote,
                                                                        payedBy: document.getElementById(
                                                                            `paidBy${key}`
                                                                        ).value,
                                                                        partialDate: el.partialDate,
                                                                        accountant: localStorage.getItem('name'),
                                                                        partialDeadline: el.partialDeadline,
                                                                        partialNote: el.partialNote,
                                                                        partialPrice: el.partialPrice,
                                                                        isPartialPayed: el.isPartialPayed
                                                                      }
                                                                  )
                                                                  .then((resp) => {
                                                                    axios
                                                                        .get(
                                                                            `${process.env.REACT_APP_URL}/BuyContracts/${props.id}`
                                                                        )
                                                                        .then((resp) => {
                                                                          swal("Payment Accomplished Successfully!", {
                                                                            icon: "success",
                                                                          }).then(() => {
                                                                            window.location.reload();
                                                                          });
                                                                        });
                                                                  });
                                                            } else {
                                                              swal("Payment Dismised!");
                                                            }
                                                          });

                                                    }
                                                  })
                                                  .catch(err => swal("Authentication Failed!", {
                                                    icon: 'error'
                                                  }))
                                            })
                                      }}
                                  >
                                    Pay
                                  </Button>}
                                  <Button
                                      variant="warning"
                                      style={{
                                        marginLeft: 15,
                                        color: "white",
                                        marginTop: 15,
                                      }}
                                      disabled={el.isPartialPayed || isGrayOut}
                                      onClick={() => {
                                        swal({
                                          title: "Are you sure?",
                                          text: "Payments Cannot be Unpaid!",
                                          icon: "warning",
                                          buttons: true,
                                        })
                                            .then((willDelete) => {
                                              if (willDelete) {
                                                axios
                                                    .put(
                                                        `${process.env.REACT_APP_URL}/PaymentProcesses/${el.id}`,
                                                        {
                                                          id: el.id,
                                                          buyContractId: el.buyContractId,
                                                          currentPayDate: el.currentPayDate,
                                                          currentPayPrice: el.currentPayPrice,
                                                          kuPriceText: el.kuPriceText,
                                                          arPriceText: el.arPriceText,
                                                          nextPayDate: el.nextPayDate,
                                                          nextPayPrice: el.nextPayPrice,
                                                          note: document.getElementById(
                                                              `note${key}`
                                                          ).value,
                                                          attachment: el.attachment,
                                                          isPayed: false,
                                                          isItKeyPayment: el.isItKeyPayment,
                                                          extraPrice: document.getElementById(
                                                              `extraPrice${key}`
                                                          ).value,
                                                          extraNote,
                                                          payedBy: document.getElementById(
                                                              `paidBy${key}`
                                                          ).value,
                                                          partialNote: document.getElementById(
                                                              `partialNote${key}`
                                                          ).value,
                                                          partialPrice: document.getElementById(
                                                              `partialPrice${key}`
                                                          ).value,
                                                          isPartialPayed: true,
                                                          partialDeadline: document.getElementById(
                                                              `partialDeadline${key}`
                                                          ).value,
                                                          partialDate: moment().format('YYYY-MM-DD')
                                                        }
                                                    )
                                                    .then((resp) => {
                                                      axios
                                                          .get(
                                                              `${process.env.REACT_APP_URL}/BuyContracts/${props.id}`
                                                          )
                                                          .then((resp) => {
                                                            setData(resp.data);
                                                          });
                                                      // .then((resp) => console.log(resp));
                                                    });
                                              } else {
                                                swal("Payment Dismised!");
                                              }
                                            });
                                      }}
                                  >
                                    Partial Pay
                                  </Button>
                                </div>
                            )}
                          </td>
                          <td className="noPrint">
                            <iframe
                                id="ifmcontentstoprint"
                                style={{
                                  height: 0,
                                  width: 0,
                                  position: "absolute",
                                  display: "none",
                                }}
                            >
                              <div id={`iframe${key}`}>
                                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'darkgreen'}}>
                                  <div style={{width: '42%', fontFamily: 'monospace'}}><p style={{letterSpacing: -1}}>Shajaratalzaiton Company For General Trading</p> <p style={{letterSpacing: -0.3}}>and Real Estate and Industial Investment</p></div>
                                  <div style={{width: '20%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start'}}>
                                    <div
                                        style={{
                                          height: 100,
                                          width: 100
                                        }}
                                    >
                                      <img
                                          src={ZaitonLogo}
                                          alt=""
                                          style={{ width: "100%", height: "100%" }}
                                      />
                                    </div>
                                  </div>

                                  <div style={{width: '30%'}}><p>شركة شجرة الزيتون للتجارة العامة والاستثمارات العقارية و الصناعية</p></div>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'space-between', color: 'darkgreen', backgroundColor: 'rgba(143,188,143, 0.5)',marginTop: 5, height: 30, borderTopStyle: 'solid', borderTopColor: 'green', borderTopWidth: 2,borderBottomColor: 'green', borderBottomStyle: 'solid', borderBottomWidth: 2}}>
                                  <p style={{margin: 3, padding: 3}}>Mob.:0750 300 5003 - 0750 300 5004</p>
                                  <p style={{margin: 3, padding: 3}}>E-mail: zaiton.plus.city@gmail.com</p>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                  <div>
                                    <div style={{border: '1px solid darkgreen', width: 140}}><p style={{borderRight: '1px solid darkgreen', margin: 3, padding: 3, width: 30}}>دینار</p></div>
                                    <div style={{border: '1px solid darkgreen', width: 140}}><p style={{borderRight: '1px solid darkgreen', margin: 3, padding: 3, width: 30}}>دۆلار</p></div>
                                  </div>
                                  <div style={{textAlign: 'center', lineHeight: 0.5}}>
                                    <p>پسولەی وەرگرتن</p>
                                    <p><u>Receipt Voucher</u></p>
                                  </div>
                                  <div style={{textAlign: 'right'}}>
                                    <p><span style={{color: 'red'}}>{el.id}</span> :ژمارەی پسوولە</p>
                                    <p>{moment(el.payDate).format("YYYY-MM-DD")} :ڕێکەوت</p>
                                  </div>
                                </div>
                                <div style={{lineHeight: 0.5}}>
                                  <div style={{display: "flex", justifyContent: 'space-between'}}>
                                    <p>Received From: {`${data.customer.firstName} ${data.customer.middleName} ${data.customer.lastName}`}</p>
                                    <p>:(وەرگیرا لە (استلمت من</p>
                                  </div>
                                  <div style={{display: "flex", justifyContent: 'space-between'}}>
                                    <p>Amount: {` $${el.currentPayPrice} - ${
                                        arabicInvoice
                                            ? el.arPriceText
                                            : el.kuPriceText
                                    }`}</p>
                                    <p>:(بڕی (المبلغ</p>
                                  </div>
                                  <div style={{display: "flex", justifyContent: 'space-between'}}>
                                    <p>Purpose of receipt:</p>
                                    <p>:(بە مەبەستی (و ذلك عن</p>
                                  </div>
                                  <div style={{display: "flex", justifyContent: 'space-between'}}>
                                    <p>Note: {document.getElementById(`note${key}`) &&
                                    document.getElementById(`note${key}`).value}</p>
                                    <p>:(تێبینیی (الملاحظة</p>
                                  </div>
                                </div>
                                <br />
                                <div style={{display: 'flex', justifyContent: 'space-around'}}>
                                  <div style={{textAlign: 'center', lineHeight: 0.5}}>
                                    <p>واژووی پێدەر</p>
                                    <p>
                                      {data.customer &&
                                      `${data.customer.firstName} ${data.customer.middleName} ${data.customer.lastName}`}
                                    </p>
                                  </div>
                                  <div style={{textAlign: 'center', lineHeight: 0.5}}>
                                    <p>واژووی وەرگر</p>
                                    <p>{el.accountant}</p>
                                  </div>
                                </div>
                              </div>
                              <div
                                  id={`partialIframe${key}`}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    margin: 40,
                                  }}
                              >
                                <h3>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Zaiton Plus City
                                </h3>
                                <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginBottom: 30,
                                    }}
                                >
                                  <div style={{ width: 100, height: 100 }}>
                                    <img
                                        style={{ width: "100%", height: "100%" }}
                                        src={ZaitonLogo}
                                       alt={`as`}/>
                                  </div>
                                  <div style={{ marginLeft: 20, width: 190 }}>
                                    <p style={{ padding: 0 }}>
                                      Email: {data.customer.email}
                                    </p>
                                    <p>Phone: {data.customer.phone}</p>
                                    <p>Description:</p>
                                  </div>
                                  <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginLeft: "25%",
                                      }}
                                  >
                                    <div style={{}}>Invoice: P{el.id} - {data.property.name}</div>
                                    <div>
                                      Date:{" "}
                                      {moment(el.payDate).format("YYYY-MM-DD")}
                                    </div>
                                    <div>
                                      {moment(el.nextPayDate).diff(
                                          moment([2007, 0, 29])
                                      ) > 0 &&
                                      `Next Payment Date: 
                                        ${moment(el.nextPayDate).format(
                                          "YYYY-MM-DD"
                                      )}`}
                                    </div>
                                    <div>
                                      Customer:{" "}
                                      {data.customer &&
                                      `${data.customer.firstName} ${data.customer.middleName} ${data.customer.lastName}`}
                                    </div>
                                  </div>
                                </div>
                                <p>
                                  Received From:{" "}
                                  {`${data.customer.firstName} ${data.customer.middleName} ${data.customer.lastName}`}
                                </p>
                                <p style={{color: 'red'}}>
                                  {" "}
                                  Partial Price:
                                  {` $${el.partialPrice} ($${el.currentPayPrice - el.partialPrice} Remains)`}
                                </p>
                                <p>
                                  Payment Deadline:{" "}
                                  {moment(el.partialDeadline).format('YYYY-MM-DD')}
                                </p>
                                <p>
                                  Partial Note:{" "}
                                  {document.getElementById(`partialNote${key}`) &&
                                  document.getElementById(`partialNote${key}`).value}
                                </p>

                                <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-around",
                                      marginTop: 40,
                                      alignItems: "center",
                                    }}
                                >
                                  <div style={{ textAlign: "center" }}>
                                    <p style={{ padding: 0, margin: 0 }}>
                                      Accountant
                                    </p>
                                    <p style={{ padding: 10, margin: 0 }}>
                                      {/* {el.accountant} */}
                                    </p>
                                  </div>
                                  <div style={{ textAlign: "center" }}>
                                    <p>Paid By</p>
                                    <p>{el.payedBy}</p>
                                  </div>
                                </div>
                              </div>
                            </iframe>
                            <Button
                                disabled={isGrayOut || !el.isPayed}
                                onClick={() => {
                                  var pri = document.getElementById(
                                      "ifmcontentstoprint"
                                  ).contentWindow;
                                  pri.document.open();
                                  pri.document.write(
                                      document.getElementById(`iframe${key}`)
                                          .innerHTML
                                  );
                                  pri.document.write("<hr />");
                                  pri.document.write(
                                      document.getElementById(`iframe${key}`)
                                          .innerHTML
                                  );
                                  // pri.document.write(content.innerHTML);
                                  pri.document.close();
                                  pri.focus();
                                  pri.print();
                                }}
                            >
                              print
                            </Button>
                            <Button
                                style={{ color: 'white', marginTop: 15}}
                                variant="warning"
                                disabled={isGrayOut || !el.isPartialPayed}
                                onClick={() => {
                                  // var content = document.getElementById(`rowNumber${key+1}`);
                                  var pri = document.getElementById(
                                      "ifmcontentstoprint"
                                  ).contentWindow;
                                  pri.document.open();
                                  pri.document.write(
                                      document.getElementById(`partialIframe${key}`)
                                          .innerHTML
                                  );
                                  pri.document.write("<br /><br /><hr /><br />");
                                  pri.document.write(
                                      document.getElementById(`partialIframe${key}`)
                                          .innerHTML
                                  );
                                  // pri.document.write(content.innerHTML);
                                  pri.document.close();
                                  pri.focus();
                                  pri.print();
                                }}
                            >
                              Print Partial Payment
                            </Button>

                            {!el.isPayed ? (isGrayOut = true) : null}
                          </td>
                        </tr>
                    );
                  })}
                {isItPayment &&
                <tr id={`rowNumber${isItPayment.id}`}>
                  <td>Structure<i className='payed'>{isItPayment.isPayed && ' - Payed'}</i></td>
                  <td colSpan="1">
                    <InputGroup
                        className="mb-3"
                        style={{ marginRight: 5 }}
                    >
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          {isItPayment.currentPayPrice && `$${numberWithCommas(isItPayment.currentPayPrice)}`}
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                          name="payPrice"
                          disabled={true}
                          value={!arabicInvoice ? isItPayment.kuPriceText : isItPayment.arPriceText}
                      />
                    </InputGroup>
                    <InputGroup style={{ display: isGrayOut && "none" }}>
                      <InputGroup.Prepend>
                        <InputGroup.Text>Note</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                          as="textarea"
                          aria-label="Note"
                          disabled={isItPayment.isPayed}
                          value={isItPayment.note}
                          id={`note${isItPayment.id}`}
                          onChange={(e) => setNote(e.target.value)}
                      />
                    </InputGroup>
                    <hr style={{ display: isGrayOut && "none" }}/>
                    <InputGroup
                        style={{
                          display: isGrayOut && "none",
                          marginTop: 15,
                        }}
                        className="mb-3"
                    >
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          Partial Payment Price
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                          placeholder="Partial Payment Price"
                          type="number"
                          disabled={isItPayment.isPayed || isItPayment.partialPrice > 0}
                          id={`partialPrice${isItPayment.id}`}
                          value={isItPayment.partialPrice}
                      />
                    </InputGroup>
                    <InputGroup style={{ display: isGrayOut && "none" }}>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          Partial Payment Note
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                          as="textarea"
                          aria-label="Note"
                          disabled={isItPayment.isPayed || isItPayment.partialPrice > 0}
                          value={isItPayment.partialNote}
                          id={`partialNote${isItPayment.id}`}
                      />
                    </InputGroup>
                    <InputGroup
                        className="mb-3"
                        style={{ marginTop: 15,display: isGrayOut && "none" }}
                    >
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          Partial Deadline
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                          type="date"
                          disabled={isItPayment.isPayed || isItPayment.partialPrice > 0}
                          name="payDate"
                          id={`partialDeadline${isItPayment.id}`}
                          defaultValue={moment(isItPayment.partialDeadline).format(
                              "YYYY-MM-DD"
                          )}
                      />
                    </InputGroup>
                  </td>
                  <td>
                    <InputGroup
                        className="mb-3"
                    >
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1" onClick= {(e) => setChangeStructureDate(false)}>
                          Date
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                          type="date"
                          name="payDate"
                          disabled={changeStructureDate}
                          onBlur={(e) => {
                            axios
                                .post(`${process.env.REACT_APP_URL}/BuyContracts/UpdatePayDate?processId=${isItPayment.id}&newDate=${e.target.value}`, {})
                                .then((resp) => {
                                  swal("Date Update!", {
                                    icon: 'success'
                                  })
                                })
                                .catch(err => swal("Failed To Update!", {
                                  icon: 'error'
                                }))
                          }}
                          defaultValue={moment(isItPayment.currentPayDate).add(periodOfInstallment * installments, 'M').format("YYYY-MM-DD")}
                      />
                    </InputGroup>
                    <InputGroup
                        style={{ display: isGrayOut && "none" }}
                        className="mb-3"
                    >
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          Extra Price
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                          placeholder="Extra Price"
                          type="number"
                          disabled={isItPayment.isPayed}
                          id={`extraPrice${isItPayment.id}`}
                          value={isItPayment.extraPrice}
                          onChange={(e) => setExtraPrice(e.target.value)}
                      />
                    </InputGroup>
                    <InputGroup style={{ display: isGrayOut && "none" }}>
                      <InputGroup.Prepend>
                        <InputGroup.Text>Note</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                          as="textarea"
                          aria-label="Note"
                          disabled={isItPayment.isPayed}
                          value={isItPayment.extraNote}
                          id={`extraNote${isItPayment.id}`}
                          onChange={(e) => setExtraNote(e.target.value)}
                      />
                    </InputGroup>

                    <InputGroup
                        className="mb-3"
                        style={{
                          marginRight: 5,
                          marginTop: 15,
                          display: isGrayOut && "none",
                        }}
                    >
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          Paid By
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                          id={`paidBy${isItPayment.id}`}
                          disabled={isItPayment.isPayed}
                          defaultValue={`${data.customer.firstName} ${data.customer.middleName} ${data.customer.lastName}`}
                          value={isItPayment.payedBy}
                      />
                    </InputGroup>
                  </td>
                  <td className="noPrint">
                    {isItPayment.isPayed ? (
                        <InputGroup
                            style={{ width: 100, margin: 0 }}
                            className="mb-3"
                        >
                          <InputGroup.Prepend>
                            <InputGroup.Checkbox
                                style={{ width: 20 }}
                                aria-label="Checkbox for following text input"
                                defaultChecked={isItPayment.isPayed}
                            />
                          </InputGroup.Prepend>
                          <FormControl
                              style={{ padding: 10 }}
                              aria-label="Text input with checkbox"
                              value={isItPayment.isPayed && "Paid"}
                          />
                        </InputGroup>
                    ) : (
                        <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                        >
                          {(role !== 'Viewer') && <Button
                              style={{ marginLeft: 15 }}
                              onClick={() => {
                                swal({
                                  text: 'Please Enter your password.',
                                  content: "input",
                                  button: {
                                    text: "Proceed!",
                                    closeModal: false,
                                  },
                                })
                                    .then((pass) => {
                                      axios
                                          .post(process.env.REACT_APP_PATH + "/login", {
                                            userName: localStorage.getItem('userName') ? localStorage.getItem('userName') : "",
                                            password: pass
                                          })
                                          .then((resp) => {
                                            if(resp.status >= 200 && resp.status < 300) {
                                              swal({
                                                title: "Are you sure?",
                                                text: `An Installment of Property ${data.property.name} will be Paid with a price of ${isItPayment.currentPayPrice}, Attention: Paid Installments Cannot be Unpaid!`,
                                                icon: "warning",
                                                buttons: true,
                                              })
                                                  .then((willDelete) => {
                                                    if (willDelete) {
                                                      axios
                                                          .put(
                                                              `${process.env.REACT_APP_URL}/PaymentProcesses/${isItPayment.id}`,
                                                              {
                                                                id: isItPayment.id,
                                                                buyContractId: isItPayment.buyContractId,
                                                                currentPayDate: isItPayment.currentPayDate,
                                                                currentPayPrice: isItPayment.currentPayPrice,
                                                                kuPriceText: isItPayment.kuPriceText,
                                                                arPriceText: isItPayment.arPriceText,
                                                                nextPayDate: isItPayment.nextPayDate,
                                                                nextPayPrice: isItPayment.nextPayPrice,
                                                                note: document.getElementById(
                                                                    `note${isItPayment.id}`
                                                                ).value,
                                                                attachment: isItPayment.attachment,
                                                                isPayed: true,
                                                                isItKeyPayment: isItPayment.isItKeyPayment,
                                                                isItPrePayment: isItPayment.isItPrePayment,
                                                                extraPrice: document.getElementById(
                                                                    `extraPrice${isItPayment.id}`
                                                                ).value,
                                                                extraNote,
                                                                payedBy: document.getElementById(
                                                                    `paidBy${isItPayment.id}`
                                                                ).value,
                                                                partialDate: isItPayment.partialDate,
                                                                accountant: localStorage.getItem('name'),
                                                                partialDeadline: isItPayment.partialDeadline,
                                                                partialNote: isItPayment.partialNote,
                                                                partialPrice: isItPayment.partialPrice,
                                                                isPartialPayed: isItPayment.isPartialPayed
                                                              }
                                                          )
                                                          .then((resp) => {
                                                            axios
                                                                .get(
                                                                    `${process.env.REACT_APP_URL}/BuyContracts/${props.id}`
                                                                )
                                                                .then((resp) => {
                                                                  swal("Payment Accomplished Successfully!", {
                                                                    icon: "success",
                                                                  }).then(() => {
                                                                    window.location.reload();
                                                                  });
                                                                });
                                                          });
                                                    } else {
                                                      swal("Payment Dismissed!");
                                                    }
                                                  });

                                            }
                                          })
                                          .catch(err => swal("Authentication Failed!", {
                                            icon: 'error'
                                          }))
                                    })
                              }}
                          >
                            Pay
                          </Button>}
                        </div>
                    )}
                  </td>
                  <td className="noPrint">
                    <iframe
                        id="ifmcontentstoprint"
                        style={{
                          height: 0,
                          width: 0,
                          position: "absolute",
                          display: "none",
                        }}
                    >
                      <div id={`iframe${isItPayment.id}`}>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'darkgreen'}}>
                          <div style={{width: '42%', fontFamily: 'monospace'}}><p style={{letterSpacing: -1}}>Shajaratalzaiton Company For General Trading</p> <p style={{letterSpacing: -0.3}}>and Real Estate and Industial Investment</p></div>
                          <div style={{width: '20%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start'}}>
                            <div
                                style={{
                                  height: 100,
                                  width: 100
                                }}
                            >
                              <img
                                  src={ZaitonLogo}
                                  alt=""
                                  style={{ width: "100%", height: "100%" }}
                              />
                            </div>
                          </div>

                          <div style={{width: '30%'}}><p>شركة شجرة الزيتون للتجارة العامة والاستثمارات العقارية و الصناعية</p></div>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', color: 'darkgreen', backgroundColor: 'rgba(143,188,143, 0.5)',marginTop: 5, height: 30, borderTopStyle: 'solid', borderTopColor: 'green', borderTopWidth: 2,borderBottomColor: 'green', borderBottomStyle: 'solid', borderBottomWidth: 2}}>
                          <p style={{margin: 3, padding: 3}}>Mob.:0750 300 5003 - 0750 300 5004</p>
                          <p style={{margin: 3, padding: 3}}>E-mail: zaiton.plus.city@gmail.com</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                          <div>
                            <div style={{border: '1px solid darkgreen', width: 140}}><p style={{borderRight: '1px solid darkgreen', margin: 3, padding: 3, width: 30}}>دینار</p></div>
                            <div style={{border: '1px solid darkgreen', width: 140}}><p style={{borderRight: '1px solid darkgreen', margin: 3, padding: 3, width: 30}}>دۆلار</p></div>
                          </div>
                          <div style={{textAlign: 'center', lineHeight: 0.5}}>
                            <p>پسولەی وەرگرتن</p>
                            <p><u>Receipt Voucher</u></p>
                          </div>
                          <div style={{textAlign: 'right'}}>
                            <p><span style={{color: 'red'}}>{isItPayment.id}</span> :ژمارەی پسوولە</p>
                            <p>{moment(isItPayment.payDate).format("YYYY-MM-DD")} :ڕێکەوت</p>
                          </div>
                        </div>
                        <div style={{lineHeight: 0.5}}>
                          <div style={{display: "flex", justifyContent: 'space-between'}}>
                            <p>Received From: {`${data.customer.firstName} ${data.customer.middleName} ${data.customer.lastName}`}</p>
                            <p>:(وەرگیرا لە (استلمت من</p>
                          </div>
                          <div style={{display: "flex", justifyContent: 'space-between'}}>
                            <p>Amount: {` $${isItPayment.currentPayPrice} - ${
                                arabicInvoice
                                    ? isItPayment.arPriceText
                                    : isItPayment.kuPriceText
                            }`}</p>
                            <p>:(بڕی (المبلغ</p>
                          </div>
                          <div style={{display: "flex", justifyContent: 'space-between'}}>
                            <p>Purpose of receipt:</p>
                            <p>:(بە مەبەستی (و ذلك عن</p>
                          </div>
                          <div style={{display: "flex", justifyContent: 'space-between'}}>
                            <p>Note: {document.getElementById(`note${isItPayment.id}`) &&
                            document.getElementById(`note${isItPayment.id}`).value}</p>
                            <p>:(تێبینیی (الملاحظة</p>
                          </div>
                        </div>
                        <br />
                        <div style={{display: 'flex', justifyContent: 'space-around'}}>
                          <div style={{textAlign: 'center', lineHeight: 0.5}}>
                            <p>واژووی پێدەر</p>
                            <p>
                              {data.customer &&
                              `${data.customer.firstName} ${data.customer.middleName} ${data.customer.lastName}`}
                            </p>
                          </div>
                          <div style={{textAlign: 'center', lineHeight: 0.5}}>
                            <p>واژووی وەرگر</p>
                            <p>{isItPayment.accountant}</p>
                          </div>
                        </div>
                      </div>
                      <div
                          id={`partialIframe${isItPayment.id}`}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            margin: 40,
                          }}
                      >
                        <h3>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Zaiton Plus City
                        </h3>
                        <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: 30,
                            }}
                        >
                          <div style={{ width: 100, height: 100 }}>
                            <img
                                style={{ width: "100%", height: "100%" }}
                                src={ZaitonLogo}
                                alt={`as`}/>
                          </div>
                          <div style={{ marginLeft: 20, width: 190 }}>
                            <p style={{ padding: 0 }}>
                              Email: {data.customer.email}
                            </p>
                            <p>Phone: {data.customer.phone}</p>
                            <p>Description:</p>
                          </div>
                          <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginLeft: "25%",
                              }}
                          >
                            <div style={{}}>Invoice: P{isItPayment.id} - {data.property.name}</div>
                            <div>
                              Date:{" "}
                              {moment(isItPayment.payDate).format("YYYY-MM-DD")}
                            </div>
                            <div>
                              {moment(isItPayment.nextPayDate).diff(
                                  moment([2007, 0, 29])
                              ) > 0 &&
                              `Next Payment Date: 
                                        ${moment(isItPayment.nextPayDate).format(
                                  "YYYY-MM-DD"
                              )}`}
                            </div>
                            <div>
                              Customer:{" "}
                              {data.customer &&
                              `${data.customer.firstName} ${data.customer.middleName} ${data.customer.lastName}`}
                            </div>
                          </div>
                        </div>
                        <p>
                          Received From:{" "}
                          {`${data.customer.firstName} ${data.customer.middleName} ${data.customer.lastName}`}
                        </p>
                        <p style={{color: 'red'}}>
                          {" "}
                          Partial Price:
                          {` $${isItPayment.partialPrice} ($${isItPayment.currentPayPrice - isItPayment.partialPrice} Remains)`}
                        </p>
                        <p>
                          Payment Deadline:{" "}
                          {moment(isItPayment.partialDeadline).format('YYYY-MM-DD')}
                        </p>
                        <p>
                          Partial Note:{" "}
                          {document.getElementById(`partialNote${isItPayment.id}`) &&
                          document.getElementById(`partialNote${isItPayment.id}`).value}
                        </p>

                        <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              marginTop: 40,
                              alignItems: "center",
                            }}
                        >
                          <div style={{ textAlign: "center" }}>
                            <p style={{ padding: 0, margin: 0 }}>
                              Accountant
                            </p>
                            <p style={{ padding: 10, margin: 0 }}>
                              {/* {isItPayment.accountant} */}
                            </p>
                          </div>
                          <div style={{ textAlign: "center" }}>
                            <p>Paid By</p>
                            <p>{isItPayment.payedBy}</p>
                          </div>
                        </div>
                      </div>
                    </iframe>
                    <Button
                        disabled={isGrayOut || !isItPayment.isPayed}
                        onClick={() => {
                          var pri = document.getElementById(
                              "ifmcontentstoprint"
                          ).contentWindow;
                          pri.document.open();
                          pri.document.write(
                              document.getElementById(`iframe${isItPayment.id}`)
                                  .innerHTML
                          );
                          pri.document.write("<hr />");
                          pri.document.write(
                              document.getElementById(`iframe${isItPayment.id}`)
                                  .innerHTML
                          );
                          pri.document.close();
                          pri.focus();
                          pri.print();
                        }}
                    >
                      print
                    </Button>
                    <Button
                        style={{ color: 'white', marginTop: 15}}
                        variant="warning"
                        disabled={isGrayOut || !isItPayment.isPartialPayed}
                        onClick={() => {
                          var pri = document.getElementById(
                              "ifmcontentstoprint"
                          ).contentWindow;
                          pri.document.open();
                          pri.document.write(
                              document.getElementById(`partialIframe${isItPayment.id}`)
                                  .innerHTML
                          );
                          pri.document.write("<br /><br /><hr /><br />");
                          pri.document.write(
                              document.getElementById(`partialIframe${isItPayment.id}`)
                                  .innerHTML
                          );
                          // pri.document.write(content.innerHTML);
                          pri.document.close();
                          pri.focus();
                          pri.print();
                        }}
                    >
                      Print Partial Payment
                    </Button>

                    {!isItPayment.isPayed ? (isGrayOut = true) : null}
                  </td>
                </tr>}
                {isItKey &&
                <tr id={`rowNumber${isItKey.id}`}>
                  <td>Delivering Keys</td>
                  <td colSpan="1">
                    <InputGroup
                        className="mb-3"
                        style={{ marginRight: 5 }}
                    >
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          {isItKey.currentPayPrice && `$${numberWithCommas(isItKey.currentPayPrice)}`}
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                          // type="number"
                          name="payPrice"
                          disabled={true}
                          value={!arabicInvoice ? isItKey.kuPriceText : isItKey.arPriceText}
                      />
                    </InputGroup>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>Note</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                          as="textarea"
                          aria-label="Note"
                          disabled={isItKey.isPayed}
                          value={isItKey.note}
                          id={`note${isItKey.id}`}
                          onChange={(e) => setNote(e.target.value)}
                      />
                    </InputGroup>
                  </td>
                  <td>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          Extra Price
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                          placeholder="Extra Price"
                          type="number"
                          disabled={isItKey.isPayed}
                          id={`extraPrice${isItKey.id}`}
                          value={isItKey.extraPrice}
                          onChange={(e) => setExtraPrice(e.target.value)}
                      />
                    </InputGroup>
                    <InputGroup
                        className="mb-3"
                    >
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1" onClick= {(e) => setChangeKeyDate(false)}>
                          Date
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                          type="date"
                          name="payDate"
                          disabled={changeKeyDate}
                          onChange={(e) => {
                            axios
                                .post(`${process.env.REACT_APP_URL}/BuyContracts/UpdatePayDate?processId=${isItKey.id}&newDate=${e.target.value}`, {})
                                .then((resp) => {
                                  swal("Date Update!", {
                                    icon: 'success'
                                  })
                                })
                                .catch(err => swal("Failed To Update!", {
                                  icon: 'error'
                                }))
                          }}
                          defaultValue={moment(isItKey.currentPayDate).format(
                              "YYYY-MM-DD"
                          )}
                      />
                    </InputGroup>
                  </td>

                  <td className="noPrint">
                    {isItKey.isPayed ? (
                        <InputGroup
                            style={{ width: 100, margin: 0 }}
                            className="mb-3"
                        >
                          <InputGroup.Prepend>
                            <InputGroup.Checkbox
                                style={{ width: 20 }}
                                aria-label="Checkbox for following text input"
                                //   value={buyContract.isPayed}
                                defaultChecked={isItKey.isPayed}
                            />
                          </InputGroup.Prepend>
                          <FormControl
                              style={{ padding: 10 }}
                              aria-label="Text input with checkbox"
                              value={isItKey.isPayed && "Paid"}
                          />
                        </InputGroup>
                    ) : (
                        (role !== 'Viewer') && <Button
                            style={{ marginLeft: 15 }}
                            onClick={() => {
                              swal({
                                text: 'Please Enter your password.',
                                content: "input",
                                button: {
                                  text: "Proceed!",
                                  closeModal: false,
                                },
                              })
                                  .then((pass) => {
                                    axios
                                        .post(process.env.REACT_APP_PATH + "/login", {
                                          // userName: 'adnan',
                                          userName: localStorage.getItem('userName') ? localStorage.getItem('userName') : "",
                                          password: pass
                                        })
                                        .then((resp) => {
                                          if(resp.status >= 200 && resp.status < 300) {
                                            swal({
                                              title: "Are you sure?",
                                              text: `An Installment of Property ${data.property.name} will be Paid with a price of ${isItKey.currentPayPrice}, Attention: Paid Installments Cannot be Unpaid!`,
                                              icon: "warning",
                                              buttons: true,
                                              // dangerMode: true,
                                            })
                                                .then((willDelete) => {
                                                  if (willDelete) {
                                                    axios
                                                        .put(
                                                            `${process.env.REACT_APP_URL}/PaymentProcesses/${isItKey.id}`,
                                                            {
                                                              id: isItKey.id,
                                                              buyContractId: isItKey.buyContractId,
                                                              currentPayDate: isItKey.currentPayDate,
                                                              currentPayPrice: isItKey.currentPayPrice,
                                                              kuPriceText: isItKey.kuPriceText,
                                                              arPriceText: isItKey.arPriceText,
                                                              nextPayDate: isItKey.nextPayDate,
                                                              nextPayPrice: isItKey.nextPayPrice,
                                                              note: document.getElementById(
                                                                  `note${isItKey.id}`
                                                              ).value,
                                                              attachment: isItKey.attachment,
                                                              accountant: localStorage.getItem('name'),
                                                              isPayed: true,
                                                              isItKeyPayment: isItKey.isItKeyPayment,
                                                              extraPrice: document.getElementById(
                                                                  `extraPrice${isItKey.id}`
                                                              ).value,
                                                            }
                                                        )
                                                        .then((resp) => {
                                                          axios
                                                              .get(
                                                                  `${process.env.REACT_APP_URL}/BuyContracts/${props.id}`
                                                              )
                                                              .then((resp) => {
                                                                swal("Payment Accomplished Successfully!", {
                                                                  icon: "success",
                                                                }).then(() => {
                                                                  window.location.reload();
                                                                });
                                                              });
                                                        });
                                                  } else {
                                                    swal("Payment Dismised!");
                                                  }
                                                });

                                          }
                                        })
                                        .catch(err => swal("Authentication Failed!", {
                                          icon: 'error'
                                        }))
                                  })
                            }}
                        >
                          Pay
                        </Button>
                    )}
                  </td>
                  <td className="noPrint">
                    <iframe
                        id="ifmcontentstoprint"
                        style={{
                          height: 0,
                          width: 0,
                          position: "absolute",
                          display: "none",
                        }}
                    >
                      <div id={`iframe${isItKey.id}`}>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'darkgreen'}}>
                          <div style={{width: '42%', fontFamily: 'monospace'}}><p style={{letterSpacing: -1}}>Shajaratalzaiton Company For General Trading</p> <p style={{letterSpacing: -0.3}}>and Real Estate and Industial Investment</p></div>
                          <div style={{width: '20%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start'}}>
                            <div
                                style={{
                                  height: 100,
                                  width: 100
                                }}
                            >
                              <img
                                  src={ZaitonLogo}
                                  alt=""
                                  style={{ width: "100%", height: "100%" }}
                              />
                            </div>
                          </div>

                          <div style={{width: '30%'}}><p>شركة شجرة الزيتون للتجارة العامة والاستثمارات العقارية و الصناعية</p></div>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', color: 'darkgreen', backgroundColor: 'rgba(143,188,143, 0.5)',marginTop: 5, height: 30, borderTopStyle: 'solid', borderTopColor: 'green', borderTopWidth: 2,borderBottomColor: 'green', borderBottomStyle: 'solid', borderBottomWidth: 2}}>
                          <p style={{margin: 3, padding: 3}}>Mob.:0750 300 5003 - 0750 300 5004</p>
                          <p style={{margin: 3, padding: 3}}>E-mail: zaiton.plus.city@gmail.com</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                          <div>
                            <div style={{border: '1px solid darkgreen', width: 140}}><p style={{borderRight: '1px solid darkgreen', margin: 3, padding: 3, width: 30}}>دینار</p></div>
                            <div style={{border: '1px solid darkgreen', width: 140}}><p style={{borderRight: '1px solid darkgreen', margin: 3, padding: 3, width: 30}}>دۆلار</p></div>
                          </div>
                          <div style={{textAlign: 'center', lineHeight: 0.5}}>
                            <p>پسولەی وەرگرتن</p>
                            <p><u>Receipt Voucher</u></p>
                          </div>
                          <div style={{textAlign: 'right'}}>
                            <p><span style={{color: 'red'}}>{isItKey.id}</span> :ژمارەی پسوولە</p>
                            <p>{moment(isItKey.payDate).format("YYYY-MM-DD")} :ڕێکەوت</p>
                          </div>
                        </div>
                        <div style={{lineHeight: 0.5}}>
                          <div style={{display: "flex", justifyContent: 'space-between'}}>
                            <p>Received From: {`${data.customer.firstName} ${data.customer.middleName} ${data.customer.lastName}`}</p>
                            <p>:(وەرگیرا لە (استلمت من</p>
                          </div>
                          <div style={{display: "flex", justifyContent: 'space-between'}}>
                            <p>Amount: {` $${isItKey.currentPayPrice} - ${
                                arabicInvoice
                                    ? isItKey.arPriceText
                                    : isItKey.kuPriceText
                            }`}</p>
                            <p>:(بڕی (المبلغ</p>
                          </div>
                          <div style={{display: "flex", justifyContent: 'space-between'}}>
                            <p>Purpose of receipt:</p>
                            <p>:(بە مەبەستی (و ذلك عن</p>
                          </div>
                          <div style={{display: "flex", justifyContent: 'space-between'}}>
                            <p>Note: {document.getElementById(`note${isItKey.id}`) &&
                            document.getElementById(`note${isItKey.id}`).value}</p>
                            <p>:(تێبینیی (الملاحظة</p>
                          </div>
                        </div>
                        <br />
                        <div style={{display: 'flex', justifyContent: 'space-around'}}>
                          <div style={{textAlign: 'center', lineHeight: 0.5}}>
                            <p>واژووی پێدەر</p>
                            <p>
                              {data.customer &&
                              `${data.customer.firstName} ${data.customer.middleName} ${data.customer.lastName}`}
                            </p>
                          </div>
                          <div style={{textAlign: 'center', lineHeight: 0.5}}>
                            <p>واژووی وەرگر</p>
                            <p>{isItKey.accountant}</p>
                          </div>
                        </div>
                      </div>
                    </iframe>
                    <Button
                        style={{ marginLeft: 15 }}
                        disabled={!isItKey.isPayed}
                        onClick={() => {
                          var pri = document.getElementById(
                              "ifmcontentstoprint"
                          ).contentWindow;
                          pri.document.open();
                          pri.document.write(
                              document.getElementById(`iframe${isItKey.id}`)
                                  .innerHTML
                          );
                          pri.document.write("<hr />");
                          pri.document.write(
                              document.getElementById(`iframe${isItKey.id}`)
                                  .innerHTML
                          );
                          // pri.document.write(content.innerHTML);
                          pri.document.close();
                          pri.focus();
                          pri.print();
                        }}
                    >
                      print
                    </Button>

                    {!isItKey.isPayed ? (isGrayOut = true) : null}
                  </td>
                </tr>}
              </tbody>
            </Table>
          </Print>
          <iframe
            id="kurdishContractPrint"
            style={{
              height: 0,
              width: 0,
              position: "absolute",
              display: "none",
            }}
          >
            {data.property && data.property.zone === 'J'
                ? <KurdishTemplateJ data={data} pageNumber={pageNumber} isItPayment={isItPayment} isItKey={isItKey} removeAfterMonth={false} />
                : <KurdishTemplate data={data} pageNumber={pageNumber} isItKey={isItKey} removeAfterMonth={false} />
            }
          </iframe>
          <iframe
            id="arabicContractPrint"
            style={{
              height: 0,
              width: 0,
              position: "absolute",
              display: "none",
            }}
          >
            {data.property && data.property.zone === 'J'
                ? <ArabicTemplateJ data={data} pageNumber={pageNumber} isItPayment={isItPayment} isItKey={isItKey} removeAfterMonth={false} />
                : <ArabicTemplate data={data} pageNumber={pageNumber} isItKey={isItKey} removeAfterMonth={false} />
            }
          </iframe>

          <iframe
              id="kurdishContractPrintMonth"
              style={{
                height: 0,
                width: 0,
                position: "absolute",
                display: "none",
              }}
          >
            {data.property && data.property.zone === 'J'
                ? <KurdishTemplateJ data={data} pageNumber={pageNumber} isItPayment={isItPayment} isItKey={isItKey} removeAfterMonth={true} />
                : <KurdishTemplate data={data} pageNumber={pageNumber} isItKey={isItKey} removeAfterMonth={true} />
            }
          </iframe>
          <iframe
              id="arabicContractPrintMonth"
              style={{
                height: 0,
                width: 0,
                position: "absolute",
                display: "none",
              }}
          >
            {data.property && data.property.zone === 'J'
                ? <ArabicTemplateJ data={data} pageNumber={pageNumber} isItPayment={isItPayment} isItKey={isItKey} removeAfterMonth={true} />
                : <ArabicTemplate data={data} pageNumber={pageNumber} isItKey={isItKey} removeAfterMonth={true} />
            }
          </iframe>
          <div style={{ display: "flex" }}>
            <InputGroup className="mb-3" style={{ width: 200, marginLeft: 10 }}>
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">Page No.</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="number"
                value={pageNumber}
                onChange={(e) => setPageNumber(e.target.value)}
              />
            </InputGroup>

            <p style={{ margin: 10 }}>
              Paid Price:{" $"}
              {data.paymentProcess &&
                data.paymentProcess
                  .filter((el) => el.isPayed)
                  .map((el) => el.currentPayPrice)
                  .reduce(reducer, 0) +
                  ((data.isPrePaymentPayed || prePaid) && data.prePaymentPrice) + (isItKey && isItKey.isPayed && isItKey.currentPayPrice) + (isItPayment && isItPayment.isPayed && isItPayment.currentPayPrice)}
            </p>
            <p style={{ margin: 10 }}>
              Remained Price:{" $"}
              {(data && data.buyingPrice) -
              (((data.paymentProcess &&
                  data.paymentProcess
                    .filter((el) => el.isPayed)
                    .map((el) => el.currentPayPrice)
                    .reduce(reducer, 0)) +
                  ((data.isPrePaymentPayed || prePaid) &&
                    data.prePaymentPrice)) + (isItKey && isItKey.isPayed && isItKey.currentPayPrice))}
            </p>
            <ProgressBar
              style={{ width: "30%", margin: 14 }}
              now={
                (((data.paymentProcess &&
                        data.paymentProcess
                            .filter((el) => el.isPayed)
                            .map((el) => el.currentPayPrice)
                            .reduce(reducer, 0) +
                        ((data.isPrePaymentPayed || prePaid) &&
                            data.prePaymentPrice)) + (isItKey && isItKey.isPayed && isItKey.currentPayPrice)) /
                    (data && data.buyingPrice)) *
                100
              }
              label={`${Math.floor(
                  (((data.paymentProcess &&
                  data.paymentProcess
                    .filter((el) => el.isPayed)
                    .map((el) => el.currentPayPrice)
                    .reduce(reducer, 0) +
                    ((data.isPrePaymentPayed || prePaid) &&
                      data.prePaymentPrice)) + (isItKey && isItKey.isPayed && isItKey.currentPayPrice)) /
                  (data && data.buyingPrice)) *
                  100
              )}%`}
            />
          </div>
          <Button
            style={{ margin: 10, marginTop: 0 }}
            onClick={() => {
              // var content = document.getElementById(`rowNumber${key+1}`);
              var pri = document.getElementById("kurdishContractPrint")
                .contentWindow;
              pri.document.open();
              pri.document.write(
                document.getElementById(`kurdishContractPrint`).innerHTML
              );
              // pri.document.write(content.innerHTML);
              pri.document.close();
              pri.focus();
              pri.print();
            }}
          >
            Print Contract - Kurdish
          </Button>
          <Button
            style={{ margin: 10, marginTop: 0 }}
            onClick={() => {
              // var content = document.getElementById(`rowNumber${key+1}`);
              var pri = document.getElementById("arabicContractPrint")
                .contentWindow;
              pri.document.open();
              pri.document.write(
                document.getElementById(`arabicContractPrint`).innerHTML
              );
              // pri.document.write(content.innerHTML);
              pri.document.close();
              pri.focus();
              pri.print();
            }}
          >
            Print Contract - Arabic
          </Button>

          <Button
              style={{ margin: 10, marginTop: 0 }}
              variant={`success`}
              onClick={() => {
                // var content = document.getElementById(`rowNumber${key+1}`);
                var pri = document.getElementById("kurdishContractPrintMonth")
                    .contentWindow;
                pri.document.open();
                pri.document.write(
                    document.getElementById(`kurdishContractPrintMonth`).innerHTML
                );
                // pri.document.write(content.innerHTML);
                pri.document.close();
                pri.focus();
                pri.print();
              }}
          >
            Print Contract - Kurdish
          </Button>
          <Button
              style={{ margin: 10, marginTop: 0 }}
              variant={`success`}
              onClick={() => {
                // var content = document.getElementById(`rowNumber${key+1}`);
                var pri = document.getElementById("arabicContractPrintMonth")
                    .contentWindow;
                pri.document.open();
                pri.document.write(
                    document.getElementById(`arabicContractPrintMonth`).innerHTML
                );
                // pri.document.write(content.innerHTML);
                pri.document.close();
                pri.focus();
                pri.print();
              }}
          >
            Print Contract - Arabic
          </Button>
        </div>
      </List>
    </PrintProvider>
  );
};

export const BuyContractEdit = (props) => {
  const [data, setData] = React.useState([]);
  const [note, setNote] = React.useState("");
  const [file, setFile] = React.useState();

  const [returnedPrice, setReturnedPrice] = React.useState();
  const [remainedPrice, setRemainedPrice] = React.useState();

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
      .get(`${process.env.REACT_APP_URL}/BuyContracts/${props.id}`)
      .then((resp) => {
        setData(resp.data);
      });
  }, []);
  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  const paidPrice =
    data.paymentProcess &&
    data.paymentProcess.filter((el) => el.isPayed === true).length !== 0
      ? data.paymentProcess
          .filter((el) => el.isPayed === true)
          .map((el) => el.currentPayPrice)
          .reduce(reducer) + (data.isPrePaymentPayed ? data.prePaymentPrice : 0)
      : data.isPrePaymentPayed === true
            ? data.isPrePaymentPayed
                ? data.prePaymentPrice
                : 0
            : 0;
  const fileSelectHandler = (e) => {
    getBase64(e.target.files[0]);
  };

  React.useEffect(() => {
    setRemainedPrice(paidPrice * 0.3);
    setReturnedPrice(paidPrice * 0.7);
  }, [paidPrice]);

  React.useEffect(() => {
    setRemainedPrice(paidPrice - returnedPrice);
  }, [returnedPrice]);

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setFile(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  const notify = useNotify();
  return (
    <Edit {...props} title="Cancel Contract">
      <>
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">Customer</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            placeholder="Customer"
            value={
              data.customer &&
              `${data.customer.firstName} ${data.customer.middleName} ${data.customer.lastName}`
            }
            type="text"
            disabled={true}
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">Paid Price</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            placeholder="Paid Price"
            value={paidPrice}
            type="number"
            disabled={true}
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">Returned Price</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            placeholder="Returned Price"
            value={returnedPrice}
            onChange={(e) => setReturnedPrice(e.target.value)}
            type="number"
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">Remained Price</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            placeholder="Remained Price"
            value={remainedPrice}
            type="number"
            disabled={true}
          />
        </InputGroup>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>Note</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            as="textarea"
            aria-label="Note"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </InputGroup>
        <input
          style={{
            margin: 5,
            borderColor: "red",
            borderWidth: 1,
            borderRadius: 4,
            padding: 5,
          }}
          type="file"
          onChange={fileSelectHandler}
        />
        <Button
          style={{ margin: 15, backgroundColor: "tomato", border: "none" }}
          onClick={() => {
            axios
              .post(`${process.env.REACT_APP_URL}/CancelledContracts`, {
                buyContractId: props.id,
                returnedPrice: returnedPrice,
                date: moment().format("YYYY-MM-DD, h:mm:ss a"),
                remainPrice: remainedPrice,
                attachment: file,
                note: note,
              })
              .then((resp) => {
                notify("Contract Cancelled!");
                window.open(`#/CancelledContracts`, "_self");
              })
              .catch((error) => {
                notify("A Problem Occured!, ", error);
              });
          }}
        >
          Cancel
        </Button>
      </>
    </Edit>
  );
};
