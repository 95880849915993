import React, { useEffect, useState } from "react";
import "../invoice.css";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";

function JZone(props) {
  const [properties, setProperties] = useState([]);
  useEffect(() => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
      .get(process.env.REACT_APP_URL + "/Dashboard/J")
      .then((res) => {
        setProperties(res.data)
        props.setLoader()
      });
  }, []);

  const soldProperties = properties.filter(
    (property) => property.isSold === true
  ).length;
  const remainedProperties = properties.filter(
    (property) => property.isSold === false
  ).length;
  const holdedProperties = properties.filter(
    (property) => property.isHijz === true
  ).length;

  const role = localStorage.getItem("role");
  role !== 'Seller' && properties.map((el) => {
    document.getElementById(`${el.name}`) && document.getElementById(`${el.name}`).addEventListener("click", () => {
      el.isSold
        ? window.open(`#/BuyContracts/${el.contractId}/show`, "_self")
        : window.open(
            `#/Properties?filter=%7B"name"%3A"${el.name}"%7D&order=ASC&page=1&perPage=10&sort=id`,
            "_self"
          );
    });
  });

  properties
    .filter((el) => el.isSold === false)
    .map((el) => {
      document.getElementById(`${el.name}`) && (document.getElementById(`${el.name}`).style.fill = "#ffc107");
    });

  properties
    .filter((el) => el.isSold === true)
    .map((el) => {
      document.getElementById(`${el.name}`) && (document.getElementById(`${el.name}`).style.fill = "#17a2b8");
    });
  properties
    .filter((el) => el.isHijz === true)
    .map((el) => {
      document.getElementById(`${el.name}`) && (document.getElementById(`${el.name}`).style.fill = "#dc3545");
    });

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "80%",
        }}
      >
        <Button
          onClick={() => {
            props.resetZone();
          }}
        >
          Back
        </Button>
        <Button variant="info">
          Sold Properties <Badge variant="light">{soldProperties}</Badge>
          <span className="sr-only">unread messages</span>
        </Button>
        <Button variant="warning">
          Remained Properties{" "}
          <Badge variant="light">{remainedProperties}</Badge>
          <span className="sr-only">unread messages</span>
        </Button>
        <Button variant="danger">
          On Hold Properties <Badge variant="light">{holdedProperties}</Badge>
          <span className="sr-only">unread messages</span>
        </Button>
      </div>
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1024 1024"
      >
        <title>J-Last </title>
        <polygon
          id="J1"
          points="60.28 919.08 60.28 838.1 143.7 838.1 143.7 914.39 101.99 917.95 60.28 919.08"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J2"
          x="70.57"
          y="764.31"
          width="62.85"
          height="83.42"
          transform="translate(908.01 704.03) rotate(90)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J3"
          x="70.57"
          y="701.48"
          width="62.85"
          height="83.42"
          transform="translate(845.18 641.2) rotate(90)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J4"
          x="70.57"
          y="638.26"
          width="62.85"
          height="83.42"
          transform="translate(781.96 577.98) rotate(90)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J5"
          x="70.57"
          y="575.03"
          width="62.85"
          height="83.42"
          transform="translate(718.73 514.75) rotate(90)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J6"
          x="70.57"
          y="511.63"
          width="62.85"
          height="83.42"
          transform="translate(655.33 451.35) rotate(90)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J7"
          x="70.57"
          y="448.48"
          width="62.85"
          height="83.42"
          transform="translate(592.19 388.21) rotate(90)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J8"
          x="70.57"
          y="385.04"
          width="62.85"
          height="83.42"
          transform="translate(528.74 324.76) rotate(90)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J9"
          x="70.57"
          y="321.87"
          width="62.85"
          height="83.42"
          transform="translate(465.58 261.59) rotate(90)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J10"
          x="70.57"
          y="258.58"
          width="62.85"
          height="83.42"
          transform="translate(402.29 198.3) rotate(90)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J11"
          x="292.21"
          y="36.08"
          width="62.85"
          height="83.42"
          transform="translate(647.27 155.58) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J12"
          x="355.87"
          y="36.08"
          width="62.85"
          height="83.42"
          transform="translate(774.58 155.58) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J13"
          x="418.72"
          y="36.08"
          width="62.85"
          height="83.42"
          transform="translate(900.28 155.58) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J14"
          x="482.38"
          y="36.08"
          width="62.85"
          height="83.42"
          transform="translate(1027.6 155.58) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J15"
          x="545.22"
          y="36.08"
          width="62.85"
          height="83.42"
          transform="translate(1153.29 155.58) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J16"
          x="608.88"
          y="36.08"
          width="62.85"
          height="83.42"
          transform="translate(1280.61 155.58) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J17"
          x="671.87"
          y="36.08"
          width="62.85"
          height="83.42"
          transform="translate(1406.59 155.58) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J18"
          x="735.53"
          y="36.08"
          width="62.85"
          height="83.42"
          transform="translate(1533.91 155.58) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J19"
          x="798.56"
          y="36.08"
          width="62.85"
          height="83.42"
          transform="translate(1659.96 155.58) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <polygon
          id="J20"
          points="862.22 36.08 942.79 36.08 941.34 81.07 937.54 119.5 862.22 119.5 862.22 36.08"
          style={{ fill: "#fde7e8" }}
        />
        <polygon
          id="J21"
          points="804.61 211.82 922.34 211.82 910.93 266.04 902.34 298.36 804.61 298.29 804.61 211.82"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J22"
          x="741.78"
          y="211.82"
          width="63.95"
          height="86.47"
          transform="translate(1547.51 510.11) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J23"
          x="677.64"
          y="211.82"
          width="63.95"
          height="86.47"
          transform="translate(1419.22 510.11) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J24"
          x="614.23"
          y="211.82"
          width="63.95"
          height="86.47"
          transform="translate(1292.4 510.11) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J25"
          x="551.9"
          y="211.82"
          width="63.39"
          height="86.47"
          transform="translate(1167.18 510.11) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J26"
          x="488.61"
          y="211.82"
          width="63.24"
          height="86.47"
          transform="translate(1040.47 510.11) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J27"
          x="424.67"
          y="211.82"
          width="63.95"
          height="86.47"
          transform="translate(913.28 510.11) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J28"
          x="362.27"
          y="211.82"
          width="62.93"
          height="86.47"
          transform="translate(787.47 510.11) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J29"
          x="299.14"
          y="211.82"
          width="63.13"
          height="86.47"
          transform="translate(661.41 510.11) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J30"
          x="235.84"
          y="211.82"
          width="62.85"
          height="86.47"
          transform="translate(534.54 510.11) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J31"
          x="235.8"
          y="298.36"
          width="62.85"
          height="82.77"
          transform="translate(534.46 679.49) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J32"
          x="299.1"
          y="298.36"
          width="63.13"
          height="82.77"
          transform="translate(661.33 679.49) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J33"
          x="362.23"
          y="298.36"
          width="62.93"
          height="82.77"
          transform="translate(787.39 679.49) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J34"
          x="424.62"
          y="298.36"
          width="63.95"
          height="82.77"
          transform="translate(913.2 679.49) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J35"
          x="488.57"
          y="298.36"
          width="63.24"
          height="82.77"
          transform="translate(1040.39 679.49) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J36"
          x="551.85"
          y="298.36"
          width="63.39"
          height="82.77"
          transform="translate(1167.1 679.49) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J37"
          x="614.18"
          y="298.36"
          width="63.95"
          height="82.77"
          transform="translate(1292.32 679.49) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J38"
          x="677.6"
          y="298.36"
          width="63.95"
          height="82.77"
          transform="translate(1419.14 679.49) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J39"
          x="741.74"
          y="298.36"
          width="63.95"
          height="82.77"
          transform="translate(1547.42 679.49) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <polygon
          id="J40"
          points="805.69 298.36 902.34 298.36 886.3 342.81 870.66 381.13 805.69 381.13 805.69 298.36"
          style={{ fill: "#fde7e8" }}
        />
        <polygon
          id="J41"
          points="744.07 432.67 848.51 432.67 823.12 479.72 800.57 517.67 744.07 517.67 744.07 432.67"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J42"
          x="680.63"
          y="432.67"
          width="64.09"
          height="85"
          transform="translate(1425.36 950.34) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J43"
          x="616.9"
          y="432.63"
          width="64.09"
          height="85"
          transform="translate(1297.89 950.27) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J44"
          x="554.43"
          y="432.63"
          width="64.09"
          height="85"
          transform="translate(1172.95 950.27) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J45"
          x="491"
          y="432.63"
          width="64.09"
          height="85"
          transform="translate(1046.1 950.27) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J46"
          x="426.91"
          y="432.63"
          width="64.09"
          height="85"
          transform="translate(917.91 950.27) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J47"
          x="364.37"
          y="432.63"
          width="64.09"
          height="85"
          transform="translate(792.84 950.27) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J48"
          x="301.1"
          y="432.63"
          width="64.09"
          height="85"
          transform="translate(666.29 950.27) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J49"
          x="237.66"
          y="432.63"
          width="64.09"
          height="85"
          transform="translate(539.41 950.27) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J50"
          x="237.62"
          y="516.94"
          width="64.09"
          height="85"
          transform="translate(539.33 1118.88) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J51"
          x="301.05"
          y="516.94"
          width="64.09"
          height="85"
          transform="translate(666.2 1118.88) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J52"
          x="364.33"
          y="516.94"
          width="64.09"
          height="85"
          transform="translate(792.76 1118.88) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J53"
          x="426.87"
          y="516.94"
          width="64.09"
          height="85"
          transform="translate(917.83 1118.88) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J54"
          x="490.96"
          y="516.94"
          width="64.09"
          height="85"
          transform="translate(1046.02 1118.88) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J55"
          x="554.39"
          y="516.94"
          width="64.09"
          height="85"
          transform="translate(1172.87 1118.88) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J56"
          x="616.86"
          y="516.94"
          width="64.09"
          height="85"
          transform="translate(1297.81 1118.88) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <path
          id="J57"
          d="M681.6,516.94h119s-21.47,34.11-34.79,50.35c-7.08,8.63-28.19,34.65-28.19,34.65H681.6Z"
          style={{ fill: "#fde7e8" }}
        />
        <path
          id="J58"
          d="M554.94,652H691.89s-44.52,45.15-68.48,63.15c-21.74,17.42-68.47,51.61-68.47,51.61Z"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J59"
          x="491.47"
          y="652.04"
          width="63.07"
          height="85"
          transform="translate(1046.02 1389.08) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J60"
          x="428.22"
          y="652.04"
          width="63.07"
          height="85"
          transform="translate(919.52 1389.08) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J61"
          x="364.84"
          y="652.04"
          width="63.07"
          height="85"
          transform="translate(792.76 1389.08) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J62"
          x="301.57"
          y="652.04"
          width="63.07"
          height="85"
          transform="translate(666.2 1389.08) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J63"
          x="238.64"
          y="652.04"
          width="63.07"
          height="85"
          transform="translate(540.35 1389.08) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <rect
          id="J64"
          x="238.64"
          y="736.46"
          width="126.63"
          height="63.07"
          transform="translate(603.91 1536) rotate(180)"
          style={{ fill: "#fde7e8" }}
        />
        <polygon
          id="J65"
          points="238.5 800.1 301.57 800.1 301.42 887.63 268.32 895.58 238.35 901.49 238.5 800.1"
          style={{ fill: "#fde7e8" }}
        />
        <polygon
          id="J66"
          points="301.57 800.1 364.64 800.1 364.64 867.2 333.1 877.9 301.57 887.1 301.57 800.1"
          style={{ fill: "#fde7e8" }}
        />
        <polygon
          id="J67"
          points="364.84 737.45 427.91 737.45 427.91 840.21 364.84 867.2 364.84 737.45"
          style={{ fill: "#fde7e8" }}
        />
        <path
          id="J68"
          d="M428.22,737.45H555.17V766.8s-47,30.44-63.22,39.78c-15.6,9-63.73,33.63-63.73,33.63Z"
          style={{ fill: "#fde7e8" }}
        />
        <g style={{ opacity: 0.6900000000000001 }}>
          <g id="_1" data-name="1">
            <rect
              x="60.03"
              y="260.63"
              width="83.67"
              height="7.11"
              transform="translate(203.74 528.37) rotate(180)"
              style={{ fill: "#139b48" }}
            />
            <rect x="59.49" y="267.39" width="1.31" height="652.49" />
            <path
              d="M60,187.44h83.13a9.9,9.9,0,0,1,9.9,9.9V917.29A19.48,19.48,0,0,1,135,936.74c-22.2,1.61-58.87,4.07-74.84,4.07"
              style={{
                fill: "none",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <path
              d="M59.79,209.37H143.7v704.8s-32.48,4.91-83.56,4.91"
              style={{
                fill: "none",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <rect x="60.03" y="837.45" width="83.67" height="1.31" />
            <rect x="60.03" y="773.96" width="83.67" height="1.31" />
            <rect x="60.03" y="710.74" width="83.67" height="1.31" />
            <rect x="60.03" y="647.51" width="83.67" height="1.31" />
            <rect x="60.03" y="584.11" width="83.67" height="1.31" />
            <rect x="60.03" y="520.97" width="83.67" height="1.31" />
            <rect x="60.03" y="457.52" width="83.67" height="1.31" />
            <rect x="60.03" y="394.35" width="83.67" height="1.31" />
            <rect x="60.03" y="331.06" width="83.67" height="1.31" />
            <rect x="60.03" y="267.39" width="83.67" height="1.31" />
            <rect x="60.03" y="259.63" width="83.67" height="1.31" />
          </g>
          <g id="_1-2" data-name="1">
            <rect
              x="245.99"
              y="74.04"
              width="83.81"
              height="7.11"
              transform="translate(365.49 -210.3) rotate(90)"
              style={{ fill: "#139b48" }}
            />
            <path
              d="M964.51,35.72c0,15.86-2.48,53.09-4.08,75.54A19.49,19.49,0,0,1,941,129.35H221.05a9.9,9.9,0,0,1-9.9-9.9V35.83"
              style={{
                fill: "none",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <path
              d="M233.08,119.5V36.69s628.33-.61,709.71-1a711.07,711.07,0,0,1-4.91,83.78Z"
              style={{
                fill: "none",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <rect x="861.15" y="36.08" width="1.31" height="83.43" />
            <rect x="797.66" y="36.08" width="1.31" height="83.43" />
            <rect x="734.45" y="36.08" width="1.31" height="83.43" />
            <rect x="671.21" y="36.08" width="1.31" height="83.43" />
            <rect x="607.82" y="36.08" width="1.31" height="83.43" />
            <rect x="544.67" y="36.08" width="1.31" height="83.43" />
            <rect x="481.23" y="36.08" width="1.31" height="83.43" />
            <rect x="418.06" y="36.08" width="1.31" height="83.43" />
            <rect x="354.77" y="36.08" width="1.31" height="83.43" />
            <rect x="291.1" y="35.69" width="1.31" height="83.81" />
            <rect x="283.33" y="35.69" width="1.31" height="83.81" />
          </g>
          <g id="B.H3">
            <path
              d="M929.54,204.34h-693a9.73,9.73,0,0,0-9.73,9.72V382.14a9.73,9.73,0,0,0,9.73,9.72H874.18a26.86,26.86,0,0,0,25.37-18c7.58-21.79,19.87-57.46,24.54-72.87,4.83-16,14.69-53.57,20.73-76.88A15.79,15.79,0,0,0,929.54,204.34Z"
              style={{
                fill: "none",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <path
              d="M922.34,211.23H235.8V381.36H870.94s25-61.2,31.28-82C908.79,277.62,922.34,211.23,922.34,211.23Z"
              style={{
                fill: "none",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <rect x="298.04" y="211.39" width="1.31" height="170.18" />
            <rect x="361.39" y="211.39" width="1.31" height="170.18" />
            <rect x="424.54" y="211.23" width="1.31" height="170.18" />
            <rect x="487.89" y="211.23" width="1.31" height="170.18" />
            <rect x="551.35" y="211.23" width="1.31" height="170.18" />
            <rect x="614.63" y="211.23" width="1.31" height="170.18" />
            <rect x="677.73" y="211.15" width="1.31" height="170.18" />
            <rect x="740.93" y="211.15" width="1.31" height="170.18" />
            <rect x="804.41" y="211.15" width="1.31" height="170.18" />
            <rect x="235.19" y="297.7" width="667.15" height="1.31" />
          </g>
          <g id="B.H2">
            <path
              d="M864,425.16H237.37a8.17,8.17,0,0,0-8.17,8.18v169a8.17,8.17,0,0,0,8.17,8.17H743.6a30.46,30.46,0,0,0,25.69-14.1S808.1,546,819.6,528.19c14.14-21.9,51-91,51-91A7.86,7.86,0,0,0,864,425.16Z"
              style={{
                fill: "none",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <path
              d="M848.51,432.71H237.62V602H737.89s32.16-37,59.94-80.06C822.47,483.74,848.51,432.71,848.51,432.71Z"
              style={{
                fill: "none",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <rect x="300.4" y="432.79" width="1.31" height="169.15" />
            <rect x="363.84" y="432.79" width="1.31" height="169.15" />
            <rect x="427.11" y="432.79" width="1.31" height="169.15" />
            <rect x="490.31" y="432.79" width="1.31" height="169.15" />
            <rect x="553.73" y="432.77" width="1.31" height="169.15" />
            <rect x="616.82" y="432.79" width="1.31" height="169.15" />
            <rect x="680.3" y="432.79" width="1.31" height="169.15" />
            <rect x="743.41" y="432.81" width="1.31" height="84.72" />
            <polygon points="800.22 518.25 800.64 516.94 237.62 516.94 237.62 518.25 800.22 518.25" />
          </g>
          <g id="B.H1">
            <path
              d="M691.89,652.11H238.64V901.49C507.26,854.24,691.89,652.11,691.89,652.11Z"
              style={{
                fill: "none",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <path
              d="M708,642.93H241.94a12.8,12.8,0,0,0-12.81,12.81V907.91a12.84,12.84,0,0,0,15.33,12.59C481.16,873.81,657.39,722.14,716.91,665A12.81,12.81,0,0,0,708,642.93Z"
              style={{
                fill: "none",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <rect x="300.77" y="799.44" width="1.31" height="88.18" />
            <rect x="238.08" y="799.44" width="126.18" height="1.31" />
            <rect x="363.95" y="652.55" width="1.31" height="214.65" />
            <rect x="300.76" y="652.49" width="1.31" height="84.1" />
            <rect x="238.41" y="736.14" width="316.1" height="1.31" />
            <polygon points="427.42 652.47 428.74 654.14 428.74 840.21 427.42 840.21 427.42 652.47" />
            <polygon points="553.86 652.47 555.17 652.47 555.17 766.05 553.86 766.8 553.86 652.47" />
            <polygon points="490.64 652.47 491.95 652.47 491.95 736.28 490.64 736.84 490.64 652.47" />
          </g>
          <rect
            x="185.92"
            y="539.79"
            width="10.17"
            height="372.96"
            rx="5.09"
            transform="translate(382.02 1452.54) rotate(-180)"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="185.92"
            y="220.58"
            width="10.17"
            height="278.93"
            rx="5.09"
            transform="translate(382.02 720.09) rotate(-180)"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="745.23"
            y="-19.52"
            width="10.17"
            height="372.96"
            rx="5.09"
            transform="translate(917.28 -583.36) rotate(90)"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="378.87"
            y="27.36"
            width="10.17"
            height="279.2"
            rx="5.09"
            transform="translate(550.92 -217) rotate(90)"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <line
            x1="862.47"
            y1="407.12"
            x2="229.2"
            y2="407.12"
            style={{
              fill: "#fff",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <line
            x1="713.65"
            y1="623.76"
            x2="229.2"
            y2="623.76"
            style={{
              fill: "#fff",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
        </g>
        <path d="M472.29,755.54c-1.41.19-1.56.32-1.56,2.09v7.67c0,2.73-.35,4.29-1.68,5.62a5.92,5.92,0,0,1-1.81,1.24l-.29-.67a3.6,3.6,0,0,0,1.82-2.75,26.43,26.43,0,0,0,.19-4v-7.08c0-1.77-.17-1.92-1.66-2.09v-.62h5Z" />
        <path d="M474.17,768.94a1.26,1.26,0,0,1-1.19-1.42,1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.28,1.28,0,0,1,474.17,768.94Z" />
        <path d="M480.1,760.66a2.49,2.49,0,0,1,1.18-.37c2,0,3.32,1.72,3.32,3.93,0,2.56-1.62,4.69-3.87,4.69s-3.88-2-3.88-5.31a8.5,8.5,0,0,1,5.07-7.92,13.89,13.89,0,0,1,2.19-.57l.11.72a9.56,9.56,0,0,0-2,.73,6.5,6.5,0,0,0-3.31,4.78Zm.38.9a3.24,3.24,0,0,0-1.67.52,8,8,0,0,0-.12,1.49c0,2.67.86,4.55,2.35,4.55s1.79-1.72,1.79-3.12C482.83,763.21,482.08,761.56,480.48,761.56Z" />
        <path d="M489.84,755.24a3,3,0,0,1,3.06,3.15,2.71,2.71,0,0,1-.53,1.7,15.46,15.46,0,0,1-1.49,1.3c1.27.81,2.51,1.82,2.51,3.66a3.78,3.78,0,0,1-3.78,3.86,3.38,3.38,0,0,1-3.62-3.5,2.61,2.61,0,0,1,.83-2.06,18.44,18.44,0,0,1,1.66-1.3,3.91,3.91,0,0,1-2-3.31A3.38,3.38,0,0,1,489.84,755.24Zm0,12.92c1.19,0,1.92-.9,1.92-2.41s-1.17-2.46-2.72-3.33a3.3,3.3,0,0,0-1.44,2.82C487.6,767.2,488.66,768.16,489.84,768.16ZM489.59,756c-1.06,0-1.62.95-1.62,2.11,0,1.52.91,2.11,2.35,3a3.15,3.15,0,0,0,1.11-2.55C491.43,756.7,490.51,756,489.59,756Z" />
        <path
          d="M457.39,792.61a9.28,9.28,0,0,1,0-14.83l.34.41a9.9,9.9,0,0,0-2.62,7,10.1,10.1,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M464.32,786.63v1.17c0,1,.06,1.14,1.22,1.24v.48h-3.89V789c1.19-.1,1.26-.22,1.26-1.26v-1.15h-4.2v-.51c1.55-2.19,3.25-4.57,4.8-6.71h.81v6.42h1.55v.8Zm-1.41-5.45c-1,1.4-2.08,3-3.18,4.65h3.18Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M471.7,784.93l-1,.52a2,2,0,0,1-.95.24,2.86,2.86,0,0,1-2.92-3,3.33,3.33,0,0,1,3.35-3.33c2.1,0,3.28,1.83,3.28,4a6.36,6.36,0,0,1-4.08,6,8.24,8.24,0,0,1-2,.43l-.12-.49A5.4,5.4,0,0,0,471.7,784.93Zm-1.77-5c-1.18,0-1.6,1.1-1.6,2.27,0,1.4.78,2.57,2.1,2.57a2.35,2.35,0,0,0,1.34-.42,8,8,0,0,0,.06-1.06C471.83,781.85,471.43,779.93,469.93,779.94Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M478,779.36c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.39-2.68-3.39-5.17C474.58,782.2,475.5,779.36,478,779.36Zm0,.58c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S479.33,779.94,478,779.94Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M491.18,789.52v-.47c.89-.09,1-.16,1-1.25v-2.69c0-1.17-.43-1.81-1.33-1.81a2.76,2.76,0,0,0-1.67.78,5.62,5.62,0,0,1,.05.68v3.13c0,1,.12,1.07,1,1.16v.47h-3.46v-.47c.94-.09,1.07-.15,1.07-1.19v-2.78c0-1.17-.38-1.78-1.29-1.78a2.84,2.84,0,0,0-1.66.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48v-.47c1-.09,1.11-.16,1.11-1.2v-3.33c0-1,0-1.08-.92-1.23v-.42a10.23,10.23,0,0,0,2.33-.63v1.28a10.06,10.06,0,0,1,1.14-.8,2.21,2.21,0,0,1,1.28-.43,1.87,1.87,0,0,1,1.76,1.29c.44-.34.83-.6,1.23-.87a2.4,2.4,0,0,1,1.27-.42c1.28,0,2,.9,2,2.48v3.09c0,1,.09,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M502.29,787.3c-.21.74-.49,1.61-.65,2.22h-6.11v-.39c.87-.86,1.83-1.81,2.62-2.72a6.3,6.3,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.84-2.05A2.78,2.78,0,0,0,496,782l-.39-.35.93-1.34a3,3,0,0,1,2.24-1,2.64,2.64,0,0,1,2.76,2.78c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.33,2.27h3c.88,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M503.82,777.78a9.3,9.3,0,0,1,0,14.83l-.36-.41a9.85,9.85,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M321.81,817.85c-1.4.19-1.55.32-1.55,2.09v7.67c0,2.73-.36,4.29-1.68,5.62a6,6,0,0,1-1.81,1.23l-.3-.66A3.59,3.59,0,0,0,318.3,831a27.56,27.56,0,0,0,.19-4v-7.08c0-1.77-.17-1.92-1.66-2.09v-.62h5Z" />
        <path d="M323.7,831.25c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,323.7,831.25Z" />
        <path d="M329.63,823a2.35,2.35,0,0,1,1.18-.37c2,0,3.31,1.72,3.31,3.92,0,2.56-1.61,4.7-3.86,4.7s-3.88-2.05-3.88-5.31a8.5,8.5,0,0,1,5.06-7.93,14.47,14.47,0,0,1,2.2-.56l.11.72a9.86,9.86,0,0,0-2,.73,6.47,6.47,0,0,0-3.3,4.78Zm.38.89a3.31,3.31,0,0,0-1.68.53,9.08,9.08,0,0,0-.11,1.49c0,2.66.85,4.55,2.35,4.55s1.79-1.72,1.79-3.12C332.36,825.51,331.6,823.86,330,823.86Z" />
        <path d="M338.61,823a2.41,2.41,0,0,1,1.18-.37c2,0,3.32,1.72,3.32,3.92,0,2.56-1.62,4.7-3.87,4.7s-3.88-2.05-3.88-5.31a8.51,8.51,0,0,1,5.07-7.93,14,14,0,0,1,2.19-.56l.11.72a10,10,0,0,0-2,.73,6.48,6.48,0,0,0-3.31,4.78Zm.38.89a3.24,3.24,0,0,0-1.67.53,9.08,9.08,0,0,0-.11,1.49c0,2.66.85,4.55,2.34,4.55s1.8-1.72,1.8-3.12C341.35,825.51,340.59,823.86,339,823.86Z" />
        <path
          d="M306.91,854.92a9.31,9.31,0,0,1,0-14.84l.35.42a9.84,9.84,0,0,0-2.63,7,10.08,10.08,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M315.21,849.6c-.22.75-.5,1.62-.66,2.23h-6.11v-.4c.87-.86,1.83-1.8,2.62-2.71,1-1.22,1.93-2.46,1.93-3.83s-.68-2.06-1.83-2.06a2.82,2.82,0,0,0-2.21,1.48l-.39-.35.94-1.33a2.92,2.92,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.5,2.29-2,3.83-.67.7-1.66,1.65-2.33,2.28h3c.89,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M323.18,842.07c-1.54,3.24-3,6.48-4.58,9.78l-1.22.13-.12-.18a94.46,94.46,0,0,0,4.7-8.66h-3.41c-1.06,0-1.13.14-1.56,1.18h-.52c.17-1,.24-1.77.33-2.47h6.28Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M327.5,841.66c2.46,0,3.35,2.72,3.35,5.17S330,852,327.5,852s-3.4-2.68-3.4-5.17S325,841.66,327.5,841.66Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.6S328.85,842.24,327.49,842.24Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M340.71,851.83v-.47c.89-.1,1-.16,1-1.25v-2.69c0-1.18-.43-1.81-1.33-1.81a2.72,2.72,0,0,0-1.67.77c0,.19,0,.4,0,.69v3.13c0,1,.13,1.06,1,1.16v.47h-3.45v-.47c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.39-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.09,1,1.19v.47h-3.49v-.47c1-.1,1.12-.16,1.12-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a9.52,9.52,0,0,0,2.33-.64v1.28a13.82,13.82,0,0,1,1.15-.8,2.26,2.26,0,0,1,1.28-.43,1.85,1.85,0,0,1,1.75,1.3c.44-.34.84-.61,1.24-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.1,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M351.82,849.6c-.22.75-.49,1.62-.65,2.23h-6.12v-.4c.88-.86,1.83-1.8,2.62-2.71,1-1.22,1.93-2.46,1.93-3.83s-.68-2.06-1.83-2.06a2.82,2.82,0,0,0-2.21,1.48l-.38-.35.93-1.33a2.93,2.93,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.83-.68.7-1.67,1.65-2.34,2.28h3c.89,0,1.12-.08,1.68-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M353.35,840.08a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.87,9.87,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M261.44,817.85c-1.41.19-1.56.32-1.56,2.09v7.67c0,2.73-.35,4.29-1.68,5.62a5.86,5.86,0,0,1-1.81,1.23l-.29-.66a3.6,3.6,0,0,0,1.82-2.76,26.21,26.21,0,0,0,.19-4v-7.08c0-1.77-.17-1.92-1.66-2.09v-.62h5Z" />
        <path d="M263.32,831.25c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,263.32,831.25Z" />
        <path d="M269.25,823a2.38,2.38,0,0,1,1.18-.37c2,0,3.32,1.72,3.32,3.92,0,2.56-1.62,4.7-3.87,4.7s-3.88-2.05-3.88-5.31a8.51,8.51,0,0,1,5.07-7.93,14,14,0,0,1,2.19-.56l.11.72a9.56,9.56,0,0,0-2,.73,6.48,6.48,0,0,0-3.31,4.78Zm.38.89a3.24,3.24,0,0,0-1.67.53,8,8,0,0,0-.12,1.49c0,2.66.86,4.55,2.35,4.55s1.79-1.72,1.79-3.12C272,825.51,271.23,823.86,269.63,823.86Z" />
        <path d="M281.94,817.8l.11.17-.5,1.52h-4.29c-.16,1.12-.4,2.72-.57,3.73a7.78,7.78,0,0,1,2.22-.62,3.44,3.44,0,0,1,3.48,3.58,4.67,4.67,0,0,1-1.63,3.57,5.33,5.33,0,0,1-3.29,1.47,2.64,2.64,0,0,1-1.92-.81.81.81,0,0,1-.32-.74,1.24,1.24,0,0,1,.42-.79.38.38,0,0,1,.55,0,3.1,3.1,0,0,0,2.33,1.18c1.47,0,2.17-1.45,2.17-3,0-1.86-1-3.18-3-3.18a4.86,4.86,0,0,0-1.85.38l-.06-.1c.29-1.69.7-4.62.91-6.36Z" />
        <path
          d="M246.54,854.92a9.29,9.29,0,0,1,0-14.84l.34.42a9.88,9.88,0,0,0-2.62,7,10.07,10.07,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M248.41,843.61l.76-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.75,1.75,0,0,1-.51,1.18,7.28,7.28,0,0,1-1.15.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.16,3.71-4.27,3.71a2.63,2.63,0,0,1-1.69-.56.7.7,0,0,1-.26-.55,1,1,0,0,1,.38-.64.33.33,0,0,1,.45,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.35a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16l-.11-.5c1.43-.47,2.53-1.09,2.53-2.25a1.39,1.39,0,0,0-1.51-1.49,2.54,2.54,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M256.24,843.61l.76-1a2.84,2.84,0,0,1,2.11-.93,2.21,2.21,0,0,1,2.45,2.15,1.74,1.74,0,0,1-.5,1.18,7,7,0,0,1-1.16.86,2.65,2.65,0,0,1,2.41,2.46c0,2.6-3.16,3.71-4.27,3.71a2.61,2.61,0,0,1-1.69-.56.72.72,0,0,1-.27-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.35a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16l-.11-.5c1.43-.47,2.52-1.09,2.52-2.25a1.38,1.38,0,0,0-1.5-1.49,2.54,2.54,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M267.13,841.66c2.45,0,3.35,2.72,3.35,5.17s-.9,5.19-3.35,5.19-3.4-2.68-3.4-5.17S264.65,841.66,267.13,841.66Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.6S268.48,842.24,267.12,842.24Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M280.33,851.83v-.47c.89-.1,1-.16,1-1.25v-2.69c0-1.18-.42-1.81-1.33-1.81a2.74,2.74,0,0,0-1.67.77,5.81,5.81,0,0,1,.05.69v3.13c0,1,.12,1.06,1,1.16v.47H275.9v-.47c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.84,2.84,0,0,0-1.66.77v3.79c0,1,.11,1.09,1,1.19v.47H271.5v-.47c1-.1,1.11-.16,1.11-1.2v-3.33c0-1-.05-1.08-.92-1.23v-.42a9.77,9.77,0,0,0,2.33-.64v1.28a13.5,13.5,0,0,1,1.14-.8,2.29,2.29,0,0,1,1.29-.43,1.86,1.86,0,0,1,1.75,1.3c.44-.34.83-.61,1.23-.88a2.5,2.5,0,0,1,1.27-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M291.44,849.6c-.21.75-.49,1.62-.65,2.23h-6.11v-.4c.87-.86,1.83-1.8,2.62-2.71,1-1.22,1.93-2.46,1.93-3.83s-.68-2.06-1.84-2.06a2.8,2.8,0,0,0-2.2,1.48l-.39-.35.93-1.33a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.83-.68.7-1.66,1.65-2.33,2.28h3c.89,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M293,840.08a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.87,9.87,0,0,0,2.63-7,10,10,0,0,0-2.63-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M289.88,755.54c-1.41.19-1.56.32-1.56,2.09v7.67c0,2.73-.36,4.29-1.68,5.62a5.92,5.92,0,0,1-1.81,1.24l-.29-.67a3.6,3.6,0,0,0,1.82-2.75,26.43,26.43,0,0,0,.19-4v-7.08c0-1.77-.17-1.92-1.66-2.09v-.62h5Z" />
        <path d="M291.76,768.94a1.26,1.26,0,0,1-1.19-1.42,1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.28,1.28,0,0,1,291.76,768.94Z" />
        <path d="M297.69,760.66a2.49,2.49,0,0,1,1.18-.37c2,0,3.32,1.72,3.32,3.93,0,2.56-1.62,4.69-3.87,4.69s-3.88-2-3.88-5.31a8.5,8.5,0,0,1,5.07-7.92,13.89,13.89,0,0,1,2.19-.57l.11.72a9.56,9.56,0,0,0-2,.73,6.5,6.5,0,0,0-3.31,4.78Zm.38.9a3.24,3.24,0,0,0-1.67.52,8,8,0,0,0-.12,1.49c0,2.67.86,4.55,2.35,4.55s1.79-1.72,1.79-3.12C300.42,763.21,299.67,761.56,298.07,761.56Z" />
        <path d="M309.58,764.84v1.55c0,1.37.08,1.5,1.4,1.64v.63h-4.46V768c1.36-.14,1.45-.3,1.45-1.67v-1.52h-4.83v-.68c1.78-2.88,3.73-6,5.52-8.85h.92v8.48h1.78v1.05ZM308,757.65c-1.11,1.85-2.39,4-3.66,6.14H308Z" />
        <path
          d="M275,792.61a9.28,9.28,0,0,1,0-14.83l.34.41a9.86,9.86,0,0,0-2.63,7,10.11,10.11,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M281.9,786.63v1.17c0,1,.07,1.14,1.23,1.24v.48h-3.89V789c1.18-.1,1.26-.22,1.26-1.26v-1.15h-4.21v-.51c1.56-2.19,3.25-4.57,4.81-6.71h.8v6.42h1.56v.8Zm-1.4-5.45c-1,1.4-2.08,3-3.18,4.65h3.18Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M290.43,779.55l.09.13-.43,1.15h-3.74c-.14.85-.35,2.06-.5,2.82a7.89,7.89,0,0,1,1.94-.47,2.8,2.8,0,0,1,3,2.72,3.35,3.35,0,0,1-1.42,2.7,5,5,0,0,1-2.87,1.11,2.46,2.46,0,0,1-1.67-.61c-.24-.21-.3-.35-.28-.56a.89.89,0,0,1,.37-.6.37.37,0,0,1,.48,0,2.87,2.87,0,0,0,2,.9c1.28,0,1.89-1.1,1.89-2.29a2.3,2.3,0,0,0-2.59-2.42,4.88,4.88,0,0,0-1.61.29l0-.07c.25-1.29.61-3.5.79-4.82Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M295.56,779.36c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.39-2.68-3.39-5.17C292.17,782.2,293.09,779.36,295.56,779.36Zm0,.58c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S296.92,779.94,295.56,779.94Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M308.77,789.52v-.47c.89-.09,1-.16,1-1.25v-2.69c0-1.17-.43-1.81-1.33-1.81a2.74,2.74,0,0,0-1.67.78,5.62,5.62,0,0,1,.05.68v3.13c0,1,.12,1.07,1,1.16v.47h-3.46v-.47c.94-.09,1.07-.15,1.07-1.19v-2.78c0-1.17-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48v-.47c1-.09,1.11-.16,1.11-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.21,2.21,0,0,1,1.28-.43,1.88,1.88,0,0,1,1.76,1.29c.43-.34.83-.6,1.23-.87a2.38,2.38,0,0,1,1.27-.42c1.27,0,2,.9,2,2.48v3.09c0,1,.09,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M319.88,787.3c-.21.74-.49,1.61-.65,2.22h-6.11v-.39c.87-.86,1.82-1.81,2.61-2.72a6.24,6.24,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.83-2.05a2.76,2.76,0,0,0-2.2,1.48l-.39-.35.93-1.34a3,3,0,0,1,2.24-1,2.64,2.64,0,0,1,2.76,2.78c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.34,2.27h3c.88,0,1.11-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M321.41,777.78a9.3,9.3,0,0,1,0,14.83l-.36-.41a9.89,9.89,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M387.05,755.54c-1.41.19-1.56.32-1.56,2.09v7.67c0,2.73-.35,4.29-1.67,5.62a6,6,0,0,1-1.81,1.24l-.3-.67a3.61,3.61,0,0,0,1.83-2.75,28,28,0,0,0,.18-4v-7.08c0-1.77-.17-1.92-1.66-2.09v-.62h5Z" />
        <path d="M388.94,768.94a1.26,1.26,0,0,1-1.19-1.42,1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.28,1.28,0,0,1,388.94,768.94Z" />
        <path d="M394.86,760.66a2.52,2.52,0,0,1,1.19-.37c1.95,0,3.31,1.72,3.31,3.93,0,2.56-1.61,4.69-3.86,4.69s-3.89-2-3.89-5.31a8.5,8.5,0,0,1,5.07-7.92,14,14,0,0,1,2.2-.57l.11.72a9.86,9.86,0,0,0-2,.73,6.47,6.47,0,0,0-3.3,4.78Zm.39.9a3.28,3.28,0,0,0-1.68.52,9.08,9.08,0,0,0-.11,1.49c0,2.67.85,4.55,2.35,4.55s1.79-1.72,1.79-3.12C397.6,763.21,396.84,761.56,395.25,761.56Z" />
        <path d="M408.49,755.77c-1.76,4.28-3.49,8.56-5.26,12.92l-1.4.17-.14-.24c1.93-3.58,3.92-7.85,5.4-11.43h-3.92c-1.21,0-1.29.18-1.79,1.55h-.59c.19-1.27.28-2.33.37-3.25h7.22Z" />
        <path
          d="M372.15,792.61a9.3,9.3,0,0,1,0-14.83l.35.41a9.86,9.86,0,0,0-2.63,7,10.11,10.11,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M379.08,786.63v1.17c0,1,.07,1.14,1.22,1.24v.48h-3.88V789c1.18-.1,1.26-.22,1.26-1.26v-1.15h-4.21v-.51c1.55-2.19,3.25-4.57,4.81-6.71h.8v6.42h1.56v.8Zm-1.4-5.45c-1,1.4-2.09,3-3.18,4.65h3.18Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M382.41,789.52V789c1.76-.1,1.85-.2,1.85-1.39v-6.08c0-.82-.14-.86-.84-.92l-.73-.08v-.43a14.59,14.59,0,0,0,3.05-.78v8.29c0,1.21.09,1.29,1.86,1.39v.48Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M392.74,779.36c2.46,0,3.35,2.72,3.35,5.17s-.89,5.18-3.35,5.18-3.4-2.68-3.4-5.17C389.34,782.2,390.26,779.36,392.74,779.36Zm0,.58c-1.41,0-1.76,2.34-1.76,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S394.09,779.94,392.73,779.94Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M406,789.52v-.47c.89-.09,1-.16,1-1.25v-2.69c0-1.17-.43-1.81-1.33-1.81a2.74,2.74,0,0,0-1.67.78c0,.19,0,.39,0,.68v3.13c0,1,.13,1.07,1,1.16v.47h-3.45v-.47c.94-.09,1.07-.15,1.07-1.19v-2.78c0-1.17-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.49v-.47c1-.09,1.12-.16,1.12-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.19,2.19,0,0,1,1.28-.43,1.86,1.86,0,0,1,1.75,1.29c.44-.34.84-.6,1.24-.87a2.38,2.38,0,0,1,1.27-.42c1.27,0,2,.9,2,2.48v3.09c0,1,.1,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M417.06,787.3c-.22.74-.49,1.61-.65,2.22h-6.12v-.39c.88-.86,1.83-1.81,2.62-2.72a6.24,6.24,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.83-2.05A2.79,2.79,0,0,0,410.8,782l-.38-.35.93-1.34a3,3,0,0,1,2.24-1,2.64,2.64,0,0,1,2.76,2.78c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.34,2.27h3c.89,0,1.12-.08,1.68-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M418.59,777.78a9.3,9.3,0,0,1,0,14.83l-.36-.41a9.89,9.89,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M387.05,674.84c-1.41.18-1.56.31-1.56,2.08v7.67c0,2.73-.35,4.29-1.67,5.62a6,6,0,0,1-1.81,1.24l-.3-.67a3.61,3.61,0,0,0,1.83-2.75,28,28,0,0,0,.18-4v-7.08c0-1.77-.17-1.92-1.66-2.08v-.63h5Z" />
        <path d="M388.94,688.23a1.26,1.26,0,0,1-1.19-1.42,1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.28,1.28,0,0,1,388.94,688.23Z" />
        <path d="M394.86,680a2.5,2.5,0,0,1,1.19-.36c1.95,0,3.31,1.72,3.31,3.92,0,2.56-1.61,4.69-3.86,4.69s-3.89-2.05-3.89-5.31a8.5,8.5,0,0,1,5.07-7.92,14,14,0,0,1,2.2-.57l.11.73a9.25,9.25,0,0,0-2,.72,6.47,6.47,0,0,0-3.3,4.78Zm.39.9a3.19,3.19,0,0,0-1.68.53,9,9,0,0,0-.11,1.48c0,2.67.85,4.55,2.35,4.55s1.79-1.71,1.79-3.12C397.6,682.5,396.84,680.85,395.25,680.85Z" />
        <path d="M401.59,688v-.63c2-.14,2.13-.27,2.13-1.84v-8c0-1.08-.17-1.13-1-1.22l-.83-.1v-.56a14.75,14.75,0,0,0,3.49-1v11c0,1.6.11,1.7,2.13,1.84V688Z" />
        <path
          d="M372.15,711.91a9.31,9.31,0,0,1,0-14.84l.35.42a9.82,9.82,0,0,0-2.63,7,10.1,10.1,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M374,700.59l.77-1a2.84,2.84,0,0,1,2.11-.93,2.21,2.21,0,0,1,2.45,2.14,1.7,1.7,0,0,1-.5,1.18,7.4,7.4,0,0,1-1.16.87,2.63,2.63,0,0,1,2.4,2.46c0,2.59-3.15,3.7-4.27,3.7a2.61,2.61,0,0,1-1.68-.55.73.73,0,0,1-.27-.55,1,1,0,0,1,.38-.64.33.33,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.88-.82,1.88-2.36a2,2,0,0,0-2.1-2,4.28,4.28,0,0,0-1,.16l-.12-.49c1.44-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.5-1.48,2.53,2.53,0,0,0-2,1.25Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M384.91,698.65c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.4-2.68-3.4-5.17S382.44,698.65,384.91,698.65Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.61S386.26,699.23,384.91,699.23Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M392.74,698.65c2.46,0,3.35,2.72,3.35,5.17s-.89,5.18-3.35,5.18-3.4-2.68-3.4-5.17S390.26,698.65,392.74,698.65Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.61S394.09,699.23,392.73,699.23Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M406,708.81v-.46c.89-.1,1-.16,1-1.26V704.4c0-1.17-.43-1.8-1.33-1.8a2.72,2.72,0,0,0-1.67.77c0,.19,0,.39,0,.68v3.13c0,1,.13,1.07,1,1.17v.46h-3.45v-.46c.94-.1,1.07-.16,1.07-1.19v-2.79c0-1.17-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.8c0,1,.11,1.09,1,1.19v.46h-3.49v-.46c1-.1,1.12-.17,1.12-1.21v-3.32c0-1-.06-1.08-.93-1.23v-.42a9.52,9.52,0,0,0,2.33-.64v1.28a13.82,13.82,0,0,1,1.15-.8,2.19,2.19,0,0,1,1.28-.43,1.86,1.86,0,0,1,1.75,1.29c.44-.34.84-.6,1.24-.87a2.38,2.38,0,0,1,1.27-.42c1.27,0,2,.9,2,2.48v3.1c0,1,.1,1.08,1,1.19v.46Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M417.06,706.59c-.22.75-.49,1.62-.65,2.22h-6.12v-.39c.88-.86,1.83-1.8,2.62-2.72a6.24,6.24,0,0,0,1.93-3.82c0-1.3-.68-2.06-1.83-2.06a2.79,2.79,0,0,0-2.21,1.48l-.38-.35.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.78c0,1.35-.49,2.3-2,3.84-.68.69-1.67,1.65-2.34,2.27h3c.89,0,1.12-.08,1.68-1.08Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M418.59,697.07a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.89,9.89,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M324.43,674.84c-1.4.18-1.55.31-1.55,2.08v7.67c0,2.73-.36,4.29-1.68,5.62a6,6,0,0,1-1.81,1.24l-.29-.67a3.6,3.6,0,0,0,1.82-2.75,27.8,27.8,0,0,0,.19-4v-7.08c0-1.77-.17-1.92-1.66-2.08v-.63h5Z" />
        <path d="M326.32,688.23a1.26,1.26,0,0,1-1.19-1.42,1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.28,1.28,0,0,1,326.32,688.23Z" />
        <path d="M332.25,680a2.46,2.46,0,0,1,1.18-.36c2,0,3.31,1.72,3.31,3.92,0,2.56-1.61,4.69-3.86,4.69s-3.88-2.05-3.88-5.31a8.49,8.49,0,0,1,5.07-7.92,13.89,13.89,0,0,1,2.19-.57l.11.73a9,9,0,0,0-2,.72,6.47,6.47,0,0,0-3.31,4.78Zm.38.9a3.15,3.15,0,0,0-1.67.53,7.9,7.9,0,0,0-.12,1.48c0,2.67.86,4.55,2.35,4.55s1.79-1.71,1.79-3.12C335,682.5,334.22,680.85,332.63,680.85Z" />
        <path d="M345.7,685c-.24,1-.56,2.14-.75,2.94h-7v-.52c1-1.14,2.1-2.38,3-3.59,1.2-1.6,2.22-3.24,2.22-5.05s-.78-2.71-2.11-2.71-2,1.09-2.52,1.95l-.45-.46,1.07-1.77a3.2,3.2,0,0,1,2.57-1.27c1.76,0,3.17,1.38,3.17,3.68,0,1.77-.57,3-2.34,5.06-.77.92-1.91,2.17-2.67,3h3.43c1,0,1.28-.1,1.92-1.43Z" />
        <path
          d="M309.54,711.91a9.29,9.29,0,0,1,0-14.84l.34.42a9.87,9.87,0,0,0-2.63,7,10.1,10.1,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M311.41,700.59l.76-1a2.84,2.84,0,0,1,2.11-.93,2.21,2.21,0,0,1,2.45,2.14,1.7,1.7,0,0,1-.5,1.18,7,7,0,0,1-1.16.87,2.64,2.64,0,0,1,2.41,2.46c0,2.59-3.16,3.7-4.27,3.7a2.62,2.62,0,0,1-1.69-.55.73.73,0,0,1-.27-.55,1,1,0,0,1,.38-.64.33.33,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2,4.29,4.29,0,0,0-1,.16l-.11-.49c1.43-.47,2.52-1.09,2.52-2.26a1.38,1.38,0,0,0-1.5-1.48,2.56,2.56,0,0,0-2,1.25Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M322.29,698.65c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.39-2.68-3.39-5.17S319.82,698.65,322.29,698.65Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.61S323.65,699.23,322.29,699.23Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M330.12,698.65c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.39-2.68-3.39-5.17S327.65,698.65,330.12,698.65Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.61S331.48,699.23,330.12,699.23Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M343.33,708.81v-.46c.89-.1,1-.16,1-1.26V704.4c0-1.17-.43-1.8-1.33-1.8a2.72,2.72,0,0,0-1.67.77,5.62,5.62,0,0,1,.05.68v3.13c0,1,.12,1.07,1,1.17v.46H338.9v-.46c.94-.1,1.07-.16,1.07-1.19v-2.79c0-1.17-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.8c0,1,.11,1.09,1,1.19v.46H334.5v-.46c1-.1,1.11-.17,1.11-1.21v-3.32c0-1-.06-1.08-.93-1.23v-.42a9.52,9.52,0,0,0,2.33-.64v1.28a13.82,13.82,0,0,1,1.15-.8,2.21,2.21,0,0,1,1.28-.43,1.88,1.88,0,0,1,1.76,1.29c.43-.34.83-.6,1.23-.87a2.38,2.38,0,0,1,1.27-.42c1.27,0,2,.9,2,2.48v3.1c0,1,.09,1.08,1,1.19v.46Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M354.44,706.59c-.21.75-.49,1.62-.65,2.22h-6.11v-.39c.87-.86,1.82-1.8,2.61-2.72a6.24,6.24,0,0,0,1.93-3.82c0-1.3-.68-2.06-1.83-2.06a2.76,2.76,0,0,0-2.2,1.48l-.39-.35.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.78c0,1.35-.49,2.3-2,3.84-.68.69-1.67,1.65-2.33,2.27h3c.88,0,1.11-.08,1.67-1.08Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M356,697.07a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.89,9.89,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M261.44,674.84c-1.41.18-1.56.31-1.56,2.08v7.67c0,2.73-.35,4.29-1.68,5.62a5.8,5.8,0,0,1-1.81,1.24l-.29-.67a3.6,3.6,0,0,0,1.82-2.75,26.43,26.43,0,0,0,.19-4v-7.08c0-1.77-.17-1.92-1.66-2.08v-.63h5Z" />
        <path d="M263.32,688.23a1.26,1.26,0,0,1-1.18-1.42,1.29,1.29,0,0,1,1.18-1.43,1.31,1.31,0,0,1,1.22,1.43A1.28,1.28,0,0,1,263.32,688.23Z" />
        <path d="M269.25,680a2.5,2.5,0,0,1,1.18-.36c2,0,3.32,1.72,3.32,3.92,0,2.56-1.62,4.69-3.87,4.69s-3.88-2.05-3.88-5.31a8.5,8.5,0,0,1,5.07-7.92,13.89,13.89,0,0,1,2.19-.57l.11.73a9.36,9.36,0,0,0-2,.72,6.5,6.5,0,0,0-3.31,4.78Zm.38.9a3.15,3.15,0,0,0-1.67.53,9,9,0,0,0-.11,1.48c0,2.67.85,4.55,2.34,4.55s1.8-1.71,1.8-3.12C272,682.5,271.23,680.85,269.63,680.85Z" />
        <path d="M275.34,677.1l.87-1.35a3.11,3.11,0,0,1,2.42-1.22,2.71,2.71,0,0,1,2.82,2.83,2.43,2.43,0,0,1-.58,1.56,9,9,0,0,1-1.32,1.14,3.31,3.31,0,0,1,2.75,3.24c0,3.43-3.62,4.9-4.9,4.9a2.8,2.8,0,0,1-1.93-.73,1,1,0,0,1-.31-.73,1.31,1.31,0,0,1,.44-.84.34.34,0,0,1,.52,0,3.28,3.28,0,0,0,2.3,1.17c1.25,0,2.15-1.08,2.15-3.12a2.41,2.41,0,0,0-2.4-2.7,4.09,4.09,0,0,0-1.19.21l-.13-.65c1.65-.62,2.9-1.44,2.9-3,0-1.27-.71-2-1.73-2a2.93,2.93,0,0,0-2.28,1.66Z" />
        <path
          d="M246.54,711.91a9.29,9.29,0,0,1,0-14.84l.34.42a9.86,9.86,0,0,0-2.62,7,10.09,10.09,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M248.41,700.59l.76-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.14,1.71,1.71,0,0,1-.51,1.18,7.26,7.26,0,0,1-1.15.87,2.64,2.64,0,0,1,2.4,2.46c0,2.59-3.16,3.7-4.27,3.7a2.64,2.64,0,0,1-1.69-.55.72.72,0,0,1-.26-.55.93.93,0,0,1,.38-.64.32.32,0,0,1,.45,0,3,3,0,0,0,2,.88c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2,4.29,4.29,0,0,0-1,.16l-.11-.49c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.48,2.56,2.56,0,0,0-2,1.25Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M259.3,698.65c2.45,0,3.35,2.72,3.35,5.17s-.9,5.18-3.35,5.18-3.4-2.68-3.4-5.17S256.82,698.65,259.3,698.65Zm0,.58c-1.42,0-1.76,2.35-1.76,4.57s.42,4.62,1.77,4.62,1.74-2.37,1.74-4.61S260.65,699.23,259.29,699.23Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M267.13,698.65c2.45,0,3.35,2.72,3.35,5.17s-.9,5.18-3.35,5.18-3.4-2.68-3.4-5.17S264.65,698.65,267.13,698.65Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.61S268.48,699.23,267.12,699.23Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M280.33,708.81v-.46c.89-.1,1-.16,1-1.26V704.4c0-1.17-.42-1.8-1.33-1.8a2.74,2.74,0,0,0-1.67.77,5.62,5.62,0,0,1,.05.68v3.13c0,1,.12,1.07,1,1.17v.46H275.9v-.46c.94-.1,1.07-.16,1.07-1.19v-2.79c0-1.17-.38-1.78-1.29-1.78a2.84,2.84,0,0,0-1.66.77v3.8c0,1,.11,1.09,1,1.19v.46H271.5v-.46c1-.1,1.11-.17,1.11-1.21v-3.32c0-1-.05-1.08-.92-1.23v-.42a9.77,9.77,0,0,0,2.33-.64v1.28a13.5,13.5,0,0,1,1.14-.8,2.22,2.22,0,0,1,1.29-.43,1.87,1.87,0,0,1,1.75,1.29c.44-.34.83-.6,1.23-.87a2.4,2.4,0,0,1,1.27-.42c1.28,0,2,.9,2,2.48v3.1c0,1,.09,1.08,1,1.19v.46Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M291.44,706.59c-.21.75-.49,1.62-.65,2.22h-6.11v-.39c.87-.86,1.83-1.8,2.62-2.72a6.3,6.3,0,0,0,1.93-3.82c0-1.3-.68-2.06-1.84-2.06a2.78,2.78,0,0,0-2.2,1.48l-.39-.35.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.78c0,1.35-.49,2.3-2,3.84-.68.69-1.66,1.65-2.33,2.27h3c.89,0,1.12-.08,1.67-1.08Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M293,697.07a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.89,9.89,0,0,0,2.63-7,10.05,10.05,0,0,0-2.63-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M450.71,674.84c-1.41.18-1.56.31-1.56,2.08v7.67c0,2.73-.35,4.29-1.68,5.62a5.92,5.92,0,0,1-1.81,1.24l-.29-.67a3.6,3.6,0,0,0,1.82-2.75,26.43,26.43,0,0,0,.19-4v-7.08c0-1.77-.17-1.92-1.66-2.08v-.63h5Z" />
        <path d="M452.59,688.23c-.7,0-1.18-.58-1.18-1.42a1.28,1.28,0,0,1,1.18-1.43,1.31,1.31,0,0,1,1.22,1.43A1.28,1.28,0,0,1,452.59,688.23Z" />
        <path d="M458.52,680a2.5,2.5,0,0,1,1.18-.36c2,0,3.32,1.72,3.32,3.92,0,2.56-1.62,4.69-3.87,4.69s-3.88-2.05-3.88-5.31a8.5,8.5,0,0,1,5.07-7.92,13.89,13.89,0,0,1,2.19-.57l.11.73a9.36,9.36,0,0,0-2,.72,6.5,6.5,0,0,0-3.31,4.78Zm.38.9a3.15,3.15,0,0,0-1.67.53,9,9,0,0,0-.11,1.48c0,2.67.85,4.55,2.34,4.55s1.79-1.71,1.79-3.12C461.25,682.5,460.5,680.85,458.9,680.85Z" />
        <path d="M468.12,674.53c2.82,0,3.85,3.59,3.85,6.82s-1,6.85-3.85,6.85-3.9-3.54-3.9-6.82C464.22,678.28,465.28,674.53,468.12,674.53Zm0,.77c-1.63,0-2,3.09-2,6s.5,6.11,2,6.11,2-3.13,2-6.08S469.67,675.3,468.11,675.3Z" />
        <path
          d="M435.81,711.91a9.29,9.29,0,0,1,0-14.84l.34.42a9.86,9.86,0,0,0-2.62,7,10.09,10.09,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M437.68,700.59l.76-1a2.84,2.84,0,0,1,2.11-.93,2.21,2.21,0,0,1,2.45,2.14,1.7,1.7,0,0,1-.5,1.18,7.26,7.26,0,0,1-1.15.87,2.64,2.64,0,0,1,2.4,2.46c0,2.59-3.16,3.7-4.27,3.7a2.64,2.64,0,0,1-1.69-.55.72.72,0,0,1-.26-.55.93.93,0,0,1,.38-.64.32.32,0,0,1,.45,0,3,3,0,0,0,2,.88c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2,4.29,4.29,0,0,0-1,.16l-.11-.49c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.48,2.56,2.56,0,0,0-2,1.25Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M448.57,698.65c2.45,0,3.35,2.72,3.35,5.17S451,709,448.57,709s-3.4-2.68-3.4-5.17S446.09,698.65,448.57,698.65Zm0,.58c-1.42,0-1.76,2.35-1.76,4.57s.42,4.62,1.77,4.62,1.73-2.37,1.73-4.61S449.92,699.23,448.56,699.23Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M456.39,698.65c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.39-2.68-3.39-5.17S453.92,698.65,456.39,698.65Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.61S457.75,699.23,456.39,699.23Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M469.6,708.81v-.46c.89-.1,1-.16,1-1.26V704.4c0-1.17-.43-1.8-1.33-1.8a2.74,2.74,0,0,0-1.67.77,5.62,5.62,0,0,1,0,.68v3.13c0,1,.12,1.07,1,1.17v.46h-3.46v-.46c.94-.1,1.07-.16,1.07-1.19v-2.79c0-1.17-.38-1.78-1.29-1.78a2.84,2.84,0,0,0-1.66.77v3.8c0,1,.11,1.09,1,1.19v.46h-3.48v-.46c1-.1,1.11-.17,1.11-1.21v-3.32c0-1-.05-1.08-.92-1.23v-.42a9.77,9.77,0,0,0,2.33-.64v1.28a13.5,13.5,0,0,1,1.14-.8,2.21,2.21,0,0,1,1.28-.43,1.87,1.87,0,0,1,1.76,1.29c.44-.34.83-.6,1.23-.87a2.4,2.4,0,0,1,1.27-.42c1.28,0,2,.9,2,2.48v3.1c0,1,.09,1.08,1,1.19v.46Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M480.71,706.59c-.21.75-.49,1.62-.65,2.22H474v-.39c.87-.86,1.83-1.8,2.62-2.72a6.3,6.3,0,0,0,1.93-3.82c0-1.3-.68-2.06-1.84-2.06a2.78,2.78,0,0,0-2.2,1.48l-.39-.35.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.78c0,1.35-.49,2.3-2,3.84-.68.69-1.67,1.65-2.33,2.27h3c.88,0,1.12-.08,1.67-1.08Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M482.24,697.07a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.85,9.85,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M515.46,674.84c-1.41.18-1.56.31-1.56,2.08v7.67c0,2.73-.35,4.29-1.67,5.62a6,6,0,0,1-1.81,1.24l-.3-.67A3.61,3.61,0,0,0,512,688a28,28,0,0,0,.18-4v-7.08c0-1.77-.17-1.92-1.66-2.08v-.63h5Z" />
        <path d="M517.35,688.23a1.26,1.26,0,0,1-1.19-1.42,1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.28,1.28,0,0,1,517.35,688.23Z" />
        <path d="M527,674.78l.11.18-.5,1.52H522.3c-.16,1.11-.4,2.72-.57,3.72a7.78,7.78,0,0,1,2.22-.62,3.44,3.44,0,0,1,3.48,3.59,4.7,4.7,0,0,1-1.63,3.57,5.37,5.37,0,0,1-3.29,1.46,2.64,2.64,0,0,1-1.92-.8.81.81,0,0,1-.32-.74,1.19,1.19,0,0,1,.42-.79.37.37,0,0,1,.55,0,3.11,3.11,0,0,0,2.33,1.19c1.47,0,2.17-1.46,2.17-3,0-1.87-1-3.18-3-3.18a5.08,5.08,0,0,0-1.85.37l-.06-.09c.29-1.7.7-4.63.91-6.37Z" />
        <path d="M534.65,681.88l-1.18.69a2.06,2.06,0,0,1-1.09.33c-2,0-3.35-1.75-3.35-4,0-2,1.38-4.41,3.85-4.41s3.76,2.42,3.76,5.3A8.45,8.45,0,0,1,532,687.7a8,8,0,0,1-2.28.56l-.15-.64C532.19,687,534.06,685,534.65,681.88Zm-2-6.59c-1.36,0-1.84,1.47-1.84,3,0,1.86.89,3.4,2.41,3.4a2.44,2.44,0,0,0,1.53-.55,11.72,11.72,0,0,0,.08-1.4C534.81,677.83,534.34,675.29,532.63,675.29Z" />
        <path
          d="M500.56,711.91a9.31,9.31,0,0,1,0-14.84l.34.42a9.86,9.86,0,0,0-2.62,7,10.14,10.14,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M502.43,700.59l.76-1a2.86,2.86,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.14,1.67,1.67,0,0,1-.51,1.18,6.82,6.82,0,0,1-1.15.87,2.63,2.63,0,0,1,2.4,2.46c0,2.59-3.15,3.7-4.27,3.7a2.61,2.61,0,0,1-1.68-.55.73.73,0,0,1-.27-.55.93.93,0,0,1,.38-.64.33.33,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2,4.29,4.29,0,0,0-1,.16l-.11-.49c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.5-1.48,2.53,2.53,0,0,0-2,1.25Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M513.32,698.65c2.46,0,3.35,2.72,3.35,5.17s-.89,5.18-3.35,5.18-3.4-2.68-3.4-5.17S510.84,698.65,513.32,698.65Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.61S514.67,699.23,513.31,699.23Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M521.15,698.65c2.46,0,3.35,2.72,3.35,5.17s-.89,5.18-3.35,5.18-3.4-2.68-3.4-5.17S518.67,698.65,521.15,698.65Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.61S522.5,699.23,521.14,699.23Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M534.35,708.81v-.46c.89-.1,1-.16,1-1.26V704.4c0-1.17-.43-1.8-1.33-1.8a2.7,2.7,0,0,0-1.67.77c0,.19,0,.39,0,.68v3.13c0,1,.13,1.07,1,1.17v.46h-3.46v-.46c1-.1,1.08-.16,1.08-1.19v-2.79c0-1.17-.39-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.8c0,1,.11,1.09,1,1.19v.46h-3.48v-.46c1-.1,1.11-.17,1.11-1.21v-3.32c0-1,0-1.08-.92-1.23v-.42a9.52,9.52,0,0,0,2.33-.64v1.28a13.82,13.82,0,0,1,1.15-.8,2.17,2.17,0,0,1,1.28-.43,1.86,1.86,0,0,1,1.75,1.29c.44-.34.84-.6,1.24-.87a2.38,2.38,0,0,1,1.27-.42c1.27,0,2,.9,2,2.48v3.1c0,1,.1,1.08,1,1.19v.46Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M545.47,706.59c-.22.75-.5,1.62-.66,2.22H538.7v-.39c.88-.86,1.83-1.8,2.62-2.72a6.3,6.3,0,0,0,1.93-3.82c0-1.3-.68-2.06-1.83-2.06a2.79,2.79,0,0,0-2.21,1.48l-.38-.35.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.78c0,1.35-.5,2.3-2,3.84-.67.69-1.66,1.65-2.33,2.27h3c.89,0,1.12-.08,1.67-1.08Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M547,697.07a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.89,9.89,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M590.6,674.84c-1.41.18-1.56.31-1.56,2.08v7.67c0,2.73-.35,4.29-1.67,5.62a6,6,0,0,1-1.81,1.24l-.3-.67a3.61,3.61,0,0,0,1.83-2.75,28,28,0,0,0,.18-4v-7.08c0-1.77-.16-1.92-1.65-2.08v-.63h5Z" />
        <path d="M592.49,688.23a1.26,1.26,0,0,1-1.19-1.42,1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.28,1.28,0,0,1,592.49,688.23Z" />
        <path d="M602.12,674.78l.11.18-.5,1.52h-4.29c-.16,1.11-.4,2.72-.57,3.72a7.78,7.78,0,0,1,2.22-.62,3.44,3.44,0,0,1,3.48,3.59,4.7,4.7,0,0,1-1.63,3.57,5.34,5.34,0,0,1-3.29,1.46,2.64,2.64,0,0,1-1.92-.8.81.81,0,0,1-.32-.74,1.19,1.19,0,0,1,.42-.79.37.37,0,0,1,.55,0,3.11,3.11,0,0,0,2.33,1.19c1.47,0,2.17-1.46,2.17-3,0-1.87-1-3.18-3-3.18a5.08,5.08,0,0,0-1.85.37l-.06-.09c.29-1.7.7-4.63.91-6.37Z" />
        <path d="M608.15,674.53a3,3,0,0,1,3.07,3.15,2.77,2.77,0,0,1-.53,1.7,18.22,18.22,0,0,1-1.49,1.31c1.27.8,2.5,1.81,2.5,3.65a3.77,3.77,0,0,1-3.77,3.86,3.38,3.38,0,0,1-3.63-3.5,2.62,2.62,0,0,1,.84-2.06,16.61,16.61,0,0,1,1.65-1.29,3.93,3.93,0,0,1-2-3.32A3.38,3.38,0,0,1,608.15,674.53Zm0,12.92c1.2,0,1.93-.89,1.93-2.41s-1.17-2.45-2.72-3.32a3.27,3.27,0,0,0-1.44,2.81C605.92,686.49,607,687.45,608.15,687.45Zm-.24-12.19c-1.06,0-1.62,1-1.62,2.12,0,1.51.9,2.1,2.34,3a3.11,3.11,0,0,0,1.12-2.55C609.75,676,608.83,675.26,607.91,675.26Z" />
        <path
          d="M575.7,711.91a9.31,9.31,0,0,1,0-14.84l.34.42a9.86,9.86,0,0,0-2.62,7,10.14,10.14,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M582.63,705.92v1.18c0,1,.07,1.13,1.22,1.24v.47H580v-.47c1.19-.11,1.27-.23,1.27-1.27v-1.15H577v-.51c1.55-2.19,3.25-4.57,4.81-6.7h.8v6.42h1.56v.79Zm-1.4-5.44c-1,1.4-2.09,3-3.19,4.65h3.19Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M588.58,698.65a2.49,2.49,0,0,1,2.68,2.39,1.88,1.88,0,0,1-.47,1.28,12.33,12.33,0,0,1-1.3,1c1.11.61,2.19,1.38,2.19,2.77a3.1,3.1,0,0,1-3.29,2.92c-1.84,0-3.16-1-3.16-2.65a1.86,1.86,0,0,1,.73-1.56,15,15,0,0,1,1.44-1,2.93,2.93,0,0,1-1.77-2.51A2.75,2.75,0,0,1,588.58,698.65Zm0,9.79a1.62,1.62,0,0,0,1.68-1.83c0-1.25-1-1.86-2.37-2.52a2.41,2.41,0,0,0-1.25,2.13A2,2,0,0,0,588.58,708.44Zm-.21-9.24a1.43,1.43,0,0,0-1.41,1.6c0,1.15.79,1.6,2,2.24a2.24,2.24,0,0,0,1-1.93C590,699.76,589.17,699.2,588.37,699.2Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M596.29,698.65c2.46,0,3.35,2.72,3.35,5.17s-.89,5.18-3.35,5.18-3.4-2.68-3.4-5.17S593.81,698.65,596.29,698.65Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.61S597.64,699.23,596.28,699.23Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M609.5,708.81v-.46c.88-.1,1-.16,1-1.26V704.4c0-1.17-.43-1.8-1.33-1.8a2.7,2.7,0,0,0-1.67.77c0,.19,0,.39,0,.68v3.13c0,1,.13,1.07,1,1.17v.46h-3.45v-.46c.94-.1,1.07-.16,1.07-1.19v-2.79c0-1.17-.39-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.8c0,1,.11,1.09,1,1.19v.46h-3.48v-.46c1-.1,1.12-.17,1.12-1.21v-3.32c0-1-.06-1.08-.93-1.23v-.42a9.52,9.52,0,0,0,2.33-.64v1.28a13.82,13.82,0,0,1,1.15-.8,2.19,2.19,0,0,1,1.28-.43,1.86,1.86,0,0,1,1.75,1.29c.44-.34.84-.6,1.24-.87a2.38,2.38,0,0,1,1.27-.42c1.27,0,2,.9,2,2.48v3.1c0,1,.1,1.08,1,1.19v.46Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M620.61,706.59c-.22.75-.5,1.62-.66,2.22h-6.11v-.39c.88-.86,1.83-1.8,2.62-2.72a6.3,6.3,0,0,0,1.93-3.82c0-1.3-.68-2.06-1.83-2.06a2.79,2.79,0,0,0-2.21,1.48L614,701l.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.78c0,1.35-.49,2.3-2,3.84-.67.69-1.66,1.65-2.33,2.27h3c.89,0,1.12-.08,1.67-1.08Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M622.13,697.07a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.89,9.89,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M261.44,541.87c-1.41.19-1.56.31-1.56,2.09v7.67c0,2.72-.35,4.29-1.68,5.61a5.8,5.8,0,0,1-1.81,1.24l-.29-.66a3.6,3.6,0,0,0,1.82-2.76,26.21,26.21,0,0,0,.19-4V544c0-1.78-.17-1.92-1.66-2.09v-.63h5Z" />
        <path d="M263.32,555.27a1.27,1.27,0,0,1-1.18-1.43,1.29,1.29,0,0,1,1.18-1.43,1.31,1.31,0,0,1,1.22,1.43A1.29,1.29,0,0,1,263.32,555.27Z" />
        <path d="M273,541.81l.11.18-.49,1.52h-4.29c-.17,1.12-.41,2.72-.57,3.73a7.42,7.42,0,0,1,2.21-.62,3.44,3.44,0,0,1,3.49,3.58,4.72,4.72,0,0,1-1.63,3.57,5.37,5.37,0,0,1-3.29,1.46,2.62,2.62,0,0,1-1.92-.8.82.82,0,0,1-.33-.74,1.24,1.24,0,0,1,.42-.79.38.38,0,0,1,.55,0,3.15,3.15,0,0,0,2.33,1.19c1.48,0,2.17-1.46,2.17-3,0-1.87-1-3.18-3-3.18a5.08,5.08,0,0,0-1.85.37l-.07-.09c.3-1.7.71-4.63.92-6.37Z" />
        <path d="M278.85,541.56c2.82,0,3.85,3.59,3.85,6.83,0,3.08-1,6.84-3.85,6.84s-3.9-3.54-3.9-6.82C275,545.31,276,541.56,278.85,541.56Zm0,.77c-1.63,0-2,3.1-2,6s.49,6.1,2,6.1,2-3.13,2-6.08S280.4,542.33,278.84,542.33Z" />
        <path
          d="M246.54,578.94a9.29,9.29,0,0,1,0-14.84l.34.42a9.86,9.86,0,0,0-2.62,7,10.07,10.07,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M248.41,567.63l.76-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.71,1.71,0,0,1-.51,1.17,7.26,7.26,0,0,1-1.15.87,2.64,2.64,0,0,1,2.4,2.46c0,2.6-3.16,3.71-4.27,3.71a2.63,2.63,0,0,1-1.69-.56.72.72,0,0,1-.26-.55,1,1,0,0,1,.38-.64.32.32,0,0,1,.45,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.36a2,2,0,0,0-2.09-2.05,3.83,3.83,0,0,0-1,.17l-.11-.5c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.48,2.56,2.56,0,0,0-2,1.25Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M259.3,565.68c2.45,0,3.35,2.72,3.35,5.17s-.9,5.19-3.35,5.19-3.4-2.69-3.4-5.17S256.82,565.68,259.3,565.68Zm0,.58c-1.42,0-1.76,2.35-1.76,4.57s.42,4.62,1.77,4.62,1.74-2.37,1.74-4.61S260.65,566.26,259.29,566.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M267.13,565.68c2.45,0,3.35,2.72,3.35,5.17s-.9,5.19-3.35,5.19-3.4-2.69-3.4-5.17S264.65,565.68,267.13,565.68Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.61S268.48,566.26,267.12,566.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M280.33,575.84v-.46c.89-.1,1-.16,1-1.26v-2.69c0-1.17-.42-1.8-1.33-1.8a2.74,2.74,0,0,0-1.67.77,5.62,5.62,0,0,1,.05.68v3.13c0,1,.12,1.07,1,1.17v.46H275.9v-.46c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.88,2.88,0,0,0-1.66.76v3.8c0,1,.11,1.09,1,1.19v.46H271.5v-.46c1-.1,1.11-.17,1.11-1.2v-3.33c0-1-.05-1.08-.92-1.23v-.42a9.77,9.77,0,0,0,2.33-.64v1.28a13.5,13.5,0,0,1,1.14-.8,2.29,2.29,0,0,1,1.29-.43,1.86,1.86,0,0,1,1.75,1.3c.44-.35.83-.61,1.23-.88a2.4,2.4,0,0,1,1.27-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.46Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M291.44,573.62c-.21.75-.49,1.62-.65,2.22h-6.11v-.39c.87-.86,1.83-1.8,2.62-2.71,1-1.22,1.93-2.46,1.93-3.83s-.68-2.06-1.84-2.06a2.78,2.78,0,0,0-2.2,1.48l-.39-.35.93-1.33a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.78c0,1.35-.49,2.3-2,3.84-.68.7-1.66,1.65-2.33,2.27h3c.89,0,1.12-.07,1.67-1.08Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M293,564.1a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.89,9.89,0,0,0,2.63-7,10.07,10.07,0,0,0-2.63-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M261.44,456c-1.41.19-1.56.31-1.56,2.09v7.67c0,2.73-.35,4.29-1.68,5.62a5.75,5.75,0,0,1-1.81,1.23l-.29-.66a3.6,3.6,0,0,0,1.82-2.76,26.21,26.21,0,0,0,.19-4v-7.08c0-1.78-.17-1.92-1.66-2.09v-.62h5Z" />
        <path d="M263.32,469.43c-.69,0-1.18-.58-1.18-1.43a1.28,1.28,0,0,1,1.18-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,263.32,469.43Z" />
        <path d="M272.16,465.33v1.55c0,1.37.07,1.49,1.4,1.64v.62H269.1v-.62c1.36-.15,1.44-.3,1.44-1.67v-1.52h-4.82v-.68c1.78-2.89,3.73-6,5.51-8.85h.93v8.47h1.78v1.06Zm-1.62-7.19c-1.1,1.85-2.39,4-3.65,6.13h3.65Z" />
        <path d="M280.63,463.08l-1.18.69a2.16,2.16,0,0,1-1.09.32c-2,0-3.36-1.75-3.36-4,0-2,1.38-4.41,3.85-4.41s3.76,2.43,3.76,5.3a8.41,8.41,0,0,1-4.68,7.87,7.9,7.9,0,0,1-2.28.57l-.14-.65C278.17,468.21,280,466.23,280.63,463.08Zm-2-6.59c-1.35,0-1.83,1.46-1.83,3,0,1.86.89,3.4,2.4,3.4a2.45,2.45,0,0,0,1.54-.56,11.72,11.72,0,0,0,.08-1.4C280.79,459,280.32,456.48,278.6,456.49Z" />
        <path
          d="M246.54,493.1a9.29,9.29,0,0,1,0-14.84l.34.42a9.88,9.88,0,0,0-2.62,7,10.07,10.07,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M248.41,481.79l.76-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.75,1.75,0,0,1-.51,1.18,7.28,7.28,0,0,1-1.15.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.16,3.71-4.27,3.71a2.63,2.63,0,0,1-1.69-.56.7.7,0,0,1-.26-.55,1,1,0,0,1,.38-.64.33.33,0,0,1,.45,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.35a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16l-.11-.5c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.48,2.54,2.54,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M259.3,479.84c2.45,0,3.35,2.72,3.35,5.17s-.9,5.19-3.35,5.19-3.4-2.68-3.4-5.17C255.9,482.69,256.82,479.84,259.3,479.84Zm0,.58c-1.42,0-1.76,2.35-1.76,4.57s.42,4.62,1.77,4.62S261,487.24,261,485,260.65,480.42,259.29,480.42Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M267.13,479.84c2.45,0,3.35,2.72,3.35,5.17s-.9,5.19-3.35,5.19-3.4-2.68-3.4-5.17C263.73,482.69,264.65,479.84,267.13,479.84Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.6S268.48,480.42,267.12,480.42Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M280.33,490v-.47c.89-.1,1-.16,1-1.25V485.6c0-1.18-.42-1.81-1.33-1.81a2.74,2.74,0,0,0-1.67.77,5.65,5.65,0,0,1,.05.69v3.13c0,1,.12,1.06,1,1.16V490H275.9v-.47c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.84,2.84,0,0,0-1.66.77v3.79c0,1,.11,1.09,1,1.19V490H271.5v-.47c1-.1,1.11-.16,1.11-1.2V485c0-1-.05-1.08-.92-1.23v-.42a9.77,9.77,0,0,0,2.33-.64V484a13.5,13.5,0,0,1,1.14-.8,2.29,2.29,0,0,1,1.29-.43,1.86,1.86,0,0,1,1.75,1.3c.44-.34.83-.61,1.23-.88a2.5,2.5,0,0,1,1.27-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19V490Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M291.44,487.78c-.21.75-.49,1.62-.65,2.23h-6.11v-.4c.87-.86,1.83-1.8,2.62-2.71,1-1.22,1.93-2.46,1.93-3.83s-.68-2.05-1.84-2.05a2.77,2.77,0,0,0-2.2,1.47l-.39-.35.93-1.33a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.83-.68.7-1.66,1.65-2.33,2.28h3c.89,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M293,478.26a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.87,9.87,0,0,0,2.63-7,10,10,0,0,0-2.63-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M260.73,320.45c-1.41.19-1.56.32-1.56,2.09v7.67c0,2.73-.35,4.29-1.68,5.62a5.69,5.69,0,0,1-1.81,1.23l-.29-.66a3.6,3.6,0,0,0,1.82-2.75,26.43,26.43,0,0,0,.19-4v-7.08c0-1.77-.17-1.92-1.66-2.09v-.62h5Z" />
        <path d="M262.61,333.85c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,262.61,333.85Z" />
        <path d="M265.64,322.71l.87-1.34a3.12,3.12,0,0,1,2.43-1.23,2.71,2.71,0,0,1,2.81,2.84,2.4,2.4,0,0,1-.58,1.55,8,8,0,0,1-1.32,1.15,3.31,3.31,0,0,1,2.75,3.24c0,3.43-3.62,4.9-4.9,4.9a2.82,2.82,0,0,1-1.93-.73,1,1,0,0,1-.31-.73,1.37,1.37,0,0,1,.44-.85.35.35,0,0,1,.53,0,3.27,3.27,0,0,0,2.3,1.16c1.25,0,2.15-1.07,2.15-3.11a2.42,2.42,0,0,0-2.41-2.71,3.77,3.77,0,0,0-1.19.22l-.13-.66c1.65-.62,2.91-1.44,2.91-3a1.72,1.72,0,0,0-1.73-2,2.94,2.94,0,0,0-2.29,1.66Z" />
        <path d="M275.27,333.56v-.62c2-.14,2.12-.27,2.12-1.84v-8c0-1.07-.16-1.12-1-1.21l-.84-.11v-.56a14.16,14.16,0,0,0,3.49-1v11c0,1.59.11,1.7,2.14,1.84v.62Z" />
        <path
          d="M245.83,357.52a9.28,9.28,0,0,1,0-14.83l.34.41a9.9,9.9,0,0,0-2.62,7,10.1,10.1,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M247.7,346.21l.76-1a2.84,2.84,0,0,1,2.11-.92,2.21,2.21,0,0,1,2.45,2.14,1.72,1.72,0,0,1-.5,1.18,6.71,6.71,0,0,1-1.15.86,2.66,2.66,0,0,1,2.4,2.46c0,2.6-3.16,3.71-4.27,3.71a2.7,2.7,0,0,1-1.69-.55.73.73,0,0,1-.27-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16L249,349c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.49,2.57,2.57,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M258.59,344.27c2.45,0,3.35,2.72,3.35,5.17s-.9,5.18-3.35,5.18-3.4-2.68-3.4-5.17C255.19,347.11,256.11,344.27,258.59,344.27Zm0,.58c-1.42,0-1.76,2.34-1.76,4.56s.42,4.63,1.77,4.63,1.73-2.37,1.73-4.61S259.94,344.85,258.58,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M266.41,344.27c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.39-2.68-3.39-5.17C263,347.11,263.94,344.27,266.41,344.27Zm0,.58c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S267.77,344.85,266.41,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M279.62,354.43V354c.89-.09,1-.16,1-1.25V350c0-1.17-.43-1.81-1.33-1.81a2.76,2.76,0,0,0-1.67.78,5.62,5.62,0,0,1,.05.68v3.13c0,1,.12,1.07,1,1.16v.47h-3.46V354c.94-.09,1.07-.15,1.07-1.19V350c0-1.18-.38-1.78-1.29-1.78a2.84,2.84,0,0,0-1.66.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48V354c1-.1,1.11-.16,1.11-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10.17,10.17,0,0,0,2.34-.63v1.28a9.23,9.23,0,0,1,1.14-.8,2.21,2.21,0,0,1,1.28-.43,1.87,1.87,0,0,1,1.76,1.29c.43-.34.83-.6,1.23-.88a2.49,2.49,0,0,1,1.27-.41c1.28,0,2,.9,2,2.48v3.09c0,1,.09,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M290.73,352.21c-.21.74-.49,1.61-.65,2.22H284V354c.87-.86,1.83-1.81,2.62-2.72a6.28,6.28,0,0,0,1.92-3.82c0-1.3-.67-2.06-1.83-2.06a2.78,2.78,0,0,0-2.2,1.48l-.39-.35.93-1.34a3,3,0,0,1,2.24-1,2.64,2.64,0,0,1,2.76,2.78c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.33,2.27h3c.88,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M292.26,342.69a9.3,9.3,0,0,1,0,14.83l-.36-.41a9.85,9.85,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M322.57,320.45c-1.41.19-1.56.32-1.56,2.09v7.67c0,2.73-.35,4.29-1.67,5.62a5.8,5.8,0,0,1-1.81,1.23l-.3-.66a3.61,3.61,0,0,0,1.83-2.75,28,28,0,0,0,.18-4v-7.08c0-1.77-.17-1.92-1.66-2.09v-.62h5Z" />
        <path d="M324.46,333.85c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.31,1.31,0,0,1,1.22,1.42A1.29,1.29,0,0,1,324.46,333.85Z" />
        <path d="M327.48,322.71l.88-1.34a3.12,3.12,0,0,1,2.42-1.23A2.71,2.71,0,0,1,333.6,323a2.45,2.45,0,0,1-.58,1.55,8.15,8.15,0,0,1-1.33,1.15,3.32,3.32,0,0,1,2.76,3.24c0,3.43-3.62,4.9-4.9,4.9a2.83,2.83,0,0,1-1.94-.73,1,1,0,0,1-.3-.73,1.36,1.36,0,0,1,.43-.85.35.35,0,0,1,.53,0,3.27,3.27,0,0,0,2.3,1.16c1.25,0,2.15-1.07,2.15-3.11a2.42,2.42,0,0,0-2.4-2.71,3.71,3.71,0,0,0-1.19.22l-.13-.66c1.64-.62,2.9-1.44,2.9-3a1.72,1.72,0,0,0-1.73-2,2.9,2.9,0,0,0-2.28,1.66Z" />
        <path d="M343.84,330.63c-.25,1-.57,2.13-.75,2.93h-7v-.51c1-1.14,2.1-2.39,3-3.59,1.2-1.6,2.21-3.25,2.21-5s-.77-2.72-2.1-2.72-2,1.1-2.53,1.95l-.44-.45,1.07-1.77a3.19,3.19,0,0,1,2.57-1.28c1.76,0,3.17,1.39,3.17,3.68,0,1.77-.57,3-2.34,5.07-.77.92-1.91,2.17-2.68,3h3.44c1,0,1.28-.1,1.92-1.44Z" />
        <path
          d="M307.67,357.52a9.3,9.3,0,0,1,0-14.83l.35.41a9.86,9.86,0,0,0-2.63,7,10.11,10.11,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M309.55,346.21l.76-1a2.84,2.84,0,0,1,2.11-.92,2.21,2.21,0,0,1,2.45,2.14,1.72,1.72,0,0,1-.5,1.18,6.45,6.45,0,0,1-1.16.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.66,2.66,0,0,1-1.68-.55.73.73,0,0,1-.27-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.89c1.1,0,1.88-.82,1.88-2.36a2,2,0,0,0-2.1-2.05,3.84,3.84,0,0,0-1,.16l-.11-.49c1.43-.47,2.52-1.09,2.52-2.26a1.38,1.38,0,0,0-1.5-1.49,2.55,2.55,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M320.43,344.27c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.39-2.68-3.39-5.17C317,347.11,318,344.27,320.43,344.27Zm0,.58c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S321.79,344.85,320.43,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M328.26,344.27c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.4-2.68-3.4-5.17C324.86,347.11,325.79,344.27,328.26,344.27Zm0,.58c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S329.61,344.85,328.26,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M341.47,354.43V354c.89-.09,1-.16,1-1.25V350c0-1.17-.43-1.81-1.33-1.81a2.74,2.74,0,0,0-1.67.78,5.92,5.92,0,0,1,0,.68v3.13c0,1,.13,1.07,1,1.16v.47H337V354c.94-.09,1.07-.15,1.07-1.19V350c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48V354c1-.1,1.11-.16,1.11-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.21,2.21,0,0,1,1.28-.43,1.88,1.88,0,0,1,1.76,1.29c.43-.34.83-.6,1.23-.88a2.46,2.46,0,0,1,1.27-.41c1.27,0,2,.9,2,2.48v3.09c0,1,.09,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M352.58,352.21c-.22.74-.49,1.61-.65,2.22h-6.12V354c.88-.86,1.83-1.81,2.62-2.72a6.24,6.24,0,0,0,1.93-3.82c0-1.3-.68-2.06-1.83-2.06a2.76,2.76,0,0,0-2.2,1.48l-.39-.35.93-1.34a3,3,0,0,1,2.24-1,2.64,2.64,0,0,1,2.76,2.78c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.34,2.27h3c.88,0,1.11-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M354.11,342.69a9.3,9.3,0,0,1,0,14.83l-.36-.41a9.89,9.89,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M388.85,320.45c-1.41.19-1.56.32-1.56,2.09v7.67c0,2.73-.35,4.29-1.67,5.62a5.8,5.8,0,0,1-1.81,1.23l-.3-.66a3.61,3.61,0,0,0,1.83-2.75,28,28,0,0,0,.18-4v-7.08c0-1.77-.16-1.92-1.65-2.09v-.62h5Z" />
        <path d="M390.74,333.85c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.31,1.31,0,0,1,1.22,1.42A1.29,1.29,0,0,1,390.74,333.85Z" />
        <path d="M393.76,322.71l.88-1.34a3.12,3.12,0,0,1,2.42-1.23,2.71,2.71,0,0,1,2.82,2.84,2.4,2.4,0,0,1-.58,1.55,8.15,8.15,0,0,1-1.33,1.15,3.31,3.31,0,0,1,2.76,3.24c0,3.43-3.62,4.9-4.9,4.9a2.85,2.85,0,0,1-1.94-.73,1,1,0,0,1-.3-.73,1.32,1.32,0,0,1,.44-.85.34.34,0,0,1,.52,0,3.27,3.27,0,0,0,2.3,1.16c1.25,0,2.15-1.07,2.15-3.11a2.41,2.41,0,0,0-2.4-2.71,3.77,3.77,0,0,0-1.19.22l-.13-.66c1.64-.62,2.9-1.44,2.9-3a1.72,1.72,0,0,0-1.73-2,2.9,2.9,0,0,0-2.28,1.66Z" />
        <path d="M402.75,322.71l.87-1.34a3.12,3.12,0,0,1,2.43-1.23,2.71,2.71,0,0,1,2.81,2.84,2.4,2.4,0,0,1-.58,1.55,8,8,0,0,1-1.32,1.15,3.31,3.31,0,0,1,2.75,3.24c0,3.43-3.62,4.9-4.9,4.9a2.82,2.82,0,0,1-1.93-.73,1,1,0,0,1-.31-.73,1.37,1.37,0,0,1,.44-.85.35.35,0,0,1,.53,0,3.27,3.27,0,0,0,2.3,1.16c1.25,0,2.15-1.07,2.15-3.11a2.42,2.42,0,0,0-2.41-2.71,3.77,3.77,0,0,0-1.19.22l-.13-.66c1.65-.62,2.91-1.44,2.91-3a1.72,1.72,0,0,0-1.73-2,2.9,2.9,0,0,0-2.28,1.66Z" />
        <path
          d="M374,357.52a9.28,9.28,0,0,1,0-14.83l.34.41a9.9,9.9,0,0,0-2.63,7,10.11,10.11,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M375.83,346.21l.76-1a2.84,2.84,0,0,1,2.11-.92,2.21,2.21,0,0,1,2.45,2.14,1.72,1.72,0,0,1-.5,1.18,6.45,6.45,0,0,1-1.16.86c1.52.33,2.41,1.29,2.41,2.46,0,2.6-3.16,3.71-4.27,3.71a2.67,2.67,0,0,1-1.69-.55.73.73,0,0,1-.27-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16l-.11-.49c1.43-.47,2.52-1.09,2.52-2.26a1.38,1.38,0,0,0-1.5-1.49,2.57,2.57,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M386.71,344.27c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.39-2.68-3.39-5.17C383.32,347.11,384.24,344.27,386.71,344.27Zm0,.58c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S388.07,344.85,386.71,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M394.54,344.27c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.39-2.68-3.39-5.17C391.15,347.11,392.07,344.27,394.54,344.27Zm0,.58c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S395.9,344.85,394.54,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M407.75,354.43V354c.89-.09,1-.16,1-1.25V350c0-1.17-.43-1.81-1.33-1.81a2.74,2.74,0,0,0-1.67.78,5.62,5.62,0,0,1,.05.68v3.13c0,1,.12,1.07,1,1.16v.47h-3.46V354c.94-.09,1.07-.15,1.07-1.19V350c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48V354c1-.1,1.11-.16,1.11-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.21,2.21,0,0,1,1.28-.43,1.88,1.88,0,0,1,1.76,1.29c.43-.34.83-.6,1.23-.88a2.46,2.46,0,0,1,1.27-.41c1.27,0,2,.9,2,2.48v3.09c0,1,.09,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M418.86,352.21c-.21.74-.49,1.61-.65,2.22H412.1V354c.87-.86,1.82-1.81,2.61-2.72a6.24,6.24,0,0,0,1.93-3.82c0-1.3-.68-2.06-1.83-2.06a2.76,2.76,0,0,0-2.2,1.48l-.39-.35.93-1.34a3,3,0,0,1,2.24-1,2.64,2.64,0,0,1,2.76,2.78c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.33,2.27h3c.88,0,1.11-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M420.39,342.69a9.3,9.3,0,0,1,0,14.83l-.36-.41a9.89,9.89,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M450.7,320.45c-1.4.19-1.55.32-1.55,2.09v7.67c0,2.73-.36,4.29-1.68,5.62a5.8,5.8,0,0,1-1.81,1.23l-.29-.66a3.6,3.6,0,0,0,1.82-2.75,27.8,27.8,0,0,0,.19-4v-7.08c0-1.77-.17-1.92-1.66-2.09v-.62h5Z" />
        <path d="M452.59,333.85c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,452.59,333.85Z" />
        <path d="M455.62,322.71l.87-1.34a3.12,3.12,0,0,1,2.42-1.23,2.71,2.71,0,0,1,2.82,2.84,2.4,2.4,0,0,1-.58,1.55,8,8,0,0,1-1.32,1.15,3.31,3.31,0,0,1,2.75,3.24c0,3.43-3.62,4.9-4.9,4.9a2.8,2.8,0,0,1-1.93-.73,1,1,0,0,1-.31-.73,1.37,1.37,0,0,1,.44-.85.34.34,0,0,1,.52,0,3.27,3.27,0,0,0,2.3,1.16c1.26,0,2.15-1.07,2.15-3.11a2.41,2.41,0,0,0-2.4-2.71,3.77,3.77,0,0,0-1.19.22l-.13-.66c1.65-.62,2.9-1.44,2.9-3a1.72,1.72,0,0,0-1.73-2,2.9,2.9,0,0,0-2.28,1.66Z" />
        <path d="M470.41,329.75v1.55c0,1.37.07,1.49,1.4,1.64v.62h-4.46v-.62c1.36-.15,1.44-.3,1.44-1.67v-1.52H464v-.68c1.78-2.88,3.73-6,5.51-8.85h.93v8.48h1.78v1.05Zm-1.62-7.19c-1.1,1.85-2.39,4-3.65,6.14h3.65Z" />
        <path
          d="M435.81,357.52a9.28,9.28,0,0,1,0-14.83l.34.41a9.9,9.9,0,0,0-2.62,7,10.1,10.1,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M437.68,346.21l.76-1a2.84,2.84,0,0,1,2.11-.92,2.21,2.21,0,0,1,2.45,2.14,1.72,1.72,0,0,1-.5,1.18,6.71,6.71,0,0,1-1.15.86,2.66,2.66,0,0,1,2.4,2.46c0,2.6-3.16,3.71-4.27,3.71a2.7,2.7,0,0,1-1.69-.55.72.72,0,0,1-.26-.55.91.91,0,0,1,.38-.64.33.33,0,0,1,.45,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16L439,349c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.49,2.57,2.57,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M448.57,344.27c2.45,0,3.35,2.72,3.35,5.17s-.9,5.18-3.35,5.18-3.4-2.68-3.4-5.17C445.17,347.11,446.09,344.27,448.57,344.27Zm0,.58c-1.42,0-1.76,2.34-1.76,4.56s.42,4.63,1.77,4.63,1.73-2.37,1.73-4.61S449.92,344.85,448.56,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M456.39,344.27c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.39-2.68-3.39-5.17C453,347.11,453.92,344.27,456.39,344.27Zm0,.58c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S457.75,344.85,456.39,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M469.6,354.43V354c.89-.09,1-.16,1-1.25V350c0-1.17-.43-1.81-1.33-1.81a2.76,2.76,0,0,0-1.67.78,5.62,5.62,0,0,1,0,.68v3.13c0,1,.12,1.07,1,1.16v.47h-3.46V354c.94-.09,1.07-.15,1.07-1.19V350c0-1.18-.38-1.78-1.29-1.78a2.84,2.84,0,0,0-1.66.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48V354c1-.1,1.11-.16,1.11-1.2v-3.33c0-1-.05-1.08-.92-1.23v-.42a10.23,10.23,0,0,0,2.33-.63v1.28a10.06,10.06,0,0,1,1.14-.8,2.21,2.21,0,0,1,1.28-.43,1.87,1.87,0,0,1,1.76,1.29c.44-.34.83-.6,1.23-.88a2.49,2.49,0,0,1,1.27-.41c1.28,0,2,.9,2,2.48v3.09c0,1,.09,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M480.71,352.21c-.21.74-.49,1.61-.65,2.22H474V354c.87-.86,1.83-1.81,2.62-2.72a6.3,6.3,0,0,0,1.93-3.82c0-1.3-.68-2.06-1.84-2.06a2.78,2.78,0,0,0-2.2,1.48l-.39-.35.93-1.34a3,3,0,0,1,2.24-1,2.64,2.64,0,0,1,2.76,2.78c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.33,2.27h3c.88,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M482.24,342.69a9.3,9.3,0,0,1,0,14.83l-.36-.41a9.85,9.85,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M514.53,320.45c-1.4.19-1.55.32-1.55,2.09v7.67c0,2.73-.36,4.29-1.68,5.62a5.8,5.8,0,0,1-1.81,1.23l-.29-.66a3.6,3.6,0,0,0,1.82-2.75,27.8,27.8,0,0,0,.19-4v-7.08c0-1.77-.17-1.92-1.66-2.09v-.62h5Z" />
        <path d="M516.42,333.85c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,516.42,333.85Z" />
        <path d="M519.45,322.71l.87-1.34a3.12,3.12,0,0,1,2.42-1.23,2.71,2.71,0,0,1,2.82,2.84,2.4,2.4,0,0,1-.58,1.55,8,8,0,0,1-1.32,1.15,3.31,3.31,0,0,1,2.75,3.24c0,3.43-3.62,4.9-4.9,4.9a2.8,2.8,0,0,1-1.93-.73,1,1,0,0,1-.31-.73,1.37,1.37,0,0,1,.44-.85.34.34,0,0,1,.52,0,3.27,3.27,0,0,0,2.3,1.16c1.26,0,2.15-1.07,2.15-3.11a2.41,2.41,0,0,0-2.4-2.71,3.77,3.77,0,0,0-1.19.22l-.13-.66c1.65-.62,2.9-1.44,2.9-3a1.72,1.72,0,0,0-1.73-2,2.9,2.9,0,0,0-2.28,1.66Z" />
        <path d="M535,320.4l.11.17-.49,1.53h-4.29c-.17,1.11-.41,2.71-.57,3.72a7.73,7.73,0,0,1,2.21-.62,3.44,3.44,0,0,1,3.49,3.58,4.7,4.7,0,0,1-1.63,3.57,5.33,5.33,0,0,1-3.29,1.47,2.64,2.64,0,0,1-1.92-.8c-.28-.29-.36-.47-.32-.75a1.27,1.27,0,0,1,.41-.79.38.38,0,0,1,.55,0,3.15,3.15,0,0,0,2.34,1.19c1.47,0,2.16-1.46,2.16-3,0-1.87-1-3.19-3-3.19a5.06,5.06,0,0,0-1.85.38l-.06-.09c.29-1.7.7-4.63.91-6.37Z" />
        <path
          d="M499.63,357.52a9.3,9.3,0,0,1,0-14.83l.35.41a9.86,9.86,0,0,0-2.63,7,10.11,10.11,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M501.5,346.21l.77-1a2.84,2.84,0,0,1,2.11-.92,2.21,2.21,0,0,1,2.45,2.14,1.72,1.72,0,0,1-.5,1.18,6.84,6.84,0,0,1-1.16.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.66,2.66,0,0,1-1.68-.55.73.73,0,0,1-.27-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.89c1.1,0,1.88-.82,1.88-2.36a2,2,0,0,0-2.1-2.05,3.84,3.84,0,0,0-1,.16l-.12-.49c1.44-.47,2.53-1.09,2.53-2.26a1.38,1.38,0,0,0-1.5-1.49,2.54,2.54,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M512.39,344.27c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.39-2.68-3.39-5.17C509,347.11,509.92,344.27,512.39,344.27Zm0,.58c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S513.75,344.85,512.39,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M520.22,344.27c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.4-2.68-3.4-5.17C516.82,347.11,517.74,344.27,520.22,344.27Zm0,.58c-1.41,0-1.76,2.34-1.76,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S521.57,344.85,520.21,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M533.43,354.43V354c.89-.09,1-.16,1-1.25V350c0-1.17-.43-1.81-1.33-1.81a2.74,2.74,0,0,0-1.67.78c0,.19,0,.39,0,.68v3.13c0,1,.13,1.07,1,1.16v.47H529V354c.94-.09,1.07-.15,1.07-1.19V350c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.49V354c1-.1,1.12-.16,1.12-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.19,2.19,0,0,1,1.28-.43,1.86,1.86,0,0,1,1.75,1.29c.44-.34.84-.6,1.24-.88a2.46,2.46,0,0,1,1.27-.41c1.27,0,2,.9,2,2.48v3.09c0,1,.1,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M544.54,352.21c-.22.74-.49,1.61-.65,2.22h-6.12V354c.88-.86,1.83-1.81,2.62-2.72a6.24,6.24,0,0,0,1.93-3.82c0-1.3-.68-2.06-1.83-2.06a2.76,2.76,0,0,0-2.2,1.48l-.39-.35.93-1.34a3,3,0,0,1,2.24-1,2.64,2.64,0,0,1,2.76,2.78c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.34,2.27h3c.89,0,1.12-.08,1.68-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M546.07,342.69a9.3,9.3,0,0,1,0,14.83l-.36-.41a9.89,9.89,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M576.38,320.45c-1.41.19-1.56.32-1.56,2.09v7.67c0,2.73-.35,4.29-1.67,5.62a5.8,5.8,0,0,1-1.81,1.23l-.3-.66a3.61,3.61,0,0,0,1.83-2.75,28,28,0,0,0,.18-4v-7.08c0-1.77-.17-1.92-1.66-2.09v-.62h5Z" />
        <path d="M578.27,333.85c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.31,1.31,0,0,1,1.22,1.42A1.29,1.29,0,0,1,578.27,333.85Z" />
        <path d="M581.29,322.71l.88-1.34a3.12,3.12,0,0,1,2.42-1.23,2.71,2.71,0,0,1,2.82,2.84,2.4,2.4,0,0,1-.58,1.55,8.15,8.15,0,0,1-1.33,1.15,3.31,3.31,0,0,1,2.76,3.24c0,3.43-3.62,4.9-4.9,4.9a2.83,2.83,0,0,1-1.94-.73,1,1,0,0,1-.3-.73,1.36,1.36,0,0,1,.43-.85.35.35,0,0,1,.53,0,3.27,3.27,0,0,0,2.3,1.16c1.25,0,2.15-1.07,2.15-3.11a2.42,2.42,0,0,0-2.4-2.71,3.71,3.71,0,0,0-1.19.22l-.13-.66c1.64-.62,2.9-1.44,2.9-3a1.72,1.72,0,0,0-1.73-2,2.9,2.9,0,0,0-2.28,1.66Z" />
        <path d="M593.18,325.57a2.41,2.41,0,0,1,1.18-.37c2,0,3.32,1.72,3.32,3.92,0,2.56-1.62,4.7-3.87,4.7s-3.88-2.05-3.88-5.31a8.5,8.5,0,0,1,5.07-7.92,13.89,13.89,0,0,1,2.19-.57l.11.72a9.77,9.77,0,0,0-2,.73,6.48,6.48,0,0,0-3.31,4.78Zm.38.89a3.24,3.24,0,0,0-1.67.53,8,8,0,0,0-.12,1.49c0,2.67.86,4.55,2.35,4.55s1.79-1.72,1.79-3.12C595.91,328.12,595.16,326.46,593.56,326.46Z" />
        <path
          d="M561.48,357.52a9.3,9.3,0,0,1,0-14.83l.35.41a9.86,9.86,0,0,0-2.63,7,10.11,10.11,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M563.35,346.21l.77-1a2.84,2.84,0,0,1,2.11-.92,2.21,2.21,0,0,1,2.45,2.14,1.72,1.72,0,0,1-.5,1.18,6.84,6.84,0,0,1-1.16.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.66,2.66,0,0,1-1.68-.55.73.73,0,0,1-.27-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.88-.82,1.88-2.36a2,2,0,0,0-2.1-2.05,3.84,3.84,0,0,0-1,.16l-.12-.49c1.44-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.5-1.49,2.54,2.54,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M574.24,344.27c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.4-2.68-3.4-5.17C570.84,347.11,571.77,344.27,574.24,344.27Zm0,.58c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S575.59,344.85,574.24,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M582.07,344.27c2.46,0,3.35,2.72,3.35,5.17s-.89,5.18-3.35,5.18-3.4-2.68-3.4-5.17C578.67,347.11,579.59,344.27,582.07,344.27Zm0,.58c-1.41,0-1.76,2.34-1.76,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S583.42,344.85,582.06,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M595.28,354.43V354c.89-.09,1-.16,1-1.25V350c0-1.17-.43-1.81-1.33-1.81a2.74,2.74,0,0,0-1.67.78c0,.19,0,.39,0,.68v3.13c0,1,.13,1.07,1,1.16v.47h-3.45V354c.94-.09,1.07-.15,1.07-1.19V350c0-1.18-.38-1.78-1.29-1.78A2.89,2.89,0,0,0,589,349v3.79c0,1,.11,1.1,1,1.19v.47h-3.49V354c1-.1,1.12-.16,1.12-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.19,2.19,0,0,1,1.28-.43,1.86,1.86,0,0,1,1.75,1.29c.44-.34.84-.6,1.24-.88a2.46,2.46,0,0,1,1.27-.41c1.27,0,2,.9,2,2.48v3.09c0,1,.1,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M606.39,352.21c-.22.74-.49,1.61-.65,2.22h-6.12V354c.88-.86,1.83-1.81,2.62-2.72a6.24,6.24,0,0,0,1.93-3.82c0-1.3-.68-2.06-1.83-2.06a2.79,2.79,0,0,0-2.21,1.48l-.38-.35.93-1.34a3,3,0,0,1,2.24-1,2.64,2.64,0,0,1,2.76,2.78c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.34,2.27h3c.89,0,1.12-.08,1.68-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M607.92,342.69a9.3,9.3,0,0,1,0,14.83l-.36-.41a9.89,9.89,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M634.35,320.45c-1.4.19-1.55.32-1.55,2.09v7.67c0,2.73-.36,4.29-1.68,5.62a5.8,5.8,0,0,1-1.81,1.23l-.29-.66a3.6,3.6,0,0,0,1.82-2.75,27.8,27.8,0,0,0,.19-4v-7.08c0-1.77-.17-1.92-1.66-2.09v-.62h5Z" />
        <path d="M636.24,333.85c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,636.24,333.85Z" />
        <path d="M639.27,322.71l.87-1.34a3.12,3.12,0,0,1,2.42-1.23,2.71,2.71,0,0,1,2.82,2.84,2.4,2.4,0,0,1-.58,1.55,8,8,0,0,1-1.32,1.15,3.31,3.31,0,0,1,2.75,3.24c0,3.43-3.62,4.9-4.9,4.9a2.8,2.8,0,0,1-1.93-.73,1,1,0,0,1-.31-.73,1.37,1.37,0,0,1,.44-.85.34.34,0,0,1,.52,0,3.27,3.27,0,0,0,2.3,1.16c1.26,0,2.15-1.07,2.15-3.11a2.41,2.41,0,0,0-2.4-2.71,3.77,3.77,0,0,0-1.19.22l-.13-.66c1.65-.62,2.9-1.44,2.9-3a1.72,1.72,0,0,0-1.72-2,2.91,2.91,0,0,0-2.29,1.66Z" />
        <path d="M655.79,320.68c-1.76,4.28-3.49,8.56-5.25,12.92l-1.41.17-.13-.24c1.92-3.58,3.92-7.86,5.39-11.43h-3.92c-1.21,0-1.29.18-1.79,1.55h-.59c.2-1.28.28-2.34.37-3.25h7.22Z" />
        <path
          d="M623.61,357.52a9.3,9.3,0,0,1,0-14.83l.34.41a9.9,9.9,0,0,0-2.62,7,10.15,10.15,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M625.48,346.21l.76-1a2.86,2.86,0,0,1,2.11-.92,2.22,2.22,0,0,1,2.46,2.14,1.69,1.69,0,0,1-.51,1.18,6.34,6.34,0,0,1-1.15.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.72,2.72,0,0,1-1.69-.55.72.72,0,0,1-.26-.55.91.91,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16l-.11-.49c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.49,2.57,2.57,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M636.37,344.27c2.46,0,3.35,2.72,3.35,5.17s-.89,5.18-3.35,5.18-3.4-2.68-3.4-5.17C633,347.11,633.89,344.27,636.37,344.27Zm0,.58c-1.41,0-1.76,2.34-1.76,4.56S635,354,636.37,354s1.74-2.37,1.74-4.61S637.72,344.85,636.36,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M644.2,344.27c2.46,0,3.35,2.72,3.35,5.17s-.89,5.18-3.35,5.18-3.4-2.68-3.4-5.17C640.8,347.11,641.72,344.27,644.2,344.27Zm0,.58c-1.42,0-1.76,2.34-1.76,4.56s.42,4.63,1.77,4.63,1.74-2.37,1.74-4.61S645.55,344.85,644.19,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M657.4,354.43V354c.89-.09,1-.16,1-1.25V350c0-1.17-.43-1.81-1.33-1.81a2.72,2.72,0,0,0-1.67.78c0,.19,0,.39,0,.68v3.13c0,1,.13,1.07,1,1.16v.47H653V354c.95-.09,1.07-.15,1.07-1.19V350c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.66.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48V354c1-.1,1.11-.16,1.11-1.2v-3.33c0-1,0-1.08-.92-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a9.41,9.41,0,0,1,1.15-.8,2.17,2.17,0,0,1,1.28-.43,1.87,1.87,0,0,1,1.75,1.29c.44-.34.84-.6,1.24-.88a2.45,2.45,0,0,1,1.26-.41c1.28,0,2,.9,2,2.48v3.09c0,1,.09,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M668.52,352.21c-.22.74-.5,1.61-.66,2.22h-6.11V354c.87-.86,1.83-1.81,2.62-2.72a6.3,6.3,0,0,0,1.93-3.82c0-1.3-.68-2.06-1.84-2.06a2.79,2.79,0,0,0-2.2,1.48l-.39-.35.94-1.34a2.94,2.94,0,0,1,2.23-1,2.65,2.65,0,0,1,2.77,2.78c0,1.34-.5,2.29-2,3.84-.67.69-1.66,1.64-2.33,2.27h3c.89,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M670,342.69a9.28,9.28,0,0,1,0,14.83l-.35-.41a9.89,9.89,0,0,0,2.63-7,10.05,10.05,0,0,0-2.63-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M696.21,320.45c-1.41.19-1.56.32-1.56,2.09v7.67c0,2.73-.36,4.29-1.68,5.62a5.69,5.69,0,0,1-1.81,1.23l-.29-.66a3.6,3.6,0,0,0,1.82-2.75,26.43,26.43,0,0,0,.19-4v-7.08c0-1.77-.17-1.92-1.66-2.09v-.62h5Z" />
        <path d="M698.09,333.85c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,698.09,333.85Z" />
        <path d="M701.12,322.71l.87-1.34a3.12,3.12,0,0,1,2.42-1.23,2.71,2.71,0,0,1,2.82,2.84,2.4,2.4,0,0,1-.58,1.55,8,8,0,0,1-1.32,1.15,3.31,3.31,0,0,1,2.75,3.24c0,3.43-3.62,4.9-4.9,4.9a2.82,2.82,0,0,1-1.93-.73,1,1,0,0,1-.31-.73,1.37,1.37,0,0,1,.44-.85.35.35,0,0,1,.53,0,3.27,3.27,0,0,0,2.29,1.16c1.26,0,2.15-1.07,2.15-3.11a2.41,2.41,0,0,0-2.4-2.71,3.77,3.77,0,0,0-1.19.22l-.13-.66c1.65-.62,2.9-1.44,2.9-3,0-1.26-.7-2-1.72-2a2.91,2.91,0,0,0-2.29,1.66Z" />
        <path d="M713.76,320.14a3,3,0,0,1,3.06,3.16,2.71,2.71,0,0,1-.53,1.7,15.46,15.46,0,0,1-1.49,1.3c1.27.8,2.51,1.82,2.51,3.65a3.78,3.78,0,0,1-3.78,3.87,3.38,3.38,0,0,1-3.62-3.51,2.63,2.63,0,0,1,.83-2.06A18.64,18.64,0,0,1,712.4,327a3.91,3.91,0,0,1-2-3.31A3.39,3.39,0,0,1,713.76,320.14Zm0,12.93c1.19,0,1.92-.9,1.92-2.41s-1.17-2.46-2.72-3.33a3.29,3.29,0,0,0-1.44,2.82C711.52,332.1,712.58,333.07,713.76,333.07Zm-.25-12.19c-1.06,0-1.62.95-1.62,2.11,0,1.52.91,2.11,2.35,3a3.15,3.15,0,0,0,1.11-2.55C715.35,321.61,714.43,320.88,713.51,320.88Z" />
        <path
          d="M685.46,357.52a9.3,9.3,0,0,1,0-14.83l.34.41a9.9,9.9,0,0,0-2.62,7,10.15,10.15,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M687.33,346.21l.76-1a2.86,2.86,0,0,1,2.11-.92,2.22,2.22,0,0,1,2.46,2.14,1.69,1.69,0,0,1-.51,1.18,6.34,6.34,0,0,1-1.15.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.66,2.66,0,0,1-1.68-.55.73.73,0,0,1-.27-.55.91.91,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2.05,3.92,3.92,0,0,0-1,.16l-.11-.49c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.5-1.49,2.54,2.54,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M698.22,344.27c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.4-2.68-3.4-5.17C694.82,347.11,695.74,344.27,698.22,344.27Zm0,.58c-1.41,0-1.76,2.34-1.76,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S699.57,344.85,698.21,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M706.05,344.27c2.46,0,3.35,2.72,3.35,5.17s-.89,5.18-3.35,5.18-3.4-2.68-3.4-5.17C702.65,347.11,703.57,344.27,706.05,344.27Zm0,.58c-1.41,0-1.76,2.34-1.76,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S707.4,344.85,706,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M719.25,354.43V354c.89-.09,1-.16,1-1.25V350c0-1.17-.43-1.81-1.33-1.81a2.72,2.72,0,0,0-1.67.78c0,.19,0,.39,0,.68v3.13c0,1,.13,1.07,1,1.16v.47h-3.45V354c.94-.09,1.07-.15,1.07-1.19V350c0-1.18-.39-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48V354c1-.1,1.12-.16,1.12-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.17,2.17,0,0,1,1.28-.43,1.86,1.86,0,0,1,1.75,1.29c.44-.34.84-.6,1.24-.88a2.46,2.46,0,0,1,1.27-.41c1.27,0,2,.9,2,2.48v3.09c0,1,.1,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M730.37,352.21c-.22.74-.5,1.61-.66,2.22H723.6V354c.88-.86,1.83-1.81,2.62-2.72a6.3,6.3,0,0,0,1.93-3.82c0-1.3-.68-2.06-1.83-2.06a2.79,2.79,0,0,0-2.21,1.48l-.38-.35.93-1.34a3,3,0,0,1,2.24-1,2.64,2.64,0,0,1,2.76,2.78c0,1.34-.49,2.29-2,3.84-.67.69-1.66,1.64-2.33,2.27h3c.89,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M731.89,342.69a9.28,9.28,0,0,1,0,14.83l-.35-.41a9.89,9.89,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M760.62,320.45c-1.41.19-1.56.32-1.56,2.09v7.67c0,2.73-.35,4.29-1.67,5.62a5.8,5.8,0,0,1-1.81,1.23l-.3-.66a3.58,3.58,0,0,0,1.82-2.75,26.43,26.43,0,0,0,.19-4v-7.08c0-1.77-.17-1.92-1.66-2.09v-.62h5Z" />
        <path d="M762.5,333.85c-.69,0-1.18-.58-1.18-1.43A1.28,1.28,0,0,1,762.5,331a1.3,1.3,0,0,1,1.22,1.42A1.28,1.28,0,0,1,762.5,333.85Z" />
        <path d="M765.53,322.71l.88-1.34a3.1,3.1,0,0,1,2.42-1.23,2.71,2.71,0,0,1,2.82,2.84,2.45,2.45,0,0,1-.58,1.55,8.15,8.15,0,0,1-1.33,1.15,3.32,3.32,0,0,1,2.76,3.24c0,3.43-3.62,4.9-4.9,4.9a2.83,2.83,0,0,1-1.94-.73,1,1,0,0,1-.3-.73,1.31,1.31,0,0,1,.43-.85.35.35,0,0,1,.53,0,3.27,3.27,0,0,0,2.3,1.16c1.25,0,2.15-1.07,2.15-3.11a2.42,2.42,0,0,0-2.4-2.71,3.71,3.71,0,0,0-1.19.22l-.13-.66c1.64-.62,2.9-1.44,2.9-3a1.72,1.72,0,0,0-1.73-2,2.9,2.9,0,0,0-2.28,1.66Z" />
        <path d="M779.81,327.5l-1.18.69a2.14,2.14,0,0,1-1.09.32c-2,0-3.36-1.75-3.36-4,0-2,1.38-4.41,3.86-4.41s3.75,2.43,3.75,5.3a8.41,8.41,0,0,1-4.68,7.87,7.9,7.9,0,0,1-2.28.57l-.14-.65C777.35,332.63,779.22,330.65,779.81,327.5Zm-2-6.59c-1.36,0-1.84,1.47-1.84,3,0,1.86.89,3.4,2.4,3.4a2.4,2.4,0,0,0,1.54-.56,11.72,11.72,0,0,0,.08-1.4C780,323.44,779.5,320.91,777.79,320.91Z" />
        <path
          d="M749.88,357.52a9.29,9.29,0,0,1,0-14.84l.34.42a9.9,9.9,0,0,0-2.63,7,10.12,10.12,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M751.75,346.21l.76-1a2.84,2.84,0,0,1,2.11-.93,2.21,2.21,0,0,1,2.45,2.15,1.72,1.72,0,0,1-.5,1.18,6.45,6.45,0,0,1-1.16.86,2.65,2.65,0,0,1,2.41,2.46c0,2.6-3.16,3.71-4.28,3.71a2.6,2.6,0,0,1-1.68-.56.72.72,0,0,1-.27-.55,1,1,0,0,1,.38-.64.35.35,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16l-.11-.5c1.43-.47,2.52-1.09,2.52-2.25a1.38,1.38,0,0,0-1.5-1.49,2.53,2.53,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M762.63,344.26c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.68-3.39-5.17C759.24,347.11,760.16,344.26,762.63,344.26Zm0,.59c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S764,344.85,762.63,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M770.46,344.26c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.68-3.39-5.17C767.07,347.11,768,344.26,770.46,344.26Zm0,.59c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S771.82,344.85,770.46,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M783.67,354.43V354c.89-.09,1-.16,1-1.25V350c0-1.17-.43-1.81-1.33-1.81a2.72,2.72,0,0,0-1.67.77,6,6,0,0,1,.05.69v3.13c0,1,.12,1.07,1,1.16v.47h-3.46V354c.94-.09,1.07-.16,1.07-1.19V350c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48V354c1-.1,1.11-.16,1.11-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a11.71,11.71,0,0,1,1.15-.81,2.29,2.29,0,0,1,1.28-.43,1.87,1.87,0,0,1,1.76,1.3c.43-.34.83-.6,1.23-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M794.78,352.21c-.21.74-.49,1.61-.65,2.22H788V354c.87-.85,1.82-1.8,2.61-2.71a6.24,6.24,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.83-2.05a2.77,2.77,0,0,0-2.2,1.47l-.39-.34.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.33,2.27h3c.88,0,1.11-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M796.31,342.68a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.85,9.85,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M837.84,320.45c-1.41.19-1.56.32-1.56,2.09v7.67c0,2.73-.35,4.29-1.68,5.62a5.69,5.69,0,0,1-1.81,1.23l-.29-.66a3.6,3.6,0,0,0,1.82-2.75,26.43,26.43,0,0,0,.19-4v-7.08c0-1.77-.17-1.92-1.66-2.09v-.62h5Z" />
        <path d="M839.72,333.85c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,839.72,333.85Z" />
        <path d="M848.55,329.75v1.55c0,1.37.08,1.49,1.41,1.64v.62H845.5v-.62c1.36-.15,1.44-.3,1.44-1.67v-1.52h-4.83v-.68c1.79-2.88,3.73-6,5.52-8.85h.92v8.48h1.79v1.05Zm-1.61-7.19c-1.11,1.85-2.39,4-3.65,6.14h3.65Z" />
        <path d="M855.25,320.14c2.82,0,3.85,3.6,3.85,6.83s-1,6.85-3.85,6.85-3.9-3.54-3.9-6.83C851.35,323.9,852.4,320.14,855.25,320.14Zm0,.77c-1.63,0-2,3.1-2,6s.5,6.11,2,6.11,2-3.13,2-6.09S856.8,320.91,855.24,320.91Z" />
        <path
          d="M827.09,357.52a9.31,9.31,0,0,1,0-14.84l.35.42a9.86,9.86,0,0,0-2.63,7,10.12,10.12,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M829,346.21l.76-1a2.88,2.88,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.72,1.72,0,0,1-.5,1.18,6.84,6.84,0,0,1-1.16.86,2.64,2.64,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.6,2.6,0,0,1-1.68-.56.72.72,0,0,1-.27-.55,1,1,0,0,1,.38-.64.35.35,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.88-.82,1.88-2.36a2,2,0,0,0-2.1-2.05,3.84,3.84,0,0,0-1,.16l-.12-.5c1.44-.47,2.53-1.09,2.53-2.25a1.39,1.39,0,0,0-1.5-1.49,2.51,2.51,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M840,344.26a2.49,2.49,0,0,1,2.68,2.39,1.89,1.89,0,0,1-.47,1.29,12.33,12.33,0,0,1-1.3,1c1.11.61,2.19,1.37,2.19,2.76a3.1,3.1,0,0,1-3.29,2.93c-1.84,0-3.16-1-3.16-2.65a1.89,1.89,0,0,1,.73-1.57c.26-.22.9-.63,1.44-1a3,3,0,0,1-1.77-2.51A2.75,2.75,0,0,1,840,344.26Zm0,9.79a1.61,1.61,0,0,0,1.68-1.82c0-1.25-1-1.86-2.37-2.52a2.41,2.41,0,0,0-1.25,2.13A2,2,0,0,0,840,354.05Zm-.21-9.23a1.42,1.42,0,0,0-1.41,1.6c0,1.15.79,1.6,2,2.24a2.26,2.26,0,0,0,1-1.93C841.37,345.37,840.56,344.82,839.76,344.82Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M850.37,344.46l.1.13-.43,1.15H846.3c-.15.85-.35,2.06-.5,2.82a7.83,7.83,0,0,1,1.93-.47,2.8,2.8,0,0,1,3,2.72,3.37,3.37,0,0,1-1.42,2.7,5,5,0,0,1-2.87,1.11,2.46,2.46,0,0,1-1.67-.61c-.25-.21-.31-.35-.28-.56a.91.91,0,0,1,.36-.6.37.37,0,0,1,.48,0,2.87,2.87,0,0,0,2,.9c1.28,0,1.89-1.1,1.89-2.3a2.29,2.29,0,0,0-2.58-2.41,4.83,4.83,0,0,0-1.61.29l-.06-.07c.26-1.29.61-3.51.8-4.82Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M860.89,354.43V354c.89-.09,1-.16,1-1.25V350c0-1.17-.43-1.81-1.33-1.81a2.72,2.72,0,0,0-1.67.77c0,.2,0,.4,0,.69v3.13c0,1,.13,1.07,1,1.16v.47h-3.45V354c.94-.09,1.07-.16,1.07-1.19V350c0-1.18-.39-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.49V354c1-.1,1.12-.16,1.12-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a11.71,11.71,0,0,1,1.15-.81,2.26,2.26,0,0,1,1.28-.43,1.85,1.85,0,0,1,1.75,1.3c.44-.34.84-.6,1.24-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.1,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M872,352.21c-.22.74-.5,1.61-.66,2.22h-6.11V354c.88-.85,1.83-1.8,2.62-2.71a6.3,6.3,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.83-2.05a2.81,2.81,0,0,0-2.21,1.47l-.38-.34.93-1.34a2.92,2.92,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.84-.67.69-1.66,1.64-2.33,2.27h3c.89,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M873.53,342.68a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.85,9.85,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M260.73,238.58c-1.41.19-1.56.32-1.56,2.09v7.67c0,2.73-.35,4.29-1.68,5.62a5.92,5.92,0,0,1-1.81,1.24l-.29-.67a3.6,3.6,0,0,0,1.82-2.75,26.43,26.43,0,0,0,.19-4v-7.08c0-1.77-.17-1.92-1.66-2.09V238h5Z" />
        <path d="M262.61,252c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,262.61,252Z" />
        <path d="M265.64,240.85l.87-1.35a3.12,3.12,0,0,1,2.43-1.23,2.71,2.71,0,0,1,2.81,2.84,2.4,2.4,0,0,1-.58,1.55,8,8,0,0,1-1.32,1.15,3.31,3.31,0,0,1,2.75,3.24c0,3.43-3.62,4.9-4.9,4.9a2.82,2.82,0,0,1-1.93-.73,1,1,0,0,1-.31-.73,1.37,1.37,0,0,1,.44-.85.35.35,0,0,1,.53,0,3.27,3.27,0,0,0,2.3,1.16c1.25,0,2.15-1.07,2.15-3.11a2.42,2.42,0,0,0-2.41-2.71,4.07,4.07,0,0,0-1.19.22l-.13-.66c1.65-.62,2.91-1.43,2.91-3a1.72,1.72,0,0,0-1.73-2,2.94,2.94,0,0,0-2.29,1.66Z" />
        <path d="M278.14,238.27c2.82,0,3.85,3.6,3.85,6.83s-1,6.85-3.85,6.85-3.9-3.54-3.9-6.83C274.24,242,275.29,238.27,278.14,238.27Zm0,.78c-1.63,0-2,3.09-2,6s.5,6.11,2,6.11,2-3.13,2-6.09S279.69,239.05,278.13,239.05Z" />
        <path
          d="M245.83,275.65a9.29,9.29,0,0,1,0-14.84l.34.42a9.9,9.9,0,0,0-2.62,7,10.1,10.1,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M247.7,264.34l.76-1a2.84,2.84,0,0,1,2.11-.93,2.21,2.21,0,0,1,2.45,2.15,1.72,1.72,0,0,1-.5,1.18,6.71,6.71,0,0,1-1.15.86,2.66,2.66,0,0,1,2.4,2.46c0,2.6-3.16,3.71-4.27,3.71a2.7,2.7,0,0,1-1.69-.55.75.75,0,0,1-.27-.56,1,1,0,0,1,.38-.64.35.35,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16l-.11-.49c1.43-.48,2.53-1.1,2.53-2.26a1.39,1.39,0,0,0-1.51-1.49,2.54,2.54,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M258.59,262.39c2.45,0,3.35,2.72,3.35,5.17s-.9,5.19-3.35,5.19-3.4-2.68-3.4-5.17C255.19,265.24,256.11,262.39,258.59,262.39Zm0,.59c-1.42,0-1.76,2.34-1.76,4.56s.42,4.63,1.77,4.63,1.73-2.37,1.73-4.61S259.94,263,258.58,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M266.41,262.39c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.68-3.39-5.17C263,265.24,263.94,262.39,266.41,262.39Zm0,.59c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S267.77,263,266.41,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M279.62,272.56v-.47c.89-.09,1-.16,1-1.25v-2.69c0-1.17-.43-1.81-1.33-1.81a2.8,2.8,0,0,0-1.67.77,6,6,0,0,1,.05.69v3.13c0,1,.12,1.07,1,1.16v.47h-3.46v-.47c.94-.09,1.07-.15,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.84,2.84,0,0,0-1.66.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48v-.47c1-.1,1.11-.16,1.11-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10.17,10.17,0,0,0,2.34-.63v1.28a9.23,9.23,0,0,1,1.14-.8,2.22,2.22,0,0,1,1.28-.44,1.88,1.88,0,0,1,1.76,1.3c.43-.34.83-.6,1.23-.88a2.5,2.5,0,0,1,1.27-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M290.73,270.34c-.21.74-.49,1.61-.65,2.22H284v-.39c.87-.86,1.83-1.81,2.62-2.72a6.28,6.28,0,0,0,1.92-3.83c0-1.29-.67-2.05-1.83-2.05a2.79,2.79,0,0,0-2.2,1.47l-.39-.34.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.33,2.27h3c.88,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M292.26,260.81a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.8,9.8,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M322.57,238.58c-1.41.19-1.56.32-1.56,2.09v7.67c0,2.73-.35,4.29-1.67,5.62a6,6,0,0,1-1.81,1.24l-.3-.67a3.61,3.61,0,0,0,1.83-2.75,28,28,0,0,0,.18-4v-7.08c0-1.77-.17-1.92-1.66-2.09V238h5Z" />
        <path d="M324.46,252c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.31,1.31,0,0,1,1.22,1.42A1.29,1.29,0,0,1,324.46,252Z" />
        <path d="M334.86,248.76c-.25,1-.57,2.13-.76,2.93h-7v-.51c1-1.14,2.1-2.38,3-3.59,1.19-1.6,2.21-3.25,2.21-5.05s-.78-2.72-2.1-2.72-2,1.1-2.53,2l-.45-.45,1.07-1.77a3.21,3.21,0,0,1,2.57-1.28c1.76,0,3.17,1.39,3.17,3.68,0,1.77-.57,3-2.33,5.07-.78.92-1.92,2.17-2.68,3h3.43c1,0,1.29-.1,1.92-1.43Z" />
        <path d="M341.76,245.63l-1.18.69a2,2,0,0,1-1.09.32c-2,0-3.36-1.74-3.36-4,0-2,1.38-4.41,3.86-4.41s3.76,2.43,3.76,5.3a8.42,8.42,0,0,1-4.69,7.87,7.6,7.6,0,0,1-2.27.57l-.15-.65C339.3,250.76,341.17,248.78,341.76,245.63Zm-2-6.59c-1.36,0-1.84,1.47-1.84,3,0,1.86.89,3.4,2.4,3.4a2.45,2.45,0,0,0,1.54-.55,11.88,11.88,0,0,0,.08-1.41C341.92,241.57,341.45,239,339.74,239Z" />
        <path
          d="M307.67,275.65a9.31,9.31,0,0,1,0-14.84l.35.42a9.86,9.86,0,0,0-2.63,7,10.11,10.11,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M309.55,264.34l.76-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.45,2.15,1.72,1.72,0,0,1-.5,1.18,6.45,6.45,0,0,1-1.16.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.66,2.66,0,0,1-1.68-.55.75.75,0,0,1-.27-.56,1,1,0,0,1,.38-.64.35.35,0,0,1,.46,0,3,3,0,0,0,2,.89c1.1,0,1.88-.82,1.88-2.36a2,2,0,0,0-2.1-2.05,3.84,3.84,0,0,0-1,.16l-.11-.49c1.43-.48,2.52-1.1,2.52-2.26a1.38,1.38,0,0,0-1.5-1.49,2.53,2.53,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M320.43,262.39c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.68-3.39-5.17C317,265.24,318,262.39,320.43,262.39Zm0,.59c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S321.79,263,320.43,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M328.26,262.39c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.4-2.68-3.4-5.17C324.86,265.24,325.79,262.39,328.26,262.39Zm0,.59c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S329.61,263,328.26,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M341.47,272.56v-.47c.89-.09,1-.16,1-1.25v-2.69c0-1.17-.43-1.81-1.33-1.81a2.78,2.78,0,0,0-1.67.77,6.27,6.27,0,0,1,0,.69v3.13c0,1,.13,1.07,1,1.16v.47H337v-.47c.94-.09,1.07-.15,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48v-.47c1-.1,1.11-.16,1.11-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.22,2.22,0,0,1,1.28-.44,1.89,1.89,0,0,1,1.76,1.3c.43-.34.83-.6,1.23-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M352.58,270.34c-.22.74-.49,1.61-.65,2.22h-6.12v-.39c.88-.86,1.83-1.81,2.62-2.72a6.24,6.24,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.83-2.05a2.77,2.77,0,0,0-2.2,1.47l-.39-.34.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.34,2.27h3c.88,0,1.11-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M354.11,260.81a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.85,9.85,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M388.85,238.58c-1.41.19-1.56.32-1.56,2.09v7.67c0,2.73-.35,4.29-1.67,5.62a6,6,0,0,1-1.81,1.24l-.3-.67a3.61,3.61,0,0,0,1.83-2.75,28,28,0,0,0,.18-4v-7.08c0-1.77-.16-1.92-1.65-2.09V238h5Z" />
        <path d="M390.74,252c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.31,1.31,0,0,1,1.22,1.42A1.29,1.29,0,0,1,390.74,252Z" />
        <path d="M401.14,248.76c-.25,1-.57,2.13-.75,2.93h-7v-.51c1-1.14,2.1-2.38,3-3.59,1.19-1.6,2.21-3.25,2.21-5.05s-.78-2.72-2.1-2.72-2,1.1-2.53,2l-.45-.45,1.07-1.77a3.21,3.21,0,0,1,2.57-1.28c1.76,0,3.17,1.39,3.17,3.68,0,1.77-.56,3-2.33,5.07-.78.92-1.91,2.17-2.68,3h3.43c1,0,1.29-.1,1.92-1.43Z" />
        <path d="M406.4,238.27a3,3,0,0,1,3.07,3.16,2.77,2.77,0,0,1-.53,1.7,16.9,16.9,0,0,1-1.49,1.3c1.27.81,2.5,1.82,2.5,3.66a3.77,3.77,0,0,1-3.77,3.86,3.38,3.38,0,0,1-3.63-3.5,2.66,2.66,0,0,1,.84-2.07,18.26,18.26,0,0,1,1.65-1.29,3.9,3.9,0,0,1-2-3.31A3.39,3.39,0,0,1,406.4,238.27Zm0,12.93c1.2,0,1.93-.9,1.93-2.41s-1.17-2.46-2.72-3.33a3.29,3.29,0,0,0-1.44,2.82C404.17,250.23,405.22,251.2,406.4,251.2ZM406.16,239c-1.06,0-1.62,1-1.62,2.11,0,1.52.9,2.11,2.34,3a3.13,3.13,0,0,0,1.12-2.55C408,239.74,407.08,239,406.16,239Z" />
        <path
          d="M374,275.65a9.29,9.29,0,0,1,0-14.84l.34.42a9.9,9.9,0,0,0-2.63,7,10.11,10.11,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M375.83,264.34l.76-1a2.84,2.84,0,0,1,2.11-.93,2.21,2.21,0,0,1,2.45,2.15,1.72,1.72,0,0,1-.5,1.18,6.45,6.45,0,0,1-1.16.86c1.52.33,2.41,1.29,2.41,2.46,0,2.6-3.16,3.71-4.27,3.71a2.67,2.67,0,0,1-1.69-.55.75.75,0,0,1-.27-.56,1,1,0,0,1,.38-.64.35.35,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16l-.11-.49c1.43-.48,2.52-1.1,2.52-2.26a1.38,1.38,0,0,0-1.5-1.49,2.54,2.54,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M386.71,262.39c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.68-3.39-5.17C383.32,265.24,384.24,262.39,386.71,262.39Zm0,.59c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S388.07,263,386.71,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M394.54,262.39c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.68-3.39-5.17C391.15,265.24,392.07,262.39,394.54,262.39Zm0,.59c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S395.9,263,394.54,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M407.75,272.56v-.47c.89-.09,1-.16,1-1.25v-2.69c0-1.17-.43-1.81-1.33-1.81a2.78,2.78,0,0,0-1.67.77,6,6,0,0,1,.05.69v3.13c0,1,.12,1.07,1,1.16v.47h-3.46v-.47c.94-.09,1.07-.15,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48v-.47c1-.1,1.11-.16,1.11-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.22,2.22,0,0,1,1.28-.44,1.89,1.89,0,0,1,1.76,1.3c.43-.34.83-.6,1.23-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M418.86,270.34c-.21.74-.49,1.61-.65,2.22H412.1v-.39c.87-.86,1.82-1.81,2.61-2.72a6.24,6.24,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.83-2.05a2.77,2.77,0,0,0-2.2,1.47l-.39-.34.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.33,2.27h3c.88,0,1.11-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M420.39,260.81a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.85,9.85,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M450.7,238.58c-1.4.19-1.55.32-1.55,2.09v7.67c0,2.73-.36,4.29-1.68,5.62a6,6,0,0,1-1.81,1.24l-.29-.67a3.6,3.6,0,0,0,1.82-2.75,27.8,27.8,0,0,0,.19-4v-7.08c0-1.77-.17-1.92-1.66-2.09V238h5Z" />
        <path d="M452.59,252c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,452.59,252Z" />
        <path d="M463,248.76c-.25,1-.57,2.13-.75,2.93h-7v-.51c1-1.14,2.1-2.38,3-3.59,1.2-1.6,2.21-3.25,2.21-5.05s-.78-2.72-2.1-2.72-2,1.1-2.53,2l-.45-.45,1.07-1.77a3.21,3.21,0,0,1,2.57-1.28c1.77,0,3.17,1.39,3.17,3.68,0,1.77-.56,3-2.33,5.07-.78.92-1.91,2.17-2.68,3h3.43c1,0,1.29-.1,1.92-1.43Z" />
        <path d="M472.14,238.81c-1.76,4.28-3.49,8.56-5.25,12.92l-1.41.17-.13-.24c1.92-3.58,3.91-7.85,5.39-11.43h-3.92c-1.21,0-1.29.18-1.79,1.55h-.59c.19-1.27.28-2.34.37-3.25H472Z" />
        <path
          d="M435.81,275.65a9.29,9.29,0,0,1,0-14.84l.34.42a9.9,9.9,0,0,0-2.62,7,10.1,10.1,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M437.68,264.34l.76-1a2.84,2.84,0,0,1,2.11-.93,2.21,2.21,0,0,1,2.45,2.15,1.72,1.72,0,0,1-.5,1.18,6.71,6.71,0,0,1-1.15.86,2.66,2.66,0,0,1,2.4,2.46c0,2.6-3.16,3.71-4.27,3.71a2.7,2.7,0,0,1-1.69-.55.74.74,0,0,1-.26-.56,1,1,0,0,1,.38-.64.34.34,0,0,1,.45,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16l-.11-.49c1.43-.48,2.53-1.1,2.53-2.26a1.39,1.39,0,0,0-1.51-1.49,2.54,2.54,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M448.57,262.39c2.45,0,3.35,2.72,3.35,5.17s-.9,5.19-3.35,5.19-3.4-2.68-3.4-5.17C445.17,265.24,446.09,262.39,448.57,262.39Zm0,.59c-1.42,0-1.76,2.34-1.76,4.56s.42,4.63,1.77,4.63,1.73-2.37,1.73-4.61S449.92,263,448.56,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M456.39,262.39c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.68-3.39-5.17C453,265.24,453.92,262.39,456.39,262.39Zm0,.59c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S457.75,263,456.39,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M469.6,272.56v-.47c.89-.09,1-.16,1-1.25v-2.69c0-1.17-.43-1.81-1.33-1.81a2.8,2.8,0,0,0-1.67.77,6,6,0,0,1,0,.69v3.13c0,1,.12,1.07,1,1.16v.47h-3.46v-.47c.94-.09,1.07-.15,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.84,2.84,0,0,0-1.66.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48v-.47c1-.1,1.11-.16,1.11-1.2v-3.33c0-1-.05-1.08-.92-1.23v-.42a10.23,10.23,0,0,0,2.33-.63v1.28a10.06,10.06,0,0,1,1.14-.8,2.22,2.22,0,0,1,1.28-.44,1.88,1.88,0,0,1,1.76,1.3c.44-.34.83-.6,1.23-.88a2.5,2.5,0,0,1,1.27-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M480.71,270.34c-.21.74-.49,1.61-.65,2.22H474v-.39c.87-.86,1.83-1.81,2.62-2.72a6.3,6.3,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.84-2.05a2.79,2.79,0,0,0-2.2,1.47l-.39-.34.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.33,2.27h3c.88,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M482.24,260.81a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.8,9.8,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M506.53,238.58c-1.4.19-1.55.32-1.55,2.09v7.67c0,2.73-.36,4.29-1.68,5.62a6,6,0,0,1-1.81,1.24l-.29-.67a3.6,3.6,0,0,0,1.82-2.75,27.8,27.8,0,0,0,.19-4v-7.08c0-1.77-.17-1.92-1.66-2.09V238h5Z" />
        <path d="M508.42,252c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,508.42,252Z" />
        <path d="M518.82,248.76c-.25,1-.57,2.13-.75,2.93h-7v-.51c1-1.14,2.1-2.38,3-3.59,1.2-1.6,2.21-3.25,2.21-5.05s-.78-2.72-2.1-2.72-2,1.1-2.53,2l-.45-.45,1.07-1.77a3.21,3.21,0,0,1,2.57-1.28c1.77,0,3.17,1.39,3.17,3.68,0,1.77-.56,3-2.33,5.07-.78.92-1.91,2.17-2.68,3h3.43c1,0,1.29-.1,1.92-1.43Z" />
        <path d="M523.33,243.7a2.41,2.41,0,0,1,1.18-.37c2,0,3.32,1.72,3.32,3.93,0,2.55-1.62,4.69-3.87,4.69s-3.88-2-3.88-5.31a8.5,8.5,0,0,1,5.07-7.92,13.89,13.89,0,0,1,2.19-.57l.12.72a9.86,9.86,0,0,0-2,.73,6.5,6.5,0,0,0-3.31,4.78Zm.38.9a3.24,3.24,0,0,0-1.67.52,9.08,9.08,0,0,0-.11,1.49c0,2.67.85,4.55,2.35,4.55s1.79-1.72,1.79-3.12C526.07,246.25,525.31,244.6,523.71,244.6Z" />
        <path
          d="M495.63,275.65a9.31,9.31,0,0,1,0-14.84l.35.42a9.86,9.86,0,0,0-2.63,7,10.11,10.11,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M497.5,264.34l.77-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.45,2.15,1.72,1.72,0,0,1-.5,1.18,6.84,6.84,0,0,1-1.16.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.66,2.66,0,0,1-1.68-.55.75.75,0,0,1-.27-.56,1,1,0,0,1,.38-.64.35.35,0,0,1,.46,0,3,3,0,0,0,2,.89c1.1,0,1.88-.82,1.88-2.36a2,2,0,0,0-2.1-2.05,3.84,3.84,0,0,0-1,.16l-.12-.49c1.44-.48,2.53-1.1,2.53-2.26a1.38,1.38,0,0,0-1.5-1.49,2.51,2.51,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M508.39,262.39c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.68-3.39-5.17C505,265.24,505.92,262.39,508.39,262.39Zm0,.59c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S509.75,263,508.39,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M516.22,262.39c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.4-2.68-3.4-5.17C512.82,265.24,513.74,262.39,516.22,262.39Zm0,.59c-1.41,0-1.76,2.34-1.76,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S517.57,263,516.21,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M529.43,272.56v-.47c.89-.09,1-.16,1-1.25v-2.69c0-1.17-.43-1.81-1.33-1.81a2.78,2.78,0,0,0-1.67.77c0,.2,0,.4,0,.69v3.13c0,1,.13,1.07,1,1.16v.47H525v-.47c.94-.09,1.07-.15,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.49v-.47c1-.1,1.12-.16,1.12-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.2,2.2,0,0,1,1.28-.44,1.87,1.87,0,0,1,1.75,1.3c.44-.34.84-.6,1.24-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.1,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M540.54,270.34c-.22.74-.49,1.61-.65,2.22h-6.12v-.39c.88-.86,1.83-1.81,2.62-2.72a6.24,6.24,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.83-2.05a2.77,2.77,0,0,0-2.2,1.47l-.39-.34.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.34,2.27h3c.89,0,1.12-.08,1.68-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M542.07,260.81a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.85,9.85,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M576.38,238.58c-1.41.19-1.56.32-1.56,2.09v7.67c0,2.73-.35,4.29-1.67,5.62a6,6,0,0,1-1.81,1.24l-.3-.67a3.61,3.61,0,0,0,1.83-2.75,28,28,0,0,0,.18-4v-7.08c0-1.77-.17-1.92-1.66-2.09V238h5Z" />
        <path d="M578.27,252c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.31,1.31,0,0,1,1.22,1.42A1.29,1.29,0,0,1,578.27,252Z" />
        <path d="M588.67,248.76c-.25,1-.57,2.13-.75,2.93h-7v-.51c1-1.14,2.1-2.38,3-3.59,1.19-1.6,2.21-3.25,2.21-5.05s-.78-2.72-2.1-2.72-2,1.1-2.53,2l-.45-.45,1.07-1.77a3.21,3.21,0,0,1,2.57-1.28c1.76,0,3.17,1.39,3.17,3.68,0,1.77-.56,3-2.33,5.07-.78.92-1.91,2.17-2.68,3h3.43c1,0,1.29-.1,1.92-1.43Z" />
        {/* <path d="M593.18,243.7a2.41,2.41,0,0,1,1.18-.37c2,0,3.32,1.72,3.32,3.93,0,2.55-1.62,4.69-3.87,4.69s-3.88-2-3.88-5.31a8.5,8.5,0,0,1,5.07-7.92,13.89,13.89,0,0,1,2.19-.57l.11.72a9.77,9.77,0,0,0-2,.73,6.5,6.5,0,0,0-3.31,4.78Zm.38.9a3.24,3.24,0,0,0-1.67.52,8,8,0,0,0-.12,1.49c0,2.67.86,4.55,2.35,4.55s1.79-1.72,1.79-3.12C595.91,246.25,595.16,244.6,593.56,244.6Z" /> */}
        <path d="M596.8,239.3l0.1,0.2l-0.5,1.5h-4.3c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.5-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4  c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.9-2.1,1.4-3.3,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.2-0.2-0.4-0.5-0.3-0.7  c0-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.2,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2  c-0.6,0-1.3,0.1-1.8,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4L596.8,239.3z" />
        <path
          d="M561.48,275.65a9.31,9.31,0,0,1,0-14.84l.35.42a9.86,9.86,0,0,0-2.63,7,10.11,10.11,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M563.35,264.34l.77-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.45,2.15,1.72,1.72,0,0,1-.5,1.18,6.84,6.84,0,0,1-1.16.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.66,2.66,0,0,1-1.68-.55.75.75,0,0,1-.27-.56,1,1,0,0,1,.38-.64.35.35,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.88-.82,1.88-2.36a2,2,0,0,0-2.1-2.05,3.84,3.84,0,0,0-1,.16l-.12-.49c1.44-.48,2.53-1.1,2.53-2.26a1.39,1.39,0,0,0-1.5-1.49,2.51,2.51,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M574.24,262.39c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.4-2.68-3.4-5.17C570.84,265.24,571.77,262.39,574.24,262.39Zm0,.59c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S575.59,263,574.24,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M582.07,262.39c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17C578.67,265.24,579.59,262.39,582.07,262.39Zm0,.59c-1.41,0-1.76,2.34-1.76,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S583.42,263,582.06,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M595.28,272.56v-.47c.89-.09,1-.16,1-1.25v-2.69c0-1.17-.43-1.81-1.33-1.81a2.78,2.78,0,0,0-1.67.77c0,.2,0,.4,0,.69v3.13c0,1,.13,1.07,1,1.16v.47h-3.45v-.47c.94-.09,1.07-.15,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.49v-.47c1-.1,1.12-.16,1.12-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.2,2.2,0,0,1,1.28-.44,1.87,1.87,0,0,1,1.75,1.3c.44-.34.84-.6,1.24-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.1,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M606.39,270.34c-.22.74-.49,1.61-.65,2.22h-6.12v-.39c.88-.86,1.83-1.81,2.62-2.72a6.24,6.24,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.83-2.05a2.81,2.81,0,0,0-2.21,1.47l-.38-.34.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.34,2.27h3c.89,0,1.12-.08,1.68-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M607.92,260.81a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.85,9.85,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M634.35,238.58c-1.4.19-1.55.32-1.55,2.09v7.67c0,2.73-.36,4.29-1.68,5.62a6,6,0,0,1-1.81,1.24l-.29-.67a3.6,3.6,0,0,0,1.82-2.75,27.8,27.8,0,0,0,.19-4v-7.08c0-1.77-.17-1.92-1.66-2.09V238h5Z" />
        <path d="M636.24,252c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,636.24,252Z" />
        <path d="M646.64,248.76c-.25,1-.57,2.13-.75,2.93h-7v-.51c1-1.14,2.1-2.38,3-3.59,1.2-1.6,2.21-3.25,2.21-5.05s-.78-2.72-2.1-2.72-2,1.1-2.53,2l-.45-.45,1.07-1.77a3.21,3.21,0,0,1,2.57-1.28c1.77,0,3.17,1.39,3.17,3.68,0,1.77-.56,3-2.33,5.07-.78.92-1.91,2.17-2.68,3h3.43c1,0,1.29-.1,1.92-1.43Z" />
        <path d="M654.06,247.88v1.55c0,1.37.07,1.49,1.4,1.64v.62H651v-.62c1.36-.15,1.44-.3,1.44-1.67v-1.52h-4.82v-.68c1.78-2.88,3.73-6,5.51-8.85h.93v8.48h1.78v1Zm-1.62-7.19c-1.1,1.85-2.39,4-3.65,6.14h3.65Z" />
        <path
          d="M623.61,275.65a9.31,9.31,0,0,1,0-14.84l.34.42a9.9,9.9,0,0,0-2.62,7,10.15,10.15,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M625.48,264.34l.76-1a2.86,2.86,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.69,1.69,0,0,1-.51,1.18,6.34,6.34,0,0,1-1.15.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.72,2.72,0,0,1-1.69-.55.74.74,0,0,1-.26-.56,1,1,0,0,1,.38-.64.35.35,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36A2,2,0,0,0,628,267.5a3.85,3.85,0,0,0-1,.16l-.11-.49c1.43-.48,2.53-1.1,2.53-2.26a1.39,1.39,0,0,0-1.51-1.49,2.54,2.54,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M636.37,262.39c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17C633,265.24,633.89,262.39,636.37,262.39Zm0,.59c-1.41,0-1.76,2.34-1.76,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S637.72,263,636.36,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M644.2,262.39c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17C640.8,265.24,641.72,262.39,644.2,262.39Zm0,.59c-1.42,0-1.76,2.34-1.76,4.56s.42,4.63,1.77,4.63,1.74-2.37,1.74-4.61S645.55,263,644.19,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M657.4,272.56v-.47c.89-.09,1-.16,1-1.25v-2.69c0-1.17-.43-1.81-1.33-1.81a2.75,2.75,0,0,0-1.67.77c0,.2,0,.4,0,.69v3.13c0,1,.13,1.07,1,1.16v.47H653v-.47c.95-.09,1.07-.15,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.66.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48v-.47c1-.1,1.11-.16,1.11-1.2v-3.33c0-1,0-1.08-.92-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a9.41,9.41,0,0,1,1.15-.8,2.18,2.18,0,0,1,1.28-.44,1.88,1.88,0,0,1,1.75,1.3c.44-.34.84-.6,1.24-.88a2.46,2.46,0,0,1,1.26-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M668.52,270.34c-.22.74-.5,1.61-.66,2.22h-6.11v-.39c.87-.86,1.83-1.81,2.62-2.72a6.3,6.3,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.84-2.05a2.81,2.81,0,0,0-2.2,1.47l-.39-.34.94-1.34a3,3,0,0,1,2.23-1,2.66,2.66,0,0,1,2.77,2.79c0,1.34-.5,2.29-2,3.84-.67.69-1.66,1.64-2.33,2.27h3c.89,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M670,260.81a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.84,9.84,0,0,0,2.63-7,10.05,10.05,0,0,0-2.63-7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M685.46,275.65a9.31,9.31,0,0,1,0-14.84l.34.42a9.9,9.9,0,0,0-2.62,7,10.15,10.15,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M687.33,264.34l.76-1a2.86,2.86,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.69,1.69,0,0,1-.51,1.18,6.34,6.34,0,0,1-1.15.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.66,2.66,0,0,1-1.68-.55.75.75,0,0,1-.27-.56,1,1,0,0,1,.38-.64.35.35,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2.05,3.92,3.92,0,0,0-1,.16l-.11-.49c1.43-.48,2.53-1.1,2.53-2.26a1.39,1.39,0,0,0-1.5-1.49,2.51,2.51,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M698.22,262.39c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.4-2.68-3.4-5.17C694.82,265.24,695.74,262.39,698.22,262.39Zm0,.59c-1.41,0-1.76,2.34-1.76,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S699.57,263,698.21,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M706.05,262.39c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17C702.65,265.24,703.57,262.39,706.05,262.39Zm0,.59c-1.41,0-1.76,2.34-1.76,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S707.4,263,706,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M719.25,272.56v-.47c.89-.09,1-.16,1-1.25v-2.69c0-1.17-.43-1.81-1.33-1.81a2.75,2.75,0,0,0-1.67.77c0,.2,0,.4,0,.69v3.13c0,1,.13,1.07,1,1.16v.47h-3.45v-.47c.94-.09,1.07-.15,1.07-1.19v-2.78c0-1.18-.39-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48v-.47c1-.1,1.12-.16,1.12-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.18,2.18,0,0,1,1.28-.44,1.87,1.87,0,0,1,1.75,1.3c.44-.34.84-.6,1.24-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.1,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M730.37,270.34c-.22.74-.5,1.61-.66,2.22H723.6v-.39c.88-.86,1.83-1.81,2.62-2.72a6.3,6.3,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.83-2.05a2.81,2.81,0,0,0-2.21,1.47l-.38-.34.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.84-.67.69-1.66,1.64-2.33,2.27h3c.89,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M731.89,260.81a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.85,9.85,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M760.62,238.58c-1.41.19-1.56.32-1.56,2.09v7.67c0,2.73-.35,4.29-1.67,5.62a6,6,0,0,1-1.81,1.24l-.3-.67a3.58,3.58,0,0,0,1.82-2.75,26.43,26.43,0,0,0,.19-4v-7.08c0-1.77-.17-1.92-1.66-2.09V238h5Z" />
        <path d="M762.5,252c-.69,0-1.18-.58-1.18-1.43a1.28,1.28,0,0,1,1.18-1.42,1.3,1.3,0,0,1,1.22,1.42A1.28,1.28,0,0,1,762.5,252Z" />
        <path d="M772.9,248.76c-.24,1-.56,2.13-.75,2.93h-7v-.51c1-1.14,2.1-2.38,3-3.59,1.2-1.6,2.22-3.25,2.22-5.05s-.78-2.72-2.11-2.72-2,1.1-2.52,2l-.45-.45,1.07-1.77a3.21,3.21,0,0,1,2.57-1.28c1.76,0,3.17,1.39,3.17,3.68,0,1.77-.57,3-2.34,5.07-.77.92-1.91,2.17-2.67,3h3.43c1,0,1.28-.1,1.92-1.43Z" />
        <path d="M781.89,248.76c-.25,1-.57,2.13-.75,2.93h-7v-.51c1-1.14,2.1-2.38,3-3.59,1.2-1.6,2.21-3.25,2.21-5.05s-.78-2.72-2.1-2.72-2,1.1-2.53,2l-.44-.45,1.07-1.77a3.19,3.19,0,0,1,2.57-1.28c1.76,0,3.17,1.39,3.17,3.68,0,1.77-.57,3-2.34,5.07-.78.92-1.91,2.17-2.68,3h3.44c1,0,1.28-.1,1.91-1.43Z" />
        <path d="M696.21,238.58c-1.41.19-1.56.32-1.56,2.09v7.67c0,2.73-.35,4.29-1.68,5.62a5.92,5.92,0,0,1-1.81,1.24l-.29-.67a3.6,3.6,0,0,0,1.82-2.75,26.43,26.43,0,0,0,.19-4v-7.08c0-1.77-.17-1.92-1.66-2.09V238h5Z" />
        <path d="M698.09,252c-.7,0-1.18-.58-1.18-1.43a1.28,1.28,0,0,1,1.18-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,698.09,252Z" />
        <path d="M708.49,248.76c-.25,1-.57,2.13-.75,2.93h-7v-.51c1-1.14,2.1-2.38,3-3.59,1.2-1.6,2.22-3.25,2.22-5.05s-.78-2.72-2.11-2.72-2,1.1-2.53,2l-.44-.45,1.07-1.77a3.21,3.21,0,0,1,2.57-1.28c1.76,0,3.17,1.39,3.17,3.68,0,1.77-.57,3-2.34,5.07-.77.92-1.91,2.17-2.67,3h3.43c1,0,1.28-.1,1.92-1.43Z" />
        <path d="M710.11,240.85l.87-1.35a3.12,3.12,0,0,1,2.42-1.23,2.71,2.71,0,0,1,2.82,2.84,2.4,2.4,0,0,1-.58,1.55,8.47,8.47,0,0,1-1.32,1.15,3.31,3.31,0,0,1,2.75,3.24c0,3.43-3.62,4.9-4.9,4.9a2.8,2.8,0,0,1-1.93-.73,1,1,0,0,1-.31-.73,1.37,1.37,0,0,1,.44-.85.34.34,0,0,1,.52,0,3.27,3.27,0,0,0,2.3,1.16c1.25,0,2.15-1.07,2.15-3.11a2.41,2.41,0,0,0-2.4-2.71,4.07,4.07,0,0,0-1.19.22l-.13-.66c1.64-.62,2.9-1.43,2.9-3a1.72,1.72,0,0,0-1.73-2,2.9,2.9,0,0,0-2.28,1.66Z" />
        <path
          d="M749.88,275.65a9.29,9.29,0,0,1,0-14.84l.34.42a9.9,9.9,0,0,0-2.63,7,10.11,10.11,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M751.75,264.34l.76-1a2.84,2.84,0,0,1,2.11-.93,2.21,2.21,0,0,1,2.45,2.15,1.72,1.72,0,0,1-.5,1.18,6.45,6.45,0,0,1-1.16.86c1.52.33,2.41,1.29,2.41,2.46,0,2.6-3.16,3.71-4.28,3.71a2.66,2.66,0,0,1-1.68-.55.75.75,0,0,1-.27-.56A1,1,0,0,1,752,271a.35.35,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16l-.11-.49c1.43-.48,2.52-1.1,2.52-2.26a1.38,1.38,0,0,0-1.5-1.49,2.53,2.53,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M762.63,262.39c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.68-3.39-5.17C759.24,265.24,760.16,262.39,762.63,262.39Zm0,.59c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S764,263,762.63,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M770.46,262.39c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.68-3.39-5.17C767.07,265.24,768,262.39,770.46,262.39Zm0,.59c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S771.82,263,770.46,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M783.67,272.56v-.47c.89-.09,1-.16,1-1.25v-2.69c0-1.17-.43-1.81-1.33-1.81a2.78,2.78,0,0,0-1.67.77,6,6,0,0,1,.05.69v3.13c0,1,.12,1.07,1,1.16v.47h-3.46v-.47c.94-.09,1.07-.15,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48v-.47c1-.1,1.11-.16,1.11-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.22,2.22,0,0,1,1.28-.44,1.89,1.89,0,0,1,1.76,1.3c.43-.34.83-.6,1.23-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M794.78,270.34c-.21.74-.49,1.61-.65,2.22H788v-.39c.87-.86,1.82-1.81,2.61-2.72a6.24,6.24,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.83-2.05a2.77,2.77,0,0,0-2.2,1.47l-.39-.34.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.33,2.27h3c.88,0,1.11-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M796.31,260.81a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.85,9.85,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M837.84,238.58c-1.41.19-1.56.32-1.56,2.09v7.67c0,2.73-.35,4.29-1.68,5.62a5.92,5.92,0,0,1-1.81,1.24l-.29-.67a3.6,3.6,0,0,0,1.82-2.75,26.43,26.43,0,0,0,.19-4v-7.08c0-1.77-.17-1.92-1.66-2.09V238h5Z" />
        <path d="M839.72,252c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,839.72,252Z" />
        <path d="M850.12,248.76c-.25,1-.57,2.13-.75,2.93h-7v-.51c1-1.14,2.1-2.38,3-3.59,1.2-1.6,2.21-3.25,2.21-5.05s-.77-2.72-2.1-2.72-2,1.1-2.53,2l-.44-.45,1.07-1.77a3.19,3.19,0,0,1,2.57-1.28c1.76,0,3.17,1.39,3.17,3.68,0,1.77-.57,3-2.34,5.07-.77.92-1.91,2.17-2.68,3h3.44c1,0,1.28-.1,1.92-1.43Z" />
        <path d="M852.38,251.69v-.62c2-.14,2.12-.27,2.12-1.84v-8c0-1.07-.16-1.12-1-1.21l-.84-.11v-.56a13.75,13.75,0,0,0,3.49-1v11c0,1.59.11,1.7,2.14,1.84v.62Z" />
        <path
          d="M827.09,275.65a9.31,9.31,0,0,1,0-14.84l.35.42a9.86,9.86,0,0,0-2.63,7,10.11,10.11,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M834.72,262.59l.09.13-.43,1.15h-3.74c-.14.85-.35,2.06-.5,2.82a7.89,7.89,0,0,1,1.94-.47,2.8,2.8,0,0,1,3,2.72,3.37,3.37,0,0,1-1.42,2.7,5,5,0,0,1-2.87,1.11,2.46,2.46,0,0,1-1.67-.61c-.24-.21-.31-.35-.28-.56a.89.89,0,0,1,.37-.6.37.37,0,0,1,.48,0,2.87,2.87,0,0,0,2,.9c1.28,0,1.89-1.1,1.89-2.3a2.3,2.3,0,0,0-2.59-2.41,4.88,4.88,0,0,0-1.61.29l-.05-.07c.25-1.29.61-3.51.79-4.82Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M837.35,272.56v-.48c1.75-.1,1.85-.2,1.85-1.39V264.6c0-.81-.14-.85-.84-.92l-.73-.08v-.42a14,14,0,0,0,3-.79v8.3c0,1.21.09,1.29,1.86,1.39v.48Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M847.68,262.39c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17C844.28,265.24,845.2,262.39,847.68,262.39Zm0,.59c-1.41,0-1.76,2.34-1.76,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S849,263,847.67,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M860.89,272.56v-.47c.89-.09,1-.16,1-1.25v-2.69c0-1.17-.43-1.81-1.33-1.81a2.78,2.78,0,0,0-1.67.77c0,.2,0,.4,0,.69v3.13c0,1,.13,1.07,1,1.16v.47h-3.45v-.47c.94-.09,1.07-.15,1.07-1.19v-2.78c0-1.18-.39-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.49v-.47c1-.1,1.12-.16,1.12-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.2,2.2,0,0,1,1.28-.44,1.87,1.87,0,0,1,1.75,1.3c.44-.34.84-.6,1.24-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.1,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M872,270.34c-.22.74-.5,1.61-.66,2.22h-6.11v-.39c.88-.86,1.83-1.81,2.62-2.72a6.3,6.3,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.83-2.05a2.81,2.81,0,0,0-2.21,1.47l-.38-.34.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.84-.67.69-1.66,1.64-2.33,2.27h3c.89,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M873.53,260.81a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.85,9.85,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M324.44,541.87c-1.41.19-1.56.31-1.56,2.09v7.67c0,2.72-.35,4.29-1.68,5.61a5.92,5.92,0,0,1-1.81,1.24l-.29-.66a3.6,3.6,0,0,0,1.82-2.76,26.21,26.21,0,0,0,.19-4V544c0-1.78-.17-1.92-1.66-2.09v-.63h5Z" />
        <path d="M326.32,555.27a1.27,1.27,0,0,1-1.19-1.43,1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.29,1.29,0,0,1,326.32,555.27Z" />
        <path d="M336,541.81l.11.18-.5,1.52h-4.28c-.17,1.12-.41,2.72-.58,3.73a7.47,7.47,0,0,1,2.22-.62,3.43,3.43,0,0,1,3.48,3.58,4.7,4.7,0,0,1-1.62,3.57,5.39,5.39,0,0,1-3.3,1.46,2.6,2.6,0,0,1-1.91-.8.82.82,0,0,1-.33-.74,1.24,1.24,0,0,1,.42-.79.38.38,0,0,1,.55,0,3.15,3.15,0,0,0,2.33,1.19c1.47,0,2.17-1.46,2.17-3,0-1.87-1-3.18-3-3.18a5.14,5.14,0,0,0-1.86.37l-.06-.09c.3-1.7.71-4.63.92-6.37Z" />
        <path d="M339,555v-.63c2-.14,2.12-.27,2.12-1.84v-8c0-1.08-.16-1.13-1-1.22l-.84-.1v-.56a14.16,14.16,0,0,0,3.49-1v11c0,1.6.11,1.7,2.14,1.84V555Z" />
        <path
          d="M309.54,578.94a9.29,9.29,0,0,1,0-14.84l.34.42a9.87,9.87,0,0,0-2.63,7,10.08,10.08,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M311.41,567.63l.76-1a2.84,2.84,0,0,1,2.11-.93,2.21,2.21,0,0,1,2.45,2.15,1.7,1.7,0,0,1-.5,1.17,7,7,0,0,1-1.16.87,2.64,2.64,0,0,1,2.41,2.46c0,2.6-3.16,3.71-4.27,3.71a2.61,2.61,0,0,1-1.69-.56.73.73,0,0,1-.27-.55,1,1,0,0,1,.38-.64.33.33,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.36a2,2,0,0,0-2.09-2.05,3.83,3.83,0,0,0-1,.17l-.11-.5c1.43-.47,2.52-1.09,2.52-2.26a1.38,1.38,0,0,0-1.5-1.48,2.56,2.56,0,0,0-2,1.25Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M322.29,565.68c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.69-3.39-5.17S319.82,565.68,322.29,565.68Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.61S323.65,566.26,322.29,566.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M330.12,565.68c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.69-3.39-5.17S327.65,565.68,330.12,565.68Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.61S331.48,566.26,330.12,566.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M343.33,575.84v-.46c.89-.1,1-.16,1-1.26v-2.69c0-1.17-.43-1.8-1.33-1.8a2.72,2.72,0,0,0-1.67.77,5.62,5.62,0,0,1,.05.68v3.13c0,1,.12,1.07,1,1.17v.46H338.9v-.46c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.94,2.94,0,0,0-1.67.76v3.8c0,1,.11,1.09,1,1.19v.46H334.5v-.46c1-.1,1.11-.17,1.11-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a9.52,9.52,0,0,0,2.33-.64v1.28a13.82,13.82,0,0,1,1.15-.8,2.29,2.29,0,0,1,1.28-.43,1.87,1.87,0,0,1,1.76,1.3c.43-.35.83-.61,1.23-.88a2.38,2.38,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.46Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M354.44,573.62c-.21.75-.49,1.62-.65,2.22h-6.11v-.39c.87-.86,1.82-1.8,2.61-2.71a6.27,6.27,0,0,0,1.93-3.83c0-1.29-.68-2.06-1.83-2.06a2.76,2.76,0,0,0-2.2,1.48l-.39-.35.93-1.33a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.78c0,1.35-.49,2.3-2,3.84-.68.7-1.67,1.65-2.33,2.27h3c.88,0,1.11-.07,1.67-1.08Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M356,564.1a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.89,9.89,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M324.44,456c-1.41.19-1.56.31-1.56,2.09v7.67c0,2.73-.35,4.29-1.68,5.62a5.86,5.86,0,0,1-1.81,1.23l-.29-.66a3.6,3.6,0,0,0,1.82-2.76,26.21,26.21,0,0,0,.19-4v-7.08c0-1.78-.17-1.92-1.66-2.09v-.62h5Z" />
        <path d="M326.32,469.43c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,326.32,469.43Z" />
        <path d="M335.15,465.33v1.55c0,1.37.08,1.49,1.41,1.64v.62h-4.47v-.62c1.37-.15,1.45-.3,1.45-1.67v-1.52h-4.83v-.68c1.79-2.89,3.73-6,5.52-8.85h.92v8.47h1.79v1.06Zm-1.61-7.19c-1.11,1.85-2.39,4-3.65,6.13h3.65Z" />
        <path d="M342,455.72a3,3,0,0,1,3.06,3.16,2.68,2.68,0,0,1-.53,1.69,15,15,0,0,1-1.49,1.31c1.27.8,2.51,1.81,2.51,3.65a3.79,3.79,0,0,1-3.78,3.87,3.38,3.38,0,0,1-3.62-3.51,2.63,2.63,0,0,1,.83-2.06,18.64,18.64,0,0,1,1.66-1.29,3.91,3.91,0,0,1-2-3.31A3.39,3.39,0,0,1,342,455.72Zm0,12.93c1.19,0,1.92-.9,1.92-2.41s-1.17-2.46-2.72-3.33a3.29,3.29,0,0,0-1.44,2.82C339.75,467.68,340.81,468.65,342,468.65Zm-.24-12.2c-1.07,0-1.63,1-1.63,2.12,0,1.52.91,2.11,2.35,3a3.15,3.15,0,0,0,1.11-2.55C343.58,457.18,342.66,456.45,341.75,456.45Z" />
        <path
          d="M309.54,493.1a9.29,9.29,0,0,1,0-14.84l.34.42a9.89,9.89,0,0,0-2.63,7,10.08,10.08,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M311.41,481.79l.76-1a2.84,2.84,0,0,1,2.11-.93,2.21,2.21,0,0,1,2.45,2.15,1.74,1.74,0,0,1-.5,1.18,7,7,0,0,1-1.16.86,2.65,2.65,0,0,1,2.41,2.46c0,2.6-3.16,3.71-4.27,3.71a2.61,2.61,0,0,1-1.69-.56.72.72,0,0,1-.27-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.35a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16l-.11-.5c1.43-.47,2.52-1.09,2.52-2.26a1.38,1.38,0,0,0-1.5-1.48,2.54,2.54,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M322.29,479.84c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.68-3.39-5.17C318.9,482.69,319.82,479.84,322.29,479.84Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62S324,487.24,324,485,323.65,480.42,322.29,480.42Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M330.12,479.84c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.68-3.39-5.17C326.73,482.69,327.65,479.84,330.12,479.84Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.6S331.48,480.42,330.12,480.42Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M343.33,490v-.47c.89-.1,1-.16,1-1.25V485.6c0-1.18-.43-1.81-1.33-1.81a2.72,2.72,0,0,0-1.67.77,5.65,5.65,0,0,1,.05.69v3.13c0,1,.12,1.06,1,1.16V490H338.9v-.47c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.09,1,1.19V490H334.5v-.47c1-.1,1.11-.16,1.11-1.2V485c0-1-.06-1.08-.93-1.23v-.42a9.52,9.52,0,0,0,2.33-.64V484a13.82,13.82,0,0,1,1.15-.8,2.29,2.29,0,0,1,1.28-.43,1.87,1.87,0,0,1,1.76,1.3c.43-.34.83-.61,1.23-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19V490Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M354.44,487.78c-.21.75-.49,1.62-.65,2.23h-6.11v-.4c.87-.86,1.82-1.8,2.61-2.71a6.27,6.27,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.83-2.05a2.75,2.75,0,0,0-2.2,1.47l-.39-.35.93-1.33a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.83-.68.7-1.67,1.65-2.33,2.28h3c.88,0,1.11-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M356,478.26a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.87,9.87,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M388.78,541.87c-1.41.19-1.56.31-1.56,2.09v7.67c0,2.72-.35,4.29-1.67,5.61a6,6,0,0,1-1.81,1.24l-.3-.66a3.61,3.61,0,0,0,1.83-2.76,27.8,27.8,0,0,0,.18-4V544c0-1.78-.16-1.92-1.66-2.09v-.63h5Z" />
        <path d="M390.67,555.27a1.27,1.27,0,0,1-1.19-1.43,1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.29,1.29,0,0,1,390.67,555.27Z" />
        <path d="M400.3,541.81l.11.18-.5,1.52h-4.29c-.16,1.12-.4,2.72-.57,3.73a7.47,7.47,0,0,1,2.22-.62,3.43,3.43,0,0,1,3.48,3.58,4.68,4.68,0,0,1-1.63,3.57,5.34,5.34,0,0,1-3.29,1.46,2.64,2.64,0,0,1-1.92-.8.81.81,0,0,1-.32-.74,1.19,1.19,0,0,1,.42-.79.38.38,0,0,1,.55,0,3.11,3.11,0,0,0,2.33,1.19c1.47,0,2.17-1.46,2.17-3,0-1.87-1-3.18-3-3.18a5.08,5.08,0,0,0-1.85.37l-.06-.09c.29-1.7.7-4.63.91-6.37Z" />
        <path d="M410.05,552.05c-.25,1-.57,2.13-.75,2.93h-7v-.52c1-1.13,2.09-2.38,3-3.58,1.2-1.61,2.21-3.25,2.21-5s-.77-2.72-2.1-2.72-2,1.1-2.53,1.95l-.44-.46,1.07-1.76a3.21,3.21,0,0,1,2.57-1.28c1.76,0,3.17,1.39,3.17,3.68,0,1.77-.57,3-2.34,5.06-.77.92-1.91,2.18-2.68,3h3.44c1,0,1.28-.1,1.92-1.43Z" />
        <path
          d="M373.88,578.94a9.31,9.31,0,0,1,0-14.84l.35.42a9.82,9.82,0,0,0-2.63,7,10.08,10.08,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M375.75,567.63l.77-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.45,2.15,1.7,1.7,0,0,1-.5,1.17,7.4,7.4,0,0,1-1.16.87,2.63,2.63,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.6,2.6,0,0,1-1.68-.56.73.73,0,0,1-.27-.55,1,1,0,0,1,.38-.64.33.33,0,0,1,.46,0,3,3,0,0,0,2,.88c1.1,0,1.88-.81,1.88-2.36a2,2,0,0,0-2.1-2.05,3.82,3.82,0,0,0-1,.17l-.12-.5c1.44-.47,2.53-1.09,2.53-2.26a1.38,1.38,0,0,0-1.5-1.48,2.53,2.53,0,0,0-2,1.25Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M386.64,565.68c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.69-3.39-5.17S384.17,565.68,386.64,565.68Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.61S388,566.26,386.64,566.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M394.47,565.68c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.4-2.69-3.4-5.17S392,565.68,394.47,565.68Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.61S395.82,566.26,394.46,566.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M407.68,575.84v-.46c.89-.1,1-.16,1-1.26v-2.69c0-1.17-.43-1.8-1.33-1.8a2.72,2.72,0,0,0-1.67.77c0,.19,0,.39,0,.68v3.13c0,1,.13,1.07,1,1.17v.46h-3.46v-.46c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.94,2.94,0,0,0-1.67.76v3.8c0,1,.11,1.09,1,1.19v.46h-3.49v-.46c1-.1,1.12-.17,1.12-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a9.52,9.52,0,0,0,2.33-.64v1.28a13.82,13.82,0,0,1,1.15-.8,2.26,2.26,0,0,1,1.28-.43,1.85,1.85,0,0,1,1.75,1.3c.44-.35.84-.61,1.24-.88a2.38,2.38,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.1,1.09,1,1.19v.46Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M418.79,573.62c-.22.75-.49,1.62-.65,2.22H412v-.39c.88-.86,1.83-1.8,2.62-2.71a6.27,6.27,0,0,0,1.93-3.83c0-1.29-.68-2.06-1.83-2.06a2.76,2.76,0,0,0-2.2,1.48l-.39-.35.93-1.33a2.93,2.93,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.78c0,1.35-.49,2.3-2,3.84-.68.7-1.67,1.65-2.34,2.27h3c.89,0,1.12-.07,1.68-1.08Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M420.32,564.1a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.89,9.89,0,0,0,2.64-7,10.07,10.07,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M388.78,456c-1.41.19-1.56.31-1.56,2.09v7.67c0,2.73-.35,4.29-1.67,5.62a6,6,0,0,1-1.81,1.23l-.3-.66a3.61,3.61,0,0,0,1.83-2.76,27.8,27.8,0,0,0,.18-4v-7.08c0-1.78-.16-1.92-1.66-2.09v-.62h5Z" />
        <path d="M390.67,469.43c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.31,1.31,0,0,1,1.22,1.42A1.29,1.29,0,0,1,390.67,469.43Z" />
        <path d="M399.5,465.33v1.55c0,1.37.08,1.49,1.4,1.64v.62h-4.46v-.62c1.36-.15,1.45-.3,1.45-1.67v-1.52h-4.83v-.68c1.78-2.89,3.73-6,5.52-8.85h.92v8.47h1.78v1.06Zm-1.61-7.19c-1.11,1.85-2.39,4-3.65,6.13h3.65Z" />
        <path d="M410.22,456.26c-1.76,4.28-3.49,8.55-5.26,12.92l-1.4.16-.14-.24c1.93-3.58,3.92-7.85,5.4-11.43H404.9c-1.21,0-1.29.19-1.79,1.56h-.59c.19-1.28.28-2.34.37-3.25h7.22Z" />
        <path
          d="M373.88,493.1a9.31,9.31,0,0,1,0-14.84l.35.42a9.84,9.84,0,0,0-2.63,7,10.08,10.08,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M375.75,481.79l.77-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.45,2.15,1.74,1.74,0,0,1-.5,1.18,7.42,7.42,0,0,1-1.16.86,2.64,2.64,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.6,2.6,0,0,1-1.68-.56.72.72,0,0,1-.27-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.88c1.1,0,1.88-.81,1.88-2.35a2,2,0,0,0-2.1-2.05,3.84,3.84,0,0,0-1,.16l-.12-.5c1.44-.47,2.53-1.09,2.53-2.26a1.38,1.38,0,0,0-1.5-1.48,2.51,2.51,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M386.64,479.84c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.68-3.39-5.17C383.25,482.69,384.17,479.84,386.64,479.84Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.6S388,480.42,386.64,480.42Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M394.47,479.84c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.4-2.68-3.4-5.17C391.07,482.69,392,479.84,394.47,479.84Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.6S395.82,480.42,394.46,480.42Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M407.68,490v-.47c.89-.1,1-.16,1-1.25V485.6c0-1.18-.43-1.81-1.33-1.81a2.72,2.72,0,0,0-1.67.77c0,.19,0,.39,0,.69v3.13c0,1,.13,1.06,1,1.16V490h-3.46v-.47c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.09,1,1.19V490h-3.49v-.47c1-.1,1.12-.16,1.12-1.2V485c0-1-.06-1.08-.93-1.23v-.42a9.52,9.52,0,0,0,2.33-.64V484a13.82,13.82,0,0,1,1.15-.8,2.26,2.26,0,0,1,1.28-.43,1.85,1.85,0,0,1,1.75,1.3c.44-.34.84-.61,1.24-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.1,1.09,1,1.19V490Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M418.79,487.78c-.22.75-.49,1.62-.65,2.23H412v-.4c.88-.86,1.83-1.8,2.62-2.71a6.27,6.27,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.83-2.05a2.75,2.75,0,0,0-2.2,1.47l-.39-.35.93-1.33a2.93,2.93,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.83-.68.7-1.67,1.65-2.34,2.28h3c.89,0,1.12-.08,1.68-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M420.32,478.26a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.87,9.87,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M452.79,541.87c-1.4.19-1.55.31-1.55,2.09v7.67c0,2.72-.36,4.29-1.68,5.61a6,6,0,0,1-1.81,1.24l-.29-.66a3.6,3.6,0,0,0,1.82-2.76,27.56,27.56,0,0,0,.19-4V544c0-1.78-.17-1.92-1.66-2.09v-.63h5Z" />
        <path d="M454.68,555.27a1.27,1.27,0,0,1-1.19-1.43,1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.29,1.29,0,0,1,454.68,555.27Z" />
        <path d="M464.31,541.81l.11.18-.5,1.52h-4.29c-.16,1.12-.4,2.72-.57,3.73a7.47,7.47,0,0,1,2.22-.62,3.43,3.43,0,0,1,3.48,3.58,4.68,4.68,0,0,1-1.63,3.57,5.34,5.34,0,0,1-3.29,1.46,2.64,2.64,0,0,1-1.92-.8.81.81,0,0,1-.32-.74,1.19,1.19,0,0,1,.42-.79.38.38,0,0,1,.55,0,3.11,3.11,0,0,0,2.33,1.19c1.47,0,2.17-1.46,2.17-3,0-1.87-1-3.18-3-3.18a5.12,5.12,0,0,0-1.85.37l-.06-.09c.29-1.7.7-4.63.92-6.37Z" />
        <path d="M466.69,544.13l.87-1.34a3.11,3.11,0,0,1,2.43-1.23,2.7,2.7,0,0,1,2.81,2.83,2.45,2.45,0,0,1-.57,1.56,8.09,8.09,0,0,1-1.33,1.14,3.33,3.33,0,0,1,2.76,3.25c0,3.43-3.62,4.89-4.91,4.89a2.78,2.78,0,0,1-1.93-.73,1,1,0,0,1-.3-.73,1.26,1.26,0,0,1,.43-.84.36.36,0,0,1,.53,0,3.24,3.24,0,0,0,2.3,1.17c1.25,0,2.15-1.08,2.15-3.11a2.42,2.42,0,0,0-2.4-2.71,4.16,4.16,0,0,0-1.2.21l-.12-.65c1.64-.62,2.9-1.44,2.9-3a1.72,1.72,0,0,0-1.73-2,2.9,2.9,0,0,0-2.28,1.66Z" />
        <path
          d="M437.89,578.94a9.31,9.31,0,0,1,0-14.84l.35.42a9.82,9.82,0,0,0-2.63,7,10.08,10.08,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M439.76,567.63l.77-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.45,2.15,1.7,1.7,0,0,1-.5,1.17,7.4,7.4,0,0,1-1.16.87,2.63,2.63,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.6,2.6,0,0,1-1.68-.56.73.73,0,0,1-.27-.55,1,1,0,0,1,.38-.64.33.33,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.88-.81,1.88-2.36a2,2,0,0,0-2.1-2.05,3.82,3.82,0,0,0-1,.17l-.12-.5c1.44-.47,2.53-1.09,2.53-2.26a1.38,1.38,0,0,0-1.5-1.48,2.53,2.53,0,0,0-2,1.25Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M450.65,565.68c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.4-2.69-3.4-5.17S448.18,565.68,450.65,565.68Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.61S452,566.26,450.65,566.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M458.48,565.68c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.69-3.4-5.17S456,565.68,458.48,565.68Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.61S459.83,566.26,458.47,566.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M471.69,575.84v-.46c.89-.1,1-.16,1-1.26v-2.69c0-1.17-.43-1.8-1.33-1.8a2.72,2.72,0,0,0-1.67.77c0,.19,0,.39,0,.68v3.13c0,1,.13,1.07,1,1.17v.46h-3.45v-.46c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.94,2.94,0,0,0-1.67.76v3.8c0,1,.11,1.09,1,1.19v.46h-3.49v-.46c1-.1,1.12-.17,1.12-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a9.52,9.52,0,0,0,2.33-.64v1.28a13.82,13.82,0,0,1,1.15-.8,2.26,2.26,0,0,1,1.28-.43,1.85,1.85,0,0,1,1.75,1.3c.44-.35.84-.61,1.24-.88a2.38,2.38,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.1,1.09,1,1.19v.46Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M482.8,573.62c-.22.75-.49,1.62-.65,2.22H476v-.39c.88-.86,1.83-1.8,2.62-2.71a6.27,6.27,0,0,0,1.93-3.83c0-1.29-.68-2.06-1.83-2.06a2.79,2.79,0,0,0-2.21,1.48l-.38-.35.93-1.33a2.93,2.93,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.78c0,1.35-.49,2.3-2,3.84-.68.7-1.67,1.65-2.34,2.27h3c.89,0,1.12-.07,1.68-1.08Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M484.33,564.1a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.89,9.89,0,0,0,2.64-7,10.07,10.07,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M452.79,456c-1.4.19-1.55.31-1.55,2.09v7.67c0,2.73-.36,4.29-1.68,5.62a6,6,0,0,1-1.81,1.23l-.29-.66a3.6,3.6,0,0,0,1.82-2.76,27.56,27.56,0,0,0,.19-4v-7.08c0-1.78-.17-1.92-1.66-2.09v-.62h5Z" />
        <path d="M454.68,469.43c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42A1.3,1.3,0,0,1,455.9,468,1.29,1.29,0,0,1,454.68,469.43Z" />
        <path d="M463.51,465.33v1.55c0,1.37.08,1.49,1.4,1.64v.62h-4.46v-.62c1.36-.15,1.45-.3,1.45-1.67v-1.52h-4.83v-.68c1.78-2.89,3.73-6,5.52-8.85h.92v8.47h1.79v1.06Zm-1.61-7.19c-1.11,1.85-2.39,4-3.65,6.13h3.65Z" />
        <path d="M469.59,461.15a2.41,2.41,0,0,1,1.18-.37c2,0,3.32,1.72,3.32,3.92,0,2.56-1.62,4.7-3.87,4.7s-3.88-2-3.88-5.31a8.51,8.51,0,0,1,5.07-7.93,14,14,0,0,1,2.19-.56l.11.72a10,10,0,0,0-2,.73,6.48,6.48,0,0,0-3.31,4.78Zm.38.89a3.24,3.24,0,0,0-1.67.53,9.08,9.08,0,0,0-.11,1.49c0,2.67.85,4.55,2.35,4.55s1.79-1.72,1.79-3.12C472.33,463.69,471.57,462,470,462Z" />
        <path
          d="M437.89,493.1a9.31,9.31,0,0,1,0-14.84l.35.42a9.84,9.84,0,0,0-2.63,7,10.08,10.08,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M439.76,481.79l.77-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.45,2.15,1.74,1.74,0,0,1-.5,1.18,7.42,7.42,0,0,1-1.16.86,2.64,2.64,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.6,2.6,0,0,1-1.68-.56.72.72,0,0,1-.27-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.88-.81,1.88-2.35a2,2,0,0,0-2.1-2.05,3.84,3.84,0,0,0-1,.16l-.12-.5c1.44-.47,2.53-1.09,2.53-2.26a1.38,1.38,0,0,0-1.5-1.48,2.51,2.51,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M450.65,479.84c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.4-2.68-3.4-5.17C447.25,482.69,448.18,479.84,450.65,479.84Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.6S452,480.42,450.65,480.42Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M458.48,479.84c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17C455.08,482.69,456,479.84,458.48,479.84Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.6S459.83,480.42,458.47,480.42Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M471.69,490v-.47c.89-.1,1-.16,1-1.25V485.6c0-1.18-.43-1.81-1.33-1.81a2.72,2.72,0,0,0-1.67.77c0,.19,0,.39,0,.69v3.13c0,1,.13,1.06,1,1.16V490h-3.45v-.47c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.09,1,1.19V490h-3.49v-.47c1-.1,1.12-.16,1.12-1.2V485c0-1-.06-1.08-.93-1.23v-.42a9.52,9.52,0,0,0,2.33-.64V484a13.82,13.82,0,0,1,1.15-.8,2.26,2.26,0,0,1,1.28-.43,1.85,1.85,0,0,1,1.75,1.3c.44-.34.84-.61,1.24-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.1,1.09,1,1.19V490Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M482.8,487.78c-.22.75-.49,1.62-.65,2.23H476v-.4c.88-.86,1.83-1.8,2.62-2.71a6.27,6.27,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.83-2.05a2.78,2.78,0,0,0-2.21,1.47l-.38-.35.93-1.33a2.93,2.93,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.83-.68.7-1.67,1.65-2.34,2.28h3c.89,0,1.12-.08,1.68-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M484.33,478.26a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.87,9.87,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M516.42,541.87c-1.41.19-1.56.31-1.56,2.09v7.67c0,2.72-.35,4.29-1.67,5.61a6,6,0,0,1-1.81,1.24l-.3-.66a3.61,3.61,0,0,0,1.83-2.76,27.8,27.8,0,0,0,.18-4V544c0-1.78-.17-1.92-1.66-2.09v-.63h5Z" />
        <path d="M518.3,555.27a1.27,1.27,0,0,1-1.18-1.43,1.29,1.29,0,0,1,1.18-1.43,1.32,1.32,0,0,1,1.23,1.43A1.29,1.29,0,0,1,518.3,555.27Z" />
        <path d="M527.94,541.81l.11.18-.5,1.52h-4.29c-.17,1.12-.4,2.72-.57,3.73a7.42,7.42,0,0,1,2.21-.62,3.44,3.44,0,0,1,3.49,3.58,4.68,4.68,0,0,1-1.63,3.57,5.37,5.37,0,0,1-3.29,1.46,2.64,2.64,0,0,1-1.92-.8.81.81,0,0,1-.32-.74,1.19,1.19,0,0,1,.42-.79.38.38,0,0,1,.55,0,3.11,3.11,0,0,0,2.33,1.19c1.47,0,2.17-1.46,2.17-3,0-1.87-1-3.18-3-3.18a5.08,5.08,0,0,0-1.85.37l-.06-.09c.29-1.7.7-4.63.91-6.37Z" />
        <path d="M536.12,551.16v1.56c0,1.37.08,1.49,1.41,1.63V555h-4.47v-.63c1.37-.14,1.45-.29,1.45-1.67v-1.52h-4.83v-.67c1.79-2.89,3.73-6,5.52-8.86h.92v8.48h1.79v1ZM534.51,544c-1.11,1.85-2.39,4-3.65,6.13h3.65Z" />
        <path
          d="M501.52,578.94a9.31,9.31,0,0,1,0-14.84l.35.42a9.82,9.82,0,0,0-2.63,7,10.08,10.08,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M503.39,567.63l.76-1a2.88,2.88,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.67,1.67,0,0,1-.51,1.17,6.82,6.82,0,0,1-1.15.87,2.63,2.63,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.6,2.6,0,0,1-1.68-.56.73.73,0,0,1-.27-.55,1,1,0,0,1,.38-.64.33.33,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.88-.81,1.88-2.36a2,2,0,0,0-2.1-2.05,3.82,3.82,0,0,0-1,.17l-.12-.5c1.44-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.5-1.48,2.53,2.53,0,0,0-2,1.25Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M514.28,565.68c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.4-2.69-3.4-5.17S511.8,565.68,514.28,565.68Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.61S515.63,566.26,514.28,566.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M522.11,565.68c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.69-3.4-5.17S519.63,565.68,522.11,565.68Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.61S523.46,566.26,522.1,566.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M535.32,575.84v-.46c.89-.1,1-.16,1-1.26v-2.69c0-1.17-.43-1.8-1.33-1.8a2.72,2.72,0,0,0-1.67.77c0,.19,0,.39,0,.68v3.13c0,1,.13,1.07,1,1.17v.46h-3.45v-.46c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.39-1.78-1.29-1.78a2.94,2.94,0,0,0-1.67.76v3.8c0,1,.11,1.09,1,1.19v.46h-3.48v-.46c1-.1,1.12-.17,1.12-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a9.52,9.52,0,0,0,2.33-.64v1.28a13.82,13.82,0,0,1,1.15-.8,2.26,2.26,0,0,1,1.28-.43,1.85,1.85,0,0,1,1.75,1.3c.44-.35.84-.61,1.24-.88a2.38,2.38,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.1,1.09,1,1.19v.46Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M546.43,573.62c-.22.75-.5,1.62-.66,2.22h-6.11v-.39c.88-.86,1.83-1.8,2.62-2.71,1-1.22,1.93-2.46,1.93-3.83s-.68-2.06-1.83-2.06a2.79,2.79,0,0,0-2.21,1.48l-.38-.35.93-1.33a2.92,2.92,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.78c0,1.35-.49,2.3-2,3.84-.67.7-1.66,1.65-2.33,2.27h3c.89,0,1.12-.07,1.67-1.08Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M548,564.1a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.89,9.89,0,0,0,2.64-7,10.07,10.07,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M516.42,456c-1.41.19-1.56.31-1.56,2.09v7.67c0,2.73-.35,4.29-1.67,5.62a6,6,0,0,1-1.81,1.23l-.3-.66a3.61,3.61,0,0,0,1.83-2.76,27.8,27.8,0,0,0,.18-4v-7.08c0-1.78-.17-1.92-1.66-2.09v-.62h5Z" />
        <path d="M518.3,469.43c-.69,0-1.18-.58-1.18-1.43a1.28,1.28,0,0,1,1.18-1.42,1.31,1.31,0,0,1,1.23,1.42A1.29,1.29,0,0,1,518.3,469.43Z" />
        <path d="M527.14,465.33v1.55c0,1.37.08,1.49,1.4,1.64v.62h-4.46v-.62c1.36-.15,1.45-.3,1.45-1.67v-1.52H520.7v-.68c1.78-2.89,3.73-6,5.52-8.85h.92v8.47h1.78v1.06Zm-1.61-7.19c-1.11,1.85-2.4,4-3.66,6.13h3.66Z" />
        <path d="M536.92,456l.11.17-.5,1.52h-4.28c-.17,1.12-.41,2.72-.58,3.73a7.78,7.78,0,0,1,2.22-.62,3.44,3.44,0,0,1,3.48,3.58,4.7,4.7,0,0,1-1.62,3.57,5.35,5.35,0,0,1-3.3,1.47,2.6,2.6,0,0,1-1.91-.81.79.79,0,0,1-.33-.74,1.26,1.26,0,0,1,.42-.79.38.38,0,0,1,.55,0,3.14,3.14,0,0,0,2.33,1.18c1.47,0,2.17-1.45,2.17-3,0-1.86-1-3.18-3-3.18a4.91,4.91,0,0,0-1.85.38l-.06-.1c.29-1.69.71-4.62.92-6.36Z" />
        <path
          d="M501.52,493.1a9.31,9.31,0,0,1,0-14.84l.35.42a9.84,9.84,0,0,0-2.63,7,10.08,10.08,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M503.39,481.79l.76-1a2.88,2.88,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.71,1.71,0,0,1-.51,1.18,6.84,6.84,0,0,1-1.15.86,2.64,2.64,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.6,2.6,0,0,1-1.68-.56.72.72,0,0,1-.27-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.88-.81,1.88-2.35a2,2,0,0,0-2.1-2.05,3.84,3.84,0,0,0-1,.16l-.12-.5c1.44-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.5-1.48,2.51,2.51,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M514.28,479.84c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.4-2.68-3.4-5.17C510.88,482.69,511.8,479.84,514.28,479.84Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.77,4.62S516,487.24,516,485,515.63,480.42,514.28,480.42Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M522.11,479.84c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17C518.71,482.69,519.63,479.84,522.11,479.84Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.6S523.46,480.42,522.1,480.42Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M535.32,490v-.47c.89-.1,1-.16,1-1.25V485.6c0-1.18-.43-1.81-1.33-1.81a2.72,2.72,0,0,0-1.67.77c0,.19,0,.39,0,.69v3.13c0,1,.13,1.06,1,1.16V490h-3.45v-.47c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.39-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.09,1,1.19V490h-3.48v-.47c1-.1,1.12-.16,1.12-1.2V485c0-1-.06-1.08-.93-1.23v-.42a9.52,9.52,0,0,0,2.33-.64V484a13.82,13.82,0,0,1,1.15-.8,2.26,2.26,0,0,1,1.28-.43,1.85,1.85,0,0,1,1.75,1.3c.44-.34.84-.61,1.24-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.1,1.09,1,1.19V490Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M546.43,487.78c-.22.75-.5,1.62-.66,2.23h-6.11v-.4c.88-.86,1.83-1.8,2.62-2.71,1-1.22,1.93-2.46,1.93-3.83s-.68-2.05-1.83-2.05a2.78,2.78,0,0,0-2.21,1.47l-.38-.35.93-1.33a2.92,2.92,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.83-.67.7-1.66,1.65-2.33,2.28h3c.89,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M548,478.26a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.87,9.87,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M577.78,541.87c-1.41.19-1.56.31-1.56,2.09v7.67c0,2.72-.35,4.29-1.68,5.61a5.8,5.8,0,0,1-1.81,1.24l-.29-.66a3.6,3.6,0,0,0,1.82-2.76,26.21,26.21,0,0,0,.19-4V544c0-1.78-.17-1.92-1.66-2.09v-.63h5Z" />
        <path d="M579.66,555.27c-.7,0-1.18-.59-1.18-1.43a1.28,1.28,0,0,1,1.18-1.43,1.31,1.31,0,0,1,1.22,1.43A1.29,1.29,0,0,1,579.66,555.27Z" />
        <path d="M589.29,541.81l.11.18-.49,1.52h-4.29c-.17,1.12-.41,2.72-.58,3.73a7.55,7.55,0,0,1,2.22-.62,3.44,3.44,0,0,1,3.49,3.58,4.72,4.72,0,0,1-1.63,3.57,5.37,5.37,0,0,1-3.29,1.46,2.62,2.62,0,0,1-1.92-.8.82.82,0,0,1-.33-.74,1.24,1.24,0,0,1,.42-.79.38.38,0,0,1,.55,0,3.15,3.15,0,0,0,2.33,1.19c1.48,0,2.17-1.46,2.17-3,0-1.87-1-3.18-3-3.18a5,5,0,0,0-1.85.37l-.07-.09c.3-1.7.71-4.63.92-6.37Z" />
        <path d="M598.28,541.81l.11.18-.5,1.52H593.6c-.16,1.12-.4,2.72-.57,3.73a7.47,7.47,0,0,1,2.22-.62,3.43,3.43,0,0,1,3.48,3.58,4.68,4.68,0,0,1-1.63,3.57,5.34,5.34,0,0,1-3.29,1.46,2.64,2.64,0,0,1-1.92-.8.81.81,0,0,1-.32-.74,1.19,1.19,0,0,1,.42-.79.38.38,0,0,1,.55,0,3.11,3.11,0,0,0,2.33,1.19c1.47,0,2.17-1.46,2.17-3,0-1.87-1-3.18-3-3.18a5.12,5.12,0,0,0-1.85.37l-.06-.09c.29-1.7.7-4.63.92-6.37Z" />
        <path
          d="M562.88,578.94a9.31,9.31,0,0,1,0-14.84l.34.42a9.86,9.86,0,0,0-2.62,7,10.12,10.12,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M564.75,567.63l.76-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.67,1.67,0,0,1-.51,1.17,6.82,6.82,0,0,1-1.15.87,2.63,2.63,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.66,2.66,0,0,1-1.69-.56.72.72,0,0,1-.26-.55,1,1,0,0,1,.38-.64.33.33,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.36a2,2,0,0,0-2.09-2.05,3.83,3.83,0,0,0-1,.17l-.11-.5c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.48,2.56,2.56,0,0,0-2,1.25Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M575.64,565.68c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.69-3.4-5.17S573.16,565.68,575.64,565.68Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.61S577,566.26,575.63,566.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M583.47,565.68c2.45,0,3.35,2.72,3.35,5.17s-.9,5.19-3.35,5.19-3.4-2.69-3.4-5.17S581,565.68,583.47,565.68Zm0,.58c-1.42,0-1.76,2.35-1.76,4.57s.42,4.62,1.77,4.62,1.74-2.37,1.74-4.61S584.82,566.26,583.46,566.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M596.67,575.84v-.46c.89-.1,1-.16,1-1.26v-2.69c0-1.17-.42-1.8-1.33-1.8a2.74,2.74,0,0,0-1.67.77,5.62,5.62,0,0,1,.05.68v3.13c0,1,.13,1.07,1,1.17v.46h-3.46v-.46c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.94,2.94,0,0,0-1.66.76v3.8c0,1,.11,1.09,1,1.19v.46h-3.48v-.46c1-.1,1.11-.17,1.11-1.2v-3.33c0-1-.05-1.08-.92-1.23v-.42a9.65,9.65,0,0,0,2.33-.64v1.28a12.32,12.32,0,0,1,1.15-.8,2.24,2.24,0,0,1,1.28-.43,1.86,1.86,0,0,1,1.75,1.3c.44-.35.84-.61,1.24-.88a2.37,2.37,0,0,1,1.26-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.46Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M607.79,573.62c-.22.75-.5,1.62-.66,2.22H601v-.39c.87-.86,1.83-1.8,2.62-2.71,1-1.22,1.93-2.46,1.93-3.83s-.68-2.06-1.84-2.06a2.79,2.79,0,0,0-2.2,1.48l-.39-.35.94-1.33a2.91,2.91,0,0,1,2.23-1,2.65,2.65,0,0,1,2.77,2.78c0,1.35-.5,2.3-2,3.84-.67.7-1.66,1.65-2.33,2.27h3c.89,0,1.12-.07,1.67-1.08Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M609.31,564.1a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.89,9.89,0,0,0,2.63-7,10.07,10.07,0,0,0-2.63-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M577.78,456c-1.41.19-1.56.31-1.56,2.09v7.67c0,2.73-.35,4.29-1.68,5.62a5.75,5.75,0,0,1-1.81,1.23l-.29-.66a3.6,3.6,0,0,0,1.82-2.76,26.21,26.21,0,0,0,.19-4v-7.08c0-1.78-.17-1.92-1.66-2.09v-.62h5Z" />
        <path d="M579.66,469.43c-.7,0-1.18-.58-1.18-1.43a1.27,1.27,0,0,1,1.18-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,579.66,469.43Z" />
        <path d="M588.5,465.33v1.55c0,1.37.07,1.49,1.4,1.64v.62h-4.46v-.62c1.36-.15,1.44-.3,1.44-1.67v-1.52h-4.82v-.68c1.78-2.89,3.73-6,5.51-8.85h.93v8.47h1.78v1.06Zm-1.62-7.19c-1.1,1.85-2.39,4-3.65,6.13h3.65Z" />
        <path d="M597.48,465.33v1.55c0,1.37.08,1.49,1.4,1.64v.62h-4.46v-.62c1.36-.15,1.45-.3,1.45-1.67v-1.52H591v-.68c1.78-2.89,3.73-6,5.52-8.85h.92v8.47h1.79v1.06Zm-1.61-7.19c-1.11,1.85-2.39,4-3.65,6.13h3.65Z" />
        <path
          d="M562.88,493.1a9.31,9.31,0,0,1,0-14.84l.34.42a9.88,9.88,0,0,0-2.62,7,10.12,10.12,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M564.75,481.79l.76-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.71,1.71,0,0,1-.51,1.18,6.84,6.84,0,0,1-1.15.86,2.64,2.64,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.66,2.66,0,0,1-1.69-.56.7.7,0,0,1-.26-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.35a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16l-.11-.5c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.48,2.54,2.54,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M575.64,479.84c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17C572.24,482.69,573.16,479.84,575.64,479.84Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.6S577,480.42,575.63,480.42Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M583.47,479.84c2.45,0,3.35,2.72,3.35,5.17s-.9,5.19-3.35,5.19-3.4-2.68-3.4-5.17C580.07,482.69,581,479.84,583.47,479.84Zm0,.58c-1.42,0-1.76,2.35-1.76,4.57s.42,4.62,1.77,4.62,1.74-2.37,1.74-4.6S584.82,480.42,583.46,480.42Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M596.67,490v-.47c.89-.1,1-.16,1-1.25V485.6c0-1.18-.42-1.81-1.33-1.81a2.74,2.74,0,0,0-1.67.77,5.65,5.65,0,0,1,.05.69v3.13c0,1,.13,1.06,1,1.16V490h-3.46v-.47c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.66.77v3.79c0,1,.11,1.09,1,1.19V490h-3.48v-.47c1-.1,1.11-.16,1.11-1.2V485c0-1-.05-1.08-.92-1.23v-.42a9.65,9.65,0,0,0,2.33-.64V484a12.32,12.32,0,0,1,1.15-.8,2.24,2.24,0,0,1,1.28-.43,1.86,1.86,0,0,1,1.75,1.3c.44-.34.84-.61,1.24-.88a2.46,2.46,0,0,1,1.26-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19V490Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M607.79,487.78c-.22.75-.5,1.62-.66,2.23H601v-.4c.87-.86,1.83-1.8,2.62-2.71,1-1.22,1.93-2.46,1.93-3.83s-.68-2.05-1.84-2.05a2.78,2.78,0,0,0-2.2,1.47l-.39-.35.94-1.33a2.91,2.91,0,0,1,2.23-1,2.66,2.66,0,0,1,2.77,2.79c0,1.34-.5,2.29-2,3.83-.67.7-1.66,1.65-2.33,2.28h3c.89,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M609.31,478.26a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.87,9.87,0,0,0,2.63-7,10,10,0,0,0-2.63-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M642,541.87c-1.41.19-1.56.31-1.56,2.09v7.67c0,2.72-.35,4.29-1.67,5.61a5.94,5.94,0,0,1-1.82,1.24l-.29-.66a3.6,3.6,0,0,0,1.82-2.76,26.21,26.21,0,0,0,.19-4V544c0-1.78-.17-1.92-1.66-2.09v-.63h5Z" />
        <path d="M643.85,555.27a1.27,1.27,0,0,1-1.18-1.43,1.29,1.29,0,0,1,1.18-1.43,1.31,1.31,0,0,1,1.22,1.43A1.29,1.29,0,0,1,643.85,555.27Z" />
        <path d="M653.48,541.81l.11.18-.49,1.52h-4.29c-.17,1.12-.41,2.72-.57,3.73a7.42,7.42,0,0,1,2.21-.62,3.44,3.44,0,0,1,3.49,3.58,4.72,4.72,0,0,1-1.63,3.57,5.37,5.37,0,0,1-3.29,1.46,2.64,2.64,0,0,1-1.92-.8.81.81,0,0,1-.32-.74,1.22,1.22,0,0,1,.41-.79.39.39,0,0,1,.56,0,3.11,3.11,0,0,0,2.33,1.19c1.47,0,2.17-1.46,2.17-3,0-1.87-1-3.18-3-3.18a5.08,5.08,0,0,0-1.85.37l-.06-.09c.29-1.7.7-4.63.91-6.37Z" />
        <path d="M658.77,547a2.38,2.38,0,0,1,1.18-.37c2,0,3.31,1.72,3.31,3.92,0,2.56-1.61,4.69-3.86,4.69s-3.88-2-3.88-5.3a8.49,8.49,0,0,1,5.07-7.93,13.89,13.89,0,0,1,2.19-.57l.11.73a9.52,9.52,0,0,0-2,.72,6.5,6.5,0,0,0-3.31,4.79Zm.38.89a3.24,3.24,0,0,0-1.67.53,8,8,0,0,0-.12,1.48c0,2.67.86,4.56,2.35,4.56s1.79-1.72,1.79-3.12C661.5,549.53,660.74,547.88,659.15,547.88Z" />
        <path
          d="M627.07,578.94a9.31,9.31,0,0,1,0-14.84l.34.42a9.86,9.86,0,0,0-2.62,7,10.12,10.12,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M628.94,567.63l.76-1a2.86,2.86,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.67,1.67,0,0,1-.51,1.17,6.82,6.82,0,0,1-1.15.87,2.63,2.63,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.6,2.6,0,0,1-1.68-.56.73.73,0,0,1-.27-.55,1,1,0,0,1,.38-.64.33.33,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.36a2,2,0,0,0-2.09-2.05,3.83,3.83,0,0,0-1,.17l-.11-.5c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.5-1.48,2.56,2.56,0,0,0-2,1.25Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M639.83,565.68c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.69-3.4-5.17S637.35,565.68,639.83,565.68Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.61S641.18,566.26,639.82,566.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M647.66,565.68c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.69-3.4-5.17S645.18,565.68,647.66,565.68Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.61S649,566.26,647.65,566.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M660.86,575.84v-.46c.89-.1,1-.16,1-1.26v-2.69c0-1.17-.43-1.8-1.33-1.8a2.7,2.7,0,0,0-1.67.77c0,.19,0,.39,0,.68v3.13c0,1,.13,1.07,1,1.17v.46h-3.46v-.46c1-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.38-1.78-1.28-1.78a2.94,2.94,0,0,0-1.67.76v3.8c0,1,.11,1.09,1,1.19v.46H652v-.46c1-.1,1.11-.17,1.11-1.2v-3.33c0-1,0-1.08-.92-1.23v-.42a9.52,9.52,0,0,0,2.33-.64v1.28a13.82,13.82,0,0,1,1.15-.8,2.24,2.24,0,0,1,1.28-.43,1.85,1.85,0,0,1,1.75,1.3c.44-.35.84-.61,1.24-.88a2.38,2.38,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.1,1.09,1,1.19v.46Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M672,573.62c-.22.75-.5,1.62-.66,2.22h-6.11v-.39c.87-.86,1.83-1.8,2.62-2.71,1-1.22,1.93-2.46,1.93-3.83s-.68-2.06-1.83-2.06a2.79,2.79,0,0,0-2.21,1.48l-.39-.35.94-1.33a2.92,2.92,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.78c0,1.35-.5,2.3-2,3.84-.67.7-1.66,1.65-2.33,2.27h3c.89,0,1.12-.07,1.67-1.08Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M673.5,564.1a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.89,9.89,0,0,0,2.64-7,10.07,10.07,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M642,456c-1.41.19-1.56.31-1.56,2.09v7.67c0,2.73-.35,4.29-1.67,5.62a5.89,5.89,0,0,1-1.82,1.23l-.29-.66a3.6,3.6,0,0,0,1.82-2.76,26.21,26.21,0,0,0,.19-4v-7.08c0-1.78-.17-1.92-1.66-2.09v-.62h5Z" />
        <path d="M643.85,469.43c-.69,0-1.18-.58-1.18-1.43a1.28,1.28,0,0,1,1.18-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,643.85,469.43Z" />
        <path d="M652.69,465.33v1.55c0,1.37.07,1.49,1.4,1.64v.62h-4.46v-.62c1.36-.15,1.44-.3,1.44-1.67v-1.52h-4.82v-.68c1.78-2.89,3.73-6,5.51-8.85h.93v8.47h1.78v1.06Zm-1.62-7.19c-1.1,1.85-2.39,4-3.65,6.13h3.65Z" />
        <path d="M655.87,458.29l.87-1.34a3.09,3.09,0,0,1,2.42-1.23,2.71,2.71,0,0,1,2.82,2.84,2.4,2.4,0,0,1-.58,1.55,7.58,7.58,0,0,1-1.32,1.14,3.32,3.32,0,0,1,2.75,3.25c0,3.43-3.62,4.9-4.9,4.9a2.76,2.76,0,0,1-1.93-.74,1,1,0,0,1-.31-.72,1.37,1.37,0,0,1,.44-.85.35.35,0,0,1,.53,0,3.25,3.25,0,0,0,2.29,1.16c1.26,0,2.15-1.08,2.15-3.11a2.41,2.41,0,0,0-2.4-2.71,3.77,3.77,0,0,0-1.19.22l-.13-.66c1.65-.62,2.9-1.44,2.9-3,0-1.26-.7-2-1.72-2a2.91,2.91,0,0,0-2.29,1.66Z" />
        <path
          d="M627.07,493.1a9.31,9.31,0,0,1,0-14.84l.34.42a9.88,9.88,0,0,0-2.62,7,10.12,10.12,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M628.94,481.79l.76-1a2.86,2.86,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.71,1.71,0,0,1-.51,1.18,6.84,6.84,0,0,1-1.15.86,2.64,2.64,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.6,2.6,0,0,1-1.68-.56.72.72,0,0,1-.27-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.35a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16l-.11-.5c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.5-1.48,2.55,2.55,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M639.83,479.84c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17C636.43,482.69,637.35,479.84,639.83,479.84Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.6S641.18,480.42,639.82,480.42Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M647.66,479.84c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17C644.26,482.69,645.18,479.84,647.66,479.84Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.6S649,480.42,647.65,480.42Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M660.86,490v-.47c.89-.1,1-.16,1-1.25V485.6c0-1.18-.43-1.81-1.33-1.81a2.7,2.7,0,0,0-1.67.77c0,.19,0,.39,0,.69v3.13c0,1,.13,1.06,1,1.16V490h-3.46v-.47c1-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.38-1.78-1.28-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.09,1,1.19V490H652v-.47c1-.1,1.11-.16,1.11-1.2V485c0-1,0-1.08-.92-1.23v-.42a9.52,9.52,0,0,0,2.33-.64V484a13.82,13.82,0,0,1,1.15-.8,2.24,2.24,0,0,1,1.28-.43,1.85,1.85,0,0,1,1.75,1.3c.44-.34.84-.61,1.24-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.1,1.09,1,1.19V490Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M672,487.78c-.22.75-.5,1.62-.66,2.23h-6.11v-.4c.87-.86,1.83-1.8,2.62-2.71,1-1.22,1.93-2.46,1.93-3.83s-.68-2.05-1.83-2.05a2.78,2.78,0,0,0-2.21,1.47l-.39-.35.94-1.33a2.92,2.92,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.5,2.29-2,3.83-.67.7-1.66,1.65-2.33,2.28h3c.89,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M673.5,478.26a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.87,9.87,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M705.55,541.87c-1.41.19-1.56.31-1.56,2.09v7.67c0,2.72-.35,4.29-1.68,5.61a5.92,5.92,0,0,1-1.81,1.24l-.29-.66a3.6,3.6,0,0,0,1.82-2.76,26.21,26.21,0,0,0,.19-4V544c0-1.78-.17-1.92-1.66-2.09v-.63h5Z" />
        <path d="M707.43,555.27a1.27,1.27,0,0,1-1.19-1.43,1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.29,1.29,0,0,1,707.43,555.27Z" />
        <path d="M717.06,541.81l.11.18-.5,1.52h-4.28c-.17,1.12-.41,2.72-.58,3.73a7.47,7.47,0,0,1,2.22-.62,3.43,3.43,0,0,1,3.48,3.58,4.7,4.7,0,0,1-1.62,3.57,5.39,5.39,0,0,1-3.3,1.46,2.6,2.6,0,0,1-1.91-.8.82.82,0,0,1-.33-.74,1.24,1.24,0,0,1,.42-.79.38.38,0,0,1,.55,0,3.15,3.15,0,0,0,2.33,1.19c1.47,0,2.17-1.46,2.17-3,0-1.87-1-3.18-3-3.18a5.14,5.14,0,0,0-1.86.37l-.06-.09c.3-1.7.71-4.63.92-6.37Z" />
        <path d="M727,542.1c-1.77,4.28-3.5,8.55-5.26,12.91l-1.41.17-.13-.24c1.93-3.58,3.92-7.85,5.39-11.43h-3.92c-1.2,0-1.29.18-1.79,1.56h-.59c.2-1.28.28-2.34.38-3.26h7.21Z" />
        <path
          d="M698.65,578.94a9.29,9.29,0,0,1,0-14.84l.34.42a9.86,9.86,0,0,0-2.62,7,10.07,10.07,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M705.58,573v1.18c0,1,.06,1.13,1.22,1.24v.47h-3.89v-.47c1.19-.11,1.26-.23,1.26-1.27V573H700v-.51c1.55-2.19,3.25-4.56,4.8-6.7h.81v6.42h1.55V573Zm-1.41-5.44c-1,1.4-2.08,3-3.18,4.65h3.18Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M714.77,573.62c-.22.75-.49,1.62-.65,2.22H708v-.39c.88-.86,1.83-1.8,2.62-2.71a6.27,6.27,0,0,0,1.93-3.83c0-1.29-.68-2.06-1.83-2.06a2.76,2.76,0,0,0-2.2,1.48l-.39-.35.93-1.33a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.78c0,1.35-.49,2.3-2,3.84-.68.7-1.67,1.65-2.34,2.27h3c.89,0,1.12-.07,1.68-1.08Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M721.93,565.87l.09.13-.43,1.16h-3.74c-.14.84-.35,2.06-.49,2.82a7.31,7.31,0,0,1,1.93-.47,2.8,2.8,0,0,1,3,2.71,3.39,3.39,0,0,1-1.41,2.71A5.09,5.09,0,0,1,718,576a2.47,2.47,0,0,1-1.68-.61c-.24-.22-.3-.35-.28-.56a.87.87,0,0,1,.37-.6.36.36,0,0,1,.48,0,2.87,2.87,0,0,0,2,.9c1.28,0,1.89-1.11,1.89-2.3a2.3,2.3,0,0,0-2.59-2.41,5.24,5.24,0,0,0-1.61.28l0-.06c.25-1.29.61-3.51.79-4.83Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M732.44,575.84v-.46c.89-.1,1-.16,1-1.26v-2.69c0-1.17-.42-1.8-1.33-1.8a2.74,2.74,0,0,0-1.67.77,5.62,5.62,0,0,1,0,.68v3.13c0,1,.13,1.07,1,1.17v.46H728v-.46c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.91,2.91,0,0,0-1.66.76v3.8c0,1,.11,1.09,1,1.19v.46h-3.48v-.46c1-.1,1.11-.17,1.11-1.2v-3.33c0-1-.05-1.08-.92-1.23v-.42a9.77,9.77,0,0,0,2.33-.64v1.28a13.5,13.5,0,0,1,1.14-.8,2.29,2.29,0,0,1,1.29-.43,1.86,1.86,0,0,1,1.75,1.3c.44-.35.83-.61,1.23-.88a2.4,2.4,0,0,1,1.27-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.46Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M743.55,573.62c-.21.75-.49,1.62-.65,2.22h-6.11v-.39c.87-.86,1.83-1.8,2.62-2.71,1-1.22,1.93-2.46,1.93-3.83s-.68-2.06-1.84-2.06a2.78,2.78,0,0,0-2.2,1.48l-.39-.35.93-1.33a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.78c0,1.35-.49,2.3-2,3.84-.68.7-1.66,1.65-2.33,2.27h3c.89,0,1.12-.07,1.67-1.08Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M745.08,564.1a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.89,9.89,0,0,0,2.63-7,10.07,10.07,0,0,0-2.63-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M697.09,456c-1.4.19-1.55.31-1.55,2.09v7.67c0,2.73-.36,4.29-1.68,5.62a6,6,0,0,1-1.81,1.23l-.3-.66a3.59,3.59,0,0,0,1.83-2.76,27.56,27.56,0,0,0,.19-4v-7.08c0-1.78-.17-1.92-1.66-2.09v-.62h5Z" />
        <path d="M699,469.43c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42A1.3,1.3,0,0,1,700.2,468,1.29,1.29,0,0,1,699,469.43Z" />
        <path d="M707.81,465.33v1.55c0,1.37.08,1.49,1.4,1.64v.62h-4.46v-.62c1.36-.15,1.45-.3,1.45-1.67v-1.52h-4.83v-.68c1.78-2.89,3.73-6,5.52-8.85h.92v8.47h1.79v1.06Zm-1.61-7.19c-1.11,1.85-2.39,4-3.65,6.13h3.65Z" />
        <path d="M718.36,466.21c-.24,1-.56,2.13-.75,2.93h-7v-.52c1-1.13,2.1-2.38,3-3.58,1.2-1.6,2.22-3.25,2.22-5.05s-.78-2.72-2.11-2.72-2,1.1-2.53,2l-.44-.46,1.07-1.76a3.21,3.21,0,0,1,2.57-1.28c1.76,0,3.17,1.39,3.17,3.68,0,1.77-.57,3-2.34,5.07-.77.91-1.91,2.17-2.67,3H716c1,0,1.28-.11,1.92-1.44Z" />
        <path
          d="M690.65,493.1a9.31,9.31,0,0,1,0-14.84l.34.42a9.88,9.88,0,0,0-2.62,7,10.07,10.07,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M692.52,481.79l.76-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.71,1.71,0,0,1-.51,1.18,6.84,6.84,0,0,1-1.15.86,2.64,2.64,0,0,1,2.4,2.46c0,2.6-3.16,3.71-4.27,3.71a2.66,2.66,0,0,1-1.69-.56.7.7,0,0,1-.26-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.35A2,2,0,0,0,695,485a3.85,3.85,0,0,0-1,.16l-.11-.5c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.48,2.54,2.54,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M703.41,479.84c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19S700,487.52,700,485C700,482.69,700.93,479.84,703.41,479.84Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.6S704.76,480.42,703.4,480.42Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M711.24,479.84c2.45,0,3.35,2.72,3.35,5.17s-.9,5.19-3.35,5.19-3.4-2.68-3.4-5.17C707.84,482.69,708.76,479.84,711.24,479.84Zm0,.58c-1.42,0-1.76,2.35-1.76,4.57s.42,4.62,1.77,4.62S713,487.24,713,485,712.59,480.42,711.23,480.42Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M724.44,490v-.47c.89-.1,1-.16,1-1.25V485.6c0-1.18-.42-1.81-1.33-1.81a2.74,2.74,0,0,0-1.67.77,5.65,5.65,0,0,1,0,.69v3.13c0,1,.13,1.06,1,1.16V490H720v-.47c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.66.77v3.79c0,1,.11,1.09,1,1.19V490h-3.48v-.47c1-.1,1.11-.16,1.11-1.2V485c0-1-.05-1.08-.92-1.23v-.42a9.65,9.65,0,0,0,2.33-.64V484a12.32,12.32,0,0,1,1.15-.8,2.24,2.24,0,0,1,1.28-.43,1.86,1.86,0,0,1,1.75,1.3c.44-.34.84-.61,1.24-.88a2.46,2.46,0,0,1,1.26-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19V490Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M735.56,487.78c-.22.75-.5,1.62-.66,2.23h-6.11v-.4c.87-.86,1.83-1.8,2.62-2.71,1-1.22,1.93-2.46,1.93-3.83s-.68-2.05-1.84-2.05a2.78,2.78,0,0,0-2.2,1.47l-.39-.35.94-1.33a2.91,2.91,0,0,1,2.23-1,2.66,2.66,0,0,1,2.77,2.79c0,1.34-.5,2.29-2,3.83-.68.7-1.66,1.65-2.33,2.28h3c.89,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M737.08,478.26a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.87,9.87,0,0,0,2.63-7,10,10,0,0,0-2.63-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M778.22,456c-1.4.19-1.55.31-1.55,2.09v7.67c0,2.73-.36,4.29-1.68,5.62a5.86,5.86,0,0,1-1.81,1.23l-.29-.66a3.6,3.6,0,0,0,1.82-2.76,26.21,26.21,0,0,0,.19-4v-7.08c0-1.78-.17-1.92-1.66-2.09v-.62h5Z" />
        <path d="M780.11,469.43c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,780.11,469.43Z" />
        <path d="M788.94,465.33v1.55c0,1.37.08,1.49,1.4,1.64v.62h-4.46v-.62c1.37-.15,1.45-.3,1.45-1.67v-1.52H782.5v-.68c1.79-2.89,3.73-6,5.52-8.85h.92v8.47h1.79v1.06Zm-1.61-7.19c-1.11,1.85-2.39,4-3.65,6.13h3.65Z" />
        <path d="M792.76,469.14v-.62c2-.14,2.13-.27,2.13-1.85v-8c0-1.08-.16-1.13-1-1.21l-.84-.11v-.56a14.16,14.16,0,0,0,3.49-1v10.95c0,1.6.11,1.71,2.14,1.85v.62Z" />
        <path
          d="M763.33,493.1a9.29,9.29,0,0,1,0-14.84l.34.42a9.88,9.88,0,0,0-2.62,7,10.07,10.07,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M765.2,481.79l.76-1a2.84,2.84,0,0,1,2.11-.93,2.21,2.21,0,0,1,2.45,2.15,1.74,1.74,0,0,1-.5,1.18,7.28,7.28,0,0,1-1.15.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.16,3.71-4.27,3.71a2.63,2.63,0,0,1-1.69-.56.72.72,0,0,1-.27-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.35a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16l-.11-.5c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.48,2.54,2.54,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M776.21,479.84a2.48,2.48,0,0,1,2.67,2.39,2,2,0,0,1-.46,1.29,15.13,15.13,0,0,1-1.3,1c1.11.6,2.18,1.37,2.18,2.76A3.11,3.11,0,0,1,776,490.2c-1.84,0-3.16-1-3.16-2.66a1.9,1.9,0,0,1,.73-1.56,16.61,16.61,0,0,1,1.44-1,3,3,0,0,1-1.77-2.51A2.76,2.76,0,0,1,776.21,479.84Zm0,9.79a1.61,1.61,0,0,0,1.67-1.82c0-1.25-1-1.87-2.36-2.52a2.39,2.39,0,0,0-1.26,2.13A2,2,0,0,0,776.21,489.63ZM776,480.4a1.43,1.43,0,0,0-1.42,1.6c0,1.15.79,1.6,2,2.24a2.27,2.27,0,0,0,1-1.94C777.6,481,776.8,480.4,776,480.4Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M786.61,480l.09.14-.43,1.15h-3.74c-.14.84-.35,2.06-.5,2.82a7.46,7.46,0,0,1,1.94-.47,2.8,2.8,0,0,1,3,2.72,3.37,3.37,0,0,1-1.42,2.7,5,5,0,0,1-2.87,1.11,2.46,2.46,0,0,1-1.67-.61c-.24-.22-.3-.35-.28-.56a.87.87,0,0,1,.37-.6.37.37,0,0,1,.48,0,2.87,2.87,0,0,0,2,.9c1.28,0,1.89-1.11,1.89-2.3a2.3,2.3,0,0,0-2.59-2.41,4.88,4.88,0,0,0-1.61.29l-.05-.07c.25-1.29.61-3.51.79-4.83Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M797.12,490v-.47c.89-.1,1-.16,1-1.25V485.6c0-1.18-.43-1.81-1.33-1.81a2.74,2.74,0,0,0-1.67.77,5.65,5.65,0,0,1,0,.69v3.13c0,1,.12,1.06,1,1.16V490h-3.46v-.47c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.84,2.84,0,0,0-1.66.77v3.79c0,1,.11,1.09,1,1.19V490h-3.48v-.47c1-.1,1.11-.16,1.11-1.2V485c0-1-.06-1.08-.93-1.23v-.42a9.72,9.72,0,0,0,2.34-.64V484a12,12,0,0,1,1.14-.8,2.29,2.29,0,0,1,1.28-.43,1.86,1.86,0,0,1,1.76,1.3c.43-.34.83-.61,1.23-.88a2.5,2.5,0,0,1,1.27-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19V490Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M808.23,487.78c-.21.75-.49,1.62-.65,2.23h-6.11v-.4c.87-.86,1.83-1.8,2.62-2.71a6.3,6.3,0,0,0,1.92-3.83c0-1.29-.67-2.05-1.83-2.05a2.77,2.77,0,0,0-2.2,1.47l-.39-.35.93-1.33a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.83-.68.7-1.67,1.65-2.33,2.28h3c.88,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M809.76,478.26a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.83,9.83,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <g id="_1-3" data-name="1">
          <path d="M94.77,857.51c-1.41.18-1.56.31-1.56,2.09v7.66c0,2.73-.35,4.3-1.67,5.62a5.94,5.94,0,0,1-1.82,1.24l-.29-.66a3.62,3.62,0,0,0,1.82-2.76,26.36,26.36,0,0,0,.19-4V859.6c0-1.78-.17-1.93-1.66-2.09v-.63h5Z" />
          <path d="M96.65,870.91a1.27,1.27,0,0,1-1.18-1.43,1.29,1.29,0,0,1,1.18-1.43,1.31,1.31,0,0,1,1.22,1.43A1.29,1.29,0,0,1,96.65,870.91Z" />
          <path d="M100.32,870.62V870c2-.14,2.13-.27,2.13-1.84v-8c0-1.08-.17-1.13-1-1.22l-.84-.1v-.56a14.61,14.61,0,0,0,3.49-1v10.95c0,1.6.11,1.7,2.13,1.84v.63Z" />
          <path d="M94.77,788.82c-1.41.19-1.56.31-1.56,2.09v7.67c0,2.72-.35,4.29-1.67,5.61a5.77,5.77,0,0,1-1.82,1.24l-.29-.66A3.6,3.6,0,0,0,91.25,802a26.21,26.21,0,0,0,.19-4v-7.08c0-1.78-.17-1.92-1.66-2.09v-.63h5Z" />
          <path d="M96.65,802.22c-.69,0-1.18-.58-1.18-1.43a1.28,1.28,0,0,1,1.18-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,96.65,802.22Z" />
          <path d="M107.05,799c-.24,1-.56,2.13-.75,2.93h-7v-.52c1-1.13,2.1-2.38,3-3.58,1.2-1.6,2.22-3.25,2.22-5.05s-.78-2.72-2.11-2.72-2,1.1-2.52,2l-.45-.46,1.07-1.76a3.21,3.21,0,0,1,2.57-1.28c1.76,0,3.17,1.39,3.17,3.68,0,1.77-.57,3-2.34,5.07-.77.91-1.91,2.17-2.67,3h3.43c1,0,1.28-.11,1.92-1.44Z" />
          <path d="M94.77,725.17c-1.41.18-1.56.31-1.56,2.08v7.67c0,2.73-.35,4.29-1.67,5.62a5.94,5.94,0,0,1-1.82,1.24l-.29-.67a3.6,3.6,0,0,0,1.82-2.75,26.43,26.43,0,0,0,.19-4v-7.08c0-1.77-.17-1.92-1.66-2.08v-.63h5Z" />
          <path d="M96.65,738.56a1.26,1.26,0,0,1-1.18-1.42,1.29,1.29,0,0,1,1.18-1.43,1.31,1.31,0,0,1,1.22,1.43A1.28,1.28,0,0,1,96.65,738.56Z" />
          <path d="M99.68,727.43l.87-1.35a3.13,3.13,0,0,1,2.43-1.22,2.7,2.7,0,0,1,2.81,2.83,2.48,2.48,0,0,1-.57,1.56,8.67,8.67,0,0,1-1.33,1.14,3.32,3.32,0,0,1,2.76,3.24c0,3.43-3.62,4.9-4.9,4.9a2.83,2.83,0,0,1-1.94-.73,1,1,0,0,1-.3-.73,1.26,1.26,0,0,1,.43-.84.35.35,0,0,1,.53,0,3.28,3.28,0,0,0,2.3,1.17c1.25,0,2.15-1.08,2.15-3.12a2.42,2.42,0,0,0-2.4-2.7,4,4,0,0,0-1.19.21l-.13-.65c1.64-.62,2.9-1.44,2.9-3,0-1.27-.71-2-1.73-2a2.93,2.93,0,0,0-2.28,1.66Z" />
          <path d="M94.77,662.17c-1.41.18-1.56.31-1.56,2.08v7.67c0,2.73-.35,4.29-1.67,5.62a5.94,5.94,0,0,1-1.82,1.24l-.29-.67a3.6,3.6,0,0,0,1.82-2.75,26.43,26.43,0,0,0,.19-4v-7.08c0-1.77-.17-1.92-1.66-2.08v-.63h5Z" />
          <path d="M96.65,675.56a1.26,1.26,0,0,1-1.18-1.42,1.29,1.29,0,0,1,1.18-1.43,1.31,1.31,0,0,1,1.22,1.43A1.28,1.28,0,0,1,96.65,675.56Z" />
          <path d="M105.49,671.46V673c0,1.38.07,1.5,1.4,1.64v.63h-4.46v-.63c1.36-.14,1.44-.3,1.44-1.67v-1.52H99.05v-.67c1.78-2.89,3.73-6,5.51-8.86h.93v8.48h1.78v1.05Zm-1.62-7.19c-1.1,1.85-2.39,4-3.65,6.14h3.65Z" />
          <path d="M94.77,598.51c-1.41.19-1.56.31-1.56,2.09v7.67c0,2.72-.35,4.29-1.67,5.61a5.77,5.77,0,0,1-1.82,1.24l-.29-.66a3.6,3.6,0,0,0,1.82-2.76,26.21,26.21,0,0,0,.19-4V600.6c0-1.78-.17-1.92-1.66-2.09v-.63h5Z" />
          <path d="M96.65,611.91c-.69,0-1.18-.58-1.18-1.43a1.29,1.29,0,0,1,1.18-1.43,1.31,1.31,0,0,1,1.22,1.43A1.29,1.29,0,0,1,96.65,611.91Z" />
          <path d="M106.28,598.46l.11.17-.49,1.52h-4.29c-.17,1.12-.41,2.72-.57,3.73a7.42,7.42,0,0,1,2.21-.62,3.44,3.44,0,0,1,3.49,3.58,4.72,4.72,0,0,1-1.63,3.57,5.37,5.37,0,0,1-3.29,1.46,2.64,2.64,0,0,1-1.92-.8.78.78,0,0,1-.32-.74,1.24,1.24,0,0,1,.41-.79.38.38,0,0,1,.55,0,3.15,3.15,0,0,0,2.34,1.19c1.47,0,2.16-1.46,2.16-3,0-1.87-1-3.18-3-3.18a4.86,4.86,0,0,0-1.85.38l-.06-.1c.29-1.69.7-4.62.91-6.36Z" />
          <path d="M94.77,535.51c-1.41.19-1.56.31-1.56,2.09v7.67c0,2.72-.35,4.29-1.67,5.61a5.77,5.77,0,0,1-1.82,1.24l-.29-.66a3.6,3.6,0,0,0,1.82-2.76,26.21,26.21,0,0,0,.19-4V537.6c0-1.78-.17-1.92-1.66-2.09v-.63h5Z" />
          <path d="M96.65,548.91c-.69,0-1.18-.58-1.18-1.43a1.29,1.29,0,0,1,1.18-1.43,1.31,1.31,0,0,1,1.22,1.43A1.29,1.29,0,0,1,96.65,548.91Z" />
          <path d="M102.58,540.63a2.41,2.41,0,0,1,1.18-.37c2,0,3.32,1.72,3.32,3.92,0,2.56-1.62,4.69-3.87,4.69s-3.88-2-3.88-5.3a8.51,8.51,0,0,1,5.07-7.93,13.89,13.89,0,0,1,2.19-.57l.12.73a10.45,10.45,0,0,0-2,.72,6.5,6.5,0,0,0-3.31,4.79Zm.38.89a3.24,3.24,0,0,0-1.67.53,9.08,9.08,0,0,0-.11,1.49c0,2.66.85,4.55,2.35,4.55s1.79-1.72,1.79-3.12C105.32,543.17,104.56,541.52,103,541.52Z" />
          <path d="M94.77,471.86c-1.41.18-1.56.31-1.56,2.08v7.67c0,2.73-.35,4.29-1.67,5.62a5.94,5.94,0,0,1-1.82,1.24l-.29-.67a3.6,3.6,0,0,0,1.82-2.75,26.43,26.43,0,0,0,.19-4v-7.08c0-1.77-.17-1.92-1.66-2.08v-.63h5Z" />
          <path d="M96.65,485.25a1.26,1.26,0,0,1-1.18-1.42,1.29,1.29,0,0,1,1.18-1.43,1.31,1.31,0,0,1,1.22,1.43A1.28,1.28,0,0,1,96.65,485.25Z" />
          <path d="M107.22,472.08c-1.76,4.28-3.49,8.56-5.25,12.92l-1.41.17-.13-.24c1.92-3.58,3.91-7.85,5.39-11.43H101.9c-1.21,0-1.29.18-1.79,1.55h-.59c.19-1.27.28-2.33.37-3.25h7.22Z" />
          <path d="M94.77,408.42c-1.41.18-1.56.31-1.56,2.09v7.66c0,2.73-.35,4.3-1.67,5.62A5.94,5.94,0,0,1,89.72,425l-.29-.67a3.6,3.6,0,0,0,1.82-2.75,26.36,26.36,0,0,0,.19-4v-7.08c0-1.78-.17-1.93-1.66-2.09v-.63h5Z" />
          <path d="M96.65,421.82a1.27,1.27,0,0,1-1.18-1.43A1.29,1.29,0,0,1,96.65,419a1.31,1.31,0,0,1,1.22,1.43A1.29,1.29,0,0,1,96.65,421.82Z" />
          <path d="M103.33,408.11a3,3,0,0,1,3.07,3.15,2.75,2.75,0,0,1-.53,1.7,16.4,16.4,0,0,1-1.49,1.31c1.27.8,2.5,1.81,2.5,3.65a3.77,3.77,0,0,1-3.77,3.86,3.39,3.39,0,0,1-3.63-3.5,2.62,2.62,0,0,1,.84-2.06,17.89,17.89,0,0,1,1.65-1.29,3.93,3.93,0,0,1-2-3.32A3.38,3.38,0,0,1,103.33,408.11Zm0,12.92c1.2,0,1.93-.89,1.93-2.4s-1.17-2.46-2.72-3.33a3.27,3.27,0,0,0-1.44,2.81C101.1,420.07,102.16,421,103.33,421Zm-.24-12.19c-1.06,0-1.62,1-1.62,2.12,0,1.51.9,2.11,2.34,3a3.11,3.11,0,0,0,1.12-2.55C104.93,409.57,104,408.84,103.09,408.84Z" />
          <path d="M94.77,345.42c-1.41.19-1.56.31-1.56,2.09v7.67c0,2.72-.35,4.29-1.67,5.61A5.77,5.77,0,0,1,89.72,362l-.29-.66a3.6,3.6,0,0,0,1.82-2.76,26.21,26.21,0,0,0,.19-4v-7.08c0-1.78-.17-1.92-1.66-2.09v-.63h5Z" />
          <path d="M96.65,358.82a1.45,1.45,0,0,1,0-2.85,1.44,1.44,0,0,1,0,2.85Z" />
          <path d="M105,352.47l-1.17.68a2.12,2.12,0,0,1-1.1.33c-2,0-3.35-1.75-3.35-4,0-2,1.38-4.41,3.85-4.41s3.76,2.43,3.76,5.3a7.71,7.71,0,0,1-7,8.44l-.14-.65C102.52,357.6,104.38,355.62,105,352.47Zm-2-6.59c-1.35,0-1.84,1.46-1.84,3,0,1.85.9,3.39,2.41,3.39a2.44,2.44,0,0,0,1.53-.55,11.72,11.72,0,0,0,.08-1.4C105.13,348.41,104.67,345.87,103,345.88Z" />
          <path d="M91,282.2c-1.41.19-1.56.31-1.56,2.09V292c0,2.73-.36,4.29-1.68,5.62A5.86,5.86,0,0,1,86,298.81l-.29-.66a3.6,3.6,0,0,0,1.82-2.76,26.21,26.21,0,0,0,.19-4v-7.08c0-1.78-.17-1.92-1.66-2.09v-.62h5Z" />
          <path d="M92.88,295.6c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,92.88,295.6Z" />
          <path d="M96.55,295.31v-.62c2-.14,2.13-.27,2.13-1.85v-8c0-1.08-.17-1.13-1-1.21l-.83-.11v-.56a14.43,14.43,0,0,0,3.49-1v10.95c0,1.6.11,1.71,2.13,1.85v.62Z" />
          <path d="M108.41,281.89c2.82,0,3.85,3.59,3.85,6.83,0,3.08-1,6.85-3.85,6.85s-3.9-3.55-3.9-6.83C104.51,285.65,105.56,281.89,108.41,281.89Zm0,.77c-1.63,0-2,3.1-2,6s.49,6.11,2,6.11,2-3.13,2-6.09S110,282.66,108.4,282.66Z" />
          <path
            d="M80.17,900.23a9.31,9.31,0,0,1,0-14.84l.34.42a9.9,9.9,0,0,0-2.62,7,10.15,10.15,0,0,0,2.62,7Z"
            style={{ fill: "#136736" }}
          />
          <path
            d="M82,888.92l.76-1a2.86,2.86,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.69,1.69,0,0,1-.51,1.18,6.34,6.34,0,0,1-1.15.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.66,2.66,0,0,1-1.68-.55.75.75,0,0,1-.27-.56,1,1,0,0,1,.38-.64.35.35,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2,3.92,3.92,0,0,0-1,.16l-.11-.49c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.5-1.49,2.51,2.51,0,0,0-2,1.26Z"
            style={{ fill: "#136736" }}
          />
          <path
            d="M96.44,887.38c-1.54,3.24-3,6.48-4.58,9.78l-1.23.13-.11-.18a94.46,94.46,0,0,0,4.7-8.66H91.8c-1,0-1.12.14-1.56,1.18h-.51c.17-1,.24-1.77.33-2.46h6.28Z"
            style={{ fill: "#136736" }}
          />
          <path
            d="M103.45,887.17l.1.13-.44,1.15H99.38c-.15.85-.35,2.06-.5,2.82a7.83,7.83,0,0,1,1.93-.47,2.8,2.8,0,0,1,3,2.72,3.39,3.39,0,0,1-1.42,2.7,5,5,0,0,1-2.87,1.11,2.46,2.46,0,0,1-1.67-.61c-.25-.21-.31-.35-.28-.56a.91.91,0,0,1,.36-.6.37.37,0,0,1,.48,0,2.87,2.87,0,0,0,2,.9c1.28,0,1.89-1.1,1.89-2.29a2.3,2.3,0,0,0-2.58-2.42,5,5,0,0,0-1.62.29l0-.07c.26-1.29.61-3.51.8-4.82Z"
            style={{ fill: "#136736" }}
          />
          <path
            d="M114,897.14v-.47c.89-.09,1-.16,1-1.25v-2.69c0-1.17-.43-1.81-1.33-1.81a2.72,2.72,0,0,0-1.67.78c0,.19,0,.39,0,.68v3.13c0,1,.13,1.07,1,1.16v.47h-3.45v-.47c.94-.09,1.07-.15,1.07-1.19V892.7c0-1.18-.39-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48v-.47c1-.1,1.12-.16,1.12-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.18,2.18,0,0,1,1.28-.44,1.85,1.85,0,0,1,1.75,1.3c.44-.34.84-.6,1.24-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.1,1.09,1,1.19v.47Z"
            style={{ fill: "#136736" }}
          />
          <path
            d="M125.08,894.92c-.22.74-.5,1.61-.66,2.22h-6.11v-.39c.88-.86,1.83-1.81,2.62-2.72a6.3,6.3,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.83-2.05a2.79,2.79,0,0,0-2.21,1.48l-.38-.35.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.84-.67.69-1.66,1.64-2.33,2.27h3c.89,0,1.12-.08,1.67-1.09Z"
            style={{ fill: "#136736" }}
          />
          <path
            d="M126.6,885.39a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.85,9.85,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
            style={{ fill: "#136736" }}
          />
          <path
            d="M80.17,827.47a9.31,9.31,0,0,1,0-14.84l.34.42a9.9,9.9,0,0,0-2.62,7,10.15,10.15,0,0,0,2.62,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M82,816.16l.76-1a2.86,2.86,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.69,1.69,0,0,1-.51,1.18,6.34,6.34,0,0,1-1.15.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.66,2.66,0,0,1-1.68-.55.75.75,0,0,1-.27-.56,1,1,0,0,1,.38-.64.35.35,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2,3.92,3.92,0,0,0-1,.16l-.11-.49c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.5-1.49,2.51,2.51,0,0,0-2,1.26Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M92.93,814.21c2.46,0,3.36,2.73,3.36,5.17s-.9,5.19-3.36,5.19-3.4-2.68-3.4-5.17S90.45,814.21,92.93,814.21Zm0,.59c-1.41,0-1.76,2.34-1.76,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S94.28,814.8,92.92,814.8Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M100.76,814.21c2.46,0,3.35,2.73,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17S98.28,814.21,100.76,814.21Zm0,.59c-1.41,0-1.76,2.34-1.76,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S102.11,814.8,100.75,814.8Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M114,824.38v-.47c.89-.09,1-.16,1-1.25V820c0-1.17-.43-1.81-1.33-1.81a2.72,2.72,0,0,0-1.67.78c0,.19,0,.39,0,.68v3.13c0,1,.13,1.07,1,1.16v.47h-3.45v-.47c.94-.09,1.07-.15,1.07-1.19v-2.78c0-1.18-.39-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48v-.47c1-.1,1.12-.16,1.12-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.18,2.18,0,0,1,1.28-.44,1.87,1.87,0,0,1,1.75,1.3c.44-.34.84-.6,1.24-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.1,1.09,1,1.19v.47Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M125.08,822.16c-.22.74-.5,1.61-.66,2.22h-6.11V824c.88-.86,1.83-1.81,2.62-2.72a6.3,6.3,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.83-2.05a2.79,2.79,0,0,0-2.21,1.48l-.38-.35.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.84-.67.69-1.66,1.64-2.33,2.27h3c.89,0,1.12-.08,1.67-1.09Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M126.6,812.63a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.85,9.85,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M80.17,764.91a9.31,9.31,0,0,1,0-14.84l.34.42a9.88,9.88,0,0,0-2.62,7,10.17,10.17,0,0,0,2.62,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M82,753.6l.76-1a2.86,2.86,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.69,1.69,0,0,1-.51,1.18,6.84,6.84,0,0,1-1.15.86,2.64,2.64,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.6,2.6,0,0,1-1.68-.56.72.72,0,0,1-.27-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.35a2,2,0,0,0-2.09-2,3.92,3.92,0,0,0-1,.16l-.11-.5c1.43-.47,2.53-1.09,2.53-2.25a1.39,1.39,0,0,0-1.5-1.49,2.51,2.51,0,0,0-2,1.26Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M92.93,751.65c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.4-2.68-3.4-5.17S90.45,751.65,92.93,751.65Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.6S94.28,752.23,92.92,752.23Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M100.76,751.65c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17S98.28,751.65,100.76,751.65Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.6S102.11,752.23,100.75,752.23Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M114,761.82v-.47c.89-.1,1-.16,1-1.25v-2.69c0-1.18-.43-1.81-1.33-1.81a2.7,2.7,0,0,0-1.67.77c0,.19,0,.4,0,.69v3.13c0,1,.13,1.06,1,1.16v.47h-3.45v-.47c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.39-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.09,1,1.19v.47h-3.48v-.47c1-.1,1.12-.16,1.12-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.27a13.82,13.82,0,0,1,1.15-.8,2.24,2.24,0,0,1,1.28-.43,1.85,1.85,0,0,1,1.75,1.3c.44-.34.84-.61,1.24-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.1,1.09,1,1.19v.47Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M125.08,759.59c-.22.75-.5,1.62-.66,2.23h-6.11v-.4c.88-.86,1.83-1.8,2.62-2.71,1-1.22,1.93-2.46,1.93-3.83s-.68-2-1.83-2a2.81,2.81,0,0,0-2.21,1.47l-.38-.34.93-1.34a2.92,2.92,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.83-.67.7-1.66,1.65-2.33,2.28h3c.89,0,1.12-.08,1.67-1.09Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M126.6,750.07a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.87,9.87,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M80.17,701.47a9.31,9.31,0,0,1,0-14.84l.34.42a9.9,9.9,0,0,0-2.62,7,10.15,10.15,0,0,0,2.62,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M82,690.16l.76-1a2.86,2.86,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.69,1.69,0,0,1-.51,1.18,6.34,6.34,0,0,1-1.15.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.66,2.66,0,0,1-1.68-.55.75.75,0,0,1-.27-.56,1,1,0,0,1,.38-.64.35.35,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2,3.92,3.92,0,0,0-1,.16l-.11-.49c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.5-1.49,2.51,2.51,0,0,0-2,1.26Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M92.93,688.21c2.46,0,3.36,2.73,3.36,5.17s-.9,5.19-3.36,5.19-3.4-2.68-3.4-5.17S90.45,688.21,92.93,688.21Zm0,.59c-1.41,0-1.76,2.34-1.76,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S94.28,688.8,92.92,688.8Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M100.76,688.21c2.46,0,3.35,2.73,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17S98.28,688.21,100.76,688.21Zm0,.59c-1.41,0-1.76,2.34-1.76,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S102.11,688.8,100.75,688.8Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M114,698.38v-.47c.89-.09,1-.16,1-1.25V694c0-1.17-.43-1.81-1.33-1.81a2.72,2.72,0,0,0-1.67.78c0,.19,0,.39,0,.68v3.13c0,1,.13,1.07,1,1.16v.47h-3.45v-.47c.94-.09,1.07-.15,1.07-1.19v-2.78c0-1.18-.39-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48v-.47c1-.1,1.12-.16,1.12-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.18,2.18,0,0,1,1.28-.44,1.87,1.87,0,0,1,1.75,1.3c.44-.34.84-.6,1.24-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.1,1.09,1,1.19v.47Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M125.08,696.16c-.22.74-.5,1.61-.66,2.22h-6.11V698c.88-.86,1.83-1.81,2.62-2.72a6.3,6.3,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.83-2.05a2.79,2.79,0,0,0-2.21,1.48l-.38-.35.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.84-.67.69-1.66,1.64-2.33,2.27h3c.89,0,1.12-.08,1.67-1.09Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M126.6,686.63a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.85,9.85,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M80.17,638.47a9.31,9.31,0,0,1,0-14.84l.34.42a9.9,9.9,0,0,0-2.62,7,10.15,10.15,0,0,0,2.62,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M82,627.16l.76-1a2.86,2.86,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.69,1.69,0,0,1-.51,1.18,6.34,6.34,0,0,1-1.15.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.66,2.66,0,0,1-1.68-.55.75.75,0,0,1-.27-.56,1,1,0,0,1,.38-.64.35.35,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2,3.92,3.92,0,0,0-1,.16l-.11-.49c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.5-1.49,2.51,2.51,0,0,0-2,1.26Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M92.93,625.21c2.46,0,3.36,2.73,3.36,5.17s-.9,5.19-3.36,5.19-3.4-2.68-3.4-5.17S90.45,625.21,92.93,625.21Zm0,.59c-1.41,0-1.76,2.34-1.76,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S94.28,625.8,92.92,625.8Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M100.76,625.21c2.46,0,3.35,2.73,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17S98.28,625.21,100.76,625.21Zm0,.59c-1.41,0-1.76,2.34-1.76,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S102.11,625.8,100.75,625.8Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M114,635.38v-.47c.89-.09,1-.16,1-1.25V631c0-1.17-.43-1.81-1.33-1.81a2.72,2.72,0,0,0-1.67.78c0,.19,0,.39,0,.68v3.13c0,1,.13,1.07,1,1.16v.47h-3.45v-.47c.94-.09,1.07-.15,1.07-1.19v-2.78c0-1.18-.39-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48v-.47c1-.1,1.12-.16,1.12-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.18,2.18,0,0,1,1.28-.44,1.87,1.87,0,0,1,1.75,1.3c.44-.34.84-.6,1.24-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.1,1.09,1,1.19v.47Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M125.08,633.16c-.22.74-.5,1.61-.66,2.22h-6.11V635c.88-.86,1.83-1.81,2.62-2.72a6.3,6.3,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.83-2.05a2.79,2.79,0,0,0-2.21,1.48l-.38-.35.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.84-.67.69-1.66,1.64-2.33,2.27h3c.89,0,1.12-.08,1.67-1.09Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M126.6,623.63a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.85,9.85,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M80.17,574.6a9.31,9.31,0,0,1,0-14.84l.34.42a9.88,9.88,0,0,0-2.62,7,10.12,10.12,0,0,0,2.62,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M82,563.29l.76-1a2.86,2.86,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.71,1.71,0,0,1-.51,1.18,6.84,6.84,0,0,1-1.15.86,2.64,2.64,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.6,2.6,0,0,1-1.68-.56.72.72,0,0,1-.27-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.35a2,2,0,0,0-2.09-2,3.92,3.92,0,0,0-1,.16l-.11-.5c1.43-.47,2.53-1.09,2.53-2.25a1.39,1.39,0,0,0-1.5-1.49,2.51,2.51,0,0,0-2,1.26Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M92.93,561.34c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.4-2.68-3.4-5.17S90.45,561.34,92.93,561.34Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.6S94.28,561.92,92.92,561.92Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M100.76,561.34c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17S98.28,561.34,100.76,561.34Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.6S102.11,561.92,100.75,561.92Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M114,571.51V571c.89-.1,1-.16,1-1.25v-2.7c0-1.17-.43-1.8-1.33-1.8a2.7,2.7,0,0,0-1.67.77c0,.19,0,.4,0,.69v3.13c0,1,.13,1.06,1,1.16v.47h-3.45V571c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.39-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.09,1,1.19v.47h-3.48V571c1-.1,1.12-.16,1.12-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a9.52,9.52,0,0,0,2.33-.64v1.28a13.82,13.82,0,0,1,1.15-.8,2.24,2.24,0,0,1,1.28-.43,1.85,1.85,0,0,1,1.75,1.3c.44-.34.84-.61,1.24-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.1,1.09,1,1.19v.47Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M125.08,569.28c-.22.75-.5,1.62-.66,2.23h-6.11v-.4c.88-.86,1.83-1.8,2.62-2.71,1-1.22,1.93-2.46,1.93-3.83s-.68-2.06-1.83-2.06a2.79,2.79,0,0,0-2.21,1.48l-.38-.35.93-1.33a2.92,2.92,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.83-.67.7-1.66,1.65-2.33,2.28h3c.89,0,1.12-.08,1.67-1.09Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M126.6,559.76a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.87,9.87,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M80.17,511.6a9.31,9.31,0,0,1,0-14.84l.34.42a9.88,9.88,0,0,0-2.62,7,10.12,10.12,0,0,0,2.62,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M82,500.29l.76-1a2.86,2.86,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.71,1.71,0,0,1-.51,1.18,6.84,6.84,0,0,1-1.15.86,2.64,2.64,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.6,2.6,0,0,1-1.68-.56.72.72,0,0,1-.27-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.35a2,2,0,0,0-2.09-2.05,3.92,3.92,0,0,0-1,.16l-.11-.5c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.5-1.48,2.51,2.51,0,0,0-2,1.26Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M92.93,498.34c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.4-2.68-3.4-5.17C89.53,501.19,90.45,498.34,92.93,498.34Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.6S94.28,498.92,92.92,498.92Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M100.76,498.34c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17C97.36,501.19,98.28,498.34,100.76,498.34Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.6S102.11,498.92,100.75,498.92Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M114,508.51V508c.89-.1,1-.16,1-1.25V504.1c0-1.18-.43-1.81-1.33-1.81a2.7,2.7,0,0,0-1.67.77c0,.19,0,.39,0,.69v3.13c0,1,.13,1.06,1,1.16v.47h-3.45V508c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.39-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.09,1,1.19v.47h-3.48V508c1-.1,1.12-.16,1.12-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a9.52,9.52,0,0,0,2.33-.64v1.28a13.82,13.82,0,0,1,1.15-.8,2.24,2.24,0,0,1,1.28-.43,1.85,1.85,0,0,1,1.75,1.3c.44-.34.84-.61,1.24-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.1,1.09,1,1.19v.47Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M125.08,506.28c-.22.75-.5,1.62-.66,2.23h-6.11v-.4c.88-.86,1.83-1.8,2.62-2.71,1-1.22,1.93-2.46,1.93-3.83s-.68-2.05-1.83-2.05a2.78,2.78,0,0,0-2.21,1.47l-.38-.35.93-1.33a2.92,2.92,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.83-.67.7-1.66,1.65-2.33,2.28h3c.89,0,1.12-.08,1.67-1.09Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M126.6,496.76a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.87,9.87,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M80.17,448.16a9.31,9.31,0,0,1,0-14.84l.34.42a9.9,9.9,0,0,0-2.62,7,10.15,10.15,0,0,0,2.62,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M82,436.85l.76-1a2.86,2.86,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.69,1.69,0,0,1-.51,1.18,6.34,6.34,0,0,1-1.15.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.66,2.66,0,0,1-1.68-.55.75.75,0,0,1-.27-.56,1,1,0,0,1,.38-.64.35.35,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36A2,2,0,0,0,84.51,440a3.92,3.92,0,0,0-1,.16l-.11-.49c1.43-.48,2.53-1.1,2.53-2.26a1.39,1.39,0,0,0-1.5-1.49,2.51,2.51,0,0,0-2,1.26Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M92.93,434.9c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.4-2.68-3.4-5.17C89.53,437.75,90.45,434.9,92.93,434.9Zm0,.59c-1.41,0-1.76,2.34-1.76,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S94.28,435.49,92.92,435.49Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M100.76,434.9c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17C97.36,437.75,98.28,434.9,100.76,434.9Zm0,.59c-1.41,0-1.76,2.34-1.76,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S102.11,435.49,100.75,435.49Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M114,445.07v-.47c.89-.09,1-.16,1-1.25v-2.69c0-1.17-.43-1.81-1.33-1.81a2.75,2.75,0,0,0-1.67.77c0,.2,0,.4,0,.69v3.13c0,1,.13,1.07,1,1.16v.47h-3.45v-.47c.94-.09,1.07-.16,1.07-1.19v-2.78c0-1.18-.39-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48v-.47c1-.1,1.12-.16,1.12-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.18,2.18,0,0,1,1.28-.44,1.85,1.85,0,0,1,1.75,1.3c.44-.34.84-.6,1.24-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.1,1.09,1,1.19v.47Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M125.08,442.85c-.22.74-.5,1.61-.66,2.22h-6.11v-.39c.88-.86,1.83-1.81,2.62-2.72a6.3,6.3,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.83-2.05a2.81,2.81,0,0,0-2.21,1.47l-.38-.34.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.84-.67.69-1.66,1.64-2.33,2.27h3c.89,0,1.12-.08,1.67-1.09Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M126.6,433.32a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.85,9.85,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M80.17,385.16a9.3,9.3,0,0,1,0-14.83l.34.41a9.91,9.91,0,0,0-2.62,7,10.14,10.14,0,0,0,2.62,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M82,373.85l.76-1a2.83,2.83,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.14,1.69,1.69,0,0,1-.51,1.18,6.82,6.82,0,0,1-1.15.87,2.63,2.63,0,0,1,2.4,2.45c0,2.6-3.15,3.71-4.27,3.71a2.66,2.66,0,0,1-1.68-.55.73.73,0,0,1-.27-.55.91.91,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36A2,2,0,0,0,84.51,377a3.92,3.92,0,0,0-1,.16l-.11-.49c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.5-1.49,2.54,2.54,0,0,0-2,1.26Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M92.93,371.91c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.4-2.68-3.4-5.17C89.53,374.75,90.45,371.91,92.93,371.91Zm0,.58c-1.41,0-1.76,2.34-1.76,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S94.28,372.49,92.92,372.49Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M100.76,371.91c2.46,0,3.35,2.72,3.35,5.17s-.89,5.18-3.35,5.18-3.4-2.68-3.4-5.17C97.36,374.75,98.28,371.91,100.76,371.91Zm0,.58c-1.41,0-1.76,2.34-1.76,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S102.11,372.49,100.75,372.49Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M114,382.07v-.47c.89-.09,1-.16,1-1.25v-2.69c0-1.17-.43-1.81-1.33-1.81a2.72,2.72,0,0,0-1.67.78c0,.19,0,.39,0,.68v3.13c0,1,.13,1.07,1,1.16v.47h-3.45v-.47c.94-.09,1.07-.15,1.07-1.19v-2.78c0-1.17-.39-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48v-.47c1-.09,1.12-.16,1.12-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.17,2.17,0,0,1,1.28-.43,1.86,1.86,0,0,1,1.75,1.29c.44-.34.84-.6,1.24-.87a2.38,2.38,0,0,1,1.27-.42c1.27,0,2,.9,2,2.48v3.09c0,1,.1,1.09,1,1.19v.47Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M125.08,379.85c-.22.75-.5,1.61-.66,2.22h-6.11v-.39c.88-.86,1.83-1.81,2.62-2.72a6.3,6.3,0,0,0,1.93-3.82c0-1.3-.68-2.06-1.83-2.06a2.79,2.79,0,0,0-2.21,1.48l-.38-.35.93-1.34a3,3,0,0,1,2.24-1,2.64,2.64,0,0,1,2.76,2.78c0,1.34-.49,2.29-2,3.84-.67.69-1.66,1.64-2.33,2.27h3c.89,0,1.12-.08,1.67-1.08Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M126.6,370.33a9.28,9.28,0,0,1,0,14.83l-.35-.41a9.89,9.89,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M80.17,321.73a9.31,9.31,0,0,1,0-14.84l.34.42a9.86,9.86,0,0,0-2.62,7,10.14,10.14,0,0,0,2.62,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M82,310.41l.76-1a2.86,2.86,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.14,1.67,1.67,0,0,1-.51,1.18,6.82,6.82,0,0,1-1.15.87,2.63,2.63,0,0,1,2.4,2.46c0,2.59-3.15,3.7-4.27,3.7a2.61,2.61,0,0,1-1.68-.55.73.73,0,0,1-.27-.55.93.93,0,0,1,.38-.64.33.33,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.36a2,2,0,0,0-2.09-2.05,4.37,4.37,0,0,0-1,.16l-.11-.49c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.5-1.48,2.53,2.53,0,0,0-2,1.25Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M92.93,308.47c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.4-2.68-3.4-5.17C89.53,311.31,90.45,308.47,92.93,308.47Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.61S94.28,309.05,92.92,309.05Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M100.76,308.47c2.46,0,3.35,2.72,3.35,5.17s-.89,5.18-3.35,5.18-3.4-2.68-3.4-5.17C97.36,311.31,98.28,308.47,100.76,308.47Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.61S102.11,309.05,100.75,309.05Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M114,318.63v-.46c.89-.1,1-.16,1-1.26v-2.69c0-1.17-.43-1.8-1.33-1.8a2.7,2.7,0,0,0-1.67.77c0,.19,0,.39,0,.68V317c0,1,.13,1.07,1,1.17v.46h-3.45v-.46c.94-.1,1.07-.16,1.07-1.19v-2.79c0-1.17-.39-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77V317c0,1,.11,1.09,1,1.19v.46h-3.48v-.46c1-.1,1.12-.17,1.12-1.21v-3.32c0-1-.06-1.08-.93-1.23V312a9.52,9.52,0,0,0,2.33-.64v1.28a13.82,13.82,0,0,1,1.15-.8,2.17,2.17,0,0,1,1.28-.43,1.86,1.86,0,0,1,1.75,1.29c.44-.34.84-.6,1.24-.87a2.38,2.38,0,0,1,1.27-.42c1.27,0,2,.9,2,2.48V317c0,1,.1,1.08,1,1.19v.46Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M125.08,316.41c-.22.75-.5,1.62-.66,2.22h-6.11v-.39c.88-.86,1.83-1.8,2.62-2.72a6.3,6.3,0,0,0,1.93-3.82c0-1.3-.68-2.06-1.83-2.06a2.79,2.79,0,0,0-2.21,1.48l-.38-.35.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.78c0,1.35-.49,2.3-2,3.84-.67.69-1.66,1.65-2.33,2.27h3c.89,0,1.12-.07,1.67-1.08Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M126.6,306.89a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.89,9.89,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M877.58,98.56a9.3,9.3,0,0,1,0-14.83l.35.41a9.86,9.86,0,0,0-2.63,7,10.11,10.11,0,0,0,2.63,7Z"
            style={{ fill: "#136736" }}
          />
          <path
            d="M879.45,87.25l.76-1a2.89,2.89,0,0,1,2.12-.93,2.22,2.22,0,0,1,2.45,2.15,1.72,1.72,0,0,1-.5,1.18,6.84,6.84,0,0,1-1.16.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.66,2.66,0,0,1-1.68-.55.74.74,0,0,1-.27-.56,1,1,0,0,1,.38-.64.35.35,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.88-.82,1.88-2.36a2,2,0,0,0-2.1-2,3.84,3.84,0,0,0-1,.16l-.12-.49c1.44-.48,2.53-1.1,2.53-2.26a1.39,1.39,0,0,0-1.5-1.49,2.51,2.51,0,0,0-2,1.26Z"
            style={{ fill: "#136736" }}
          />
          <path
            d="M893.85,85.71c-1.54,3.24-3,6.48-4.58,9.78l-1.22.13-.12-.18a94.46,94.46,0,0,0,4.7-8.66h-3.41c-1.06,0-1.13.14-1.56,1.18h-.52c.17-1,.24-1.77.33-2.46h6.28Z"
            style={{ fill: "#136736" }}
          />
          <path
            d="M900.86,85.5l.1.13-.43,1.15h-3.74c-.15.85-.35,2.06-.5,2.82a7.83,7.83,0,0,1,1.93-.47,2.8,2.8,0,0,1,3,2.72,3.37,3.37,0,0,1-1.42,2.7A5,5,0,0,1,897,95.66a2.46,2.46,0,0,1-1.67-.61c-.25-.21-.31-.35-.28-.56a.91.91,0,0,1,.36-.6.37.37,0,0,1,.48,0,2.87,2.87,0,0,0,2,.9c1.28,0,1.89-1.1,1.89-2.29a2.3,2.3,0,0,0-2.58-2.42,4.83,4.83,0,0,0-1.61.29l-.06-.07c.26-1.29.61-3.51.8-4.82Z"
            style={{ fill: "#136736" }}
          />
          <path
            d="M911.38,95.47V95c.89-.09,1-.16,1-1.25V91.06c0-1.17-.43-1.81-1.33-1.81a2.78,2.78,0,0,0-1.67.77c0,.2,0,.4,0,.69v3.13c0,1,.13,1.07,1,1.16v.47H907V95c.94-.09,1.07-.15,1.07-1.19V91c0-1.18-.39-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.49V95c1-.1,1.12-.16,1.12-1.2V90.47c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.2,2.2,0,0,1,1.28-.44,1.87,1.87,0,0,1,1.75,1.3c.44-.34.84-.6,1.24-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.1,1.09,1,1.19v.47Z"
            style={{ fill: "#136736" }}
          />
          <path
            d="M922.49,93.25c-.22.74-.49,1.61-.66,2.22h-6.11v-.39c.88-.86,1.83-1.81,2.62-2.72a6.3,6.3,0,0,0,1.93-3.83c0-1.29-.68-2-1.83-2A2.81,2.81,0,0,0,916.23,88l-.38-.34.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.34,2.27h3c.89,0,1.12-.08,1.68-1.09Z"
            style={{ fill: "#136736" }}
          />
          <path
            d="M924,83.73a9.3,9.3,0,0,1,0,14.83l-.36-.41a9.89,9.89,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
            style={{ fill: "#136736" }}
          />
          <path
            d="M806.86,96.53a9.29,9.29,0,0,1,0-14.84l.34.42a9.86,9.86,0,0,0-2.62,7,10.09,10.09,0,0,0,2.62,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M808.73,85.22l.76-1a2.84,2.84,0,0,1,2.11-.93,2.21,2.21,0,0,1,2.45,2.15,1.7,1.7,0,0,1-.5,1.17,7,7,0,0,1-1.16.87,2.64,2.64,0,0,1,2.41,2.46c0,2.6-3.16,3.71-4.27,3.71a2.61,2.61,0,0,1-1.69-.56.73.73,0,0,1-.27-.55,1,1,0,0,1,.38-.64.33.33,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.35a2,2,0,0,0-2.09-2.06,4.29,4.29,0,0,0-1,.16l-.11-.49c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.48,2.56,2.56,0,0,0-2,1.25Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M819.62,83.27c2.45,0,3.35,2.72,3.35,5.17s-.9,5.19-3.35,5.19-3.4-2.69-3.4-5.17S817.14,83.27,819.62,83.27Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.61S821,83.85,819.61,83.85Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M827.44,83.27c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.69-3.39-5.17S825,83.27,827.44,83.27Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57S826.1,93,827.45,93s1.73-2.37,1.73-4.61S828.8,83.85,827.44,83.85Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M840.65,93.43V93c.89-.1,1-.16,1-1.26V89c0-1.17-.43-1.8-1.33-1.8a2.72,2.72,0,0,0-1.67.77,5.65,5.65,0,0,1,.05.69V91.8c0,1,.12,1.07,1,1.17v.46h-3.46V93c.94-.1,1.07-.16,1.07-1.19V89c0-1.18-.38-1.78-1.29-1.78a2.94,2.94,0,0,0-1.67.76v3.8c0,1,.11,1.09,1,1.19v.46h-3.48V93c1-.1,1.11-.17,1.11-1.2V88.44c0-1-.06-1.08-.93-1.23v-.42a9.52,9.52,0,0,0,2.33-.64v1.28a13.82,13.82,0,0,1,1.15-.8,2.29,2.29,0,0,1,1.28-.43,1.88,1.88,0,0,1,1.76,1.29c.43-.34.83-.6,1.23-.87A2.46,2.46,0,0,1,841,86.2c1.27,0,2,.9,2,2.49v3.09c0,1,.09,1.09,1,1.19v.46Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M851.76,91.21c-.21.75-.49,1.62-.65,2.22H845V93c.87-.86,1.83-1.8,2.62-2.72a6.26,6.26,0,0,0,1.92-3.82c0-1.29-.67-2.06-1.83-2.06a2.76,2.76,0,0,0-2.2,1.48l-.39-.35.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.83-.68.7-1.67,1.65-2.33,2.27h3c.88,0,1.11-.07,1.67-1.08Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M853.29,81.69a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.89,9.89,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M743.75,97.07a9.28,9.28,0,0,1,0-14.83l.34.41a9.91,9.91,0,0,0-2.62,7,10.09,10.09,0,0,0,2.62,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M745.62,85.76l.76-1a2.81,2.81,0,0,1,2.11-.93A2.21,2.21,0,0,1,750.94,86a1.72,1.72,0,0,1-.5,1.18,7.26,7.26,0,0,1-1.15.87,2.64,2.64,0,0,1,2.4,2.45c0,2.6-3.16,3.71-4.27,3.71a2.7,2.7,0,0,1-1.69-.55.73.73,0,0,1-.27-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2,3.85,3.85,0,0,0-1,.16l-.11-.49c1.43-.47,2.53-1.09,2.53-2.26A1.39,1.39,0,0,0,748,84.84a2.57,2.57,0,0,0-2,1.26Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M756.51,83.82c2.45,0,3.35,2.72,3.35,5.17s-.9,5.18-3.35,5.18-3.4-2.68-3.4-5.17S754,83.82,756.51,83.82Zm0,.58c-1.42,0-1.76,2.34-1.76,4.56s.42,4.63,1.77,4.63,1.73-2.37,1.73-4.61S757.86,84.4,756.5,84.4Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M764.33,83.82c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.39-2.68-3.39-5.17S761.86,83.82,764.33,83.82Zm0,.58c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S765.69,84.4,764.33,84.4Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M777.54,94v-.47c.89-.09,1-.16,1-1.25V89.57c0-1.17-.43-1.8-1.33-1.8a2.74,2.74,0,0,0-1.67.77,5.62,5.62,0,0,1,.05.68v3.13c0,1,.12,1.07,1,1.16V94h-3.46v-.47c.94-.09,1.07-.15,1.07-1.19V89.54c0-1.17-.38-1.78-1.29-1.78a2.84,2.84,0,0,0-1.66.77v3.79c0,1,.1,1.1,1,1.19V94h-3.48v-.47c1-.09,1.11-.16,1.11-1.2V89c0-1-.06-1.08-.93-1.23v-.42a10.17,10.17,0,0,0,2.34-.63V88a10.45,10.45,0,0,1,1.14-.8,2.21,2.21,0,0,1,1.28-.43A1.87,1.87,0,0,1,775.41,88c.43-.34.83-.6,1.23-.87a2.4,2.4,0,0,1,1.27-.42c1.28,0,2,.9,2,2.48v3.09c0,1,.09,1.09,1,1.19V94Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M788.65,91.76c-.21.75-.49,1.61-.65,2.22h-6.11v-.39c.87-.86,1.83-1.81,2.62-2.72a6.28,6.28,0,0,0,1.92-3.82c0-1.3-.67-2.06-1.83-2.06a2.76,2.76,0,0,0-2.2,1.48l-.39-.35.93-1.34a3,3,0,0,1,2.24-1,2.64,2.64,0,0,1,2.76,2.78c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.33,2.27h3c.88,0,1.12-.08,1.67-1.08Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M790.18,82.24a9.3,9.3,0,0,1,0,14.83l-.36-.41a9.85,9.85,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M680.53,96.85a9.3,9.3,0,0,1,0-14.83l.34.42a9.86,9.86,0,0,0-2.62,7,10.14,10.14,0,0,0,2.62,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M682.4,85.54l.76-1a2.83,2.83,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.14,1.69,1.69,0,0,1-.51,1.18,6.82,6.82,0,0,1-1.15.87,2.63,2.63,0,0,1,2.4,2.46c0,2.59-3.15,3.7-4.27,3.7a2.66,2.66,0,0,1-1.69-.55.72.72,0,0,1-.26-.55.93.93,0,0,1,.38-.64.33.33,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2,4.29,4.29,0,0,0-1,.16l-.11-.49c1.43-.47,2.53-1.09,2.53-2.26a1.38,1.38,0,0,0-1.51-1.48,2.56,2.56,0,0,0-2,1.25Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M693.29,83.6c2.46,0,3.35,2.72,3.35,5.17S695.75,94,693.29,94s-3.4-2.68-3.4-5.17S690.81,83.6,693.29,83.6Zm0,.58c-1.41,0-1.76,2.34-1.76,4.57s.43,4.62,1.77,4.62S695,91,695,88.76,694.64,84.18,693.28,84.18Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M701.12,83.6c2.46,0,3.35,2.72,3.35,5.17S703.58,94,701.12,94s-3.4-2.68-3.4-5.17S698.64,83.6,701.12,83.6Zm0,.58c-1.42,0-1.76,2.34-1.76,4.57s.42,4.62,1.77,4.62,1.74-2.37,1.74-4.61S702.47,84.18,701.11,84.18Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M714.32,93.76V93.3c.89-.1,1-.16,1-1.26V89.35c0-1.17-.43-1.8-1.33-1.8a2.7,2.7,0,0,0-1.67.77c0,.19,0,.39,0,.68v3.13c0,1,.13,1.07,1,1.17v.46h-3.46V93.3c.94-.1,1.07-.16,1.07-1.2V89.32c0-1.17-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.66.77V92.1c0,1,.11,1.1,1,1.2v.46h-3.48V93.3c1-.1,1.11-.17,1.11-1.21V88.77c0-1-.05-1.08-.92-1.24v-.42a9.49,9.49,0,0,0,2.33-.63v1.28a12.32,12.32,0,0,1,1.15-.8,2.17,2.17,0,0,1,1.28-.43,1.87,1.87,0,0,1,1.75,1.29c.44-.34.84-.6,1.24-.87a2.37,2.37,0,0,1,1.26-.42c1.28,0,2,.9,2,2.48V92.1c0,1,.09,1.09,1,1.2v.46Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M725.44,91.54c-.22.75-.5,1.61-.66,2.22h-6.11v-.39c.87-.86,1.83-1.8,2.62-2.72a6.3,6.3,0,0,0,1.93-3.82c0-1.3-.68-2.06-1.84-2.06a2.79,2.79,0,0,0-2.2,1.48l-.39-.35.94-1.34a2.94,2.94,0,0,1,2.23-1,2.65,2.65,0,0,1,2.77,2.78c0,1.34-.5,2.3-2,3.84-.67.69-1.66,1.64-2.33,2.27h3c.89,0,1.12-.08,1.67-1.08Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M727,82a9.28,9.28,0,0,1,0,14.83l-.35-.41a9.89,9.89,0,0,0,2.63-7,10.05,10.05,0,0,0-2.63-7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M617.2,97.18a9.3,9.3,0,0,1,0-14.83l.35.41a9.87,9.87,0,0,0-2.63,7,10.1,10.1,0,0,0,2.63,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M619.08,85.87l.76-1a2.84,2.84,0,0,1,2.11-.92,2.21,2.21,0,0,1,2.45,2.14,1.72,1.72,0,0,1-.5,1.18,6.84,6.84,0,0,1-1.16.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.66,2.66,0,0,1-1.68-.55.73.73,0,0,1-.27-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.89c1.1,0,1.88-.82,1.88-2.36a2,2,0,0,0-2.1-2,3.84,3.84,0,0,0-1,.16l-.12-.49c1.44-.47,2.53-1.09,2.53-2.26a1.38,1.38,0,0,0-1.5-1.49,2.55,2.55,0,0,0-2,1.26Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M630,83.93c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.39-2.68-3.39-5.17S627.49,83.93,630,83.93Zm0,.58c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S631.32,84.51,630,84.51Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M637.79,83.93c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.4-2.68-3.4-5.17S635.31,83.93,637.79,83.93Zm0,.58c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S639.14,84.51,637.79,84.51Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M651,94.09v-.47c.89-.09,1-.16,1-1.25V89.68c0-1.17-.43-1.81-1.33-1.81a2.74,2.74,0,0,0-1.67.78c0,.19,0,.39,0,.68v3.13c0,1,.13,1.07,1,1.16v.47h-3.46v-.47c.94-.09,1.07-.15,1.07-1.19V89.65c0-1.17-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48v-.47c1-.1,1.11-.16,1.11-1.2V89.09c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.19,2.19,0,0,1,1.28-.43,1.88,1.88,0,0,1,1.76,1.29c.43-.34.83-.6,1.23-.88a2.46,2.46,0,0,1,1.27-.41c1.27,0,2,.9,2,2.48v3.09c0,1,.09,1.09,1,1.19v.47Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M662.11,91.87c-.22.74-.49,1.61-.65,2.22h-6.12V93.7c.88-.86,1.83-1.81,2.62-2.72a6.24,6.24,0,0,0,1.93-3.82c0-1.3-.68-2.06-1.83-2.06a2.76,2.76,0,0,0-2.2,1.48l-.39-.35.93-1.34a3,3,0,0,1,2.24-1,2.64,2.64,0,0,1,2.76,2.78c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.34,2.27h3c.89,0,1.12-.08,1.68-1.09Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M663.64,82.35a9.3,9.3,0,0,1,0,14.83l-.36-.41a9.89,9.89,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M553.77,96.75a9.29,9.29,0,0,1,0-14.84l.34.42a9.86,9.86,0,0,0-2.62,7,10.09,10.09,0,0,0,2.62,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M555.64,85.43l.76-1a2.81,2.81,0,0,1,2.11-.93A2.21,2.21,0,0,1,561,85.63a1.72,1.72,0,0,1-.5,1.18,7,7,0,0,1-1.16.87,2.64,2.64,0,0,1,2.41,2.46c0,2.59-3.16,3.7-4.27,3.7a2.62,2.62,0,0,1-1.69-.55.73.73,0,0,1-.27-.55,1,1,0,0,1,.38-.64.33.33,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.36a2,2,0,0,0-2.09-2,4.29,4.29,0,0,0-1,.16l-.11-.49c1.43-.47,2.53-1.09,2.53-2.26A1.39,1.39,0,0,0,558,84.52a2.56,2.56,0,0,0-2,1.25Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M566.53,83.49c2.45,0,3.35,2.72,3.35,5.17s-.9,5.18-3.35,5.18-3.4-2.68-3.4-5.17S564.05,83.49,566.53,83.49Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.61S567.88,84.07,566.52,84.07Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M574.35,83.49c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18S571,91.16,571,88.67,571.88,83.49,574.35,83.49Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.61S575.71,84.07,574.35,84.07Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M587.56,93.65v-.46c.89-.1,1-.16,1-1.26V89.24c0-1.17-.43-1.8-1.33-1.8a2.72,2.72,0,0,0-1.67.77,5.62,5.62,0,0,1,.05.68V92c0,1,.12,1.07,1,1.17v.46h-3.46v-.46c.94-.1,1.07-.16,1.07-1.19V89.21c0-1.17-.38-1.78-1.29-1.78a2.84,2.84,0,0,0-1.66.77V92c0,1,.1,1.09,1,1.19v.46h-3.48v-.46c1-.1,1.11-.17,1.11-1.21V88.66c0-1-.06-1.08-.93-1.23V87a9.72,9.72,0,0,0,2.34-.64v1.28a12,12,0,0,1,1.14-.8,2.21,2.21,0,0,1,1.28-.43,1.88,1.88,0,0,1,1.76,1.29c.43-.34.83-.6,1.23-.87a2.38,2.38,0,0,1,1.27-.42c1.28,0,2,.9,2,2.48V92c0,1,.09,1.08,1,1.19v.46Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M598.67,91.43c-.21.75-.49,1.62-.65,2.22h-6.11v-.39c.87-.86,1.83-1.8,2.62-2.72a6.28,6.28,0,0,0,1.92-3.82c0-1.3-.67-2.06-1.83-2.06a2.76,2.76,0,0,0-2.2,1.48l-.39-.35.93-1.34a3,3,0,0,1,2.24-1A2.65,2.65,0,0,1,598,86.27c0,1.34-.49,2.3-2,3.84-.68.69-1.67,1.65-2.33,2.27h3c.88,0,1.12-.07,1.67-1.08Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M600.2,81.91a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.89,9.89,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M491.42,96.54a9.31,9.31,0,0,1,0-14.84l.35.42a9.82,9.82,0,0,0-2.63,7,10.1,10.1,0,0,0,2.63,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M493.3,85.23l.76-1a2.84,2.84,0,0,1,2.11-.93,2.21,2.21,0,0,1,2.45,2.15,1.7,1.7,0,0,1-.5,1.17,7,7,0,0,1-1.16.87,2.63,2.63,0,0,1,2.4,2.46c0,2.59-3.15,3.71-4.27,3.71a2.6,2.6,0,0,1-1.68-.56.73.73,0,0,1-.27-.55,1,1,0,0,1,.38-.64.33.33,0,0,1,.46,0,3,3,0,0,0,2,.88c1.1,0,1.88-.81,1.88-2.35a2,2,0,0,0-2.1-2.06,4.28,4.28,0,0,0-1,.16l-.11-.49c1.43-.47,2.52-1.09,2.52-2.26a1.38,1.38,0,0,0-1.5-1.48,2.54,2.54,0,0,0-2,1.25Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M504.18,83.28c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.69-3.39-5.17S501.71,83.28,504.18,83.28Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.61S505.54,83.86,504.18,83.86Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M512,83.28c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.4-2.69-3.4-5.17S509.54,83.28,512,83.28Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.61S513.36,83.86,512,83.86Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M525.22,93.44V93c.89-.1,1-.16,1-1.26V89c0-1.17-.43-1.8-1.33-1.8a2.72,2.72,0,0,0-1.67.77,6,6,0,0,1,0,.69v3.12c0,1,.13,1.07,1,1.17v.46h-3.46V93c.94-.1,1.07-.16,1.07-1.19V89c0-1.18-.38-1.78-1.29-1.78a2.88,2.88,0,0,0-1.67.76v3.8c0,1,.11,1.09,1,1.19v.46h-3.48V93c1-.1,1.11-.17,1.11-1.21V88.45c0-1-.06-1.08-.93-1.23V86.8a9.52,9.52,0,0,0,2.33-.64v1.28a13.82,13.82,0,0,1,1.15-.8,2.29,2.29,0,0,1,1.28-.43,1.88,1.88,0,0,1,1.76,1.29c.43-.34.83-.6,1.23-.87a2.38,2.38,0,0,1,1.27-.42c1.27,0,2,.9,2,2.48v3.1c0,1,.09,1.09,1,1.19v.46Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M536.33,91.22c-.22.75-.49,1.62-.65,2.22h-6.12v-.39c.88-.86,1.83-1.8,2.62-2.72a6.22,6.22,0,0,0,1.93-3.82c0-1.29-.68-2.06-1.83-2.06a2.76,2.76,0,0,0-2.2,1.48l-.39-.35.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.78c0,1.35-.49,2.3-2,3.84-.68.7-1.67,1.65-2.34,2.27h3c.88,0,1.11-.07,1.67-1.08Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M537.86,81.7a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.89,9.89,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M427,97.07a9.31,9.31,0,0,1,0-14.84l.34.42a9.9,9.9,0,0,0-2.62,7,10.15,10.15,0,0,0,2.62,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M428.87,85.76l.76-1a2.86,2.86,0,0,1,2.11-.93A2.22,2.22,0,0,1,434.2,86a1.69,1.69,0,0,1-.51,1.18,6.34,6.34,0,0,1-1.15.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.66,2.66,0,0,1-1.68-.55.75.75,0,0,1-.27-.56,1,1,0,0,1,.38-.64.35.35,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2,3.85,3.85,0,0,0-1,.16l-.11-.49c1.43-.48,2.53-1.1,2.53-2.26a1.39,1.39,0,0,0-1.51-1.49,2.54,2.54,0,0,0-2,1.26Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M439.76,83.81c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17S437.28,83.81,439.76,83.81Zm0,.59c-1.41,0-1.76,2.34-1.76,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S441.11,84.4,439.75,84.4Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M447.59,83.81c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17S445.11,83.81,447.59,83.81Zm0,.59c-1.41,0-1.76,2.34-1.76,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S448.94,84.4,447.58,84.4Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M460.79,94v-.47c.89-.09,1-.16,1-1.25V89.57c0-1.17-.43-1.81-1.33-1.81a2.75,2.75,0,0,0-1.67.77c0,.2,0,.4,0,.69v3.13c0,1,.13,1.07,1,1.16V94h-3.46v-.47c.95-.09,1.07-.16,1.07-1.19V89.54c0-1.18-.38-1.78-1.28-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19V94H452v-.47c1-.1,1.11-.16,1.11-1.2V89c0-1-.05-1.08-.92-1.23v-.42a10,10,0,0,0,2.33-.63V88a10.26,10.26,0,0,1,1.15-.8,2.18,2.18,0,0,1,1.28-.44,1.88,1.88,0,0,1,1.75,1.3c.44-.34.84-.6,1.24-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19V94Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M471.91,91.76c-.22.74-.5,1.61-.66,2.22h-6.11v-.39c.87-.86,1.83-1.81,2.62-2.72A6.3,6.3,0,0,0,469.69,87c0-1.29-.68-2.05-1.83-2.05a2.81,2.81,0,0,0-2.21,1.47l-.39-.34.94-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.5,2.29-2,3.84-.67.69-1.66,1.64-2.33,2.27h3c.89,0,1.12-.08,1.67-1.09Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M473.43,82.23a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.84,9.84,0,0,0,2.63-7,10.05,10.05,0,0,0-2.63-7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M364,97.07a9.31,9.31,0,0,1,0-14.84l.35.42a9.86,9.86,0,0,0-2.63,7,10.11,10.11,0,0,0,2.63,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M365.87,85.76l.76-1a2.89,2.89,0,0,1,2.12-.93A2.22,2.22,0,0,1,371.2,86a1.72,1.72,0,0,1-.5,1.18,6.84,6.84,0,0,1-1.16.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.66,2.66,0,0,1-1.68-.55.75.75,0,0,1-.27-.56,1,1,0,0,1,.38-.64.35.35,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.88-.82,1.88-2.36a2,2,0,0,0-2.1-2,3.84,3.84,0,0,0-1,.16l-.12-.49c1.44-.48,2.53-1.1,2.53-2.26a1.39,1.39,0,0,0-1.5-1.49,2.51,2.51,0,0,0-2,1.26Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M376.76,83.81c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.4-2.68-3.4-5.17S374.29,83.81,376.76,83.81Zm0,.59c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S378.11,84.4,376.76,84.4Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M384.59,83.81c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17S382.11,83.81,384.59,83.81Zm0,.59c-1.41,0-1.76,2.34-1.76,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S385.94,84.4,384.58,84.4Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M397.8,94v-.47c.89-.09,1-.16,1-1.25V89.57c0-1.17-.43-1.81-1.33-1.81a2.78,2.78,0,0,0-1.67.77c0,.2,0,.4,0,.69v3.13c0,1,.13,1.07,1,1.16V94h-3.45v-.47c.94-.09,1.07-.16,1.07-1.19V89.54c0-1.18-.39-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19V94H389v-.47c1-.1,1.12-.16,1.12-1.2V89c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63V88a10.26,10.26,0,0,1,1.15-.8,2.2,2.2,0,0,1,1.28-.44,1.87,1.87,0,0,1,1.75,1.3c.44-.34.84-.6,1.24-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.1,1.09,1,1.19V94Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M408.91,91.76c-.22.74-.49,1.61-.66,2.22h-6.11v-.39c.88-.86,1.83-1.81,2.62-2.72A6.3,6.3,0,0,0,406.69,87c0-1.29-.68-2.05-1.83-2.05a2.81,2.81,0,0,0-2.21,1.47l-.38-.34.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.34,2.27h3c.89,0,1.12-.08,1.68-1.09Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M410.44,82.23a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.85,9.85,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M300.67,98.13a9.31,9.31,0,0,1,0-14.84l.35.42a9.82,9.82,0,0,0-2.63,7,10.1,10.1,0,0,0,2.63,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M302.55,86.81l.76-1a2.84,2.84,0,0,1,2.11-.93A2.21,2.21,0,0,1,307.87,87a1.7,1.7,0,0,1-.5,1.17,7,7,0,0,1-1.16.87,2.63,2.63,0,0,1,2.4,2.46c0,2.59-3.15,3.71-4.27,3.71a2.6,2.6,0,0,1-1.68-.56.73.73,0,0,1-.27-.55,1,1,0,0,1,.38-.64.33.33,0,0,1,.46,0,3,3,0,0,0,2,.88c1.1,0,1.88-.81,1.88-2.36a2,2,0,0,0-2.1-2,4.28,4.28,0,0,0-1,.16l-.11-.49c1.43-.47,2.52-1.09,2.52-2.26a1.38,1.38,0,0,0-1.5-1.48,2.54,2.54,0,0,0-2,1.25Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M313.43,84.87c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19S310,92.54,310,90.06,311,84.87,313.43,84.87Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.61S314.79,85.45,313.43,85.45Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M321.26,84.87c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.4-2.69-3.4-5.17S318.79,84.87,321.26,84.87Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.77,4.62S323,92.27,323,90,322.61,85.45,321.26,85.45Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M334.47,95v-.46c.89-.1,1-.16,1-1.26V90.62c0-1.17-.43-1.8-1.33-1.8a2.72,2.72,0,0,0-1.67.77,5.92,5.92,0,0,1,0,.68V93.4c0,1,.13,1.07,1,1.17V95H330v-.46c.94-.1,1.07-.16,1.07-1.19V90.6c0-1.18-.38-1.79-1.29-1.79a2.89,2.89,0,0,0-1.67.77v3.8c0,1,.11,1.09,1,1.19V95h-3.48v-.46c1-.1,1.11-.17,1.11-1.21V90c0-1-.06-1.08-.93-1.23v-.42a9.52,9.52,0,0,0,2.33-.64V89a13.82,13.82,0,0,1,1.15-.8,2.29,2.29,0,0,1,1.28-.43,1.88,1.88,0,0,1,1.76,1.29c.43-.34.83-.6,1.23-.87a2.38,2.38,0,0,1,1.27-.42c1.27,0,2,.9,2,2.48v3.1c0,1,.09,1.09,1,1.19V95Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M345.58,92.81c-.22.75-.49,1.62-.65,2.22h-6.12v-.39c.88-.86,1.83-1.8,2.62-2.72a6.22,6.22,0,0,0,1.93-3.82c0-1.29-.68-2.06-1.83-2.06a2.76,2.76,0,0,0-2.2,1.48l-.39-.35.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.78c0,1.35-.49,2.3-2,3.84-.68.7-1.67,1.65-2.34,2.27h3c.88,0,1.11-.07,1.67-1.08Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M347.11,83.29a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.89,9.89,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
            style={{ fill: "#626466" }}
          />
          <g id="_1-4" data-name="1">
            <path d="M884.36,55.84c-1.41.18-1.56.31-1.56,2.08v7.67c0,2.73-.35,4.3-1.67,5.62a6,6,0,0,1-1.81,1.24l-.3-.67A3.61,3.61,0,0,0,880.85,69a28,28,0,0,0,.18-4V57.92c0-1.77-.16-1.92-1.65-2.08v-.63h5Z" />
            <path d="M886.25,69.23a1.26,1.26,0,0,1-1.19-1.42,1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.28,1.28,0,0,1,886.25,69.23Z" />
            <path d="M896.65,66c-.25,1-.57,2.14-.75,2.94h-7v-.52c1-1.14,2.1-2.38,3-3.59,1.19-1.6,2.21-3.24,2.21-5.05s-.78-2.71-2.1-2.71-2,1.09-2.53,2l-.45-.46,1.07-1.77a3.2,3.2,0,0,1,2.57-1.27c1.76,0,3.17,1.38,3.17,3.68,0,1.77-.56,3-2.33,5.06-.78.92-1.91,2.17-2.68,3h3.43c1,0,1.29-.1,1.92-1.43Z" />
            <path d="M901.77,55.53c2.82,0,3.85,3.59,3.85,6.82s-1,6.85-3.85,6.85-3.9-3.54-3.9-6.83C897.87,59.28,898.93,55.53,901.77,55.53Zm0,.77c-1.62,0-2,3.09-2,6s.49,6.11,2,6.11,2-3.13,2-6.08S903.32,56.3,901.76,56.3Z" />
            <path d="M820.85,55.83c-1.4.18-1.55.31-1.55,2.09v7.66c0,2.73-.36,4.3-1.68,5.62a6,6,0,0,1-1.81,1.24l-.29-.67A3.6,3.6,0,0,0,817.34,69a27.65,27.65,0,0,0,.19-4V57.92c0-1.78-.17-1.93-1.66-2.09V55.2h5Z" />
            <path d="M822.74,69.23a1.27,1.27,0,0,1-1.19-1.43,1.29,1.29,0,0,1,1.19-1.43A1.31,1.31,0,0,1,824,67.8,1.29,1.29,0,0,1,822.74,69.23Z" />
            <path d="M826.41,68.94v-.63c2-.14,2.12-.27,2.12-1.84v-8c0-1.08-.16-1.13-1-1.22l-.84-.1v-.56a14.61,14.61,0,0,0,3.49-1V66.47c0,1.6.11,1.7,2.14,1.84v.63Z" />
            <path d="M840,62.87l-1.17.69a2.12,2.12,0,0,1-1.1.33c-2,0-3.35-1.75-3.35-4,0-2,1.38-4.41,3.85-4.41s3.76,2.42,3.76,5.3a7.71,7.71,0,0,1-7,8.44l-.14-.65C837.59,68,839.46,66,840,62.87Zm-2-6.58c-1.35,0-1.84,1.46-1.84,3,0,1.85.9,3.39,2.41,3.39a2.49,2.49,0,0,0,1.54-.55c0-.48.07-1,.07-1.4C840.2,58.82,839.74,56.28,838,56.29Z" />
            <path d="M757.74,55.28c-1.41.19-1.55.31-1.55,2.09V65c0,2.73-.36,4.3-1.68,5.62a5.85,5.85,0,0,1-1.81,1.24l-.3-.66a3.59,3.59,0,0,0,1.83-2.76,27.56,27.56,0,0,0,.19-4V57.37c0-1.78-.17-1.93-1.66-2.09v-.63h5Z" />
            <path d="M759.63,68.68a1.27,1.27,0,0,1-1.19-1.43,1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.29,1.29,0,0,1,759.63,68.68Z" />
            <path d="M763.3,68.39v-.63c2-.14,2.12-.26,2.12-1.84v-8c0-1.08-.16-1.13-1-1.22l-.84-.1V56a14.61,14.61,0,0,0,3.49-1v11c0,1.6.11,1.7,2.14,1.84v.63Z" />
            <path d="M775.29,55a3,3,0,0,1,3.07,3.15,2.75,2.75,0,0,1-.53,1.7,16.4,16.4,0,0,1-1.49,1.31c1.27.8,2.5,1.81,2.5,3.65a3.77,3.77,0,0,1-3.77,3.86,3.38,3.38,0,0,1-3.63-3.5,2.62,2.62,0,0,1,.84-2.06,16.61,16.61,0,0,1,1.65-1.29,3.92,3.92,0,0,1-2-3.32A3.38,3.38,0,0,1,775.29,55Zm0,12.93c1.2,0,1.93-.9,1.93-2.41s-1.17-2.46-2.72-3.33A3.27,3.27,0,0,0,773.06,65C773.06,66.93,774.12,67.9,775.29,67.9Zm-.24-12.2c-1.06,0-1.62,1-1.62,2.12,0,1.51.9,2.11,2.34,3a3.1,3.1,0,0,0,1.12-2.55C776.89,56.43,776,55.7,775.05,55.7Z" />
            <path d="M694.53,55.5c-1.41.19-1.56.31-1.56,2.09v7.67c0,2.72-.35,4.29-1.68,5.61a5.63,5.63,0,0,1-1.81,1.24l-.29-.66A3.6,3.6,0,0,0,691,68.69a26.21,26.21,0,0,0,.19-4V57.59c0-1.78-.17-1.92-1.66-2.09v-.63h5Z" />
            <path d="M696.41,68.9c-.7,0-1.18-.59-1.18-1.43a1.27,1.27,0,0,1,1.18-1.42,1.44,1.44,0,0,1,0,2.85Z" />
            <path d="M700.08,68.61V68c2-.14,2.13-.26,2.13-1.84v-8c0-1.08-.17-1.13-1-1.21l-.84-.11v-.56a14.3,14.3,0,0,0,3.49-1v11c0,1.6.11,1.7,2.13,1.84v.63Z" />
            <path d="M716,55.73c-1.77,4.28-3.5,8.55-5.26,12.92l-1.41.16-.13-.24c1.93-3.58,3.92-7.85,5.39-11.43h-3.92c-1.2,0-1.29.18-1.79,1.56h-.59c.2-1.28.28-2.34.38-3.26h7.22Z" />
            <path d="M631.2,55.17c-1.41.19-1.56.31-1.56,2.09v7.67c0,2.72-.35,4.29-1.67,5.61a5.85,5.85,0,0,1-1.81,1.24l-.3-.66a3.61,3.61,0,0,0,1.83-2.76,27.8,27.8,0,0,0,.18-4V57.26c0-1.78-.16-1.92-1.66-2.09v-.63h5Z" />
            <path d="M633.09,68.57a1.45,1.45,0,0,1,0-2.85,1.31,1.31,0,0,1,1.22,1.42A1.29,1.29,0,0,1,633.09,68.57Z" />
            <path d="M636.76,68.28v-.63c2-.14,2.12-.26,2.12-1.84v-8c0-1.08-.16-1.13-1-1.22l-.84-.1V55.9a14.16,14.16,0,0,0,3.49-1v11c0,1.6.11,1.7,2.14,1.84v.63Z" />
            <path d="M648,60.29a2.41,2.41,0,0,1,1.18-.37c2,0,3.32,1.72,3.32,3.92,0,2.56-1.62,4.69-3.87,4.69s-3.88-2-3.88-5.3a8.51,8.51,0,0,1,5.07-7.93,13.89,13.89,0,0,1,2.19-.57l.11.73a10.36,10.36,0,0,0-2,.72A6.52,6.52,0,0,0,646.82,61Zm.38.89a3.24,3.24,0,0,0-1.67.53,8,8,0,0,0-.12,1.48c0,2.67.86,4.56,2.35,4.56s1.79-1.72,1.79-3.12C650.73,62.83,650,61.18,648.38,61.18Z" />
            <path d="M567.76,55.61c-1.4.19-1.55.31-1.55,2.09v7.66c0,2.73-.36,4.3-1.68,5.62a5.85,5.85,0,0,1-1.81,1.24l-.29-.66a3.6,3.6,0,0,0,1.82-2.76,26.21,26.21,0,0,0,.19-4V57.7c0-1.78-.17-1.93-1.66-2.09V55h5Z" />
            <path d="M569.65,69a1.27,1.27,0,0,1-1.19-1.43,1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.29,1.29,0,0,1,569.65,69Z" />
            <path d="M573.32,68.72v-.63c2-.14,2.12-.26,2.12-1.84v-8c0-1.08-.16-1.13-1-1.22l-.84-.1v-.56a14.61,14.61,0,0,0,3.49-1v11c0,1.6.12,1.7,2.14,1.84v.63Z" />
            <path d="M588.26,55.55l.12.18-.5,1.52h-4.29c-.17,1.12-.41,2.72-.57,3.73a7.48,7.48,0,0,1,2.21-.63,3.45,3.45,0,0,1,3.49,3.59,4.68,4.68,0,0,1-1.63,3.57A5.37,5.37,0,0,1,583.8,69a2.64,2.64,0,0,1-1.92-.8.81.81,0,0,1-.32-.74,1.22,1.22,0,0,1,.41-.79.4.4,0,0,1,.56,0,3.11,3.11,0,0,0,2.33,1.19c1.47,0,2.17-1.45,2.17-3,0-1.87-1-3.18-3-3.18a5.08,5.08,0,0,0-1.85.37l-.06-.09c.29-1.7.7-4.63.91-6.37Z" />
            <path d="M506.41,55.28c-1.41.19-1.56.31-1.56,2.09V65c0,2.73-.35,4.3-1.68,5.62a5.63,5.63,0,0,1-1.81,1.24l-.29-.66a3.6,3.6,0,0,0,1.82-2.76,26.21,26.21,0,0,0,.19-4V57.37c0-1.78-.17-1.93-1.66-2.09v-.63h5Z" />
            <path d="M508.29,68.68a1.27,1.27,0,0,1-1.18-1.43,1.29,1.29,0,0,1,1.18-1.43,1.31,1.31,0,0,1,1.22,1.43A1.29,1.29,0,0,1,508.29,68.68Z" />
            <path d="M512,68.39v-.63c2-.14,2.13-.26,2.13-1.84v-8c0-1.08-.17-1.13-1-1.22l-.84-.1V56a14.61,14.61,0,0,0,3.49-1v11c0,1.6.11,1.7,2.13,1.84v.63Z" />
            <path d="M526.11,64.57v1.56c0,1.37.08,1.49,1.4,1.63v.63h-4.46v-.63c1.36-.14,1.45-.29,1.45-1.67V64.57h-4.83V63.9c1.78-2.89,3.73-6,5.52-8.85h.92v8.47h1.79v1Zm-1.61-7.18c-1.11,1.84-2.39,4-3.65,6.13h3.65Z" />
            <path d="M441,55.28c-1.41.19-1.56.31-1.56,2.09V65c0,2.73-.35,4.3-1.68,5.62A5.74,5.74,0,0,1,436,71.89l-.29-.66a3.6,3.6,0,0,0,1.82-2.76,26.21,26.21,0,0,0,.19-4V57.37c0-1.78-.17-1.93-1.66-2.09v-.63h5Z" />
            <path d="M442.88,68.68a1.27,1.27,0,0,1-1.19-1.43,1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.29,1.29,0,0,1,442.88,68.68Z" />
            <path d="M446.55,68.39v-.63c2-.14,2.13-.26,2.13-1.84v-8c0-1.08-.17-1.13-1-1.22l-.83-.1V56a14.75,14.75,0,0,0,3.49-1v11c0,1.6.11,1.7,2.13,1.84v.63Z" />
            <path d="M454.89,57.54l.88-1.34A3.09,3.09,0,0,1,458.19,55,2.71,2.71,0,0,1,461,57.8a2.41,2.41,0,0,1-.58,1.56,7.69,7.69,0,0,1-1.33,1.14,3.32,3.32,0,0,1,2.76,3.25c0,3.43-3.62,4.89-4.9,4.89a2.78,2.78,0,0,1-1.94-.73,1,1,0,0,1-.3-.73,1.27,1.27,0,0,1,.44-.84.35.35,0,0,1,.52,0,3.24,3.24,0,0,0,2.3,1.17c1.25,0,2.15-1.08,2.15-3.11a2.41,2.41,0,0,0-2.4-2.71,4.09,4.09,0,0,0-1.19.21l-.13-.65c1.64-.62,2.9-1.44,2.9-3a1.72,1.72,0,0,0-1.73-2A2.9,2.9,0,0,0,455.3,58Z" />
            <path d="M378,55.28c-1.41.19-1.56.31-1.56,2.09V65c0,2.73-.35,4.3-1.67,5.62A5.85,5.85,0,0,1,373,71.89l-.3-.66a3.61,3.61,0,0,0,1.83-2.76,27.8,27.8,0,0,0,.18-4V57.37c0-1.78-.17-1.93-1.66-2.09v-.63h5Z" />
            <path d="M379.88,68.68a1.27,1.27,0,0,1-1.18-1.43,1.29,1.29,0,0,1,1.18-1.43,1.32,1.32,0,0,1,1.23,1.43A1.29,1.29,0,0,1,379.88,68.68Z" />
            <path d="M383.55,68.39v-.63c2-.14,2.13-.26,2.13-1.84v-8c0-1.08-.16-1.13-1-1.22l-.84-.1V56a14.61,14.61,0,0,0,3.49-1v11c0,1.6.11,1.7,2.14,1.84v.63Z" />
            <path d="M399.27,65.46c-.25,1-.57,2.13-.75,2.93h-7v-.52c1-1.13,2.1-2.38,3-3.58,1.2-1.61,2.21-3.25,2.21-5.06s-.77-2.71-2.1-2.71-2,1.1-2.53,1.95l-.44-.46,1.07-1.76A3.19,3.19,0,0,1,395.29,55c1.76,0,3.17,1.39,3.17,3.68,0,1.77-.57,3-2.34,5.06-.78.92-1.91,2.18-2.68,3h3.44c1,0,1.28-.1,1.91-1.43Z" />
            <path d="M310.51,55.39c-1.41.18-1.55.31-1.55,2.09v7.66c0,2.73-.36,4.3-1.68,5.62A6,6,0,0,1,305.47,72l-.3-.66A3.63,3.63,0,0,0,307,68.58a27.88,27.88,0,0,0,.18-4V57.48c0-1.78-.16-1.93-1.65-2.09v-.63h5Z" />
            <path d="M312.4,68.79a1.27,1.27,0,0,1-1.19-1.43,1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.29,1.29,0,0,1,312.4,68.79Z" />
            <path d="M316.07,68.5v-.63c2-.14,2.12-.27,2.12-1.84V58c0-1.08-.16-1.13-1-1.22l-.84-.1v-.56a14.61,14.61,0,0,0,3.49-1V66c0,1.6.11,1.7,2.14,1.84v.63Z" />
            <path d="M325.05,68.5v-.63c2-.14,2.13-.27,2.13-1.84V58c0-1.08-.17-1.13-1-1.22l-.83-.1v-.56a14.75,14.75,0,0,0,3.49-1V66c0,1.6.11,1.7,2.13,1.84v.63Z" />
          </g>
        </g>
        <path
          d="M974.37,153.59c-.76-3.26-2-6.55-6-6.55-3.21,0-4.46,2.68-4.46,5.3,0,3.3,1.84,5,5.46,7.16,5.07,3,7.44,5.33,7.44,10.07,0,5.33-4,9.35-9.65,9.35a17,17,0,0,1-6.72-1.62c-.33-1.34-.88-5-1.19-7.65l1.4-.41c.87,3.29,2.86,7.8,7.3,7.8,3,0,4.86-2.37,4.86-5.59,0-3.65-1.73-5.21-5.49-7.57-4.53-2.82-7.14-5.21-7.14-9.93,0-4.55,3.25-8.71,9.09-8.71a24.93,24.93,0,0,1,5.78.93c.17,1.8.37,3.89.71,7.1Z"
          style={{ fill: "#ed1f24" }}
        />
        <path
          d="M981.21,152.71l2-3.15a7.26,7.26,0,0,1,5.69-2.88,6.36,6.36,0,0,1,6.61,6.65A5.64,5.64,0,0,1,994.2,157a18.5,18.5,0,0,1-3.11,2.68c4.09,1,6.47,4,6.47,7.62,0,8.05-8.5,11.49-11.5,11.49a6.53,6.53,0,0,1-4.54-1.72,2.36,2.36,0,0,1-.72-1.7,3.11,3.11,0,0,1,1-2,.83.83,0,0,1,1.24.06,7.67,7.67,0,0,0,5.4,2.74c2.94,0,5-2.53,5-7.31,0-4.57-2.94-6.35-5.63-6.35a9.34,9.34,0,0,0-2.8.5l-.3-1.54c3.86-1.45,6.81-3.37,6.81-7,0-3-1.66-4.61-4.06-4.61s-4.16,1.93-5.36,3.9Z"
          style={{ fill: "#ed1f24" }}
        />
        <path
          d="M749.12,624.07c-.76-3.26-2-6.56-6-6.56-3.21,0-4.46,2.69-4.46,5.3,0,3.3,1.85,5,5.47,7.16,5.07,3,7.44,5.33,7.44,10.08,0,5.33-4,9.35-9.65,9.35a17.08,17.08,0,0,1-6.72-1.62c-.33-1.35-.89-5-1.19-7.66l1.4-.4c.86,3.29,2.85,7.8,7.29,7.8,3,0,4.87-2.38,4.87-5.59,0-3.65-1.73-5.22-5.5-7.57-4.52-2.82-7.13-5.22-7.13-9.93,0-4.56,3.25-8.72,9.08-8.72a24.7,24.7,0,0,1,5.78.94c.18,1.79.37,3.89.72,7.09Z"
          style={{ fill: "#ed1f24" }}
        />
        <path
          d="M768.38,634.42,765.62,636a5,5,0,0,1-2.57.76c-4.73,0-7.88-4.11-7.88-9.3,0-4.78,3.24-10.35,9.05-10.35,5.64,0,8.82,5.69,8.82,12.44,0,9.1-5,15.82-11,18.47a18.64,18.64,0,0,1-5.35,1.34l-.34-1.52C762.61,646.46,767,641.82,768.38,634.42ZM763.63,619c-3.18,0-4.31,3.44-4.31,7,0,4.35,2.1,8,5.64,8a5.73,5.73,0,0,0,3.61-1.3,27.71,27.71,0,0,0,.18-3.29C768.75,624.9,767.66,618.94,763.63,619Z"
          style={{ fill: "#ed1f24" }}
        />
        <path
          d="M889,401.57c-.75-3.26-2-6.55-6-6.55-3.21,0-4.46,2.68-4.46,5.3,0,3.29,1.84,5,5.46,7.15,5.08,3,7.45,5.33,7.45,10.08,0,5.33-4,9.35-9.66,9.35a16.86,16.86,0,0,1-6.71-1.62c-.33-1.34-.89-5-1.19-7.65l1.4-.41c.86,3.29,2.85,7.8,7.29,7.8,3,0,4.87-2.37,4.87-5.59,0-3.65-1.73-5.21-5.5-7.57-4.52-2.82-7.14-5.22-7.14-9.93,0-4.55,3.26-8.71,9.09-8.71a24.83,24.83,0,0,1,5.78.93c.17,1.8.37,3.89.72,7.09Z"
          style={{ fill: "#ed1f24" }}
        />
        <path
          d="M897.31,426.16v-1.47c4.73-.33,5-.63,5-4.33V401.51c0-2.53-.38-2.65-2.26-2.86l-2-.24V397.1a34,34,0,0,0,8.19-2.44v25.7c0,3.75.26,4,5,4.33v1.47Z"
          style={{ fill: "#ed1f24" }}
        />
        <path
          d="M925.14,394.66c6.63,0,9,8.43,9,16,0,7.24-2.41,16.07-9,16.07s-9.15-8.31-9.15-16C916,403.47,918.47,394.66,925.14,394.66Zm0,1.8c-3.82,0-4.76,7.27-4.76,14.15s1.16,14.33,4.78,14.33,4.68-7.34,4.68-14.28S928.79,396.46,925.13,396.46Z"
          style={{ fill: "#ed1f24" }}
        />
        <path
          d="M191.3,931.05c-.75-3.26-2-6.55-6-6.55-3.21,0-4.46,2.68-4.46,5.3,0,3.29,1.84,5,5.46,7.15,5.08,3,7.45,5.33,7.45,10.08,0,5.33-4,9.35-9.66,9.35a16.86,16.86,0,0,1-6.71-1.62c-.33-1.34-.89-5-1.19-7.65l1.4-.41c.86,3.29,2.85,7.8,7.29,7.8,3,0,4.87-2.37,4.87-5.59,0-3.65-1.73-5.21-5.5-7.57-4.52-2.82-7.14-5.22-7.14-9.93,0-4.56,3.26-8.71,9.09-8.71a24.83,24.83,0,0,1,5.78.93c.17,1.79.37,3.89.72,7.09Z"
          style={{ fill: "#ed1f24" }}
        />
        <path
          d="M199.65,955.64v-1.47c4.73-.33,5-.63,5-4.33V931c0-2.53-.38-2.65-2.26-2.86l-2-.24v-1.31a34,34,0,0,0,8.19-2.44v25.7c0,3.75.26,4,5,4.33v1.47Z"
          style={{ fill: "#ed1f24" }}
        />
        <text
          transform="translate(950.36 331.93) rotate(-77.08) scale(1 1.15)"
          style={{
            fontSize: "31.315839767456055px",
            fill: "#f27172",
            fontFamily: "MyriadPro-Regular, Myriad Pro",
          }}
        >
          <tspan style={{ letterSpacing: "-0.012972668241270725em" }}>B</tspan>
          <tspan x="16.57" y="0">
            .J3
          </tspan>
        </text>
        <text
          transform="translate(994.51 105.58) rotate(-87.02) scale(1 1.15)"
          style={{
            fontSize: "31.315839767456055px",
            fill: "#b0daab",
            fontFamily: "MyriadPro-Regular, Myriad Pro",
          }}
        >
          <tspan style={{ letterSpacing: "-0.012972668241270725em" }}>B</tspan>
          <tspan x="16.57" y="0">
            .IJ
          </tspan>
        </text>
        <text
          transform="matrix(0.59, -0.8, 0.92, 0.68, 832.06, 573.5)"
          style={{
            fontSize: "31.315839767456055px",
            fill: "#f27172",
            fontFamily: "MyriadPro-Regular, Myriad Pro",
          }}
        >
          <tspan style={{ letterSpacing: "-0.012972668241270725em" }}>B</tspan>
          <tspan x="16.57" y="0">
            .J2
          </tspan>
        </text>
        <text
          transform="translate(505 863.46) rotate(-30.58) scale(1 1.15)"
          style={{
            fontSize: "31.315839767456055px",
            fill: "#f27172",
            fontFamily: "MyriadPro-Regular, Myriad Pro",
          }}
        >
          <tspan style={{ letterSpacing: "-0.012972668241270725em" }}>B</tspan>
          <tspan x="16.57" y="0">
            .J1
          </tspan>
        </text>
      </svg>
    </div>
  );
}
export default JZone;
