import React, { PureComponent, useEffect, useState } from 'react';
import {
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  } from 'recharts';
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#dc3545', '#17a2b8'];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent, index, data
  }) => {
     const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

function GenderVisits(props) {
    const [visits, setVisits] = useState(0)
    const [visitGroup, setVisitGroup] = useState([])
    const [monthsVisits, setMonthsVists] = useState([])

    useEffect(() => {
        if (props.currentTab === 'monthlyLeadVisits') {
            const token = localStorage.getItem("token");
            axios.defaults.headers.common = {Authorization: `Bearer ${token}`};
            axios.get(`${process.env.REACT_APP_URL}/LeadVisits/GetLeadVisitReport?startDate=${props.startDate}&endDate=${props.endDate}&_end=1000`)
                .then((resp) => {
                    setMonthsVists(resp.data)
                });
            // axios.get(`${process.env.REACT_APP_URL}/LeadVisits?startDate=${props.startDate}&endDate=${props.endDate}&_end=1000`)
            // .then((resp) => {
            //     // console.log(resp.data)
            //     setMonthsVists(resp.data.map(el => { return {name: el.id, males: el.males, females: el.females, visitDate: moment(el.visitDate).format('HH')}}).reduce((acc, value) => {
            //         // Group initialization
            //         if (!acc[value.visitDate]) {
            //           acc[value.visitDate] = [];
            //         }

            //         // Grouping
            //         acc[value.visitDate].push(value);

            //         return acc;
            //       }, {}))
            // });
        }
      }, [props.startDate, props.endDate, props.currentTab]);


    // let morning = []
    // let afternoon = []
    // let evening = []
    // console.log(monthsVisits)
    // for (let x in monthsVisits) {
    //   console.log(x)
    //     if(x >= 9 && x < 12) {
    //         morning.push({name: x, value: monthsVisits[x].length})
    //     } else if (x >= 12 && x < 14) {
    //         afternoon.push({name: x, value: monthsVisits[x].length})
    //     } else if (x >= 14 && x < 17) {
    //         evening.push({name: x, value: monthsVisits[x].length})
    //     }
    //   }

      const data = [
        {
          name: 'Morning',
          value: monthsVisits.map(visits => visits.map(visit => (moment(visit.visitDate).isBetween(moment(visit.visitDate).format('YYYY-MM-DDT00:00:00'), moment(visit.visitDate).format('YYYY-MM-DDT11:59:59')) && (visit.females + visit.males))).reduce((a,b) => a + b, 0)).reduce((a,b) => a + b, 0),
        },
        {
          name: 'Afternoon',
          value: monthsVisits.map(visits => visits.map(visit => (moment(visit.visitDate).isBetween(moment(visit.visitDate).format('YYYY-MM-DDT12:00:00'), moment(visit.visitDate).format('YYYY-MM-DDT13:59:59')) && (visit.females + visit.males))).reduce((a,b) => a + b, 0)).reduce((a,b) => a + b, 0),
        },
        {
          name: 'Evening',
          value: monthsVisits.map(visits => visits.map(visit => (moment(visit.visitDate).isBetween(moment(visit.visitDate).format('YYYY-MM-DDT14:00:00'), moment(visit.visitDate).format('YYYY-MM-DDT23:59:59')) && (visit.females + visit.males))).reduce((a,b) => a + b, 0)).reduce((a,b) => a + b, 0),
        },
      ];

    return (
        <div>
            <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        {/* <Bar dataKey="pv" fill="#8884d8" /> */}
        <Bar dataKey="value" fill="#82ca9d" />
      </BarChart>
        </div>
    )
}

export default GenderVisits
