import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ProgressField,
  Filter,
  TextInput,
  FunctionField,
  Show,
  EditButton,
  Edit,
  TabbedForm,
  DateField,
  FormTab,
  BooleanField,
  useNotify,
} from "react-admin";
import ZaitonLogo from "../assets/zaiton-plus-logo.jpg";
import axios from "axios";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import ProgressBar from "react-bootstrap/ProgressBar";
import moment from "moment";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import "./invoice.css";

const BuyContractFilter = (props) => (
  <NoPrint>
    <Filter {...props}>
      <TextInput label="Property Name" source="Name" alwaysOn />
      <TextInput label="First Name" source="firstName" />
      <TextInput label="Second Name" source="secondName" />
      <TextInput label="Note" source="note" />
    </Filter>
  </NoPrint>
);

const ListActions = () => {
  return (
    <NoPrint>
      <Button
        onClick={() => {
          window.print();
        }}
      >
        Print Contract
      </Button>
    </NoPrint>
  );
};

export const FinishedContractsList = (props) => (
  <PrintProvider>
    <Print single={true} name="foo">
      <List
        id="expenses"
        {...props}
        title="Finished Contracts"
        filters={<BuyContractFilter />}
        actions={<ListActions />}
      >
        <Datagrid rowClick="show">
          <TextField source="contractNumber" />
          <TextField source="property.name" label="Property Name" />
          <FunctionField
            label="Customer Name"
            render={(record) =>
              `${record.customer.firstName} ${record.customer.middleName}`
            }
          />
          <DateField source="buyingDate" />
          <FunctionField
            label="Payment Progress"
            render={(record) => (
              <ProgressBar
                now={
                  (record.paymentProcess.filter((e) => e.isPayed === true)
                    .length /
                    record.paymentProcess.length) *
                  100
                }
                label={`${Math.floor(
                  (record.paymentProcess.filter((e) => e.isPayed === true)
                    .length /
                    record.paymentProcess.length) *
                    100
                )}%`}
              />
            )}
          />
          <TextField source="buyingPrice" />
          {/* <BooleanField source="isLoanDone" /> */}
          <TextField source="note" />

          {/* <FunctionField
        label="Cancel Contract"
        render={(record) =>
          !record.isLoanDone && (
            <EditButton
              label="Cancel Contract"
              style={{ color: "red" }}
              icon={false}
            />
          )
        }
      /> */}
        </Datagrid>
      </List>
    </Print>
  </PrintProvider>
);

export const FinishedContractsShow = (props) => {
  Date.prototype.toDateInputValue = function () {
    var local = new Date(this);
    local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
    return local.toJSON().slice(0, 10);
  };

  const [rows, setRows] = React.useState(15);
  const [periodOfInstallment, setPeriodOfInstallment] = React.useState(4);
  const [preInstallment, setPreInstallment] = React.useState(35);
  const [installments, setInstallments] = React.useState(50);
  const [getKeys, setGetKeys] = React.useState(15);
  const [properitiesArr, setProperitiesArr] = React.useState([]);
  const [properitiesData, setProperitiesData] = React.useState([]);
  const [customerArr, setCustomerArr] = React.useState([]);
  const [customerData, setCustomerData] = React.useState([]);
  const [properityData, setProperityData] = React.useState(0);
  const [defaultDate, setDefaultDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const [buyContractId, setBuyContractId] = React.useState(-1);
  const [note, setNote] = React.useState("");
  const [buyType, setBuyType] = React.useState("installment");
  const [customer, setCustomer] = React.useState("");
  const [customerId, setCustomerId] = React.useState();
  const [properity, setProperity] = React.useState("");
  const [propertyId, setProperityId] = React.useState("");
  const [data, setData] = React.useState([]);
  const [extraPrice, setExtraPrice] = React.useState(0);
  const [extraNote, setExtraNote] = React.useState("");
  const [prePaid, setPrePaid] = React.useState(false);

  const keyValue = (properityData.initialPrice / 100) * getKeys;
  const prePayValue = (properityData.initialPrice / 100) * preInstallment;
  const eachInstallment =
    (properityData.initialPrice - (keyValue + prePayValue)) / rows;
  React.useEffect(() => {
    setInstallments(100 - (preInstallment + getKeys));
  }, [preInstallment]);
  React.useEffect(() => {
    setInstallments(100 - (preInstallment + getKeys));
  }, [getKeys]);

  const arrLength = [];
  for (let index = 0; index < rows; index++) {
    arrLength[index] = index;
  }
  const arr = ["first", "second", "third", "fourth"];

  const renameKeys = (keysMap, obj) =>
    Object.keys(obj).reduce(
      (acc, key) => ({
        ...acc,
        ...{ [keysMap[key] || key]: obj[key] },
      }),
      {}
    );

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
      .get(`${process.env.REACT_APP_URL}/BuyContracts/${props.id}`)
      .then((resp) => {
        setData(resp.data);
      });
  }, []);
  function handleClick() {}

  let isGrayOut = false;

  const notify = useNotify();

  return (
    <PrintProvider>
      <List
        empty={false}
        {...props}
        title="Finished Contracts"
        actions={<ListActions />}
      >
        <div id="divcontents">
          <Print single={true} name="foo">
            <Print single={true} name="foo">
              <div style={{ display: "flex", width: "100%" }}>
                <div
                  style={{
                    height: 150,
                    width: 150,
                    marginRight: 10,
                    marginLeft: 10,
                    marginTop: 30,
                  }}
                >
                  <img
                    src={ZaitonLogo}
                    alt=""
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
                <div style={{ width: "85%" }}>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "50%" }}>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            Area
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          placeholder="Area"
                          value={data.property && `${data.property.area}m`}
                          type="text"
                          disabled={true}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            Street
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          placeholder="Street Width"
                          value={
                            data.property && `${data.property.streetWidth}m`
                          }
                          type="text"
                          disabled={true}
                        />
                        <FormControl
                          placeholder="Street Number"
                          value={data.property && data.property.streetNumber}
                          type="text"
                          disabled={true}
                        />
                      </InputGroup>
                    </div>

                    <div style={{ width: "50%", marginLeft: 20 }}>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            Name
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          placeholder="Name"
                          value={data.property && data.property.name}
                          type="text"
                          disabled={true}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            Zone
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          placeholder="Zone"
                          value={data.property && data.property.zone}
                          type="text"
                          disabled={true}
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "50%" }}>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            Address
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          placeholder="Address"
                          value={data.customer && data.customer.address}
                          type="text"
                          disabled={true}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            phone
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          placeholder="phone"
                          value={data.customer && data.customer.phone2}
                          type="text"
                          disabled={true}
                        />
                      </InputGroup>
                    </div>
                    <div style={{ marginLeft: 20, width: "50%" }}>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            First Name
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          placeholder="First Name"
                          value={data.customer && `${data.customer.firstName}`}
                          type="text"
                          disabled={true}
                        />
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">
                            phone
                          </InputGroup.Text>
                        </InputGroup.Prepend>

                        <FormControl
                          placeholder="Phone"
                          value={data.customer && data.customer.phone}
                          type="text"
                          disabled={true}
                        />
                      </InputGroup>
                    </div>
                  </div>
                </div>
              </div>
            </Print>

            {/* </SimpleForm> */}
            <div style={{ width: "99%", margin: "auto" }}>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">
                    Property Price
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  placeholder="Price"
                  value={data.property && data.property.initialPrice}
                  type="number"
                  disabled={true}
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">
                    No. of Installments
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  type="number"
                  disabled={true}
                  value={rows}
                  onChange={(e) => setRows(e.target.value)}
                />
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">
                    Period of Installments
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  type="number"
                  disabled={true}
                  value={periodOfInstallment}
                  onChange={(e) => setPeriodOfInstallment(e.target.value)}
                />
              </InputGroup>
            </div>

            <Table
              bordered
              hover
              style={{ width: "99%", margin: "auto", marginBottom: 15 }}
            >
              <thead>
                <tr>
                  <th>Installment</th>
                  <th>Price</th>
                  {/* <th>Percentage of Installments</th> */}
                  <th>Date</th>
                  <th className="noPrint">Is Paid</th>
                  <th className="noPrint">Action</th>
                </tr>
              </thead>
              <tbody>
                {/* <tr>
                  <td>Prepayment</td>
                  <td>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        type="number"
                        placeholder="Pre Payment"
                      disabled={true}
                        value={preInstallment}
                        onChange={(e) =>
                          setPreInstallment(parseInt(e.target.value))
                        }
                      />
                    </InputGroup>
                  </td>
                  <td>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        type="number"
                        value={prePayValue}
                        disabled={true}
                      />
                    </InputGroup>
                  </td>
                  <td>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          date
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        type="date"
                      disabled={true}
                        value={defaultDate}
                        onChange={(e) => setDefaultDate(e.target.value)}
                      />
                    </InputGroup>
                  </td>
                </tr>
                <tr>
                  <td>Installments</td>
                  <td>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        type="number"
                        placeholder="Installments"
                        value={installments}
                      disabled={true}
                        onChange={(e) =>
                          setInstallments(parseInt(e.target.value))
                        }
                      />
                    </InputGroup>
                  </td>
                  <td colSpan="2">
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>Note</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        as="textarea"
                        aria-label="Note"
                      disabled={true}
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                      />
                    </InputGroup>
                  </td>
                </tr> */}
                <td>Pre Payment</td>
                <td>
                  <InputGroup className="mb-3" style={{ marginRight: 5 }}>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      type="number"
                      name="payPrice"
                      disabled={true}
                      value={data && data.prePaymentPrice}
                    />
                  </InputGroup>
                </td>
                <td>
                  <InputGroup className="mb-3" style={{ marginLeft: 5 }}>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">date</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      type="date"
                      name="payDate"
                      disabled={true}
                      value={moment(data.buyingDate).format("YYYY-MM-DD")}
                    />
                  </InputGroup>
                </td>
                <td className="noPrint">
                  <Button
                    style={{ marginLeft: 15 }}
                    disabled={data.isPrePaymentPayed || prePaid}
                    onClick={() => {
                      axios
                        .post(
                          `${process.env.REACT_APP_URL}/BuyContracts/PayPrePaid?buyContractId=${data.id}`
                        )
                        .then((res) => {
                          if (res.status >= 200 && res.status < 300) {
                            setPrePaid(true);
                          }
                        })
                        .catch((error) => notify("Couldn't Proceed Payment"));
                    }}
                  >
                    Pay
                  </Button>
                </td>
                <td className="noPrint">
                  <iframe
                    id="ifmcontentstoprint"
                    style={{
                      height: 0,
                      width: 0,
                      position: "absolute",
                      display: "none",
                    }}
                  >
                    <div
                      id={`cachFrame`}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: 40,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: 30,
                        }}
                      >
                        <div style={{ width: 100, height: 100 }}>
                          <img
                            style={{ width: "100%", height: "100%" }}
                            src={ZaitonLogo}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "45%",
                          }}
                        >
                          <div style={{}}>
                            Invoice Number: {data.contractNumber}
                          </div>
                          <div>
                            Date: {moment(data.buyingDate).format("YYYY-MM-DD")}
                          </div>
                        </div>
                      </div>
                      <p>
                        Received From:{" "}
                        {data.customer &&
                          `${data.customer.firstName} ${data.customer.middleName} ${data.customer.lastName}`}
                      </p>
                      <p>Price: ${data.prePaymentPrice}</p>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          marginTop: 120,
                          alignItems: "center",
                        }}
                      >
                        <div style={{ textAlign: "center" }}>
                          <p style={{ padding: 0, margin: 0 }}>Accountant</p>
                          <p style={{ padding: 10, margin: 0 }}>
                            {localStorage.getItem("name")}
                          </p>
                        </div>
                        <div style={{ textAlign: "center" }}>
                          <p>Customer</p>
                          <p>
                            {data.customer &&
                              `${data.customer.firstName} ${data.customer.middleName} ${data.customer.lastName}`}
                          </p>
                        </div>
                      </div>
                    </div>
                  </iframe>
                  <Button
                    style={{ marginLeft: 15 }}
                    onClick={() => {
                      // var content = document.getElementById(`rowNumber${key+1}`);
                      var pri = document.getElementById("ifmcontentstoprint")
                        .contentWindow;
                      pri.document.open();
                      pri.document.write(
                        document.getElementById(`cachFrame`).innerHTML
                      );
                      pri.document.write("<br /><br /><hr /><br />");
                      pri.document.write(
                        document.getElementById(`cachFrame`).innerHTML
                      );
                      // pri.document.write(content.innerHTML);
                      pri.document.close();
                      pri.focus();
                      pri.print();
                    }}
                  >
                    print
                  </Button>
                </td>
                {data.paymentProcess &&
                  data.paymentProcess.map((el, key) => {
                    if (key + 1 === data.paymentProcess.length) {
                      return (
                        <tr id={`rowNumber${key + 1}`}>
                          <td>Delivering Keys</td>
                          {/* <form style={{ display: "flex" }}> */}
                          <td colSpan="1">
                            <InputGroup
                              className="mb-3"
                              style={{ marginRight: 5 }}
                            >
                              <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">
                                  $
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                type="number"
                                name="payPrice"
                                disabled={true}
                                value={el.currentPayPrice}
                              />
                            </InputGroup>
                            <InputGroup>
                              <InputGroup.Prepend>
                                <InputGroup.Text>Note</InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                as="textarea"
                                aria-label="Note"
                                disabled={el.isPayed}
                                value={el.extraNote}
                                id={`note${key}`}
                                onChange={(e) => setNote(e.target.value)}
                              />
                            </InputGroup>
                          </td>
                          <td>
                            <InputGroup
                              className="mb-3"
                              style={{ marginLeft: 5 }}
                            >
                              <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">
                                  date
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                type="date"
                                name="payDate"
                                disabled={true}
                                value={moment(el.currentPayDate).format(
                                  "YYYY-MM-DD"
                                )}
                              />
                            </InputGroup>
                            <InputGroup className="mb-3">
                              <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">
                                  Extra Price
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                placeholder="Extra Price"
                                type="number"
                                disabled={el.isPayed}
                                id={`extraPrice${key}`}
                                value={el.extraPrice}
                                onChange={(e) => setExtraPrice(e.target.value)}
                              />
                            </InputGroup>
                          </td>
                          <td className="noPrint">
                            {el.isPayed ? (
                              <InputGroup
                                style={{ width: 100, margin: 0 }}
                                className="mb-3"
                              >
                                <InputGroup.Prepend>
                                  <InputGroup.Checkbox
                                    style={{ width: 20 }}
                                    aria-label="Checkbox for following text input"
                                    //   value={buyContract.isPayed}
                                    defaultChecked={el.isPayed}
                                  />
                                </InputGroup.Prepend>
                                <FormControl
                                  style={{ padding: 10 }}
                                  aria-label="Text input with checkbox"
                                  value={el.isPayed && "Paid"}
                                />
                              </InputGroup>
                            ) : (
                              <Button
                                style={{ marginLeft: 15 }}
                                onClick={() => {
                                  const token = localStorage.getItem("token");
                                  axios.defaults.headers.common = {
                                    Authorization: `Bearer ${token}`,
                                  };
                                  axios
                                    .put(
                                      `${process.env.REACT_APP_URL}/PaymentProcesses/${el.id}`,
                                      {
                                        id: el.id,
                                        buyContractId: el.buyContractId,
                                        currentPayDate: el.currentPayDate,
                                        currentPayPrice: el.currentPayPrice,
                                        nextPayDate: el.nextPayDate,
                                        nextPayPrice: el.nextPayPrice,
                                        note: document.getElementById(
                                          `note${key}`
                                        ).value,
                                        attachment: el.attachment,
                                        isPayed: true,
                                        isItKeyPayment: el.isItKeyPayment,
                                        extraPrice: document.getElementById(
                                          `extraPrice${key}`
                                        ).value,
                                      }
                                    )
                                    .then((resp) => {
                                      axios
                                        .get(
                                          `${process.env.REACT_APP_URL}/BuyContracts/${props.id}`
                                        )
                                        .then((resp) => {
                                          setData(resp.data);
                                        })
                                        .then((resp) => console.log(resp));
                                    });
                                }}
                              >
                                Pay
                              </Button>
                            )}
                          </td>
                          <td className="noPrint">
                            <iframe
                              id="ifmcontentstoprint"
                              style={{
                                height: 0,
                                width: 0,
                                position: "absolute",
                                display: "none",
                              }}
                            >
                              <div
                                id={`iframe${key}`}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  margin: 40,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: 30,
                                  }}
                                >
                                  <div style={{ width: 100, height: 100 }}>
                                    <img
                                      style={{ width: "100%", height: "100%" }}
                                      src={ZaitonLogo}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      marginLeft: "45%",
                                    }}
                                  >
                                    <div>
                                      Invoice Number: {el.contractNumber}
                                    </div>
                                    <div>
                                      Date:{" "}
                                      {moment(el.currentPayDate).format(
                                        "YYYY-MM-DD"
                                      )}
                                    </div>
                                    <div>
                                      Next Payment Date:{" "}
                                      {moment(el.nextPayDate).format(
                                        "YYYY-MM-DD"
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <p>
                                  Received From:{" "}
                                  {`${data.customer.firstName} ${data.customer.middleName} ${data.customer.lastName}`}
                                </p>
                                <p>Price: ${el.currentPayPrice}</p>
                                <p>
                                  Note:{" "}
                                  {document.getElementById(`note${key}`) &&
                                    document.getElementById(`note${key}`).value}
                                </p>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    marginTop: 120,
                                    alignItems: "center",
                                  }}
                                >
                                  <div style={{ textAlign: "center" }}>
                                    <p style={{ padding: 0, margin: 0 }}>
                                      Accountant
                                    </p>
                                    <p style={{ padding: 10, margin: 0 }}>
                                      {localStorage.getItem("name")}
                                    </p>
                                  </div>
                                  <div style={{ textAlign: "center" }}>
                                    <p>Customer</p>
                                    <p>
                                      {data.customer &&
                                        `${data.customer.firstName} ${data.customer.middleName} ${data.customer.lastName}`}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </iframe>
                            <Button
                              style={{ marginLeft: 15 }}
                              onClick={() => {
                                // var content = document.getElementById(`rowNumber${key+1}`);
                                var pri = document.getElementById(
                                  "ifmcontentstoprint"
                                ).contentWindow;
                                pri.document.open();
                                pri.document.write(
                                  document.getElementById(`iframe${key}`)
                                    .innerHTML
                                );
                                pri.document.write(
                                  "<br /><br /><br /><br /><br /><hr /><br />"
                                );
                                pri.document.write(
                                  document.getElementById(`iframe${key}`)
                                    .innerHTML
                                );
                                // pri.document.write(content.innerHTML);
                                pri.document.close();
                                pri.focus();
                                pri.print();
                              }}
                            >
                              print
                            </Button>

                            {!el.isPayed ? (isGrayOut = true) : null}
                          </td>
                        </tr>
                      );
                    }
                    return (
                      // {arrLength.map((el, key) => (
                      <tr id={`rowNumber${key + 1}`}>
                        <td>{key + 1}</td>
                        {/* <form style={{ display: "flex" }}> */}
                        <td colSpan="1">
                          <InputGroup
                            className="mb-3"
                            style={{ marginRight: 5 }}
                          >
                            <InputGroup.Prepend>
                              <InputGroup.Text id="basic-addon1">
                                $
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              type="number"
                              name="payPrice"
                              disabled={true}
                              value={el.currentPayPrice}
                            />
                          </InputGroup>
                          <InputGroup style={{ display: isGrayOut && "none" }}>
                            <InputGroup.Prepend>
                              <InputGroup.Text>Note</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              as="textarea"
                              aria-label="Note"
                              disabled={el.isPayed}
                              value={el.note}
                              id={`note${key}`}
                              onChange={(e) => setNote(e.target.value)}
                            />
                          </InputGroup>
                        </td>
                        <td>
                          <InputGroup
                            className="mb-3"
                            style={{ marginLeft: 5 }}
                          >
                            <InputGroup.Prepend>
                              <InputGroup.Text id="basic-addon1">
                                date
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              type="date"
                              name="payDate"
                              disabled={true}
                              value={moment(el.currentPayDate).format(
                                "YYYY-MM-DD"
                              )}
                            />
                          </InputGroup>
                          <InputGroup
                            style={{ display: isGrayOut && "none" }}
                            className="mb-3"
                          >
                            <InputGroup.Prepend>
                              <InputGroup.Text id="basic-addon1">
                                Extra Price
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              placeholder="Extra Price"
                              type="number"
                              disabled={el.isPayed}
                              id={`extraPrice${key}`}
                              value={el.extraPrice}
                              onChange={(e) => setExtraPrice(e.target.value)}
                            />
                          </InputGroup>
                          <InputGroup style={{ display: isGrayOut && "none" }}>
                            <InputGroup.Prepend>
                              <InputGroup.Text>Note</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                              as="textarea"
                              aria-label="Note"
                              disabled={el.isPayed}
                              value={el.extraNote}
                              onChange={(e) => setExtraNote(e.target.value)}
                            />
                          </InputGroup>
                        </td>
                        <td className="noPrint">
                          {el.isPayed ? (
                            <InputGroup
                              style={{ width: 100, margin: 0 }}
                              className="mb-3"
                            >
                              <InputGroup.Prepend>
                                <InputGroup.Checkbox
                                  style={{ width: 20 }}
                                  aria-label="Checkbox for following text input"
                                  //   value={buyContract.isPayed}
                                  defaultChecked={el.isPayed}
                                />
                              </InputGroup.Prepend>
                              <FormControl
                                style={{ padding: 10 }}
                                aria-label="Text input with checkbox"
                                value={el.isPayed && "Paid"}
                              />
                            </InputGroup>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Button
                                style={{ marginLeft: 15 }}
                                disabled={isGrayOut}
                                onClick={() => {
                                  const token = localStorage.getItem("token");
                                  axios.defaults.headers.common = {
                                    Authorization: `Bearer ${token}`,
                                  };
                                  axios
                                    .put(
                                      `${process.env.REACT_APP_URL}/PaymentProcesses/${el.id}`,
                                      {
                                        id: el.id,
                                        buyContractId: el.buyContractId,
                                        currentPayDate: el.currentPayDate,
                                        currentPayPrice: el.currentPayPrice,
                                        nextPayDate: el.nextPayDate,
                                        nextPayPrice: el.nextPayPrice,
                                        note: document.getElementById(
                                          `note${key}`
                                        ).value,
                                        attachment: el.attachment,
                                        isPayed: true,
                                        isItKeyPayment: el.isItKeyPayment,
                                        extraPrice: document.getElementById(
                                          `extraPrice${key}`
                                        ).value,
                                        extraNote,
                                      }
                                    )
                                    .then((resp) => {
                                      axios
                                        .get(
                                          `${process.env.REACT_APP_URL}/BuyContracts/${props.id}`
                                        )
                                        .then((resp) => {
                                          setData(resp.data);
                                        })
                                        .then((resp) => console.log(resp));
                                    });
                                }}
                              >
                                Pay
                              </Button>
                            </div>
                          )}
                        </td>
                        <td className="noPrint">
                          <iframe
                            id="ifmcontentstoprint"
                            style={{
                              height: 0,
                              width: 0,
                              position: "absolute",
                              display: "none",
                            }}
                          >
                            <div
                              id={`iframe${key}`}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                margin: 40,
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: 30,
                                }}
                              >
                                <div style={{ width: 100, height: 100 }}>
                                  <img
                                    style={{ width: "100%", height: "100%" }}
                                    src={ZaitonLogo}
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginLeft: "45%",
                                  }}
                                >
                                  <div style={{}}>
                                    Invoice Number: {el.contractNumber}
                                  </div>
                                  <div>
                                    Date:{" "}
                                    {moment(el.currentPayDate).format(
                                      "YYYY-MM-DD"
                                    )}
                                  </div>
                                  <div>
                                    Next Payment Date:{" "}
                                    {moment(el.nextPayDate).format(
                                      "YYYY-MM-DD"
                                    )}
                                  </div>
                                </div>
                              </div>
                              <p>
                                Received From:{" "}
                                {`${data.customer.firstName} ${data.customer.middleName} ${data.customer.lastName}`}
                              </p>
                              <p>Price: ${el.currentPayPrice}</p>
                              <p>
                                Note:{" "}
                                {document.getElementById(`note${key}`) &&
                                  document.getElementById(`note${key}`).value}
                              </p>
                              <p>Extra Note: {extraNote}</p>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  marginTop: 120,
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ textAlign: "center" }}>
                                  <p style={{ padding: 0, margin: 0 }}>
                                    Accountant
                                  </p>
                                  <p style={{ padding: 10, margin: 0 }}>
                                    {localStorage.getItem("name")}
                                  </p>
                                </div>
                                <div style={{ textAlign: "center" }}>
                                  <p>Customer</p>
                                  <p>
                                    {data.customer &&
                                      `${data.customer.firstName} ${data.customer.middleName} ${data.customer.lastName}`}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </iframe>
                          <Button
                            style={{ marginLeft: 15 }}
                            disabled={isGrayOut || !el.isPayed}
                            onClick={() => {
                              // var content = document.getElementById(`rowNumber${key+1}`);
                              var pri = document.getElementById(
                                "ifmcontentstoprint"
                              ).contentWindow;
                              pri.document.open();
                              pri.document.write(
                                document.getElementById(`iframe${key}`)
                                  .innerHTML
                              );
                              pri.document.write("<br /><br /><hr /><br />");
                              pri.document.write(
                                document.getElementById(`iframe${key}`)
                                  .innerHTML
                              );
                              // pri.document.write(content.innerHTML);
                              pri.document.close();
                              pri.focus();
                              pri.print();
                            }}
                          >
                            print
                          </Button>

                          {!el.isPayed ? (isGrayOut = true) : null}
                        </td>
                      </tr>
                    );
                  })}
                {/* <tr>
                  <td>Dilivering Keys</td>
                  <td>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        type="number"
                        placeholder="Getting Keys Payment"
                        value={getKeys}
                        onChange={(e) => setGetKeys(parseInt(e.target.value))}
                      />
                    </InputGroup>
                  </td>
                  <td>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        type="number"
                        value={data.keyRecevePrice}
                        disabled={true}
                      />
                    </InputGroup>
                  </td>
                  <td colSpan="2"></td>
                </tr> */}
              </tbody>
            </Table>
          </Print>
        </div>
      </List>
    </PrintProvider>
  );
};
