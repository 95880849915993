import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  TopToolbar,
  sanitizeListRestProps,
  CreateButton,
  EditButton,
  regex,
  RadioButtonGroupInput,
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  DateInput,
  TextInput,
  Edit,
  AutocompleteInput,
  Show,
  SimpleShowLayout,
  NumberInput,
  required,
  useListContext,
  Pagination,
  Filter,
} from "react-admin";
import axios from "axios";
import moment from "moment";
import Button from "react-bootstrap/Button";
import { NoPrint } from "react-easy-print";
import "./invoice.css";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import swal from 'sweetalert';

const CustomerFilter = (props) => {
  return (
    <NoPrint>
      <Filter {...props}>
        <TextInput label="Phone" source="phone" />
        <TextInput label="Lead Source" source="HowCame" />
        <SelectInput source="state" defaultValue="New" choices={[
            { id: 'New', name: 'New' },
            { id: 'Qualified', name: 'Qualified' },
            { id: 'Discussion', name: 'Discussion' },
            { id: 'Negotiation', name: 'Negotiation' },
            { id: 'Won', name: 'Won' },
            { id: 'Lost', name: 'Lost' },
        ]} />
        <DateInput label="Start Date" source="startDate" />
        <DateInput label="End Date" source="endDate" />
      </Filter>
    </NoPrint>
  );
};
const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
  } = useListContext();
  return (
    <NoPrint>
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <Button
          onClick={() => {
            window.print();
          }}
        >
          Print Report
        </Button>
        {filters &&
          React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
          })}
        <CreateButton basePath={basePath} />
      </TopToolbar>
    </NoPrint>
  );
};
const PostPagination = (props) => (
  <NoPrint>
    <Pagination rowsPerPageOptions={[10, 25, 50, 100, 150]} {...props} />
    <Total props={props && props} />
  </NoPrint>
);

const Total = (props) => {
  let totals = props.props && props.props.data;
  let count = totals && Object.keys(totals).length;
  return null;
};

export const LeadCustomersList = (props) => {
  const role = localStorage.getItem("role");
  
  return (
    <List
    {...props}
    // pagination={<PostPagination />}
    filters={<CustomerFilter />}
    // actions={<ListActions />}
    >
    <Datagrid rowClick="show">
        <TextField source="name"/>
        <TextField source="address"/>
        <TextField source="phone"/>
        <TextField source="note"/>
        <TextField source="state"/>
        <TextField label="Lead Source" source="leadSource.name" />
        {(role === 'Admin' || role === 'Accountant') && <EditButton />}
    </Datagrid>
    </List>
  );
};

export const LeadCustomersShow = (props) => {
  const [visits, setVisists] = React.useState([]);

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
      .get(
        `${process.env.REACT_APP_URL}/LeadCustomers/${props.id}`
      )
      .then((resp) => setVisists(resp.data.leadVisits));
  }, [])
  console.log(visits)
  return (
    <div style={{display: 'flex'}}>
      <Show {...props} style={{flex: 8}}>
        <SimpleShowLayout>
          <TextField source="name"/>
          <TextField source="address"/>
          <TextField source="phone"/>
          <TextField source="note"/>
        </SimpleShowLayout>
      </Show>
      <div style={{flex: 5, marginLeft: 15, marginTop: 62}}>
        {visits.map((el, key) => {
          return <Card key={key} style={{marginBottom: 15}}>
            <CardHeader title={`${moment(el.visitDate).format('YYYY/MM/DD hh:mm')}`}/>
            <CardContent>
              <div>No. of Males : {el.males}</div>
              <div>No. of Females: {el.females}</div>
            </CardContent>
            <CardContent>
              <div>New State: {el.newState}</div>
              <div>Previouse State: {el.oldState}</div>
            </CardContent>
            <CardContent>Note: {el.note}</CardContent>
          </Card>
        })}
      </div>
    </div>
  )};

  const onFailure = (error) => {
    swal(`Could'nt Create, the most probably the phone number exists or is in a wrong format!`, {
      icon: 'error'
    })
};

export const LeadCustomersCreate = (props) => {
  return (
    <Create {...props} onFailure={onFailure}>
      <SimpleForm redirect="list">
      <div style={{display: 'flex', width: '100%'}}>
            <div style={{ marginRight: 55 }}>
                <TextInput source="name" fullWidth={true} validate={[required()]}/>
                <TextInput source="address" fullWidth={true} validate={[required()]}/>
                <TextInput source="phone" fullWidth={true} validate={[required(),regex(
                  /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                  "Invalid Phone Number!"
                )]}/>
                <TextInput source="note" multiline fullWidth={true} />
            </div>
            <div>
                <TextInput source="visitNote" multiline fullWidth={true}/>
                <ReferenceInput fullWidth={true} label="Lead Sources" source="leadSourceId" reference="LeadSources" validate={[required()]} perPage={1000}>
                    <AutocompleteInput optionText={record => `${record.name}`} />
                </ReferenceInput>
                <NumberInput source="males" validate={[required()]} fullWidth={true} />
                <NumberInput source="females" validate={[required()]} fullWidth={true} />
                <RadioButtonGroupInput
                    fullWidth={true}
                    source="isMale"
                    label="Gender"
                    validate={[required()]}
                    choices={[
                    { id: true, name: "Male" },
                    { id: false, name: "Female" },
                    ]}
                />
            </div>
        </div>
      </SimpleForm>
    </Create>
  );
};

export const LeadCustomersEdit = (props) => (
  <Edit {...props}>
    <SimpleForm redirect="list">
      <div style={{display: 'flex', width: '100%'}}>
            <div style={{ marginRight: 55 }}>
                <TextInput source="name" fullWidth={true} validate={[required()]}/>
                <TextInput source="address" fullWidth={true}/>
                <TextInput source="phone" fullWidth={true} validate={[required()]}/>
            </div>
            <div>
                <TextInput source="note" multiline fullWidth={true}/>
                {/* <SelectInput source="state" fullWidth={true} choices={[
                    { id: 'new', name: 'New' },
                ]} /> */}
                <ReferenceInput fullWidth={true} label="LeadSources" source="leadSourceId" reference="LeadSources" validate={[required()]}>
                    <SelectInput optionText="name" defaultValue={1}/>
                </ReferenceInput>
                <RadioButtonGroupInput
                    fullWidth={true}
                    source="isMale"
                    label="Gender"
                    validate={[required()]}
                    choices={[
                    { id: true, name: "Male" },
                    { id: false, name: "Female" },
                    ]}
                />
            </div>
        </div>
    </SimpleForm>
  </Edit>
);
