import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  TopToolbar,
  sanitizeListRestProps,
  DateInput,
  NumberField,
  DateField,
  useListContext,
  Pagination,
  Filter,
} from "react-admin";
import axios from "axios";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import { NoPrint } from "react-easy-print";
import "./invoice.css";


let startDate = ''
let endDate = ''
const CustomerFilter = (props) => {
  startDate = props.filterValues.startDate
  endDate = props.filterValues.endDate
  return (
    <Filter {...props}>
      <DateInput
        label="Start Date"
        source="startDate"
        //   defaultValue={moment().format("YYYY-MM-DD")}
        alwaysOn
      />
      <DateInput
        label="End Date"
        source="endDate"
        //   defaultValue="2020-12-12"
        alwaysOn
      />
    </Filter>
  );
};
const PostPagination = (props) => (
  <NoPrint>
    <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500]} {...props} />
  </NoPrint>
);

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  return (
    <NoPrint>
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <Button
          onClick={() => {
            window.print();
          }}
        >
          Print Report
        </Button>
        {filters &&
          React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
          })}
        {/* <CreateButton basePath={basePath} /> */}
      </TopToolbar>
    </NoPrint>
  );
};

const postRowStyle = (record, index) => ({
  backgroundColor: record.isLost ? "tomato" : "#efe ",
});

export const TransactionList = (props) => {
  const [total, setTotal] = React.useState(0);
  const [incomes, setIncomes] = React.useState(0);
  const [outcomes, setOutcomes] = React.useState(0);

  let lost = 0;
  let got = 0;
  React.useEffect(() => {
    setTotal(incomes - outcomes);
  }, [incomes, outcomes]);

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios.get(`${process.env.REACT_APP_URL}/Expenses/GetAllInfo?startDate=${startDate}&endDate=${endDate}`)
    .then(res => {
      setIncomes(res.headers.incomes)
      setOutcomes(res.headers.outcomes)
    })
  }, [props])

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div id="expenses">
      {/* <PrintProvider>
        <Print single={true} name="foo"> */}
        <div style={{display: 'flex', marginTop: 10}}>
        <InputGroup className="mb-3" style={{ marginTop: 10 }}>
        <InputGroup.Prepend>
          <InputGroup.Text id="basic-addon1">Income</InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          placeholder="Income"
          aria-label="Total"
          aria-describedby="basic-addon1"
          value={`$${numberWithCommas(incomes)}`}
          disabled={true}
        />
      </InputGroup>
      <InputGroup className="mb-3" style={{ marginTop: 10, marginLeft: 5 }}>
        <InputGroup.Prepend>
          <InputGroup.Text id="basic-addon1">Outcome</InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          placeholder="Outcome"
          aria-label="Total"
          aria-describedby="basic-addon1"
          value={`$${numberWithCommas(outcomes)}`}
          disabled={true}
        />
      </InputGroup>
      </div>
      <InputGroup className="mb-3" >
        <InputGroup.Prepend>
          <InputGroup.Text id="basic-addon1">Remain</InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          placeholder="Remain"
          aria-label="Total"
          aria-describedby="basic-addon1"
          value={`$${numberWithCommas(total)}`}
          disabled={true}
        />
      </InputGroup>
      <List
        {...props}
        // filterDefaultValues={{
        //   startDate: moment().format("YYYY-MM-DD"),
        //   endDate: moment().add(7, "days").format("DD-MM-YYYY"),
        // }}
        title="Transactions"
        // perPage={500}
        pagination={<PostPagination />}
        filters={<CustomerFilter />}
        actions={<ListActions />}
      >
        <Datagrid rowClick="show" rowStyle={postRowStyle}>
          <TextField source="detail" />
          <TextField source="contractNumber" label="Number"/>
          <DateField source="date" />
          {/* <FunctionField
            render={(record) => {
              record.isLost && (lost = lost + record.price);
              record.isLost && setNegative(lost);
            }}
          />
          <FunctionField
            render={(record) => {
              !record.isLost && (got = got + record.price);
              !record.isLost && setPositive(got);
            }}
          /> */}
          <NumberField
            source="price"
            options={{ style: "currency", currency: "USD" }}
          />
          <TextField source="type" />
        </Datagrid>
      </List>


      {/* </Print>
      </PrintProvider> */}
    </div>
  );
};
