import React, { useEffect, useState } from "react";
import "../invoice.css";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";

function IZone(props) {
  const [properties, setProperties] = useState([]);
  useEffect(() => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
      .get(process.env.REACT_APP_URL + "/Dashboard/I")
      .then((res) => {
        setProperties(res.data)
        props.setLoader()
      });
  }, []);

  const soldProperties = properties.filter(
    (property) => property.isSold === true
  ).length;
  const remainedProperties = properties.filter(
    (property) => property.isSold === false
  ).length;
  const holdedProperties = properties.filter(
    (property) => property.isHijz === true
  ).length;

  const role = localStorage.getItem("role");
  role !== 'Seller' && properties.map((el) => {
    document.getElementById(`${el.name}`) && document.getElementById(`${el.name}`).addEventListener("click", () => {
      el.isSold
        ? window.open(`#/BuyContracts/${el.contractId}/show`, "_self")
        : window.open(
            `#/Properties?filter=%7B"name"%3A"${el.name}"%7D&order=ASC&page=1&perPage=10&sort=id`,
            "_self"
          );
    });
  });

  properties
    .filter((el) => el.isSold === false)
    .map((el) => {
      document.getElementById(`${el.name}`) && (document.getElementById(`${el.name}`).style.fill = "#ffc107");
    });

  properties
    .filter((el) => el.isSold === true)
    .map((el) => {
      document.getElementById(`${el.name}`) && (document.getElementById(`${el.name}`).style.fill = "#17a2b8");
    });

  properties
    .filter((el) => el.isHijz === true)
    .map((el) => {
      document.getElementById(`${el.name}`) && (document.getElementById(`${el.name}`).style.fill = "#dc3545");
    });
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "80%",
        }}
      >
        <Button
          onClick={() => {
            props.resetZone();
          }}
        >
          Back
        </Button>
        <Button variant="info">
          Sold Properties <Badge variant="light">{soldProperties}</Badge>
          <span className="sr-only">unread messages</span>
        </Button>
        <Button variant="warning">
          Remained Properties{" "}
          <Badge variant="light">{remainedProperties}</Badge>
          <span className="sr-only">unread messages</span>
        </Button>
        <Button variant="danger">
          On Hold Properties <Badge variant="light">{holdedProperties}</Badge>
          <span className="sr-only">unread messages</span>
        </Button>
      </div>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 1024 1024"
        style={{ enableBackground: "new 0 0 1024 1024" }}
        xml="preserve"
      >
        <title>I-Last</title>
        <polygon
          id="I1"
          style={{ fill: "#E8F5FD" }}
          points="60.3,81.2 60.3,162.1 143.7,162.1 143.7,85.9 102,82.3 "
        />
        <rect
          id="I2"
          x="60.3"
          y="162.8"
          style={{ fill: "#E8F5FD" }}
          width="83.4"
          height="62.8"
        />
        <rect
          id="I3"
          x="60.3"
          y="225.6"
          style={{ fill: "#E8F5FD" }}
          width="83.4"
          height="62.8"
        />
        <rect
          id="I4"
          x="60.3"
          y="288.8"
          style={{ fill: "#E8F5FD" }}
          width="83.4"
          height="62.8"
        />
        <rect
          id="I5"
          x="60.3"
          y="352.1"
          style={{ fill: "#E8F5FD" }}
          width="83.4"
          height="62.8"
        />
        <rect
          id="I6"
          x="60.3"
          y="415.5"
          style={{ fill: "#E8F5FD" }}
          width="83.4"
          height="62.8"
        />
        <rect
          id="I7"
          x="60.3"
          y="478.6"
          style={{ fill: "#E8F5FD" }}
          width="83.4"
          height="62.8"
        />
        <rect
          id="I8"
          x="60.3"
          y="542.1"
          style={{ fill: "#E8F5FD" }}
          width="83.4"
          height="62.8"
        />
        <rect
          id="I9"
          x="60.3"
          y="605.2"
          style={{ fill: "#E8F5FD" }}
          width="83.4"
          height="62.8"
        />
        <rect
          id="I10"
          x="60.3"
          y="668.5"
          style={{ fill: "#E8F5FD" }}
          width="83.4"
          height="62.8"
        />
        <rect
          id="I11"
          x="292.2"
          y="880.8"
          style={{ fill: "#E8F5FD" }}
          width="62.9"
          height="83.4"
        />
        <rect
          id="I12"
          x="355.9"
          y="880.8"
          style={{ fill: "#E8F5FD" }}
          width="62.9"
          height="83.4"
        />
        <rect
          id="I13"
          x="418.7"
          y="880.8"
          style={{ fill: "#E8F5FD" }}
          width="62.9"
          height="83.4"
        />
        <rect
          id="I14"
          x="482.4"
          y="880.8"
          style={{ fill: "#E8F5FD" }}
          width="62.8"
          height="83.4"
        />
        <rect
          id="I15"
          x="545.2"
          y="880.8"
          style={{ fill: "#E8F5FD" }}
          width="62.8"
          height="83.4"
        />
        <rect
          id="I16"
          x="608.9"
          y="880.8"
          style={{ fill: "#E8F5FD" }}
          width="62.8"
          height="83.4"
        />
        <rect
          id="I17"
          x="671.9"
          y="880.8"
          style={{ fill: "#E8F5FD" }}
          width="62.8"
          height="83.4"
        />
        <rect
          id="I18"
          x="735.5"
          y="880.8"
          style={{ fill: "#E8F5FD" }}
          width="62.8"
          height="83.4"
        />
        <rect
          id="I19"
          x="798.5"
          y="880.8"
          style={{ fill: "#E8F5FD" }}
          width="62.8"
          height="83.4"
        />
        <polygon
          id="I20"
          style={{ fill: "#E8F5FD" }}
          points="862.2,964.2 942.8,964.2 941.3,919.2 937.5,880.8 862.2,880.8 "
        />
        <polygon
          id="I21"
          style={{ fill: "#E8F5FD" }}
          points="804.6,788.4 922.3,788.4 910.9,734.2 902.3,701.9 804.6,702 "
        />
        <rect
          id="I22"
          x="741.8"
          y="702"
          style={{ fill: "#E8F5FD" }}
          width="64"
          height="86.5"
        />
        <rect
          id="I23"
          x="677.6"
          y="702"
          style={{ fill: "#E8F5FD" }}
          width="64"
          height="86.5"
        />
        <rect
          id="I24"
          x="614.2"
          y="702"
          style={{ fill: "#E8F5FD" }}
          width="64"
          height="86.5"
        />
        <rect
          id="I25"
          x="551.9"
          y="702"
          style={{ fill: "#E8F5FD" }}
          width="63.4"
          height="86.5"
        />
        <rect
          id="I26"
          x="488.6"
          y="702"
          style={{ fill: "#E8F5FD" }}
          width="63.2"
          height="86.5"
        />
        <rect
          id="I27"
          x="424.7"
          y="702"
          style={{ fill: "#E8F5FD" }}
          width="64"
          height="86.5"
        />
        <rect
          id="I28"
          x="362.3"
          y="702"
          style={{ fill: "#E8F5FD" }}
          width="62.9"
          height="86.5"
        />
        <rect
          id="I29"
          x="299.1"
          y="702"
          style={{ fill: "#E8F5FD" }}
          width="63.1"
          height="86.5"
        />
        <rect
          id="I30"
          x="235.8"
          y="702"
          style={{ fill: "#E8F5FD" }}
          width="62.9"
          height="86.5"
        />
        <rect
          id="I31"
          x="235.8"
          y="619.1"
          style={{ fill: "#E8F5FD" }}
          width="62.8"
          height="82.8"
        />
        <rect
          id="I32"
          x="299.1"
          y="619.1"
          style={{ fill: "#E8F5FD" }}
          width="63.1"
          height="82.8"
        />
        <rect
          id="I33"
          x="362.2"
          y="619.1"
          style={{ fill: "#E8F5FD" }}
          width="62.9"
          height="82.8"
        />
        <rect
          id="I34"
          x="424.6"
          y="619.1"
          style={{ fill: "#E8F5FD" }}
          width="64"
          height="82.8"
        />
        <rect
          id="I35"
          x="488.6"
          y="619.1"
          style={{ fill: "#E8F5FD" }}
          width="63.2"
          height="82.8"
        />
        <rect
          id="I36"
          x="551.9"
          y="619.1"
          style={{ fill: "#E8F5FD" }}
          width="63.4"
          height="82.8"
        />
        <rect
          id="I37"
          x="614.2"
          y="619.1"
          style={{ fill: "#E8F5FD" }}
          width="64"
          height="82.8"
        />
        <rect
          id="I38"
          x="677.6"
          y="619.1"
          style={{ fill: "#E8F5FD" }}
          width="64"
          height="82.8"
        />
        <rect
          id="I39"
          x="741.7"
          y="619.1"
          style={{ fill: "#E8F5FD" }}
          width="64"
          height="82.8"
        />
        <polygon
          id="I40"
          style={{ fill: "#E8F5FD" }}
          points="805.7,701.9 902.3,701.9 886.3,657.4 870.7,619.1 805.7,619.1 "
        />
        <polygon
          id="I41"
          style={{ fill: "#E8F5FD" }}
          points="744.1,567.6 848.5,567.6 823.1,520.5 800.6,482.6 744.1,482.6 "
        />
        <rect
          id="I42"
          x="680.6"
          y="482.6"
          style={{ fill: "#E8F5FD" }}
          width="64.1"
          height="85"
        />
        <rect
          id="I43"
          x="616.9"
          y="482.6"
          style={{ fill: "#E8F5FD" }}
          width="64.1"
          height="85"
        />
        <rect
          id="I44"
          x="554.4"
          y="482.6"
          style={{ fill: "#E8F5FD" }}
          width="64.1"
          height="85"
        />
        <rect
          id="I45"
          x="491"
          y="482.6"
          style={{ fill: "#E8F5FD" }}
          width="64.1"
          height="85"
        />
        <rect
          id="I46"
          x="426.9"
          y="482.6"
          style={{ fill: "#E8F5FD" }}
          width="64.1"
          height="85"
        />
        <rect
          id="I47"
          x="364.4"
          y="482.6"
          style={{ fill: "#E8F5FD" }}
          width="64.1"
          height="85"
        />
        <rect
          id="I48"
          x="301.1"
          y="482.6"
          style={{ fill: "#E8F5FD" }}
          width="64.1"
          height="85"
        />
        <rect
          id="I49"
          x="237.7"
          y="482.6"
          style={{ fill: "#E8F5FD" }}
          width="64.1"
          height="85"
        />
        <rect
          id="I50"
          x="237.6"
          y="398.3"
          style={{ fill: "#E8F5FD" }}
          width="64.1"
          height="85"
        />
        <rect
          id="I51"
          x="301.1"
          y="398.3"
          style={{ fill: "#E8F5FD" }}
          width="64.1"
          height="85"
        />
        <rect
          id="I52"
          x="364.3"
          y="398.3"
          style={{ fill: "#E8F5FD" }}
          width="64.1"
          height="85"
        />
        <rect
          id="I53"
          x="426.9"
          y="398.3"
          style={{ fill: "#E8F5FD" }}
          width="64.1"
          height="85"
        />
        <rect
          id="I54"
          x="491"
          y="398.3"
          style={{ fill: "#E8F5FD" }}
          width="64.1"
          height="85"
        />
        <rect
          id="I55"
          x="554.4"
          y="398.3"
          style={{ fill: "#E8F5FD" }}
          width="64.1"
          height="85"
        />
        <rect
          id="I56"
          x="616.9"
          y="398.3"
          style={{ fill: "#E8F5FD" }}
          width="64.1"
          height="85"
        />
        <path
          id="I57"
          style={{ fill: "#E8F5FD" }}
          d="M681.6,483.3h119c0,0-21.4-34.1-34.8-50.3c-7.1-8.6-28.2-34.6-28.2-34.6h-56.1V483.3z"
        />
        <path
          id="I58"
          style={{ fill: "#E8F5FD" }}
          d="M554.9,348.2h137c0,0-44.5-45.1-68.5-63.1c-21.7-17.4-68.5-51.6-68.5-51.6L554.9,348.2z"
        />
        <rect
          id="I59"
          x="491.5"
          y="263.2"
          style={{ fill: "#E8F5FD" }}
          width="63.1"
          height="85"
        />
        <rect
          id="I60"
          x="428.2"
          y="263.2"
          style={{ fill: "#E8F5FD" }}
          width="63.1"
          height="85"
        />
        <rect
          id="I61"
          x="364.9"
          y="263.2"
          style={{ fill: "#E8F5FD" }}
          width="63.1"
          height="85"
        />
        <rect
          id="I62"
          x="301.6"
          y="263.2"
          style={{ fill: "#E8F5FD" }}
          width="63.1"
          height="85"
        />
        <rect
          id="I63"
          x="238.6"
          y="263.2"
          style={{ fill: "#E8F5FD" }}
          width="63.1"
          height="85"
        />
        <rect
          id="I64"
          x="238.6"
          y="200.7"
          style={{ fill: "#E8F5FD" }}
          width="126.6"
          height="63.1"
        />
        <polygon
          id="I65"
          style={{ fill: "#E8F5FD" }}
          points="238.5,200.1 301.6,200.1 301.4,112.6 268.3,104.7 238.4,98.8 "
        />
        <polygon
          id="I66"
          style={{ fill: "#E8F5FD" }}
          points="301.6,200.1 364.6,200.1 364.6,133.1 333.1,122.3 301.6,113.2 "
        />
        <polygon
          id="I67"
          style={{ fill: "#E8F5FD" }}
          points="364.8,262.8 427.9,262.8 427.9,160 364.8,133.1 "
        />
        <path
          id="I68"
          style={{ fill: "#E8F5FD" }}
          d="M428.2,262.8h126.9v-29.3c0,0-47-30.4-63.2-39.8c-15.6-9-63.7-33.6-63.7-33.6L428.2,262.8z"
        />
        <g style={{ opacity: 0.69 }}>
          <g id="_1">
            <rect
              x="60"
              y="732.5"
              style={{ fill: "#139B48" }}
              width="83.7"
              height="7.1"
            />
            <rect x="59.5" y="80.4" width="1.3" height="652.5" />
            <path
              style={{
                fill: "none",
                stroke: "#000000",
                strokeWidth: 0.5,
                strokeMiterlimit: 10,
              }}
              d="M60,812.8h83.1c5.5,0,9.9-4.4,9.9-9.9l0,0V83c0-10.2-7.8-18.7-18-19.5c-22.2-1.6-58.9-4.1-74.8-4.1"
            />
            <path
              style={{
                fill: "none",
                stroke: "#000000",
                strokeWidth: 0.5,
                strokeMiterlimit: 10,
              }}
              d="M59.8,790.9h83.9V86.1c0,0-32.5-4.9-83.6-4.9"
            />
            <rect x="60" y="161.5" width="83.7" height="1.3" />
            <rect x="60" y="225" width="83.7" height="1.3" />
            <rect x="60" y="288.2" width="83.7" height="1.3" />
            <rect x="60" y="351.4" width="83.7" height="1.3" />
            <rect x="60" y="414.8" width="83.7" height="1.3" />
            <rect x="60" y="478" width="83.7" height="1.3" />
            <rect x="60" y="541.4" width="83.7" height="1.3" />
            <rect x="60" y="604.6" width="83.7" height="1.3" />
            <rect x="60" y="667.9" width="83.7" height="1.3" />
            <rect x="60" y="731.5" width="83.7" height="1.3" />
            <rect x="60" y="739.3" width="83.7" height="1.3" />
          </g>
          <g id="_1-2">
            <rect
              x="284.3"
              y="880.7"
              style={{ fill: "#139B48" }}
              width="7.1"
              height="83.8"
            />
            <path
              style={{
                fill: "none",
                stroke: "#000000",
                strokeWidth: 0.5,
                strokeMiterlimit: 10,
              }}
              d="M964.5,964.5c0-15.9-2.5-53.1-4.1-75.5c-0.7-10.2-9.2-18.1-19.4-18.1h-720c-5.5,0-9.9,4.4-9.9,9.9l0,0v83.6"
            />
            <path
              style={{
                fill: "none",
                stroke: "#000000",
                strokeWidth: 0.5,
                strokeMiterlimit: 10,
              }}
              d="M233.1,880.7v82.8c0,0,628.3,0.6,709.7,1c0-28-1.6-56-4.9-83.8L233.1,880.7z"
            />
            <rect x="861.2" y="880.7" width="1.3" height="83.4" />
            <rect x="797.7" y="880.7" width="1.3" height="83.4" />
            <rect x="734.5" y="880.7" width="1.3" height="83.4" />
            <rect x="671.2" y="880.7" width="1.3" height="83.4" />
            <rect x="607.8" y="880.7" width="1.3" height="83.4" />
            <rect x="544.7" y="880.7" width="1.3" height="83.4" />
            <rect x="481.2" y="880.7" width="1.3" height="83.4" />
            <rect x="418.1" y="880.7" width="1.3" height="83.4" />
            <rect x="354.8" y="880.7" width="1.3" height="83.4" />
            <rect x="291.1" y="880.7" width="1.3" height="83.8" />
            <rect x="283.3" y="880.7" width="1.3" height="83.8" />
          </g>
          <g id="B.H3">
            <path
              style={{
                fill: "none",
                stroke: "#000000",
                strokeWidth: 0.5,
                strokeMiterlimit: 10,
              }}
              d="M929.5,795.9h-693c-5.4,0-9.7-4.4-9.7-9.7V618.1c0-5.4,4.4-9.7,9.7-9.7h637.6c11.4,0,21.6,7.2,25.4,18
			c7.6,21.8,19.9,57.5,24.5,72.9c4.8,16,14.7,53.6,20.7,76.9c2.2,8.4-2.9,17.1-11.3,19.3C932.2,795.7,930.9,795.9,929.5,795.9z"
            />
            <path
              style={{
                fill: "none",
                stroke: "#000000",
                strokeWidth: 0.5,
                strokeMiterlimit: 10,
              }}
              d="M922.3,789H235.8V618.9h635.1c0,0,25,61.2,31.3,82C908.8,722.6,922.3,789,922.3,789z"
            />
            <rect x="298" y="618.7" width="1.3" height="170.2" />
            <rect x="361.4" y="618.7" width="1.3" height="170.2" />
            <rect x="424.5" y="618.8" width="1.3" height="170.2" />
            <rect x="487.9" y="618.8" width="1.3" height="170.2" />
            <rect x="551.3" y="618.8" width="1.3" height="170.2" />
            <rect x="614.6" y="618.8" width="1.3" height="170.2" />
            <rect x="677.7" y="618.9" width="1.3" height="170.2" />
            <rect x="740.9" y="618.9" width="1.3" height="170.2" />
            <rect x="804.4" y="618.9" width="1.3" height="170.2" />
            <rect x="235.2" y="701.2" width="667.2" height="1.3" />
          </g>
          <g id="B.H2">
            <path
              style={{
                fill: "none",
                stroke: "#000000",
                strokeWidth: 0.5,
                strokeMiterlimit: 10,
              }}
              d="M864,575.1H237.4c-4.5,0-8.2-3.7-8.2-8.2c0,0,0,0,0,0v-169c0-4.5,3.7-8.2,8.2-8.2c0,0,0,0,0,0h506.2
			c10.4,0,20.1,5.3,25.7,14.1c0,0,38.8,50.5,50.3,68.3c14.1,21.9,51,91,51,91c2.3,3.7,1.2,8.5-2.5,10.8
			C866.9,574.7,865.5,575.1,864,575.1z"
            />
            <path
              style={{
                fill: "none",
                stroke: "#000000",
                strokeWidth: 0.5,
                strokeMiterlimit: 10,
              }}
              d="M848.5,567.5H237.6V398.2h500.3c0,0,32.2,37,59.9,80.1C822.5,516.5,848.5,567.5,848.5,567.5z"
            />
            <rect x="300.4" y="398.3" width="1.3" height="169.1" />
            <rect x="363.8" y="398.3" width="1.3" height="169.1" />
            <rect x="427.1" y="398.3" width="1.3" height="169.1" />
            <rect x="490.3" y="398.3" width="1.3" height="169.1" />
            <rect x="553.7" y="398.3" width="1.3" height="169.1" />
            <rect x="616.8" y="398.3" width="1.3" height="169.1" />
            <rect x="680.3" y="398.3" width="1.3" height="169.1" />
            <rect x="743.4" y="482.7" width="1.3" height="84.7" />
            <polygon points="800.2,482 800.6,483.3 237.6,483.3 237.6,482 		" />
          </g>
          <g id="B.H1">
            <path
              style={{
                fill: "none",
                stroke: "#000000",
                strokeWidth: 0.5,
                strokeMiterlimit: 10,
              }}
              d="M691.9,348.1H238.6V98.8C507.3,146,691.9,348.1,691.9,348.1z"
            />
            <path
              style={{
                fill: "none",
                stroke: "#000000",
                strokeWidth: 0.5,
                strokeMiterlimit: 10,
              }}
              d="M708,357.3H241.9c-7.1,0-12.8-5.7-12.8-12.8V92.3c0-7.1,5.8-12.8,12.8-12.8c0.8,0,1.7,0.1,2.5,0.2
			c236.7,46.7,412.9,198.4,472.5,255.5c5.1,4.9,5.3,13,0.4,18.1C714.9,355.9,711.5,357.3,708,357.3z"
            />
            <rect x="300.8" y="112.6" width="1.3" height="88.2" />
            <rect x="238.1" y="199.5" width="126.2" height="1.3" />
            <rect x="364" y="133.1" width="1.3" height="214.7" />
            <rect x="300.8" y="263.7" width="1.3" height="84.1" />
            <rect x="238.4" y="262.8" width="316.1" height="1.3" />
            <polygon points="427.4,347.8 428.7,346.1 428.7,160 427.4,160 		" />
            <polygon points="553.9,347.8 555.2,347.8 555.2,234.2 553.9,233.4 		" />
            <polygon points="490.6,347.8 492,347.8 492,264 490.6,263.4 		" />
          </g>
          <path
            style={{
              fill: "#129B48",
              stroke: "#000000",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M191,460.5L191,460.5c-2.8,0-5.1-2.3-5.1-5.1V92.6c0-2.8,2.3-5.1,5.1-5.1h0c2.8,0,5.1,2.3,5.1,5.1v362.8
		C196.1,458.2,193.8,460.5,191,460.5z"
          />
          <path
            style={{
              fill: "#129B48",
              stroke: "#000000",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M191,779.7L191,779.7c-2.8,0-5.1-2.3-5.1-5.1V505.8c0-2.8,2.3-5.1,5.1-5.1h0c2.8,0,5.1,2.3,5.1,5.1v268.8
		C196.1,777.4,193.8,779.7,191,779.7z"
          />
          <path
            style={{
              fill: "#129B48",
              stroke: "#000000",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M563.8,833.3L563.8,833.3c0-2.8,2.3-5.1,5.1-5.1h362.8c2.8,0,5.1,2.3,5.1,5.1v0c0,2.8-2.3,5.1-5.1,5.1H568.9
		C566.1,838.4,563.8,836.1,563.8,833.3z"
          />
          <path
            style={{
              fill: "#129B48",
              stroke: "#000000",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M244.4,833.3L244.4,833.3c0-2.8,2.3-5.1,5.1-5.1h269c2.8,0,5.1,2.3,5.1,5.1v0c0,2.8-2.3,5.1-5.1,5.1h-269
		C246.6,838.4,244.4,836.1,244.4,833.3z"
          />
          <line
            style={{
              fill: "none",
              stroke: "#000000",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            x1="862.5"
            y1="593.1"
            x2="229.2"
            y2="593.1"
          />
          <line
            style={{
              fill: "none",
              stroke: "#000000",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            x1="713.7"
            y1="376.5"
            x2="229.2"
            y2="376.5"
          />
        </g>
        <path
          d="M467.3,222.4v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.6,0.3-1.6,2.1v8.3
	c0,1.8,0.1,1.9,1.6,2.1v0.6H467.3z"
        />
        <path
          d="M475.4,222.6c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
	C476.7,221.9,476.2,222.5,475.4,222.6C475.4,222.6,475.4,222.6,475.4,222.6z"
        />
        <path
          d="M481.3,214.4c0.4-0.2,0.8-0.4,1.2-0.4c2,0,3.3,1.7,3.3,3.9c0,2.6-1.6,4.7-3.9,4.7s-3.9-2-3.9-5.3c-0.1-3.4,1.9-6.5,5.1-7.9
	c0.7-0.2,1.5-0.4,2.2-0.6l0.1,0.7c-0.7,0.2-1.4,0.4-2,0.7c-1.8,1-3,2.8-3.3,4.8L481.3,214.4z M481.7,215.3c-0.6,0-1.2,0.2-1.7,0.5
	c-0.1,0.5-0.1,1-0.1,1.5c0,2.7,0.9,4.6,2.4,4.6s1.8-1.7,1.8-3.1C484,216.9,483.3,215.3,481.7,215.3z"
        />
        <path
          d="M491,208.9c1.7,0,3,1.3,3.1,2.9c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.2-0.5,1.7c-0.5,0.5-1,0.9-1.5,1.3c1.3,0.8,2.5,1.8,2.5,3.6
	c0,2.1-1.6,3.8-3.7,3.9c0,0,0,0-0.1,0c-1.9,0.1-3.5-1.3-3.6-3.1c0-0.1,0-0.3,0-0.4c0-0.8,0.3-1.6,0.8-2.1c0.5-0.5,1.1-0.9,1.7-1.3
	c-1.2-0.7-2-1.9-2-3.3C487.6,210.5,489.1,209,491,208.9C491,208.9,491,208.9,491,208.9z M491,221.9c1.2,0,1.9-0.9,1.9-2.4
	s-1.2-2.5-2.7-3.3c-0.9,0.6-1.5,1.7-1.4,2.8C488.8,220.9,489.9,221.9,491,221.9L491,221.9z M490.8,209.7c-1.1,0-1.6,1-1.6,2.1
	c0,1.5,0.9,2.1,2.3,3c0.8-0.6,1.2-1.6,1.1-2.6C492.6,210.4,491.7,209.7,490.8,209.7L490.8,209.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M457.4,246.3c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L457.4,246.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M464.3,240.3v1.2c0,1,0.1,1.1,1.2,1.2v0.5h-3.9v-0.5c1.2-0.1,1.3-0.2,1.3-1.3v-1.2h-4.2v-0.5
	c1.5-2.2,3.3-4.6,4.8-6.7h0.8v6.4h1.5v0.8H464.3z M462.9,234.9c-1,1.4-2.1,3-3.2,4.6h3.2V234.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M471.7,238.6l-1,0.5c-0.3,0.2-0.6,0.2-1,0.3c-1.6,0-2.9-1.2-2.9-2.8c0-0.1,0-0.1,0-0.2c0-1.8,1.5-3.3,3.3-3.3
	c0,0,0,0,0,0c2.1,0,3.3,1.8,3.3,4c0,2.7-1.6,5.1-4.1,6c-0.6,0.2-1.3,0.4-2,0.4l-0.1-0.5C469.5,242.6,471.3,240.9,471.7,238.6z
	 M469.9,233.6c-1.2,0-1.6,1.1-1.6,2.3c0,1.4,0.8,2.6,2.1,2.6c0.5,0,0.9-0.2,1.3-0.4c0-0.4,0.1-0.7,0.1-1.1
	C471.8,235.6,471.4,233.6,469.9,233.6L469.9,233.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M478,233.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S475.5,233.1,478,233.1z M478,233.6
	c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S479.3,233.6,478,233.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M491.2,243.2v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.7-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L491.2,243.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M502.3,241c-0.2,0.8-0.5,1.6-0.6,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1-1.2,1.9-2.5,1.9-3.8
	s-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L502.3,241z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M503.8,231.5c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L503.8,231.5z"
        />
        <path
          d="M316.9,160v-0.6c1.5-0.2,1.6-0.3,1.6-2.1V149c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.6,0.3-1.6,2.1v8.3
	c0,1.8,0.1,1.9,1.6,2.1v0.6H316.9z"
        />
        <path
          d="M324.9,160.3c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
	C326.2,159.6,325.7,160.2,324.9,160.3C324.9,160.3,324.9,160.3,324.9,160.3z"
        />
        <path
          d="M330.8,152.1c0.4-0.2,0.8-0.4,1.2-0.4c2,0,3.3,1.7,3.3,3.9c0,2.6-1.6,4.7-3.9,4.7s-3.9-2.1-3.9-5.3
	c-0.1-3.4,1.9-6.5,5.1-7.9c0.7-0.2,1.4-0.4,2.2-0.6l0.1,0.7c-0.7,0.2-1.4,0.4-2,0.7c-1.8,1-3,2.8-3.3,4.8L330.8,152.1z M331.2,152.9
	c-0.6,0-1.2,0.2-1.7,0.5c-0.1,0.5-0.1,1-0.1,1.5c0,2.7,0.9,4.6,2.3,4.6s1.8-1.7,1.8-3.1C333.5,154.6,332.8,153,331.2,152.9
	L331.2,152.9z"
        />
        <path
          d="M339.8,152.1c0.4-0.2,0.8-0.4,1.2-0.4c2,0,3.3,1.7,3.3,3.9c0,2.6-1.6,4.7-3.9,4.7s-3.9-2.1-3.9-5.3
	c-0.1-3.4,1.9-6.5,5.1-7.9c0.7-0.2,1.5-0.4,2.2-0.6l0.1,0.7c-0.7,0.2-1.4,0.4-2,0.7c-1.8,1-3,2.8-3.3,4.8L339.8,152.1z M340.2,152.9
	c-0.6,0-1.2,0.2-1.7,0.5c-0.1,0.5-0.1,1-0.1,1.5c0,2.7,0.9,4.6,2.4,4.6s1.8-1.7,1.8-3.1C342.5,154.6,341.8,153,340.2,152.9
	L340.2,152.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M306.9,184c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.4,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L306.9,184z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M315.2,178.7c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.3,1.9-3.8
	c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L315.2,178.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M323.2,171.1c-1.5,3.2-3,6.5-4.6,9.8l-1.2,0.1l-0.1-0.2c1.7-2.8,3.3-5.7,4.7-8.7h-3.4c-1.1,0-1.1,0.1-1.6,1.2
	h-0.5c0.2-1,0.2-1.8,0.3-2.5h6.3L323.2,171.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M327.5,170.7c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S325,170.7,327.5,170.7z M327.5,171.3
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S328.9,171.3,327.5,171.3L327.5,171.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M340.7,180.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.1,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.7-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L340.7,180.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M351.8,178.7c-0.2,0.7-0.5,1.6-0.6,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.7-2.3,1.9-3.8
	c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L351.8,178.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M353.4,169.2c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L353.4,169.2z"
        />
        <path
          d="M256.5,160v-0.6c1.5-0.2,1.6-0.3,1.6-2.1V149c0-1.8-0.1-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.6,0.3-1.6,2.1v8.3
	c0,1.8,0.1,1.9,1.6,2.1v0.6H256.5z"
        />
        <path
          d="M264.5,160.3c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
	C265.8,159.6,265.3,160.2,264.5,160.3C264.6,160.3,264.5,160.3,264.5,160.3z"
        />
        <path
          d="M270.4,152.1c0.4-0.2,0.8-0.4,1.2-0.4c2,0,3.3,1.7,3.3,3.9c0,2.6-1.6,4.7-3.9,4.7s-3.9-2.1-3.9-5.3
	c-0.1-3.4,1.9-6.5,5.1-7.9c0.7-0.2,1.5-0.4,2.2-0.6l0.1,0.7c-0.7,0.2-1.4,0.4-2,0.7c-1.8,1-3,2.8-3.3,4.8L270.4,152.1z M270.8,152.9
	c-0.6,0-1.2,0.2-1.7,0.5c-0.1,0.5-0.1,1-0.1,1.5c0,2.7,0.9,4.6,2.4,4.6s1.8-1.7,1.8-3.1C273.2,154.6,272.4,153,270.8,152.9
	L270.8,152.9z"
        />
        <path
          d="M283.1,146.9l0.1,0.2l-0.5,1.5h-4.3c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.5-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4
	c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.9-2.1,1.4-3.3,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.2-0.2-0.4-0.5-0.3-0.7
	c0-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2
	c-0.6,0-1.3,0.1-1.9,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4H283.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M246.5,184c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L246.5,184z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M248.4,172.7l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.1,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.6
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.4,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L248.4,172.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M256.2,172.7l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.2,0.9c1.5,0.3,2.4,1.3,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.6
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L256.2,172.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M267.1,170.7c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S264.6,170.7,267.1,170.7z M267.1,171.3
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S268.5,171.3,267.1,171.3L267.1,171.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M280.3,180.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.1,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.7-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L280.3,180.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M291.4,178.7c-0.2,0.7-0.5,1.6-0.6,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
	c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L291.4,178.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M293,169.2c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L293,169.2z"
        />
        <path
          d="M284.9,222.4v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.6,0.3-1.6,2.1v8.3
	c0,1.8,0.1,1.9,1.6,2.1v0.6H284.9z"
        />
        <path
          d="M293,222.6c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
	C294.3,221.9,293.8,222.5,293,222.6C293.1,222.6,293,222.6,293,222.6z"
        />
        <path
          d="M298.9,214.4c0.4-0.2,0.8-0.4,1.2-0.4c2,0,3.3,1.7,3.3,3.9c0,2.6-1.6,4.7-3.9,4.7s-3.9-2-3.9-5.3c-0.1-3.4,1.9-6.5,5.1-7.9
	c0.7-0.2,1.5-0.4,2.2-0.6l0.1,0.7c-0.7,0.2-1.4,0.4-2,0.7c-1.8,1-3,2.7-3.3,4.7L298.9,214.4z M299.3,215.3c-0.6,0-1.2,0.2-1.7,0.5
	c-0.1,0.5-0.1,1-0.1,1.5c0,2.7,0.9,4.6,2.4,4.6s1.8-1.7,1.8-3.1C301.6,216.9,300.9,215.3,299.3,215.3z"
        />
        <path
          d="M310.8,218.5v1.6c0,1.4,0.1,1.5,1.4,1.6v0.6h-4.5v-0.6c1.4-0.1,1.5-0.3,1.5-1.7v-1.5h-4.8v-0.7c1.8-2.9,3.7-6,5.5-8.9h0.9
	v8.5h1.8v1.1H310.8z M309.2,211.3c-1.1,1.9-2.4,4-3.6,6.1h3.6V211.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M275,246.3c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L275,246.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M281.9,240.3v1.2c0,1,0.1,1.1,1.2,1.2v0.5h-3.9v-0.5c1.2-0.1,1.3-0.2,1.3-1.3v-1.2h-4.2v-0.5
	c1.6-2.2,3.3-4.6,4.8-6.7h0.8v6.4h1.6v0.8H281.9z M280.5,234.9c-1,1.4-2.1,3-3.2,4.6h3.2V234.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M290.4,233.2l0.1,0.1l-0.4,1.1h-3.7c-0.1,0.8-0.4,2.1-0.5,2.8c0.6-0.2,1.3-0.4,1.9-0.5c1.5-0.1,2.9,1,3,2.6
	c0,0,0,0.1,0,0.1c0,1.1-0.5,2.1-1.4,2.7c-0.8,0.7-1.8,1.1-2.9,1.1c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.2-0.3-0.4-0.3-0.6
	c0-0.2,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.6,1.2,0.9,2,0.9c1.3,0,1.9-1.1,1.9-2.3c0.1-1.3-0.9-2.4-2.2-2.4
	c-0.1,0-0.3,0-0.4,0c-0.5,0-1.1,0.1-1.6,0.3V238c0.3-1.3,0.6-3.5,0.8-4.8L290.4,233.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M295.6,233.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S293.1,233.1,295.6,233.1z M295.6,233.6
	c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S296.9,233.6,295.6,233.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M308.8,243.2v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5H300v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.8-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L308.8,243.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M319.9,241c-0.2,0.8-0.5,1.6-0.6,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
	c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L319.9,241z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M321.4,231.5c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c3.5-4,3.5-10,0-14L321.4,231.5z"
        />
        <path
          d="M382.1,222.4v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.1-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
	c0,1.8,0.1,1.9,1.7,2.1v0.6H382.1z"
        />
        <path
          d="M390.1,222.6c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
	C391.4,221.9,390.9,222.6,390.1,222.6C390.2,222.6,390.2,222.6,390.1,222.6z"
        />
        <path
          d="M396.1,214.4c0.4-0.2,0.8-0.4,1.2-0.4c2,0,3.3,1.7,3.3,3.9c0,2.6-1.6,4.7-3.9,4.7s-3.9-2-3.9-5.3c-0.1-3.4,1.9-6.5,5.1-7.9
	c0.7-0.2,1.4-0.4,2.2-0.6l0.1,0.7c-0.7,0.2-1.4,0.4-2,0.7c-1.8,1-3,2.8-3.3,4.8L396.1,214.4z M396.4,215.3c-0.6,0-1.2,0.2-1.7,0.5
	c-0.1,0.5-0.1,1-0.1,1.5c0,2.7,0.9,4.6,2.4,4.6s1.8-1.7,1.8-3.1C398.8,216.9,398,215.3,396.4,215.3z"
        />
        <path
          d="M409.7,209.5c-1.8,4.3-3.5,8.6-5.3,12.9l-1.4,0.2l-0.1-0.2c1.9-3.6,3.9-7.9,5.4-11.4h-3.9c-1.2,0-1.3,0.2-1.8,1.6H402
	c0.2-1.3,0.3-2.3,0.4-3.3h7.2L409.7,209.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M372.1,246.3c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.4,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L372.1,246.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M379.1,240.3v1.2c0,1,0.1,1.1,1.2,1.2v0.5h-3.9v-0.5c1.2-0.1,1.3-0.2,1.3-1.3v-1.2h-4.2v-0.5
	c1.5-2.2,3.3-4.6,4.8-6.7h0.8v6.4h1.6v0.8H379.1z M377.7,234.9c-1,1.4-2.1,3-3.2,4.6h3.2V234.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M382.4,243.2v-0.5c1.8-0.1,1.9-0.2,1.9-1.4v-6.1c0-0.8-0.1-0.9-0.8-0.9l-0.7-0.1v-0.4c1-0.1,2.1-0.4,3-0.8v8.3
	c0,1.2,0.1,1.3,1.9,1.4v0.5L382.4,243.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M392.7,233.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S390.3,233.1,392.7,233.1z M392.7,233.6
	c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S394.1,233.6,392.7,233.6L392.7,233.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M406,243.2v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.8-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L406,243.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M417.1,241c-0.2,0.8-0.5,1.6-0.6,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
	c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L417.1,241z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M418.6,231.5c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L418.6,231.5z"
        />
        <path
          d="M382.1,303.1v-0.6c1.5-0.2,1.6-0.3,1.6-2.1V292c0-1.8-0.1-1.9-1.6-2.1v-0.6h5.1v0.7c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
	c0,1.8,0.1,1.9,1.7,2.1v0.6L382.1,303.1z"
        />
        <path
          d="M390.1,303.4c-0.7,0-1.2-0.6-1.2-1.3c0,0,0-0.1,0-0.1c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4
	c0,0,0,0,0,0C391.4,302.6,390.9,303.3,390.1,303.4C390.2,303.3,390.2,303.3,390.1,303.4z"
        />
        <path
          d="M396.1,295.1c0.4-0.2,0.8-0.3,1.2-0.4c2,0,3.3,1.7,3.3,3.9c0,2.6-1.6,4.7-3.9,4.7s-3.9-2-3.9-5.3c-0.1-3.4,1.9-6.5,5.1-7.9
	c0.7-0.2,1.4-0.4,2.2-0.6l0.1,0.7c-0.7,0.2-1.4,0.4-2,0.7c-1.8,1-3,2.8-3.3,4.8L396.1,295.1z M396.4,296c-0.6,0-1.2,0.2-1.7,0.5
	c-0.1,0.5-0.1,1-0.1,1.5c0,2.7,0.9,4.5,2.4,4.5s1.8-1.7,1.8-3.1C398.8,297.6,398,296,396.4,296L396.4,296z"
        />
        <path
          d="M402.8,303.1v-0.6c2-0.1,2.1-0.3,2.1-1.8v-8c0-1.1-0.2-1.1-1-1.2l-0.8-0.1v-0.6c1.2-0.2,2.4-0.5,3.5-1v11
	c0,1.6,0.1,1.7,2.1,1.8v0.6L402.8,303.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M372.1,327c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.4,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L372.1,327z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M374,315.7l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.8,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.1,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c-0.1,0-0.1,0-0.2,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L374,315.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M384.9,313.8c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S382.4,313.8,384.9,313.8z M384.9,314.3
	c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S386.3,314.3,384.9,314.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M392.7,313.8c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S390.3,313.8,392.7,313.8z M392.7,314.3
	c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S394.1,314.3,392.7,314.3L392.7,314.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M406,323.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.8-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L406,323.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M417.1,321.7c-0.2,0.8-0.5,1.6-0.6,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
	c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L417.1,321.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M418.6,312.2c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L418.6,312.2z"
        />
        <path
          d="M319.5,303.1v-0.6c1.5-0.2,1.6-0.3,1.6-2.1V292c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.7c-1.5,0.2-1.6,0.3-1.6,2.1v8.3
	c0,1.8,0.1,1.9,1.6,2.1v0.6L319.5,303.1z"
        />
        <path
          d="M327.5,303.4c-0.7,0-1.2-0.6-1.2-1.3c0,0,0-0.1,0-0.1c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0,0c0.7,0,1.3,0.7,1.2,1.4
	c0,0,0,0,0,0C328.8,302.6,328.3,303.3,327.5,303.4C327.6,303.3,327.5,303.3,327.5,303.4z"
        />
        <path
          d="M333.4,295.1c0.4-0.2,0.8-0.3,1.2-0.4c2,0,3.3,1.7,3.3,3.9c0,2.6-1.6,4.7-3.9,4.7s-3.9-2-3.9-5.3c-0.1-3.4,1.9-6.5,5.1-7.9
	c0.7-0.2,1.4-0.4,2.2-0.6l0.1,0.7c-0.7,0.2-1.4,0.4-2,0.7c-1.8,1-3,2.8-3.3,4.8L333.4,295.1z M333.8,296c-0.6,0-1.2,0.2-1.7,0.5
	c-0.1,0.5-0.1,1-0.1,1.5c0,2.7,0.9,4.5,2.3,4.5s1.8-1.7,1.8-3.1C336.2,297.6,335.4,296,333.8,296L333.8,296z"
        />
        <path
          d="M346.9,300.1c-0.3,1-0.6,2.1-0.8,2.9h-7v-0.5c1-1.1,2.1-2.4,3-3.6c1.2-1.6,2.2-3.3,2.2-5.1s-0.8-2.7-2.1-2.7s-2,1.1-2.5,2
	l-0.5-0.5l1.1-1.8c0.6-0.8,1.6-1.3,2.6-1.3c1.8,0,3.2,1.4,3.2,3.7c0,1.8-0.6,3-2.3,5.1c-0.8,0.9-1.9,2.2-2.7,3h3.4
	c1,0,1.3-0.1,1.9-1.4L346.9,300.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M309.5,327c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L309.5,327z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M311.4,315.7l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c-0.1,0-0.1,0-0.2,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L311.4,315.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M322.3,313.8c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S319.8,313.8,322.3,313.8z M322.3,314.3
	c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S323.6,314.3,322.3,314.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M330.1,313.8c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S327.6,313.8,330.1,313.8z M330.1,314.3
	c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S331.5,314.3,330.1,314.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M343.3,323.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.8-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L343.3,323.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M354.4,321.7c-0.2,0.8-0.5,1.6-0.6,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
	c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L354.4,321.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M356,312.2c4.1,3.1,4.9,9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c3.5-4,3.5-10,0-14L356,312.2z"
        />
        <path
          d="M256.5,303.1v-0.6c1.5-0.2,1.6-0.3,1.6-2.1V292c0-1.8-0.1-1.9-1.6-2.1v-0.6h5.1v0.7c-1.5,0.2-1.6,0.3-1.6,2.1v8.3
	c0,1.8,0.1,1.9,1.6,2.1v0.6L256.5,303.1z"
        />
        <path
          d="M264.5,303.4c-0.7,0-1.2-0.6-1.2-1.3c0,0,0-0.1,0-0.1c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4
	c0,0,0,0,0,0C265.8,302.6,265.3,303.3,264.5,303.4C264.6,303.3,264.5,303.3,264.5,303.4z"
        />
        <path
          d="M270.5,295.1c0.4-0.2,0.8-0.3,1.2-0.4c2,0,3.3,1.7,3.3,3.9c0,2.6-1.6,4.7-3.9,4.7s-3.9-2-3.9-5.3c-0.1-3.4,1.9-6.5,5.1-7.9
	c0.7-0.2,1.5-0.4,2.2-0.6l0.1,0.7c-0.7,0.2-1.4,0.4-2,0.7c-1.8,1-3,2.8-3.3,4.8L270.5,295.1z M270.8,296c-0.6,0-1.2,0.2-1.7,0.5
	c-0.1,0.5-0.1,1-0.2,1.5c0,2.7,0.9,4.5,2.4,4.5s1.8-1.7,1.8-3.1C273.2,297.6,272.4,296,270.8,296L270.8,296z"
        />
        <path
          d="M276.5,292.2l0.9-1.3c0.6-0.8,1.5-1.2,2.4-1.2c1.5-0.1,2.7,1.1,2.8,2.6c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.1-0.6,1.6
	c-0.4,0.4-0.8,0.8-1.3,1.1c1.6,0.3,2.7,1.6,2.8,3.3c0,3.4-3.6,4.9-4.9,4.9c-0.7,0-1.4-0.3-1.9-0.7c-0.2-0.2-0.3-0.5-0.3-0.7
	c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.3,0,2.1-1.1,2.1-3.1c0.2-1.3-0.8-2.5-2.1-2.7
	c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.2,0.2l-0.1-0.6c1.6-0.6,2.9-1.4,2.9-3c0.2-0.9-0.5-1.8-1.4-2c-0.1,0-0.2,0-0.3,0
	c-1,0.1-1.8,0.8-2.3,1.6L276.5,292.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M246.5,327c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L246.5,327z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M248.4,315.7l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.1,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.4,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c-0.1,0-0.1,0-0.2,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L248.4,315.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M259.3,313.8c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S256.8,313.8,259.3,313.8z M259.3,314.3
	c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S260.6,314.3,259.3,314.3L259.3,314.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M267.1,313.8c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S264.6,313.8,267.1,313.8z M267.1,314.3
	c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S268.5,314.3,267.1,314.3L267.1,314.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M280.3,323.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.7-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L280.3,323.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M291.4,321.7c-0.2,0.8-0.5,1.6-0.6,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1-1.2,1.9-2.5,1.9-3.8
	s-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L291.4,321.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M293,312.2c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L293,312.2z"
        />
        <path
          d="M445.7,303.1v-0.6c1.5-0.2,1.6-0.3,1.6-2.1V292c0-1.8-0.1-1.9-1.6-2.1v-0.6h5.1v0.7c-1.5,0.2-1.6,0.3-1.6,2.1v8.3
	c0,1.8,0.1,1.9,1.6,2.1v0.6L445.7,303.1z"
        />
        <path
          d="M453.8,303.4c-0.7,0-1.2-0.6-1.2-1.3c0,0,0-0.1,0-0.1c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4
	c0,0,0,0,0,0C455.1,302.6,454.6,303.3,453.8,303.4C453.8,303.3,453.8,303.3,453.8,303.4z"
        />
        <path
          d="M459.7,295.1c0.4-0.2,0.8-0.3,1.2-0.4c2,0,3.3,1.7,3.3,3.9c0,2.6-1.6,4.7-3.9,4.7s-3.9-2-3.9-5.3c-0.1-3.4,1.9-6.5,5.1-7.9
	c0.7-0.2,1.5-0.4,2.2-0.6l0.1,0.7c-0.7,0.2-1.4,0.4-2,0.7c-1.8,1-3,2.8-3.3,4.8L459.7,295.1z M460.1,296c-0.6,0-1.2,0.2-1.7,0.5
	c-0.1,0.5-0.1,1-0.1,1.5c0,2.7,0.9,4.5,2.4,4.5s1.8-1.7,1.8-3.1C462.5,297.6,461.7,296,460.1,296L460.1,296z"
        />
        <path
          d="M469.3,289.6c2.8,0,3.9,3.6,3.9,6.8s-1,6.9-3.9,6.9s-3.9-3.5-3.9-6.8C465.4,293.4,466.5,289.6,469.3,289.6z M469.3,290.4
	c-1.6,0-2,3.1-2,6s0.5,6.1,2,6.1s2-3.1,2-6.1S470.9,290.4,469.3,290.4L469.3,290.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M435.8,327c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L435.8,327z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M437.7,315.7l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.1,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c-0.1,0-0.1,0-0.2,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L437.7,315.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M448.6,313.8c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S446.1,313.8,448.6,313.8z M448.6,314.3
	c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S449.9,314.3,448.6,314.3L448.6,314.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M456.4,313.8c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.1S453.9,313.8,456.4,313.8z M456.4,314.3
	c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S457.8,314.3,456.4,314.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M469.6,323.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.7-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L469.6,323.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M480.7,321.7c-0.2,0.8-0.5,1.6-0.6,2.2H474v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1-1.2,1.9-2.5,1.9-3.8
	s-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L480.7,321.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M482.2,312.2c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L482.2,312.2z"
        />
        <path
          d="M510.5,303.1v-0.6c1.5-0.2,1.6-0.3,1.6-2.1V292c0-1.8-0.1-1.9-1.6-2.1v-0.6h5.1v0.7c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
	c0,1.8,0.2,1.9,1.7,2.1v0.6L510.5,303.1z"
        />
        <path
          d="M518.5,303.4c-0.7,0-1.2-0.6-1.2-1.3c0,0,0-0.1,0-0.1c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4
	c0,0,0,0,0,0C519.8,302.6,519.3,303.3,518.5,303.4C518.6,303.3,518.6,303.3,518.5,303.4z"
        />
        <path
          d="M528.2,289.9l0.1,0.2l-0.5,1.5h-4.3c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.5-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4
	c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.9-2.1,1.4-3.3,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.2-0.2-0.4-0.5-0.3-0.7
	c0-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2
	c-0.6,0-1.3,0.1-1.8,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4L528.2,289.9z"
        />
        <path
          d="M535.8,297l-1.2,0.7c-0.3,0.2-0.7,0.3-1.1,0.3c-2,0-3.3-1.8-3.3-4c0-2,1.4-4.4,3.8-4.4s3.8,2.4,3.8,5.3
	c0.1,3.3-1.7,6.4-4.7,7.9c-0.7,0.3-1.5,0.5-2.3,0.6l-0.1-0.6C533.4,302.1,535.3,300.1,535.8,297z M533.8,290.4c-1.3,0-1.8,1.5-1.8,3
	c0,1.9,0.9,3.4,2.4,3.4c0.6,0,1.1-0.2,1.5-0.5c0-0.5,0.1-1,0.1-1.4C536,292.9,535.5,290.4,533.8,290.4L533.8,290.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M500.6,327c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L500.6,327z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M502.4,315.7l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.1,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.1,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c-0.1,0-0.1,0-0.2,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L502.4,315.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M513.3,313.8c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S510.8,313.8,513.3,313.8z M513.3,314.3
	c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S514.7,314.3,513.3,314.3L513.3,314.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M521.2,313.8c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S518.7,313.8,521.2,313.8z M521.2,314.3
	c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S522.5,314.3,521.2,314.3L521.2,314.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M534.3,323.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L534.3,323.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M545.5,321.7c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1-1.2,1.9-2.5,1.9-3.8
	s-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L545.5,321.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M547,312.2c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L547,312.2z"
        />
        <path
          d="M585.6,303.1v-0.6c1.5-0.2,1.6-0.3,1.6-2.1V292c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.7c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
	c0,1.8,0.2,1.9,1.7,2.1v0.6L585.6,303.1z"
        />
        <path
          d="M593.7,303.4c-0.7,0-1.2-0.6-1.2-1.3c0,0,0-0.1,0-0.1c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4
	c0,0,0,0,0,0C595,302.6,594.5,303.3,593.7,303.4C593.7,303.3,593.7,303.3,593.7,303.4z"
        />
        <path
          d="M603.3,289.9l0.1,0.2l-0.5,1.5h-4.3c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.5-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4
	c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.9-2.1,1.4-3.3,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.3-0.3-0.4-0.5-0.3-0.7
	c0-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2
	c-0.6,0-1.3,0.1-1.9,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4L603.3,289.9z"
        />
        <path
          d="M609.3,289.6c1.7,0,3,1.3,3.1,2.9c0,0.1,0,0.1,0,0.2c0,0.6-0.2,1.2-0.5,1.7c-0.5,0.5-1,0.9-1.5,1.3c1.3,0.8,2.5,1.8,2.5,3.6
	c0,2.1-1.6,3.8-3.7,3.9c0,0-0.1,0-0.1,0c-1.9,0.1-3.5-1.3-3.6-3.1c0-0.1,0-0.2,0-0.4c0-0.8,0.3-1.5,0.8-2.1c0.5-0.5,1.1-0.9,1.7-1.3
	c-1.2-0.7-2-1.9-2-3.3c-0.1-1.9,1.4-3.4,3.3-3.5C609.3,289.6,609.3,289.6,609.3,289.6z M609.3,302.6c1.2,0,1.9-0.9,1.9-2.4
	c0-1.7-1.2-2.5-2.7-3.3c-0.9,0.6-1.5,1.7-1.4,2.8C607.1,301.6,608.2,302.6,609.3,302.6L609.3,302.6z M609.1,290.4
	c-1.1,0-1.6,1-1.6,2.1c0,1.5,0.9,2.1,2.3,3c0.7-0.6,1.2-1.6,1.1-2.5C610.9,291.1,610,290.4,609.1,290.4L609.1,290.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M575.7,327c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L575.7,327z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M582.6,321v1.2c0,1,0.1,1.1,1.2,1.2v0.5H580v-0.5c1.2-0.1,1.3-0.2,1.3-1.3V321H577v-0.5
	c1.5-2.2,3.3-4.6,4.8-6.7h0.8v6.4h1.6v0.8H582.6z M581.2,315.6c-1,1.4-2.1,3-3.2,4.6h3.2V315.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M588.6,313.8c1.4-0.1,2.6,0.9,2.7,2.3c0,0,0,0.1,0,0.1c0,0.5-0.2,0.9-0.5,1.3c-0.4,0.4-0.8,0.7-1.3,1
	c1.1,0.6,2.2,1.4,2.2,2.8c-0.1,1.7-1.6,3-3.3,2.9c0,0,0,0,0,0c-1.8,0-3.2-1-3.2-2.7c0-0.6,0.2-1.2,0.7-1.6c0.5-0.4,0.9-0.7,1.4-1
	c-1-0.4-1.7-1.4-1.8-2.5C585.7,314.9,587,313.7,588.6,313.8C588.5,313.8,588.5,313.8,588.6,313.8z M588.6,323.6
	c0.9,0,1.7-0.7,1.7-1.5c0-0.1,0-0.2,0-0.3c0-1.3-1-1.9-2.4-2.5c-0.8,0.4-1.3,1.3-1.3,2.1c-0.1,1.1,0.7,2.1,1.8,2.2
	C588.5,323.5,588.5,323.5,588.6,323.6L588.6,323.6z M588.4,314.3c-0.8,0-1.4,0.6-1.4,1.4c0,0.1,0,0.1,0,0.2c0,1.1,0.8,1.6,2,2.2
	c0.6-0.4,1-1.2,1-1.9C590,314.9,589.2,314.3,588.4,314.3L588.4,314.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M596.3,313.8c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S593.8,313.8,596.3,313.8z M596.3,314.3
	c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S597.6,314.3,596.3,314.3L596.3,314.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M609.5,323.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5H605v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L609.5,323.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M620.6,321.7c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1-1.2,1.9-2.5,1.9-3.8
	s-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L620.6,321.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M622.1,312.2c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L622.1,312.2z"
        />
        <path
          d="M256.5,436v-0.6c1.5-0.2,1.6-0.3,1.6-2.1V425c0-1.8-0.1-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.6,0.3-1.6,2.1v8.3
	c0,1.8,0.1,1.9,1.6,2.1v0.6H256.5z"
        />
        <path
          d="M264.5,436.3c-0.7,0-1.2-0.6-1.2-1.3c0,0,0-0.1,0-0.1c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4
	c0,0,0,0,0,0C265.8,435.6,265.3,436.2,264.5,436.3C264.6,436.3,264.5,436.3,264.5,436.3z"
        />
        <path
          d="M274.1,422.9l0.1,0.2l-0.5,1.5h-4.3c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.5-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4
	c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.9-2.1,1.4-3.3,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.2-0.2-0.4-0.5-0.3-0.7
	c0-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.2,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2
	c-0.6,0-1.3,0.1-1.9,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4L274.1,422.9z"
        />
        <path
          d="M280,422.6c2.8,0,3.9,3.6,3.9,6.8s-1,6.9-3.9,6.9s-3.9-3.5-3.9-6.8C276.1,426.4,277.2,422.6,280,422.6z M280,423.4
	c-1.6,0-2,3.1-2,6s0.5,6.1,2,6.1s2-3.1,2-6.1S281.6,423.4,280,423.4L280,423.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M246.5,460c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L246.5,460z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M248.4,448.7l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.1,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.5
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.4,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L248.4,448.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M259.3,446.7c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S256.8,446.7,259.3,446.7z M259.3,447.3
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S260.6,447.3,259.3,447.3L259.3,447.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M267.1,446.7c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S264.6,446.7,267.1,446.7z M267.1,447.3
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S268.5,447.3,267.1,447.3L267.1,447.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M280.3,456.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.7-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.6,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L280.3,456.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M291.4,454.7c-0.2,0.8-0.5,1.6-0.6,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
	c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.2
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L291.4,454.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M293,445.1c4.1,3.1,4.9,8.9,1.9,13c-0.5,0.7-1.2,1.3-1.9,1.9l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L293,445.1z"
        />
        <path
          d="M256.5,521.9v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.1-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.6,0.3-1.6,2.1v8.3
	c0,1.8,0.1,1.9,1.6,2.1v0.6H256.5z"
        />
        <path
          d="M264.5,522.2c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
	C265.8,521.4,265.3,522.1,264.5,522.2C264.6,522.1,264.5,522.1,264.5,522.2z"
        />
        <path
          d="M273.4,518v1.5c0,1.4,0.1,1.5,1.4,1.6v0.6h-4.5v-0.6c1.4-0.2,1.5-0.3,1.5-1.7V518h-4.8v-0.7c1.8-2.9,3.7-6,5.5-8.9h0.9v8.5
	h1.8v1L273.4,518z M271.7,510.9c-1.1,1.8-2.4,4-3.6,6.1h3.6V510.9z"
        />
        <path
          d="M281.8,515.8l-1.2,0.7c-0.3,0.2-0.7,0.3-1.1,0.3c-2,0-3.4-1.7-3.4-4c0-2,1.4-4.4,3.9-4.4s3.8,2.4,3.8,5.3
	c0.1,3.3-1.7,6.4-4.7,7.9c-0.7,0.3-1.5,0.5-2.3,0.6l-0.1-0.6C279.4,520.9,281.2,519,281.8,515.8z M279.8,509.2c-1.4,0-1.8,1.5-1.8,3
	c0,1.9,0.9,3.4,2.4,3.4c0.6,0,1.1-0.2,1.5-0.5c0.1-0.5,0.1-0.9,0.1-1.4C282,511.7,281.5,509.2,279.8,509.2L279.8,509.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M246.5,545.8c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L246.5,545.8z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M248.4,534.5l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.1,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.6
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.4,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L248.4,534.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M259.3,532.6c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S256.8,532.6,259.3,532.6z M259.3,533.2
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.3,1.7-4.6S260.6,533.2,259.3,533.2L259.3,533.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M267.1,532.6c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S264.6,532.6,267.1,532.6z M267.1,533.2
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S268.5,533.2,267.1,533.2L267.1,533.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M280.3,542.7v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.7-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.2,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H280.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M291.4,540.5c-0.2,0.7-0.5,1.6-0.6,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
	c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L291.4,540.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M293,531c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L293,531z"
        />
        <path
          d="M255.8,657.4v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.1-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.6,0.3-1.6,2.1v8.3
	c0,1.8,0.1,1.9,1.6,2.1v0.6H255.8z"
        />
        <path
          d="M263.8,657.7c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0.1,1.3,0.7,1.2,1.4c0,0,0,0,0,0
	C265.1,657,264.6,657.6,263.8,657.7C263.8,657.7,263.8,657.7,263.8,657.7z"
        />
        <path
          d="M266.8,646.6l0.9-1.3c0.6-0.8,1.5-1.2,2.4-1.2c1.5-0.1,2.8,1.1,2.8,2.6c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.1-0.6,1.5
	c-0.4,0.4-0.8,0.8-1.3,1.1c1.6,0.3,2.7,1.6,2.8,3.3c0,3.4-3.6,4.9-4.9,4.9c-0.7,0-1.4-0.2-1.9-0.7c-0.2-0.2-0.3-0.4-0.3-0.7
	c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.3-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.3,0,2.1-1.1,2.1-3.1c0.2-1.3-0.8-2.5-2.1-2.7
	c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.2,0.2l-0.1-0.7c1.6-0.6,2.9-1.4,2.9-3c0.2-0.9-0.5-1.8-1.4-2c-0.1,0-0.2,0-0.3,0
	c-1,0.1-1.9,0.7-2.3,1.7L266.8,646.6z"
        />
        <path
          d="M276.5,657.4v-0.6c2-0.2,2.1-0.3,2.1-1.8v-8c0-1.1-0.2-1.1-1-1.2l-0.8-0.1v-0.6c1.2-0.2,2.4-0.5,3.5-1v11
	c0,1.6,0.1,1.7,2.1,1.8v0.6L276.5,657.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M245.8,681.4c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L245.8,681.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M247.7,670.1l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.1,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0
	c-0.3,0-0.7,0.1-1,0.2L249,673c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L247.7,670.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M258.6,668.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S256.1,668.1,258.6,668.1z M258.6,668.7
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S259.9,668.7,258.6,668.7L258.6,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M266.4,668.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S263.9,668.1,266.4,668.1z M266.4,668.7
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S267.8,668.7,266.4,668.7L266.4,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M279.6,678.3v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.7-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H279.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M290.7,676.1c-0.2,0.7-0.5,1.6-0.6,2.2H284v-0.4c0.9-0.8,1.8-1.8,2.6-2.7c1.1-1,1.7-2.4,1.9-3.8
	c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L290.7,676.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M292.3,666.6c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L292.3,666.6z"
        />
        <path
          d="M317.6,657.4v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.1-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
	c0,1.8,0.1,1.9,1.7,2.1v0.6H317.6z"
        />
        <path
          d="M325.6,657.7c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
	C326.9,657,326.4,657.6,325.6,657.7C325.7,657.7,325.7,657.7,325.6,657.7z"
        />
        <path
          d="M328.7,646.6l0.9-1.3c0.6-0.8,1.5-1.2,2.5-1.3c1.5-0.1,2.8,1.1,2.8,2.6c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.1-0.6,1.5
	c-0.4,0.4-0.8,0.8-1.3,1.1c1.6,0.3,2.7,1.6,2.8,3.3c0,3.4-3.6,4.9-4.9,4.9c-0.7,0-1.4-0.3-1.9-0.7c-0.2-0.2-0.3-0.4-0.3-0.7
	c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.3-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.3,0,2.1-1.1,2.1-3.1c0.2-1.3-0.8-2.5-2.1-2.7
	c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.2,0.2l-0.1-0.7c1.6-0.6,2.9-1.4,2.9-3c0-1.3-0.7-2-1.7-2c-1,0.1-1.9,0.7-2.3,1.7L328.7,646.6z
	"
        />
        <path
          d="M345,654.5c-0.2,1-0.6,2.1-0.8,2.9h-7v-0.5c1-1.1,2.1-2.4,3-3.6c1.2-1.6,2.2-3.3,2.2-5s-0.8-2.7-2.1-2.7s-2,1.1-2.5,2
	l-0.5-0.5l1.1-1.8c0.6-0.8,1.6-1.3,2.6-1.3c1.8,0,3.2,1.4,3.2,3.7c0,1.8-0.6,3-2.3,5.1c-0.8,0.9-1.9,2.2-2.7,3h3.4
	c1,0,1.3-0.1,1.9-1.4L345,654.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M307.7,681.4c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.4,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c0,2.6,0.9,5.1,2.6,7L307.7,681.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M309.5,670.1l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.8,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.1,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L309.5,670.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M320.4,668.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S318,668.1,320.4,668.1z M320.4,668.7
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S321.8,668.7,320.4,668.7L320.4,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M328.3,668.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S325.8,668.1,328.3,668.1z M328.3,668.7
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S329.6,668.7,328.3,668.7L328.3,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M341.5,678.3v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5H337v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.8-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H341.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M352.6,676.1c-0.2,0.7-0.5,1.6-0.6,2.2h-6.1v-0.4c0.9-0.8,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
	c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L352.6,676.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M354.1,666.6c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L354.1,666.6z"
        />
        <path
          d="M383.9,657.4v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.1-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
	c0,1.8,0.1,1.9,1.7,2.1v0.6H383.9z"
        />
        <path
          d="M391.9,657.7c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
	C393.2,657,392.7,657.6,391.9,657.7C392,657.7,392,657.7,391.9,657.7z"
        />
        <path
          d="M395,646.6l0.9-1.3c0.6-0.8,1.5-1.2,2.4-1.2c1.5-0.1,2.8,1.1,2.8,2.6c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.1-0.6,1.5
	c-0.4,0.4-0.8,0.8-1.3,1.1c1.6,0.3,2.7,1.6,2.8,3.3c0,3.4-3.6,4.9-4.9,4.9c-0.7,0-1.4-0.3-1.9-0.7c-0.2-0.2-0.3-0.4-0.3-0.7
	c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.3-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.3,0,2.2-1.1,2.2-3.1c0.2-1.3-0.8-2.5-2.1-2.7
	c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.2,0.2l-0.1-0.7c1.6-0.6,2.9-1.4,2.9-3c0.2-0.9-0.5-1.8-1.4-2c-0.1,0-0.2,0-0.3,0
	c-1,0.1-1.9,0.8-2.3,1.7L395,646.6z"
        />
        <path
          d="M403.9,646.6l0.9-1.3c0.6-0.8,1.5-1.2,2.4-1.2c1.5-0.1,2.8,1.1,2.8,2.6c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.1-0.6,1.5
	c-0.4,0.4-0.8,0.8-1.3,1.1c1.6,0.3,2.7,1.6,2.8,3.3c0,3.4-3.6,4.9-4.9,4.9c-0.7,0-1.4-0.3-1.9-0.7c-0.2-0.2-0.3-0.4-0.3-0.7
	c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.3-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.3,0,2.1-1.1,2.1-3.1c0.2-1.3-0.8-2.5-2.1-2.7
	c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.2,0.2l-0.1-0.7c1.6-0.6,2.9-1.4,2.9-3c0.2-0.9-0.5-1.8-1.4-2c-0.1,0-0.2,0-0.3,0
	c-1,0.1-1.9,0.7-2.3,1.7L403.9,646.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M374,681.4c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c0,2.6,0.9,5.1,2.6,7L374,681.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M375.8,670.1l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L375.8,670.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M386.7,668.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S384.2,668.1,386.7,668.1z M386.7,668.7
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S388.1,668.7,386.7,668.7L386.7,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M394.5,668.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S392.1,668.1,394.5,668.1z M394.5,668.7
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S395.9,668.7,394.5,668.7L394.5,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M407.8,678.3v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5H399v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.8-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H407.8z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M418.9,676.1c-0.2,0.7-0.5,1.6-0.6,2.2h-6.1v-0.4c0.9-0.8,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
	c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L418.9,676.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M420.4,666.6c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8L420,681c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L420.4,666.6z"
        />
        <path
          d="M445.7,657.4v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.6,0.3-1.6,2.1v8.3
	c0,1.8,0.1,1.9,1.6,2.1v0.6H445.7z"
        />
        <path
          d="M453.8,657.7c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
	C455.1,657,454.6,657.6,453.8,657.7C453.8,657.7,453.8,657.7,453.8,657.7z"
        />
        <path
          d="M456.8,646.6l0.9-1.3c0.6-0.8,1.5-1.2,2.4-1.2c1.5-0.1,2.8,1.1,2.8,2.6c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.1-0.6,1.5
	c-0.4,0.4-0.8,0.8-1.3,1.1c1.6,0.3,2.7,1.6,2.8,3.3c0,3.4-3.6,4.9-4.9,4.9c-0.7,0-1.4-0.3-1.9-0.7c-0.2-0.2-0.3-0.4-0.3-0.7
	c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.3-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.3,0,2.1-1.1,2.1-3.1c0.2-1.3-0.8-2.5-2.1-2.7
	c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.2,0.2l-0.1-0.7c1.6-0.6,2.9-1.4,2.9-3c0.2-0.9-0.5-1.8-1.4-2c-0.1,0-0.2,0-0.3,0
	c-1,0.1-1.9,0.7-2.3,1.7L456.8,646.6z"
        />
        <path
          d="M471.6,653.6v1.5c0,1.4,0.1,1.5,1.4,1.6v0.6h-4.5v-0.6c1.4-0.2,1.5-0.3,1.5-1.7v-1.5h-4.8V653c1.8-2.9,3.7-6,5.5-8.8h0.9
	v8.5h1.8v1.1L471.6,653.6z M470,646.4c-1.1,1.8-2.4,4-3.6,6.1h3.6V646.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M435.8,681.4c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L435.8,681.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M437.7,670.1l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.1,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0
	c-0.3,0-0.7,0.1-1,0.2L439,673c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L437.7,670.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M448.6,668.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S446.1,668.1,448.6,668.1z M448.6,668.7
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S449.9,668.7,448.6,668.7L448.6,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M456.4,668.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S453.9,668.1,456.4,668.1z M456.4,668.7
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S457.8,668.7,456.4,668.7L456.4,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M469.6,678.3v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.7-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H469.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M480.7,676.1c-0.2,0.7-0.5,1.6-0.6,2.2H474v-0.4c0.9-0.8,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
	c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L480.7,676.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M482.2,666.6c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L482.2,666.6z"
        />
        <path
          d="M509.6,657.4v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
	c0,1.8,0.2,1.9,1.7,2.1v0.6H509.6z"
        />
        <path
          d="M517.6,657.7c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
	C518.9,657,518.4,657.6,517.6,657.7C517.7,657.7,517.6,657.7,517.6,657.7z"
        />
        <path
          d="M520.6,646.6l0.9-1.3c0.6-0.8,1.5-1.2,2.4-1.2c1.5-0.1,2.8,1.1,2.8,2.6c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.1-0.6,1.5
	c-0.4,0.4-0.8,0.8-1.3,1.1c1.6,0.3,2.7,1.6,2.8,3.3c0,3.4-3.6,4.9-4.9,4.9c-0.7,0-1.4-0.3-1.9-0.7c-0.2-0.2-0.3-0.4-0.3-0.7
	c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.3-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.3,0,2.2-1.1,2.2-3.1c0.2-1.3-0.8-2.5-2.1-2.7
	c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.2,0.2l-0.1-0.7c1.6-0.6,2.9-1.4,2.9-3c0.2-0.9-0.5-1.8-1.4-2c-0.1,0-0.2,0-0.3,0
	c-1,0.1-1.9,0.7-2.3,1.7L520.6,646.6z"
        />
        <path
          d="M536.2,644.3l0.1,0.2l-0.5,1.5h-4.3c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.5-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4
	c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.9-2.1,1.4-3.3,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.2-0.2-0.4-0.5-0.3-0.7
	c0-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.2,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2
	c-0.6,0-1.3,0.1-1.8,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4L536.2,644.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M499.6,681.4c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.4,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c0,2.6,0.9,5.1,2.6,7L499.6,681.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M501.5,670.1l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.8,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.1,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L501.5,670.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M512.4,668.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S509.9,668.1,512.4,668.1z M512.4,668.7
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6c1.3,0,1.7-2.4,1.7-4.6S513.8,668.7,512.4,668.7L512.4,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M520.2,668.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S517.7,668.1,520.2,668.1z M520.2,668.7
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S521.6,668.7,520.2,668.7L520.2,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M533.4,678.3v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5H529v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H533.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M544.5,676.1c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.8,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
	c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L544.5,676.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M546.1,666.6c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L546.1,666.6z"
        />
        <path
          d="M571.4,657.4v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
	c0,1.8,0.2,1.9,1.7,2.1v0.6H571.4z"
        />
        <path
          d="M579.5,657.7c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
	C580.8,657,580.2,657.6,579.5,657.7C579.5,657.7,579.5,657.7,579.5,657.7z"
        />
        <path
          d="M582.5,646.6l0.9-1.3c0.6-0.8,1.5-1.2,2.4-1.2c1.5-0.1,2.8,1.1,2.8,2.6c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.1-0.6,1.5
	c-0.4,0.4-0.8,0.8-1.3,1.1c1.6,0.3,2.7,1.6,2.8,3.3c0,3.4-3.6,4.9-4.9,4.9c-0.7,0-1.4-0.3-1.9-0.7c-0.2-0.2-0.3-0.4-0.3-0.7
	c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.3-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.3,0,2.2-1.1,2.2-3.1c0.2-1.3-0.8-2.5-2.1-2.7
	c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.2,0.2l-0.1-0.7c1.7-0.6,2.9-1.4,2.9-3c0-1.3-0.7-2-1.7-2c-1,0.1-1.9,0.7-2.3,1.7L582.5,646.6z
	"
        />
        <path
          d="M594.4,649.5c0.4-0.2,0.8-0.4,1.2-0.4c2,0,3.3,1.7,3.3,3.9c0,2.6-1.6,4.7-3.9,4.7s-3.9-2-3.9-5.3c-0.1-3.4,1.9-6.5,5.1-7.9
	c0.7-0.2,1.5-0.4,2.2-0.6l0.1,0.7c-0.7,0.2-1.4,0.4-2,0.7c-1.8,1-3,2.8-3.3,4.8L594.4,649.5z M594.8,650.3c-0.6,0-1.2,0.2-1.7,0.5
	c-0.1,0.5-0.1,1-0.1,1.5c0,2.7,0.8,4.5,2.3,4.5s1.8-1.7,1.8-3.1C597.1,652,596.3,650.3,594.8,650.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M561.5,681.4c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c0,2.6,0.9,5.1,2.6,7L561.5,681.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M563.3,670.1l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.8,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L563.3,670.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M574.2,668.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S571.8,668.1,574.2,668.1z M574.2,668.7
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S575.6,668.7,574.2,668.7L574.2,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M582.1,668.1c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S579.6,668.1,582.1,668.1z M582.1,668.7
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S583.4,668.7,582.1,668.7L582.1,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M595.3,678.3v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.4-0.7v1.3
	c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L595.3,678.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M606.4,676.1c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.8,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
	c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L606.4,676.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M607.9,666.6c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L607.9,666.6z"
        />
        <path
          d="M629.4,657.4v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
	c0,1.8,0.2,1.9,1.7,2.1v0.6H629.4z"
        />
        <path
          d="M637.4,657.7c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
	C638.7,657,638.2,657.6,637.4,657.7C637.5,657.7,637.5,657.7,637.4,657.7z"
        />
        <path
          d="M640.5,646.6l0.9-1.3c0.6-0.8,1.5-1.2,2.4-1.2c1.5-0.1,2.8,1.1,2.8,2.6c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.1-0.6,1.5
	c-0.4,0.4-0.8,0.8-1.3,1.1c1.6,0.3,2.7,1.6,2.8,3.3c0,3.4-3.6,4.9-4.9,4.9c-0.7,0-1.4-0.3-1.9-0.7c-0.2-0.2-0.3-0.4-0.3-0.7
	c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.3-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.3,0,2.2-1.1,2.2-3.1c0.2-1.3-0.8-2.5-2.1-2.7
	c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.2,0.2l-0.1-0.7c1.6-0.6,2.9-1.4,2.9-3c0.2-0.9-0.5-1.8-1.4-2c-0.1,0-0.2,0-0.3,0
	c-1,0.1-1.9,0.7-2.3,1.7L640.5,646.6z"
        />
        <path
          d="M657,644.6c-1.8,4.3-3.5,8.5-5.3,12.9l-1.4,0.2l-0.1-0.2c1.9-3.6,3.9-7.8,5.4-11.4h-3.9c-1.2,0-1.3,0.2-1.8,1.6h-0.6
	c0.2-1.3,0.3-2.3,0.4-3.3h7.2L657,644.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M623.6,681.4c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c0,2.6,0.9,5.1,2.6,7L623.6,681.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M625.5,670.1l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L625.5,670.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M636.4,668.1c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S633.9,668.1,636.4,668.1z M636.4,668.7
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S637.7,668.7,636.4,668.7L636.4,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M644.2,668.1c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S641.7,668.1,644.2,668.1z M644.2,668.7
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S645.5,668.7,644.2,668.7L644.2,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M657.4,678.3v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5H653v-0.5c1-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H657.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M668.5,676.1c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.8,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
	c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.2
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L668.5,676.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M670,666.6c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L670,666.6z"
        />
        <path
          d="M691.2,657.4v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
	c0,1.8,0.2,1.9,1.7,2.1v0.6H691.2z"
        />
        <path
          d="M699.3,657.7c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
	C700.6,657,700.1,657.6,699.3,657.7C699.3,657.7,699.3,657.7,699.3,657.7z"
        />
        <path
          d="M702.3,646.6l0.9-1.3c0.6-0.8,1.5-1.2,2.4-1.2c1.5-0.1,2.8,1.1,2.8,2.6c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.1-0.6,1.5
	c-0.4,0.4-0.8,0.8-1.3,1.1c1.6,0.3,2.7,1.6,2.8,3.3c0,3.4-3.6,4.9-4.9,4.9c-0.7,0-1.4-0.3-1.9-0.7c-0.2-0.2-0.3-0.4-0.3-0.7
	c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.3-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.3,0,2.2-1.1,2.2-3.1c0.2-1.3-0.8-2.5-2.1-2.7
	c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.1,0.3l-0.1-0.7c1.6-0.6,2.9-1.4,2.9-3c0.2-0.9-0.5-1.8-1.4-2c-0.1,0-0.2,0-0.3,0
	c-1,0.1-1.9,0.7-2.3,1.7L702.3,646.6z"
        />
        <path
          d="M715,644c1.7,0,3,1.3,3.1,2.9c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.2-0.5,1.7c-0.5,0.5-1,0.9-1.5,1.3c1.3,0.8,2.5,1.8,2.5,3.7
	c0,2.1-1.6,3.8-3.7,3.9c0,0,0,0-0.1,0c-1.9,0.1-3.5-1.3-3.6-3.1c0-0.1,0-0.2,0-0.4c0-0.8,0.3-1.5,0.8-2.1c0.5-0.5,1.1-0.9,1.7-1.3
	c-1.2-0.7-2-1.9-2-3.3C711.6,645.6,713,644.1,715,644C714.9,644,715,644,715,644z M715,657c1.2,0,1.9-0.9,1.9-2.4s-1.2-2.5-2.7-3.3
	c-0.9,0.6-1.5,1.7-1.4,2.8C712.7,656,713.8,657,715,657z M714.8,644.8c-1.1,0-1.6,1-1.6,2.1c0,1.5,0.9,2.1,2.3,3
	c0.8-0.6,1.2-1.6,1.1-2.6C716.5,645.5,715.6,644.8,714.8,644.8L714.8,644.8z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M685.5,681.4c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c0,2.6,0.9,5.1,2.6,7L685.5,681.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M687.3,670.1l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L687.3,670.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M698.2,668.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S695.7,668.1,698.2,668.1z M698.2,668.7
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S699.6,668.7,698.2,668.7L698.2,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M706,668.1c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S703.6,668.1,706,668.1z M706,668.7
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S707.4,668.7,706,668.7L706,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M719.3,678.3v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H719.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M730.4,676.1c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.8,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
	c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L730.4,676.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M731.9,666.6c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L731.9,666.6z"
        />
        <path
          d="M755.7,657.4v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.2-1.9-1.6-2.1v-0.6h5v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
	c0,1.8,0.2,1.9,1.7,2.1v0.6H755.7z"
        />
        <path
          d="M763.7,657.7c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
	C765,657,764.5,657.6,763.7,657.7C763.8,657.7,763.7,657.7,763.7,657.7z"
        />
        <path
          d="M766.7,646.6l0.9-1.3c0.6-0.8,1.5-1.2,2.4-1.3c1.5-0.1,2.8,1.1,2.8,2.6c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.1-0.6,1.5
	c-0.4,0.4-0.8,0.8-1.3,1.1c1.6,0.3,2.7,1.6,2.8,3.3c0,3.4-3.6,4.9-4.9,4.9c-0.7,0-1.4-0.3-1.9-0.7c-0.2-0.2-0.3-0.4-0.3-0.7
	c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.3-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.3,0,2.2-1.1,2.2-3.1c0.2-1.3-0.8-2.5-2.1-2.7
	c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.2,0.2l-0.1-0.7c1.7-0.6,2.9-1.4,2.9-3c0.2-0.9-0.5-1.8-1.4-2c-0.1,0-0.2,0-0.3,0
	c-1,0.1-1.9,0.7-2.3,1.7L766.7,646.6z"
        />
        <path
          d="M781,651.4l-1.2,0.7c-0.3,0.2-0.7,0.3-1.1,0.3c-2,0-3.3-1.8-3.3-4c0-2,1.4-4.4,3.8-4.4s3.8,2.4,3.8,5.3
	c0.4,4.2-2.7,8-6.9,8.4c0,0-0.1,0-0.1,0l-0.2-0.7C778.5,656.5,780.4,654.5,781,651.4z M779,644.8c-1.3,0-1.8,1.5-1.8,3
	c0,1.9,0.9,3.4,2.4,3.4c0.6,0,1.1-0.2,1.5-0.5c0.1-0.5,0.1-0.9,0.1-1.4C781.2,647.3,780.7,644.8,779,644.8z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M749.9,681.4c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L749.9,681.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M751.8,670.1l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.3c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L751.8,670.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M762.6,668.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S760.2,668.1,762.6,668.1z M762.6,668.7
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S764,668.7,762.6,668.7L762.6,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M770.5,668.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S768,668.1,770.5,668.1z M770.5,668.7
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S771.8,668.7,770.5,668.7L770.5,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M783.7,678.3v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H783.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M794.8,676.1c-0.2,0.8-0.5,1.6-0.7,2.2H788v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
	c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L794.8,676.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M796.3,666.6c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8L796,681c3.5-4,3.5-10,0-14L796.3,666.6z"
        />
        <path
          d="M832.9,657.4v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
	c0,1.8,0.2,1.9,1.7,2.1v0.6H832.9z"
        />
        <path
          d="M840.9,657.7c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
	C842.2,657,841.7,657.6,840.9,657.7C841,657.7,840.9,657.7,840.9,657.7z"
        />
        <path
          d="M849.8,653.6v1.5c0,1.4,0.1,1.5,1.4,1.6v0.6h-4.5v-0.6c1.4-0.2,1.5-0.3,1.5-1.7v-1.5h-4.8V653c1.8-2.9,3.7-6,5.5-8.8h0.9
	v8.5h1.8v1.1L849.8,653.6z M848.1,646.4c-1.1,1.8-2.4,4-3.7,6.1h3.7V646.4z"
        />
        <path
          d="M856.4,644c2.8,0,3.8,3.6,3.8,6.8c0,3.1-1,6.8-3.8,6.8s-3.9-3.5-3.9-6.8C852.5,647.8,853.6,644,856.4,644z M856.4,644.8
	c-1.6,0-2,3.1-2,6s0.5,6.1,2,6.1s2-3.1,2-6.1S858,644.8,856.4,644.8L856.4,644.8z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M827.1,681.4c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L827.1,681.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M829,670.1l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.3c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L829,670.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M840,668.1c1.4-0.1,2.6,0.9,2.7,2.3c0,0,0,0.1,0,0.1c0,0.5-0.2,0.9-0.5,1.3c-0.4,0.4-0.8,0.7-1.3,1
	c1.1,0.6,2.2,1.4,2.2,2.8c-0.1,1.7-1.6,3-3.3,2.9c0,0,0,0,0,0c-1.8,0-3.2-1-3.2-2.7c0-0.6,0.2-1.2,0.7-1.6c0.5-0.4,0.9-0.7,1.4-1
	c-1-0.4-1.7-1.4-1.8-2.5C837.1,669.3,838.4,668.1,840,668.1C839.9,668.1,840,668.1,840,668.1z M840,677.9c0.9,0,1.6-0.6,1.7-1.5
	c0-0.1,0-0.2,0-0.3c0-1.3-1-1.9-2.4-2.5c-0.8,0.4-1.3,1.3-1.3,2.1c-0.1,1.1,0.7,2.1,1.8,2.2C839.9,677.9,839.9,677.9,840,677.9z
	 M839.8,668.7c-0.8,0-1.4,0.6-1.4,1.4c0,0.1,0,0.1,0,0.2c0,1.2,0.8,1.6,2,2.2c0.6-0.4,1-1.2,1-1.9
	C841.4,669.3,840.6,668.7,839.8,668.7L839.8,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M850.4,668.3l0.1,0.1l-0.4,1.2h-3.7c-0.2,0.8-0.3,2.1-0.5,2.8c0.6-0.2,1.3-0.4,1.9-0.5c1.5-0.1,2.9,1,3,2.6
	c0,0,0,0.1,0,0.1c0,1.1-0.5,2.1-1.4,2.7c-0.8,0.7-1.8,1.1-2.9,1.1c-0.6,0-1.2-0.2-1.7-0.6c-0.3-0.2-0.3-0.3-0.3-0.6
	c0-0.2,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.6,1.2,0.9,2,0.9c1.3,0,1.9-1.1,1.9-2.3c0.1-1.3-0.9-2.3-2.1-2.4
	c-0.1,0-0.3,0-0.4,0c-0.5,0-1.1,0.1-1.6,0.3l-0.1-0.1c0.3-1.3,0.6-3.5,0.8-4.8L850.4,668.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M860.9,678.3v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H860.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M872,676.1c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1-1.2,1.9-2.5,1.9-3.8
	s-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L872,676.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M873.5,666.6c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L873.5,666.6z"
        />
        <path
          d="M255.8,739.3v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.1-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.6,0.3-1.6,2.1v8.3
	c0,1.8,0.1,1.9,1.6,2.1v0.6H255.8z"
        />
        <path
          d="M263.8,739.6c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
	C265.1,738.9,264.6,739.5,263.8,739.6C263.9,739.6,263.8,739.6,263.8,739.6z"
        />
        <path
          d="M266.8,728.5l0.9-1.3c0.6-0.8,1.5-1.2,2.4-1.2c1.5-0.1,2.8,1.1,2.8,2.6c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.1-0.6,1.5
	c-0.4,0.4-0.8,0.8-1.3,1.1c1.6,0.3,2.7,1.6,2.8,3.3c0,3.4-3.6,4.9-4.9,4.9c-0.7,0-1.4-0.3-1.9-0.7c-0.2-0.2-0.3-0.4-0.3-0.7
	c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.3-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.3,0,2.1-1.1,2.1-3.1c0.2-1.3-0.8-2.5-2.1-2.7
	c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.2,0.2l-0.1-0.7c1.6-0.6,2.9-1.4,2.9-3c0.2-0.9-0.5-1.8-1.4-2c-0.1,0-0.2,0-0.3,0
	c-1,0.1-1.9,0.7-2.3,1.7L266.8,728.5z"
        />
        <path
          d="M279.3,725.9c2.8,0,3.9,3.6,3.9,6.8c0,3.1-1,6.8-3.9,6.8s-3.9-3.5-3.9-6.8C275.4,729.7,276.5,725.9,279.3,725.9z
	 M279.3,726.7c-1.6,0-2,3.1-2,6s0.5,6.1,2,6.1s2-3.1,2-6.1S280.9,726.7,279.3,726.7L279.3,726.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M245.8,763.3c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L245.8,763.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M247.7,752l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.1,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.3c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.4-1.5-1.1-1.6c-0.2,0-0.3,0-0.5,0c-0.8,0.1-1.6,0.5-2,1.3
	L247.7,752z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M258.6,750c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S256.1,750,258.6,750z M258.6,750.6
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S259.9,750.6,258.6,750.6L258.6,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M266.4,750c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S263.9,750,266.4,750z M266.4,750.6
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S267.8,750.6,266.4,750.6L266.4,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M279.6,760.2v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.7-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H279.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M290.7,758c-0.2,0.7-0.5,1.6-0.6,2.2H284v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.7-2.4,1.9-3.8
	c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.4c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L290.7,758z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M292.3,748.4c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L292.3,748.4z"
        />
        <path
          d="M317.6,739.3v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.1-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
	c0,1.8,0.1,1.9,1.7,2.1v0.6H317.6z"
        />
        <path
          d="M325.6,739.6c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
	C326.9,738.9,326.4,739.5,325.6,739.6C325.7,739.6,325.7,739.6,325.6,739.6z"
        />
        <path
          d="M336,736.4c-0.3,1-0.6,2.1-0.8,2.9h-7v-0.5c1-1.1,2.1-2.4,3-3.6c1.2-1.6,2.2-3.3,2.2-5s-0.8-2.7-2.1-2.7s-2,1.1-2.5,2
	l-0.4-0.5l1.1-1.8c0.6-0.8,1.6-1.3,2.6-1.3c1.8,0,3.2,1.4,3.2,3.7c0,1.8-0.6,3-2.3,5.1c-0.8,0.9-1.9,2.2-2.7,3h3.4
	c1,0,1.3-0.1,1.9-1.4L336,736.4z"
        />
        <path
          d="M343,733.3l-1.2,0.7c-0.3,0.2-0.7,0.3-1.1,0.3c-2,0-3.4-1.8-3.4-4c0-2,1.4-4.4,3.9-4.4s3.8,2.4,3.8,5.3
	c0.1,3.3-1.7,6.4-4.7,7.9c-0.7,0.3-1.5,0.5-2.3,0.6l-0.1-0.7C340.5,738.4,342.4,736.4,343,733.3z M341,726.7c-1.4,0-1.8,1.5-1.8,3
	c0,1.9,0.9,3.4,2.4,3.4c0.6,0,1.1-0.2,1.5-0.6c0-0.5,0.1-1,0.1-1.4C343.1,729.2,342.6,726.7,341,726.7L341,726.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M307.7,763.3c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.4,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L307.7,763.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M309.5,752l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.8,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.1,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.3c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L309.5,752z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M320.4,750c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S318,750,320.4,750z M320.4,750.6
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S321.8,750.6,320.4,750.6L320.4,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M328.3,750c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S325.8,750,328.3,750z M328.3,750.6
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S329.6,750.6,328.3,750.6L328.3,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M341.5,760.2v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5H337v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.8-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H341.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M352.6,758c-0.2,0.7-0.5,1.6-0.6,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
	c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L352.6,758z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M354.1,748.4c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L354.1,748.4z"
        />
        <path
          d="M383.9,739.3v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.1-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
	c0,1.8,0.1,1.9,1.7,2.1v0.6H383.9z"
        />
        <path
          d="M391.9,739.6c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
	C393.2,738.9,392.7,739.5,391.9,739.6C392,739.6,392,739.6,391.9,739.6z"
        />
        <path
          d="M402.3,736.4c-0.3,1-0.6,2.1-0.8,2.9h-7v-0.5c1-1.1,2.1-2.4,3-3.6c1.2-1.6,2.2-3.3,2.2-5s-0.8-2.7-2.1-2.7s-2,1.1-2.5,2
	l-0.4-0.5l1.1-1.8c0.6-0.8,1.6-1.3,2.6-1.3c1.8,0,3.2,1.4,3.2,3.7c0,1.8-0.6,3-2.3,5.1c-0.8,0.9-1.9,2.2-2.7,3h3.4
	c1,0,1.3-0.1,1.9-1.4L402.3,736.4z"
        />
        <path
          d="M407.6,725.9c1.7,0,3,1.3,3.1,2.9c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.2-0.5,1.7c-0.5,0.5-1,0.9-1.5,1.3c1.3,0.8,2.5,1.8,2.5,3.7
	c0,2.1-1.6,3.8-3.7,3.9c0,0,0,0-0.1,0c-1.9,0.1-3.5-1.3-3.6-3.1c0-0.1,0-0.3,0-0.4c0-0.8,0.3-1.5,0.8-2.1c0.5-0.5,1.1-0.9,1.7-1.3
	c-1.2-0.7-2-1.9-2-3.3C404.2,727.5,405.6,726,407.6,725.9C407.5,725.9,407.6,725.9,407.6,725.9z M407.6,738.8c1.2,0,1.9-0.9,1.9-2.4
	s-1.2-2.5-2.7-3.3c-0.9,0.6-1.5,1.7-1.4,2.8C405.4,737.8,406.4,738.8,407.6,738.8z M407.4,726.6c-1.1,0-1.6,1-1.6,2.1
	c0,1.5,0.9,2.1,2.4,3c0.7-0.6,1.2-1.6,1.1-2.5C409.2,727.3,408.3,726.6,407.4,726.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M374,763.3c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L374,763.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M375.8,752l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.3c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L375.8,752z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M386.7,750c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S384.2,750,386.7,750z M386.7,750.6
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S388.1,750.6,386.7,750.6L386.7,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M394.5,750c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S392.1,750,394.5,750z M394.5,750.6
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S395.9,750.6,394.5,750.6L394.5,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M407.8,760.2v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5H399v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.8-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H407.8z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M418.9,758c-0.2,0.7-0.5,1.6-0.6,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
	c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L418.9,758z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M420.4,748.4c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L420.4,748.4z"
        />
        <path
          d="M445.7,739.3v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.6,0.3-1.6,2.1v8.3
	c0,1.8,0.1,1.9,1.6,2.1v0.6H445.7z"
        />
        <path
          d="M453.8,739.6c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
	C455.1,738.9,454.6,739.5,453.8,739.6C453.8,739.6,453.8,739.6,453.8,739.6z"
        />
        <path
          d="M464.2,736.4c-0.3,1-0.6,2.1-0.8,2.9h-7v-0.5c1-1.1,2.1-2.4,3-3.6c1.2-1.6,2.2-3.3,2.2-5s-0.8-2.7-2.1-2.7s-2,1.1-2.5,2
	l-0.4-0.5l1.1-1.8c0.6-0.8,1.6-1.3,2.6-1.3c1.8,0,3.2,1.4,3.2,3.7c0,1.8-0.6,3-2.3,5.1c-0.8,0.9-1.9,2.2-2.7,3h3.4
	c1,0,1.3-0.1,1.9-1.4L464.2,736.4z"
        />
        <path
          d="M473.3,726.4c-1.8,4.3-3.5,8.5-5.3,12.9l-1.4,0.2l-0.1-0.2c1.9-3.6,3.9-7.8,5.4-11.4H468c-1.2,0-1.3,0.2-1.8,1.6h-0.6
	c0.2-1.3,0.3-2.3,0.4-3.3h7.2L473.3,726.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M435.8,763.3c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L435.8,763.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M437.7,752l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.1,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.3c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.4-1.5-1.1-1.6c-0.1,0-0.3,0-0.4,0c-0.8,0.1-1.6,0.5-2,1.3
	L437.7,752z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M448.6,750c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S446.1,750,448.6,750z M448.6,750.6
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S449.9,750.6,448.6,750.6L448.6,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M456.4,750c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S453.9,750,456.4,750z M456.4,750.6
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S457.8,750.6,456.4,750.6L456.4,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M469.6,760.2v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.7-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H469.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M480.7,758c-0.2,0.7-0.5,1.6-0.6,2.2H474v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1-1.2,1.9-2.5,1.9-3.8s-0.7-2-1.8-2
	c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.4c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3c0,1.3-0.5,2.3-2,3.8
	c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L480.7,758z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M482.2,748.4c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L482.2,748.4z"
        />
        <path
          d="M501.6,739.3v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.6,0.3-1.6,2.1v8.3
	c0,1.8,0.1,1.9,1.6,2.1v0.6H501.6z"
        />
        <path
          d="M509.6,739.6c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
	C510.9,738.9,510.4,739.5,509.6,739.6C509.7,739.6,509.6,739.6,509.6,739.6z"
        />
        <path
          d="M520,736.4c-0.3,1-0.6,2.1-0.8,2.9h-7v-0.5c1-1.1,2.1-2.4,3-3.6c1.2-1.6,2.2-3.3,2.2-5s-0.8-2.7-2.1-2.7s-2,1.1-2.5,2
	l-0.4-0.5l1.1-1.8c0.6-0.8,1.6-1.3,2.6-1.3c1.8,0,3.2,1.4,3.2,3.7c0,1.8-0.6,3-2.3,5.1c-0.8,0.9-1.9,2.2-2.7,3h3.4
	c1,0,1.3-0.1,1.9-1.4L520,736.4z"
        />
        <path
          d="M524.5,731.3c0.4-0.2,0.8-0.4,1.2-0.4c2,0,3.3,1.7,3.3,3.9c0,2.6-1.6,4.7-3.9,4.7s-3.9-2-3.9-5.3c-0.1-3.4,1.9-6.5,5.1-7.9
	c0.7-0.2,1.5-0.4,2.2-0.6l0.1,0.7c-0.7,0.2-1.4,0.4-2,0.7c-1.8,1-3,2.8-3.3,4.8L524.5,731.3z M524.9,732.2c-0.6,0-1.2,0.2-1.7,0.5
	c-0.1,0.5-0.1,1-0.1,1.5c0,2.7,0.8,4.5,2.3,4.5s1.8-1.7,1.8-3.1C527.3,733.9,526.5,732.2,524.9,732.2L524.9,732.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M495.6,763.3c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.4,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L495.6,763.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M497.5,752l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.8,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.1,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.3c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L497.5,752z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M508.4,750c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S505.9,750,508.4,750z M508.4,750.6
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S509.8,750.6,508.4,750.6L508.4,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M516.2,750c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S513.7,750,516.2,750z M516.2,750.6
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S517.6,750.6,516.2,750.6L516.2,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M529.4,760.2v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5H525v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H529.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M540.5,758c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
	c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L540.5,758z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M542.1,748.4c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L542.1,748.4z"
        />
        <path
          d="M571.4,739.3v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
	c0,1.8,0.2,1.9,1.7,2.1v0.6H571.4z"
        />
        <path
          d="M579.5,739.6c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
	C580.8,738.9,580.2,739.5,579.5,739.6C579.5,739.6,579.5,739.6,579.5,739.6z"
        />
        <path
          d="M589.9,736.4c-0.3,1-0.6,2.1-0.8,2.9h-7v-0.5c1-1.1,2.1-2.4,3-3.6c1.2-1.6,2.2-3.3,2.2-5s-0.8-2.7-2.1-2.7s-2,1.1-2.5,2
	l-0.4-0.5l1.1-1.8c0.6-0.8,1.6-1.3,2.6-1.3c1.8,0,3.2,1.4,3.2,3.7c0,1.8-0.6,3-2.3,5.1c-0.8,0.9-1.9,2.2-2.7,3h3.4
	c1,0,1.3-0.1,1.9-1.4L589.9,736.4z"
        />
        {/* <path
          d="M594.4,731.3c0.4-0.2,0.8-0.4,1.2-0.4c2,0,3.3,1.7,3.3,3.9c0,2.6-1.6,4.7-3.9,4.7s-3.9-2-3.9-5.3c-0.1-3.4,1.9-6.5,5.1-7.9
	c0.7-0.2,1.5-0.4,2.2-0.6l0.1,0.7c-0.7,0.2-1.4,0.4-2,0.7c-1.8,1-3,2.8-3.3,4.8L594.4,731.3z M594.8,732.2c-0.6,0-1.2,0.2-1.7,0.5
	c-0.1,0.5-0.1,1-0.1,1.5c0,2.7,0.8,4.5,2.3,4.5s1.8-1.7,1.8-3.1C597.1,733.9,596.3,732.2,594.8,732.2z"
        /> */}
        <path d="M598.2,726.4l0.1,0.2l-0.5,1.5h-4.3c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.5-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4  c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.9-2.1,1.4-3.3,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.2-0.2-0.4-0.5-0.3-0.7  c0-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.2,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2  c-0.6,0-1.3,0.1-1.8,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4L598.2,726.4z" />
        <path
          style={{ fill: "#136736" }}
          d="M561.5,763.3c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L561.5,763.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M563.3,752l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.8,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.3c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L563.3,752z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M574.2,750c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S571.8,750,574.2,750z M574.2,750.6
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S575.6,750.6,574.2,750.6L574.2,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M582.1,750c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S579.6,750,582.1,750z M582.1,750.6
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S583.4,750.6,582.1,750.6L582.1,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M595.3,760.2v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H595.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M606.4,758c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
	c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L606.4,758z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M607.9,748.4c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L607.9,748.4z"
        />
        <path
          d="M629.4,739.3v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
	c0,1.8,0.2,1.9,1.7,2.1v0.6H629.4z"
        />
        <path
          d="M637.4,739.6c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
	C638.7,738.9,638.2,739.5,637.4,739.6C637.5,739.6,637.5,739.6,637.4,739.6z"
        />
        <path
          d="M647.8,736.4c-0.3,1-0.6,2.1-0.8,2.9h-7v-0.5c1-1.1,2.1-2.4,3-3.6c1.2-1.6,2.2-3.3,2.2-5s-0.8-2.7-2.1-2.7s-2,1.1-2.5,2
	l-0.4-0.5l1.1-1.8c0.6-0.8,1.6-1.3,2.6-1.3c1.8,0,3.2,1.4,3.2,3.7c0,1.8-0.6,3-2.3,5.1c-0.8,0.9-1.9,2.2-2.7,3h3.4
	c1,0,1.3-0.1,1.9-1.4L647.8,736.4z"
        />
        <path
          d="M655.3,735.5v1.5c0,1.4,0.1,1.5,1.4,1.6v0.6h-4.5v-0.6c1.4-0.2,1.5-0.3,1.5-1.7v-1.5h-4.8v-0.7c1.8-2.9,3.7-6,5.5-8.8h0.9
	v8.5h1.8v1.1H655.3z M653.6,728.3c-1.1,1.8-2.4,4-3.7,6.1h3.7V728.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M623.6,763.3c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L623.6,763.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M625.5,752l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.3c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L625.5,752z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M636.4,750c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S633.9,750,636.4,750z M636.4,750.6
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S637.7,750.6,636.4,750.6L636.4,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M644.2,750c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S641.7,750,644.2,750z M644.2,750.6
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S645.5,750.6,644.2,750.6L644.2,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M657.4,760.2v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5H653v-0.5c1-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H657.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M668.5,758c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1-1.2,1.9-2.5,1.9-3.8s-0.7-2-1.8-2
	c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.2c0,1.3-0.5,2.3-2,3.8
	c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L668.5,758z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M670,748.4c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L670,748.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M685.5,763.3c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L685.5,763.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M687.3,752l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.3c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L687.3,752z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M698.2,750c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S695.7,750,698.2,750z M698.2,750.6
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S699.6,750.6,698.2,750.6L698.2,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M706,750c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S703.6,750,706,750z M706,750.6
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S707.4,750.6,706,750.6L706,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M719.3,760.2v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H719.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M730.4,758c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1-1.2,1.9-2.5,1.9-3.8s-0.7-2-1.8-2
	c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3c0,1.3-0.5,2.3-2,3.8
	c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L730.4,758z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M731.9,748.4c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L731.9,748.4z"
        />
        <path
          d="M755.7,739.3v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.2-1.9-1.6-2.1v-0.6h5v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
	c0,1.8,0.2,1.9,1.7,2.1v0.6H755.7z"
        />
        <path
          d="M763.7,739.6c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
	C765,738.9,764.5,739.5,763.7,739.6C763.8,739.6,763.7,739.6,763.7,739.6z"
        />
        <path
          d="M774.1,736.4c-0.3,1-0.6,2.1-0.8,2.9h-7v-0.5c1-1.1,2.1-2.4,3-3.6c1.2-1.6,2.2-3.3,2.2-5s-0.8-2.7-2.1-2.7s-2,1.1-2.5,2
	l-0.5-0.5l1.1-1.8c0.6-0.8,1.6-1.3,2.6-1.3c1.8,0,3.2,1.4,3.2,3.7c0,1.8-0.6,3-2.3,5.1c-0.8,0.9-1.9,2.2-2.7,3h3.4
	c1,0,1.3-0.1,1.9-1.4L774.1,736.4z"
        />
        <path
          d="M783.1,736.4c-0.3,1-0.6,2.1-0.8,2.9h-7v-0.5c1-1.1,2.1-2.4,3-3.6c1.2-1.6,2.2-3.3,2.2-5s-0.8-2.7-2.1-2.7s-2,1.1-2.5,2
	l-0.4-0.5l1.1-1.8c0.6-0.8,1.6-1.3,2.6-1.3c1.8,0,3.2,1.4,3.2,3.7c0,1.8-0.6,3-2.3,5.1c-0.8,0.9-1.9,2.2-2.7,3h3.4
	c1,0,1.3-0.1,1.9-1.4L783.1,736.4z"
        />
        <path
          d="M691.2,739.3v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
	c0,1.8,0.2,1.9,1.7,2.1v0.6H691.2z"
        />
        <path
          d="M699.3,739.6c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
	C700.6,738.9,700.1,739.5,699.3,739.6C699.3,739.6,699.3,739.6,699.3,739.6z"
        />
        <path
          d="M709.7,736.4c-0.3,1-0.6,2.1-0.8,2.9h-7v-0.5c1-1.1,2.1-2.4,3-3.6c1.2-1.6,2.2-3.3,2.2-5s-0.8-2.7-2.1-2.7s-2,1.1-2.5,2
	l-0.5-0.5l1.1-1.8c0.6-0.8,1.6-1.3,2.6-1.3c1.8,0,3.2,1.4,3.2,3.7c0,1.8-0.6,3-2.3,5.1c-0.8,0.9-1.9,2.2-2.7,3h3.4
	c1,0,1.3-0.1,1.9-1.4L709.7,736.4z"
        />
        <path
          d="M711.3,728.5l0.9-1.3c0.6-0.8,1.5-1.2,2.4-1.2c1.5-0.1,2.8,1.1,2.8,2.6c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.1-0.6,1.5
	c-0.4,0.4-0.8,0.8-1.3,1.1c1.6,0.3,2.7,1.6,2.8,3.3c0,3.4-3.6,4.9-4.9,4.9c-0.7,0-1.4-0.3-1.9-0.7c-0.2-0.2-0.3-0.4-0.3-0.7
	c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.3-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.3,0,2.2-1.1,2.2-3.1c0.2-1.3-0.8-2.5-2.1-2.7
	c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.2,0.2l-0.1-0.7c1.7-0.6,2.9-1.4,2.9-3c0-1.3-0.7-2-1.7-2c-1,0.1-1.9,0.7-2.3,1.7L711.3,728.5z
	"
        />
        <path
          style={{ fill: "#136736" }}
          d="M749.9,763.3c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L749.9,763.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M751.8,752l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.3c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L751.8,752z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M762.6,750c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S760.2,750,762.6,750z M762.6,750.6
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S764,750.6,762.6,750.6L762.6,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M770.5,750c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S768,750,770.5,750z M770.5,750.6
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S771.8,750.6,770.5,750.6L770.5,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M783.7,760.2v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H783.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M794.8,758c-0.2,0.7-0.5,1.6-0.7,2.2H788v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
	c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L794.8,758z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M796.3,748.4c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L796.3,748.4z"
        />
        <path
          d="M832.9,739.3v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
	c0,1.8,0.2,1.9,1.7,2.1v0.6H832.9z"
        />
        <path
          d="M840.9,739.6c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
	C842.2,738.9,841.7,739.5,840.9,739.6C841,739.6,840.9,739.6,840.9,739.6z"
        />
        <path
          d="M851.3,736.4c-0.3,1-0.6,2.1-0.8,2.9h-7v-0.5c1-1.1,2.1-2.4,3-3.6c1.2-1.6,2.2-3.3,2.2-5s-0.8-2.7-2.1-2.7s-2,1.1-2.5,2
	l-0.5-0.5l1.1-1.8c0.6-0.8,1.6-1.3,2.6-1.3c1.8,0,3.2,1.4,3.2,3.7c0,1.8-0.6,3-2.3,5.1c-0.8,0.9-1.9,2.2-2.7,3h3.4
	c1,0,1.3-0.1,1.9-1.4L851.3,736.4z"
        />
        <path
          d="M853.6,739.3v-0.6c2-0.1,2.1-0.3,2.1-1.8v-8c0-1.1-0.2-1.1-1-1.2l-0.8-0.1V727c1.2-0.2,2.4-0.5,3.5-1v11
	c0,1.6,0.1,1.7,2.1,1.8v0.6L853.6,739.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M827.1,763.3c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L827.1,763.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M834.7,750.2l0.1,0.1l-0.4,1.2h-3.7c-0.1,0.8-0.3,2.1-0.5,2.8c0.6-0.2,1.3-0.4,1.9-0.5c1.5-0.1,2.9,1,3,2.6
	c0,0,0,0.1,0,0.1c0,1.1-0.5,2.1-1.4,2.7c-0.8,0.7-1.8,1.1-2.9,1.1c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.2-0.3-0.3-0.3-0.6
	c0-0.2,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.6,1.2,0.9,2,0.9c1.3,0,1.9-1.1,1.9-2.3c0.1-1.3-0.9-2.4-2.2-2.4
	c-0.1,0-0.3,0-0.4,0c-0.5,0-1.1,0.1-1.6,0.3l0-0.1c0.3-1.3,0.6-3.5,0.8-4.8L834.7,750.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M837.3,760.2v-0.5c1.8-0.1,1.8-0.2,1.8-1.4v-6.1c0-0.8-0.1-0.8-0.8-0.9l-0.7-0.1v-0.4c1-0.2,2-0.4,3-0.8v8.3
	c0,1.2,0.1,1.3,1.9,1.4v0.5H837.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M847.7,750c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S845.2,750,847.7,750z M847.7,750.6
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S849,750.6,847.7,750.6L847.7,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M860.9,760.2v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H860.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M872,758c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1-1.2,1.9-2.5,1.9-3.8s-0.7-2-1.8-2
	c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3c0,1.3-0.5,2.3-2,3.8
	c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L872,758z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M873.5,748.4c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L873.5,748.4z"
        />
        <path
          d="M319.5,436v-0.6c1.5-0.2,1.6-0.3,1.6-2.1V425c0-1.8-0.1-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.6,0.3-1.6,2.1v8.3
	c0,1.8,0.1,1.9,1.6,2.1v0.6H319.5z"
        />
        <path
          d="M327.5,436.3c-0.7,0-1.2-0.6-1.2-1.3c0,0,0-0.1,0-0.1c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0,0c0.7,0,1.3,0.7,1.2,1.4
	c0,0,0,0,0,0C328.8,435.6,328.3,436.2,327.5,436.3C327.6,436.3,327.5,436.3,327.5,436.3z"
        />
        <path
          d="M337.1,422.9l0.1,0.2l-0.5,1.5h-4.3c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.4-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4
	c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.9-2.1,1.4-3.3,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.2-0.2-0.4-0.5-0.3-0.7
	c0-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.2,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2
	c-0.6,0-1.3,0.1-1.9,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4L337.1,422.9z"
        />
        <path
          d="M340.2,436v-0.6c2-0.1,2.1-0.3,2.1-1.8v-8c0-1.1-0.2-1.1-1-1.2l-0.8-0.1v-0.6c1.2-0.2,2.4-0.5,3.5-1v11
	c0,1.6,0.1,1.7,2.1,1.8v0.6H340.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M309.5,460c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L309.5,460z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M311.4,448.7l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.5
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L311.4,448.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M322.3,446.7c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S319.8,446.7,322.3,446.7z M322.3,447.3
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S323.6,447.3,322.3,447.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M330.1,446.7c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S327.6,446.7,330.1,446.7z M330.1,447.3
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S331.5,447.3,330.1,447.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M343.3,456.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.8-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.6,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L343.3,456.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M354.4,454.7c-0.2,0.8-0.5,1.6-0.6,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
	c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.2
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L354.4,454.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M356,445.1c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L356,445.1z"
        />
        <path
          d="M319.5,521.9v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.1-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.6,0.3-1.6,2.1v8.3
	c0,1.8,0.1,1.9,1.6,2.1v0.6H319.5z"
        />
        <path
          d="M327.5,522.2c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
	C328.8,521.4,328.3,522.1,327.5,522.2C327.6,522.1,327.5,522.1,327.5,522.2z"
        />
        <path
          d="M336.4,518v1.5c0,1.4,0.1,1.5,1.4,1.6v0.6h-4.5v-0.6c1.4-0.2,1.5-0.3,1.5-1.7V518h-4.8v-0.7c1.8-2.9,3.7-6,5.5-8.9h0.9v8.5
	h1.8v1L336.4,518z M334.7,510.9c-1.1,1.8-2.4,4-3.7,6.1h3.7V510.9z"
        />
        <path
          d="M343.2,508.5c1.7,0,3,1.3,3.1,2.9c0,0.1,0,0.1,0,0.2c0,0.6-0.2,1.2-0.5,1.7c-0.5,0.5-1,0.9-1.5,1.3c1.3,0.8,2.5,1.8,2.5,3.7
	c0,2.1-1.6,3.8-3.7,3.9c0,0,0,0-0.1,0c-1.9,0.1-3.5-1.3-3.6-3.1c0-0.1,0-0.3,0-0.4c0-0.8,0.3-1.5,0.8-2.1c0.5-0.5,1.1-0.9,1.7-1.3
	c-1.2-0.7-2-1.9-2-3.3c-0.1-1.9,1.4-3.4,3.3-3.5C343.1,508.5,343.1,508.5,343.2,508.5z M343.2,521.4c1.2,0,1.9-0.9,1.9-2.4
	s-1.2-2.5-2.7-3.3c-0.9,0.6-1.5,1.7-1.4,2.8C341,520.4,342,521.4,343.2,521.4z M342.9,509.2c-1.1,0-1.6,1-1.6,2.1
	c0,1.5,0.9,2.1,2.3,3c0.7-0.6,1.2-1.6,1.1-2.5C344.8,509.9,343.9,509.2,342.9,509.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M309.5,545.8c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L309.5,545.8z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M311.4,534.5l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.2,0.9c1.5,0.3,2.4,1.3,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.6
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L311.4,534.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M322.3,532.6c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S319.8,532.6,322.3,532.6z M322.3,533.2
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.3,1.7-4.6S323.6,533.2,322.3,533.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M330.1,532.6c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S327.6,532.6,330.1,532.6z M330.1,533.2
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S331.5,533.2,330.1,533.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M343.3,542.7v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.7-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H343.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M354.4,540.5c-0.2,0.7-0.5,1.6-0.6,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
	c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L354.4,540.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M356,531c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L356,531z"
        />
        <path
          d="M383.8,436v-0.6c1.5-0.2,1.6-0.3,1.6-2.1V425c0-1.8-0.1-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
	c0,1.8,0.1,1.9,1.7,2.1v0.6H383.8z"
        />
        <path
          d="M391.9,436.3c-0.7,0-1.2-0.6-1.2-1.3c0,0,0-0.1,0-0.1c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4
	c0,0,0,0,0,0C393.2,435.6,392.6,436.2,391.9,436.3C391.9,436.3,391.9,436.3,391.9,436.3z"
        />
        <path
          d="M401.5,422.9l0.1,0.2l-0.5,1.5h-4.3c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.5-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4
	c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.9-2.1,1.4-3.3,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.3-0.3-0.4-0.5-0.3-0.7
	c0-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2
	c-0.6,0-1.3,0.1-1.9,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4L401.5,422.9z"
        />
        <path
          d="M411.3,433.1c-0.3,1-0.6,2.1-0.8,2.9h-7v-0.5c1-1.1,2.1-2.4,3-3.6c1.2-1.6,2.2-3.2,2.2-5s-0.8-2.7-2.1-2.7s-2,1.1-2.5,2
	l-0.5-0.5l1.1-1.8c0.6-0.8,1.6-1.3,2.6-1.3c1.8,0,3.2,1.4,3.2,3.7c0,1.8-0.6,3-2.3,5.1c-0.8,0.9-1.9,2.2-2.7,3h3.4
	c1,0,1.3-0.1,1.9-1.4L411.3,433.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M373.9,460c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.4,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L373.9,460z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M375.8,448.7l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.1,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.5
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c-0.1,0-0.1,0-0.2,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L375.8,448.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M386.6,446.7c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S384.2,446.7,386.6,446.7z M386.6,447.3
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S388,447.3,386.6,447.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M394.5,446.7c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S392,446.7,394.5,446.7z M394.5,447.3
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S395.8,447.3,394.5,447.3L394.5,447.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M407.7,456.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.8-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H407.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M418.8,454.7c-0.2,0.8-0.5,1.6-0.6,2.2H412v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
	c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.2
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L418.8,454.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M420.3,445.1c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L420.3,445.1z"
        />
        <path
          d="M383.8,521.9v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.1-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
	c0,1.8,0.1,1.9,1.7,2.1v0.6H383.8z"
        />
        <path
          d="M391.9,522.2c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
	C393.2,521.4,392.6,522.1,391.9,522.2C391.9,522.1,391.9,522.1,391.9,522.2z"
        />
        <path
          d="M400.7,518v1.5c0,1.4,0.1,1.5,1.4,1.6v0.6h-4.5v-0.6c1.4-0.2,1.5-0.3,1.5-1.7V518h-4.8v-0.7c1.8-2.9,3.7-6,5.5-8.9h0.9v8.5
	h1.8v1L400.7,518z M399.1,510.9c-1.1,1.8-2.4,4-3.6,6.1h3.6V510.9z"
        />
        <path
          d="M411.4,509c-1.8,4.3-3.5,8.6-5.3,12.9l-1.4,0.2l-0.1-0.2c1.9-3.6,3.9-7.8,5.4-11.4h-3.9c-1.2,0-1.3,0.2-1.8,1.5h-0.6
	c0.2-1.3,0.3-2.3,0.4-3.3h7.2L411.4,509z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M373.9,545.8c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.4,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L373.9,545.8z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M375.8,534.5l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.8,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.1,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.6
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c-0.1,0-0.1,0-0.2,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L375.8,534.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M386.6,532.6c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S384.2,532.6,386.6,532.6z M386.6,533.2
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S388,533.2,386.6,533.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M394.5,532.6c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S392,532.6,394.5,532.6z M394.5,533.2
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S395.8,533.2,394.5,533.2L394.5,533.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M407.7,542.7v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.7-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H407.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M418.8,540.5c-0.2,0.7-0.5,1.6-0.6,2.2H412v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
	c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L418.8,540.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M420.3,531c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L420.3,531z"
        />
        <path
          d="M447.8,436v-0.6c1.5-0.2,1.6-0.3,1.6-2.1V425c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.6,0.3-1.6,2.1v8.3
	c0,1.8,0.1,1.9,1.6,2.1v0.6H447.8z"
        />
        <path
          d="M455.9,436.3c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
	C457.2,435.6,456.7,436.2,455.9,436.3C455.9,436.3,455.9,436.3,455.9,436.3z"
        />
        <path
          d="M465.5,422.9l0.1,0.2l-0.5,1.5h-4.3c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.5-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4
	c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.9-2.1,1.4-3.3,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.3-0.3-0.4-0.5-0.3-0.7
	c0-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2
	c-0.6,0-1.3,0.1-1.9,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4L465.5,422.9z"
        />
        <path
          d="M467.9,425.2l0.9-1.4c0.6-0.8,1.5-1.2,2.4-1.2c1.5-0.1,2.8,1.1,2.8,2.6c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.1-0.6,1.6
	c-0.4,0.4-0.8,0.8-1.3,1.1c1.6,0.3,2.7,1.6,2.8,3.3c0,3.4-3.6,4.9-4.9,4.9c-0.7,0-1.4-0.3-1.9-0.7c-0.2-0.2-0.3-0.5-0.3-0.7
	c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.3-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.3,0,2.1-1.1,2.1-3.1c0.2-1.3-0.8-2.5-2.1-2.7
	c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.2,0.2l-0.1-0.6c1.6-0.6,2.9-1.4,2.9-3c0-1.3-0.7-2-1.7-2c-1,0.1-1.9,0.7-2.3,1.6L467.9,425.2z
	"
        />
        <path
          style={{ fill: "#136736" }}
          d="M437.9,460c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.4,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L437.9,460z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M439.8,448.7l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.1,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.5
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c-0.1,0-0.1,0-0.2,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L439.8,448.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M450.6,446.7c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S448.2,446.7,450.6,446.7z M450.6,447.3
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S452,447.3,450.6,447.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M458.5,446.7c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S456,446.7,458.5,446.7z M458.5,447.3
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S459.8,447.3,458.5,447.3L458.5,447.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M471.7,456.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.8-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H471.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M482.8,454.7c-0.2,0.8-0.5,1.6-0.6,2.2H476v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
	c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.2
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L482.8,454.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M484.3,445.1c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L484.3,445.1z"
        />
        <path
          d="M447.8,521.9v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.6,0.3-1.6,2.1v8.3
	c0,1.8,0.1,1.9,1.6,2.1v0.6H447.8z"
        />
        <path
          d="M455.9,522.2c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
	C457.2,521.4,456.7,522.1,455.9,522.2C455.9,522.1,455.9,522.1,455.9,522.2z"
        />
        <path
          d="M464.7,518v1.5c0,1.4,0.1,1.5,1.4,1.6v0.6h-4.5v-0.6c1.4-0.2,1.4-0.3,1.4-1.7V518h-4.8v-0.7c1.8-2.9,3.7-6,5.5-8.9h0.9v8.5
	h1.8v1L464.7,518z M463.1,510.9c-1.1,1.8-2.4,4-3.6,6.1h3.6V510.9z"
        />
        <path
          d="M470.8,513.9c0.4-0.2,0.8-0.4,1.2-0.4c2,0,3.3,1.7,3.3,3.9c0,2.5-1.6,4.7-3.9,4.7s-3.9-2-3.9-5.3c-0.1-3.4,1.9-6.5,5.1-7.9
	c0.7-0.2,1.5-0.4,2.2-0.6l0.1,0.7c-0.7,0.2-1.4,0.4-2,0.7c-1.8,1-3,2.8-3.3,4.8L470.8,513.9z M471.2,514.8c-0.6,0-1.2,0.2-1.7,0.5
	c-0.1,0.5-0.1,1-0.1,1.5c0,2.7,0.9,4.5,2.4,4.5s1.8-1.7,1.8-3.1C473.5,516.4,472.8,514.8,471.2,514.8z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M437.9,545.8c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.4,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L437.9,545.8z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M439.8,534.5l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.8,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.1,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.6
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c-0.1,0-0.1,0-0.2,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L439.8,534.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M450.6,532.6c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S448.2,532.6,450.6,532.6z M450.6,533.2
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S452,533.2,450.6,533.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M458.5,532.6c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S456,532.6,458.5,532.6z M458.5,533.2
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S459.8,533.2,458.5,533.2L458.5,533.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M471.7,542.7v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.7-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H471.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M482.8,540.5c-0.2,0.7-0.5,1.6-0.6,2.2H476v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
	c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L482.8,540.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M484.3,531c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L484.3,531z"
        />
        <path
          d="M511.5,436v-0.6c1.5-0.2,1.6-0.3,1.6-2.1V425c0-1.8-0.2-1.9-1.6-2.1v-0.6h5v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
	c0,1.8,0.2,1.9,1.7,2.1v0.6H511.5z"
        />
        <path
          d="M519.5,436.3c-0.7,0-1.2-0.6-1.2-1.3c0,0,0-0.1,0-0.1c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4
	c0,0,0,0,0,0C520.8,435.6,520.3,436.2,519.5,436.3C519.6,436.3,519.5,436.3,519.5,436.3z"
        />
        <path
          d="M529.1,422.9l0.1,0.2l-0.5,1.5h-4.3c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.5-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4
	c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.9-2.1,1.4-3.3,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.2-0.2-0.4-0.5-0.3-0.7
	c0-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2
	c-0.6,0-1.3,0.1-1.8,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4L529.1,422.9z"
        />
        <path
          d="M537.3,432.2v1.5c0,1.4,0.1,1.5,1.4,1.6v0.6h-4.5v-0.6c1.4-0.1,1.4-0.3,1.4-1.7v-1.5h-4.8v-0.7c1.8-2.9,3.7-6,5.5-8.9h0.9
	v8.5h1.8v1L537.3,432.2z M535.7,425c-1.1,1.9-2.4,4-3.7,6.1h3.7V425z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M501.5,460c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.4,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L501.5,460z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M503.4,448.7l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.1,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.1,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.5
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c-0.1,0-0.1,0-0.2,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L503.4,448.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M514.3,446.7c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S511.8,446.7,514.3,446.7z M514.3,447.3
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S515.6,447.3,514.3,447.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M522.1,446.7c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S519.6,446.7,522.1,446.7z M522.1,447.3
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S523.5,447.3,522.1,447.3L522.1,447.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M535.3,456.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H535.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M546.4,454.7c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
	c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.2
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L546.4,454.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M548,445.1c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L548,445.1z"
        />
        <path
          d="M511.5,521.9v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.2-1.9-1.6-2.1v-0.6h5v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
	c0,1.8,0.2,1.9,1.7,2.1v0.6H511.5z"
        />
        <path
          d="M519.5,522.2c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
	C520.8,521.4,520.3,522.1,519.5,522.2C519.6,522.1,519.5,522.1,519.5,522.2z"
        />
        <path
          d="M528.3,518v1.5c0,1.4,0.1,1.5,1.4,1.6v0.6h-4.5v-0.6c1.4-0.2,1.5-0.3,1.5-1.7V518h-4.8v-0.7c1.8-2.9,3.7-6,5.5-8.9h0.9v8.5
	h1.8v1L528.3,518z M526.7,510.9c-1.1,1.8-2.4,4-3.7,6.1h3.7V510.9z"
        />
        <path
          d="M538.1,508.7l0.1,0.2l-0.5,1.5h-4.3c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.4-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4
	c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.9-2.1,1.4-3.3,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.2-0.2-0.4-0.5-0.3-0.7
	c0-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.2,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2
	c-0.6,0-1.3,0.1-1.8,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4L538.1,508.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M501.5,545.8c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.4,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L501.5,545.8z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M503.4,534.5l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.3,0.3-0.7,0.6-1.1,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.1,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.6
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c-0.1,0-0.1,0-0.2,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L503.4,534.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M514.3,532.6c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S511.8,532.6,514.3,532.6z M514.3,533.2
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.3,1.7-4.6S515.6,533.2,514.3,533.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M522.1,532.6c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S519.6,532.6,522.1,532.6z M522.1,533.2
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S523.5,533.2,522.1,533.2L522.1,533.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M535.3,542.7v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.7-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H535.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M546.4,540.5c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
	c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L546.4,540.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M548,531c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L548,531z"
        />
        <path
          d="M572.8,436v-0.6c1.5-0.2,1.6-0.3,1.6-2.1V425c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
	c0,1.8,0.2,1.9,1.7,2.1v0.6H572.8z"
        />
        <path
          d="M580.9,436.3c-0.7,0-1.2-0.6-1.2-1.3c0,0,0-0.1,0-0.1c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4
	c0,0,0,0,0,0C582.2,435.6,581.6,436.2,580.9,436.3C580.9,436.3,580.9,436.3,580.9,436.3z"
        />
        <path
          d="M590.5,422.9l0.1,0.2l-0.5,1.5h-4.3c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.5-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4
	c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.9-2.1,1.4-3.3,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.2-0.2-0.4-0.5-0.3-0.7
	c0-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.2,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2
	c-0.6,0-1.3,0.1-1.8,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4L590.5,422.9z"
        />
        <path
          d="M599.5,422.9l0.1,0.2l-0.5,1.5h-4.3c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.5-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4
	c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.9-2.1,1.4-3.3,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.3-0.3-0.4-0.5-0.3-0.7
	c0-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2
	c-0.6,0-1.3,0.1-1.8,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4L599.5,422.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M562.9,460c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c0,2.6,0.9,5.1,2.6,7L562.9,460z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M564.8,448.7l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.5
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L564.8,448.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M575.6,446.7c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S573.2,446.7,575.6,446.7z M575.6,447.3
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S577,447.3,575.6,447.3L575.6,447.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M583.5,446.7c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S581,446.7,583.5,446.7z M583.5,447.3
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S584.8,447.3,583.5,447.3L583.5,447.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M596.7,456.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.7-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H596.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M607.8,454.7c-0.2,0.8-0.5,1.6-0.7,2.2H601v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
	c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L607.8,454.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M609.3,445.1c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L609.3,445.1z"
        />
        <path
          d="M572.8,521.9v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
	c0,1.8,0.2,1.9,1.7,2.1v0.6H572.8z"
        />
        <path
          d="M580.9,522.2c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
	C582.2,521.4,581.6,522.1,580.9,522.2C580.9,522.1,580.9,522.1,580.9,522.2z"
        />
        <path
          d="M589.7,518v1.5c0,1.4,0.1,1.5,1.4,1.6v0.6h-4.5v-0.6c1.4-0.2,1.5-0.3,1.5-1.7V518h-4.8v-0.7c1.8-2.9,3.7-6,5.5-8.9h0.9v8.5
	h1.8v1L589.7,518z M588.1,510.9c-1.1,1.8-2.4,4-3.7,6.1h3.7V510.9z"
        />
        <path
          d="M598.7,518v1.5c0,1.4,0.1,1.5,1.4,1.6v0.6h-4.5v-0.6c1.4-0.2,1.4-0.3,1.4-1.7V518h-4.8v-0.7c1.8-2.9,3.7-6,5.5-8.9h0.9v8.5
	h1.8v1L598.7,518z M597.1,510.9c-1.1,1.8-2.4,4-3.7,6.1h3.7V510.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M562.9,545.8c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c0,2.6,0.9,5.1,2.6,7L562.9,545.8z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M564.8,534.5l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.3,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.6
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L564.8,534.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M575.6,532.6c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S573.2,532.6,575.6,532.6z M575.6,533.2
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S577,533.2,575.6,533.2L575.6,533.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M583.5,532.6c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S581,532.6,583.5,532.6z M583.5,533.2
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S584.8,533.2,583.5,533.2L583.5,533.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M596.7,542.7v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.7-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.2,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H596.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M607.8,540.5c-0.2,0.7-0.5,1.6-0.7,2.2H601v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
	c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.2
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L607.8,540.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M609.3,531c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L609.3,531z"
        />
        <path
          d="M637,436v-0.6c1.5-0.2,1.6-0.3,1.6-2.1V425c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
	c0,1.8,0.2,1.9,1.7,2.1v0.6H637z"
        />
        <path
          d="M645,436.3c-0.7,0-1.2-0.6-1.2-1.3c0,0,0-0.1,0-0.1c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4
	c0,0,0,0,0,0C646.3,435.6,645.8,436.2,645,436.3C645.1,436.3,645.1,436.3,645,436.3z"
        />
        <path
          d="M654.7,422.9l0.1,0.2l-0.5,1.5H650c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.5-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4
	c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.9-2.1,1.4-3.3,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.2-0.2-0.4-0.5-0.3-0.7
	c0-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2
	c-0.6,0-1.3,0.1-1.8,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4L654.7,422.9z"
        />
        <path
          d="M660,428c0.4-0.2,0.8-0.3,1.2-0.4c2,0,3.3,1.7,3.3,3.9c0,2.6-1.6,4.7-3.9,4.7s-3.9-2-3.9-5.3c-0.1-3.4,1.9-6.5,5.1-7.9
	c0.7-0.2,1.4-0.4,2.2-0.6l0.1,0.7c-0.7,0.2-1.4,0.4-2,0.7c-1.8,1-3,2.8-3.3,4.8L660,428z M660.4,428.9c-0.6,0-1.2,0.2-1.7,0.5
	c-0.1,0.5-0.1,1-0.1,1.5c0,2.7,0.8,4.5,2.3,4.5s1.8-1.7,1.8-3.1C662.7,430.6,661.9,428.9,660.4,428.9L660.4,428.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M627.1,460c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c0,2.6,0.9,5.1,2.6,7L627.1,460z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M628.9,448.7l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.5
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L628.9,448.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M639.8,446.7c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S637.3,446.7,639.8,446.7z M639.8,447.3
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S641.2,447.3,639.8,447.3L639.8,447.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M647.7,446.7c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S645.2,446.7,647.7,446.7z M647.7,447.3
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S649,447.3,647.7,447.3L647.7,447.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M660.9,456.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5H652v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H660.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M672,454.7c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
	c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.2
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L672,454.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M673.5,445.1c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L673.5,445.1z"
        />
        <path
          d="M637,521.9v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
	c0,1.8,0.2,1.9,1.7,2.1v0.6H637z"
        />
        <path
          d="M645,522.2c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
	C646.3,521.4,645.8,522.1,645,522.2C645.1,522.1,645.1,522.1,645,522.2z"
        />
        <path
          d="M653.9,518v1.5c0,1.4,0.1,1.5,1.4,1.6v0.6h-4.5v-0.6c1.4-0.2,1.5-0.3,1.5-1.7V518h-4.8v-0.7c1.8-2.9,3.7-6,5.5-8.9h0.9v8.5
	h1.8v1L653.9,518z M652.3,510.9c-1.1,1.8-2.4,4-3.7,6.1h3.7V510.9z"
        />
        <path
          d="M657.1,511l0.9-1.4c0.6-0.8,1.5-1.2,2.4-1.2c1.5-0.1,2.7,1.1,2.8,2.6c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.1-0.6,1.6
	c-0.4,0.4-0.8,0.8-1.3,1.2c1.6,0.3,2.7,1.6,2.8,3.2c0,3.4-3.6,4.9-4.9,4.9c-0.7,0-1.4-0.3-1.9-0.7c-0.2-0.2-0.3-0.5-0.3-0.7
	c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.3-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.3,0,2.2-1.1,2.2-3.1c0.2-1.3-0.8-2.5-2.1-2.7
	c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.2,0.2l-0.1-0.7c1.6-0.6,2.9-1.4,2.9-3c0.2-0.9-0.5-1.8-1.4-2c-0.1,0-0.2,0-0.3,0
	c-1,0.1-1.9,0.7-2.3,1.7L657.1,511z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M627.1,545.8c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c0,2.6,0.9,5.1,2.6,7L627.1,545.8z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M628.9,534.5l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.3,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.6
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L628.9,534.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M639.8,532.6c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S637.3,532.6,639.8,532.6z M639.8,533.2
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S641.2,533.2,639.8,533.2L639.8,533.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M647.7,532.6c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S645.2,532.6,647.7,532.6z M647.7,533.2
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S649,533.2,647.7,533.2L647.7,533.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M660.9,542.7v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5H652v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.7-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H660.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M672,540.5c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
	c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L672,540.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M673.5,531c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L673.5,531z"
        />
        <path
          d="M700.6,436v-0.6c1.5-0.2,1.6-0.3,1.6-2.1V425c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
	c0,1.8,0.2,1.9,1.7,2.1v0.6H700.6z"
        />
        <path
          d="M708.6,436.3c-0.7,0-1.2-0.6-1.2-1.3c0,0,0-0.1,0-0.1c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4
	c0,0,0,0,0,0C709.9,435.6,709.4,436.2,708.6,436.3C708.7,436.3,708.7,436.3,708.6,436.3z"
        />
        <path
          d="M718.3,422.9l0.1,0.2l-0.5,1.5h-4.3c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.4-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4
	c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.9-2.1,1.4-3.3,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.2-0.2-0.4-0.5-0.3-0.7
	c0.1-0.3,0.2-0.6,0.4-0.7c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.2,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2
	c-0.6,0-1.3,0.1-1.8,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4L718.3,422.9z"
        />
        <path
          d="M728.2,423.1c-1.8,4.3-3.5,8.6-5.3,12.9l-1.4,0.2l-0.1-0.2c1.9-3.6,3.9-7.9,5.4-11.4h-3.9c-1.2,0-1.3,0.2-1.8,1.5h-0.6
	c0.2-1.3,0.3-2.3,0.4-3.3h7.2L728.2,423.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M698.7,460c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L698.7,460z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M705.6,454v1.2c0,1,0.1,1.1,1.2,1.2v0.5h-3.9v-0.5c1.2-0.1,1.3-0.2,1.3-1.3V454H700v-0.5
	c1.5-2.2,3.3-4.6,4.8-6.7h0.8v6.4h1.5v0.8H705.6z M704.2,448.6c-1,1.4-2.1,3-3.2,4.6h3.2V448.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M714.8,454.7c-0.2,0.8-0.5,1.6-0.7,2.2H708v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
	c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.2
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L714.8,454.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M721.9,446.9l0.1,0.1l-0.4,1.1h-3.7c-0.1,0.9-0.3,2.1-0.5,2.8c0.6-0.2,1.3-0.4,1.9-0.5c1.5-0.1,2.9,1,3,2.6
	c0,0,0,0.1,0,0.1c0,1.1-0.5,2.1-1.4,2.7c-0.8,0.7-1.8,1.1-2.9,1.1c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.2-0.3-0.4-0.3-0.6
	c0-0.2,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.6,1.2,0.9,2,0.9c1.3,0,1.9-1.1,1.9-2.3c0.1-1.3-0.9-2.3-2.1-2.4
	c-0.1,0-0.3,0-0.4,0c-0.5,0-1.1,0.1-1.6,0.3v-0.1c0.3-1.3,0.6-3.5,0.8-4.8L721.9,446.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M732.4,456.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5H728v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.7-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H732.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M743.5,454.7c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
	c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.2
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L743.5,454.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M745.1,445.1c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L745.1,445.1z"
        />
        <path
          d="M692.1,521.9v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
	c0,1.8,0.2,1.9,1.7,2.1v0.6H692.1z"
        />
        <path
          d="M700.2,522.2c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
	C701.5,521.4,701,522.1,700.2,522.2C700.2,522.1,700.2,522.1,700.2,522.2z"
        />
        <path
          d="M709,518v1.5c0,1.4,0.1,1.5,1.4,1.6v0.6H706v-0.6c1.4-0.2,1.4-0.3,1.4-1.7V518h-4.8v-0.7c1.8-2.9,3.7-6,5.5-8.9h0.9v8.5h1.8
	v1L709,518z M707.4,510.9c-1.1,1.8-2.4,4-3.7,6.1h3.7V510.9z"
        />
        <path
          d="M719.6,518.9c-0.3,1-0.6,2.1-0.8,2.9h-7v-0.5c1-1.1,2.1-2.4,3-3.6c1.2-1.6,2.2-3.3,2.2-5s-0.8-2.7-2.1-2.7s-2,1.1-2.5,2
	l-0.5-0.5l1.1-1.8c0.6-0.8,1.6-1.3,2.6-1.3c1.8,0,3.2,1.4,3.2,3.7c0,1.8-0.6,3-2.3,5.1c-0.8,0.9-1.9,2.2-2.7,3h3.4
	c1,0,1.3-0.1,1.9-1.4L719.6,518.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M690.7,545.8c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L690.7,545.8z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M692.5,534.5l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.3,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.6
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L692.5,534.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M703.4,532.6c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S700.9,532.6,703.4,532.6z M703.4,533.2
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S704.8,533.2,703.4,533.2L703.4,533.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M711.2,532.6c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S708.8,532.6,711.2,532.6z M711.2,533.2
	c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.8-2.3,1.8-4.6S712.6,533.2,711.2,533.2L711.2,533.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M724.4,542.7v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5H720v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.7-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.2,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H724.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M735.6,540.5c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
	c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.2
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L735.6,540.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M737.1,531c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L737.1,531z"
        />
        <path
          d="M773.3,521.9v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
	c0,1.8,0.2,1.9,1.7,2.1v0.6H773.3z"
        />
        <path
          d="M781.3,522.2c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
	C782.6,521.4,782.1,522.1,781.3,522.2C781.4,522.1,781.3,522.1,781.3,522.2z"
        />
        <path
          d="M790.1,518v1.5c0,1.4,0.1,1.5,1.4,1.6v0.6h-4.5v-0.6c1.4-0.2,1.4-0.3,1.4-1.7V518h-4.8v-0.7c1.8-2.9,3.7-6,5.5-8.9h0.9v8.5
	h1.8v1L790.1,518z M788.5,510.9c-1.1,1.8-2.4,4-3.7,6.1h3.7V510.9z"
        />
        <path
          d="M794,521.9v-0.6c2-0.1,2.1-0.3,2.1-1.8v-8c0-1.1-0.2-1.1-1-1.2l-0.8-0.1v-0.6c1.2-0.2,2.4-0.5,3.5-1v10.9
	c0,1.6,0.1,1.7,2.1,1.8v0.6L794,521.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M763.3,545.8c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
	c-0.1,2.6,0.9,5.1,2.6,7L763.3,545.8z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M765.2,534.5l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
	c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.6
	c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0
	c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
	L765.2,534.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M776.2,532.6c1.4-0.1,2.6,0.9,2.7,2.3c0,0,0,0.1,0,0.1c0,0.5-0.2,0.9-0.5,1.3c-0.4,0.4-0.8,0.7-1.3,1
	c1.1,0.6,2.2,1.4,2.2,2.8c-0.1,1.7-1.5,3-3.3,2.9c0,0,0,0,0,0c-1.8,0-3.2-1-3.2-2.7c0-0.6,0.2-1.2,0.7-1.6c0.5-0.4,0.9-0.7,1.4-1
	c-1-0.4-1.7-1.4-1.8-2.5C773.3,533.7,774.6,532.5,776.2,532.6C776.1,532.6,776.2,532.6,776.2,532.6z M776.2,542.3
	c0.9,0,1.6-0.6,1.7-1.5c0-0.1,0-0.2,0-0.3c0-1.3-1-1.9-2.4-2.5c-0.8,0.4-1.3,1.2-1.3,2.1c-0.1,1.1,0.7,2.1,1.8,2.2
	C776.1,542.3,776.2,542.3,776.2,542.3z M776,533.1c-0.8,0-1.4,0.6-1.4,1.4c0,0.1,0,0.1,0,0.2c0,1.2,0.8,1.6,2,2.2
	c0.6-0.4,1-1.2,1-1.9C777.6,533.7,776.8,533.1,776,533.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M786.6,532.8l0.1,0.1l-0.4,1.2h-3.7c-0.1,0.8-0.3,2.1-0.5,2.8c0.6-0.2,1.3-0.4,1.9-0.5c1.5-0.1,2.9,1,3,2.6
	c0,0,0,0.1,0,0.1c0,1.1-0.5,2.1-1.4,2.7c-0.8,0.7-1.8,1.1-2.9,1.1c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.2-0.3-0.3-0.3-0.6
	c0-0.2,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.6,1.2,0.9,2,0.9c1.3,0,1.9-1.1,1.9-2.3c0.1-1.3-0.9-2.4-2.2-2.4
	c-0.1,0-0.3,0-0.4,0c-0.5,0-1.1,0.1-1.6,0.3l0-0.1c0.3-1.3,0.6-3.5,0.8-4.8L786.6,532.8z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M797.1,542.7v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7
	v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
	c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
	c0.4-0.3,0.7-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.2,0.8-0.4,1.3-0.4
	c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H797.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M808.2,540.5c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.7-2.4,1.9-3.8
	c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
	c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L808.2,540.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M809.8,531c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
	c0.1-2.6-0.9-5.1-2.6-7L809.8,531z"
        />
        <g id="_1-3">
          <path
            d="M879.4,917.1v-0.6c1.5-0.2,1.6-0.3,1.6-2.1V906c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.7c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
		c0,1.8,0.2,1.9,1.7,2.1v0.6L879.4,917.1z"
          />
          <path
            d="M887.4,917.4c-0.7,0-1.2-0.6-1.2-1.3c0,0,0-0.1,0-0.1c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4
		c0,0,0,0,0,0C888.7,916.6,888.2,917.3,887.4,917.4C887.5,917.4,887.5,917.4,887.4,917.4z"
          />
          <path
            d="M897.8,914.1c-0.3,1-0.6,2.1-0.8,2.9h-7v-0.5c1-1.1,2.1-2.4,3-3.6c1.2-1.6,2.2-3.2,2.2-5s-0.8-2.7-2.1-2.7s-2,1.1-2.5,2
		l-0.4-0.5l1.1-1.8c0.6-0.8,1.6-1.3,2.6-1.3c1.8,0,3.2,1.4,3.2,3.7c0,1.8-0.6,3-2.3,5.1c-0.8,0.9-1.9,2.2-2.7,3h3.4
		c1,0,1.3-0.1,1.9-1.4L897.8,914.1z"
          />
          <path
            d="M903,903.7c2.8,0,3.9,3.6,3.9,6.8s-1,6.8-3.9,6.8s-3.9-3.5-3.9-6.8C899.1,907.4,900.1,903.7,903,903.7z M903,904.4
		c-1.6,0-2,3.1-2,6s0.5,6.1,2,6.1s2-3.1,2-6.1S904.5,904.4,903,904.4L903,904.4z"
          />
          <path
            d="M815.9,917.1v-0.6c1.5-0.2,1.6-0.3,1.6-2.1V906c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.7c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
		c0,1.8,0.2,1.9,1.7,2.1v0.6L815.9,917.1z"
          />
          <path
            d="M823.9,917.3c-0.8-0.1-1.3-0.9-1.2-1.7c0.1-0.6,0.6-1,1.2-1.2c0.8,0.1,1.3,0.8,1.2,1.6
		C825.1,916.8,824.6,917.3,823.9,917.3z"
          />
          <path
            d="M827.6,917.1v-0.6c2-0.1,2.1-0.3,2.1-1.8v-8c0-1.1-0.2-1.1-1-1.2l-0.8-0.1v-0.6c1.2-0.2,2.4-0.5,3.5-1v11
		c0,1.6,0.1,1.7,2.1,1.8v0.6L827.6,917.1z"
          />
          <path
            d="M841.2,911l-1.2,0.7c-0.3,0.2-0.7,0.3-1.1,0.3c-2,0-3.3-1.8-3.3-4c0-2,1.4-4.4,3.8-4.4s3.8,2.4,3.8,5.3
		c0.1,3.3-1.7,6.4-4.7,7.9c-0.7,0.3-1.5,0.5-2.3,0.6l-0.1-0.7C838.8,916.1,840.7,914.2,841.2,911z M839.2,904.4
		c-1.3,0-1.8,1.5-1.8,3c0,1.8,0.9,3.4,2.4,3.4c0.6,0,1.1-0.2,1.5-0.5c0.1-0.5,0.1-0.9,0.1-1.4C841.4,906.9,840.9,904.4,839.2,904.4
		L839.2,904.4z"
          />
          <path
            d="M752.8,916.5v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
		c0,1.8,0.1,1.9,1.7,2.1v0.6H752.8z"
          />
          <path
            d="M760.8,916.8c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
		C762.1,916.1,761.6,916.7,760.8,916.8C760.9,916.8,760.8,916.8,760.8,916.8z"
          />
          <path
            d="M764.5,916.5v-0.6c2-0.1,2.1-0.3,2.1-1.8v-8c0-1.1-0.2-1.1-1-1.2l-0.8-0.1v-0.6c1.2-0.2,2.4-0.5,3.5-1V914
		c0,1.6,0.1,1.7,2.1,1.8v0.6L764.5,916.5z"
          />
          <path
            d="M776.5,903.1c1.7,0,3,1.3,3.1,2.9c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.2-0.5,1.7c-0.5,0.5-1,0.9-1.5,1.3
		c1.3,0.8,2.5,1.8,2.5,3.7c0,2.1-1.6,3.8-3.7,3.9c0,0,0,0-0.1,0c-1.9,0.1-3.5-1.3-3.6-3.1c0-0.1,0-0.3,0-0.4c0-0.8,0.3-1.5,0.8-2.1
		c0.5-0.5,1.1-0.9,1.7-1.3c-1.2-0.7-2-1.9-2-3.3C773.1,904.7,774.5,903.2,776.5,903.1C776.4,903.1,776.5,903.1,776.5,903.1z
		 M776.5,916c1.2,0,1.9-0.9,1.9-2.4s-1.2-2.5-2.7-3.3c-0.9,0.6-1.5,1.7-1.4,2.8C774.3,915,775.3,916,776.5,916L776.5,916z
		 M776.3,903.8c-1.1,0-1.6,1-1.6,2.1c0,1.5,0.9,2.1,2.3,3c0.7-0.6,1.2-1.6,1.1-2.6C778.1,904.5,777.2,903.8,776.3,903.8z"
          />
          <path
            d="M689.6,916.7v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.2-1.9-1.6-2.1V903h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
		c0,1.8,0.2,1.9,1.7,2.1v0.6L689.6,916.7z"
          />
          <path
            d="M697.6,917c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
		C698.9,916.3,698.4,916.9,697.6,917C697.7,917,697.6,917,697.6,917z"
          />
          <path
            d="M701.3,916.7v-0.6c2-0.1,2.1-0.3,2.1-1.8v-8c0-1.1-0.2-1.1-1-1.2l-0.8-0.1v-0.6c1.2-0.2,2.4-0.5,3.5-1v11
		c0,1.6,0.1,1.7,2.1,1.8v0.6L701.3,916.7z"
          />
          <path
            d="M717.2,903.8c-1.8,4.3-3.5,8.5-5.3,12.9l-1.4,0.2l-0.1-0.2c1.9-3.6,3.9-7.8,5.4-11.4h-3.9c-1.2,0-1.3,0.2-1.8,1.6h-0.6
		c0.2-1.3,0.3-2.3,0.4-3.3h7.2L717.2,903.8z"
          />
          <path
            d="M626.2,916.4v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
		c0,1.8,0.2,1.9,1.7,2.1v0.6H626.2z"
          />
          <path
            d="M634.3,916.7c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
		C635.6,916,635.1,916.6,634.3,916.7C634.3,916.7,634.3,916.7,634.3,916.7z"
          />
          <path
            d="M638,916.4v-0.6c2-0.1,2.1-0.3,2.1-1.8v-8c0-1.1-0.2-1.1-1-1.2l-0.8-0.1V904c1.2-0.2,2.4-0.5,3.5-1v11
		c0,1.6,0.1,1.7,2.1,1.8v0.6L638,916.4z"
          />
          <path
            d="M649.2,908.4c0.4-0.2,0.8-0.4,1.2-0.4c2,0,3.3,1.7,3.3,3.9c0,2.6-1.6,4.7-3.9,4.7s-3.9-2-3.9-5.3c-0.1-3.4,1.9-6.5,5.1-7.9
		c0.7-0.2,1.5-0.4,2.2-0.6l0.1,0.7c-0.7,0.2-1.4,0.4-2,0.7c-1.8,1-3,2.8-3.3,4.8L649.2,908.4z M649.6,909.3c-0.6,0-1.2,0.2-1.7,0.5
		c-0.1,0.5-0.1,1-0.1,1.5c0,2.7,0.8,4.5,2.3,4.5s1.8-1.7,1.8-3.1C651.9,911,651.2,909.3,649.6,909.3z"
          />
          <path
            d="M562.8,916.8v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
		c0,1.8,0.2,1.9,1.7,2.1v0.6H562.8z"
          />
          <path
            d="M570.8,917.1c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
		C572.1,916.4,571.6,917,570.8,917.1C570.9,917.1,570.9,917.1,570.8,917.1z"
          />
          <path
            d="M574.5,916.8v-0.6c2-0.1,2.1-0.3,2.1-1.8v-8c0-1.1-0.2-1.1-1-1.2l-0.8-0.1v-0.6c1.2-0.2,2.4-0.5,3.5-1v11
		c0,1.6,0.1,1.7,2.1,1.8v0.6L574.5,916.8z"
          />
          <path
            d="M589.5,903.7l0.1,0.2l-0.5,1.5h-4.3c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.5-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4
		c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.9-2,1.4-3.3,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.2-0.2-0.4-0.5-0.3-0.7
		c0-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2
		c-0.6,0-1.3,0.1-1.8,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4L589.5,903.7z"
          />
          <path
            d="M501.4,916.5v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.1-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.6,0.3-1.6,2.1v8.3
		c0,1.8,0.1,1.9,1.6,2.1v0.6H501.4z"
          />
          <path
            d="M509.5,916.8c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
		C510.8,916.1,510.3,916.7,509.5,916.8C509.5,916.8,509.5,916.8,509.5,916.8z"
          />
          <path
            d="M513.2,916.5v-0.6c2-0.1,2.1-0.3,2.1-1.8v-8c0-1.1-0.2-1.1-1-1.2l-0.8-0.1v-0.6c1.2-0.2,2.4-0.5,3.5-1V914
		c0,1.6,0.1,1.7,2.1,1.8v0.6L513.2,916.5z"
          />
          <path
            d="M527.3,912.7v1.5c0,1.4,0.1,1.5,1.4,1.6v0.6h-4.5v-0.6c1.4-0.2,1.4-0.3,1.4-1.7v-1.5h-4.8V912c1.8-2.9,3.7-6,5.5-8.8h0.9
		v8.5h1.8v1.1L527.3,912.7z M525.7,905.5c-1.1,1.8-2.4,4-3.7,6.1h3.7V905.5z"
          />
          <path
            d="M436,916.5v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.1-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.6,0.3-1.6,2.1v8.3
		c0,1.8,0.1,1.9,1.6,2.1v0.6H436z"
          />
          <path
            d="M444.1,916.8c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
		C445.4,916.1,444.9,916.7,444.1,916.8C444.1,916.8,444.1,916.8,444.1,916.8z"
          />
          <path
            d="M447.8,916.5v-0.6c2-0.1,2.1-0.3,2.1-1.8v-8c0-1.1-0.2-1.1-1-1.2l-0.8-0.1v-0.6c1.2-0.2,2.4-0.5,3.5-1V914
		c0,1.6,0.1,1.7,2.1,1.8v0.6L447.8,916.5z"
          />
          <path
            d="M456.1,905.7l0.9-1.3c0.6-0.8,1.5-1.2,2.4-1.2c1.5-0.1,2.8,1.1,2.8,2.6c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.1-0.6,1.5
		c-0.4,0.4-0.8,0.8-1.3,1.1c1.6,0.3,2.7,1.6,2.8,3.3c0,3.4-3.6,4.9-4.9,4.9c-0.7,0-1.4-0.3-1.9-0.7c-0.2-0.2-0.3-0.4-0.3-0.7
		c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.3-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.3,0,2.1-1.1,2.1-3.1c0.2-1.3-0.8-2.5-2.1-2.7
		c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.2,0.2l-0.1-0.7c1.6-0.6,2.9-1.4,2.9-3c0-1.3-0.7-2-1.7-2c-1,0.1-1.9,0.7-2.3,1.7L456.1,905.7
		z"
          />
          <path
            d="M373,916.5v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.1-1.9-1.6-2.1v-0.6h5v0.6c-1.5,0.2-1.6,0.3-1.6,2.1v8.3
		c0,1.8,0.1,1.9,1.6,2.1v0.6H373z"
          />
          <path
            d="M381.1,916.8c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
		C382.4,916.1,381.9,916.7,381.1,916.8C381.1,916.8,381.1,916.8,381.1,916.8z"
          />
          <path
            d="M384.8,916.5v-0.6c2-0.1,2.1-0.3,2.1-1.8v-8c0-1.1-0.2-1.1-1-1.2l-0.8-0.1v-0.6c1.2-0.2,2.4-0.5,3.5-1V914
		c0,1.6,0.1,1.7,2.1,1.8v0.6L384.8,916.5z"
          />
          <path
            d="M400.5,913.6c-0.2,1-0.6,2.1-0.8,2.9h-7V916c1-1.1,2.1-2.4,3-3.6c1.2-1.6,2.2-3.3,2.2-5s-0.8-2.7-2.1-2.7s-2,1.1-2.5,2
		l-0.5-0.5l1.1-1.8c0.6-0.8,1.6-1.3,2.6-1.3c1.8,0,3.2,1.4,3.2,3.7c0,1.8-0.6,3-2.3,5.1c-0.8,0.9-1.9,2.2-2.7,3h3.4
		c1,0,1.3-0.1,1.9-1.4L400.5,913.6z"
          />
          <path
            d="M305.5,916.6V916c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.1-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.6,0.3-1.6,2.1v8.3
		c0,1.8,0.1,1.9,1.6,2.1v0.6H305.5z"
          />
          <path
            d="M313.6,916.9c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
		C314.9,916.2,314.4,916.8,313.6,916.9C313.6,916.9,313.6,916.9,313.6,916.9z"
          />
          <path
            d="M317.3,916.6V916c2-0.2,2.1-0.3,2.1-1.8v-8c0-1.1-0.2-1.1-1-1.2l-0.8-0.1v-0.6c1.2-0.2,2.4-0.5,3.5-1v11
		c0,1.6,0.1,1.7,2.1,1.8v0.6L317.3,916.6z"
          />
          <path
            d="M326.3,916.6V916c2-0.2,2.1-0.3,2.1-1.8v-8c0-1.1-0.2-1.1-1-1.2l-0.8-0.1v-0.6c1.2-0.2,2.4-0.5,3.5-1v11
		c0,1.6,0.1,1.7,2.1,1.8v0.6L326.3,916.6z"
          />
        </g>
        <g id="_1-4">
          <path
            d="M89.8,114.7v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.2-1.9-1.6-2.1V101h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
		c0,1.8,0.2,1.9,1.7,2.1v0.6L89.8,114.7z"
          />
          <path
            d="M97.8,115c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
		C99.1,114.3,98.6,114.9,97.8,115C97.9,115,97.9,115,97.8,115z"
          />
          <path
            d="M101.5,114.7v-0.6c2-0.1,2.1-0.3,2.1-1.8v-8c0-1.1-0.2-1.1-1-1.2l-0.8-0.1v-0.6c1.2-0.2,2.4-0.5,3.5-1v11
		c0,1.6,0.1,1.7,2.1,1.8v0.6L101.5,114.7z"
          />
          <path
            d="M89.8,187.5v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
		c0,1.8,0.2,1.9,1.7,2.1v0.6H89.8z"
          />
          <path
            d="M97.8,187.8c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
		C99.1,187.1,98.6,187.7,97.8,187.8C97.9,187.8,97.9,187.8,97.8,187.8z"
          />
          <path
            d="M108.3,184.6c-0.3,1-0.6,2.1-0.8,2.9h-7V187c1-1.1,2.1-2.4,3-3.6c1.2-1.6,2.2-3.3,2.2-5s-0.8-2.7-2.1-2.7s-2,1.1-2.5,1.9
		l-0.4-0.5l1.1-1.8c0.6-0.8,1.6-1.3,2.6-1.3c1.8,0,3.2,1.4,3.2,3.7c0,1.8-0.6,3-2.3,5.1c-0.8,0.9-1.9,2.2-2.7,3h3.4
		c1,0,1.3-0.1,1.9-1.4L108.3,184.6z"
          />
          <path
            d="M89.8,250.1v-0.6c1.5-0.2,1.6-0.3,1.6-2.1V239c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
		c0,1.8,0.2,1.9,1.7,2.1v0.6L89.8,250.1z"
          />
          <path
            d="M97.8,250.3c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
		C99.1,249.6,98.6,250.3,97.8,250.3C97.9,250.3,97.9,250.3,97.8,250.3z"
          />
          <path
            d="M100.9,239.2l0.9-1.4c0.6-0.8,1.5-1.2,2.4-1.2c1.5-0.1,2.8,1.1,2.8,2.6c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.1-0.6,1.6
		c-0.4,0.4-0.8,0.8-1.3,1.1c1.6,0.3,2.7,1.6,2.8,3.2c0,3.4-3.6,4.9-4.9,4.9c-0.7,0-1.4-0.2-1.9-0.7c-0.2-0.2-0.3-0.5-0.3-0.7
		c0-0.3,0.2-0.6,0.4-0.9c0.1-0.1,0.3-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.3,0,2.2-1.1,2.2-3.1c0.2-1.3-0.8-2.5-2.1-2.7
		c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.2,0.2l-0.1-0.7c1.7-0.6,2.9-1.4,2.9-3c0.2-0.9-0.5-1.8-1.4-2c-0.1,0-0.2,0-0.3,0
		c-1,0.1-1.9,0.7-2.3,1.7L100.9,239.2z"
          />
          <path
            d="M89.8,313.5v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
		c0,1.8,0.2,1.9,1.7,2.1v0.6H89.8z"
          />
          <path
            d="M97.8,313.8c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
		C99.1,313.1,98.6,313.7,97.8,313.8C97.9,313.8,97.9,313.8,97.8,313.8z"
          />
          <path
            d="M106.7,309.7v1.5c0,1.4,0.1,1.5,1.4,1.6v0.6h-4.5v-0.6c1.4-0.1,1.4-0.3,1.4-1.7v-1.5h-4.8V309c1.8-2.9,3.7-6,5.5-8.9h0.9
		v8.5h1.8v1.1H106.7z M105.1,302.5c-1.1,1.9-2.4,4-3.7,6.1h3.7V302.5z"
          />
          <path
            d="M89.8,376.5v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
		c0,1.8,0.2,1.9,1.7,2.1v0.6H89.8z"
          />
          <path
            d="M97.8,376.8c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
		C99.1,376.1,98.6,376.7,97.8,376.8C97.9,376.8,97.9,376.8,97.8,376.8z"
          />
          <path
            d="M107.5,363.3l0.1,0.2l-0.5,1.5h-4.3c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.5-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4
		c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.9-2.1,1.4-3.3,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.2-0.2-0.4-0.5-0.3-0.7
		c0-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2
		c-0.6,0-1.3,0.1-1.8,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4L107.5,363.3z"
          />
          <path
            d="M89.8,440.4v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
		c0,1.8,0.2,1.9,1.7,2.1v0.6H89.8z"
          />
          <path
            d="M97.8,440.6c-0.7,0-1.2-0.6-1.2-1.3c0,0,0-0.1,0-0.1c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4
		c0,0,0,0,0,0C99.1,439.9,98.6,440.6,97.8,440.6C97.9,440.6,97.9,440.6,97.8,440.6z"
          />
          <path
            d="M103.8,432.4c0.4-0.2,0.8-0.4,1.2-0.4c2,0,3.3,1.7,3.3,3.9c0,2.5-1.6,4.7-3.9,4.7s-3.9-2-3.9-5.3c-0.1-3.4,1.9-6.5,5.1-7.9
		c0.7-0.2,1.5-0.4,2.2-0.6l0.1,0.7c-0.7,0.2-1.4,0.4-2,0.7c-1.8,1-3,2.8-3.3,4.8L103.8,432.4z M104.2,433.3c-0.6,0-1.2,0.2-1.7,0.5
		c-0.1,0.5-0.1,1-0.1,1.5c0,2.7,0.8,4.5,2.3,4.5s1.8-1.7,1.8-3.1C106.5,434.9,105.8,433.3,104.2,433.3z"
          />
          <path
            d="M89.8,503.4v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
		c0,1.8,0.2,1.9,1.7,2.1v0.6H89.8z"
          />
          <path
            d="M97.8,503.6c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
		C99.1,502.9,98.6,503.6,97.8,503.6C97.9,503.6,97.9,503.6,97.8,503.6z"
          />
          <path
            d="M108.4,490.5c-1.8,4.3-3.5,8.6-5.3,12.9l-1.4,0.2l-0.1-0.2c1.9-3.6,3.9-7.9,5.4-11.4h-3.9c-1.2,0-1.3,0.2-1.8,1.5h-0.6
		c0.2-1.3,0.3-2.3,0.4-3.3h7.2L108.4,490.5z"
          />
          <path
            d="M89.8,566.8v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
		c0,1.8,0.2,1.9,1.7,2.1v0.6H89.8z"
          />
          <path
            d="M97.8,567.1c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0
		C99.1,566.4,98.6,567,97.8,567.1C97.9,567.1,97.9,567.1,97.8,567.1z"
          />
          <path
            d="M104.5,553.4c1.7,0,3,1.3,3.1,2.9c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.2-0.5,1.7c-0.5,0.5-1,0.9-1.5,1.3
		c1.3,0.8,2.5,1.8,2.5,3.7c0,2.1-1.6,3.8-3.7,3.9c0,0,0,0-0.1,0c-1.9,0.1-3.5-1.3-3.6-3.1c0-0.1,0-0.2,0-0.4c0-0.8,0.3-1.5,0.8-2.1
		c0.5-0.5,1.1-0.9,1.7-1.3c-1.2-0.7-2-1.9-2-3.3c-0.1-1.9,1.4-3.4,3.2-3.5C104.5,553.4,104.5,553.4,104.5,553.4z M104.5,566.3
		c1.2,0,1.9-0.9,1.9-2.4s-1.2-2.5-2.7-3.3c-0.9,0.6-1.5,1.7-1.4,2.8C102.3,565.3,103.3,566.3,104.5,566.3z M104.3,554.1
		c-1.1,0-1.6,1-1.6,2.1c0,1.5,0.9,2.1,2.3,3c0.7-0.6,1.2-1.6,1.1-2.5C106.1,554.8,105.2,554.1,104.3,554.1z"
          />
          <path
            d="M89.8,629.8v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
		c0,1.8,0.2,1.9,1.7,2.1v0.6H89.8z"
          />
          <path d="M97.8,630.1c-0.8-0.1-1.3-0.9-1.2-1.7c0.1-0.6,0.6-1,1.2-1.2c0.8,0.1,1.3,0.8,1.2,1.6C99,629.5,98.5,630,97.8,630.1z" />
          <path
            d="M106.2,623.7l-1.2,0.7c-0.3,0.2-0.7,0.3-1.1,0.3c-2,0-3.4-1.8-3.4-4c0-2,1.4-4.4,3.8-4.4s3.8,2.4,3.8,5.3
		c0.1,3.3-1.7,6.4-4.7,7.9c-0.7,0.3-1.5,0.5-2.3,0.6l-0.1-0.7C103.7,628.9,105.6,626.9,106.2,623.7z M104.2,617.1
		c-1.3,0-1.8,1.5-1.8,3c0,1.8,0.9,3.4,2.4,3.4c0.6,0,1.1-0.2,1.5-0.5c0.1-0.5,0.1-0.9,0.1-1.4C106.3,619.7,105.9,617.1,104.2,617.1
		L104.2,617.1z"
          />
          <path
            d="M86,693.2v-0.6c1.5-0.2,1.6-0.3,1.6-2.1v-8.3c0-1.8-0.2-1.9-1.6-2.1v-0.6h5.1v0.6c-1.5,0.2-1.7,0.3-1.7,2.1v8.3
		c0,1.8,0.2,1.9,1.7,2.1v0.6H86z"
          />
          <path
            d="M94.1,693.5c-0.7,0-1.2-0.6-1.2-1.3c0,0,0-0.1,0-0.1c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0,0c0.7,0,1.3,0.7,1.2,1.4
		c0,0,0,0,0,0C95.4,692.8,94.9,693.4,94.1,693.5C94.1,693.5,94.1,693.5,94.1,693.5z"
          />
          <path
            d="M97.7,693.2v-0.6c2-0.1,2.1-0.3,2.1-1.8v-8c0-1.1-0.2-1.1-1-1.2l-0.8-0.1v-0.6c1.2-0.2,2.4-0.5,3.5-1v11
		c0,1.6,0.1,1.7,2.1,1.8v0.6L97.7,693.2z"
          />
          <path
            d="M109.6,679.8c2.8,0,3.8,3.6,3.8,6.8s-1,6.8-3.8,6.8s-3.9-3.5-3.9-6.8C105.7,683.6,106.8,679.8,109.6,679.8z M109.6,680.6
		c-1.6,0-2,3.1-2,6s0.5,6.1,2,6.1s2-3.1,2-6.1S111.2,680.6,109.6,680.6L109.6,680.6z"
          />
          <path
            style={{ fill: "#136736" }}
            d="M80.2,144.4c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
		c0,2.6,0.9,5.1,2.6,7L80.2,144.4z"
          />
          <path
            style={{ fill: "#136736" }}
            d="M82,133l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
		c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.6
		c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0
		c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
		L82,133z"
          />
          <path
            style={{ fill: "#136736" }}
            d="M96.4,131.5c-1.5,3.3-3,6.5-4.6,9.8l-1.2,0.1l-0.1-0.2c1.7-2.8,3.3-5.7,4.7-8.6h-3.4c-1,0-1.1,0.1-1.6,1.2
		h-0.5c0.2-1,0.2-1.8,0.3-2.5h6.3L96.4,131.5z"
          />
          <path
            style={{ fill: "#136736" }}
            d="M103.4,131.3l0.1,0.1l-0.4,1.2h-3.7c-0.2,0.8-0.3,2.1-0.5,2.8c0.6-0.2,1.3-0.4,1.9-0.5c1.5-0.1,2.9,1,3,2.6
		c0,0,0,0.1,0,0.1c0,1.1-0.5,2.1-1.4,2.7c-0.8,0.7-1.8,1.1-2.9,1.1c-0.6,0-1.2-0.2-1.7-0.6c-0.3-0.2-0.3-0.4-0.3-0.6
		c0-0.2,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.6,1.2,0.9,2,0.9c1.3,0,1.9-1.1,1.9-2.3c0.1-1.3-0.9-2.3-2.1-2.4
		c-0.1,0-0.3,0-0.4,0c-0.6,0-1.1,0.1-1.6,0.3v-0.1c0.3-1.3,0.6-3.5,0.8-4.8L103.4,131.3z"
          />
          <path
            style={{ fill: "#136736" }}
            d="M114,141.3v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7
		v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.4v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
		c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
		c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
		c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L114,141.3z"
          />
          <path
            style={{ fill: "#136736" }}
            d="M125.1,139c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
		c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.2
		c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L125.1,139z"
          />
          <path
            style={{ fill: "#136736" }}
            d="M126.6,129.5c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7
		c0.1-2.6-0.9-5.1-2.6-7L126.6,129.5z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M80.2,213c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
		c0,2.6,0.9,5.1,2.6,7L80.2,213z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M82,201.7l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
		c-0.3,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.6
		c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0
		c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
		L82,201.7z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M92.9,199.8c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S90.4,199.8,92.9,199.8z M92.9,200.4
		c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S94.3,200.4,92.9,200.4L92.9,200.4z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M100.8,199.8c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S98.3,199.8,100.8,199.8z M100.8,200.4
		c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S102.1,200.4,100.8,200.4L100.8,200.4z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M114,209.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7
		v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.4v-0.5c0.9-0.1,1.1-0.1,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
		c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
		c0.4-0.3,0.7-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
		c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H114z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M125.1,207.7c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
		c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
		c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L125.1,207.7z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M126.6,198.2c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7
		c0.1-2.6-0.9-5.1-2.6-7L126.6,198.2z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M80.2,276.7c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
		c-0.1,2.6,0.9,5.1,2.6,7L80.2,276.7z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M82,265.4l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
		c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5
		c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1
		c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0
		c-0.8,0.1-1.6,0.5-2,1.3L82,265.4z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M92.9,263.4c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S90.4,263.4,92.9,263.4z M92.9,264
		c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S94.3,264,92.9,264L92.9,264z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M100.8,263.4c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S98.3,263.4,100.8,263.4z M100.8,264
		c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S102.1,264,100.8,264L100.8,264z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M114,273.6v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7
		v3.2c0,1,0.1,1.1,1,1.2v0.5h-3.4v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
		c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.4c0-1-0.1-1.1-0.9-1.2V267c0.8-0.1,1.6-0.3,2.3-0.6v1.3
		c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.4,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
		c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L114,273.6z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M125.1,271.4c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1-1.2,1.9-2.5,1.9-3.8
		s-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
		c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L125.1,271.4z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M126.6,261.9c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7
		c0.1-2.6-0.9-5.1-2.6-7L126.6,261.9z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M80.2,339.7c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
		c-0.1,2.6,0.9,5.1,2.6,7L80.2,339.7z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M82,328.4l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
		c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5
		c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1
		c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0
		c-0.8,0.1-1.6,0.5-2,1.3L82,328.4z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M92.9,326.4c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S90.4,326.4,92.9,326.4z M92.9,327
		c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S94.3,327,92.9,327L92.9,327z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M100.8,326.4c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S98.3,326.4,100.8,326.4z M100.8,327
		c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S102.1,327,100.8,327L100.8,327z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M114,336.6v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7
		v3.2c0,1,0.1,1.1,1,1.2v0.5h-3.4v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
		c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.4c0-1-0.1-1.1-0.9-1.2V330c0.8-0.1,1.6-0.3,2.3-0.6v1.3
		c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.4,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
		c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L114,336.6z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M125.1,334.4c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1-1.2,1.9-2.5,1.9-3.8
		s-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
		c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L125.1,334.4z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M126.6,324.9c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7
		c0.1-2.6-0.9-5.1-2.6-7L126.6,324.9z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M80.2,403.3c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
		c0,2.6,0.9,5.1,2.6,7L80.2,403.3z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M82,392l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
		c-0.3,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.5
		c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0
		c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
		L82,392z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M92.9,390.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2C89.5,392.9,90.4,390.1,92.9,390.1z
		 M92.9,390.7c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S94.3,390.7,92.9,390.7L92.9,390.7z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M100.8,390.1c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2C97.4,392.9,98.3,390.1,100.8,390.1z
		 M100.8,390.7c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S102.1,390.7,100.8,390.7L100.8,390.7z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M114,400.3v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7
		v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.4v-0.5c0.9-0.1,1.1-0.1,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
		c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
		c0.4-0.3,0.7-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
		c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H114z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M125.1,398c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
		c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
		c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L125.1,398z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M126.6,388.5c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7
		c0.1-2.6-0.9-5.1-2.6-7L126.6,388.5z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M80.2,466.3c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
		c0,2.6,0.9,5.1,2.6,7L80.2,466.3z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M82,455l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
		c-0.3,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.5
		c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0
		c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
		L82,455z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M92.9,453.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2C89.5,455.9,90.4,453.1,92.9,453.1z
		 M92.9,453.7c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S94.3,453.7,92.9,453.7L92.9,453.7z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M100.8,453.1c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2C97.4,455.9,98.3,453.1,100.8,453.1z
		 M100.8,453.7c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S102.1,453.7,100.8,453.7L100.8,453.7z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M114,463.3v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7
		v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.4v-0.5c0.9-0.1,1.1-0.1,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
		c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
		c0.4-0.3,0.7-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
		c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H114z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M125.1,461c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
		c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
		c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L125.1,461z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M126.6,451.5c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7
		c0.1-2.6-0.9-5.1-2.6-7L126.6,451.5z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M80.2,530c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
		c-0.1,2.6,0.9,5.1,2.6,7L80.2,530z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M82,518.7l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
		c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5
		c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.3c0-1.1-0.8-2-1.9-2.1
		c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0
		c-0.8,0.1-1.6,0.5-2,1.3L82,518.7z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M92.9,516.7c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S90.4,516.7,92.9,516.7z M92.9,517.3
		c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S94.3,517.3,92.9,517.3L92.9,517.3z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M100.8,516.7c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S98.3,516.7,100.8,516.7z M100.8,517.3
		c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S102.1,517.3,100.8,517.3L100.8,517.3z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M114,526.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7
		v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.4v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
		c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
		c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
		c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H114z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M125.1,524.7c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1-1.2,1.9-2.5,1.9-3.8
		s-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
		c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L125.1,524.7z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M126.6,515.2c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7
		c0.1-2.6-0.9-5.1-2.6-7L126.6,515.2z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M80.2,593.4c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
		c0,2.6,0.9,5.1,2.6,7L80.2,593.4z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M82,582.1l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
		c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.5
		c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0
		c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
		L82,582.1z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M92.9,580.2c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S90.4,580.2,92.9,580.2z M92.9,580.8
		c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S94.3,580.8,92.9,580.8L92.9,580.8z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M100.8,580.2c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S98.3,580.2,100.8,580.2z M100.8,580.8
		c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S102.1,580.8,100.8,580.8L100.8,580.8z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M114,590.3v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7
		v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.4v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
		c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
		c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
		c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H114z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M125.1,588.1c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1V590c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
		c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.2
		c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L125.1,588.1z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M126.6,578.6c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7
		c0.1-2.6-0.9-5.1-2.6-7L126.6,578.6z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M80.2,656.4c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
		c0,2.6,0.9,5.1,2.6,7L80.2,656.4z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M82,645.1l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
		c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.5
		c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0
		c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
		L82,645.1z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M92.9,643.2c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S90.4,643.2,92.9,643.2z M92.9,643.8
		c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S94.3,643.8,92.9,643.8L92.9,643.8z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M100.8,643.2c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S98.3,643.2,100.8,643.2z M100.8,643.8
		c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S102.1,643.8,100.8,643.8L100.8,643.8z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M114,653.3v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7
		v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.4v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
		c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
		c0.4-0.3,0.7-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
		c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H114z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M125.1,651.1c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1V653c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
		c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
		c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L125.1,651.1z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M126.6,641.6c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7
		c0.1-2.6-0.9-5.1-2.6-7L126.6,641.6z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M80.2,719.7c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
		c0,2.6,0.9,5.1,2.6,7L80.2,719.7z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M82,708.3l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
		c-0.3,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.6
		c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0
		c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
		L82,708.3z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M92.9,706.4c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S90.4,706.4,92.9,706.4z M92.9,707
		c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S94.3,707,92.9,707L92.9,707z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M100.8,706.4c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S98.3,706.4,100.8,706.4z M100.8,707
		c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S102.1,707,100.8,707L100.8,707z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M114,716.6v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7
		v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.4v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
		c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
		c0.4-0.3,0.7-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
		c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H114z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M125.1,714.3c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
		c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
		c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L125.1,714.3z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M126.6,704.8c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7
		c0.1-2.6-0.9-5.1-2.6-7L126.6,704.8z"
          />
          <path
            style={{ fill: "#136736" }}
            d="M877.6,946.7c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
		c-0.1,2.6,0.9,5.1,2.6,7L877.6,946.7z"
          />
          <path
            style={{ fill: "#136736" }}
            d="M879.5,935.4l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
		c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.5
		c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1
		c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0
		c-0.8,0.1-1.6,0.5-2,1.3L879.5,935.4z"
          />
          <path
            style={{ fill: "#136736" }}
            d="M893.8,933.8c-1.5,3.3-3,6.5-4.6,9.8l-1.2,0.1l-0.1-0.2c1.7-2.8,3.3-5.7,4.7-8.7h-3.4c-1.1,0-1.1,0.1-1.6,1.2
		h-0.5c0.2-1,0.2-1.8,0.3-2.5h6.3L893.8,933.8z"
          />
          <path
            style={{ fill: "#136736" }}
            d="M900.9,933.6l0.1,0.1l-0.4,1.2h-3.7c-0.2,0.8-0.3,2.1-0.5,2.8c0.6-0.2,1.3-0.4,1.9-0.5c1.5-0.1,2.9,1,3,2.6
		c0,0,0,0.1,0,0.1c0,1.1-0.5,2.1-1.4,2.7c-0.8,0.7-1.8,1.1-2.9,1.1c-0.6,0-1.2-0.2-1.7-0.6c-0.3-0.2-0.3-0.3-0.3-0.6
		c0-0.2,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.6,1.2,0.9,2,0.9c1.3,0,1.9-1.1,1.9-2.3c0.1-1.3-0.9-2.3-2.1-2.4
		c-0.1,0-0.3,0-0.4,0c-0.5,0-1.1,0.1-1.6,0.3l-0.1-0.1c0.3-1.3,0.6-3.5,0.8-4.8L900.9,933.6z"
          />
          <path
            style={{ fill: "#136736" }}
            d="M911.4,943.6v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8
		c0,0.2,0,0.4,0,0.7v3.2c0,1,0.1,1.1,1,1.2v0.5H907v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8
		c-0.6,0.1-1.2,0.3-1.7,0.8v3.8c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2V937
		c0.8-0.1,1.6-0.3,2.3-0.6v1.3c0.4-0.3,0.7-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9
		c0.4-0.3,0.8-0.4,1.3-0.4c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L911.4,943.6z"
          />
          <path
            style={{ fill: "#136736" }}
            d="M922.5,941.4c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
		c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
		c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L922.5,941.4z"
          />
          <path
            style={{ fill: "#136736" }}
            d="M924,931.8c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
		c0.1-2.6-0.9-5.1-2.6-7L924,931.8z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M806.9,944.7c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
		c-0.1,2.6,0.9,5.1,2.6,7L806.9,944.7z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M808.7,933.3l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
		c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.1,2.4,2.4c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5
		c0.1-0.2,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.3c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0
		c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
		L808.7,933.3z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M819.6,931.4c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S817.1,931.4,819.6,931.4z M819.6,932
		c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S821,932,819.6,932L819.6,932z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M827.4,931.4c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S825,931.4,827.4,931.4z M827.4,932
		c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S828.8,932,827.4,932L827.4,932z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M840.7,941.5v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8
		c0,0.2,0,0.5,0,0.7v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8
		c-0.6,0.1-1.2,0.3-1.7,0.8v3.8c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4
		c0.8-0.1,1.6-0.3,2.3-0.6v1.3c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9
		c0.4-0.3,0.8-0.4,1.3-0.4c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H840.7z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M851.8,939.3c-0.2,0.8-0.5,1.6-0.7,2.2H845v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.7-2.4,1.9-3.8
		c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
		c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L851.8,939.3z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M853.3,929.8c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c3.5-4,3.5-10,0-14L853.3,929.8z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M743.8,945.2c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
		c-0.1,2.6,0.9,5.1,2.6,7L743.8,945.2z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M745.6,933.9l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
		c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.5
		c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0
		c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0-0.8-0.6-1.4-1.3-1.4c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
		L745.6,933.9z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M756.5,931.9c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S754,931.9,756.5,931.9z M756.5,932.5
		c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S757.9,932.5,756.5,932.5L756.5,932.5z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M764.3,931.9c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S761.9,931.9,764.3,931.9z M764.3,932.5
		c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S765.7,932.5,764.3,932.5z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M777.5,942.1v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8
		c0,0.2,0,0.5,0,0.7v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8
		c-0.6,0.1-1.2,0.3-1.7,0.8v3.8c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4
		c0.8-0.1,1.6-0.3,2.3-0.6v1.3c0.4-0.3,0.7-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9
		c0.4-0.3,0.8-0.4,1.3-0.4c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H777.5z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M788.7,939.9c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.7-2.4,1.9-3.8
		c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.2
		c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L788.7,939.9z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M790.2,930.4c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
		c0.1-2.6-0.9-5.1-2.6-7L790.2,930.4z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M680.5,945c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
		c0,2.6,0.9,5.1,2.6,7L680.5,945z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M682.4,933.7l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
		c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5
		c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.3c0-1.1-0.8-2-1.9-2.1
		c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0
		c-0.8,0.1-1.6,0.5-2,1.3L682.4,933.7z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M693.3,931.7c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S690.8,931.7,693.3,931.7z M693.3,932.3
		c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S694.6,932.3,693.3,932.3L693.3,932.3z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M701.1,931.7c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S698.6,931.7,701.1,931.7z M701.1,932.3
		c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S702.5,932.3,701.1,932.3L701.1,932.3z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M714.3,941.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8
		c0,0.2,0,0.4,0,0.7v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8
		c-0.6,0.1-1.2,0.3-1.7,0.8v3.8c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4
		c0.8-0.1,1.6-0.3,2.3-0.6v1.3c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9
		c0.4-0.3,0.8-0.4,1.3-0.4c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L714.3,941.9z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M725.4,939.7c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
		c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
		c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L725.4,939.7z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M727,930.1c4.1,3.1,4.9,8.9,1.9,13c-0.5,0.7-1.2,1.3-1.9,1.9l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7
		c0.1-2.6-0.9-5.1-2.6-7L727,930.1z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M617.2,945.3c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
		c-0.1,2.6,0.9,5.1,2.6,7L617.2,945.3z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M619.1,934l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
		c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.5
		c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1
		c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0
		c-0.8,0.1-1.6,0.5-2,1.3L619.1,934z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M630,932c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S627.5,932,630,932z M630,932.6
		c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S631.3,932.6,630,932.6z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M637.8,932c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S635.3,932,637.8,932z M637.8,932.6
		c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S639.1,932.6,637.8,932.6z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M651,942.2v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7
		v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
		c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
		c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
		c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H651z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M662.1,940c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
		c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.2
		c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L662.1,940z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M663.6,930.5c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
		c0.1-2.6-0.9-5.1-2.6-7L663.6,930.5z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M553.8,944.9c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
		c-0.1,2.6,0.9,5.1,2.6,7L553.8,944.9z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M555.6,933.6l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
		c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5
		c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.3c0-1.1-0.8-2-1.9-2.1
		c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0
		c-0.8,0.1-1.6,0.5-2,1.3L555.6,933.6z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M566.5,931.6c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S564,931.6,566.5,931.6z M566.5,932.2
		c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S567.9,932.2,566.5,932.2L566.5,932.2z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M574.3,931.6c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.3-2.7-3.3-5.2S571.9,931.6,574.3,931.6z M574.3,932.2
		c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S575.7,932.2,574.3,932.2z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M587.6,941.8v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8
		c0,0.2,0,0.5,0,0.7v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8
		c-0.6,0.1-1.2,0.3-1.7,0.8v3.8c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4
		c0.8-0.1,1.6-0.3,2.3-0.6v1.3c0.4-0.3,0.7-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9
		c0.4-0.3,0.8-0.4,1.3-0.4c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H587.6z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M598.7,939.5c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.7-2.4,1.9-3.8
		c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1,2.8,2.5c0,0.1,0,0.2,0,0.3
		c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L598.7,939.5z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M600.2,930c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c3.5-4,3.5-10,0-14L600.2,930z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M491.4,944.7c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.4,0.4c-1.8,1.9-2.7,4.4-2.6,7
		c-0.1,2.6,0.9,5.1,2.6,7L491.4,944.7z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M493.3,933.3l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.8,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
		c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.1,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5
		c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.3c0-1.1-0.8-2-1.9-2.1
		c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0
		c-0.8,0.1-1.6,0.5-2,1.3L493.3,933.3z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M504.2,931.4c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S501.7,931.4,504.2,931.4z M504.2,932
		c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S505.5,932,504.2,932L504.2,932z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M512,931.4c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S509.5,931.4,512,931.4z M512,932
		c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6c1.3,0,1.7-2.4,1.7-4.6S513.4,932,512,932L512,932z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M525.2,941.6v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8
		c0,0.2,0,0.5,0,0.7v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8
		c-0.6,0.1-1.2,0.3-1.7,0.8v3.8c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4
		c0.8-0.1,1.6-0.3,2.3-0.6v1.3c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9
		c0.4-0.3,0.8-0.4,1.3-0.4c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H525.2z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M536.3,939.3c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
		c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
		c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L536.3,939.3z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M537.9,929.8c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c3.5-4,3.5-10,0-14L537.9,929.8z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M427,945.2c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7
		c0,2.6,0.9,5.1,2.6,7L427,945.2z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M428.9,933.9l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
		c-0.4,0.3-0.7,0.6-1.1,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.1,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.5
		c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0
		c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3
		L428.9,933.9z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M439.8,931.9c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S437.3,931.9,439.8,931.9z M439.8,932.5
		c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S441.1,932.5,439.8,932.5L439.8,932.5z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M447.6,931.9c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S445.1,931.9,447.6,931.9z M447.6,932.5
		c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S448.9,932.5,447.6,932.5L447.6,932.5z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M460.8,942.1v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8
		c0,0.2,0,0.4,0,0.7v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8
		c-0.6,0.1-1.2,0.3-1.7,0.8v3.8c0,1,0.1,1.1,1,1.2v0.5H452v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4
		c0.8-0.1,1.6-0.3,2.3-0.6v1.3c0.4-0.3,0.7-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9
		c0.4-0.3,0.8-0.4,1.3-0.4c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L460.8,942.1z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M471.9,939.9c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
		c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
		c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L471.9,939.9z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M473.4,930.4c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
		c0.1-2.6-0.9-5.1-2.6-7L473.4,930.4z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M364,945.2c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.4,0.4c-1.8,1.9-2.7,4.4-2.6,7
		c-0.1,2.6,0.9,5.1,2.6,7L364,945.2z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M365.9,933.9l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
		c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.1,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.5
		c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1
		c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0
		c-0.8,0.1-1.6,0.5-2,1.3L365.9,933.9z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M376.8,931.9c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S374.3,931.9,376.8,931.9z M376.8,932.5
		c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S378.1,932.5,376.8,932.5z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M384.6,931.9c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S382.1,931.9,384.6,931.9z M384.6,932.5
		c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S385.9,932.5,384.6,932.5L384.6,932.5z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M397.8,942.1v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8
		c0,0.2,0,0.4,0,0.7v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8
		c-0.6,0.1-1.2,0.3-1.7,0.8v3.8c0,1,0.1,1.1,1,1.2v0.5H389v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4
		c0.8-0.1,1.6-0.3,2.3-0.6v1.3c0.4-0.3,0.7-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9
		c0.4-0.3,0.8-0.4,1.3-0.4c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L397.8,942.1z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M408.9,939.9c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
		c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3
		c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L408.9,939.9z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M410.4,930.4c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
		c0.1-2.6-0.9-5.1-2.6-7L410.4,930.4z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M300.7,945.1c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.4,0.4c-1.8,1.9-2.7,4.4-2.6,7
		c-0.1,2.6,0.9,5.1,2.6,7L300.7,945.1z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M302.5,933.8l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2
		c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.1,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.5
		c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1
		c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0
		c-0.8,0.1-1.6,0.5-2,1.3L302.5,933.8z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M313.4,931.8c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S311,931.8,313.4,931.8z M313.4,932.4
		c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S314.8,932.4,313.4,932.4L313.4,932.4z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M321.3,931.8c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S318.8,931.8,321.3,931.8z M321.3,932.4
		c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S322.6,932.4,321.3,932.4L321.3,932.4z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M334.5,942v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7
		v3.1c0,1,0.1,1.1,1,1.2v0.5H330v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8
		c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2V937c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3
		c0.4-0.3,0.8-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4
		c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H334.5z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M345.6,939.8c-0.2,0.8-0.5,1.6-0.6,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8
		c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.2
		c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L345.6,939.8z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M347.1,930.3c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7
		c0.1-2.6-0.9-5.1-2.6-7L347.1,930.3z"
          />
        </g>
        <path
          style={{ fill: "#ED1F24" }}
          d="M974.4,820.4c-0.8-3.3-2-6.5-6-6.5c-3.2,0-4.5,2.7-4.5,5.3c0,3.3,1.8,5,5.5,7.2c5.1,3,7.4,5.3,7.4,10.1
	c0,5.3-4,9.3-9.7,9.3c-2.3-0.1-4.6-0.6-6.7-1.6c-0.3-1.3-0.9-5-1.2-7.7l1.4-0.4c0.9,3.3,2.9,7.8,7.3,7.8c3,0,4.9-2.4,4.9-5.6
	c0-3.7-1.7-5.2-5.5-7.6c-4.5-2.8-7.1-5.2-7.1-9.9c0-4.5,3.3-8.7,9.1-8.7c2,0.1,3.9,0.4,5.8,0.9c0.2,1.8,0.4,3.9,0.7,7.1L974.4,820.4
	z"
        />
        <path
          style={{ fill: "#ED1F24" }}
          d="M994.8,836v3.6c0,3.2,0.2,3.5,3.3,3.8v1.5h-10.5v-1.5c3.2-0.3,3.4-0.7,3.4-3.9V836h-11.3v-1.6
	c4.2-6.8,8.8-14.2,13-20.8h2.2v19.9h4.1v2.5H994.8z M991.1,819.1c-2.6,4.3-5.6,9.3-8.6,14.4h8.6V819.1z"
        />
        <path
          style={{ fill: "#ED1F24" }}
          d="M749.1,358.9c-0.8-3.3-2-6.5-6-6.5c-3.2,0-4.5,2.7-4.5,5.3c0,3.3,1.8,5,5.5,7.2c5.1,3,7.4,5.3,7.4,10.1
	c0,5.3-4,9.4-9.7,9.4c-2.3-0.1-4.6-0.6-6.7-1.6c-0.3-1.4-0.9-5-1.2-7.7l1.4-0.4c0.9,3.3,2.8,7.8,7.3,7.8c3,0,4.9-2.4,4.9-5.6
	c0-3.6-1.7-5.2-5.5-7.6c-4.5-2.8-7.1-5.2-7.1-9.9s3.3-8.7,9.1-8.7c2,0.1,3.9,0.4,5.8,0.9c0.2,1.8,0.4,3.9,0.7,7.1L749.1,358.9z"
        />
        <path
          style={{ fill: "#ED1F24" }}
          d="M757.5,383.5V382c4.7-0.3,5-0.6,5-4.3v-18.8c0-2.5-0.4-2.6-2.3-2.9l-2-0.3v-1.3c2.8-0.5,5.6-1.3,8.2-2.4v25.7
	c0,3.8,0.3,4,5,4.3v1.5L757.5,383.5z"
        />
        <path
          style={{ fill: "#ED1F24" }}
          d="M794.4,376.6c-0.6,2.3-1.3,5-1.8,6.9h-16.5v-1.2c2.4-2.7,4.9-5.6,7.1-8.4c2.8-3.8,5.2-7.6,5.2-11.9
	c0-4-1.8-6.4-4.9-6.4c-2.9,0-4.8,2.6-5.9,4.6l-1-1.1l2.5-4.2c1.4-1.9,3.6-3,6-3c4.1,0,7.5,3.3,7.5,8.6c0,4.1-1.3,7.1-5.5,11.9
	c-1.8,2.1-4.5,5.1-6.3,7h8.1c2.4,0,3-0.2,4.5-3.4L794.4,376.6z"
        />
        <path
          style={{ fill: "#ED1F24" }}
          d="M889,578.4c-0.8-3.3-2-6.5-6-6.5c-3.2,0-4.5,2.7-4.5,5.3c0,3.3,1.8,5,5.5,7.2c5.1,3,7.5,5.3,7.5,10.1
	c0,5.3-4,9.3-9.7,9.3c-2.3-0.1-4.6-0.6-6.7-1.6c-0.3-1.3-0.9-5-1.2-7.7l1.4-0.4c0.9,3.3,2.8,7.8,7.3,7.8c3,0,4.9-2.4,4.9-5.6
	c0-3.7-1.7-5.2-5.5-7.6c-4.5-2.8-7.1-5.2-7.1-9.9c0-4.6,3.3-8.7,9.1-8.7c2,0.1,3.9,0.4,5.8,0.9c0.2,1.8,0.4,3.9,0.7,7.1L889,578.4z"
        />
        <path
          style={{ fill: "#ED1F24" }}
          d="M897.3,603v-1.5c4.7-0.3,5-0.6,5-4.3v-18.8c0-2.5-0.4-2.7-2.3-2.8l-2-0.3V574c2.8-0.5,5.6-1.3,8.2-2.4v25.7
	c0,3.8,0.3,4,5,4.3v1.4H897.3z"
        />
        <path
          style={{ fill: "#ED1F24" }}
          d="M918.4,603v-1.5c4.7-0.3,5-0.6,5-4.3v-18.8c0-2.5-0.4-2.7-2.3-2.8l-2-0.3V574c2.8-0.5,5.6-1.3,8.2-2.4v25.7
	c0,3.8,0.3,4,5,4.3v1.4H918.4z"
        />
        <path
          style={{ fill: "#ED1F24" }}
          d="M203.3,28.3c-0.8-3.3-2-6.6-6-6.6c-3.2,0-4.5,2.7-4.5,5.3c0,3.3,1.8,5,5.5,7.2c5.1,3,7.4,5.3,7.4,10.1
	c0,5.3-4,9.3-9.7,9.3c-2.3-0.1-4.6-0.6-6.7-1.6c-0.3-1.3-0.9-5-1.2-7.7l1.4-0.4c0.9,3.3,2.9,7.8,7.3,7.8c3,0,4.9-2.4,4.9-5.6
	c0-3.7-1.7-5.2-5.5-7.6c-4.5-2.8-7.1-5.2-7.1-9.9s3.3-8.7,9.1-8.7c2,0.1,3.9,0.4,5.8,0.9c0.2,1.8,0.4,3.9,0.7,7.1L203.3,28.3z"
        />
        <path
          style={{ fill: "#ED1F24" }}
          d="M211.6,52.9v-1.5c4.7-0.3,5-0.6,5-4.3V28.2c0-2.5-0.4-2.6-2.3-2.9l-2-0.3v-1.3c2.8-0.5,5.6-1.3,8.2-2.4v25.7
	c0,3.8,0.3,4,5,4.3v1.5H211.6z"
        />
        <text
          transform="matrix(0.2619 0.8292 -0.9536 0.3012 922.3408 674.4801)"
          style={{
            fill: "#F8D832",
            fontFamily: "ArialMT",
            fontSize: "36.018px",
          }}
        >
          B
        </text>
        <text
          transform="matrix(0.2619 0.8292 -0.9536 0.3012 927.3306 690.2803)"
          style={{
            fill: "#F8D832",
            fontFamily: "ArialMT",
            fontSize: "36.018px",
          }}
        >
          .I3
        </text>
        <text
          transform="matrix(0.5192 0.6976 -0.8022 0.5971 809.3904 444.1295)"
          style={{
            fill: "#F8D832",
            fontFamily: "ArialMT",
            fontSize: "36.018px",
          }}
        >
          B
        </text>
        <text
          transform="matrix(0.5192 0.6976 -0.8022 0.5971 819.2839 457.4221)"
          style={{
            fill: "#F8D832",
            fontFamily: "ArialMT",
            fontSize: "36.018px",
          }}
        >
          .I2
        </text>
        <text
          transform="matrix(0.7462 0.4512 -0.5119 0.859 491.9507 160.0403)"
          style={{
            fill: "#F8D832",
            fontFamily: "ArialMT",
            fontSize: "36.0955px",
          }}
        >
          B
        </text>
        <text
          transform="matrix(0.7462 0.4512 -0.5119 0.859 506.2004 168.6569)"
          style={{
            fill: "#F8D832",
            fontFamily: "ArialMT",
            fontSize: "36.0955px",
          }}
        >
          .I1
        </text>
      </svg>
    </div>
  );
}
export default IZone;
