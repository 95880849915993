import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  EmailField,
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  DateInput,
  BooleanInput,
  Edit,
  Filter,
  TabbedForm,
  BooleanField,
  FormTab,
  required,
  NumberInput,
  Tab,
  TabbedShowLayout,
  FunctionField,
  Show,
  DateField,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  CreateButton,
  ReferenceArrayField,
  ReferenceManyField,
  SingleFieldList,
  ChipField,
} from "react-admin";
import axios from "axios";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import moment from "moment";
import ZaitonLogo from "../assets/zaiton-plus-logo.jpg";
import Button from "react-bootstrap/Button";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import { Link } from "react-router-dom";

const ProperityFilter = (props) => (
  <div style={{display: 'flex', alignItems: 'center'}}>
    <Filter {...props}>
      <TextInput label="Name" source="name" alwaysOn />
      <TextInput label="Zone" source="zone" alwaysOn/>
      <TextInput label="Block" source="block" alwaysOn/>
      <TextInput label="Street Number" source="streetNumber" alwaysOn/>
    </Filter>

    {(props.filterValues.name || props.filterValues.zone || props.filterValues.block || props.filterValues.streetNumber) && <Button style={{height: 40}} onClick={() => props.setFilters({
        name: '',
        zone: '',
        block: '',
        streetNumber: '',
      })}>Clear fields</Button>}
  </div>
);

const ListActions = (props) => {
  const role = localStorage.getItem("role");
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  return (
    <NoPrint>
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {/* <Button
          onClick={() => {
            window.print();
          }}
        >
          Print Report
        </Button> */}
        {/* {filters &&
          React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
          })} */}
        {(role === 'Admin' || role === 'Accountant') && <CreateButton basePath={basePath} />}
      </TopToolbar>
    </NoPrint>
  );
};

export const ProperityList = (props) => {
  const role = localStorage.getItem("role");

  return (
    <List {...props} filters={<ProperityFilter />} actions={<ListActions />}>
      <Datagrid rowClick={(role !== 'PhotoGrapher' && role !== '6') && "show"}>
        <TextField source="name" />
        <TextField source="zone" />
        <TextField source="block" />
        <TextField source="initialPrice" />
        <TextField source="streetNumber" />
        <TextField source="area" />
        {/* <TextField source="streetWidth" /> */}
        <TextField source="numberOfRooms" />
        <BooleanField source="corner" />
        {/* <TextField source="corner" /> */}
        {/* <TextField source="note" /> */}
        <BooleanField source="isSold" />
        {(role === 'Admin' || role === 'Accountant' || role === 'PhotoGrapher' || role === '6') &&  <EditButton />}
      </Datagrid>
    </List>
  )};

const PropertyName = (props) => `Property Name: ${props.record.name}`;

export const PropertyShow = (props) => {
  const [propertyExpences, setPropertyExpences] = React.useState([]);
  const [propertyName, setPropertyName] = React.useState([]);
  const [propertyZone, setPropertyZone] = React.useState([]);
  const [expencePrice, setExpencePrice] = React.useState();
  const [expenceDate, setExpenceDate] = React.useState();
  const [expenceNote, setExpenceNote] = React.useState("");
  const [expenceFile, setExpenceFile] = React.useState("");
  const [attachmentsModified, setAttachmentsModified] = React.useState(0);
  const [expencePriceText, setExpencePriceText] = React.useState(0);
  const [id, setId] = React.useState(props.id);

  const expenceFileSelectHandler = (e) => {
    getBase64(e.target.files[0]);
  };
  function expenceSubmit(e) {
    e.preventDefault();
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

    axios
      .post(process.env.REACT_APP_URL + "/PropertyExpenses", {
        propertyId: id,
        price: expencePrice,
        date: expenceDate,
        note: expenceNote,
        attachment: expenceFile,
      })
      .then((res) => {
        setAttachmentsModified(attachmentsModified + 1);
        setExpenceDate("");
        setExpenceFile("");
        setExpenceNote("");
        setExpencePrice("");
        document.getElementById("expenseFileSelector").value = "";
      });
  }
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
      .get(`${process.env.REACT_APP_URL}/Properties/${id}`)
      .then((resp) => {
        setPropertyExpences(resp.data.propertyExpenses);
        setPropertyName(resp.data.name);
        setPropertyZone(resp.data.zone);
      });
  }, [attachmentsModified]);
  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setExpenceFile(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  const expences =
    propertyExpences &&
    propertyExpences.map((propertyExpence, key) => {
      return (
        <div
          key={key}
          style={{
            borderRadius: 5,
            margin: 10,
            width: 200,
            height: 250,
            padding: 5,
            backgroundColor: "#f2f2f2",
            display: "flex",
            justifyContent: "center",
            alignItems: "space-between",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: 190,
              height: 90,
              backgroundColor: "lightblue",
              borderRadius: 5,
            }}
          >
            <img
              style={{
                width: "100%",
                objectFit: "cover",
                height: "100%",
                borderRadius: 4,
              }}
              src={propertyExpence.attachment}
            />
          </div>
          <div>
            <p>Price: ${propertyExpence.price}</p>
            <p>Date: {moment(propertyExpence.date).format("YYYY-MM-DD")}</p>
            <p>Note: {propertyExpence.note}</p>
          </div>
          <iframe
            id="ifmcontentstoprint"
            style={{
              height: 0,
              width: 0,
              position: "absolute",
              display: "none",
            }}
          >
            <div
              id={`iframe${key}`}
              style={{ display: "flex", flexDirection: "column", margin: 40 }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 30,
                }}
              >
                <div style={{ width: 100, height: 100 }}>
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={ZaitonLogo}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "45%",
                  }}
                >
                  <div>Invoice Number: {propertyExpence.id}</div>
                  <div>
                    Date: {moment(propertyExpence.date).format("YYYY-MM-DD")}
                  </div>
                </div>
              </div>
              <p>Property Name: {propertyName}</p>
              <p>Property Zone: {propertyZone}</p>
              <p>Price: {`$${propertyExpence.price} - ${expencePriceText}`}</p>
              <p>Note: {propertyExpence.note}</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginTop: 100,
                }}
              >
                <p style={{ padding: 0, margin: 0 }}>Accountant</p>
                <p style={{ padding: 10, margin: 0 }}>
                  {localStorage.getItem("name")}
                </p>
              </div>
            </div>
          </iframe>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <button
              style={{
                border: "none",
                backgroundColor: "#2196f3",
                borderRadius: 5,
                color: "white",
                padding: 3,
                width: 90,
                textAlign: "center",
              }}
              onClick={() => {
                const token = localStorage.getItem("token");
                axios.defaults.headers.common = {
                  Authorization: `Bearer ${token}`,
                };
                axios
                  .get(
                    `${process.env.REACT_APP_URL}/Dashboard/NumberToTextKu?number=${propertyExpence.price}`
                  )
                  .then((resp) => {
                    setExpencePriceText(resp.data);
                  })
                  .then(() => {
                    var pri = document.getElementById("ifmcontentstoprint")
                      .contentWindow;
                    pri.document.open();
                    pri.document.write(
                      document.getElementById(`iframe${key}`).innerHTML
                    );
                    pri.document.write("<br /><br /><br /><hr /><br />");
                    pri.document.write(
                      document.getElementById(`iframe${key}`).innerHTML
                    );
                    // pri.document.write(content.innerHTML);
                    pri.document.close();
                    pri.focus();
                    pri.print();
                  });
                // var content = document.getElementById(`rowNumber${key+1}`);
              }}
            >
              print
            </button>
            <a href={propertyExpence.attachment} target="_blank">
              <div
                style={{
                  border: "none",
                  backgroundColor: "#2196f3",
                  borderRadius: 5,
                  color: "white",
                  padding: 3,
                  width: 90,
                  textAlign: "center",
                }}
              >
                View
              </div>
            </a>
          </div>
        </div>
      );
    });
  return (
    <Show {...props} title={<PropertyName />}>
      <TabbedShowLayout>
        <Tab label="Property">
          <TextField source="name" />
          <TextField source="zone" />
          <TextField source="block" />
          <TextField source="initialPrice" />
          <TextField source="streetNumber" />
          <TextField source="area" />
          <TextField source="streetWidth" />
          <TextField source="numberOfRooms" />
          <BooleanField source="corner" />
          <TextField source="note" />
          <DateField source="createdAt" />
          <DateField source="updatedAt" />
          <BooleanField source="isSold" />
        </Tab>
        <Tab label="Contracts">
          <ReferenceField
            link={false}
            label="Customer"
            source="id"
            reference="Properties/GetContracts"
          >
            <FunctionField
              label="Customer"
              render={(record) => {
                return record.buyContract.length > 0 ? (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Price</th>
                        <th>Customer Name</th>
                        <th>Contract ID</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {record.buyContract.map((el, key) => {
                        return (
                          <tr key={key}>
                            <td>{key + 1}</td>
                            <td>${el.buyingPrice}</td>
                            <td><Link to={`/Customers/${el.customer.id}`} style={{textDecoration: 'none'}}>{`${el.customer.firstName} ${el.customer.middleName} ${el.customer.lastName}`}</Link></td>
                            <td><Link to={`/BuyContracts/${el.id}/show`} style={{textDecoration: 'none'}}>{el.id}</Link></td>
                            <td>
                              {moment(el.buyingDate).format("YYYY-MM-DD")}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : (
                  "No Contracts Yet"
                );
              }}
            />
          </ReferenceField>
        </Tab>
        <Tab label="Expenses">
          <form style={{ width: "100%" }} onSubmit={expenceSubmit}>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                value={expencePrice}
                required={true}
                onChange={(e) => setExpencePrice(e.target.value)}
                type="number"
                placeholder="Price"
                aria-describedby="basic-addon1"
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">Date</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                value={expenceDate}
                required={true}
                onChange={(e) => setExpenceDate(e.target.value)}
                type="date"
                placeholder="Price"
                aria-describedby="basic-addon1"
              />
            </InputGroup>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>Note</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                value={expenceNote}
                onChange={(e) => setExpenceNote(e.target.value)}
                as="textarea"
                aria-label="With textarea"
              />
            </InputGroup>
            <input
              style={{
                marginTop: 15,
              }}
              type="file"
              id="expenseFileSelector"
              onChange={expenceFileSelectHandler}
            />
            <div>
              <input
                style={{
                  marginTop: 15,
                  border: "none",
                  borderRadius: 4,
                  padding: 5,
                  paddingLeft: 20,
                  paddingRight: 20,
                  backgroundColor: "#2196f3",
                  color: "white",
                }}
                type="submit"
                value="Save"
              />
            </div>
          </form>
          <div
            style={{
              width: "100%",
              justifyContent: "center",
              display: "flex",
              flexWrap: "wrap",
              marginTop: 40,
            }}
          >
            {expences}
          </div>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const ProperityCreate = (props) => (
  <Create
    {...props}
    transform={(data) => ({ ...data, name: data.name.toUpperCase() })}
  >
    <SimpleForm redirect="list">
      <div style={{ display: "flex", width: "100%" }}>
        <div>
          <TextInput source="name" fullWidth={true} validate={[required()]} />
          <SelectInput
            fullWidth={true}
            validate={[required()]}
            source="zone"
            choices={[
              { id: "H", name: "H" },
              { id: "I", name: "I" },
              { id: "J", name: "J" },
              { id: "K", name: "K" },
            ]}
          />
          <TextInput source="block" fullWidth={true} validate={[required()]} />
          <TextInput
            source="streetNumber"
            fullWidth={true}
            validate={[required()]}
          />
          <TextInput source="area" fullWidth={true} validate={[required()]} />
        </div>
        <div style={{ marginLeft: 35 }}>
          <TextInput
            source="numberOfRooms"
            fullWidth={true}
            validate={[required()]}
          />
          <TextInput source="floors" fullWidth={true} validate={[required()]} />
          <TextInput multiline source="note" fullWidth={true} />
          <SelectInput
            fullWidth={true}
            validate={[required()]}
            source="streetWidth"
            choices={[
              { id: "12m", name: "12m" },
              { id: "22m", name: "22m" },
              { id: "40m", name: "40m" },
            ]}
          />
          <NumberInput
            source="initialPrice"
            fullWidth={true}
            validate={[required()]}
          />
          <BooleanInput source="corner" fullWidth={true} />
        </div>
      </div>
    </SimpleForm>
  </Create>
);

const ProperityTitle = ({ record }) => {
  return <span>Properity {record ? `"${record.name}"` : ""}</span>;
};

export const ProperityEdit = (props) => {
  const [file, setFile] = React.useState();
  const [fileName, setFileName] = React.useState();
  const [id, setId] = React.useState(props.id);
  const [customerAttachments, setCustomerAttachments] = React.useState([]);
  const [isSharable, setIsSharable] = React.useState(false);
  const [is360, setIs360] = React.useState(false);
  const [expencePrice, setExpencePrice] = React.useState();
  const [expenceDate, setExpenceDate] = React.useState();
  const [expenceNote, setExpenceNote] = React.useState("");
  const [expenceFile, setExpenceFile] = React.useState("");
  const [propertyExpences, setPropertyExpences] = React.useState([]);
  const [propertyName, setPropertyName] = React.useState([]);
  const [propertyZone, setPropertyZone] = React.useState([]);
  const [attachmentsModified, setAttachmentsModified] = React.useState(0);
  const [expencePriceText, setExpencePriceText] = React.useState(0);

  const fileSelectHandler = (e) => {
    setFileName(e.target.files[0].name);
    getBase64(e.target.files[0], false);
  };
  const expenceFileSelectHandler = (e) => {
    getBase64(e.target.files[0], true);
  };

  const token = localStorage.getItem("token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

  const expences =
    propertyExpences &&
    propertyExpences.map((propertyExpence, key) => {
      return (
        <div
          key={key}
          style={{
            borderRadius: 5,
            margin: 10,
            width: 200,
            height: 250,
            padding: 5,
            backgroundColor: "#f2f2f2",
            display: "flex",
            justifyContent: "center",
            alignItems: "space-between",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: 190,
              height: 90,
              backgroundColor: "lightblue",
              borderRadius: 5,
            }}
          >
            <img
              style={{
                width: "100%",
                objectFit: "cover",
                height: "100%",
                borderRadius: 4,
              }}
              src={propertyExpence.attachment}
            />
          </div>
          <div>
            <p>Price: ${propertyExpence.price}</p>
            <p>Date: {moment(propertyExpence.date).format("YYYY-MM-DD")}</p>
            <p>Note: {propertyExpence.note}</p>
          </div>
          <iframe
            id="ifmcontentstoprint"
            style={{
              height: 0,
              width: 0,
              position: "absolute",
              display: "none",
            }}
          >
            <div
              id={`iframe${key}`}
              style={{ display: "flex", flexDirection: "column", margin: 40 }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 30,
                }}
              >
                <div style={{ width: 100, height: 100 }}>
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={ZaitonLogo}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "45%",
                  }}
                >
                  <div>Invoice Number: {propertyExpence.id}</div>
                  <div>
                    Date: {moment(propertyExpence.date).format("YYYY-MM-DD")}
                  </div>
                </div>
              </div>
              <p>Property Name: {propertyName}</p>
              <p>Property Zone: {propertyZone}</p>
              <p>Price: {`$${propertyExpence.price} - ${expencePriceText}`}</p>
              <p>Note: {propertyExpence.note}</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: 100,
                }}
              >
                <p>Accountant</p>
              </div>
            </div>
          </iframe>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <button
              style={{
                border: "none",
                backgroundColor: "#2196f3",
                borderRadius: 5,
                color: "white",
                padding: 3,
                width: 90,
                textAlign: "center",
              }}
              onClick={() => {
                const token = localStorage.getItem("token");
                axios.defaults.headers.common = {
                  Authorization: `Bearer ${token}`,
                };
                axios
                  .get(
                    `${process.env.REACT_APP_URL}/Dashboard/NumberToTextKu?number=${propertyExpence.price}`
                  )
                  .then((resp) => {
                    setExpencePriceText(resp.data);
                  })
                  .then(() => {
                    var pri = document.getElementById("ifmcontentstoprint")
                      .contentWindow;
                    pri.document.open();
                    pri.document.write(
                      document.getElementById(`iframe${key}`).innerHTML
                    );
                    pri.document.write("<br /><br /><br /><hr /><br />");
                    pri.document.write(
                      document.getElementById(`iframe${key}`).innerHTML
                    );
                    // pri.document.write(content.innerHTML);
                    pri.document.close();
                    pri.focus();
                    pri.print();
                  });
                // var content = document.getElementById(`rowNumber${key+1}`);
              }}
            >
              print
            </button>
            <a href={propertyExpence.attachment} target="_blank">
              <div
                style={{
                  border: "none",
                  backgroundColor: "#2196f3",
                  borderRadius: 5,
                  color: "white",
                  padding: 3,
                  width: 90,
                  textAlign: "center",
                }}
              >
                View
              </div>
            </a>
          </div>
        </div>
      );
    });
  const downloadStyles = {
    width: 85,
    backgroundColor: "lightblue",
    color: "#fff",
    textAlign: "center",
    fontWeight: "bolder",
    paddingTop: 1,
    paddingBottom: 2,
    border: "none",
  };
  const sharableStyles = {
    width: 75,
    backgroundColor: "green",
    color: "white",
    textAlign: "center",
    fontWeight: "bolder",
    border: "none",
  };
  const deleteStyles = {
    width: 75,
    backgroundColor: "#F32013",
    color: "white",
    textAlign: "center",
    fontWeight: "bolder",
    border: "none",
    borderBottomRightRadius: 5,
  };
  const printStyles = {
    width: 65,
    backgroundColor: "lightblue",
    color: "white",
    textAlign: "center",
    fontWeight: "bolder",
    border: "none",
    borderBottomLeftRadius: 5,
  };
  const images =
    customerAttachments &&
    customerAttachments.map((customerAttachment, key) => {
      return (
        customerAttachment && (
          <div key={key} style={{ margin: 10 }}>
            <div style={{ width: 300, height: 200, borderStyle: 'solid', borderWidth: 1, borderColor: '#777', padding: 5 }}>
              {customerAttachment.is360 && <Badge style={{position: 'absolute'}} variant="primary">360</Badge>}
              <img
                src={customerAttachment.link}
                alt={customerAttachment.name}
                style={{
                  width: "100%",
                  height: "100%",
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
                  objectFit: "scale-down",
                }}
              />
            </div>
            <iframe
              id={`iframe${customerAttachment.id}`}
              style={{
                height: 0,
                width: 0,
                position: "absolute",
                display: "none",
              }}
            >
              <div style={{ width: "100%", height: "100%" }}>
                <img
                  src={customerAttachment.link}
                  alt={customerAttachment.name}
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "scale-down",
                  }}
                />
              </div>
            </iframe>
            <div style={{ display: "flex" }}>
              <button
                style={printStyles}
                onClick={() => printImage(customerAttachment.id)}
              >
                Print
              </button>
              <button
                style={sharableStyles}
                onClick={(e) => {
                  const token = localStorage.getItem("token");
                  axios.defaults.headers.common = {
                    Authorization: `Bearer ${token}`,
                  };
                  axios
                    .post(
                      `${process.env.REACT_APP_URL}/PropertyAttachments/ToggleShareable?id=${customerAttachment.id}`
                    )
                    .then(() => {
                      setAttachmentsModified(attachmentsModified + 1);
                    });
                }}
              >
                {customerAttachment.shareableForCustomer ? "Unshare" : "Share"}
              </button>
              <a
                href={customerAttachment.link}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <div style={downloadStyles}>Download</div>
              </a>
              <button
                style={deleteStyles}
                onClick={() => deleteImage(customerAttachment.id)}
              >
                Delete
              </button>
            </div>
          </div>
        )
      );
    });

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
      .get(`${process.env.REACT_APP_URL}/Properties/${id}`)
      .then((resp) => {
        setCustomerAttachments(resp.data.propertyAttachments);
        setPropertyExpences(resp.data.propertyExpenses);
        setPropertyName(resp.data.name);
        setPropertyZone(resp.data.zone);
      });
  }, [attachmentsModified]);

  function handleSubmit(e) {
    e.preventDefault();

    axios
      .post(process.env.REACT_APP_URL + "/PropertyAttachments", {
        src: file,
        title: fileName,
        propertyId: id,
        isSharable: isSharable,
        is360: is360,
      })
      .then((res) => {
        setAttachmentsModified(attachmentsModified + 1);
        setFile();
        setFileName();
        setIsSharable(false);
        setIs360(false);
        document.getElementById("fileSelector").value = "";
      });
  }
  function expenceSubmit(e) {
    e.preventDefault();

    axios
      .post(process.env.REACT_APP_URL + "/PropertyExpenses", {
        propertyId: id,
        price: expencePrice,
        date: expenceDate,
        note: expenceNote,
        attachment: expenceFile,
      })
      .then((res) => {
        setAttachmentsModified(attachmentsModified + 1);
        setExpenceDate("");
        setExpenceFile("");
        setExpenceNote("");
        setExpencePrice("");
        document.getElementById("expenseFileSelector").value = "";
      });
  }

  function getBase64(file, expence) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      expence ? setExpenceFile(reader.result) : setFile(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  function deleteImage(id) {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
      .delete(`${process.env.REACT_APP_URL}/PropertyAttachments/${id}`)
      .then((res) => setAttachmentsModified(attachmentsModified + 1));
  }
  function printImage(id) {
    var pri = document.getElementById(`iframe${id}`).contentWindow;
    pri.document.open();
    pri.document.write(document.getElementById(`iframe${id}`).innerHTML);
    // pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  }

  const role = localStorage.getItem("role");
  return (
    <Edit title={<ProperityTitle />} {...props}>
      <TabbedForm>
      <FormTab label="Attachments">
          <form style={{ width: "100%" }} onSubmit={handleSubmit}>
            <div style={{ display: "flex", width: "100%" }}>
              <input
                style={{
                  margin: 5,
                  borderColor: "red",
                  borderWidth: 1,
                  borderRadius: 4,
                  padding: 5,
                }}
                type="file"
                id="fileSelector"
                required={true}
                onChange={fileSelectHandler}
              />
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <InputGroup style={{ width: 200, marginTop: 8 }} className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Checkbox
                      aria-label="Checkbox for following text input"
                      checked={isSharable}
                      onChange={(e) =>
                        isSharable ? setIsSharable(false) : setIsSharable(true)
                      }
                    />
                  </InputGroup.Prepend>
                  <FormControl
                    aria-label="Text input with checkbox"
                    placeholder="Is Sharable"
                    disabled={true}
                  />
                </InputGroup>
                <InputGroup style={{ width: 200, marginTop: 8 }} className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Checkbox
                      aria-label="Checkbox for following text input"
                      checked={is360}
                      onChange={(e) =>
                        setIs360(!is360)
                      }
                    />
                  </InputGroup.Prepend>
                  <FormControl
                    aria-label="Text input with checkbox"
                    placeholder="Is 360"
                    disabled={true}
                  />
                </InputGroup>
              </div>
            </div>

            <input
              style={{
                margin: 5,
                border: "none",
                borderRadius: 4,
                padding: 5,
                paddingLeft: 20,
                paddingRight: 20,
              }}
              type="submit"
              value="send"
            />
          </form>
          <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
            {images}
          </div>
        </FormTab>
        { (role !== 'PhotoGrapher' && role !== '6') && <FormTab label="Property">
          <div style={{ display: "flex", width: "100%" }}>
            <div>
              <NumberInput
                source="initialPrice"
                fullWidth={true}
                validate={[required()]}
              />
              <TextInput
                source="name"
                fullWidth={true}
                validate={[required()]}
              />
              <SelectInput
                fullWidth={true}
                validate={[required()]}
                source="zone"
                choices={[
                  { id: "H", name: "H" },
                  { id: "I", name: "I" },
                  { id: "J", name: "J" },
                  { id: "K", name: "K" },
                ]}
              />
              <TextInput
                source="block"
                fullWidth={true}
                validate={[required()]}
              />
              <TextInput
                source="streetNumber"
                fullWidth={true}
                validate={[required()]}
              />
              <TextInput
                source="area"
                fullWidth={true}
                validate={[required()]}
              />
            </div>
            <div style={{ marginLeft: 35 }}>
              <TextInput
                source="numberOfRooms"
                fullWidth={true}
                validate={[required()]}
              />
              <TextInput
                source="floors"
                fullWidth={true}
                validate={[required()]}
              />
              <TextInput multiline source="note" fullWidth={true} />
              <SelectInput
                fullWidth={true}
                validate={[required()]}
                source="streetWidth"
                choices={[
                  { id: "12m", name: "12m" },
                  { id: "22m", name: "22m" },
                ]}
              />
              <BooleanInput source="corner" fullWidth={true} />
            </div>
          </div>
        </FormTab>}
        { (role !== 'PhotoGrapher' && role !== '6') && <FormTab label="Expence">
          <form style={{ width: "100%" }} onSubmit={expenceSubmit}>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                value={expencePrice}
                required={true}
                onChange={(e) => setExpencePrice(e.target.value)}
                type="number"
                placeholder="Price"
                aria-describedby="basic-addon1"
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">Date</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                value={expenceDate}
                required={true}
                onChange={(e) => setExpenceDate(e.target.value)}
                type="date"
                placeholder="Price"
                aria-describedby="basic-addon1"
              />
            </InputGroup>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>Note</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                value={expenceNote}
                onChange={(e) => setExpenceNote(e.target.value)}
                as="textarea"
                aria-label="With textarea"
              />
            </InputGroup>
            <input
              style={{
                marginTop: 15,
              }}
              type="file"
              id="expenseFileSelector"
              onChange={expenceFileSelectHandler}
            />
            <div>
              <input
                style={{
                  marginTop: 15,
                  border: "none",
                  borderRadius: 4,
                  padding: 5,
                  paddingLeft: 20,
                  paddingRight: 20,
                  backgroundColor: "#2196f3",
                  color: "white",
                }}
                type="submit"
                value="Save"
              />
            </div>
          </form>
          <div
            style={{
              width: "100%",
              justifyContent: "center",
              display: "flex",
              flexWrap: "wrap",
              marginTop: 40,
            }}
          >
            {expences}
          </div>
        </FormTab>}
      </TabbedForm>
    </Edit>
  );
};
