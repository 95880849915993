import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  TopToolbar,
  sanitizeListRestProps,
  CreateButton,
  EditButton,
  ShowButton,
  EmailField,
  FunctionField,
  Create,
  SimpleForm,
  ReferenceInput,
  useNotify,
  SelectInput,
  DateInput,
  TextInput,
  Edit,
  CheckboxGroupInput,
  NumberField,
  FileField,
  FileInput,
  Show,
  SimpleShowLayout,
  DateField,
  NumberInput,
  required,
  TabbedForm,
  FormTab,
  ImageField,
  ImageInput,
  useListContext,
  Pagination,
  Filter,
} from "react-admin";
import axios from "axios";
import moment from "moment";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from "react-bootstrap/Button";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import "./invoice.css";
import ZaitonLogo from '../assets/zaiton-plus-logo.jpg'
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextFieldMaterial from "@material-ui/core/TextField";

const CustomerFilter = (props) => {
  return (
    <NoPrint>
      <Filter {...props}>
        <TextInput label="Expense Type" source="expenseType" alwaysOn />
        <TextInput label="Note" source="note" />
        <TextInput label="User" source="user" />
        <DateInput label="Start Date" source="startDate" />
        <DateInput label="End Date" source="endDate" />
      </Filter>
    </NoPrint>
  );
};
const ListActions = (props) => {
  const role = localStorage.getItem("role");
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  return (
    <NoPrint>
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <Button
          onClick={() => {
            window.print();
          }}
        >
          Print Report
        </Button>
        {filters &&
          React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
          })}
        {(role === 'Admin' || role === 'Accountant') && <CreateButton basePath={basePath} />}
      </TopToolbar>
    </NoPrint>
  );
};
const PostPagination = (props) => (
  <NoPrint>
    <Pagination rowsPerPageOptions={[10, 25, 50, 100, 150]} {...props} />
  </NoPrint>
);

export const  ExpenceList = (props) => {
  const role = localStorage.getItem("role");
  const [expenceTypes, setExpenceTypes] = React.useState([])
  const [expenceType, setExpenceType] = React.useState([])
  const [total, setTotal] = React.useState(0);
  const [partialTotal, setPartialTotal] = React.useState(0);

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios.get(process.env.REACT_APP_URL + "/ExpenseTypes?_end=10000").then((resp) => {
      setExpenceTypes(resp.data)
    });
  }, [])
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios.get(`${process.env.REACT_APP_URL}/Expenses?_end=10&_order=ASC&_sort=id&_start=0&expenseType=${expenceType}`).then((resp) => {
      setTotal(resp.headers.outcomesall)
      setPartialTotal(resp.headers.outcomes)
    });
  }, [expenceType])
  return (
    <div id="expenses">
      <div style={{display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap'}}>
        {
          expenceTypes.map((el, key) => {
            return (
              <Button variant="info" style={{marginRight: 5}} onClick={() => setExpenceType(el.name)}>{el.name}</Button>
            )
          })
        }
      </div>
      <PrintProvider>
        <Print single={true} name="foo">
          <List
            {...props}
            // pagination={<PostPagination />}
            filterDefaultValues={{ expenseType: expenceType }}
            filters={<CustomerFilter />}
            actions={<ListActions />}
          >
            <Datagrid>
              {/* <FunctionField
                render={(record) => <Total props={record.total} />}
              /> */}
              <TextField source="id" />
              <NumberField
                source="price"
                options={{ style: "currency", currency: "USD" }}
              />
              <TextField source="expenseType" />
              <TextField source="user" />
              <TextField source="note" />
              <DateField source="date" />
              <DateField source="createdAt" />
              <FunctionField render={(record) => {
                return <iframe
                  id={`iframe${record.id}`}
                  style={{
                    height: 0,
                    width: 0,
                    position: "absolute",
                    display: "none",
                  }}
                >
                  <div id={`cachFrame${record.id}`}>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'darkgreen'}}>
                      <div style={{width: '42%', fontFamily: 'monospace'}}><p style={{letterSpacing: -1}}>Shajaratalzaiton Company For General Trading</p> <p style={{letterSpacing: -0.3}}>and Real Estate and Industial Investment</p></div>
                        <div style={{width: '20%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start'}}>
                          <div
                            style={{
                              height: 100,
                              width: 100,
                              // marginRight: 10,
                              // marginLeft: 10,
                              // marginTop: 30,
                            }}
                          >
                            <img
                              src={ZaitonLogo}
                              alt=""
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>
                        </div>
                        
                        <div style={{width: '30%'}}><p>شركة شجرة الزيتون للتجارة العامة والاستثمارات العقارية و الصناعية</p></div>
                      </div>
                      <div style={{display: 'flex', justifyContent: 'space-between', color: 'darkgreen', backgroundColor: 'lightyellow',marginTop: 5, height: 30, borderTopStyle: 'solid', borderTopColor: '#DBA800', borderTopWidth: 2,borderBottomColor: '#DBA800', borderBottomStyle: 'solid', borderBottomWidth: 2}}>
                        <p style={{margin: 3, padding: 3}}>Mob.:0750 300 5003 - 0750 300 5004</p>
                        <p style={{margin: 3, padding: 3}}>E-mail: zaiton.plus.city@gmail.com</p>
                      </div>
                      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <div>
                          <div style={{border: '1px solid #DBA800', width: 140}}><p style={{borderRight: '1px solid #DBA800', margin: 3, padding: 3, width: 30}}>دینار</p></div>
                          <div style={{border: '1px solid #DBA800', width: 140}}><p style={{borderRight: '1px solid #DBA800', margin: 3, padding: 3, width: 30}}>دۆلار</p></div>
                        </div>
                        <div style={{textAlign: 'center', lineHeight: 0.5}}>
                          <p>پسولەی وەرگرتن</p>
                          <p><u>Receipt Voucher</u></p>
                        </div>
                        <div style={{textAlign: 'right'}}>
                          <p>{record.id} :ژمارەی پسوولە</p>
                          <p>{moment(record.date).format('YYYY-MM-DD')} :ڕێکەوت</p>
                        </div>
                      </div>
                      <div style={{lineHeight: 0.5}}>
                        <div style={{display: "flex", justifyContent: 'space-between'}}>
                          <p>Paid For: {record.expenseType}</p>
                          <p>:(درا بە (دفعت الى</p>
                        </div>
                        <div style={{display: "flex", justifyContent: 'space-between'}}>
                          <p>Amount: {record.price}</p>
                          <p>:(بڕی (المبلغ</p>
                        </div>
                        <div style={{display: "flex", justifyContent: 'space-between'}}>
                          <p>Purpose of receipt:</p>
                          <p>:(بە مەبەستی (و ذلك عن</p>
                        </div>
                        <div style={{display: "flex", justifyContent: 'space-between'}}>
                          <p>Note:</p>
                          <p>:(تێبینیی (الملاحظة</p>
                        </div>
                      </div>
                      <br />
                      <div style={{display: 'flex', justifyContent: 'space-around'}}>
                        <div style={{textAlign: 'center', lineHeight: 0.5}}>
                          <p>واژووی وەرگر</p>
                          <p>{record.user}</p>
                        </div>
                        <p>واژووی پێدەر</p>
                      </div>
                    </div>
                  </iframe>
                }} />
              <FunctionField render={(record) => <Button onClick={() => {
                      // var content = document.getElementById(`rowNumber${key+1}`);
                      var pri = document.getElementById(`iframe${record.id}`)
                        .contentWindow;
                      pri.document.open();
                      pri.document.write(
                        document.getElementById(`cachFrame${record.id}`).innerHTML
                      );
                      pri.document.write(
                        "<hr />"
                      );
                      pri.document.write(
                        document.getElementById(`cachFrame${record.id}`).innerHTML
                      );
                      // pri.document.write(content.innerHTML);
                      pri.document.close();
                      pri.focus();
                      pri.print();
                    }}>Print</Button>} />
              {(role === 'Admin' || role === 'Accountant') && <EditButton className="noPrint" />}
              <ShowButton />
            </Datagrid>
          </List>

          <InputGroup className="mb-3" style={{ marginTop: 10 }} variant="outline-secondary">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">Total</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder="Total"
              aria-label="Total"
              aria-describedby="basic-addon1"
              value={`$${total}`}
              disabled={true}
            />
            <InputGroup.Prepend style={{marginLeft: 10}} >
              <InputGroup.Text id="basic-addon1">Partial Total</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder="Total"
              aria-label="Total"
              aria-describedby="basic-addon1"
              value={`$${partialTotal}`}
              disabled={true}
            />
          </InputGroup>
        </Print>
      </PrintProvider>
    </div>
  );
};
export const ExpenceShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="price" />
      <TextField source="expenseType" />
      <TextField source="user" />
      <TextField source="note" />
      <TextField source="attachment" />
      <DateField source="date" />
      <DateField source="createdAt" />
      <ImageField source="attachment" fullWidth={true} />
    </SimpleShowLayout>
  </Show>
);
export const ExpenceCreate = (props) => {
  const [file, setFile] = React.useState();
  const [id, setId] = React.useState(props.id);
  // const [expenceAttachments, setExpenceAttachments] = React.useState([]);
  const [expencePrice, setExpencePrice] = React.useState(0);
  const [expenceDate, setExpenceDate] = React.useState();
  const [expenceNote, setExpenceNote] = React.useState("");
  const [expenceType, setExpenceType] = React.useState("");
  const [expenseTypes, setExpenseTypes] = React.useState("");
  const [expenseTypesData, setExpenseTypesData] = React.useState([]);

  // React.useEffect(() => {
  //     axios.get(`${process.env.REACT_APP_URL}/Expenses/${id}`).then((resp) => {
  //         setExpenceAttachments(resp.data.attachment);
  //     });
  // }, []);

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
      .get(`${process.env.REACT_APP_URL}/ExpenseTypes`)
      .then((resp) => {
        setExpenseTypes(
          resp.data.map((expenseType) => {
            return {
              info: {
                id: expenseType.id,
                name: expenseType.name,
              },
              id: expenseType.id,
              name: expenseType.name,
            };
          })
        );
        setExpenseTypesData(resp.data);
      });
  }, []);

  const notify = useNotify();
  const fileSelectHandler = (e) => {
    // setFileName(e.target.files[0].name);
    getBase64(e.target.files[0], false);
  };

  //     const images = expenceAttachments && expenceAttachments.map((customerAttachment) => {
  //     return (
  //       customerAttachment && (
  //         <div style={{ margin: 10 }}>
  //           <div style={{ width: 300, height: 200 }}>
  //             <img
  //               src={"https://gutta.lv/wp-content/uploads/2015/10/test-img.jpg"}
  //               // src={customerAttachment.link}
  //               alt={customerAttachment.name}
  //               style={{
  //                 width: "100%",
  //                 height: "100%",
  //                 borderTopLeftRadius: 5,
  //                 borderTopRightRadius: 5,
  //               }}
  //             />
  //           </div>
  //             <iframe id={`iframe${customerAttachment.id}`} style={{height: 0, width: 0, position: 'absolute',display: 'none'}}>
  //                 <div style={{ width: '100%', height: '100%' }}>
  //                   <img
  //                     src={"https://gutta.lv/wp-content/uploads/2015/10/test-img.jpg"}
  //                     // src={customerAttachment.link}
  //                     alt={customerAttachment.name}
  //                     style={{
  //                       width: "100%",
  //                       height: "auto",
  //                     }}
  //                   />
  //                 </div>
  //             </iframe>
  //             <div style={{display: 'flex'}}>
  //               <button
  //                 style={{
  //                   width: 100,
  //                   backgroundColor: "lightblue",
  //                   color: "white",
  //                   textAlign: "center",
  //                   fontWeight: "bolder",
  //                   border: "none",
  //                   borderBottomLeftRadius: 5,
  //                 }}
  //                 onClick={() => printImage(customerAttachment.id)}
  //               >
  //                 Print
  //               </button>
  //               <a href={'https://gutta.lv/wp-content/uploads/2015/10/test-img.jpg'} target="_blank" style={{textDecoration: 'none'}}>
  //                 <div
  //                 style={{
  //                   width: 100,
  //                   backgroundColor: "green",
  //                   color: "#fff",
  //                   textAlign: "center",
  //                   fontWeight: "bolder",
  //                   paddingTop: 1,
  //                   paddingBottom: 2,
  //                   border: "none",
  //                 }}

  //               >
  //                 Download
  //               </div>
  //             </a>
  //               <button
  //                 style={{
  //                   width: 100,
  //                   backgroundColor: "#F32013",
  //                   color: "white",
  //                   textAlign: "center",
  //                   fontWeight: "bolder",
  //                   border: "none",
  //                   borderBottomRightRadius: 5,
  //                 }}
  //                 onClick={() => deleteImage(customerAttachment.id)}
  //               >
  //                 Delete
  //               </button>
  //           </div>

  //         </div>
  //       )
  //     );
  //   });

  function handleSubmit(e) {
    e.preventDefault();
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
      .post(process.env.REACT_APP_URL + "/Expenses", {
        attachment: file,
        note: expenceNote,
        price: expencePrice,
        date: expenceDate,
        expenseTypeId: expenceType,
        user: localStorage.getItem('name')
      })
      .then((resp) => {
        if (resp.status >= 200 && resp.status < 300) {
          notify("Expense Created!");
          window.open(`#/Expenses`, "_self");
        }
      })
      .catch((error) => notify("Couldn't Create Expense"));
  }

  function getBase64(file, expence) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setFile(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  function deleteImage(id) {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
      .delete(`${process.env.REACT_APP_URL}/PropertyAttachments/${id}`)
      .then((res) => console.log(res));
  }
  function printImage(id) {
    var pri = document.getElementById(`iframe${id}`).contentWindow;
    pri.document.open();
    pri.document.write(document.getElementById(`iframe${id}`).innerHTML);
    // pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  }
  return (
    <Create {...props}>
      {/* <TabbedForm> */}
      {/* <FormTab label="Expence">
                <TextInput source="price" fullWidth={true}/>
                <TextInput source="expenseType" fullWidth={true}/>
                <TextInput source="note" fullWidth={true}/>
                <DateInput source="date" fullWidth={true}/>
            </FormTab> */}
      <SimpleForm label="Attachment" toolbar={false}>
        <form style={{ width: "100%" }} onSubmit={handleSubmit}>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              onChange={(e) => setExpencePrice(e.target.value)}
              type="number"
              placeholder="Price"
              aria-describedby="basic-addon1"
            />
          </InputGroup>
          {/* <InputGroup className="mb-3">
            <DropdownButton
              as={InputGroup.Prepend}
              variant="outline-secondary"
              title="Expense Type"
              id="input-group-dropdown-1"
              onSelect={(e) => setExpenceType(parseInt(e))}
            >
              {expenseTypes}
            </DropdownButton>
            <FormControl
              aria-describedby="basic-addon1"
              value={expenseTypesData
                .filter((el) => el.id === expenceType)
                .map((el) => el.name)}
            />
          </InputGroup> */}
            <Autocomplete
              id="combo-box-demo"
              options={expenseTypes}
              getOptionLabel={(option) => {
                // setPropertyInfo({
                //   propertyData: option.info,
                //   propertyId: option.id,
                //   propertyPrice: option.price,
                // });
                return option.name;
              }}
              style={{ width: 500, marginBottom: 15 }}
              onChange={(e, option) => {
                setExpenceType(option.id)
              }}
              renderInput={(params) => (
                <TextFieldMaterial {...params} label="Expense Type" variant="outlined" />
              )}
            />
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">Date</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              onChange={(e) => setExpenceDate(e.target.value)}
              type="date"
              aria-describedby="basic-addon1"
            />
          </InputGroup>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>Note</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              onChange={(e) => setExpenceNote(e.target.value)}
              as="textarea"
              aria-label="With textarea"
            />
          </InputGroup>
          <input
            style={{
              marginTop: 5,
              borderColor: "red",
              borderWidth: 1,
              borderRadius: 4,
              padding: 5,
            }}
            type="file"
            onChange={fileSelectHandler}
          />
          <br />
          <input
            style={{
              marginTop: 5,
              border: "none",
              borderRadius: 4,
              padding: 5,
              paddingLeft: 20,
              paddingRight: 20,
            }}
            type="submit"
            value="send"
          />
        </form>
        {/* <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
                    {images}
                </div> */}
      </SimpleForm>
      {/* </TabbedForm> */}
    </Create>
  );
};

export const ExpenseEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <NumberInput source="price" fullWidth={true} validate={[required()]} />
      {/* <TextInput source="expenseTypeId" fullWidth={true} /> */}
      <ReferenceInput
        label="Expense type"
        source="expenseTypeId"
        reference="ExpenseTypes"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="user" fullWidth={true} />
      <TextInput source="note" fullWidth={true} />
      <DateInput source="date" fullWidth={true} />
    </SimpleForm>
  </Edit>
);
