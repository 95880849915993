import * as React from "react";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import axios from "axios";
import moment from "moment";
import Table from "react-bootstrap/Table";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import GenderVisits from './LeadAnalizes/GenderVisits'
import NumOfVisits from './LeadAnalizes/NumOfVisits'
import DailyLeadVisits from './LeadAnalizes/DailyLeadVisits'
import MonthlyLeadVisits from './LeadAnalizes/MonthlyLeadVisits'
import PerSources from './LeadAnalizes/PerSources'
import PrintProvider, { Print } from "react-easy-print";
import "./invoice.css";

function Reports() {
  const [activePhase, setActivePhase] = React.useState("Monthly Lead Visits");
  // const [propertiesData, setPropertiesData] = React.useState([]);
  // const [customersData, setCustomersData] = React.useState([]);
  const [startDate, setStartDate] = React.useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = React.useState(moment(startDate).add(1, "M").format("YYYY-MM-DD"));
  const [currentTab, setCurrentTab] = React.useState('monthlyLeadVisits');

  // React.useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  //   axios.get(process.env.REACT_APP_URL + "/Properties").then((resp) => {
  //       setPropertiesData(resp.data);
  //     });
  //   axios.get(process.env.REACT_APP_URL + "/Customers").then((resp) => {
  //     setCustomersData(resp.data);
  //   });
  // }, []);

  const role = localStorage.getItem("role");

  // const properties = (
  //   <div style={{ display: activePhase !== "Properties Report" && "none" }}>
  //     <Table striped bordered hover>
  //       <thead>
  //         <tr>
  //           <th>#</th>
  //           <th>Property Name</th>
  //           <th>Zone</th>
  //           <th>Block</th>
  //           {/* <th>Customer Name</th> */}
  //         </tr>
  //       </thead>
  //       <tbody>
  //         {propertiesData.map((property, key) => {
  //           return (
  //             <tr key={key}>
  //               <td>{key + 1}</td>
  //               <td>{property.name}</td>
  //               <td>{property.zone}</td>
  //               <td>{property.block}</td>
  //               {/* <td>{`${property.buyContract.customer.firstName} ${property.buyContract.customer.middleName}`}</td> */}
  //             </tr>
  //           );
  //         })}
  //       </tbody>
  //     </Table>
  //   </div>
  // );
  //
  // const customers = (
  //   <ul style={{ display: activePhase !== "Customers Report" && "none" }}>
  //     {customersData.map((customer, key) => {
  //       return <li key={key}>{`${customer.firstName} ${customer.middleName}`}</li>;
  //     })}
  //   </ul>
  // );

  return (
    <React.Fragment>
      <Card>
        <CardHeader title="Reports" />
        <div style={{display: 'flex'}}>
        <InputGroup className="mb-3" style={{ margin: 5 }}>
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">Start Date</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            type="date"
            placeholder="Starting Date"
            aria-label="Username"
            aria-describedby="basic-addon1"
            onChange={(e) => setStartDate(e.target.value)}
            value={moment(startDate).format("YYYY-MM-DD")}
          />
        </InputGroup>
        <InputGroup className="mb-3" style={{ margin: 5 }}>
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">End Date</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            type="date"
            placeholder="End Date"
            aria-label="Username"
            aria-describedby="basic-addon1"
            onChange={(e) => setEndDate(e.target.value)}
            value={moment(endDate).format("YYYY-MM-DD")}
          />
        </InputGroup>
        </div>
        <div style={{ display: "flex" }}>
          {/*{role !== 'Seller' && <CardContent*/}
          {/*  style={{*/}
          {/*    cursor: "pointer",*/}
          {/*    backgroundColor: activePhase === "Properties Report" && "#f2f2f2",*/}
          {/*    borderRadius: 4,*/}
          {/*    padding: 5,*/}
          {/*    margin: 5,*/}
          {/*  }}*/}
          {/*  onClick={() => {*/}
          {/*    setActivePhase("Properties Report");*/}
          {/*  }}*/}
          {/*>*/}
          {/*  Properties*/}
          {/*</CardContent>}*/}
          {/*{role !== 'Seller' && <CardContent*/}
          {/*  style={{*/}
          {/*    cursor: "pointer",*/}
          {/*    backgroundColor: activePhase === "Customers Report" && "#f2f2f2",*/}
          {/*    borderRadius: 4,*/}
          {/*    padding: 5,*/}
          {/*    margin: 5,*/}
          {/*  }}*/}
          {/*  onClick={() => {*/}
          {/*    setActivePhase("Customers Report");*/}
          {/*  }}*/}
          {/*>*/}
          {/*  Customers*/}
          {/*</CardContent>}*/}
          {/* {role !== 'Seller' && <CardContent
            style={{
              cursor: "pointer",
              backgroundColor: activePhase === "expenses" && "#f2f2f2",
              borderRadius: 4,
              padding: 5,
              margin: 5,
            }}
            onClick={() => {
              setActivePhase("expenses");
            }}
          >
            Expenses
          </CardContent>} */}
          <CardContent
            style={{
              cursor: "pointer",
              backgroundColor: activePhase === "Lead Analizes" && "#f2f2f2",
              borderRadius: 4,
              padding: 5,
              margin: 5,
            }}
            onClick={() => {
                setCurrentTab('leadAnalyzes');
                setActivePhase("Lead Analyzes");
            }}
          >
            Lead Analyzes
          </CardContent>
          <CardContent
            style={{
              cursor: "pointer",
              backgroundColor: activePhase === "Daily Lead Visits" && "#f2f2f2",
              borderRadius: 4,
              padding: 5,
              margin: 5,
            }}
            onClick={() => {
                setCurrentTab('dailyLeadVisits');
                setActivePhase("Daily Lead Visits");
            }}
          >
            Daily Lead Visits
          </CardContent>
          <CardContent
            style={{
              cursor: "pointer",
              backgroundColor: activePhase === "Monthly Lead Visits" && "#f2f2f2",
              borderRadius: 4,
              padding: 5,
              margin: 5,
            }}
            onClick={() => {
                setCurrentTab('monthlyLeadVisits');
                setActivePhase("Monthly Lead Visits");
            }}
          >
            Monthly Lead Visits
          </CardContent>
          <CardContent
            style={{
              cursor: "pointer",
              backgroundColor: activePhase === "Per Sources" && "#f2f2f2",
              borderRadius: 4,
              padding: 5,
              margin: 5,
            }}
            onClick={() => {
                setCurrentTab('perSources');
                setActivePhase("Per Sources");
            }}
          >
            Per Sources
          </CardContent>
          <Button
            style={{margin: 5, marginLeft: 'auto'}}
            onClick={() => {
              window.print();
            }}
          >
            Print Report
          </Button>
        </div>
      </Card>
      <Card style={{ marginTop: 15 }} id="report">
        <PrintProvider>
          <Print single={true} name="foo">
            <CardHeader title={activePhase} id="reportTitle" />
            {/*{role !== 'Seller' && properties}*/}
            {/*{role !== 'Seller' && customers}*/}
            <div style={{ display: activePhase !== "Lead Analyzes" ? 'none' : "flex" }}>
              <GenderVisits startDate={startDate} endDate={endDate} currentTab={currentTab} />
              <NumOfVisits startDate={startDate} endDate={endDate} currentTab={currentTab} />
            </div>
            <div style={{ display: activePhase !== "Daily Lead Visits" && 'none' }}>
              <DailyLeadVisits startDate={startDate} endDate={endDate} currentTab={currentTab} />
            </div>
            <div id="monthlyReport" style={{ display: activePhase !== "Monthly Lead Visits" && 'none' }}>
              <MonthlyLeadVisits startDate={startDate} endDate={endDate} currentTab={currentTab} />
            </div>
            <div style={{ display: activePhase !== "Per Sources" && 'none' }}>
              <PerSources startDate={startDate} endDate={endDate} currentTab={currentTab} />
            </div>
          </Print>
        </PrintProvider>
      </Card>
    </React.Fragment>
  );
}

export default Reports;
