import React, { PureComponent, useEffect, useState } from 'react';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  } from 'recharts';
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'

function GenderVisits(props) {
    const [visits, setVisits] = useState(0)
    const [visitGroup, setVisitGroup] = useState([])

    React.useEffect(() => {
        if (props.currentTab === 'leadAnalyzes') {
            const token = localStorage.getItem("token");
            axios.defaults.headers.common = {Authorization: `Bearer ${token}`};
            axios.get(`${process.env.REACT_APP_URL}/LeadVisits?startDate=${props.startDate}&endDate=${props.endDate}&_end=1000`).then((resp) => {
                setVisits(resp.data)

                setVisitGroup(resp.data.map(el => {
                    return {
                        name: el.id,
                        males: el.males,
                        females: el.females,
                        visitDate: moment(el.visitDate).format('YYYY-MM-DD')
                    }
                }).reduce((acc, value) => {
                    // Group initialization
                    if (!acc[value.visitDate]) {
                        acc[value.visitDate] = [];
                    }

                    // Grouping
                    acc[value.visitDate].push(value);

                    return acc;
                }, {}))

            });
        }
      }, [props.startDate, props.endDate, props.currentTab]);

    let data = []

    for (let x in visitGroup) {
        data.push({name: x, visits: visitGroup[x].length})
      }
    return (
        <div>
            <p style={{marginBottom: 0, paddingBottom: 0, color: '#666'}}>Number of Visits</p>
            <LineChart
                width={500}
                height={300}
                data={data.sort(function(a,b){
                    // Turn your strings into dates, and then subtract them
                    // to get a value that is either negative, positive, or zero.
                    return new Date(a.name) - new Date(b.name);
                })}
                margin={{
                top: 5, right: 30, left: 20, bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="visits" stroke="#8884d8" activeDot={{ r: 8 }} />
            </LineChart>
        </div>
    )
}

export default GenderVisits
