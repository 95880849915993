import * as React from "react";
import ZaitonLogo from "../assets/zaiton-plus-logo.jpg";
import axios from "axios";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import moment from "moment";
import "./invoice.css";
import Card from '@material-ui/core/Card';

export const ContractShow = (props) => {
    Date.prototype.toDateInputValue = function () {
      var local = new Date(this);
      local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
      return local.toJSON().slice(0, 10);
    };

    const [rows, setRows] = React.useState();
    const [periodOfInstallment, setPeriodOfInstallment] = React.useState(4);
    const [preInstallment, setPreInstallment] = React.useState(35);
    const [installments, setInstallments] = React.useState(50);
    const [getKeys, setGetKeys] = React.useState(15);
    const [properitiesArr, setProperitiesArr] = React.useState([]);
    const [properitiesData, setProperitiesData] = React.useState([]);
    const [customerArr, setCustomerArr] = React.useState([]);
    const [customerData, setCustomerData] = React.useState([]);
    const [properityData, setProperityData] = React.useState(0);
    const [defaultDate, setDefaultDate] = React.useState(
      moment().format("YYYY-MM-DD")
    );
    const [buyContractId, setBuyContractId] = React.useState(-1);
    const [note, setNote] = React.useState("");
    const [buyType, setBuyType] = React.useState("installment");
    const [customer, setCustomer] = React.useState("");
    const [customerId, setCustomerId] = React.useState();
    const [properity, setProperity] = React.useState("");
    const [pageNumber, setPageNumber] = React.useState(7);
    const [data, setData] = React.useState([]);
    const [extraPrice, setExtraPrice] = React.useState(0);
    const [extraNote, setExtraNote] = React.useState("");
    const [prePaid, setPrePaid] = React.useState(false);
    const [prePaymentText, setPrePaymentText] = React.useState(0);
    const [openSmSm, setOpenSmSm] = React.useState(false);
    const [contractNumber, setContractNumber] = React.useState("");
    const [arabicInvoice, setArabicInvoice] = React.useState(false);

    const keyValue = (properityData.initialPrice / 100) * getKeys;
    const prePayValue = (properityData.initialPrice / 100) * preInstallment;
    const eachInstallment =
      (properityData.initialPrice - (keyValue + prePayValue)) / rows;
    React.useEffect(() => {
      setInstallments(100 - (preInstallment + getKeys));
    }, [preInstallment]);
    React.useEffect(() => {
      setInstallments(100 - (preInstallment + getKeys));
    }, [getKeys]);

    const arrLength = [];
    for (let index = 0; index < rows; index++) {
      arrLength[index] = index;
    }
    const arr = ["first", "second", "third", "fourth"];

    const renameKeys = (keysMap, obj) =>
      Object.keys(obj).reduce(
        (acc, key) => ({
          ...acc,
          ...{ [keysMap[key] || key]: obj[key] },
        }),
        {}
      );

    // React.useEffect(() => {
    //   const token = localStorage.getItem("token");
    //   axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    //   axios
    //     .get(`${process.env.REACT_APP_URL}/Dashboard/GetContractInfo?contractNumber=${props.id}`)
    //     .then((resp) => {
    //       setData(resp.data);
    //       setOpenSmSm(resp.data.isPrePaymentPayed);
    //     });
    // }, []);
    // React.useEffect(() => {
    //   const token = localStorage.getItem("token");
    //   axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    //   axios
    //     .get(
    //       `${process.env.REACT_APP_URL}/Dashboard/NumberToTextKu?number=${data.prePaymentPrice}`
    //     )
    //     .then((resp) => {
    //       setPrePaymentText(resp.data);
    //     });
    //   setRows(data.paymentProcess && data.paymentProcess.length - 1);
    // }, [data]);
    // React.useEffect(() => {
    //   const token = localStorage.getItem("token");
    //   axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    //   if(arabicInvoice) {
    //     axios
    //     .get(
    //       `${process.env.REACT_APP_URL}/Dashboard/NumberToTextAr?number=${data.prePaymentPrice}`
    //     )
    //     .then((resp) => {
    //       setPrePaymentText(resp.data);
    //     });
    //   } else {
    //   axios
    //     .get(
    //       `${process.env.REACT_APP_URL}/Dashboard/NumberToTextKu?number=${data.prePaymentPrice}`
    //     )
    //     .then((resp) => {
    //       setPrePaymentText(resp.data);
    //     });
    //   }
    // }, [arabicInvoice]);

    function numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const role = localStorage.getItem('role')
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    let isGrayOut = false;
    return (
        // <List
        //   empty={false}
        //   {...props}
        //   pagination={false}
        // >
        <Card style={{marginTop: 50, padding: 5}}>
          <div id="divcontents">
              <div>
                <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                            Contract Number
                        </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            placeholder="Contract Number"
                            value={contractNumber}
                            type="text"
                            onChange={e => setContractNumber(e.target.value)}
                        />
                    </InputGroup>
                    <Button style={{width: 150}} onClick={() => {
                        const token = localStorage.getItem("token");
                        axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
                        axios
                        .get(`${process.env.REACT_APP_URL}/Dashboard/GetContractInfo?contractNumber=${contractNumber}`)
                        .then((resp) => {
                            setData(resp.data);
                            setOpenSmSm(resp.data.isPrePaymentPayed);
                        });
                    }}>Find</Button>
              </div>
                {data && <div style={{ display: "flex", width: "100%" }}>
                  <div
                    style={{
                      height: 150,
                      width: 150,
                      marginRight: 10,
                      marginLeft: 10,
                      marginTop: 30,
                    }}
                  >
                    <img
                      src={ZaitonLogo}
                      alt=""
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <div style={{ width: "85%" }}>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              Name
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            placeholder="Name"
                            value={data.property && data.property.name}
                            type="text"
                            disabled={true}
                          />
                        </InputGroup>
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              Zone
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            placeholder="Zone"
                            value={data.property && data.property.zone}
                            type="text"
                            disabled={true}
                          />
                        </InputGroup>
                      </div>
                      <div style={{ width: "50%", marginLeft: 20 }}>
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              Area
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            placeholder="Area"
                            value={data.property && `${data.property.area}`}
                            type="text"
                            disabled={true}
                          />
                        </InputGroup>
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              Street
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            placeholder="Street Width"
                            value={
                              data.property && `${data.property.streetWidth}m`
                            }
                            type="text"
                            disabled={true}
                          />
                          <FormControl
                            placeholder="Street Number"
                            value={data.property && data.property.streetNumber}
                            type="text"
                            disabled={true}
                          />
                        </InputGroup>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              Full Name
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            placeholder="Full Name"
                            value={
                              data.customer &&
                              `${data.customer.firstName} ${data.customer.middleName} ${data.customer.lastName}`
                            }
                            type="text"
                            disabled={true}
                          />
                        </InputGroup>
                        {/*<InputGroup className="mb-3">*/}
                        {/*  <InputGroup.Prepend>*/}
                        {/*    <InputGroup.Text id="basic-addon1">*/}
                        {/*      phone*/}
                        {/*    </InputGroup.Text>*/}
                        {/*  </InputGroup.Prepend>*/}

                        {/*  <FormControl*/}
                        {/*    placeholder="Phone"*/}
                        {/*    value={data.customer && data.customer.phone}*/}
                        {/*    type="text"*/}
                        {/*    disabled={true}*/}
                        {/*  />*/}
                        {/*</InputGroup>*/}
                      </div>
                      <div style={{ width: "50%", marginLeft: 20 }}>
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              Address
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            placeholder="Address"
                            value={data.customer && data.customer.address}
                            type="text"
                            disabled={true}
                          />
                        </InputGroup>
                        {/*<InputGroup className="mb-3">*/}
                        {/*  <InputGroup.Prepend>*/}
                        {/*    <InputGroup.Text id="basic-addon1">*/}
                        {/*      phone*/}
                        {/*    </InputGroup.Text>*/}
                        {/*  </InputGroup.Prepend>*/}
                        {/*  <FormControl*/}
                        {/*    placeholder="phone"*/}
                        {/*    value={data.customer && data.customer.phone2}*/}
                        {/*    type="text"*/}
                        {/*    disabled={true}*/}
                        {/*  />*/}
                        {/*</InputGroup>*/}
                      </div>
                    </div>
                  </div>
                </div>}
            </div>
        </Card>
    );
  }

export default ContractShow
