import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  FunctionField,
  SimpleForm,
  TextInput,
  Edit,
  ExportButton,
  Filter, useListContext, TopToolbar, sanitizeListRestProps, CreateButton, downloadCSV,
} from "react-admin";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import axios from 'axios';
import { Card, CardHeader } from "@material-ui/core";
import swal from 'sweetalert';
import PrintProvider, {NoPrint, Print} from "react-easy-print";
import moment from "moment";
import jsonExport from 'jsonexport/dist'

const CustomerFilter = (props) => (
  <Filter {...props}>
    <TextInput label="First Name" source="firstName" alwaysOn />
    <TextInput label="Middle Name" source="middleName" alwaysOn />
    <TextInput label="Zone" source="zone" alwaysOn />
  </Filter>
);

const ContractActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  return (
      <NoPrint>
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
          <Button
              onClick={() => {
                window.print();
              }}
          >
            Print Report
          </Button>
          <ExportButton />
        </TopToolbar>
      </NoPrint>
  );
};

export const UserNamesAndPassesList = (props) => {
  const role = localStorage.getItem("role");
  const exporter = (data) => {
    const dataForExport = data.map(data => {
      let {
        firstName, middleName,
        lastName, userName, phone,
        password, propertyNames
      } = data;
      let fullName = firstName + ' ' + middleName + ' ' + lastName;
      return {fullName, userName, password, phone, propertyNames};
    });
    jsonExport(dataForExport, {
      headers: [
        'fullName', 'userName', 'password',
        'newPhone', 'propertyNames'
      ],
      rename: ['Fullname', 'User Name', 'Password', 'Phone', 'Property Names']
    }, (err, csv) => {
      downloadCSV("\ufeff" + csv, 'usernames.' + moment(new Date()).format('YYMMDDHHmmssa'));
    });
  }

  return (
    <List {...props} title="User Login Info" filters={<CustomerFilter />} actions={<ContractActions />} exporter={exporter}>
      <PrintProvider>
        <Print single={true} name="foo">
          <Datagrid>
            <FunctionField
              label="Name"
              render={(record) => `${record.firstName} ${record.middleName} ${record.lastName}`}
            />
            <TextField source="userName" />
            <TextField source="password" />
            <TextField source="phone" />
            <TextField source="propertyNames" />
            <NoPrint>
              {role === 'Admin' ? <EditButton /> : null}
            </NoPrint>
          </Datagrid>
        </Print>
      </PrintProvider>
    </List>
  );
};

const UserName = (props) => `Username: ${props.record.userName}`;

export const UserNamesAndPassesEdit = (props) => (
  <Edit {...props} title={<UserName />}>
    <SimpleForm>
      {/* <FunctionField
        label="Name"
        render={(record) => `${record.firstName} ${record.middleName}`}
      /> */}
      <TextInput source="firstName" />
      <TextInput source="middleName" />
      <TextInput source="lastName" />
      <TextInput source="userName" />
      <TextInput source="password" />
    </SimpleForm>
  </Edit>
);

export const ChangePassword = (props) => {
  const [oldPassword, setOldPassword] = React.useState('')
  const [newPassword, setNewPassword] = React.useState('')
  const [confirmPassword, setConfirmPassword] = React.useState('')

  return (
    <Card>
      <CardHeader title="Change Password" />
      <div style={{margin: 5}}>
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">
              Old Password
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">
              New Password
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </InputGroup>
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">
              Confirm Password
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </InputGroup>
        <Button onClick={() => {
          const token = localStorage.getItem("token");
          axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

          (newPassword === confirmPassword) ? swal({
            title: "Are you sure?",
            text: "Your Password Will Change Permanently!",
            icon: "warning",
            buttons: true,
            // dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
              setNewPassword('')
              setOldPassword('')
              setConfirmPassword('')

              axios
              .post(`${process.env.REACT_APP_PATH}/ChangePassword?userId=${localStorage.getItem('loginId')}&password=${oldPassword}&newpassword=${newPassword}`)
              .then((resp) => {
                if(resp.status === 203) {
                  swal("Your Old Password is Incorrect!", {
                    icon: "error",
                  });
                  return
                }
                swal("Password Changed Successfully!", {
                  icon: "success",
                });
              })
              .catch((err) => {
                swal("Failed To Proceed Changing your Password!", {
                  icon: "error",
                });
              })

            } else {
              swal("Payment Dismised!");
            }
          }) :
          swal("Passwords do not Match!")
        }}>
          Change Password
        </Button>
      </div>
    </Card>
  )
}
