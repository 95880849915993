import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  DateField,
} from "react-admin";
import PrintProvider, { Print } from "react-easy-print";
import moment from "moment";
import axios from "axios";
import ZaitonLogo from "../assets/zaiton-plus-logo.jpg";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import KurdishTemplateJ from "./templates/KurdishContractJ";
import KurdishTemplate from "./templates/KurdishContract";
import ArabicTemplateJ from "./templates/ArabicContractJ";
import ArabicTemplate from "./templates/ArabicContract";
import ProgressBar from "react-bootstrap/ProgressBar";
import swal from "sweetalert";

export const TransferedContractList = (props) => {
  return (
    <div id="expenses">
      <PrintProvider>
        <Print single={true} name="foo">
          <List {...props} title="Transfered Contracts">
            <Datagrid rowClick="show">
              <FunctionField
                label="Previous Property"
                render={(record) => `${record.oldContract.property.name}`}
              />
              <FunctionField
                label="Previous Customer Name"
                render={(record) =>
                  `${record.oldContract.customer.firstName} ${record.oldContract.customer.middleName}`
                }
              />

              <TextField source="newContractName" />
              <DateField source="transferDate" />
              <TextField source="note" />
            </Datagrid>
          </List>
        </Print>
      </PrintProvider>
    </div>
  );
};

export const TransferedContractShow = (props) => {
  Date.prototype.toDateInputValue = function () {
    var local = new Date(this);
    local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
    return local.toJSON().slice(0, 10);
  };

  const [rows, setRows] = React.useState();
  const [periodOfInstallment, setPeriodOfInstallment] = React.useState(4);
  const [pageNumber, setPageNumber] = React.useState(7);
  const [data, setData] = React.useState([]);
  const [prePaid, setPrePaid] = React.useState(false);
  const [prePaymentText, setPrePaymentText] = React.useState(0);
  const [arabicInvoice, setArabicInvoice] = React.useState(false);
  const [isItPayment, setIsItPayment] = React.useState(null);
  const [isItKey, setIsItKey] = React.useState(null);

  React.useEffect(async () => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    await axios
        .get(`${process.env.REACT_APP_URL}/TransferedContracts/${props.id}`)
        .then((resp) => {
          setData(resp.data.oldContract ?? []);
          let isItPrePayment = resp.data.oldContract.paymentProcess.find(x => x.isItPrePayment.toString() === 'true');
          if (isItPrePayment) {
            let isItPrePaymentIndex = resp.data.oldContract.paymentProcess.findIndex(x => x.isItPrePayment.toString() === 'true');
            resp.data.oldContract.paymentProcess.splice(isItPrePaymentIndex, 1);
            setIsItPayment(isItPrePayment);
          }
          let isItKeyPayment = resp.data.oldContract.paymentProcess.find(x => x.isItKeyPayment.toString() === 'true');
          if (isItKeyPayment) {
            let isItKeyPaymentIndex = resp.data.oldContract.paymentProcess.findIndex(x => x.isItKeyPayment.toString() === 'true');
            resp.data.oldContract.paymentProcess.splice(isItKeyPaymentIndex, 1);
            setIsItKey(isItKeyPayment);
          }
        });
  }, []);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  let isGrayOut = false;
  return (
      <PrintProvider>
        <List
            empty={false}
            {...props}
            pagination={false}
        >
          <div id="divcontents">
            <Print single={true} name="foo">
              <Print single={true} name="foo">
                <div style={{ display: "flex", width: "100%" }}>
                  <div
                      style={{
                        height: 150,
                        width: 150,
                        marginRight: 10,
                        marginLeft: 10,
                        marginTop: 30,
                      }}
                  >
                    <img
                        src={ZaitonLogo}
                        alt=""
                        style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <div style={{ width: "85%" }}>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              Name
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                              placeholder="Name"
                              value={data.property && data.property.name}
                              type="text"
                              disabled={true}
                          />
                        </InputGroup>
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              Zone
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                              placeholder="Zone"
                              value={data.property && data.property.zone}
                              type="text"
                              disabled={true}
                          />
                        </InputGroup>
                      </div>
                      <div style={{ width: "50%", marginLeft: 20 }}>
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              Area
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                              placeholder="Area"
                              value={data.property && `${data.property.area}`}
                              type="text"
                              disabled={true}
                          />
                        </InputGroup>
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              Street
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                              placeholder="Street Width"
                              value={
                                data.property && `${data.property.streetWidth}m`
                              }
                              type="text"
                              disabled={true}
                          />
                          <FormControl
                              placeholder="Street Number"
                              value={data.property && data.property.streetNumber}
                              type="text"
                              disabled={true}
                          />
                        </InputGroup>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              Full Name
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                              placeholder="Full Name"
                              value={
                                data.customer &&
                                `${data.customer.firstName} ${data.customer.middleName} ${data.customer.lastName}`
                              }
                              type="text"
                              disabled={true}
                          />
                        </InputGroup>
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              phone
                            </InputGroup.Text>
                          </InputGroup.Prepend>

                          <FormControl
                              placeholder="Phone"
                              value={data.customer && data.customer.phone}
                              type="text"
                              disabled={true}
                          />
                        </InputGroup>
                      </div>
                      <div style={{ width: "50%", marginLeft: 20 }}>
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              Address
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                              placeholder="Address"
                              value={data.customer && data.customer.address}
                              type="text"
                              disabled={true}
                          />
                        </InputGroup>
                        <InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                              phone
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                              placeholder="phone"
                              value={data.customer && data.customer.phone2}
                              type="text"
                              disabled={true}
                          />
                        </InputGroup>
                      </div>
                    </div>
                  </div>
                </div>
                <InputGroup
                    className="mb-3"
                    style={{ width: "99%", margin: "auto" }}
                >
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                      Contract Number
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                      placeholder="Contract Number"
                      value={data.contractNumber}
                      type="text"
                      disabled={true}
                  />
                </InputGroup>
              </Print>
              <div style={{ width: "99%", margin: "auto" }}>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                      Property Price
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                      placeholder="Price"
                      value={data && data.buyingPrice && numberWithCommas(data.buyingPrice)}
                      disabled={true}
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                      No. of Installments
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                      type="number"
                      disabled={true}
                      value={rows}
                  />
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                      Period of Installments
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                      type="number"
                      disabled={true}
                      value={periodOfInstallment}
                  />
                </InputGroup>
              </div>
              <Table
                  bordered
                  hover
                  style={{ width: "99%", margin: "auto", marginBottom: 15 }}
              >
                <thead>
                <tr>
                  <th>Installment</th>
                  <th>Price</th>
                  <th>Date</th>
                </tr>
                </thead>
                <tbody>
                <td>Pre Payment</td>
                <td>
                  <InputGroup className="mb-3" style={{ marginRight: 5 }}>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">{data && data.prePaymentPrice && `$${numberWithCommas(data.prePaymentPrice)}`}</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        name="payPrice"
                        disabled={true}
                        value={prePaymentText}
                    />
                  </InputGroup>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>Note</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        as="textarea"
                        aria-label="Note"
                        disabled={true}
                        value={data.prePaymentNote}
                        id={`prePaymentNote`}
                    />
                  </InputGroup>
                </td>
                <td>
                  <InputGroup className="mb-3" style={{ marginLeft: 5 }}>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="basic-addon1">date</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        type="date"
                        name="payDate"
                        disabled={true}
                        value={moment(data.buyingDate).format("YYYY-MM-DD")}
                    />
                  </InputGroup>
                </td>
                {data.paymentProcess &&
                data.paymentProcess.map((el, key) => {
                  return (
                      <tr id={`rowNumber${key + 1}`}>
                        <td>{`${key + 1}`}<i className='payed'>{el.isPayed && ' - Payed'}</i></td>
                        <td colSpan="1">
                          <InputGroup
                              className="mb-3"
                              style={{ marginRight: 5 }}
                          >
                            <InputGroup.Prepend>
                              <InputGroup.Text id="basic-addon1">
                                {el.currentPayPrice && `$${numberWithCommas(el.currentPayPrice)}`}
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                name="payPrice"
                                disabled={true}
                                value={!arabicInvoice ? el.kuPriceText : el.arPriceText}
                            />
                          </InputGroup>
                          <InputGroup style={{ display: isGrayOut && "none" }}>
                            <InputGroup.Prepend>
                              <InputGroup.Text>Note</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                as="textarea"
                                aria-label="Note"
                                disabled={true}
                                value={el.note}
                                id={`note${key}`}
                            />
                          </InputGroup>
                          <hr style={{ display: isGrayOut && "none" }}/>
                          <InputGroup
                              style={{
                                display: isGrayOut && "none",
                                marginTop: 15,
                              }}
                              className="mb-3"
                          >
                            <InputGroup.Prepend>
                              <InputGroup.Text id="basic-addon1">
                                Partial Payment Price
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="Partial Payment Price"
                                type="number"
                                disabled={true}
                                id={`partialPrice${key}`}
                                value={el.partialPrice}
                            />
                          </InputGroup>
                          <InputGroup style={{ display: isGrayOut && "none" }}>
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                Partial Payment Note
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                as="textarea"
                                aria-label="Note"
                                disabled={true}
                                value={el.partialNote}
                                id={`partialNote${key}`}
                            />
                          </InputGroup>
                          <InputGroup
                              className="mb-3"
                              style={{ marginTop: 15,display: isGrayOut && "none" }}
                          >
                            <InputGroup.Prepend>
                              <InputGroup.Text id="basic-addon1">
                                Partial Deadline
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                type="date"
                                disabled={true}
                                name="payDate"
                                id={`partialDeadline${key}`}
                                defaultValue={moment(el.partialDeadline).format(
                                    "YYYY-MM-DD"
                                )}
                            />
                          </InputGroup>
                        </td>
                        <td>
                          <InputGroup
                              className="mb-3"
                          >
                            <InputGroup.Prepend>
                              <InputGroup.Text id="basic-addon1">
                                Date
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                type="date"
                                name="payDate"
                                disabled={true}
                                value={moment(el.currentPayDate).format(
                                    "YYYY-MM-DD"
                                )}
                            />
                          </InputGroup>
                          <InputGroup
                              style={{ display: isGrayOut && "none" }}
                              className="mb-3"
                          >
                            <InputGroup.Prepend>
                              <InputGroup.Text id="basic-addon1">
                                Extra Price
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="Extra Price"
                                type="number"
                                disabled={true}
                                id={`extraPrice${key}`}
                                value={el.extraPrice}
                            />
                          </InputGroup>
                          <InputGroup style={{ display: isGrayOut && "none" }}>
                            <InputGroup.Prepend>
                              <InputGroup.Text>Note</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                as="textarea"
                                aria-label="Note"
                                disabled={true}
                                value={el.extraNote}
                                id={`extraNote${key}`}
                            />
                          </InputGroup>

                          <InputGroup
                              className="mb-3"
                              style={{
                                marginRight: 5,
                                marginTop: 15,
                                display: isGrayOut && "none",
                              }}
                          >
                            <InputGroup.Prepend>
                              <InputGroup.Text id="basic-addon1">
                                Paid By
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                id={`paidBy${key}`}
                                disabled={true}
                                defaultValue={`${data.customer.firstName} ${data.customer.middleName} ${data.customer.lastName}`}
                                value={el.payedBy}
                            />
                          </InputGroup>
                        </td>
                      </tr>
                  );
                })}
                {isItPayment &&
                <tr id={`rowNumber${isItPayment.id}`}>
                  <td>Structure<i className='payed'>{isItPayment.isPayed && ' - Payed'}</i></td>
                  <td colSpan="1">
                    <InputGroup
                        className="mb-3"
                        style={{ marginRight: 5 }}
                    >
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          {isItPayment.currentPayPrice && `$${numberWithCommas(isItPayment.currentPayPrice)}`}
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                          name="payPrice"
                          disabled={true}
                          value={!arabicInvoice ? isItPayment.kuPriceText : isItPayment.arPriceText}
                      />
                    </InputGroup>
                    <InputGroup style={{ display: isGrayOut && "none" }}>
                      <InputGroup.Prepend>
                        <InputGroup.Text>Note</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                          as="textarea"
                          aria-label="Note"
                          disabled={true}
                          value={isItPayment.note}
                          id={`note${isItPayment.id}`}
                      />
                    </InputGroup>
                    <hr style={{ display: isGrayOut && "none" }}/>
                    <InputGroup
                        style={{
                          display: isGrayOut && "none",
                          marginTop: 15,
                        }}
                        className="mb-3"
                    >
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          Partial Payment Price
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                          placeholder="Partial Payment Price"
                          type="number"
                          disabled={true}
                          id={`partialPrice${isItPayment.id}`}
                          value={isItPayment.partialPrice}
                      />
                    </InputGroup>
                    <InputGroup style={{ display: isGrayOut && "none" }}>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          Partial Payment Note
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                          as="textarea"
                          aria-label="Note"
                          disabled={true}
                          value={isItPayment.partialNote}
                          id={`partialNote${isItPayment.id}`}
                      />
                    </InputGroup>
                    <InputGroup
                        className="mb-3"
                        style={{ marginTop: 15,display: isGrayOut && "none" }}
                    >
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          Partial Deadline
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                          type="date"
                          disabled={true}
                          name="payDate"
                          id={`partialDeadline${isItPayment.id}`}
                          defaultValue={moment(isItPayment.partialDeadline).format(
                              "YYYY-MM-DD"
                          )}
                      />
                    </InputGroup>
                  </td>
                  <td>
                    <InputGroup
                        className="mb-3"
                    >
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          Date
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                          type="date"
                          name="payDate"
                          disabled={true}
                          defaultValue={moment(isItPayment.currentPayDate).add(periodOfInstallment, 'M').format("YYYY-MM-DD")}
                      />
                    </InputGroup>
                    <InputGroup
                        style={{ display: isGrayOut && "none" }}
                        className="mb-3"
                    >
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          Extra Price
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                          placeholder="Extra Price"
                          type="number"
                          disabled={true}
                          id={`extraPrice${isItPayment.id}`}
                          value={isItPayment.extraPrice}
                      />
                    </InputGroup>
                    <InputGroup style={{ display: isGrayOut && "none" }}>
                      <InputGroup.Prepend>
                        <InputGroup.Text>Note</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                          as="textarea"
                          aria-label="Note"
                          disabled={true}
                          value={isItPayment.extraNote}
                          id={`extraNote${isItPayment.id}`}
                      />
                    </InputGroup>

                    <InputGroup
                        className="mb-3"
                        style={{
                          marginRight: 5,
                          marginTop: 15,
                          display: isGrayOut && "none",
                        }}
                    >
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          Paid By
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                          id={`paidBy${isItPayment.id}`}
                          disabled={true}
                          defaultValue={`${data.customer.firstName} ${data.customer.middleName} ${data.customer.lastName}`}
                          value={isItPayment.payedBy}
                      />
                    </InputGroup>
                  </td>
                </tr>}
                {isItKey &&
                <tr id={`rowNumber${isItKey.id}`}>
                  <td>Delivering Keys</td>
                  <td colSpan="1">
                    <InputGroup
                        className="mb-3"
                        style={{ marginRight: 5 }}
                    >
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          {isItKey.currentPayPrice && `$${numberWithCommas(isItKey.currentPayPrice)}`}
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                          // type="number"
                          name="payPrice"
                          disabled={true}
                          value={!arabicInvoice ? isItKey.kuPriceText : isItKey.arPriceText}
                      />
                    </InputGroup>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>Note</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                          as="textarea"
                          aria-label="Note"
                          disabled={true}
                          value={isItKey.note}
                          id={`note${isItKey.id}`}
                      />
                    </InputGroup>
                  </td>
                  <td>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          Extra Price
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                          placeholder="Extra Price"
                          type="number"
                          disabled={true}
                          id={`extraPrice${isItKey.id}`}
                          value={isItKey.extraPrice}
                      />
                    </InputGroup>
                    <InputGroup
                        className="mb-3"
                    >
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          Date
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                          type="date"
                          name="payDate"
                          disabled={true}
                          defaultValue={moment(isItKey.currentPayDate).format(
                              "YYYY-MM-DD"
                          )}
                      />
                    </InputGroup>
                  </td>
                </tr>}
                </tbody>
              </Table>
            </Print>
            <iframe
                id="kurdishContractPrint"
                style={{
                  height: 0,
                  width: 0,
                  position: "absolute",
                  display: "none",
                }}
            >
              {data.property && data.property.zone === 'J'
                  ? <KurdishTemplateJ data={data} pageNumber={pageNumber} isItPayment={isItPayment} isItKey={isItKey} />
                  : <KurdishTemplate data={data} pageNumber={pageNumber} isItKey={isItKey} />
              }
            </iframe>
            <iframe
                id="arabicContractPrint"
                style={{
                  height: 0,
                  width: 0,
                  position: "absolute",
                  display: "none",
                }}
            >
              {data.property && data.property.zone === 'J'
                  ? <ArabicTemplateJ data={data} pageNumber={pageNumber} isItPayment={isItPayment} isItKey={isItKey} />
                  : <ArabicTemplate data={data} pageNumber={pageNumber} isItKey={isItKey} />
              }
            </iframe>
            <div style={{ display: "flex" }}>
              <p style={{ margin: 10 }}>
                Paid Price:{" $"}
                {data.paymentProcess &&
                data.paymentProcess
                    .filter((el) => el.isPayed)
                    .map((el) => el.currentPayPrice)
                    .reduce(reducer, 0) +
                ((data.isPrePaymentPayed || prePaid) && data.prePaymentPrice)}
              </p>
              <p style={{ margin: 10 }}>
                Remained Price:{" $"}
                {(data && data.buyingPrice) -
                ((data.paymentProcess &&
                    data.paymentProcess
                        .filter((el) => el.isPayed)
                        .map((el) => el.currentPayPrice)
                        .reduce(reducer, 0)) +
                    ((data.isPrePaymentPayed || prePaid) &&
                        data.prePaymentPrice))}
              </p>
              <ProgressBar
                  style={{ width: "30%", margin: 14 }}
                  now={
                    ((data.paymentProcess &&
                        data.paymentProcess
                            .filter((el) => el.isPayed)
                            .map((el) => el.currentPayPrice)
                            .reduce(reducer, 0) +
                        ((data.isPrePaymentPayed || prePaid) &&
                            data.prePaymentPrice)) /
                        (data && data.buyingPrice)) *
                    100
                  }
                  label={`${Math.floor(
                      ((data.paymentProcess &&
                          data.paymentProcess
                              .filter((el) => el.isPayed)
                              .map((el) => el.currentPayPrice)
                              .reduce(reducer, 0) +
                          ((data.isPrePaymentPayed || prePaid) &&
                              data.prePaymentPrice)) /
                          (data && data.buyingPrice)) *
                      100
                  )}%`}
              />
            </div>
            <Button
                style={{ margin: 10, marginTop: 0 }}
                onClick={() => {
                  // var content = document.getElementById(`rowNumber${key+1}`);
                  var pri = document.getElementById("kurdishContractPrint")
                      .contentWindow;
                  pri.document.open();
                  pri.document.write(
                      document.getElementById(`kurdishContractPrint`).innerHTML
                  );
                  // pri.document.write(content.innerHTML);
                  pri.document.close();
                  pri.focus();
                  pri.print();
                }}
            >
              Print Contract - Kurdish
            </Button>
            <Button
                style={{ margin: 10, marginTop: 0 }}
                onClick={() => {
                  // var content = document.getElementById(`rowNumber${key+1}`);
                  var pri = document.getElementById("arabicContractPrint")
                      .contentWindow;
                  pri.document.open();
                  pri.document.write(
                      document.getElementById(`arabicContractPrint`).innerHTML
                  );
                  // pri.document.write(content.innerHTML);
                  pri.document.close();
                  pri.focus();
                  pri.print();
                }}
            >
              Print Contract - Arabic
            </Button>
          </div>
        </List>
      </PrintProvider>
  );
};