import React, { useEffect, useState } from "react";
import "./invoice.css";
import HZone from "./zones/HZone";
import KZone from "./zones/KZone";
import IZone from "./zones/IZone";
import JZone from "./zones/JZone";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import LoadingBar from 'react-top-loading-bar'

function Map() {
  const [zone, setZone] = useState();
  const [progress, setProgress] = useState(0)
  const [hZone, setHZone] = useState(<HZone resetZone={() => resetZone()} setLoader={() => setLoader()} />);
  const [kZone, setKZone] = useState(<KZone resetZone={() => resetZone()} setLoader={() => setLoader()} />);
  const [jZone, setJZone] = useState(<JZone resetZone={() => resetZone()} setLoader={() => setLoader()} />);
  const [iZone, setIZone] = useState(<IZone resetZone={() => resetZone()} setLoader={() => setLoader()} />);
  const [zoneInfo, setZoneInfo] = useState([]);
  const [progressBackground, setProgressBackground] = useState('transparent');

  function resetZone() {
    setZone();
    setProgress(0)
  }

  function setLoader() {
    setProgress(100)
  }

  const hGroup = document.getElementById("H-Group");
  hGroup && hGroup.addEventListener("click", () => {
    setZone("HZone")
    setProgressBackground('rgb(229,229,229)')
    setProgress(30)
  });

  const kGroup = document.getElementById("K-Group");
  kGroup && kGroup.addEventListener("click", () => {
    setZone("KZone")
    setProgressBackground('rgb(229,229,229)')
    setProgress(40)
  });

  const jGroup = document.getElementById("J-Group");
  jGroup && jGroup.addEventListener("click", () => {
    setZone("JZone")
    setProgressBackground('rgb(229,229,229)')
    setProgress(35)
  });

  const iGroup = document.getElementById("I-Group");
  iGroup && iGroup.addEventListener("click", () => {
    setZone("IZone")
    setProgressBackground('rgb(229,229,229)')
    setProgress(30)
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
      .post(process.env.REACT_APP_URL + "/Dashboard/ZoneInfo")
      .then((res) => setZoneInfo(res.data));
  }, []);

  // window.addEventListener('scroll',function(e) {
  //  document.getElementsByClassName('loadingBar')[0].style.marginTop = "-48px"
  // });

  return (
    <div>
      <div
        style={{
          justifyContent: "center",
          width: "100%",
          display: zone ? "none" : "flex",
          marginTop: 15,
        }}
      >
        <Button variant="info">
          Zone - H <Badge variant="light">%{Math.floor(zoneInfo.hZone)}</Badge>
          <span className="sr-only">unread messages</span>
        </Button>
        <Button variant="info" style={{ marginLeft: 10 }}>
          Zone - I <Badge variant="light">%{Math.floor(zoneInfo.iZone)}</Badge>
          <span className="sr-only">unread messages</span>
        </Button>
        <Button variant="info" style={{ marginLeft: 10 }}>
          Zone - J <Badge variant="light">%{Math.floor(zoneInfo.jZone)}</Badge>
          <span className="sr-only">unread messages</span>
        </Button>
        <Button variant="info" style={{ marginLeft: 10 }}>
          Zone - K <Badge variant="light">%{Math.floor(zoneInfo.kZone)}</Badge>
          <span className="sr-only">unread messages</span>
        </Button>
      </div>
      {/* <div> */}
        <LoadingBar
          color='rgb(255,0,0)'
          progress={progress}
          className='loadingBar'
          height={20}
          shadow={false}
          background={progressBackground}
          // background='rgb(229,229,229)'
          onLoaderFinished={() => {
            document.getElementsByClassName('loadingBar')[0] && (document.getElementsByClassName('loadingBar')[0].style.marginTop = "0px")
            setProgress(0)
            setProgressBackground('transparent')
          }}
        />
      {/* </div> */}
      {zone === "HZone" && hZone}
      {zone === "KZone" && kZone}
      {zone === "JZone" && jZone}
      {zone === "IZone" && iZone}
      <svg
        style={{ display: zone && "none" }}
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1024 1024"
      >
        <title>ALL</title>
        <g id="Map">
          <g id="H-Group">
            <path
              d="M172.09,445H433.63V380.16h-242s-9.51,23.31-11.92,31.25C177.25,419.68,172.09,445,172.09,445Z"
              style={{ fill: "#fdfce3" }}
            />
            <path
              d="M200.61,361.29H433.34v-64.5H242.75a301.63,301.63,0,0,0-22.83,30.5C210.53,341.85,200.61,361.29,200.61,361.29Z"
              style={{ fill: "#fdfce3" }}
            />
            <path
              d="M260.42,277.7H433.09v-95C330.75,200.7,260.42,277.7,260.42,277.7Z"
              style={{ fill: "#fdfce3" }}
            />
            <g id="H4">
              <polygon
                points="166.68 480.61 189.41 480.61 214.26 480.61 239.12 480.61 263.97 480.61 288.82 480.61 313.67 480.61 338.52 480.61 363.37 480.61 388.22 480.61 413.08 480.61 413.08 512.31 388.22 512.31 363.37 512.31 338.52 512.31 313.67 512.31 288.82 512.31 263.97 512.31 239.12 512.31 214.26 512.31 189.41 512.31 164.81 512.31 165.3 495.52 166.68 480.61"
                style={{ fill: "#fdfce3" }}
              />
            </g>
            <g id="B.H3">
              <path
                d="M170.21,448.29h264a3.71,3.71,0,0,0,3.7-3.71v-64a3.7,3.7,0,0,0-3.7-3.7H191.3a10.23,10.23,0,0,0-9.66,6.86c-2.89,8.31-7.57,21.89-9.35,27.76-1.84,6.08-5.6,20.41-7.9,29.29A6,6,0,0,0,170.21,448.29Z"
                style={{
                  fill: "none",
                  stroke: "#000",
                  strokeMiterlimit: 10,
                  strokeWidth: 0.5,
                }}
              />
              <path
                d="M173,445.66H434.5V380.85h-242S183,404.16,180.62,412.1C178.12,420.37,173,445.66,173,445.66Z"
                style={{
                  fill: "none",
                  stroke: "#000",
                  strokeMiterlimit: 10,
                  strokeWidth: 0.5,
                }}
              />
              <rect x="410.29" y="380.77" width="0.5" height="64.83" />
              <rect x="386.15" y="380.77" width="0.5" height="64.83" />
              <rect x="362.09" y="380.83" width="0.5" height="64.83" />
              <rect x="337.96" y="380.83" width="0.5" height="64.83" />
              <rect x="313.79" y="380.83" width="0.5" height="64.83" />
              <rect x="289.68" y="380.83" width="0.5" height="64.83" />
              <rect x="265.64" y="380.86" width="0.5" height="64.83" />
              <rect x="241.56" y="380.86" width="0.5" height="64.83" />
              <rect x="217.38" y="380.86" width="0.5" height="64.83" />
              <rect x="180.57" y="412.22" width="254.16" height="0.5" />
            </g>
            <g id="B.H2">
              <path
                d="M195.18,364.16H433.9a3.11,3.11,0,0,0,3.11-3.11v-64.4a3.11,3.11,0,0,0-3.11-3.11H241.05a11.59,11.59,0,0,0-9.79,5.37s-14.78,19.22-19.16,26c-5.39,8.35-19.45,34.65-19.45,34.65A3,3,0,0,0,195.18,364.16Z"
                style={{
                  fill: "none",
                  stroke: "#000",
                  strokeMiterlimit: 10,
                  strokeWidth: 0.5,
                }}
              />
              <path
                d="M201.08,361.29H433.8v-64.5H243.22a301.63,301.63,0,0,0-22.83,30.5C211,341.85,201.08,361.29,201.08,361.29Z"
                style={{
                  fill: "none",
                  stroke: "#000",
                  strokeMiterlimit: 10,
                  strokeWidth: 0.5,
                }}
              />
              <rect x="409.39" y="296.82" width="0.5" height="64.44" />
              <rect x="385.22" y="296.82" width="0.5" height="64.44" />
              <rect x="361.11" y="296.82" width="0.5" height="64.44" />
              <rect x="337.04" y="296.82" width="0.5" height="64.44" />
              <rect x="312.88" y="296.82" width="0.5" height="64.44" />
              <rect x="288.85" y="296.82" width="0.5" height="64.44" />
              <rect x="264.66" y="296.82" width="0.5" height="64.44" />
              <rect x="240.62" y="328.97" width="0.5" height="32.27" />
              <polygon points="219.48 328.7 219.32 329.2 433.8 329.2 433.8 328.7 219.48 328.7" />
            </g>
            <g id="B.H1">
              <path
                d="M260.75,277.7H433.41v-95C331.08,200.7,260.75,277.7,260.75,277.7Z"
                style={{
                  fill: "none",
                  stroke: "#000",
                  strokeMiterlimit: 10,
                  strokeWidth: 0.5,
                }}
              />
              <path
                d="M254.6,281.2H432.16a4.88,4.88,0,0,0,4.88-4.88V180.25a4.89,4.89,0,0,0-5.84-4.79C341,193.25,273.89,251,251.21,272.8A4.88,4.88,0,0,0,254.6,281.2Z"
                style={{
                  fill: "none",
                  stroke: "#000",
                  strokeMiterlimit: 10,
                  strokeWidth: 0.5,
                }}
              />
              <rect x="409.25" y="187.98" width="0.5" height="33.59" />
              <rect x="385.56" y="221.08" width="48.07" height="0.5" />
              <rect x="385.18" y="195.77" width="0.5" height="81.77" />
              <rect x="409.25" y="245.52" width="0.5" height="32.04" />
              <rect x="313.08" y="245.19" width="120.42" height="0.5" />
              <polygon points="361.5 277.57 361 276.93 361 206.05 361.5 206.05 361.5 277.57" />
              <polygon points="313.33 277.57 312.83 277.57 312.83 234.3 313.33 234.01 313.33 277.57" />
              <polygon points="337.41 277.57 336.91 277.57 336.91 245.64 337.41 245.43 337.41 277.57" />
            </g>
            <line
              x1="195.76"
              y1="371.04"
              x2="437.01"
              y2="371.04"
              style={{
                fill: "#fff",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <line
              x1="252.46"
              y1="288.5"
              x2="437.01"
              y2="288.5"
              style={{
                fill: "#fff",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <polygon
              points="469.57 177.73 469.57 200.46 469.57 225.31 469.57 250.16 469.57 275.02 469.57 299.87 469.57 324.72 469.57 349.57 469.57 374.42 469.57 399.27 469.57 424.13 501.27 424.13 501.27 399.27 501.27 374.42 501.27 349.57 501.27 324.72 501.27 299.87 501.27 275.02 501.27 250.16 501.27 225.31 501.27 200.46 501.27 175.86 484.48 176.35 469.57 177.73"
              style={{ fill: "#fdfce3" }}
            />
            <path
              d="M255.17,193.91c-3.12.3-3.42.5-3.42,3.7v15.15c0,3.19.33,3.33,3.42,3.66v1.13H244.68v-1.13c3.14-.38,3.43-.47,3.43-3.66v-7.54H235.57v7.54c0,3.16.31,3.29,3.39,3.66v1.13H228.5v-1.13c3-.34,3.4-.47,3.4-3.66V197.61c0-3.2-.34-3.41-3.4-3.7v-1.13H239v1.13c-3,.27-3.39.53-3.39,3.7v5.94h12.54v-5.94c0-3.17-.41-3.43-3.43-3.7v-1.13h10.49Z"
              style={{ fill: "#f7d731" }}
            />
          </g>
          <g id="I-Group">
            <g id="B.I3">
              <path
                d="M833.36,448.29h-264a3.71,3.71,0,0,1-3.71-3.71v-64a3.71,3.71,0,0,1,3.71-3.7H812.28a10.23,10.23,0,0,1,9.66,6.86c2.89,8.31,7.57,21.89,9.35,27.76,1.84,6.08,5.6,20.41,7.9,29.29A6,6,0,0,1,833.36,448.29Z"
                style={{
                  fill: "none",
                  stroke: "#000",
                  strokeMiterlimit: 10,
                  strokeWidth: 0.5,
                }}
              />
              <path
                d="M830.12,445H568.58V380.16h242s9.51,23.31,11.92,31.25C825,419.68,830.12,445,830.12,445Z"
                style={{
                  fill: "#e8f5fd",
                  stroke: "#000",
                  strokeMiterlimit: 10,
                  strokeWidth: 0.5,
                }}
              />
              <rect x="592.79" y="380.16" width="0.5" height="64.44" />
              <rect x="616.92" y="380.16" width="0.5" height="64.44" />
              <rect x="640.98" y="380.22" width="0.5" height="64.44" />
              <rect x="665.12" y="380.22" width="0.5" height="64.44" />
              <rect x="689.29" y="380.22" width="0.5" height="64.44" />
              <rect x="713.4" y="380.22" width="0.5" height="64.44" />
              <rect x="737.43" y="380.25" width="0.5" height="64.44" />
              <rect x="761.51" y="380.25" width="0.5" height="64.44" />
              <rect x="785.7" y="380.25" width="0.5" height="64.44" />
              <polygon points="822.5 412.22 822.68 412.72 568.74 412.72 568.74 412.22 822.5 412.22" />
            </g>
            <g id="B.I2">
              <path
                d="M807.5,364.16H568.78a3.12,3.12,0,0,1-3.12-3.11v-64.4a3.12,3.12,0,0,1,3.12-3.11H761.63a11.59,11.59,0,0,1,9.79,5.37s14.78,19.22,19.16,26C796,333.26,810,359.56,810,359.56A3,3,0,0,1,807.5,364.16Z"
                style={{
                  fill: "none",
                  stroke: "#000",
                  strokeMiterlimit: 10,
                  strokeWidth: 0.5,
                }}
              />
              <path
                d="M801.6,361.29H568.87v-64.5H759.46a301.63,301.63,0,0,1,22.83,30.5C791.68,341.85,801.6,361.29,801.6,361.29Z"
                style={{
                  fill: "#e8f5fd",
                  stroke: "#000",
                  strokeMiterlimit: 10,
                  strokeWidth: 0.5,
                }}
              />
              <rect x="592.79" y="296.82" width="0.5" height="64.44" />
              <rect x="616.96" y="296.82" width="0.5" height="64.44" />
              <rect x="641.06" y="296.82" width="0.5" height="64.44" />
              <rect x="665.14" y="296.82" width="0.5" height="64.44" />
              <rect x="689.3" y="296.82" width="0.5" height="64.44" />
              <rect x="713.33" y="296.82" width="0.5" height="64.44" />
              <rect x="737.51" y="296.82" width="0.5" height="64.44" />
              <rect x="761.56" y="328.97" width="0.5" height="32.27" />
              <polygon points="783.2 328.7 783.36 329.2 568.87 329.2 568.87 328.7 783.2 328.7" />
            </g>
            <g id="B.I1">
              <path
                d="M741.79,277.7H569.12v-95C671.46,200.7,741.79,277.7,741.79,277.7Z"
                style={{
                  fill: "#e8f5fd",
                  stroke: "#000",
                  strokeMiterlimit: 10,
                  strokeWidth: 0.5,
                }}
              />
              <path
                d="M747.94,281.2H570.38a4.87,4.87,0,0,1-4.88-4.88V180.25a4.89,4.89,0,0,1,5.84-4.79c90.17,17.79,157.31,75.57,180,97.34A4.88,4.88,0,0,1,747.94,281.2Z"
                style={{
                  fill: "none",
                  stroke: "#000",
                  strokeMiterlimit: 10,
                  strokeWidth: 0.5,
                }}
              />
              <rect x="592.79" y="187.98" width="0.5" height="33.59" />
              <rect x="568.91" y="221.08" width="48.07" height="0.5" />
              <rect x="616.86" y="195.77" width="0.5" height="81.77" />
              <rect x="592.79" y="245.52" width="0.5" height="32.04" />
              <rect x="569.03" y="245.19" width="120.42" height="0.5" />
              <polygon points="641.04 277.57 641.54 276.93 641.54 206.05 641.04 206.05 641.04 277.57" />
              <polygon points="689.21 277.57 689.71 277.57 689.71 234.3 689.21 234.01 689.21 277.57" />
              <polygon points="665.12 277.57 665.62 277.57 665.62 245.64 665.12 245.43 665.12 277.57" />
            </g>
            <polygon
              points="836.07 480.42 813.33 480.42 788.48 480.42 763.63 480.42 738.78 480.42 713.93 480.42 689.08 480.42 664.23 480.42 639.37 480.42 614.52 480.42 589.67 480.42 589.67 512.12 614.52 512.12 639.37 512.12 664.23 512.12 689.08 512.12 713.93 512.12 738.78 512.12 763.63 512.12 788.48 512.12 813.33 512.12 837.93 512.12 837.45 495.32 836.07 480.42"
              style={{
                fill: "#e8f5fd",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <polygon
              points="533.1 177.73 533.1 200.46 533.1 225.31 533.1 250.16 533.1 275.02 533.1 299.87 533.1 324.72 533.1 349.57 533.1 374.42 533.1 399.27 533.1 424.13 501.4 424.13 501.4 399.27 501.4 374.42 501.4 349.57 501.4 324.72 501.4 299.87 501.4 275.02 501.4 250.16 501.4 225.31 501.4 200.46 501.4 175.86 518.19 176.35 533.1 177.73"
              style={{
                fill: "#e8f5fd",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <line
              x1="566.89"
              y1="288.5"
              x2="751.44"
              y2="288.5"
              style={{
                fill: "#fff",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <line
              x1="566.76"
              y1="371.04"
              x2="808.01"
              y2="371.04"
              style={{
                fill: "#fff",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <path
              d="M755,217.55v-1.13c3.09-.33,3.4-.5,3.4-3.73v-15c0-3.27-.32-3.45-3.4-3.77v-1.13h10.5v1.13c-3.11.33-3.43.5-3.43,3.77v15c0,3.26.31,3.39,3.43,3.73v1.13Z"
              style={{ fill: "#7aafdf" }}
            />
          </g>
          <g id="K-Group">
            <g id="B.K3">
              <path
                d="M168.9,576.41h264a3.7,3.7,0,0,1,3.7,3.7v64a3.7,3.7,0,0,1-3.7,3.7H190a10.21,10.21,0,0,1-9.66-6.87c-2.89-8.3-7.58-21.88-9.36-27.76-1.84-6.07-5.59-20.4-7.89-29.29A6,6,0,0,1,168.9,576.41Z"
                style={{
                  fill: "none",
                  stroke: "#000",
                  strokeMiterlimit: 10,
                  strokeWidth: 0.5,
                }}
              />
              <path
                d="M172.64,580.07H434.18v64.82h-242s-9.51-23.32-11.91-31.25C177.8,605.36,172.64,580.07,172.64,580.07Z"
                style={{
                  fill: "#eff7ed",
                  stroke: "#000",
                  strokeMiterlimit: 10,
                  strokeWidth: 0.5,
                }}
              />
              <rect x="408.97" y="580.1" width="0.5" height="64.44" />
              <rect x="384.84" y="580.1" width="0.5" height="64.44" />
              <rect x="360.78" y="580.04" width="0.5" height="64.44" />
              <rect x="336.65" y="580.04" width="0.5" height="64.44" />
              <rect x="312.47" y="580.04" width="0.5" height="64.44" />
              <rect x="288.37" y="580.04" width="0.5" height="64.44" />
              <rect x="264.33" y="580.01" width="0.5" height="64.44" />
              <rect x="240.25" y="580.01" width="0.5" height="64.44" />
              <rect x="216.07" y="580.01" width="0.5" height="64.44" />
              <rect x="179.77" y="611.98" width="254.41" height="0.5" />
            </g>
            <g id="B.K2">
              <path
                d="M195.18,659.9H433.9A3.12,3.12,0,0,1,437,663v64.39a3.12,3.12,0,0,1-3.11,3.12H241.05a11.6,11.6,0,0,1-9.79-5.38s-14.78-19.21-19.16-26c-5.39-8.34-19.45-34.65-19.45-34.65A3,3,0,0,1,195.18,659.9Z"
                style={{
                  fill: "none",
                  stroke: "#000",
                  strokeMiterlimit: 10,
                  strokeWidth: 0.5,
                }}
              />
              <path
                d="M201.08,662.78H433.8v64.5H243.22a303,303,0,0,1-22.83-30.5C211,682.22,201.08,662.78,201.08,662.78Z"
                style={{
                  fill: "#eff7ed",
                  stroke: "#000",
                  strokeMiterlimit: 10,
                  strokeWidth: 0.5,
                }}
              />
              <rect x="409.39" y="662.81" width="0.5" height="64.44" />
              <rect x="385.22" y="662.81" width="0.5" height="64.44" />
              <rect x="361.11" y="662.81" width="0.5" height="64.44" />
              <rect x="337.04" y="662.81" width="0.5" height="64.44" />
              <rect x="312.88" y="662.8" width="0.5" height="64.44" />
              <rect x="288.85" y="662.81" width="0.5" height="64.44" />
              <rect x="264.66" y="662.81" width="0.5" height="64.44" />
              <rect x="240.62" y="662.81" width="0.5" height="32.27" />
              <polygon points="219.48 695.36 219.32 694.86 433.8 694.86 433.8 695.36 219.48 695.36" />
            </g>
            <g id="B.K1">
              <path
                d="M260.75,747.06H433.41v95C331.08,824.06,260.75,747.06,260.75,747.06Z"
                style={{
                  fill: "#eff7ed",
                  stroke: "#000",
                  strokeMiterlimit: 10,
                  strokeWidth: 0.5,
                }}
              />
              <path
                d="M254.6,743.56H432.16a4.89,4.89,0,0,1,4.88,4.88v96.07a4.9,4.9,0,0,1-5.84,4.8C341,831.52,273.89,773.74,251.21,752A4.89,4.89,0,0,1,254.6,743.56Z"
                style={{
                  fill: "none",
                  stroke: "#000",
                  strokeMiterlimit: 10,
                  strokeWidth: 0.5,
                }}
              />
              <rect x="409.25" y="803.19" width="0.5" height="33.59" />
              <rect x="385.56" y="803.19" width="48.07" height="0.5" />
              <rect x="385.18" y="747.23" width="0.5" height="81.77" />
              <rect x="409.25" y="747.21" width="0.5" height="32.04" />
              <rect x="313.08" y="779.07" width="120.42" height="0.5" />
              <polygon points="361.5 747.2 361 747.83 361 818.72 361.5 818.72 361.5 747.2" />
              <polygon points="313.33 747.2 312.83 747.2 312.83 790.47 313.33 790.75 313.33 747.2" />
              <polygon points="337.41 747.2 336.91 747.2 336.91 779.13 337.41 779.34 337.41 747.2" />
            </g>
            <polygon
              points="166.68 544.43 189.41 544.43 214.26 544.43 239.12 544.43 263.97 544.43 288.82 544.43 313.67 544.43 338.52 544.43 363.37 544.43 388.22 544.43 413.08 544.43 413.08 512.73 388.22 512.73 363.37 512.73 338.52 512.73 313.67 512.73 288.82 512.73 263.97 512.73 239.12 512.73 214.26 512.73 189.41 512.73 164.81 512.73 165.3 529.52 166.68 544.43"
              style={{
                fill: "#eff7ed",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <polygon
              points="469.8 847.12 469.8 824.38 469.8 799.53 469.8 774.68 469.8 749.83 469.8 724.98 469.8 700.13 469.8 675.27 469.8 650.42 469.8 625.57 469.8 600.72 501.5 600.72 501.5 625.57 501.5 650.42 501.5 675.27 501.5 700.13 501.5 724.98 501.5 749.83 501.5 774.68 501.5 799.53 501.5 824.38 501.5 848.98 484.7 848.5 469.8 847.12"
              style={{
                fill: "#eff7ed",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <line
              x1="195.76"
              y1="653.7"
              x2="437.01"
              y2="653.7"
              style={{
                fill: "#fff",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <line
              x1="252.46"
              y1="737.04"
              x2="437.01"
              y2="737.04"
              style={{
                fill: "#fff",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <path
              d="M228.5,806.2h10.42v1.12c-2.93.29-3.44.54-3.44,3.89v6.53a5.73,5.73,0,0,0,2.79-1.8c2.21-2.12,4.61-4.61,6.25-6.49,1.1-1.28,1-1.79-.69-2l-1-.11V806.2h9.39v1.12c-2.82.35-3.88.54-6.41,3.16-1.2,1.19-4,3.9-6.28,6.2,2.81,3.42,6.81,8.15,9,10.53,1.84,2,2.34,2.35,4.63,2.62V831h-6.37c-2.69-3.11-5.83-7-9.16-11-.86-1.08-1.5-1.19-2.11-1.13V826c0,3.3.33,3.54,3.45,3.82V831H228.5v-1.13c3.08-.27,3.4-.52,3.4-3.82v-14.8c0-3.38-.29-3.65-3.4-3.89Z"
              style={{ fill: "#afd9ab" }}
            />
          </g>
          <g id="J-Group">
            <polygon
              points="836.07 544.15 813.33 544.15 788.48 544.15 763.63 544.15 738.78 544.15 713.93 544.15 689.08 544.15 664.23 544.15 639.37 544.15 614.52 544.15 589.67 544.15 589.67 512.45 614.52 512.45 639.37 512.45 664.23 512.45 689.08 512.45 713.93 512.45 738.78 512.45 763.63 512.45 788.48 512.45 813.33 512.45 837.93 512.45 837.45 529.24 836.07 544.15"
              style={{
                fill: "#fde7e8",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <polygon
              points="533.38 847.12 533.38 824.38 533.38 799.53 533.38 774.68 533.38 749.83 533.38 724.98 533.38 700.13 533.38 675.27 533.38 650.42 533.38 625.57 533.38 600.72 501.68 600.72 501.68 625.57 501.68 650.42 501.68 675.27 501.68 700.13 501.68 724.98 501.68 749.83 501.68 774.68 501.68 799.53 501.68 824.38 501.68 848.98 518.47 848.5 533.38 847.12"
              style={{
                fill: "#fde7e8",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <g id="B.J3">
              <path
                d="M833.36,577.43h-264a3.71,3.71,0,0,0-3.71,3.71v64a3.71,3.71,0,0,0,3.71,3.7H812.28a10.23,10.23,0,0,0,9.66-6.86c2.89-8.3,7.57-21.89,9.35-27.76,1.84-6.08,5.6-20.41,7.9-29.29A6,6,0,0,0,833.36,577.43Z"
                style={{
                  fill: "none",
                  stroke: "#000",
                  strokeMiterlimit: 10,
                  strokeWidth: 0.5,
                }}
              />
              <path
                d="M830.12,580.75H568.58v64.81h242s9.51-23.31,11.92-31.25C825,606,830.12,580.75,830.12,580.75Z"
                style={{
                  fill: "#fde7e8",
                  stroke: "#000",
                  strokeMiterlimit: 10,
                  strokeWidth: 0.5,
                }}
              />
              <rect x="592.79" y="581.12" width="0.5" height="64.44" />
              <rect x="616.92" y="581.12" width="0.5" height="64.44" />
              <rect x="640.98" y="581.06" width="0.5" height="64.44" />
              <rect x="665.12" y="581.06" width="0.5" height="64.44" />
              <rect x="689.29" y="581.06" width="0.5" height="64.44" />
              <rect x="713.4" y="581.06" width="0.5" height="64.44" />
              <rect x="737.43" y="581.03" width="0.5" height="64.44" />
              <rect x="761.51" y="581.03" width="0.5" height="64.44" />
              <rect x="785.7" y="581.03" width="0.5" height="64.44" />
              <rect x="568.85" y="613" width="253.65" height="0.5" />
            </g>
            <g id="B.J2">
              <path
                d="M807.5,659.9H568.78a3.12,3.12,0,0,0-3.12,3.12v64.39a3.12,3.12,0,0,0,3.12,3.12H761.63a11.6,11.6,0,0,0,9.79-5.38s14.78-19.21,19.16-26C796,690.81,810,664.5,810,664.5A3,3,0,0,0,807.5,659.9Z"
                style={{
                  fill: "none",
                  stroke: "#000",
                  strokeMiterlimit: 10,
                  strokeWidth: 0.5,
                }}
              />
              <path
                d="M801.6,662.78H568.87v64.5H759.46a303,303,0,0,0,22.83-30.5C791.68,682.22,801.6,662.78,801.6,662.78Z"
                style={{
                  fill: "#fde7e8",
                  stroke: "#000",
                  strokeMiterlimit: 10,
                  strokeWidth: 0.5,
                }}
              />
              <rect x="592.79" y="662.81" width="0.5" height="64.44" />
              <rect x="616.96" y="662.81" width="0.5" height="64.44" />
              <rect x="641.06" y="662.81" width="0.5" height="64.44" />
              <rect x="665.14" y="662.81" width="0.5" height="64.44" />
              <rect x="689.3" y="662.8" width="0.5" height="64.44" />
              <rect x="713.33" y="662.81" width="0.5" height="64.44" />
              <rect x="737.51" y="662.81" width="0.5" height="64.44" />
              <rect x="761.56" y="662.81" width="0.5" height="32.27" />
              <polygon points="783.2 695.36 783.36 694.86 568.87 694.86 568.87 695.36 783.2 695.36" />
            </g>
            <g id="B.J1">
              <path
                d="M741.79,747.06H569.12v95C671.46,824.06,741.79,747.06,741.79,747.06Z"
                style={{
                  fill: "#fde7e8",
                  stroke: "#000",
                  strokeMiterlimit: 10,
                  strokeWidth: 0.5,
                }}
              />
              <path
                d="M747.94,743.56H570.38a4.88,4.88,0,0,0-4.88,4.88v96.07a4.9,4.9,0,0,0,5.84,4.8c90.17-17.79,157.31-75.57,180-97.34A4.88,4.88,0,0,0,747.94,743.56Z"
                style={{
                  fill: "none",
                  stroke: "#000",
                  strokeMiterlimit: 10,
                  strokeWidth: 0.5,
                }}
              />
              <rect x="592.79" y="803.19" width="0.5" height="33.59" />
              <rect x="568.91" y="803.19" width="48.07" height="0.5" />
              <rect x="616.86" y="747.23" width="0.5" height="81.77" />
              <rect x="592.79" y="747.21" width="0.5" height="32.04" />
              <rect x="569.03" y="779.07" width="120.42" height="0.5" />
              <polygon points="641.04 747.2 641.54 747.83 641.54 818.72 641.04 818.72 641.04 747.2" />
              <polygon points="689.21 747.2 689.71 747.2 689.71 790.47 689.21 790.75 689.21 747.2" />
              <polygon points="665.12 747.2 665.62 747.2 665.62 779.13 665.12 779.34 665.12 747.2" />
            </g>
            <line
              x1="566.89"
              y1="737.04"
              x2="751.44"
              y2="737.04"
              style={{
                fill: "#fff",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <line
              x1="566.76"
              y1="653.7"
              x2="808.01"
              y2="653.7"
              style={{
                fill: "#fff",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <path
              d="M765.3,800.74c-2.92.34-3.23.57-3.23,3.77v13.82c0,4.92-.73,7.74-3.48,10.13a12.36,12.36,0,0,1-3.75,2.23l-.61-1.2a6.53,6.53,0,0,0,3.78-5,42.59,42.59,0,0,0,.39-7.25V804.51c0-3.2-.35-3.47-3.44-3.77v-1.13H765.3Z"
              style={{ fill: "#f37072" }}
            />
          </g>
          <g id="_1" data-name="1">
            <rect
              x="469.58"
              y="424.13"
              width="63.75"
              height="2.71"
              style={{ fill: "#139b48" }}
            />
            <rect x="501.16" y="175.69" width="0.5" height="248.57" />
            <path
              d="M533.31,454.72H469.79A3.77,3.77,0,0,1,466,451V176.68a7.42,7.42,0,0,1,6.88-7.41c8.46-.61,22.43-1.55,28.51-1.55s20.23.95,28.78,1.56a7.42,7.42,0,0,1,6.89,7.41V451A3.77,3.77,0,0,1,533.31,454.72Z"
              style={{
                fill: "none",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <path
              d="M533.33,446.37H469.58V177.87A235.44,235.44,0,0,1,501.41,176a271,271,0,0,1,31.92,1.87Z"
              style={{
                fill: "none",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <rect x="469.58" y="206.6" width="63.75" height="0.5" />
            <rect x="469.58" y="230.79" width="63.75" height="0.5" />
            <rect x="469.58" y="254.87" width="63.75" height="0.5" />
            <rect x="469.58" y="278.96" width="63.75" height="0.5" />
            <rect x="469.58" y="303.11" width="63.75" height="0.5" />
            <rect x="469.58" y="327.16" width="63.75" height="0.5" />
            <rect x="469.58" y="351.33" width="63.75" height="0.5" />
            <rect x="469.58" y="375.4" width="63.75" height="0.5" />
            <rect x="469.58" y="399.51" width="63.75" height="0.5" />
            <rect x="469.58" y="423.76" width="63.75" height="0.5" />
            <rect x="469.58" y="426.72" width="63.75" height="0.5" />
          </g>
          <g id="_2" data-name="2">
            <rect
              x="469.77"
              y="597.93"
              width="63.75"
              height="2.71"
              transform="translate(1003.29 1198.57) rotate(180)"
              style={{ fill: "#139b48" }}
            />
            <rect x="501.44" y="600.51" width="0.5" height="248.57" />
            <path
              d="M469.79,570.05h63.52a3.77,3.77,0,0,1,3.77,3.77V848.09a7.43,7.43,0,0,1-6.89,7.41c-8.45.61-22.42,1.55-28.5,1.55s-20.23-.95-28.78-1.56a7.42,7.42,0,0,1-6.89-7.41V573.82A3.77,3.77,0,0,1,469.79,570.05Z"
              style={{
                fill: "none",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <path
              d="M469.77,578.4h63.75V846.9a235.44,235.44,0,0,1-31.83,1.87,271,271,0,0,1-31.92-1.87Z"
              style={{
                fill: "none",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <rect x="469.77" y="817.67" width="63.75" height="0.5" />
            <rect x="469.77" y="793.48" width="63.75" height="0.5" />
            <rect x="469.77" y="769.4" width="63.75" height="0.5" />
            <rect x="469.77" y="745.31" width="63.75" height="0.5" />
            <rect x="469.77" y="721.16" width="63.75" height="0.5" />
            <rect x="469.77" y="697.11" width="63.75" height="0.5" />
            <rect x="469.77" y="672.94" width="63.75" height="0.5" />
            <rect x="469.77" y="648.87" width="63.75" height="0.5" />
            <rect x="469.77" y="624.76" width="63.75" height="0.5" />
            <rect x="469.77" y="600.51" width="63.75" height="0.5" />
            <rect x="469.77" y="597.55" width="63.75" height="0.5" />
          </g>
          <g id="_1-2" data-name="1">
            <rect
              x="382.78"
              y="511.12"
              width="63.75"
              height="2.71"
              transform="translate(-97.83 927.13) rotate(-90)"
              style={{ fill: "#139b48" }}
            />
            <rect x="164.86" y="512.27" width="248.57" height="0.5" />
            <path
              d="M443.89,480.62v63.53a3.77,3.77,0,0,1-3.77,3.77H165.84a7.42,7.42,0,0,1-7.4-6.89c-.62-8.46-1.55-22.43-1.55-28.51s.94-20.22,1.55-28.77a7.43,7.43,0,0,1,7.41-6.9H440.12A3.77,3.77,0,0,1,443.89,480.62Z"
              style={{
                fill: "none",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <path
              d="M435.53,480.6v63.75H167a236.5,236.5,0,0,1-1.86-31.83A272.64,272.64,0,0,1,167,480.6Z"
              style={{
                fill: "none",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <rect x="195.76" y="480.6" width="0.5" height="63.75" />
            <rect x="219.95" y="480.6" width="0.5" height="63.75" />
            <rect x="244.03" y="480.6" width="0.5" height="63.75" />
            <rect x="268.12" y="480.6" width="0.5" height="63.75" />
            <rect x="292.27" y="480.6" width="0.5" height="63.75" />
            <rect x="316.33" y="480.6" width="0.5" height="63.75" />
            <rect x="340.5" y="480.6" width="0.5" height="63.75" />
            <rect x="364.56" y="480.6" width="0.5" height="63.75" />
            <rect x="388.67" y="480.6" width="0.5" height="63.75" />
            <rect x="412.93" y="480.6" width="0.5" height="63.75" />
            <rect x="415.89" y="480.6" width="0.5" height="63.75" />
          </g>
          <g id="_2-2" data-name="2">
            <rect
              x="556.57"
              y="510.94"
              width="63.75"
              height="2.71"
              transform="translate(1100.74 -76.16) rotate(90)"
              style={{ fill: "#139b48" }}
            />
            <rect x="589.67" y="512" width="248.57" height="0.5" />
            <path
              d="M559.21,544.15V480.62a3.77,3.77,0,0,1,3.77-3.77H837.25a7.43,7.43,0,0,1,7.41,6.89c.61,8.46,1.55,22.43,1.55,28.51s-.94,20.22-1.55,28.77a7.43,7.43,0,0,1-7.41,6.9H563A3.77,3.77,0,0,1,559.21,544.15Z"
              style={{
                fill: "none",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <path
              d="M567.57,544.17V480.42h268.5a236.5,236.5,0,0,1,1.86,31.83,272.64,272.64,0,0,1-1.86,31.92Z"
              style={{
                fill: "none",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <rect x="806.84" y="480.42" width="0.5" height="63.75" />
            <rect x="782.65" y="480.42" width="0.5" height="63.75" />
            <rect x="758.57" y="480.42" width="0.5" height="63.75" />
            <rect x="734.48" y="480.42" width="0.5" height="63.75" />
            <rect x="710.33" y="480.42" width="0.5" height="63.75" />
            <rect x="686.27" y="480.42" width="0.5" height="63.75" />
            <rect x="662.1" y="480.42" width="0.5" height="63.75" />
            <rect x="638.04" y="480.42" width="0.5" height="63.75" />
            <rect x="613.93" y="480.42" width="0.5" height="63.75" />
            <rect x="589.67" y="480.42" width="0.5" height="63.75" />
            <rect x="589.3" y="480.42" width="0.5" height="63.75" />
            <rect x="586.71" y="480.42" width="0.5" height="63.75" />
          </g>
          <path
            d="M564.54,875.46a4,4,0,0,0-4.59-3.15A366.3,366.3,0,0,1,501.46,877c-201.27,0-365-163.74-365-365,0-170.86,118-314.68,276.81-354.22a4,4,0,0,0,3-4.71v0a4,4,0,0,0-4.89-3.1,373.2,373.2,0,0,0-55.09,705.75A370.63,370.63,0,0,0,501.46,885a376,376,0,0,0,59.8-4.75,4,4,0,0,0,3.28-4.79Z"
            style={{ fill: "#129b48", stroke: "#000", strokeMiterlimit: 10 }}
          />
          <path
            d="M845.14,657.19C827.31,702,799.46,741.5,765.21,775.75A372.33,372.33,0,0,1,595.7,873a4,4,0,0,1-4.94-3.09v0a4,4,0,0,1,2.94-4.7c156.75-41,272.77-183.78,272.77-353.19,0-201.26-163.74-365-365-365a365.05,365.05,0,0,0-54.36,4.05,4,4,0,0,1-4.55-3.17h0a4,4,0,0,1,3.32-4.78,376,376,0,0,1,55.59-4.1,372.71,372.71,0,0,1,373,373S879.83,570,845.14,657.19Z"
            style={{ fill: "#129b48", stroke: "#000", strokeMiterlimit: 10 }}
          />
          <rect
            x="469.4"
            y="479.7"
            width="63.77"
            height="64.9"
            rx="6.91"
            style={{
              fill: "none",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="466.29"
            y="476.53"
            width="70"
            height="71.24"
            rx="7.58"
            style={{
              fill: "none",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <path d="M501.43,507.82a4.34,4.34,0,1,0,4.33,4.33A4.33,4.33,0,0,0,501.43,507.82Zm3.8,4H501.8v-3.48A3.8,3.8,0,0,1,505.23,511.84Zm-4.12-3.49v3.49h-3.48A3.82,3.82,0,0,1,501.11,508.35Zm-3.47,4.18h3.47V516A3.79,3.79,0,0,1,497.64,512.53ZM501.8,516v-3.42h3.43A3.83,3.83,0,0,1,501.8,516Z" />
          <text
            transform="translate(499.17 514.03)"
            style={{
              fontSize: "8.251720428466797px",
              fill: "#9b1b1e",
              fontFamily: "AdobeArabic-Regular, Adobe Arabic",
            }}
          >
            C
          </text>
          <rect
            x="449.62"
            y="178.41"
            width="3.88"
            height="142.08"
            rx="1.94"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="549.31"
            y="178.41"
            width="3.88"
            height="142.08"
            rx="1.94"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="449.62"
            y="335.84"
            width="3.88"
            height="106.26"
            rx="1.94"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="549.31"
            y="335.84"
            width="3.88"
            height="106.36"
            rx="1.94"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="449.62"
            y="704.24"
            width="3.88"
            height="142.08"
            rx="1.94"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="549.31"
            y="704.24"
            width="3.88"
            height="142.08"
            rx="1.94"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="449.62"
            y="582.64"
            width="3.88"
            height="106.26"
            rx="1.94"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="549.31"
            y="582.54"
            width="3.88"
            height="106.36"
            rx="1.94"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="236.55"
            y="491.17"
            width="3.88"
            height="142.08"
            rx="1.94"
            transform="translate(-323.73 800.7) rotate(-90)"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="236.55"
            y="391.48"
            width="3.88"
            height="142.08"
            rx="1.94"
            transform="translate(-224.04 701.01) rotate(-90)"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="376.07"
            y="509.08"
            width="3.88"
            height="106.26"
            rx="1.94"
            transform="translate(-184.21 940.22) rotate(-90)"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="376.12"
            y="409.34"
            width="3.88"
            height="106.36"
            rx="1.94"
            transform="translate(-84.47 840.58) rotate(-90)"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="762.38"
            y="491.17"
            width="3.88"
            height="142.08"
            rx="1.94"
            transform="translate(202.11 1326.53) rotate(-90)"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="762.38"
            y="391.48"
            width="3.88"
            height="142.08"
            rx="1.94"
            transform="translate(301.79 1226.85) rotate(-90)"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="622.87"
            y="509.08"
            width="3.88"
            height="106.26"
            rx="1.94"
            transform="translate(62.59 1187.02) rotate(-90)"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="622.82"
            y="409.34"
            width="3.88"
            height="106.36"
            rx="1.94"
            transform="translate(162.23 1087.28) rotate(-90)"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <path
            d="M529.38,524.07c-1.54.18-1.71.3-1.71,2v7.31c0,2.6-.38,4.09-1.83,5.35a6.47,6.47,0,0,1-2,1.18l-.32-.63a3.48,3.48,0,0,0,2-2.63,22.56,22.56,0,0,0,.2-3.83v-6.75c0-1.69-.18-1.83-1.81-2v-.6h5.46Z"
            style={{ fill: "#f37072" }}
          />
          <path
            d="M523.94,496.27v-.6c1.63-.17,1.79-.26,1.79-2v-7.94c0-1.72-.16-1.82-1.79-2v-.59h5.55v.59c-1.65.18-1.82.27-1.82,2v7.94c0,1.72.17,1.79,1.82,2v.6Z"
            style={{ fill: "#7aafdf" }}
          />
          <path
            d="M486.54,483.77c-1.66.16-1.81.27-1.81,2v8c0,1.69.17,1.76,1.81,1.93v.6H481v-.6c1.66-.2,1.81-.24,1.81-1.93v-4h-6.62v4c0,1.67.16,1.74,1.79,1.93v.6h-5.53v-.6c1.61-.18,1.79-.24,1.79-1.93v-8c0-1.69-.18-1.8-1.79-2v-.59H478v.59c-1.6.14-1.79.28-1.79,2v3.14h6.62v-3.14c0-1.68-.22-1.81-1.81-2v-.59h5.55Z"
            style={{ fill: "#f9d730" }}
          />
          <path
            d="M472.44,526.84H478v.6c-1.55.15-1.82.28-1.82,2.06V533a3.1,3.1,0,0,0,1.47-1c1.17-1.11,2.44-2.43,3.31-3.43.58-.67.51-.94-.37-1.06l-.53-.06v-.6h5v.6a4.33,4.33,0,0,0-3.39,1.67c-.63.63-2.1,2.06-3.31,3.28,1.48,1.8,3.59,4.3,4.73,5.56a3,3,0,0,0,2.45,1.39v.59h-3.36c-1.43-1.64-3.08-3.68-4.85-5.8-.45-.57-.79-.62-1.11-.59v3.77c0,1.75.17,1.88,1.82,2v.59h-5.51v-.59c1.63-.15,1.79-.28,1.79-2V529.5c0-1.8-.15-1.94-1.79-2.06Z"
            style={{ fill: "#afd9aa" }}
          />
          <rect
            x="449.62"
            y="178.41"
            width="3.88"
            height="142.08"
            rx="1.94"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="549.31"
            y="178.41"
            width="3.88"
            height="142.08"
            rx="1.94"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="449.62"
            y="335.84"
            width="3.88"
            height="106.26"
            rx="1.94"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="549.31"
            y="335.84"
            width="3.88"
            height="106.36"
            rx="1.94"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="449.62"
            y="704.24"
            width="3.88"
            height="142.08"
            rx="1.94"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="549.31"
            y="704.24"
            width="3.88"
            height="142.08"
            rx="1.94"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="449.62"
            y="582.64"
            width="3.88"
            height="106.26"
            rx="1.94"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="549.31"
            y="582.54"
            width="3.88"
            height="106.36"
            rx="1.94"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="236.55"
            y="491.17"
            width="3.88"
            height="142.08"
            rx="1.94"
            transform="translate(-323.73 800.7) rotate(-90)"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="236.55"
            y="391.48"
            width="3.88"
            height="142.08"
            rx="1.94"
            transform="translate(-224.04 701.01) rotate(-90)"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="376.07"
            y="509.08"
            width="3.88"
            height="106.26"
            rx="1.94"
            transform="translate(-184.21 940.22) rotate(-90)"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="376.12"
            y="409.34"
            width="3.88"
            height="106.36"
            rx="1.94"
            transform="translate(-84.47 840.58) rotate(-90)"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="762.38"
            y="491.17"
            width="3.88"
            height="142.08"
            rx="1.94"
            transform="translate(202.11 1326.53) rotate(-90)"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="762.38"
            y="391.48"
            width="3.88"
            height="142.08"
            rx="1.94"
            transform="translate(301.79 1226.85) rotate(-90)"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="622.87"
            y="509.08"
            width="3.88"
            height="106.26"
            rx="1.94"
            transform="translate(62.59 1187.02) rotate(-90)"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="622.82"
            y="409.34"
            width="3.88"
            height="106.36"
            rx="1.94"
            transform="translate(162.23 1087.28) rotate(-90)"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <text
            transform="translate(798.02 711.88) rotate(-57.9) scale(1 1.15)"
            style={{
              fontSize: "12.755020141601562px",
              fill: "#f27172",
              fontFamily: "MyriadPro-Regular, Myriad Pro",
            }}
          >
            <tspan style={{ letterSpacing: "-0.01297742707674124em" }}>B</tspan>
            <tspan x="6.75" y="0">
              .J2
            </tspan>
          </text>
          <text
            transform="matrix(0.85, -0.52, 0.6, 0.98, 668.35, 828.95)"
            style={{
              fontSize: "11.95242977142334px",
              fill: "#f27172",
              fontFamily: "MyriadPro-Regular, Myriad Pro",
            }}
          >
            <tspan style={{ letterSpacing: "-0.012970525645392562em" }}>
              B
            </tspan>
            <tspan x="6.32" y="0">
              .J1
            </tspan>
          </text>
          <text
            transform="translate(842.94 625.15) rotate(-73.92) scale(1 1.15)"
            style={{
              fontSize: "12.755020141601562px",
              fill: "#f27172",
              fontFamily: "MyriadPro-Regular, Myriad Pro",
            }}
          >
            <tspan style={{ letterSpacing: "-0.01297742707674124em" }}>B</tspan>
            <tspan x="6.75" y="0">
              .J3
            </tspan>
          </text>
          <path
            d="M548.92,856.69c-.3-1.14-.79-2.3-2.42-2.3a1.72,1.72,0,0,0-1.81,1.86c0,1.16.75,1.77,2.21,2.52,2.06,1.05,3,1.87,3,3.54,0,1.87-1.61,3.29-3.9,3.29a7.83,7.83,0,0,1-2.72-.57c-.13-.47-.35-1.77-.48-2.69l.57-.14c.35,1.15,1.15,2.74,3,2.74a1.86,1.86,0,0,0,2-2c0-1.28-.7-1.83-2.22-2.66-1.82-1-2.88-1.83-2.88-3.49s1.31-3.06,3.67-3.06a12.12,12.12,0,0,1,2.34.32c.07.64.15,1.37.29,2.5Z"
            style={{ fill: "#ed1f24" }}
          />
          <path
            d="M552.3,865.34v-.52c1.91-.12,2-.22,2-1.52v-6.63c0-.89-.16-.93-.92-1l-.79-.09v-.46a15.5,15.5,0,0,0,3.31-.86v9c0,1.32.11,1.4,2,1.52v.52Z"
            style={{ fill: "#ed1f24" }}
          />
          <path
            d="M768.15,736.1c-.3-1.14-.79-2.3-2.43-2.3a1.72,1.72,0,0,0-1.8,1.86c0,1.16.75,1.77,2.21,2.52,2,1.05,3,1.87,3,3.54,0,1.88-1.61,3.29-3.9,3.29a7.78,7.78,0,0,1-2.72-.57c-.13-.47-.36-1.77-.48-2.69l.57-.14c.35,1.15,1.15,2.74,2.95,2.74a1.86,1.86,0,0,0,2-2c0-1.28-.7-1.83-2.22-2.66-1.83-1-2.88-1.83-2.88-3.49s1.31-3.06,3.67-3.06a11.19,11.19,0,0,1,2.34.33c.07.63.15,1.36.28,2.49Z"
            style={{ fill: "#ed1f24" }}
          />
          <path
            d="M775.94,739.74l-1.12.57a2.15,2.15,0,0,1-1,.27,3.12,3.12,0,0,1-3.18-3.27,3.64,3.64,0,0,1,3.66-3.64c2.28,0,3.57,2,3.57,4.38a6.91,6.91,0,0,1-4.45,6.49,8.37,8.37,0,0,1-2.16.47l-.14-.53A5.86,5.86,0,0,0,775.94,739.74ZM774,734.31c-1.28,0-1.74,1.21-1.74,2.47,0,1.54.85,2.8,2.28,2.8a2.56,2.56,0,0,0,1.46-.45,9.72,9.72,0,0,0,.07-1.16C776.09,736.4,775.65,734.3,774,734.31Z"
            style={{ fill: "#ed1f24" }}
          />
          <path
            d="M816.85,652.63c-.27-1-.7-2-2.12-2a1.49,1.49,0,0,0-1.56,1.62c0,1,.64,1.53,1.92,2.19,1.78.92,2.61,1.63,2.61,3.08,0,1.63-1.4,2.86-3.39,2.86a6.65,6.65,0,0,1-2.36-.5,23.59,23.59,0,0,1-.42-2.34l.49-.12c.31,1,1,2.38,2.57,2.38a1.61,1.61,0,0,0,1.71-1.7c0-1.12-.61-1.6-1.93-2.32-1.59-.86-2.51-1.59-2.51-3s1.14-2.67,3.19-2.67a9.63,9.63,0,0,1,2,.29c.06.54.13,1.18.25,2.16Z"
            style={{ fill: "#ed1f24" }}
          />
          <path
            d="M819.78,660.15v-.45c1.66-.1,1.76-.19,1.76-1.32v-5.76c0-.78-.14-.81-.8-.88l-.69-.07v-.4a13.88,13.88,0,0,0,2.88-.75v7.86c0,1.15.09,1.22,1.76,1.32v.45Z"
            style={{ fill: "#ed1f24" }}
          />
          <path
            d="M829.56,650.52c2.33,0,3.18,2.58,3.18,4.9s-.85,4.91-3.18,4.91-3.22-2.54-3.22-4.9S827.22,650.52,829.56,650.52Zm0,.55c-1.34,0-1.67,2.22-1.67,4.33s.4,4.38,1.68,4.38,1.64-2.25,1.64-4.37S830.85,651.07,829.56,651.07Z"
            style={{ fill: "#ed1f24" }}
          />
          <path
            d="M849.17,557.73c-.29-1.08-.75-2.18-2.3-2.18a1.63,1.63,0,0,0-1.71,1.77c0,1.09.71,1.67,2.09,2.38,2,1,2.85,1.77,2.85,3.35,0,1.78-1.52,3.12-3.69,3.12a7.47,7.47,0,0,1-2.57-.54c-.13-.45-.34-1.68-.46-2.55l.54-.14c.33,1.1,1.09,2.6,2.79,2.6a1.76,1.76,0,0,0,1.86-1.86c0-1.22-.66-1.74-2.1-2.52-1.73-.94-2.73-1.74-2.73-3.3S845,555,847.22,555a11.08,11.08,0,0,1,2.21.31c.06.6.14,1.3.27,2.37Z"
            style={{ fill: "#ed1f24" }}
          />
          <path
            d="M851.79,557.44l.79-1.05a2.91,2.91,0,0,1,2.17-1,2.29,2.29,0,0,1,2.53,2.22,1.73,1.73,0,0,1-.52,1.21,6.43,6.43,0,0,1-1.19.89c1.57.34,2.48,1.33,2.48,2.54,0,2.68-3.25,3.83-4.4,3.83a2.69,2.69,0,0,1-1.74-.58.72.72,0,0,1-.28-.57,1.06,1.06,0,0,1,.39-.66.37.37,0,0,1,.48,0,3.06,3.06,0,0,0,2.06.92c1.13,0,1.94-.85,1.94-2.44a2,2,0,0,0-2.16-2.11,4.56,4.56,0,0,0-1.07.16l-.12-.51c1.48-.48,2.61-1.12,2.61-2.32a1.44,1.44,0,0,0-1.55-1.54,2.59,2.59,0,0,0-2.05,1.3Z"
            style={{ fill: "#ed1f24" }}
          />
          <text
            transform="translate(193.65 693.92) rotate(56.31) scale(1 1.15)"
            style={{
              fontSize: "12.755020141601562px",
              fill: "#afd9aa",
              fontFamily: "MyriadPro-Regular, Myriad Pro",
            }}
          >
            <tspan style={{ letterSpacing: "-0.01297742707674124em" }}>B</tspan>
            <tspan x="6.75" y="0">
              .K2
            </tspan>
          </text>
          <text
            transform="translate(317.86 818.7) rotate(29.38) scale(1 1.15)"
            style={{
              fontSize: "11.95242977142334px",
              fill: "#afd9aa",
              fontFamily: "MyriadPro-Regular, Myriad Pro",
            }}
          >
            <tspan style={{ letterSpacing: "-0.012970525645392562em" }}>
              B
            </tspan>
            <tspan x="6.32" y="0">
              .K1
            </tspan>
          </text>
          <text
            transform="translate(473.04 868.78) rotate(1.22) scale(1 1.15)"
            style={{
              fontSize: "11.95242977142334px",
              fill: "#afd9aa",
              fontFamily: "MyriadPro-Regular, Myriad Pro",
            }}
          >
            <tspan style={{ letterSpacing: "-0.012970525645392562em" }}>
              B
            </tspan>
            <tspan x="6.32" y="0">
              .
            </tspan>
            <tspan
              x="8.8"
              y="0"
              style={{ letterSpacing: "0.038012831852087495em" }}
            >
              K
            </tspan>
            <tspan
              x="15.73"
              y="0"
              style={{ letterSpacing: "-0.000020426024638413485em" }}
            >
              J
            </tspan>
          </text>
          <text
            transform="translate(477.1 164.47) rotate(-0.56) scale(1 1.15)"
            style={{
              fontSize: "11.95242977142334px",
              fill: "#afd9aa",
              fontFamily: "MyriadPro-Regular, Myriad Pro",
            }}
          >
            <tspan style={{ letterSpacing: "-0.012970525645392562em" }}>
              B
            </tspan>
            <tspan x="6.32" y="0">
              .IH
            </tspan>
          </text>
          <text
            transform="matrix(0.03, -1, 1.15, 0.03, 858.77, 533.62)"
            style={{
              fontSize: "11.95242977142334px",
              fill: "#afd9aa",
              fontFamily: "MyriadPro-Regular, Myriad Pro",
            }}
          >
            <tspan style={{ letterSpacing: "-0.012970525645392562em" }}>
              B
            </tspan>
            <tspan x="6.32" y="0">
              .IJ
            </tspan>
          </text>
          <text
            transform="translate(154.29 538.09) rotate(-92.7) scale(1 1.15)"
            style={{
              fontSize: "11.95242977142334px",
              fill: "#afd9aa",
              fontFamily: "MyriadPro-Regular, Myriad Pro",
            }}
          >
            <tspan style={{ letterSpacing: "-0.012970525645392562em" }}>
              B
            </tspan>
            <tspan x="6.32" y="0">
              .KH
            </tspan>
          </text>
          <text
            transform="matrix(0.25, 0.97, -1.11, 0.29, 154.3, 604.56)"
            style={{
              fontSize: "12.755020141601562px",
              fill: "#afd9aa",
              fontFamily: "MyriadPro-Regular, Myriad Pro",
            }}
          >
            <tspan style={{ letterSpacing: "-0.01297742707674124em" }}>B</tspan>
            <tspan x="6.75" y="0">
              .K3
            </tspan>
          </text>
          <path
            d="M448.23,856.07c-.31-1.15-.8-2.31-2.43-2.31a1.72,1.72,0,0,0-1.8,1.87c0,1.16.74,1.76,2.2,2.51,2.06,1.06,3,1.88,3,3.55,0,1.87-1.61,3.28-3.9,3.28a7.5,7.5,0,0,1-2.71-.57,27.16,27.16,0,0,1-.49-2.69l.57-.14c.35,1.16,1.15,2.74,2.95,2.74a1.86,1.86,0,0,0,2-2c0-1.29-.7-1.84-2.23-2.66-1.82-1-2.88-1.84-2.88-3.49s1.31-3.07,3.67-3.07a11.19,11.19,0,0,1,2.34.33c.07.63.15,1.37.29,2.49Z"
            style={{ fill: "#ed1f24" }}
          />
          <path
            d="M458,862.29c-.24.81-.54,1.76-.71,2.42h-6.66v-.43c.95-.93,2-2,2.85-2.95,1.14-1.33,2.1-2.68,2.1-4.17s-.74-2.24-2-2.24a3,3,0,0,0-2.4,1.61l-.43-.38,1-1.46a3.2,3.2,0,0,1,2.44-1.05,2.89,2.89,0,0,1,3,3c0,1.46-.54,2.5-2.22,4.18-.74.76-1.81,1.8-2.54,2.48h3.26c1,0,1.22-.08,1.82-1.18Z"
            style={{ fill: "#ed1f24" }}
          />
          <path
            d="M234,735.37c.13-1.13.21-1.87.28-2.5a11.19,11.19,0,0,1,2.34-.33c2.36,0,3.67,1.46,3.67,3.07s-1.05,2.5-2.88,3.49c-1.52.83-2.22,1.38-2.22,2.66a1.85,1.85,0,0,0,2,2c1.8,0,2.6-1.58,2.95-2.74l.57.15c-.12.91-.35,2.21-.48,2.69a7.78,7.78,0,0,1-2.72.57c-2.29,0-3.9-1.42-3.9-3.29,0-1.67,1-2.49,3-3.54,1.46-.76,2.21-1.36,2.21-2.52a1.71,1.71,0,0,0-1.8-1.86c-1.64,0-2.13,1.15-2.43,2.3Z"
            style={{ fill: "#ed1f24" }}
          />
          <path
            d="M231.67,743.85l-.14.54a8.81,8.81,0,0,1-2.16-.47,6.93,6.93,0,0,1-4.45-6.5c0-2.37,1.29-4.37,3.57-4.37a3.64,3.64,0,0,1,3.66,3.64A3.12,3.12,0,0,1,229,740a2.27,2.27,0,0,1-1-.27l-1.12-.57A5.86,5.86,0,0,0,231.67,743.85Zm-5-6.5a9.44,9.44,0,0,0,.07,1.15,2.56,2.56,0,0,0,1.46.46c1.44,0,2.28-1.27,2.28-2.8,0-1.27-.46-2.48-1.74-2.48C227.1,733.68,226.66,735.77,226.66,737.35Z"
            style={{ fill: "#ed1f24" }}
          />
          <path
            d="M175.51,652c-.27-1-.69-2-2.11-2a1.5,1.5,0,0,0-1.57,1.63c0,1,.65,1.53,1.92,2.18,1.78.92,2.62,1.63,2.62,3.09,0,1.62-1.4,2.85-3.4,2.85a6.66,6.66,0,0,1-2.36-.49c-.11-.41-.31-1.54-.42-2.34l.49-.13c.31,1,1,2.39,2.57,2.39a1.62,1.62,0,0,0,1.71-1.71c0-1.12-.61-1.59-1.93-2.31-1.59-.87-2.51-1.6-2.51-3s1.14-2.66,3.19-2.66a10,10,0,0,1,2,.28c.06.55.13,1.19.25,2.17Z"
            style={{ fill: "#ed1f24" }}
          />
          <path
            d="M184.14,650.28c-1.46,3.07-2.89,6.14-4.34,9.27l-1.16.12-.11-.17a89.41,89.41,0,0,0,4.45-8.21h-3.24c-1,0-1.06.14-1.47,1.12h-.49c.16-.91.23-1.68.31-2.33H184Z"
            style={{ fill: "#ed1f24" }}
          />
          <path
            d="M150.32,557.11c-.28-1.08-.75-2.18-2.29-2.18a1.62,1.62,0,0,0-1.71,1.76c0,1.1.71,1.67,2.09,2.38,1.94,1,2.85,1.78,2.85,3.36,0,1.77-1.52,3.11-3.7,3.11A7.3,7.3,0,0,1,145,565c-.12-.45-.33-1.68-.45-2.55l.54-.13c.33,1.09,1.09,2.59,2.79,2.59a1.76,1.76,0,0,0,1.86-1.86c0-1.21-.66-1.73-2.1-2.51-1.74-.94-2.74-1.74-2.74-3.31s1.25-2.9,3.48-2.9a11.08,11.08,0,0,1,2.21.31c.07.6.14,1.29.28,2.36Z"
            style={{ fill: "#ed1f24" }}
          />
          <path
            d="M153,556.82l.78-1.05a2.94,2.94,0,0,1,2.18-1,2.28,2.28,0,0,1,2.53,2.21,1.77,1.77,0,0,1-.52,1.22,7.32,7.32,0,0,1-1.19.89,2.73,2.73,0,0,1,2.48,2.54c0,2.67-3.26,3.82-4.41,3.82a2.77,2.77,0,0,1-1.74-.57.77.77,0,0,1-.27-.57,1,1,0,0,1,.39-.66.36.36,0,0,1,.48,0,3.1,3.1,0,0,0,2.06.91c1.13,0,1.93-.84,1.93-2.43a2,2,0,0,0-2.16-2.12,4.13,4.13,0,0,0-1.07.17l-.11-.51c1.48-.48,2.61-1.12,2.61-2.33a1.44,1.44,0,0,0-1.56-1.53,2.62,2.62,0,0,0-2.05,1.29Z"
            style={{ fill: "#ed1f24" }}
          />
          <text
            transform="translate(789.38 314.73) rotate(59.2) scale(1 1.15)"
            style={{
              fontSize: "12.755020141601562px",
              fill: "#7bafdd",
              fontFamily: "MyriadPro-Regular, Myriad Pro",
            }}
          >
            <tspan style={{ letterSpacing: "-0.01297742707674124em" }}>B</tspan>
            <tspan x="6.75" y="0">
              .J2
            </tspan>
          </text>
          <text
            transform="translate(663.12 200.38) rotate(32.05) scale(1 1.15)"
            style={{
              fontSize: "11.95242977142334px",
              fill: "#7bafdd",
              fontFamily: "MyriadPro-Regular, Myriad Pro",
            }}
          >
            <tspan style={{ letterSpacing: "-0.012970525645392562em" }}>
              B
            </tspan>
            <tspan x="6.32" y="0">
              .I1
            </tspan>
          </text>
          <text
            transform="matrix(0.19, 0.98, -1.13, 0.22, 834.19, 398.32)"
            style={{
              fontSize: "12.755020141601562px",
              fill: "#7bafdd",
              fontFamily: "MyriadPro-Regular, Myriad Pro",
            }}
          >
            <tspan style={{ letterSpacing: "-0.01297742707674124em" }}>B</tspan>
            <tspan x="6.75" y="0">
              .I3
            </tspan>
          </text>
          <path
            d="M548.92,161.24c-.3-1.15-.79-2.31-2.42-2.31a1.72,1.72,0,0,0-1.81,1.87c0,1.16.75,1.76,2.21,2.51,2.06,1.06,3,1.88,3,3.55,0,1.87-1.61,3.28-3.9,3.28a7.61,7.61,0,0,1-2.72-.57c-.13-.47-.35-1.77-.48-2.69l.57-.14c.35,1.16,1.15,2.74,3,2.74a1.85,1.85,0,0,0,2-2c0-1.29-.7-1.84-2.22-2.66-1.82-1-2.88-1.84-2.88-3.49s1.31-3.07,3.67-3.07a11.19,11.19,0,0,1,2.34.33c.07.63.15,1.37.29,2.49Z"
            style={{ fill: "#ed1f24" }}
          />
          <path
            d="M552.3,169.88v-.51c1.91-.12,2-.23,2-1.53v-6.62c0-.89-.16-.93-.92-1l-.79-.08v-.46a15.5,15.5,0,0,0,3.31-.86v9c0,1.32.11,1.41,2,1.53v.51Z"
            style={{ fill: "#ed1f24" }}
          />
          <path
            d="M760.57,279.86c-.31-1.14-.8-2.3-2.43-2.3a1.71,1.71,0,0,0-1.8,1.86c0,1.16.74,1.76,2.21,2.52,2.05,1.05,3,1.87,3,3.54,0,1.87-1.61,3.29-3.91,3.29a7.72,7.72,0,0,1-2.71-.57,25.32,25.32,0,0,1-.48-2.69l.56-.15c.35,1.16,1.16,2.75,3,2.75a1.87,1.87,0,0,0,2-2c0-1.28-.7-1.83-2.22-2.66-1.83-1-2.89-1.83-2.89-3.49s1.32-3.07,3.68-3.07a11.91,11.91,0,0,1,2.33.33c.07.63.15,1.37.29,2.5Z"
            style={{ fill: "#ed1f24" }}
          />
          <path
            d="M763.94,288.5V288c1.91-.12,2-.22,2-1.52v-6.63c0-.89-.15-.93-.91-1l-.8-.09v-.46a15.59,15.59,0,0,0,3.32-.86v9c0,1.32.1,1.4,2,1.52v.51Z"
            style={{ fill: "#ed1f24" }}
          />
          <path
            d="M778.86,286.08c-.23.82-.54,1.76-.71,2.42h-6.66v-.42c1-.94,2-2,2.85-3,1.14-1.32,2.1-2.68,2.1-4.17s-.74-2.24-2-2.24a3,3,0,0,0-2.4,1.61l-.43-.38,1-1.46a3.23,3.23,0,0,1,2.44-1.05,2.89,2.89,0,0,1,3,3c0,1.46-.54,2.49-2.22,4.18-.74.75-1.81,1.79-2.54,2.47h3.26c1,0,1.22-.08,1.82-1.18Z"
            style={{ fill: "#ed1f24" }}
          />
          <path
            d="M816.85,365.87c-.27-1-.7-2-2.12-2a1.49,1.49,0,0,0-1.56,1.62c0,1,.64,1.53,1.92,2.19,1.78.92,2.61,1.63,2.61,3.08,0,1.63-1.4,2.86-3.39,2.86a6.65,6.65,0,0,1-2.36-.5,23.59,23.59,0,0,1-.42-2.34l.49-.12c.31,1,1,2.38,2.57,2.38a1.61,1.61,0,0,0,1.71-1.7c0-1.12-.61-1.6-1.93-2.32-1.59-.86-2.51-1.59-2.51-3s1.14-2.67,3.19-2.67a9.63,9.63,0,0,1,2,.29c.06.54.13,1.18.25,2.16Z"
            style={{ fill: "#ed1f24" }}
          />
          <path
            d="M819.78,373.39v-.45c1.66-.1,1.76-.19,1.76-1.32v-5.76c0-.78-.14-.81-.8-.88l-.69-.07v-.4a13.88,13.88,0,0,0,2.88-.75v7.86c0,1.15.09,1.22,1.76,1.32v.45Z"
            style={{ fill: "#ed1f24" }}
          />
          <path
            d="M827.19,373.39v-.45c1.67-.1,1.76-.19,1.76-1.32v-5.76c0-.78-.14-.81-.8-.88l-.69-.07v-.4a13.72,13.72,0,0,0,2.88-.75v7.86c0,1.15.1,1.22,1.77,1.32v.45Z"
            style={{ fill: "#ed1f24" }}
          />
          <path
            d="M848.08,458.73c-.28-1.08-.75-2.18-2.29-2.18a1.63,1.63,0,0,0-1.71,1.77c0,1.1.71,1.67,2.09,2.38,1.94,1,2.85,1.78,2.85,3.36,0,1.77-1.52,3.11-3.69,3.11a7.31,7.31,0,0,1-2.58-.54c-.12-.45-.33-1.68-.45-2.55l.54-.14c.33,1.1,1.09,2.6,2.79,2.6a1.76,1.76,0,0,0,1.86-1.86c0-1.21-.66-1.74-2.1-2.52-1.73-.94-2.74-1.74-2.74-3.3s1.25-2.9,3.48-2.9a10.17,10.17,0,0,1,2.21.31c.07.59.15,1.29.28,2.36Z"
            style={{ fill: "#ed1f24" }}
          />
          <path
            d="M855.92,463.94v1.21c0,1.07.07,1.17,1.26,1.28v.49h-4v-.49c1.22-.11,1.3-.23,1.3-1.3v-1.19h-4.34v-.53c1.6-2.26,3.35-4.71,5-6.92h.82v6.62h1.61v.83Zm-1.44-5.62c-1,1.45-2.15,3.11-3.29,4.79h3.29Z"
            style={{ fill: "#ed1f24" }}
          />
          <text
            transform="translate(200.18 334.44) rotate(-57.9) scale(1 1.15)"
            style={{
              fontSize: "12.755020141601562px",
              fill: "#f8d732",
              fontFamily: "MyriadPro-Regular, Myriad Pro",
            }}
          >
            <tspan style={{ letterSpacing: "-0.01297742707674124em" }}>B</tspan>
            <tspan x="6.75" y="0">
              .H2
            </tspan>
          </text>
          <text
            transform="matrix(0.85, -0.52, 0.6, 0.98, 321.13, 212.12)"
            style={{
              fontSize: "11.95242977142334px",
              fill: "#f8d732",
              fontFamily: "MyriadPro-Regular, Myriad Pro",
            }}
          >
            <tspan style={{ letterSpacing: "-0.012970525645392562em" }}>
              B
            </tspan>
            <tspan x="6.32" y="0">
              .H1
            </tspan>
          </text>
          <text
            transform="translate(164.21 421.2) rotate(-73.92) scale(1 1.15)"
            style={{
              fontSize: "12.755020141601562px",
              fill: "#f8d732",
              fontFamily: "MyriadPro-Regular, Myriad Pro",
            }}
          >
            <tspan style={{ letterSpacing: "-0.01297742707674124em" }}>B</tspan>
            <tspan x="6.75" y="0">
              .H3
            </tspan>
          </text>
          <path
            d="M450.39,160.61c-.3-1.14-.79-2.3-2.43-2.3a1.71,1.71,0,0,0-1.8,1.86c0,1.16.75,1.77,2.21,2.52,2.05,1.05,3,1.87,3,3.54,0,1.87-1.61,3.29-3.9,3.29a7.83,7.83,0,0,1-2.72-.57c-.13-.47-.36-1.77-.48-2.69l.57-.14c.35,1.15,1.15,2.74,2.95,2.74a1.86,1.86,0,0,0,2-2c0-1.28-.7-1.83-2.22-2.66-1.83-1-2.88-1.83-2.88-3.49s1.31-3.06,3.67-3.06a12.12,12.12,0,0,1,2.34.32c.07.64.15,1.37.29,2.5Z"
            style={{ fill: "#ed1f24" }}
          />
          <path
            d="M460.16,166.84c-.24.81-.54,1.76-.72,2.42h-6.66v-.43c1-.94,2-2,2.86-3,1.13-1.32,2.1-2.68,2.1-4.17s-.74-2.24-2-2.24a3.06,3.06,0,0,0-2.4,1.61l-.42-.38,1-1.46a3.26,3.26,0,0,1,2.44-1,2.89,2.89,0,0,1,3,3c0,1.46-.53,2.5-2.21,4.18-.74.75-1.82,1.79-2.54,2.47h3.25c1,0,1.22-.08,1.83-1.18Z"
            style={{ fill: "#ed1f24" }}
          />
          <path
            d="M231.16,281.83c-.3-1.15-.79-2.31-2.42-2.31a1.72,1.72,0,0,0-1.81,1.86c0,1.16.75,1.77,2.21,2.52,2.05,1.05,3,1.88,3,3.55,0,1.87-1.61,3.28-3.9,3.28a7.83,7.83,0,0,1-2.72-.57c-.13-.47-.36-1.77-.48-2.69l.57-.14c.35,1.15,1.15,2.74,2.95,2.74a1.85,1.85,0,0,0,2-2c0-1.29-.7-1.84-2.22-2.67-1.83-1-2.88-1.83-2.88-3.49s1.31-3.06,3.67-3.06a11.19,11.19,0,0,1,2.34.33c.07.63.15,1.36.29,2.49Z"
            style={{ fill: "#ed1f24" }}
          />
          <path
            d="M240.2,279.6l.1.15-.47,1.25h-4.07c-.16.93-.38,2.25-.55,3.08a8.23,8.23,0,0,1,2.11-.51,3.05,3.05,0,0,1,3.31,3,3.66,3.66,0,0,1-1.55,2.94,5.43,5.43,0,0,1-3.12,1.21,2.69,2.69,0,0,1-1.82-.66c-.27-.24-.34-.38-.31-.61a1,1,0,0,1,.4-.66.4.4,0,0,1,.52,0,3.12,3.12,0,0,0,2.21,1c1.4,0,2.06-1.2,2.06-2.5a2.49,2.49,0,0,0-2.81-2.62,5.4,5.4,0,0,0-1.76.31l-.06-.08c.28-1.4.67-3.82.87-5.26Z"
            style={{ fill: "#ed1f24" }}
          />
          <path
            d="M175.51,366.5c-.27-1-.69-2-2.11-2a1.49,1.49,0,0,0-1.57,1.62c0,1,.65,1.53,1.92,2.18,1.78.92,2.62,1.63,2.62,3.09,0,1.62-1.4,2.85-3.4,2.85a6.66,6.66,0,0,1-2.36-.49c-.11-.41-.31-1.54-.42-2.34l.49-.13c.31,1,1,2.39,2.57,2.39A1.62,1.62,0,0,0,175,372c0-1.12-.61-1.59-1.93-2.31-1.59-.87-2.51-1.6-2.51-3s1.14-2.66,3.19-2.66a10,10,0,0,1,2,.28c.06.55.13,1.19.25,2.17Z"
            style={{ fill: "#ed1f24" }}
          />
          <path
            d="M180.31,368.28a2.17,2.17,0,0,1,1-.26,2.69,2.69,0,0,1,2.74,2.81,3.24,3.24,0,0,1-3.19,3.37c-1.82,0-3.21-1.47-3.21-3.81a6.18,6.18,0,0,1,4.19-5.69,14,14,0,0,1,1.81-.41l.09.52a9.26,9.26,0,0,0-1.65.52,4.82,4.82,0,0,0-2.73,3.44Zm.31.64a3,3,0,0,0-1.38.38,5.48,5.48,0,0,0-.09,1.07c0,1.91.7,3.26,1.93,3.26s1.48-1.23,1.48-2.23C182.56,370.11,181.94,368.92,180.62,368.92Z"
            style={{ fill: "#ed1f24" }}
          />
          <path
            d="M150.32,460.69c-.28-1.08-.75-2.18-2.29-2.18a1.63,1.63,0,0,0-1.71,1.77c0,1.09.71,1.67,2.09,2.38,1.94,1,2.85,1.77,2.85,3.35,0,1.78-1.52,3.12-3.7,3.12a7.53,7.53,0,0,1-2.57-.54c-.12-.45-.33-1.68-.45-2.55l.54-.14c.33,1.1,1.09,2.6,2.79,2.6a1.76,1.76,0,0,0,1.86-1.86c0-1.22-.66-1.74-2.1-2.52-1.74-.94-2.74-1.74-2.74-3.3s1.25-2.91,3.48-2.91a11.08,11.08,0,0,1,2.21.31c.07.6.14,1.3.28,2.37Z"
            style={{ fill: "#ed1f24" }}
          />
          <path
            d="M158.16,465.9v1.21c0,1.07.07,1.17,1.26,1.28v.49h-4v-.49c1.22-.11,1.29-.23,1.29-1.31V465.9h-4.33v-.53c1.6-2.26,3.35-4.71,5-6.92h.83v6.62h1.61v.83Zm-1.45-5.62c-1,1.44-2.14,3.11-3.28,4.79h3.28Z"
            style={{ fill: "#ed1f24" }}
          />
        </g>
        <path
          d="M564.54,875.46a4,4,0,0,0-4.59-3.15A366.3,366.3,0,0,1,501.46,877c-201.27,0-365-163.74-365-365,0-170.86,118-314.68,276.81-354.22a4,4,0,0,0,3-4.71v0a4,4,0,0,0-4.89-3.1,373.2,373.2,0,0,0-55.09,705.75A370.63,370.63,0,0,0,501.46,885a376,376,0,0,0,59.8-4.75,4,4,0,0,0,3.28-4.79Z"
          style={{ fill: "#129b48", stroke: "#000", strokeMiterlimit: 10 }}
        />
        <path
          d="M845.14,657.19C827.31,702,799.46,741.5,765.21,775.75A372.33,372.33,0,0,1,595.7,873a4,4,0,0,1-4.94-3.09v0a4,4,0,0,1,2.94-4.7c156.75-41,272.77-183.78,272.77-353.19,0-201.26-163.74-365-365-365a365.05,365.05,0,0,0-54.36,4.05,4,4,0,0,1-4.55-3.17h0a4,4,0,0,1,3.32-4.78,376,376,0,0,1,55.59-4.1,372.71,372.71,0,0,1,373,373S879.83,570,845.14,657.19Z"
          style={{ fill: "#129b48", stroke: "#000", strokeMiterlimit: 10 }}
        />
      </svg>
    </div>
  );
}

export default Map;
