import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  EmailField,
  FunctionField,
  required,
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  DateInput,
  TextInput,
  Edit,
  CheckboxGroupInput,
  NumberField,
  FileField,
  FileInput,
  Show,
  SimpleShowLayout,
  DateField,
  TabbedForm,
  FormTab,
  ImageField,
  ImageInput,
  useListContext,
  Pagination,
  Filter,
} from "react-admin";
import axios from "axios";
import moment from "moment";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from "react-bootstrap/Button";
import PrintProvider, { Print, NoPrint } from "react-easy-print";

const CustomerFilter = (props) => {
  return (
    <NoPrint>
      <Filter {...props}>
        <TextInput label="Expense Type" source="expenseType" alwaysOn />
        <TextInput label="Note" source="note" />
        <TextInput label="User" source="user" />
        <DateInput label="start Date" source="startDate" />
        <DateInput label="end Date" source="endDate" />
      </Filter>
    </NoPrint>
  );
};

// const ListActions = () => {
//   return (
//     <Button
//       onClick={() => {
//         window.print();
//       }}
//     >
//       Print Report
//     </Button>
//   );
// };
const PostPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100, 150]} {...props} />
);
export const ExpenseTypeList = (props) => {
  return (
    <div id="expenses">
      <PrintProvider>
        <Print single={true} name="foo">
          <List
            {...props}
            title="Expense Types"
            pagination={<PostPagination />}
            filters={<CustomerFilter />}
          >
            <Datagrid rowClick="show">
              <TextField source="name" />
              <DateField source="cretaedAt" />
              <DateField source="updatedAt" />
            </Datagrid>
          </List>
        </Print>
      </PrintProvider>
    </div>
  );
};
export const ExpenseTypeShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <DateField source="cretaedAt" />
      <DateField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
);
export const ExpenseTypeCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" fullWidth={true} validate={[required()]} />
      <DateInput
        source="cretaedAt"
        fullWidth={true}
        style={{ display: "none" }}
      />
      <DateInput
        source="updatedAt"
        fullWidth={true}
        style={{ display: "none" }}
      />
    </SimpleForm>
  </Create>
);
