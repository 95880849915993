import * as React from "react";
import ZaitonLogo from "../assets/zaiton-plus-logo.jpg";
import { Title, useNotify } from "react-admin";
import axios from "axios";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Card } from "@material-ui/core";
import ToggleButton from "react-bootstrap/ToggleButton";

export const InvoiceList = (props) => {
  Date.prototype.toDateInputValue = function () {
    var local = new Date(this);
    local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
    return local.toJSON().slice(0, 10);
  };

  const token = localStorage.getItem("token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

  const [rows, setRows] = React.useState(15);
  const [periodOfInstallment, setPeriodOfInstallment] = React.useState(4);
  const [preInstallment, setPreInstallment] = React.useState(35);
  const [installments, setInstallments] = React.useState(50);
  const [getKeys, setGetKeys] = React.useState(15);
  const [properitiesArr, setProperitiesArr] = React.useState([]);
  const [properitiesData, setProperitiesData] = React.useState([]);
  const [customerArr, setCustomerArr] = React.useState([]);
  const [customerData, setCustomerData] = React.useState([]);
  const [properityData, setProperityData] = React.useState(0);

  const [structure, setStructure] = React.useState(0);
  const [structureValue, setStructureValue] = React.useState(0);
  const [percentageStructure, setPercentageStructure] = React.useState(true);
  const [structureDate, setStructureDate] = React.useState(moment().add(24, 'M').format("YYYY-MM-DD"));
  const [deliDate, setDeliDate] = React.useState(moment().add('60', 'M').format("YYYY-MM-DD"));

  const [defaultDate, setDefaultDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const [defaultDate2, setDefaultDate2] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const [buyContractId, setBuyContractId] = React.useState(-1);
  const [note, setNote] = React.useState("");
  const [customer, setCustomer] = React.useState("");
  const [customerId, setCustomerId] = React.useState();
  const [properity, setProperity] = React.useState("");
  const [propertyId, setProperityId] = React.useState("");
  const [price, setPrice] = React.useState(0);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [unauthorised, setUnauthorised] = React.useState(false);
  const [done, setDone] = React.useState(false);
  const [contractNumber, setContractNumber] = React.useState();
  const [prePayValue, setPrePayValue] = React.useState(
    (price / 100) * preInstallment
  );
  const [prePayValue2, setPrePayValue2] = React.useState(prePayValue);
  const [keyValue, setKeyValue] = React.useState((price / 100) * getKeys);
  const [keyValue2, setKeyValue2] = React.useState(keyValue);
  const [keyPercentage, setKeyPercentage] = React.useState(true);
  const [prePercentage, setPrePercentage] = React.useState(true);
  const [totalInstallments, setTotalInstallments] = React.useState(0);
  const [defaultDateOnly, setDefaultDateOnly] = React.useState(false);

  React.useEffect(() => {
    keyPercentage && setKeyValue((price / 100) * getKeys);
    keyPercentage && setKeyValue2((price / 100) * getKeys);
  }, [getKeys, price]);

  React.useEffect(() => {
    setDefaultDate2(defaultDate);
  }, [defaultDate]);

  let oneInstallment = 0;
  React.useEffect(() => {
    var tObj = document.getElementsByClassName("eachInstallment");
    for (var i = 0; i < tObj.length; i++) {
      prePercentage &&
        (tObj[i].defaultValue =
          (price - (keyValue + prePayValue + structureValue)) / rows > 0 &&
          (price - (keyValue + prePayValue + structureValue)) / rows);

      setTotalInstallments(
        ((price - (keyValue2 + prePayValue2 + structureValue)) / rows > 0 &&
          (price - (keyValue2 + prePayValue2 + structureValue)) / rows) + totalInstallments
      );

      oneInstallment =
        (price - (keyValue + prePayValue + structureValue)) / rows > 0 &&
        (price - (keyValue + prePayValue + structureValue)) / rows;
    }
  }, [
    keyValue2,
    keyValue,
    prePayValue,
    price,
    prePayValue2,
    rows,
    customerId,
    propertyId,
    structureValue
  ]);

  const notify = useNotify();

  React.useEffect(() => {
    prePercentage && setPrePayValue((price / 100) * preInstallment);
    prePercentage && setPrePayValue2((price / 100) * preInstallment);
  }, [preInstallment, price]);

  React.useEffect(() => {
    prePercentage && setInstallments(100 - (preInstallment + getKeys));
  }, [preInstallment]);

  React.useEffect(() => {
    setInstallments(100 - (preInstallment + getKeys));
  }, [getKeys]);

  const arrLength = [];
  for (let index = 0; index < rows; index++) {
    arrLength[index] = index;
  }
  const arr = ["first", "second", "third", "fourth"];

  const renameKeys = (keysMap, obj) =>
    Object.keys(obj).reduce(
      (acc, key) => ({
        ...acc,
        ...{ [keysMap[key] || key]: obj[key] },
      }),
      {}
    );

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
      .get(
        process.env.REACT_APP_URL + "/BuyContracts/GetContractNumber"
      )
      .then((resp) => setContractNumber(resp.data));
    axios
      .get(process.env.REACT_APP_URL + "/Properties/GetNotSold")
      .then((resp) => {
        setProperitiesArr(
          resp.data.map((properity) => {
            return {
              info: {
                zone: properity.zone,
                streetWidth: properity.streetWidth,
                streetNumber: properity.streetWidth,
                area: properity.area,
              },
              id: properity.id,
              name: properity.name,
              price: properity.initialPrice,
            };
          })
        );
        setProperitiesData(resp.data);
      });
    axios.get(process.env.REACT_APP_URL + "/Customers?_end=10000").then((resp) => {
      setCustomerArr(
        resp.data.map((properity, key) => {
          return {
            id: properity.id,
            name: `${properity.firstName} ${properity.middleName} ${properity.lastName}`,
            phone: properity.phone,
          };
        })
      );
      setCustomerData(resp.data);
    });
  }, []);

  async function handleClick() {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    var tObjForValidation = document.getElementsByClassName("eachInstallment");
    let totalInstallmentsForValidation = 0;
    for (var i = 0; i < tObjForValidation.length; i++) {
      totalInstallmentsForValidation =
        totalInstallmentsForValidation + parseInt(tObjForValidation[i].value);
    }
    if (totalInstallmentsForValidation + prePayValue2 + keyValue2 + structureValue !== price) {
      notify(
        `Total Price is not Accurate, ($${
          price - (totalInstallmentsForValidation + prePayValue2 + keyValue2)
        }) price difference found!`,
        "error"
      );
      return;
    }

    if (keyValue < 0) {
      notify("Key delivering should have a valid price", "error");
      return;
    } else if (prePayValue < 0) {
      notify("Pre payment should have a valid price", "error");
      return;
    }

    oneInstallment >= 0 && Number.isInteger(oneInstallment)
      ? await axios
          .post(process.env.REACT_APP_URL + "/BuyContracts", {
            customerId,
            propertyId,
            contractNumber,
            prePaymentPrice: prePayValue2,
            isPrePaymentPayed: false,
            buyingDate: defaultDateOnly ? defaultDate2 : defaultDate,
            buyingPrice: price,
            isLoanDone: false,
            keyRecevePrice: keyValue2,
            buyType: "installment",
            note: note,
            createdAt: "2020-08-25T07:38:08.687Z",
            updatedAt: "2020-08-25T07:38:08.687Z",
          })
          .then((resp) => {
            if (resp.status === 205) {
              notify("Contract Number Exists!", "error");
              return
            }
            if (resp.status >= 200 && resp.status < 300) {
              notify("Contract Created!");
              setDone(true);
            } else {
              notify("A problem occured while creating!", "error");
            }

            axios.post(
                process.env.REACT_APP_URL + "/PaymentMethodProcedures",
                {
                  firsDay: { payPrice: structureValue, payDate: structureDate },
                  secondDay: { payPrice: 0, payDate: moment('2080-1-1').format('YYYY-MM-DD') },
                  id: resp.data,
                  isItKeyPayment: false,
                  isItPrePayment: true
                });

            for (var i = 0; i <= document.forms.length; i++) {
              if (i === document.forms.length) {
                var keyDayForm = document.forms[i - 1];
                var keyDayData = Object.fromEntries(
                  new FormData(keyDayForm).entries()
                );
                const keyDate = moment(keyDayData.payDate)
                  .add(periodOfInstallment, "M")
                  .format("YYYY-MM-DD");
                axios.post(
                  process.env.REACT_APP_URL + "/PaymentMethodProcedures",
                  {
                    firsDay: { payPrice: keyValue2, payDate: deliDate },
                    secondDay: { payPrice: 0, payDate: deliDate },
                    id: resp.data,
                    isItKeyPayment: true,
                  });
                return;
              }
              var firstDayForm = document.forms[i];
              var secondDayForm = document.forms[i + 1];
              var firstDayData = Object.fromEntries(
                new FormData(firstDayForm).entries()
              );
              var secondDayData = Object.fromEntries(
                new FormData(secondDayForm).entries()
              );
              axios.post(
                process.env.REACT_APP_URL + "/PaymentMethodProcedures",
                {
                  firsDay:
                    firstDayData.payDate2 !== ""
                      ? {
                          payPrice: firstDayData.payPrice,
                          payDate: firstDayData.payDate2,
                        }
                      : {
                          payPrice: firstDayData.payPrice,
                          payDate: firstDayData.payDate,
                        },
                  secondDay:
                    secondDayData.payDate2 !== ""
                      ? {
                          payPrice: secondDayData.payPrice,
                          payDate: secondDayData.payDate2,
                        }
                      : {
                          payPrice: secondDayData.payPrice,
                          payDate: secondDayData.payDate,
                        },
                  id: resp.data,
                  isItKeyPayment: false,
                }
              );
            }
          })
          .catch((err) => notify(`${err}`, "error"))
      : notify("Installments should be valid whole number values!", "error");
    window.open(`#/BuyContracts`, "_self");
  }

  return (
    <Card style={{ paddingTop: 5, paddingBottom: 5, marginTop: 60 }}>
      <Title title="New Contract" />
      <div>
        <div style={{ display: "flex", width: "99%", margin: "auto" }}>
          <div
            style={{
              height: 150,
              width: 150,
              marginRight: 20,
            }}
          >
            <img
              src={ZaitonLogo}
              alt=""
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div
            style={{
              width: "45%",
              marginBottom: 15,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Autocomplete
              id="combo-box-demo"
              options={properitiesArr}
              getOptionLabel={(option) => {
                // setPropertyInfo({
                //   propertyData: option.info,
                //   propertyId: option.id,
                //   propertyPrice: option.price,
                // });
                return option.name;
              }}
              style={{ width: 500 }}
              onChange={(e, option) => {
                setProperityData(option.info);
                setProperityId(option.id);
                setPrice(option.price);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Property" variant="outlined" />
              )}
            />
            <Autocomplete
              id="combo-box-demo"
              options={customerArr}
              getOptionLabel={(option) => {
                setCustomerId(option.id);
                return `${option.name} | ${option.phone}`;
              }}
              style={{ width: 500 }}
              onSelect={(e) => setCustomerId(e.target.value)}
              renderInput={(params) => (
                <TextField {...params} label="Customer" variant="outlined" />
              )}
            />
          </div>
          <div style={{ width: "45%", marginLeft: 20 }}>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">Area</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                placeholder="Area"
                value={properityData.area && `${properityData.area}m`}
                // value={console.log(properityData)}
                type="text"
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">Street</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                placeholder="Street Width"
                value={
                  properityData.streetWidth && `${properityData.streetWidth}m`
                }
                type="text"
              />
              <FormControl
                placeholder="Street Number"
                value={properityData.streetNumber}
                type="text"
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">Zone</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                placeholder="Zone"
                value={properityData.zone}
                type="text"
              />
            </InputGroup>
          </div>
        </div>

        <InputGroup className="mb-3" style={{ width: "99%", margin: "auto" }}>
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">Contract Number</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            placeholder="Contract Number"
            onChange={(e) => setContractNumber(e.target.value)}
            value={contractNumber}
            type="text"
          />
        </InputGroup>
        {/* </SimpleForm> */}
        <div style={{ width: "99%", margin: "auto" }}>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">
                Property Price
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              placeholder="Price"
              onChange={(e) => setPrice(parseInt(e.target.value))}
              disabled={!isAdmin}
              value={price}
              type="number"
            />
            <Button
              style={{ marginLeft: 10 }}
              variant="primary"
              onClick={() => setShow(true)}
            >
              Change Price
            </Button>

            <Modal
              show={show}
              onHide={() => setShow(false)}
              dialogClassName="modal-90w"
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                  Please Enter Your Username and Password.
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                      Username
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                      Password
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputGroup>
                <p
                  style={{
                    color: "tomato",
                    fontSize: 12,
                    display: !unauthorised && "none",
                  }}
                >
                  Username or Password is Incorrect!
                </p>
                <Button
                  onClick={() => {
                    const token = localStorage.getItem("token");
                    axios.defaults.headers.common = {
                      Authorization: `Bearer ${token}`,
                    };
                    axios
                      .post(process.env.REACT_APP_PATH + "/loginCheck", {
                        username,
                        password,
                      })
                      .then((res) => {
                        if (res.status === 200) {
                          setIsAdmin(true);
                          setShow(false);
                          setUnauthorised(false);
                        } else {
                          setIsAdmin(false);
                          setUnauthorised(true);
                        }
                      })
                      .catch((error) => {
                        setIsAdmin(false);
                        setUnauthorised(true);
                        setUsername("");
                        setPassword("");
                      });
                  }}
                >
                  Submit
                </Button>
              </Modal.Body>
            </Modal>
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">
                No. of Installments
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              type="number"
              value={rows}
              onChange={(e) => e.target.value >= 0 && setRows(e.target.value)}
            />
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">
                Period of Installments
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              type="number"
              value={periodOfInstallment}
              onChange={(e) =>
                e.target.value > 0 && setPeriodOfInstallment(e.target.value)
              }
            />
          </InputGroup>
        </div>

        <Table
          bordered
          hover
          style={{ width: "99%", margin: "auto", marginBottom: 15 }}
        >
          <thead>
            <tr>
              <th>Installment</th>
              <th>Percentage %</th>
              <th>Percentage of Installments</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Prepayment</td>
              <td>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    type="number"
                    placeholder="Pre Payment"
                    value={preInstallment}
                    disabled={!prePercentage}
                    onChange={(e) =>
                      e.target.value >= 0 &&
                      prePercentage &&
                      setPreInstallment(parseFloat(e.target.value))
                    }
                  />
                </InputGroup>
              </td>
              <td>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    type="number"
                    value={prePayValue2}
                    onChange={(e) => {
                      setPrePayValue2(parseInt(e.target.value));
                      setPreInstallment((e.target.value / price) * 100);
                    }}
                    disabled={prePercentage}
                  />

                  <ToggleButton
                    style={{ marginLeft: 5, marginBottom: 0 }}
                    type="checkbox"
                    checked={prePercentage}
                    onChange={() => setPrePercentage(!prePercentage)}
                  >
                    {" "}
                    Percentage
                  </ToggleButton>
                </InputGroup>
              </td>
              <td>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">Date</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    type="date"
                    value={defaultDateOnly ? defaultDate2 : defaultDate}
                    onChange={(e) =>
                      defaultDateOnly
                        ? setDefaultDate2(e.target.value)
                        : setDefaultDate(e.target.value)
                    }
                  />
                  <ToggleButton
                    style={{ marginLeft: 5, marginBottom: 0 }}
                    type="checkbox"
                    checked={defaultDateOnly}
                    onChange={() => setDefaultDateOnly(!defaultDateOnly)}
                  >
                    {" "}
                    Change Individually
                  </ToggleButton>
                </InputGroup>
              </td>
            </tr>
            {(properityData !== 0 && properityData.zone === 'J') &&
            <tr>
              <td>Structure</td>
              <td colSpan={2}>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                      type="number"
                      value={structureValue}
                      onChange={(e) => {
                        setStructureValue(parseInt(e.target.value));
                        setStructure((e.target.value / price) * 100);
                      }}
                      disabled={false}
                  />
                </InputGroup>
              </td>
              <td>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">Date</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                      type="date"
                      defaultValue={structureDate}
                      onChange={(e) => setStructureDate(e.target.value)}
                  />
                </InputGroup>
              </td>
            </tr>
            }
            <tr>
              <td>Installments</td>
              <td>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    type="number"
                    disabled={true}
                    placeholder="Installments"
                    value={installments}
                    onChange={(e) => setInstallments(parseInt(e.target.value))}
                  />
                </InputGroup>
              </td>
              <td colSpan="2">
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>Note</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    as="textarea"
                    aria-label="Note"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />
                </InputGroup>
              </td>
            </tr>
            {arrLength.map((el, key) => (
              <tr>
                <td>{key + 1}</td>
                <td colSpan="3">
                  <form style={{ display: "flex" }}>
                    <InputGroup className="mb-3" style={{ marginRight: 5 }}>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        type="number"
                        name="payPrice"
                        // value={eachInstallment}
                        // onChange={(e) => setInstallmentModified(e.target.value)}
                        className="eachInstallment"
                      />
                    </InputGroup>
                    <InputGroup className="mb-3" style={{ marginLeft: 5 }}>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          date
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        type="date"
                        id={`date${key + 1}`}
                        readOnly={true}
                        style={{
                          borderRight: "none",
                          borderBottomRightRadius: 0,
                          borderTopRightRadius: 0,
                        }}
                        name="payDate"
                        value={moment(defaultDate)
                          .add(periodOfInstallment * (key + 1), "M")
                          .format("YYYY-MM-DD")}
                      />
                    </InputGroup>
                    <FormControl
                      style={{
                        width: 74,
                        borderLeft: "none",
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                      }}
                      // value=" "
                      type="date"
                      name="payDate2"
                      onChange={(e) => {
                        document.getElementById(`date${key + 1}`).value =
                          e.target.value;
                      }}
                    />
                    {/* </InputGroup> */}
                  </form>
                </td>
              </tr>
            ))}
            <tr>
              <td>Delivering Keys</td>
              <td>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    type="number"
                    placeholder="Getting Keys Payment"
                    value={getKeys}
                    disabled={!keyPercentage}
                    onChange={(e) =>
                      e.target.value >= 0 &&
                      keyPercentage &&
                      setGetKeys(parseFloat(e.target.value))
                    }
                  />
                </InputGroup>
              </td>
              <td>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    type="number"
                    value={keyValue2}
                    onChange={(e) => {
                      setKeyValue2(parseInt(e.target.value));
                      !keyPercentage &&
                        setGetKeys((e.target.value / price) * 100);
                    }}
                    disabled={keyPercentage}
                  />

                  <ToggleButton
                    style={{ marginLeft: 5, marginBottom: 0 }}
                    type="checkbox"
                    checked={keyPercentage}
                    onChange={() => setKeyPercentage(!keyPercentage)}
                  >
                    {" "}
                    Percentage
                  </ToggleButton>
                </InputGroup>
              </td>
              <td>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">Date</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                      type="date"
                      value={deliDate}
                      onChange={(e) => setDeliDate(e.target.value)}
                  />
                </InputGroup>
              </td>
            </tr>
          </tbody>
        </Table>
        <Button
          disabled={done}
          style={{ marginLeft: 15 }}
          onClick={() => handleClick()}
        >
          Submit
        </Button>
      </div>
    </Card>
  );
};
