import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  EmailField,
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  DateInput,
  BooleanInput,
  Edit,
  Filter,
  TabbedForm,
  BooleanField,
  FormTab,
  required,
  NumberInput,
  SimpleShowLayout,
  Show,
  DateField,
} from "react-admin";
import axios from "axios";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import moment from "moment";
import ZaitonLogo from "../assets/zaiton-plus-logo.jpg";

// const ReservedProperitiesFilter = (props) => (
//   <Filter {...props}>
//     <TextInput label="Name" source="name" alwaysOn />
//     <TextInput label="Zone" source="zone" />
//     <TextInput label="Block" source="block" />
//     <TextInput label="Street Number" source="streetNumber" />
//   </Filter>
// );

export const ReservedPropertiesList = (props) => (
  <List {...props} title="Reserved Properties">
    <Datagrid>
      <TextField label="Name" source="property.name" />
      <TextField label="Zone" source="property.zone" />
      <TextField label="Block" source="property.block" />
      <DateField source="date" />
      <TextField source="note" />
    </Datagrid>
  </List>
);

export const ReservedPropertiesCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <ReferenceInput
        label="Name"
        source="propertyId"
        reference="Properties/GetNotSold"
        validate={[required()]}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput multiline source="note" />
      <DateInput
        source="date"
        validate={[required()]}
        defaultValue={moment().format("YYYY-MM-DD")}
      />
    </SimpleForm>
  </Create>
);
