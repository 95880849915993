import * as React from "react";
import { Title } from "react-admin";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import CARD from 'react-bootstrap/Card';
import axios from "axios";
import moment from "moment";
import Accordion from 'react-bootstrap/Accordion'
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import Map from "./components/map";
import DollarIcon from "@material-ui/icons/AttachMoney";
import CardWithIcon from "./components/cards/CardWithIcon.tsx";
import {FaFile, FaHome} from "react-icons/all";

function Dashboard(props) {
  const [upcomingPayments, setUpcomingPayments] = React.useState([]);
  const [upcomingPaymentRange, setUpcomingPaymentRange] = React.useState([]);
  const [activePhase, setActivePhase] = React.useState("nextTenDays");
  const [dailySummary, setDailySummary] = React.useState([]);
  const [startDate, setStartDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(
    moment().add(7, "d").format("YYYY-MM-DD")
  );
  const [generalData, setGeneralData] = React.useState([]);

  const [visibleRange, setVisibleRange] = React.useState('current');
  const [currentPayments, setCurrentPayments] = React.useState([]);
  const [startCurrent, setStartCurrent] = React.useState(0);
  const [endCurrent, setEndCurrent] = React.useState(10);
  const [nextPayments, setNextPayments] = React.useState([]);
  const [startNext, setStartNext] = React.useState(0);
  const [endNext, setEndNext] = React.useState(10);
  const [passedPayments, setPassedPayments] = React.useState([]);
  const [startPassed, setStartPassed] = React.useState(0);
  const [endPassed, setEndPassed] = React.useState(10);

  const role = localStorage.getItem("role").toLowerCase();

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

    axios
      .get(`${process.env.REACT_APP_URL}/PaymentProcesses?isPaid=false&sort=currentPayDate&order=ASC&_start=${startCurrent}&_end=${endCurrent}&title=bar&startDate=${moment().format('MM/DD/YYYY')}&endDate=${moment().add(10, 'days').format('MM/DD/YYYY')}`)
      .then((resp) => {
        (visibleRange === 'current') && setUpcomingPaymentRange(resp.data)
        setCurrentPayments(resp.data)
      });

    axios
      .get(`${process.env.REACT_APP_URL}/PaymentProcesses?isPaid=false&sort=currentPayDate&order=ASC&_start=${startNext}&_end=${endNext}&title=bar&startDate=${moment().add(10, 'days').format('MM/DD/YYYY')}&endDate=${moment().add(20, 'days').format('MM/DD/YYYY')}`)
      .then((resp) => {
        (visibleRange === 'next') && setUpcomingPaymentRange(resp.data)
        setNextPayments(resp.data)
      });

    axios
      .get(`${process.env.REACT_APP_URL}/PaymentProcesses/GetPassesPayment?sort=currentPayDate&_start=${startPassed}&_end=${endPassed}`)
      .then((resp) => {
        (visibleRange === 'passed') && setUpcomingPaymentRange(resp.data)
        setPassedPayments(resp.data)
      });

    axios
      .post(process.env.REACT_APP_URL + "/Dashboard/TodaySummary")
      .then((res) => setDailySummary(res.data));
  }, [endNext, endCurrent, endPassed]);

  React.useEffect(() => {
    axios
      .post(process.env.REACT_APP_URL + "/Dashboard/GeneralData", {
        startDate,
        endDate,
      })
      .then((res) => {
        setGeneralData(res.data);
      })
      .catch((error) => console.log(error.message));
  }, [endDate, startDate]);

  const upcomingPaymentsTemplate = upcomingPaymentRange
    .filter(upcomingPayment => upcomingPayment.isItKeyPayment === false)
    .sort(function(b, a){
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.currentPayDate) - new Date(a.currentPayDate);
    })
    .map((upcomingPayment, key) => (
      <CardContent key={key} style={{ paddingTop: 5 }}>
        <div
          style={{
            backgroundColor: "#f9f9f9",
            borderRadius: 4,
            padding: 10,
          }}
        >
          <h5
            style={{ marginBottom: 5 }}
          >
              <a href={`#/Customers/${upcomingPayment.buyContract.customer.id}/show`}>
                  {`${upcomingPayment.buyContract.customer.firstName} ${upcomingPayment.buyContract.customer.middleName} ${upcomingPayment.buyContract.customer.lastName} (${upcomingPayment.buyContract.customer.phone.replace('+964', "0")})`}
              </a>
          </h5>
          <divz>
            Price: <b>${upcomingPayment.currentPayPrice && upcomingPayment.currentPayPrice.toLocaleString()}</b>
          </divz>
          <div className={'mt-1'}>
              <a href={`#/BuyContracts/${upcomingPayment.buyContractId}/show`} className={`btn btn-primary`}><FaHome /></a>
              &nbsp;
              <a href={`#/Properties/${upcomingPayment.buyContract.propertyId}/show`} className={`btn btn-primary`}><FaFile /></a>
          </div>
          <div style={{ color: "#777", textAlign: "right" }}>
            <i>
              {moment(upcomingPayment.currentPayDate).format("DD/MM/YYYY")}
            </i>
          </div>
        </div>
      </CardContent>
    ));
  return (
    <div>
      <Title title={`${localStorage.getItem('name')}`}/>
      <div style={{ display: "flex" }}>
        <div style={{ width: (role === 'admin' || role === 'accountant') ? "70%" : '100%' }}>
          <div style={{ width:  (role === 'admin' || role === 'accountant') ? 800 : '100%', height: 800 }}>
              {role === 'admin' || role === 'accountant' ? <Map /> : ''}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            width: "30%",
          }}
        >
          {(role === 'admin' || role === 'accountant' || role === 'viewer') && <Accordion style={{width: '100%', marginTop: 15}}>
            <CARD>
                <CARD.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    Today's Summary
                  </Accordion.Toggle>
                </CARD.Header>
                <Accordion.Collapse eventKey="1">
                  <CARD.Body>
                  <CardWithIcon
                    to="/"
                    icon={DollarIcon}
                    subtitle={"Today's Summary"}
                    children={
                      <p style={{ padding: 10 }}>
                        Save Box: ${dailySummary.saveBox} <br />
                        Property Expenses: ${dailySummary.propertyExpenses} <br />
                        Expenses: ${dailySummary.expenses} <br />
                        Remained Cash to Collect: ${dailySummary.remainToCollect} <br />
                      </p>
                    }
                  />
                  </CARD.Body>
              </Accordion.Collapse>
            </CARD>
            <CARD>
              <CARD.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  General Data
                </Accordion.Toggle>
              </CARD.Header>
              <Accordion.Collapse eventKey="0">
                <CARD.Body>
                <div
              style={{
                width: "94%",
                margin: 10,
                marginLeft: "auto",
                // height: 40,
              }}
            >
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">
                    Start Date
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  type="date"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                />
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">End Date</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  type="date"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                />
              </InputGroup>
            </div>

            <CardContent style={{ paddingTop: 0 }}>
              Expenses: {generalData.expenses && (parseFloat(generalData.expenses)).toLocaleString()} <br />
              Property Expenses: {generalData.propertyExpenses && (parseFloat(generalData.propertyExpenses)).toLocaleString()} <br />
              Cancelled Contracts: {generalData.canselledContractsCount && (parseFloat(generalData.canselledContractsCount)).toLocaleString()} <br />
              Current Contracts: {generalData.currentContractCount && (parseFloat(generalData.currentContractCount)).toLocaleString()} <br />
              Returned Money: {generalData.returnedPrices && (parseFloat(generalData.returnedPrices)).toLocaleString()} <br />
              Remained Money: {generalData.remaainPrices && (parseFloat(generalData.remaainPrices)).toLocaleString()} <br />
              Safe Box: {generalData.saveBox && (parseFloat(generalData.saveBox)).toLocaleString()} <br />
            </CardContent>
                </CARD.Body>
              </Accordion.Collapse>
            </CARD>
          </Accordion>}
          {(role === 'admin' || role === 'accountant' || role === 'viewer') && <Card style={{ width: "100%", marginTop: 15, overflowAnchor: 'none' }}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <CardContent
                style={{
                  cursor: "pointer",
                  backgroundColor: activePhase === "passed" && "#f2f2f2",
                  borderRadius: 4,
                  padding: 5,
                  margin: 5,
                }}
                onClick={() => {
                  setActivePhase("passed");
                  setVisibleRange('passed')
                  setUpcomingPaymentRange(
                    passedPayments
                  );
                }}
              >
                Passed
              </CardContent>
              <CardContent
                style={{
                  cursor: "pointer",
                  backgroundColor: activePhase === "nextTenDays" && "#f2f2f2",
                  borderRadius: 4,
                  padding: 5,
                  margin: 5,
                }}
                onClick={() => {
                  setActivePhase("nextTenDays");
                  currentPayments && setUpcomingPaymentRange(
                    currentPayments
                  );
                  setVisibleRange('current')
                }}
              >
                Next 10 Days
              </CardContent>
              <CardContent
                style={{
                  cursor: "pointer",
                  backgroundColor:
                    activePhase === "afterNextTenDays" && "#f2f2f2",
                  borderRadius: 4,
                  padding: 5,
                  margin: 5,
                }}
                onClick={() => {
                  setActivePhase("afterNextTenDays");
                  setUpcomingPaymentRange(
                    nextPayments
                  );
                  setVisibleRange('next')
                }}
              >
                Next 20 Days
              </CardContent>
            </div>
            <CardHeader title="Upcoming Payments" />
            {upcomingPaymentsTemplate}

            <Button
            style ={{ marginLeft: 15, marginBottom: 15}}
            onClick={() => {
              if(visibleRange === 'passed') {
                setEndPassed(endPassed + 10)
              } else if(visibleRange === 'current') {
                setEndCurrent(endCurrent + 10)
              } else if(visibleRange === 'next') {
                setEndNext(endNext + 10)
              }
            }}>
              Load More
            </Button>
          </Card>}
        </div>
      </div>
    </div>
  );
}
export default Dashboard;
