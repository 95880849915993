import axios from 'axios'

const authProvider = {
  login: ({ username, password }) => {
    const request = new Request(process.env.REACT_APP_PATH + "/login", {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status === 401) {
          throw new Error("Username or Password is incorrect!");
        } else if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        
        return response.json();
      })
      .then(({ token, userFirstName, userLastName, userType, loginId, lastLogout, userName }) => {
        if(userType === 'Customer') {
          throw new Error('You do not have permission!')
        }
        localStorage.setItem("token", token);
        localStorage.setItem("name", `${userFirstName} ${userLastName}`);
        localStorage.setItem("role", userType);
        localStorage.setItem("loginId", loginId);
        localStorage.setItem("lastLogout", lastLogout);
        (userType === 'Admin' || userType === 'Accountant') && localStorage.setItem("userName", userName);
        window.location.reload()
      });
  },
  logout: () => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

  axios.post(`${process.env.REACT_APP_PATH}/LogOut`)
    .then(resp => {
    })
      localStorage.removeItem('token');
      return Promise.resolve();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () =>
    localStorage.getItem("token") ? Promise.resolve() : Promise.reject(),
  getPermissions: () => {
    const role = localStorage.getItem("permissions");
    return role ? Promise.resolve(role) : Promise.reject();
  },
};

export default authProvider;
