import React, { useState, useEffect } from 'react';
import axios from 'axios'
import Table from "react-bootstrap/Table";
import moment from 'moment'
import PerSourcesChart from "./PerSourceChart";

function PerSources(props) {
    const [monthsVisits, setMonthsVisits] = useState([])

    useEffect(() => {
        if (props.currentTab === 'perSources') {
            const token = localStorage.getItem("token");
            axios.defaults.headers.common = {Authorization: `Bearer ${token}`};
            axios.get(`${process.env.REACT_APP_URL}/LeadVisits/GetLeadVisitReport2?startDate=${props.startDate}&endDate=${props.endDate}&_end=1000`)
                .then((resp) => {
                    resp.data.sort(function (a, b) {
                        return b.length - a.length;
                    });
                    setMonthsVisits(resp.data)
                });
        }
      }, [props.startDate, props.endDate, props.currentTab]);

    return (
        <div style={{margin: 5}}>
            <Table striped bordered hover>
            <thead>
                <tr>
                <th>#</th>
                <th>Total</th>
                <th>9:00 - 12:00</th>
                <th>12:00 - 14:00</th>
                <th>14:00 - 17:00</th>
                <th>Women</th>
                <th>Men</th>
                </tr>
            </thead>
            <tbody>
                {
                    monthsVisits.map((visits, key) => {
                        return <tr key={key}>
                            {/* <td>{moment(visits[0].visitDate).format('ddd - YYYY/MM/DD')}</td> */}
                            <td>{visits[0].sourceName}</td>
                            <td>{visits.map(visit => visit.females + visit.males).reduce((a,b) => a + b, 0)}</td>
                            <td>{visits.map(visit => (moment(visit.visitDate).isBetween(moment(visit.visitDate).format('YYYY-MM-DDT09:00:00'), moment(visit.visitDate).format('YYYY-MM-DDT12:00:00')) && (visit.females + visit.males))).reduce((a,b) => a + b, 0)}</td>
                            <td>{visits.map(visit => (moment(visit.visitDate).isBetween(moment(visit.visitDate).format('YYYY-MM-DDT12:00:00'), moment(visit.visitDate).format('YYYY-MM-DDT14:00:00')) && (visit.females + visit.males))).reduce((a,b) => a + b, 0)}</td>
                            <td>{visits.map(visit => (moment(visit.visitDate).isBetween(moment(visit.visitDate).format('YYYY-MM-DDT14:00:00'), moment(visit.visitDate).format('YYYY-MM-DDT17:00:00')) && (visit.females + visit.males))).reduce((a,b) => a + b, 0)}</td>
                            <td>{visits.map(visit => visit.females ).reduce((a,b) => a + b, 0)}</td>
                            <td>{visits.map(visit => visit.males).reduce((a,b) => a + b, 0)}</td>
                        </tr>
                    })
                }
                <tr>
                    <td><b>Total</b></td>
                    <td><b>{monthsVisits.map(visits => visits.map(el => el.females + el.males).reduce((a,b) => a + b, 0)).reduce((a,b) => a + b, 0)}</b></td>
                    <td><b>{monthsVisits.map(visits => visits.map(visit => (moment(visit.visitDate).isBetween(moment().format('YYYY-MM-DDT09:00:00'), moment().format('YYYY-MM-DDT12:00:00')) && (visit.females + visit.males))).reduce((a,b) => a + b, 0)).reduce((a,b) => a + b, 0)}</b></td>
                    <td><b>{monthsVisits.map(visits => visits.map(visit => (moment(visit.visitDate).isBetween(moment().format('YYYY-MM-DDT12:00:00'), moment().format('YYYY-MM-DDT14:00:00')) && (visit.females + visit.males))).reduce((a,b) => a + b, 0)).reduce((a,b) => a + b, 0)}</b></td>
                    <td><b>{monthsVisits.map(visits => visits.map(visit => (moment(visit.visitDate).isBetween(moment().format('YYYY-MM-DDT14:00:00'), moment().format('YYYY-MM-DDT17:00:00')) && (visit.females + visit.males))).reduce((a,b) => a + b, 0)).reduce((a,b) => a + b, 0)}</b></td>
                    <td><b>{monthsVisits.map(visits => visits.map(el => el.females).reduce((a,b) => a + b, 0)).reduce((a,b) => a + b, 0)}</b></td>
                    <td><b>{monthsVisits.map(visits => visits.map(el => el.males).reduce((a,b) => a + b, 0)).reduce((a,b) => a + b, 0)}</b></td>
                </tr>
            </tbody>
            </Table>
            <div style={{display: 'flex', width: '100%', justifyContent: 'space-around', alignItems: 'center'}}>
                <PerSourcesChart startDate={props.startDate} endDate={props.endDate} currentTab={props.currentTab} />
            </div>
        </div>
    )
}

export default PerSources
