import React, { useEffect, useState } from "react";
import "../invoice.css";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import { Route, Redirect } from "react-router";

function HZone(props) {
  const [properties, setProperties] = useState([]);
  const [name, setName] = useState();
  useEffect(() => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
      .get(process.env.REACT_APP_URL + "/Dashboard/H")
      .then((res) => {
        setProperties(res.data)
        props.setLoader()
      });
  }, []);

  const soldProperties = properties.filter(
    (property) => property.isSold === true
  ).length;
  const remainedProperties = properties.filter(
    (property) => property.isSold === false
  ).length;
  const holdedProperties = properties.filter(
    (property) => property.isHijz === true
  ).length;

  const role = localStorage.getItem("role");
  role !== 'Seller' && properties.map((el) => {
    document.getElementById(`${el.name}`) && document.getElementById(`${el.name}`).addEventListener("click", () => {
      el.isSold
        ? window.open(`#/BuyContracts/${el.contractId}/show`, "_self")
        : window.open(
            `#/Properties?filter=%7B"name"%3A"${el.name}"%7D&order=ASC&page=1&perPage=10&sort=id`,
            "_self"
          );
    });
  });
  properties
    .filter((el) => el.isSold === false)
    .map((el) => {
      document.getElementById(`${el.name}`) && (document.getElementById(`${el.name}`).style.fill = "#ffc107");
    });

  properties
    .filter((el) => el.isSold === true)
    .map((el) => {
      document.getElementById(`${el.name}`) && (document.getElementById(`${el.name}`).style.fill = "#17a2b8");
    });

  properties
    .filter((el) => el.isHijz === true)
    .map((el) => {
      document.getElementById(`${el.name}`) && (document.getElementById(`${el.name}`).style.fill = "#dc3545");
    });
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "80%",
        }}
      >
        <Button
          onClick={() => {
            props.resetZone();
          }}
        >
          Back
        </Button>
        <Button variant="info">
          Sold Properties <Badge variant="light">{soldProperties}</Badge>
          <span className="sr-only">unread messages</span>
        </Button>
        <Button variant="warning">
          Remained Properties{" "}
          <Badge variant="light">{remainedProperties}</Badge>
          <span className="sr-only">unread messages</span>
        </Button>
        <Button variant="danger">
          On Hold Properties <Badge variant="light">{holdedProperties}</Badge>
          <span className="sr-only">unread messages</span>
        </Button>
      </div>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 1024 1024"
        style={{ enableBackground: "new 0 0 1024 1024" }}
        xml="preserve"
      >
        <title>H-Last</title>
        <polygon
          id="H1"
          style={{ fill: "#FDFCE3" }}
          points="963.7,81.2 963.7,162.1 880.3,162.1 880.3,85.9 922,82.3 "
        />
        <rect
          id="H2"
          x="880.3"
          y="162.8"
          style={{ fill: "#FDFCE3" }}
          width="83.4"
          height="62.8"
        />
        <rect
          id="H3"
          x="880.3"
          y="225.6"
          style={{ fill: "#FDFCE3" }}
          width="83.4"
          height="62.8"
        />
        <rect
          id="H4"
          x="880.3"
          y="288.9"
          style={{ fill: "#FDFCE3" }}
          width="83.4"
          height="62.8"
        />
        <rect
          id="H5"
          x="880.3"
          y="352.1"
          style={{ fill: "#FDFCE3" }}
          width="83.4"
          height="62.8"
        />
        <rect
          id="H6"
          x="880.3"
          y="415.5"
          style={{ fill: "#FDFCE3" }}
          width="83.4"
          height="62.8"
        />
        <rect
          id="H7"
          x="880.3"
          y="478.6"
          style={{ fill: "#FDFCE3" }}
          width="83.4"
          height="62.8"
        />
        <rect
          id="H8"
          x="880.3"
          y="542.1"
          style={{ fill: "#FDFCE3" }}
          width="83.4"
          height="62.8"
        />
        <rect
          id="H9"
          x="880.3"
          y="605.2"
          style={{ fill: "#FDFCE3" }}
          width="83.4"
          height="62.8"
        />
        <rect
          id="H10"
          x="880.3"
          y="668.5"
          style={{ fill: "#FDFCE3" }}
          width="83.4"
          height="62.8"
        />
        <rect
          id="H11"
          x="668.9"
          y="880.7"
          style={{ fill: "#FDFCE3" }}
          width="62.8"
          height="83.4"
        />
        <rect
          id="H12"
          x="605.3"
          y="880.7"
          style={{ fill: "#FDFCE3" }}
          width="62.8"
          height="83.4"
        />
        <rect
          id="H13"
          x="542.4"
          y="880.7"
          style={{ fill: "#FDFCE3" }}
          width="62.8"
          height="83.4"
        />
        <rect
          id="H14"
          x="478.8"
          y="880.7"
          style={{ fill: "#FDFCE3" }}
          width="62.9"
          height="83.4"
        />
        <rect
          id="H15"
          x="415.9"
          y="880.7"
          style={{ fill: "#FDFCE3" }}
          width="62.9"
          height="83.4"
        />
        <rect
          id="H16"
          x="352.3"
          y="880.7"
          style={{ fill: "#FDFCE3" }}
          width="62.9"
          height="83.4"
        />
        <rect
          id="H17"
          x="289.3"
          y="880.7"
          style={{ fill: "#FDFCE3" }}
          width="62.9"
          height="83.4"
        />
        <rect
          id="H18"
          x="225.6"
          y="880.7"
          style={{ fill: "#FDFCE3" }}
          width="62.9"
          height="83.4"
        />
        <rect
          id="H19"
          x="162.6"
          y="880.7"
          style={{ fill: "#FDFCE3" }}
          width="62.9"
          height="83.4"
        />
        <polygon
          id="H20"
          style={{ fill: "#FDFCE3" }}
          points="161.8,964.2 81.2,964.2 82.7,919.2 86.5,880.8 161.8,880.8 "
        />
        <polygon
          id="H21"
          style={{ fill: "#FDFCE3" }}
          points="219.4,788.4 101.7,788.4 113.1,734.2 121.7,701.9 219.4,702 "
        />
        <rect
          id="H22"
          x="218.3"
          y="702"
          style={{ fill: "#FDFCE3" }}
          width="63.9"
          height="86.5"
        />
        <rect
          id="H23"
          x="282.4"
          y="702"
          style={{ fill: "#FDFCE3" }}
          width="64"
          height="86.5"
        />
        <rect
          id="H24"
          x="345.8"
          y="702"
          style={{ fill: "#FDFCE3" }}
          width="64"
          height="86.5"
        />
        <rect
          id="H25"
          x="408.7"
          y="702"
          style={{ fill: "#FDFCE3" }}
          width="63.4"
          height="86.5"
        />
        <rect
          id="H26"
          x="472.1"
          y="702"
          style={{ fill: "#FDFCE3" }}
          width="63.2"
          height="86.5"
        />
        <rect
          id="H27"
          x="535.4"
          y="702"
          style={{ fill: "#FDFCE3" }}
          width="64"
          height="86.5"
        />
        <rect
          id="H28"
          x="598.8"
          y="702"
          style={{ fill: "#FDFCE3" }}
          width="62.9"
          height="86.5"
        />
        <rect
          id="H29"
          x="661.7"
          y="702"
          style={{ fill: "#FDFCE3" }}
          width="63.1"
          height="86.5"
        />
        <rect
          id="H30"
          x="725.3"
          y="702"
          style={{ fill: "#FDFCE3" }}
          width="62.8"
          height="86.5"
        />
        <rect
          id="H31"
          x="725.3"
          y="619.1"
          style={{ fill: "#FDFCE3" }}
          width="62.8"
          height="82.8"
        />
        <rect
          id="H32"
          x="661.8"
          y="619.1"
          style={{ fill: "#FDFCE3" }}
          width="63.1"
          height="82.8"
        />
        <rect
          id="H33"
          x="598.8"
          y="619.1"
          style={{ fill: "#FDFCE3" }}
          width="62.9"
          height="82.8"
        />
        <rect
          id="H34"
          x="535.4"
          y="619.1"
          style={{ fill: "#FDFCE3" }}
          width="64"
          height="82.8"
        />
        <rect
          id="H35"
          x="472.2"
          y="619.1"
          style={{ fill: "#FDFCE3" }}
          width="63.2"
          height="82.8"
        />
        <rect
          id="H36"
          x="408.8"
          y="619.1"
          style={{ fill: "#FDFCE3" }}
          width="63.4"
          height="82.8"
        />
        <rect
          id="H37"
          x="345.9"
          y="619.1"
          style={{ fill: "#FDFCE3" }}
          width="64"
          height="82.8"
        />
        <rect
          id="H38"
          x="282.5"
          y="619.1"
          style={{ fill: "#FDFCE3" }}
          width="64"
          height="82.8"
        />
        <rect
          id="H39"
          x="218.3"
          y="619.1"
          style={{ fill: "#FDFCE3" }}
          width="64"
          height="82.8"
        />
        <polygon
          id="H40"
          style={{ fill: "#FDFCE3" }}
          points="218.3,701.9 121.7,701.9 137.7,657.4 153.3,619.1 218.3,619.1 "
        />
        <polygon
          id="H41"
          style={{ fill: "#FDFCE3" }}
          points="279.9,567.6 175.5,567.6 200.9,520.5 223.4,482.6 279.9,482.6 "
        />
        <rect
          id="H42"
          x="279.3"
          y="482.6"
          style={{ fill: "#FDFCE3" }}
          width="64.1"
          height="85"
        />
        <rect
          id="H43"
          x="343"
          y="482.6"
          style={{ fill: "#FDFCE3" }}
          width="64.1"
          height="85"
        />
        <rect
          id="H44"
          x="405.5"
          y="482.6"
          style={{ fill: "#FDFCE3" }}
          width="64.1"
          height="85"
        />
        <rect
          id="H45"
          x="468.9"
          y="482.6"
          style={{ fill: "#FDFCE3" }}
          width="64.1"
          height="85"
        />
        <rect
          id="H46"
          x="533"
          y="482.6"
          style={{ fill: "#FDFCE3" }}
          width="64.1"
          height="85"
        />
        <rect
          id="H47"
          x="595.5"
          y="482.6"
          style={{ fill: "#FDFCE3" }}
          width="64.1"
          height="85"
        />
        <rect
          id="H48"
          x="658.8"
          y="482.6"
          style={{ fill: "#FDFCE3" }}
          width="64.1"
          height="85"
        />
        <rect
          id="H49"
          x="722.3"
          y="482.6"
          style={{ fill: "#FDFCE3" }}
          width="64.1"
          height="85"
        />
        <rect
          id="H50"
          x="722.3"
          y="398.3"
          style={{ fill: "#FDFCE3" }}
          width="64.1"
          height="85"
        />
        <rect
          id="H51"
          x="658.8"
          y="398.3"
          style={{ fill: "#FDFCE3" }}
          width="64.1"
          height="85"
        />
        <rect
          id="H52"
          x="595.6"
          y="398.3"
          style={{ fill: "#FDFCE3" }}
          width="64.1"
          height="85"
        />
        <rect
          id="H53"
          x="533"
          y="398.3"
          style={{ fill: "#FDFCE3" }}
          width="64.1"
          height="85"
        />
        <rect
          id="H54"
          x="469"
          y="398.3"
          style={{ fill: "#FDFCE3" }}
          width="64.1"
          height="85"
        />
        <rect
          id="H55"
          x="405.5"
          y="398.3"
          style={{ fill: "#FDFCE3" }}
          width="64.1"
          height="85"
        />
        <rect
          id="H56"
          x="343"
          y="398.3"
          style={{ fill: "#FDFCE3" }}
          width="64.1"
          height="85"
        />
        <path
          id="H57"
          style={{ fill: "#FDFCE3" }}
          d="M342.4,483.3h-119c0,0,21.4-34.1,34.8-50.3c7.1-8.6,28.2-34.6,28.2-34.6h56.1V483.3z"
        />
        <path
          id="H58"
          style={{ fill: "#FDFCE3" }}
          d="M469.1,348.2h-137c0,0,44.5-45.1,68.5-63.1c21.7-17.4,68.5-51.6,68.5-51.6V348.2z"
        />
        <rect
          id="H59"
          x="469.5"
          y="263.2"
          style={{ fill: "#FDFCE3" }}
          width="63.1"
          height="85"
        />
        <rect
          id="H60"
          x="532.7"
          y="263.2"
          style={{ fill: "#FDFCE3" }}
          width="63.1"
          height="85"
        />
        <rect
          id="H61"
          x="596.1"
          y="263.2"
          style={{ fill: "#FDFCE3" }}
          width="63.1"
          height="85"
        />
        <rect
          id="H62"
          x="659.4"
          y="263.2"
          style={{ fill: "#FDFCE3" }}
          width="63.1"
          height="85"
        />
        <rect
          id="H63"
          x="722.3"
          y="263.2"
          style={{ fill: "#FDFCE3" }}
          width="63.1"
          height="85"
        />
        <rect
          id="H64"
          x="658.7"
          y="200.7"
          style={{ fill: "#FDFCE3" }}
          width="126.6"
          height="63.1"
        />
        <polygon
          id="H65"
          style={{ fill: "#FDFCE3" }}
          points="785.5,200.1 722.4,200.1 722.6,112.6 755.7,104.7 785.7,98.8 "
        />
        <polygon
          id="H66"
          style={{ fill: "#FDFCE3" }}
          points="722.4,200.1 659.4,200.1 659.4,133.1 690.9,122.3 722.4,113.2 "
        />
        <polygon
          id="H67"
          style={{ fill: "#FDFCE3" }}
          points="659.2,262.8 596.1,262.8 596.1,160 659.2,133.1 "
        />
        <path
          id="H68"
          style={{ fill: "#FDFCE3" }}
          d="M595.8,262.8h-127v-29.3c0,0,47.1-30.4,63.2-39.8c15.6-9,63.7-33.6,63.7-33.6L595.8,262.8z"
        />
        <g style={{ opacity: "0.69" }}>
          <g id="_1">
            <rect
              x="880.3"
              y="732.5"
              style={{ fill: "#139B48" }}
              width="83.7"
              height="7.1"
            />
            <rect x="963.2" y="80.4" width="1.3" height="652.5" />
            <path
              style={{
                fill: "none",
                stroke: "#000000",
                strokeWidth: 0.5,
                strokeMiterlimit: 10,
              }}
              d="M964,812.8h-83.2c-5.5,0-9.9-4.4-9.9-9.9v0V83c0-10.2,7.9-18.7,18.1-19.5c22.2-1.6,58.9-4.1,74.8-4.1"
            />
            <path
              style={{
                fill: "none",
                stroke: "#000000",
                strokeWidth: 0.5,
                strokeMiterlimit: 10,
              }}
              d="M964.2,790.9h-83.9V86.1c0,0,32.5-4.9,83.6-4.9"
            />
            <rect x="880.3" y="161.5" width="83.7" height="1.3" />
            <rect x="880.3" y="225" width="83.7" height="1.3" />
            <rect x="880.3" y="288.2" width="83.7" height="1.3" />
            <rect x="880.3" y="351.4" width="83.7" height="1.3" />
            <rect x="880.3" y="414.8" width="83.7" height="1.3" />
            <rect x="880.3" y="478" width="83.7" height="1.3" />
            <rect x="880.3" y="541.4" width="83.7" height="1.3" />
            <rect x="880.3" y="604.6" width="83.7" height="1.3" />
            <rect x="880.3" y="667.9" width="83.7" height="1.3" />
            <rect x="880.3" y="731.5" width="83.7" height="1.3" />
            <rect x="880.3" y="739.3" width="83.7" height="1.3" />
          </g>
          <g id="_1-2">
            <rect
              x="732.6"
              y="880.8"
              style={{ fill: "#139B48" }}
              width="7.1"
              height="83.8"
            />
            <path
              style={{
                fill: "none",
                stroke: "#000000",
                strokeWidth: 0.5,
                strokeMiterlimit: 10,
              }}
              d="M59.5,964.5c0-15.9,2.5-53.1,4.1-75.5c0.7-10.2,9.2-18.1,19.4-18.1h720c5.5,0,9.9,4.4,9.9,9.9v0v83.6"
            />
            <path
              style={{
                fill: "none",
                stroke: "#000000",
                strokeWidth: 0.5,
                strokeMiterlimit: 10,
              }}
              d="M790.9,880.7v82.8c0,0-628.3,0.6-709.7,1c0-28,1.6-56,4.9-83.8L790.9,880.7z"
            />
            <rect x="161.5" y="880.7" width="1.3" height="83.4" />
            <rect x="225" y="880.7" width="1.3" height="83.4" />
            <rect x="288.2" y="880.7" width="1.3" height="83.4" />
            <rect x="351.5" y="880.7" width="1.3" height="83.4" />
            <rect x="414.9" y="880.7" width="1.3" height="83.4" />
            <rect x="478" y="880.7" width="1.3" height="83.4" />
            <rect x="541.5" y="880.7" width="1.3" height="83.4" />
            <rect x="604.6" y="880.7" width="1.3" height="83.4" />
            <rect x="667.9" y="880.7" width="1.3" height="83.4" />
            <rect x="731.6" y="880.7" width="1.3" height="83.8" />
            <rect x="739.3" y="880.7" width="1.3" height="83.8" />
          </g>
          <g id="B.H3">
            <path
              style={{
                fill: "none",
                stroke: "#000000",
                strokeWidth: 0.5,
                strokeMiterlimit: 10,
              }}
              d="M94.5,795.9h693c5.4,0,9.7-4.4,9.7-9.7V618.1c0-5.4-4.4-9.7-9.7-9.7H149.8c-11.4,0-21.6,7.2-25.4,18    c-7.6,21.8-19.9,57.5-24.5,72.9c-4.8,16-14.7,53.6-20.7,76.9c-2.2,8.4,2.9,17.1,11.3,19.3C91.8,795.7,93.1,795.9,94.5,795.9z"
            />
            <path
              style={{
                fill: "none",
                stroke: "#000000",
                strokeWidth: 0.5,
                strokeMiterlimit: 10,
              }}
              d="M101.7,789h686.5V618.9H153.1c0,0-25,61.2-31.3,82C115.2,722.6,101.7,789,101.7,789z"
            />
            <rect x="724.7" y="618.7" width="1.3" height="170.2" />
            <rect x="661.3" y="618.7" width="1.3" height="170.2" />
            <rect x="598.2" y="618.8" width="1.3" height="170.2" />
            <rect x="534.8" y="618.8" width="1.3" height="170.2" />
            <rect x="471.3" y="618.8" width="1.3" height="170.2" />
            <rect x="408" y="618.8" width="1.3" height="170.2" />
            <rect x="345" y="618.9" width="1.3" height="170.2" />
            <rect x="281.8" y="618.9" width="1.3" height="170.2" />
            <rect x="218.3" y="618.9" width="1.3" height="170.2" />
            <rect x="121.7" y="701.2" width="667.2" height="1.3" />
          </g>
          <g id="B.H2">
            <path
              style={{
                fill: "none",
                stroke: "#000000",
                strokeWidth: 0.5,
                strokeMiterlimit: 10,
              }}
              d="M160,575.1h626.6c4.5,0,8.2-3.7,8.2-8.2c0,0,0,0,0,0v-169c0-4.5-3.7-8.2-8.2-8.2H280.4    c-10.4,0-20.1,5.3-25.7,14.1c0,0-38.8,50.5-50.3,68.3c-14.1,21.9-51,91-51,91c-2.3,3.7-1.2,8.5,2.5,10.8    C157.1,574.7,158.5,575.1,160,575.1z"
            />
            <path
              style={{
                fill: "none",
                stroke: "#000000",
                strokeWidth: 0.5,
                strokeMiterlimit: 10,
              }}
              d="M175.5,567.5h610.9V398.2H286.1c0,0-32.2,37-59.9,80.1C201.5,516.5,175.5,567.5,175.5,567.5z"
            />
            <rect x="722.3" y="398.3" width="1.3" height="169.1" />
            <rect x="658.8" y="398.3" width="1.3" height="169.1" />
            <rect x="595.6" y="398.3" width="1.3" height="169.1" />
            <rect x="532.4" y="398.3" width="1.3" height="169.1" />
            <rect x="469" y="398.3" width="1.3" height="169.1" />
            <rect x="405.9" y="398.3" width="1.3" height="169.1" />
            <rect x="342.4" y="398.3" width="1.3" height="169.1" />
            <rect x="279.3" y="482.7" width="1.3" height="84.7" />
            <polygon points="223.8,482 223.4,483.3 786.4,483.3 786.4,482   " />
          </g>
          <g id="B.H1">
            <path
              style={{
                fill: "none",
                stroke: "#000000",
                strokeWidth: 0.5,
                strokeMiterlimit: 10,
              }}
              d="M332.1,348.1h453.3V98.8C516.7,146,332.1,348.1,332.1,348.1z"
            />
            <path
              style={{
                fill: "none",
                stroke: "#000000",
                strokeWidth: 0.5,
                strokeMiterlimit: 10,
              }}
              d="M316,357.3h466.1c7.1,0,12.8-5.7,12.8-12.8V92.3c0-7.1-5.8-12.8-12.8-12.8c-0.8,0-1.7,0.1-2.5,0.2    c-236.7,46.7-412.9,198.4-472.5,255.5c-5.1,4.9-5.3,13-0.4,18.1C309.1,355.9,312.5,357.3,316,357.3z"
            />
            <rect x="721.9" y="112.6" width="1.3" height="88.2" />
            <rect x="659.7" y="199.5" width="126.2" height="1.3" />
            <rect x="658.7" y="133.1" width="1.3" height="214.7" />
            <rect x="721.9" y="263.7" width="1.3" height="84.1" />
            <rect x="469.5" y="262.8" width="316.1" height="1.3" />
            <polygon points="596.6,347.8 595.3,346.1 595.3,160 596.6,160   " />
            <polygon points="470.1,347.8 468.8,347.8 468.8,234.2 470.1,233.4   " />
            <polygon points="533.4,347.8 532,347.8 532,264 533.4,263.4   " />
          </g>
          <path
            style={{
              fill: "#129B48",
              stroke: "#000000",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M833,87.5L833,87.5c2.8,0,5.1,2.3,5.1,5.1v362.8c0,2.8-2.3,5.1-5.1,5.1h0c-2.8,0-5.1-2.3-5.1-5.1V92.6   C827.9,89.8,830.2,87.5,833,87.5z"
          />
          <path
            style={{
              fill: "#129B48",
              stroke: "#000000",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M833,500.7L833,500.7c2.8,0,5.1,2.3,5.1,5.1v268.7c0,2.8-2.3,5.1-5.1,5.1h0c-2.8,0-5.1-2.3-5.1-5.1V505.8   C827.9,503,830.2,500.7,833,500.7z"
          />
          <path
            style={{
              fill: "#129B48",
              stroke: "#000000",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M87.2,833.3L87.2,833.3c0-2.8,2.3-5.1,5.1-5.1h362.8c2.8,0,5.1,2.3,5.1,5.1v0c0,2.8-2.3,5.1-5.1,5.1H92.3   C89.5,838.4,87.2,836.1,87.2,833.3z"
          />
          <path
            style={{
              fill: "#129B48",
              stroke: "#000000",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M500.4,833.3L500.4,833.3c0-2.8,2.3-5.1,5.1-5.1h269c2.8,0,5.1,2.3,5.1,5.1v0c0,2.8-2.3,5.1-5.1,5.1h-269   C502.7,838.4,500.4,836.1,500.4,833.3z"
          />
          <line
            style={{
              fill: "none",
              stroke: "#000000",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            x1="161.5"
            y1="593.1"
            x2="794.8"
            y2="593.1"
          />
          <line
            style={{
              fill: "none",
              stroke: "#000000",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            x1="310.4"
            y1="376.5"
            x2="794.8"
            y2="376.5"
          />
        </g>
        <path d="M543,209.2c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.8,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2v-4.2h-6v4.2  c0,1.8,0.2,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.1-1.6,0.3-1.6,2v3.3h6v-3.3  c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V209.2z" />
        <path d="M546,222.6c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C547.3,221.9,546.8,222.6,546,222.6C546.1,222.6,546,222.6,546,222.6z" />
        <path d="M551.9,214.4c0.4-0.2,0.8-0.4,1.2-0.4c2,0,3.3,1.7,3.3,3.9c0,2.6-1.6,4.7-3.9,4.7s-3.9-2-3.9-5.3c-0.1-3.4,1.9-6.5,5.1-7.9  c0.7-0.2,1.5-0.4,2.2-0.6l0.1,0.7c-0.7,0.2-1.4,0.4-2,0.7c-1.8,1-3,2.8-3.3,4.8L551.9,214.4z M552.3,215.3c-0.6,0-1.2,0.2-1.7,0.5  c-0.1,0.5-0.1,1-0.1,1.5c0,2.7,0.8,4.6,2.3,4.6s1.8-1.7,1.8-3.1C554.6,216.9,553.8,215.3,552.3,215.3z" />
        <path d="M561.6,208.9c1.7,0,3,1.3,3.1,2.9c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.2-0.5,1.7c-0.5,0.5-1,0.9-1.5,1.3c1.3,0.8,2.5,1.8,2.5,3.6  c0,2.1-1.6,3.8-3.7,3.9c0,0-0.1,0-0.1,0c-1.9,0.1-3.5-1.3-3.6-3.1c0-0.1,0-0.3,0-0.4c0-0.8,0.3-1.5,0.8-2.1c0.5-0.5,1.1-0.9,1.7-1.3  c-1.2-0.7-2-1.9-2-3.3C558.2,210.6,559.6,209,561.6,208.9C561.5,208.9,561.6,208.9,561.6,208.9z M561.6,221.9c1.2,0,1.9-0.9,1.9-2.4  s-1.2-2.5-2.7-3.3c-0.9,0.6-1.5,1.7-1.4,2.8C559.4,220.9,560.4,221.9,561.6,221.9L561.6,221.9z M561.4,209.7c-1.1,0-1.6,1-1.6,2.1  c0,1.5,0.9,2.1,2.3,3c0.8-0.6,1.2-1.6,1.1-2.6C563.2,210.4,562.3,209.7,561.4,209.7L561.4,209.7z" />
        <path
          style={{ fill: "#136736" }}
          d="M520.2,246.3c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L520.2,246.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M527.1,240.3v1.2c0,1,0.1,1.1,1.2,1.2v0.5h-3.9v-0.5c1.2-0.1,1.3-0.2,1.3-1.3v-1.2h-4.2v-0.5  c1.5-2.2,3.3-4.6,4.8-6.7h0.8v6.4h1.5v0.8H527.1z M525.7,234.9c-1,1.4-2.1,3-3.2,4.6h3.2V234.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M534.5,238.6l-1,0.5c-0.3,0.2-0.7,0.3-1,0.3c-1.6,0-2.9-1.2-2.9-2.8c0-0.1,0-0.1,0-0.2c0-1.8,1.5-3.3,3.3-3.3  c0,0,0,0,0,0c2.1,0,3.3,1.8,3.3,4c0,2.7-1.6,5.1-4.1,6c-0.6,0.2-1.3,0.4-2,0.4L530,243C532.3,242.7,534.1,240.9,534.5,238.6z   M532.7,233.6c-1.2,0-1.6,1.1-1.6,2.3c0,1.4,0.8,2.6,2.1,2.6c0.5,0,0.9-0.1,1.3-0.4c0-0.4,0.1-0.7,0.1-1.1  C534.6,235.6,534.2,233.6,532.7,233.6L532.7,233.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M540.8,233.1c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S538.3,233.1,540.8,233.1z M540.8,233.6  c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S542.1,233.6,540.8,233.6L540.8,233.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M554,243.2v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L554,243.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M565.1,241c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1-1.2,1.9-2.5,1.9-3.8  s-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L565.1,241z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M566.6,231.5c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L566.6,231.5z"
        />
        <path d="M693.5,146.9c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.9,1.7,2v0.7h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2v-4.2h-6v4.2  c0,1.8,0.2,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2V149c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.1-1.6,0.3-1.6,2v3.3h6V149  c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V146.9z" />
        <path d="M696.4,160.3c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C697.7,159.6,697.2,160.2,696.4,160.3C696.5,160.3,696.4,160.3,696.4,160.3z" />
        <path d="M702.3,152.1c0.4-0.2,0.8-0.4,1.2-0.4c2,0,3.3,1.7,3.3,3.9c0,2.6-1.6,4.7-3.9,4.7s-3.9-2.1-3.9-5.3  c-0.1-3.4,1.9-6.5,5.1-7.9c0.7-0.2,1.5-0.4,2.2-0.6l0.1,0.7c-0.7,0.2-1.4,0.4-2,0.7c-1.8,1-3,2.8-3.3,4.8L702.3,152.1z M702.7,152.9  c-0.6,0-1.2,0.2-1.7,0.5c-0.1,0.5-0.1,1-0.1,1.5c0,2.7,0.8,4.6,2.3,4.6s1.8-1.7,1.8-3.1C705.1,154.6,704.3,153,702.7,152.9  L702.7,152.9z" />
        <path d="M711.3,152.1c0.4-0.2,0.8-0.4,1.2-0.4c2,0,3.3,1.7,3.3,3.9c0,2.6-1.6,4.7-3.9,4.7s-3.9-2.1-3.9-5.3  c-0.1-3.4,1.9-6.5,5.1-7.9c0.7-0.2,1.4-0.4,2.2-0.6l0.1,0.7c-0.7,0.2-1.4,0.4-2,0.7c-1.8,1-3,2.8-3.3,4.8L711.3,152.1z M711.7,152.9  c-0.6,0-1.2,0.2-1.7,0.5c-0.1,0.5-0.1,1-0.1,1.5c0,2.7,0.9,4.6,2.3,4.6s1.8-1.7,1.8-3.1C714.1,154.6,713.3,153,711.7,152.9  L711.7,152.9z" />
        <path
          style={{ fill: "#136736" }}
          d="M668.7,184c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L668.7,184z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M677,178.7c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8  c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L677,178.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M684.9,171.1c-1.5,3.2-3,6.5-4.6,9.8l-1.2,0.1l-0.1-0.2c1.7-2.8,3.3-5.7,4.7-8.7h-3.4c-1,0-1.1,0.1-1.6,1.2  h-0.5c0.2-1,0.3-1.8,0.3-2.5h6.3L684.9,171.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M689.2,170.7c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S686.8,170.7,689.2,170.7z M689.2,171.3  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S690.6,171.3,689.2,171.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M702.5,180.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5H698v-0.5c0.9-0.1,1.1-0.1,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.7-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L702.5,180.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M713.6,178.7c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8  c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L713.6,178.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M715.1,169.2c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L715.1,169.2z"
        />
        <path d="M753.8,146.9c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.9,1.7,2v0.7h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2v-4.2h-6v4.2  c0,1.8,0.2,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2V149c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.1-1.6,0.3-1.6,2v3.3h6V149  c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V146.9z" />
        <path d="M756.8,160.3c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0.1,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C758.1,159.6,757.6,160.2,756.8,160.3C756.8,160.3,756.8,160.3,756.8,160.3z" />
        <path d="M762.7,152.1c0.4-0.2,0.8-0.4,1.2-0.4c2,0,3.3,1.7,3.3,3.9c0,2.6-1.6,4.7-3.9,4.7s-3.9-2.1-3.9-5.3  c-0.1-3.4,1.9-6.5,5.1-7.9c0.7-0.2,1.5-0.4,2.2-0.6l0.1,0.7c-0.7,0.2-1.4,0.4-2,0.7c-1.8,1-3,2.8-3.3,4.8L762.7,152.1z M763.1,152.9  c-0.6,0-1.2,0.2-1.7,0.5c-0.1,0.5-0.1,1-0.1,1.5c0,2.7,0.8,4.6,2.3,4.6s1.8-1.7,1.8-3.1C765.5,154.6,764.7,153,763.1,152.9  L763.1,152.9z" />
        <path d="M775.4,146.9l0.1,0.2l-0.5,1.5h-4.3c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.4-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4  c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.8-2,1.4-3.2,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.2-0.2-0.4-0.5-0.3-0.7  c0-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2  c-0.6,0-1.3,0.1-1.8,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4H775.4z" />
        <path
          style={{ fill: "#136736" }}
          d="M731,184c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7c0,2.6,0.9,5.1,2.6,7  L731,184z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M732.9,172.7l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.3,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.6  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L732.9,172.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M740.7,172.7l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.3,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.6  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L740.7,172.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M751.6,170.7c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S749.1,170.7,751.6,170.7z M751.6,171.3  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S753,171.3,751.6,171.3L751.6,171.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M764.8,180.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.1,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5H756v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.7-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L764.8,180.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M775.9,178.7c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8  c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.2  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L775.9,178.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M777.5,169.2c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L777.5,169.2z"
        />
        <path d="M725.4,209.2c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.8,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2v-4.2h-6v4.2  c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.1-1.6,0.3-1.6,2v3.3h6v-3.3  c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V209.2z" />
        <path d="M728.4,222.6c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C729.7,221.9,729.1,222.6,728.4,222.6C728.4,222.6,728.4,222.6,728.4,222.6z" />
        <path d="M734.3,214.4c0.4-0.2,0.8-0.4,1.2-0.4c2,0,3.3,1.7,3.3,3.9c0,2.6-1.6,4.7-3.9,4.7s-3.9-2-3.9-5.3c-0.1-3.4,1.9-6.5,5.1-7.9  c0.7-0.2,1.4-0.4,2.2-0.6l0.1,0.7c-0.7,0.2-1.4,0.4-2,0.7c-1.8,1-3,2.8-3.3,4.8L734.3,214.4z M734.7,215.3c-0.6,0-1.2,0.2-1.7,0.5  c-0.1,0.5-0.1,1-0.1,1.5c0,2.7,0.9,4.6,2.3,4.6s1.8-1.7,1.8-3.1C737,216.9,736.3,215.3,734.7,215.3z" />
        <path d="M746.2,218.5v1.6c0,1.4,0.1,1.5,1.4,1.6v0.6h-4.5v-0.6c1.4-0.1,1.5-0.3,1.5-1.7v-1.5h-4.8v-0.7c1.8-2.9,3.7-6,5.5-8.9h0.9  v8.5h1.8v1.1H746.2z M744.6,211.3c-1.1,1.9-2.4,4-3.7,6.1h3.7V211.3z" />
        <path
          style={{ fill: "#136736" }}
          d="M702.6,246.3c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L702.6,246.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M709.5,240.3v1.2c0,1,0.1,1.1,1.2,1.2v0.5h-3.9v-0.5c1.2-0.1,1.3-0.2,1.3-1.3v-1.2h-4.2v-0.5  c1.5-2.2,3.3-4.6,4.8-6.7h0.8v6.4h1.6v0.8H709.5z M708.1,234.9c-1,1.4-2.1,3-3.2,4.6h3.2V234.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M718,233.2l0.1,0.1l-0.4,1.1H714c-0.1,0.8-0.3,2.1-0.5,2.8c0.6-0.2,1.3-0.4,1.9-0.5c1.5-0.1,2.9,1,3,2.6  c0,0,0,0.1,0,0.1c0,1.1-0.5,2.1-1.4,2.7c-0.8,0.7-1.8,1.1-2.9,1.1c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.2-0.3-0.4-0.3-0.6  c0-0.2,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.6,1.2,0.9,2,0.9c1.3,0,1.9-1.1,1.9-2.3c0.1-1.3-0.9-2.3-2.1-2.4  c-0.1,0-0.3,0-0.4,0c-0.5,0-1.1,0.1-1.6,0.3l-0.1-0.1c0.3-1.3,0.6-3.5,0.8-4.8L718,233.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M723.2,233.1c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S720.7,233.1,723.2,233.1z M723.2,233.6  c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S724.5,233.6,723.2,233.6L723.2,233.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M736.4,243.2v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5H732v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L736.4,243.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M747.5,241c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1-1.2,1.9-2.5,1.9-3.8  s-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L747.5,241z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M749,231.5c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L749,231.5z"
        />
        <path d="M628.2,209.2c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.8,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2v-4.2h-6v4.2  c0,1.8,0.2,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.1-1.6,0.3-1.6,2v3.3h6v-3.3  c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V209.2z" />
        <path d="M631.2,222.6c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C632.5,221.9,632,222.6,631.2,222.6C631.2,222.6,631.2,222.6,631.2,222.6z" />
        <path d="M637.1,214.4c0.4-0.2,0.8-0.4,1.2-0.4c2,0,3.3,1.7,3.3,3.9c0,2.6-1.6,4.7-3.9,4.7s-3.9-2-3.9-5.3c-0.1-3.4,1.9-6.5,5.1-7.9  c0.7-0.2,1.4-0.4,2.2-0.6l0.1,0.7c-0.7,0.2-1.4,0.4-2,0.7c-1.8,1-3,2.8-3.3,4.8L637.1,214.4z M637.5,215.3c-0.6,0-1.2,0.2-1.7,0.5  c-0.1,0.5-0.1,1-0.1,1.5c0,2.7,0.8,4.6,2.3,4.6s1.8-1.7,1.8-3.1C639.8,216.9,639.1,215.3,637.5,215.3z" />
        <path d="M650.7,209.5c-1.8,4.3-3.5,8.6-5.3,12.9l-1.4,0.2l-0.1-0.2c1.9-3.6,3.9-7.9,5.4-11.4h-3.9c-1.2,0-1.3,0.2-1.8,1.6H643  c0.2-1.3,0.3-2.3,0.4-3.3h7.2L650.7,209.5z" />
        <path
          style={{ fill: "#136736" }}
          d="M605.4,246.3c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L605.4,246.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M612.3,240.3v1.2c0,1,0.1,1.1,1.2,1.2v0.5h-3.9v-0.5c1.2-0.1,1.3-0.2,1.3-1.3v-1.2h-4.2v-0.5  c1.6-2.2,3.3-4.6,4.8-6.7h0.8v6.4h1.6v0.8H612.3z M610.9,234.9c-1,1.4-2.1,3-3.2,4.6h3.2V234.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M615.7,243.2v-0.5c1.8-0.1,1.9-0.2,1.9-1.4v-6.1c0-0.8-0.2-0.9-0.8-0.9l-0.7-0.1v-0.4c1-0.2,2-0.4,3-0.8v8.3  c0,1.2,0.1,1.3,1.9,1.4v0.5L615.7,243.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M626,233.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S623.5,233.1,626,233.1z M626,233.6  c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S627.3,233.6,626,233.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M639.2,243.2v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L639.2,243.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M650.3,241c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8  c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L650.3,241z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M651.8,231.5c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c3.5-4,3.5-10,0-14L651.8,231.5z"
        />
        <path d="M628.2,290c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.9,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2v-4.2h-6v4.2  c0,1.8,0.2,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2V292c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.7c-1.5,0.1-1.6,0.3-1.6,2v3.3h6V292  c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V290z" />
        <path d="M631.2,303.4c-0.7,0-1.2-0.6-1.2-1.3c0,0,0-0.1,0-0.1c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0,0c0.7,0,1.3,0.7,1.2,1.4  c0,0,0,0,0,0C632.5,302.6,632,303.3,631.2,303.4C631.2,303.3,631.2,303.3,631.2,303.4z" />
        <path d="M637.1,295.1c0.4-0.2,0.8-0.3,1.2-0.4c2,0,3.3,1.7,3.3,3.9c0,2.6-1.6,4.7-3.9,4.7s-3.9-2-3.9-5.3c-0.1-3.4,1.9-6.5,5.1-7.9  c0.7-0.2,1.4-0.4,2.2-0.6l0.1,0.7c-0.7,0.2-1.4,0.4-2,0.7c-1.8,1-3,2.8-3.3,4.8L637.1,295.1z M637.5,296c-0.6,0-1.2,0.2-1.7,0.5  c-0.1,0.5-0.1,1-0.1,1.5c0,2.7,0.8,4.5,2.3,4.5s1.8-1.7,1.8-3.1C639.8,297.6,639.1,296,637.5,296L637.5,296z" />
        <path d="M643.8,303.1v-0.6c2-0.1,2.1-0.3,2.1-1.8v-8c0-1.1-0.2-1.1-1-1.2l-0.8-0.1v-0.6c1.2-0.2,2.4-0.5,3.5-1v11  c0,1.6,0.1,1.7,2.1,1.8v0.6L643.8,303.1z" />
        <path
          style={{ fill: "#136736" }}
          d="M605.4,327c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L605.4,327z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M607.3,315.7l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.8,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.3,0.3-0.7,0.6-1.1,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c-0.1,0-0.1,0-0.2,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L607.3,315.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M618.2,313.8c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S615.7,313.8,618.2,313.8z M618.2,314.3  c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S619.5,314.3,618.2,314.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M626,313.8c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S623.5,313.8,626,313.8z M626,314.3  c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S627.3,314.3,626,314.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M639.2,323.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L639.2,323.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M650.3,321.7c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8  c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L650.3,321.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M651.8,312.2c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c3.5-4,3.5-10,0-14L651.8,312.2z"
        />
        <path d="M690.8,290c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.9,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2v-4.2h-6v4.2  c0,1.8,0.2,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2V292c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.7c-1.5,0.1-1.6,0.3-1.6,2v3.3h6V292  c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V290z" />
        <path d="M693.8,303.4c-0.7,0-1.2-0.6-1.2-1.3c0,0,0-0.1,0-0.1c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4  c0,0,0,0,0,0C695.1,302.6,694.6,303.3,693.8,303.4C693.9,303.3,693.8,303.3,693.8,303.4z" />
        <path d="M699.7,295.1c0.4-0.2,0.8-0.3,1.2-0.4c2,0,3.3,1.7,3.3,3.9c0,2.6-1.6,4.7-3.9,4.7s-3.9-2-3.9-5.3c-0.1-3.4,1.9-6.6,5.1-7.9  c0.7-0.2,1.4-0.4,2.2-0.6l0.1,0.7c-0.7,0.2-1.4,0.4-2,0.7c-1.8,1-3,2.8-3.3,4.8L699.7,295.1z M700.1,296c-0.6,0-1.2,0.2-1.7,0.5  c-0.1,0.5-0.1,1-0.1,1.5c0,2.7,0.9,4.5,2.3,4.5s1.8-1.7,1.8-3.1C702.5,297.6,701.7,296,700.1,296L700.1,296z" />
        <path d="M713.2,300.1c-0.2,1-0.6,2.1-0.8,2.9h-7v-0.5c1-1.1,2.1-2.4,3-3.6c1.2-1.6,2.2-3.3,2.2-5.1s-0.8-2.7-2.1-2.7s-2,1.1-2.5,2  l-0.5-0.5l1.1-1.8c0.6-0.8,1.6-1.3,2.6-1.3c1.8,0,3.2,1.4,3.2,3.7c0,1.8-0.6,3-2.3,5.1c-0.8,0.9-1.9,2.2-2.7,3h3.4  c1,0,1.3-0.1,1.9-1.4L713.2,300.1z" />
        <path
          style={{ fill: "#136736" }}
          d="M668,327c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L668,327z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M669.9,315.7l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c-0.1,0-0.1,0-0.2,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L669.9,315.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M680.8,313.8c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S678.3,313.8,680.8,313.8z M680.8,314.3  c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S682.1,314.3,680.8,314.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M688.6,313.8c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S686.1,313.8,688.6,313.8z M688.6,314.3  c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S690,314.3,688.6,314.3L688.6,314.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M701.8,323.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.4v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5H693v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L701.8,323.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M712.9,321.7c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1-1.2,1.9-2.5,1.9-3.8  s-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L712.9,321.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M714.5,312.2c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L714.5,312.2z"
        />
        <path d="M753.8,290c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.9,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2v-4.2h-6v4.2  c0,1.8,0.2,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2V292c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.7c-1.5,0.1-1.6,0.3-1.6,2v3.3h6V292  c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V290z" />
        <path d="M756.8,303.4c-0.7,0-1.2-0.6-1.2-1.3c0,0,0-0.1,0-0.1c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4  c0,0,0,0,0,0C758.1,302.6,757.6,303.3,756.8,303.4C756.9,303.3,756.8,303.3,756.8,303.4z" />
        <path d="M762.7,295.1c0.4-0.2,0.8-0.3,1.2-0.4c2,0,3.3,1.7,3.3,3.9c0,2.6-1.6,4.7-3.9,4.7s-3.9-2-3.9-5.3c-0.1-3.4,1.9-6.6,5.1-7.9  c0.7-0.2,1.4-0.4,2.2-0.6l0.1,0.7c-0.7,0.2-1.4,0.4-2,0.7c-1.8,1-3,2.8-3.3,4.8L762.7,295.1z M763.1,296c-0.6,0-1.2,0.2-1.7,0.5  c-0.1,0.5-0.1,1-0.1,1.5c0,2.7,0.9,4.5,2.3,4.5s1.8-1.7,1.8-3.1C765.5,297.6,764.7,296,763.1,296L763.1,296z" />
        <path d="M768.8,292.2l0.9-1.3c0.6-0.8,1.5-1.2,2.4-1.2c1.5-0.1,2.8,1.1,2.8,2.6c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.1-0.6,1.6  c-0.4,0.4-0.8,0.8-1.3,1.1c1.6,0.3,2.7,1.6,2.8,3.3c0,3.4-3.6,4.9-4.9,4.9c-0.7,0-1.4-0.3-1.9-0.7c-0.2-0.2-0.3-0.5-0.3-0.7  c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.3,0,2.2-1.1,2.2-3.1c0.2-1.3-0.8-2.5-2.1-2.7  c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.2,0.2l-0.1-0.6c1.6-0.6,2.9-1.4,2.9-3c0.2-0.9-0.5-1.8-1.4-2c-0.1,0-0.2,0-0.3,0  c-1,0.1-1.9,0.7-2.3,1.6L768.8,292.2z" />
        <path
          style={{ fill: "#136736" }}
          d="M731,327c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L731,327z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M732.9,315.7l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c-0.1,0-0.1,0-0.2,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L732.9,315.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M743.8,313.8c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S741.3,313.8,743.8,313.8z M743.8,314.3  c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S745.1,314.3,743.8,314.3L743.8,314.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M751.6,313.8c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S749.1,313.8,751.6,313.8z M751.6,314.3  c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S753,314.3,751.6,314.3L751.6,314.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M764.8,323.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5H756v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L764.8,323.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M775.9,321.7c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1-1.2,1.9-2.5,1.9-3.8  s-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.2  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L775.9,321.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M777.5,312.2c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L777.5,312.2z"
        />
        <path d="M564.6,290c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.9,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2v-4.2h-6v4.2  c0,1.8,0.2,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2V292c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.7c-1.5,0.1-1.6,0.3-1.6,2v3.3h6V292  c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V290z" />
        <path d="M567.5,303.4c-0.7,0-1.2-0.6-1.2-1.3c0,0,0-0.1,0-0.1c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4  c0,0,0,0,0,0C568.8,302.6,568.3,303.3,567.5,303.4C567.6,303.3,567.6,303.3,567.5,303.4z" />
        <path d="M573.5,295.1c0.4-0.2,0.8-0.3,1.2-0.4c2,0,3.3,1.7,3.3,3.9c0,2.6-1.6,4.7-3.9,4.7s-3.9-2-3.9-5.3c-0.1-3.4,1.9-6.6,5.1-7.9  c0.7-0.2,1.4-0.4,2.2-0.6l0.1,0.7c-0.7,0.2-1.4,0.4-2,0.7c-1.8,1-3,2.8-3.3,4.8L573.5,295.1z M573.8,296c-0.6,0-1.2,0.2-1.7,0.5  c-0.1,0.5-0.1,1-0.1,1.5c0,2.7,0.9,4.5,2.3,4.5s1.8-1.7,1.8-3.1C576.2,297.6,575.4,296,573.8,296L573.8,296z" />
        <path d="M583,289.6c2.8,0,3.9,3.6,3.9,6.8s-1,6.9-3.9,6.9s-3.9-3.5-3.9-6.8C579.2,293.4,580.2,289.6,583,289.6z M583,290.4  c-1.6,0-2,3.1-2,6s0.5,6.1,2,6.1s2-3.1,2-6.1S584.6,290.4,583,290.4z" />
        <path
          style={{ fill: "#136736" }}
          d="M541.8,327c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L541.8,327z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M543.6,315.7l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c-0.1,0-0.1,0-0.2,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L543.6,315.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M554.5,313.8c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S552,313.8,554.5,313.8z M554.5,314.3  c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S555.9,314.3,554.5,314.3L554.5,314.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M562.3,313.8c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.3-2.7-3.3-5.1S559.9,313.8,562.3,313.8z M562.3,314.3  c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S563.7,314.3,562.3,314.3L562.3,314.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M575.5,323.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L575.5,323.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M586.7,321.7c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1-1.2,1.9-2.5,1.9-3.8  s-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L586.7,321.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M588.2,312.2c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L588.2,312.2z"
        />
        <path d="M499.8,290c-1.5,0.2-1.6,0.3-1.6,2v8.4c0,1.8,0.2,1.9,1.6,2v0.6h-5.1v-0.6c1.5-0.2,1.6-0.3,1.6-2v-4.2h-6v4.2  c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2V292c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.7c-1.5,0.1-1.6,0.3-1.6,2v3.3h6V292  c0-1.8-0.2-1.9-1.6-2v-0.6h5.1V290z" />
        <path d="M502.8,303.4c-0.7,0-1.2-0.6-1.2-1.3c0,0,0-0.1,0-0.1c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4  c0,0,0,0,0,0C504.1,302.6,503.6,303.3,502.8,303.4C502.8,303.3,502.8,303.3,502.8,303.4z" />
        <path d="M512.4,289.9l0.1,0.2l-0.5,1.5h-4.3c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.5-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4  c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.9-2.1,1.4-3.3,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.2-0.2-0.4-0.5-0.3-0.7  c0-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.2,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2  c-0.6,0-1.3,0.1-1.9,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4L512.4,289.9z" />
        <path d="M520.1,297l-1.2,0.7c-0.3,0.2-0.7,0.3-1.1,0.3c-2,0-3.3-1.8-3.3-4c0-2,1.4-4.4,3.8-4.4s3.8,2.4,3.8,5.3  c0.1,3.3-1.7,6.4-4.7,7.9c-0.7,0.3-1.5,0.5-2.3,0.6l-0.2-0.6C517.6,302.1,519.5,300.1,520.1,297z M518.1,290.4c-1.4,0-1.8,1.5-1.8,3  c0,1.9,0.9,3.4,2.4,3.4c0.6,0,1.1-0.2,1.5-0.5c0.1-0.5,0.1-0.9,0.1-1.4C520.2,292.9,519.8,290.4,518.1,290.4L518.1,290.4z" />
        <path
          style={{ fill: "#136736" }}
          d="M477,327c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L477,327z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M478.9,315.7l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c-0.1,0-0.1,0-0.2,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L478.9,315.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M489.8,313.8c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S487.3,313.8,489.8,313.8z M489.8,314.3  c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S491.1,314.3,489.8,314.3L489.8,314.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M497.6,313.8c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S495.1,313.8,497.6,313.8z M497.6,314.3  c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S499,314.3,497.6,314.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M510.8,323.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5H502v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.7-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L510.8,323.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M521.9,321.7c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.7-2.4,1.9-3.8  c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L521.9,321.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M523.4,312.2c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L523.4,312.2z"
        />
        <path d="M424.7,290c-1.5,0.2-1.6,0.3-1.6,2v8.4c0,1.8,0.2,1.9,1.6,2v0.6h-5.1v-0.6c1.5-0.2,1.6-0.3,1.6-2v-4.2h-6v4.2  c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2V292c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.7c-1.5,0.1-1.6,0.3-1.6,2v3.3h6V292  c0-1.8-0.2-1.9-1.6-2v-0.6h5.1V290z" />
        <path d="M427.6,303.4c-0.7,0-1.2-0.6-1.2-1.3c0,0,0-0.1,0-0.1c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4  c0,0,0,0,0,0C428.9,302.6,428.4,303.3,427.6,303.4C427.7,303.3,427.7,303.3,427.6,303.4z" />
        <path d="M437.3,289.9l0.1,0.2l-0.5,1.5h-4.3c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.4-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4  c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.9-2.1,1.4-3.3,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.2-0.2-0.4-0.5-0.3-0.7  c0-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.2,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2  c-0.6,0-1.3,0.1-1.9,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4L437.3,289.9z" />
        <path d="M443.3,289.6c1.7,0,3,1.3,3.1,2.9c0,0.1,0,0.1,0,0.2c0,0.6-0.2,1.2-0.5,1.7c-0.5,0.5-1,0.9-1.5,1.3c1.3,0.8,2.5,1.8,2.5,3.6  c0,2.1-1.6,3.8-3.7,3.9c0,0,0,0-0.1,0c-1.9,0.1-3.5-1.3-3.6-3.1c0-0.1,0-0.3,0-0.4c0-0.8,0.3-1.5,0.8-2.1c0.5-0.5,1.1-0.9,1.6-1.3  c-1.2-0.7-2-1.9-2-3.3c-0.1-1.9,1.4-3.4,3.3-3.5C443.2,289.6,443.3,289.6,443.3,289.6z M443.3,302.6c1.2,0,1.9-0.9,1.9-2.4  c0-1.7-1.2-2.5-2.7-3.3c-0.9,0.6-1.5,1.7-1.4,2.8C441.1,301.6,442.1,302.6,443.3,302.6L443.3,302.6z M443.1,290.4  c-1.1,0-1.6,1-1.6,2.1c0,1.5,0.9,2.1,2.3,3c0.8-0.6,1.2-1.6,1.1-2.5C444.9,291.1,444,290.4,443.1,290.4L443.1,290.4z" />
        <path
          style={{ fill: "#136736" }}
          d="M401.9,327c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L401.9,327z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M408.8,321v1.2c0,1,0.1,1.1,1.2,1.2v0.5h-3.9v-0.5c1.2-0.1,1.3-0.2,1.3-1.3V321h-4.2v-0.5  c1.6-2.2,3.3-4.6,4.8-6.7h0.8v6.4h1.6v0.8H408.8z M407.4,315.6c-1,1.4-2.1,3-3.2,4.6h3.2V315.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M414.8,313.8c1.4-0.1,2.6,0.9,2.7,2.3c0,0,0,0.1,0,0.1c0,0.5-0.2,0.9-0.5,1.3c-0.4,0.4-0.8,0.7-1.3,1  c1.1,0.6,2.2,1.4,2.2,2.8c-0.1,1.7-1.6,3-3.3,2.9c0,0,0,0,0,0c-1.8,0-3.2-1-3.2-2.7c0-0.6,0.3-1.2,0.7-1.6c0.5-0.4,0.9-0.7,1.4-1  c-1-0.4-1.7-1.4-1.8-2.5C411.9,314.9,413.1,313.7,414.8,313.8C414.7,313.8,414.7,313.8,414.8,313.8z M414.8,323.6  c0.9,0,1.6-0.6,1.7-1.5c0-0.1,0-0.2,0-0.3c0-1.3-1-1.9-2.4-2.5c-0.8,0.4-1.3,1.2-1.3,2.1c-0.1,1.1,0.7,2.1,1.8,2.2  C414.6,323.5,414.7,323.5,414.8,323.6L414.8,323.6z M414.5,314.3c-0.8,0-1.4,0.7-1.4,1.4c0,0.1,0,0.1,0,0.2c0,1.1,0.8,1.6,2,2.2  c0.6-0.4,1-1.2,1-1.9C416.1,314.9,415.3,314.3,414.5,314.3L414.5,314.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M422.5,313.8c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S420,313.8,422.5,313.8z M422.5,314.3  c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S423.8,314.3,422.5,314.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M435.7,323.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.8-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L435.7,323.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M446.8,321.7c-0.2,0.8-0.5,1.6-0.6,2.2H440v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.7-2.4,1.9-3.8  c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L446.8,321.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M448.3,312.2c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c3.5-4,3.5-10,0-14L448.3,312.2z"
        />
        <path d="M753.8,422.9c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.9,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2v-4.2h-6v4.2  c0,1.8,0.2,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2V425c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.1-1.6,0.3-1.6,2v3.3h6V425  c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V422.9z" />
        <path d="M756.8,436.3c-0.7,0-1.2-0.6-1.2-1.3c0,0,0-0.1,0-0.1c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4  c0,0,0,0,0,0C758.1,435.6,757.6,436.2,756.8,436.3C756.9,436.3,756.8,436.3,756.8,436.3z" />
        <path d="M766.4,422.9l0.1,0.2l-0.5,1.5h-4.3c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.5-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4  c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.9-2.1,1.4-3.3,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.2-0.2-0.4-0.5-0.3-0.7  c0-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2  c-0.6,0-1.3,0.1-1.8,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4L766.4,422.9z" />
        <path d="M772.3,422.6c2.8,0,3.8,3.6,3.8,6.8s-1,6.9-3.8,6.9s-3.9-3.5-3.9-6.8C768.4,426.4,769.5,422.6,772.3,422.6z M772.3,423.4  c-1.6,0-2,3.1-2,6s0.5,6.1,2,6.1s2-3.1,2-6.1S773.9,423.4,772.3,423.4L772.3,423.4z" />
        <path
          style={{ fill: "#136736" }}
          d="M731,460c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7c0,2.6,0.9,5.1,2.6,7  L731,460z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M732.9,448.7l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.5  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L732.9,448.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M743.8,446.7c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S741.3,446.7,743.8,446.7z M743.8,447.3  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S745.1,447.3,743.8,447.3L743.8,447.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M751.6,446.7c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S749.1,446.7,751.6,446.7z M751.6,447.3  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S753,447.3,751.6,447.3L751.6,447.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M764.8,456.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5H756v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.7-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H764.8z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M775.9,454.7c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8  c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L775.9,454.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M777.5,445.1c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L777.5,445.1z"
        />
        <path d="M753.8,508.8c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.8,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2V515h-6v4.2  c0,1.8,0.2,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.1-1.6,0.3-1.6,2v3.3h6v-3.3  c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V508.8z" />
        <path d="M756.8,522.2c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C758.1,521.4,757.6,522.1,756.8,522.2C756.9,522.1,756.8,522.1,756.8,522.2z" />
        <path d="M765.6,518v1.5c0,1.4,0.1,1.5,1.4,1.6v0.6h-4.5v-0.6c1.4-0.2,1.5-0.3,1.5-1.7V518h-4.8v-0.7c1.8-2.9,3.7-6,5.5-8.9h0.9v8.5  h1.8v1L765.6,518z M764,510.9c-1.1,1.8-2.4,4-3.7,6.1h3.7V510.9z" />
        <path d="M774.1,515.8l-1.2,0.7c-0.3,0.2-0.7,0.3-1.1,0.3c-2,0-3.3-1.7-3.3-4c0-2,1.4-4.4,3.8-4.4s3.8,2.4,3.8,5.3  c0.1,3.3-1.7,6.4-4.7,7.9c-0.7,0.3-1.5,0.5-2.3,0.6l-0.1-0.6C771.7,520.9,773.5,519,774.1,515.8z M772.1,509.2c-1.3,0-1.8,1.5-1.8,3  c0,1.9,0.9,3.4,2.4,3.4c0.6,0,1.1-0.2,1.5-0.5c0-0.5,0.1-1,0.1-1.4C774.3,511.7,773.8,509.2,772.1,509.2L772.1,509.2z" />
        <path
          style={{ fill: "#136736" }}
          d="M731,545.8c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c0,2.6,0.9,5.1,2.6,7L731,545.8z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M732.9,534.5l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.3,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.6  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L732.9,534.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M743.8,532.6c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S741.3,532.6,743.8,532.6z M743.8,533.2  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S745.1,533.2,743.8,533.2L743.8,533.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M751.6,532.6c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S749.1,532.6,751.6,532.6z M751.6,533.2  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S753,533.2,751.6,533.2L751.6,533.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M764.8,542.7v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5H756v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.7-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.2,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H764.8z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M775.9,540.5c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8  c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.2  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L775.9,540.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M777.5,531c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L777.5,531z"
        />
        <path d="M754.5,644.3c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.8,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2v-4.2h-6v4.2  c0,1.8,0.2,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.2-1.6,0.3-1.6,2v3.3h6v-3.3  c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V644.3z" />
        <path d="M757.5,657.7c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C758.8,657,758.3,657.6,757.5,657.7C757.6,657.7,757.5,657.7,757.5,657.7z" />
        <path d="M760.5,646.6l0.9-1.3c0.6-0.8,1.5-1.2,2.4-1.2c1.5-0.1,2.8,1.1,2.8,2.6c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.1-0.6,1.5  c-0.4,0.4-0.8,0.8-1.3,1.1c1.6,0.3,2.7,1.6,2.8,3.3c0,3.4-3.6,4.9-4.9,4.9c-0.7,0-1.4-0.3-1.9-0.7c-0.2-0.2-0.3-0.4-0.3-0.7  c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.3-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.3,0,2.2-1.1,2.2-3.1c0.2-1.3-0.8-2.5-2.1-2.7  c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.2,0.2l-0.1-0.7c1.7-0.6,2.9-1.4,2.9-3c0-1.3-0.7-2-1.7-2c-1,0.1-1.9,0.7-2.3,1.7L760.5,646.6z  " />
        <path d="M770.2,657.4v-0.6c2-0.2,2.1-0.3,2.1-1.8v-8c0-1.1-0.2-1.1-1-1.2l-0.8-0.1v-0.6c1.2-0.2,2.4-0.5,3.5-1v11  c0,1.6,0.1,1.7,2.1,1.8v0.6L770.2,657.4z" />
        <path
          style={{ fill: "#136736" }}
          d="M731.7,681.4c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c0,2.6,0.9,5.1,2.6,7L731.7,681.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M733.6,670.1l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0  c-0.3,0-0.7,0.1-1,0.2L735,673c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L733.6,670.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M744.5,668.1c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S742,668.1,744.5,668.1z M744.5,668.7  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S745.8,668.7,744.5,668.7L744.5,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M752.3,668.1c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S749.8,668.1,752.3,668.1z M752.3,668.7  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S753.7,668.7,752.3,668.7L752.3,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M765.5,678.3v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.4v-0.5c1-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H765.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M776.7,676.1c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.8,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8  c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L776.7,676.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M778.2,666.6c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L778.2,666.6z"
        />
        <path d="M692.7,644.3c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.8,1.7,2v0.6h-5v-0.6c1.5-0.2,1.7-0.3,1.7-2v-4.2h-6v4.2  c0,1.8,0.2,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.2-1.6,0.3-1.6,2v3.3h6v-3.3  c0-1.8-0.2-1.9-1.7-2v-0.6h5V644.3z" />
        <path d="M695.7,657.7c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C697,657,696.4,657.6,695.7,657.7C695.7,657.7,695.7,657.7,695.7,657.7z" />
        <path d="M698.7,646.6l0.9-1.3c0.6-0.8,1.5-1.2,2.4-1.3c1.5-0.1,2.8,1.1,2.8,2.6c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.1-0.6,1.5  c-0.4,0.4-0.8,0.8-1.3,1.1c1.6,0.3,2.7,1.6,2.8,3.3c0,3.4-3.6,4.9-4.9,4.9c-0.7,0-1.4-0.3-1.9-0.7c-0.2-0.2-0.3-0.4-0.3-0.7  c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.3-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.3,0,2.2-1.1,2.2-3.1c0.2-1.3-0.8-2.5-2.1-2.7  c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.2,0.2l-0.1-0.7c1.7-0.6,2.9-1.4,2.9-3c0.2-0.9-0.5-1.8-1.4-2c-0.1,0-0.2,0-0.3,0  c-1,0.1-1.9,0.7-2.3,1.7L698.7,646.6z" />
        <path d="M715,654.5c-0.2,1-0.6,2.1-0.8,2.9h-7v-0.5c1-1.1,2.1-2.4,3-3.6c1.2-1.6,2.2-3.3,2.2-5s-0.8-2.7-2.1-2.7s-2,1.1-2.5,2  l-0.5-0.5l1.1-1.8c0.6-0.8,1.6-1.3,2.6-1.3c1.8,0,3.2,1.4,3.2,3.7c0,1.8-0.6,3-2.3,5.1c-0.8,0.9-1.9,2.2-2.7,3h3.4  c1,0,1.3-0.1,1.9-1.4L715,654.5z" />
        <path
          style={{ fill: "#136736" }}
          d="M669.9,681.4c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c0,2.6,0.9,5.1,2.6,7L669.9,681.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M671.8,670.1l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.8,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L671.8,670.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M682.7,668.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S680.2,668.1,682.7,668.1z M682.7,668.7  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S684,668.7,682.7,668.7L682.7,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M690.5,668.1c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S688,668.1,690.5,668.1z M690.5,668.7  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S691.8,668.7,690.5,668.7L690.5,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M703.7,678.3v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H703.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M714.8,676.1c-0.2,0.7-0.5,1.6-0.7,2.2H708v-0.4c0.9-0.8,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8  c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L714.8,676.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M716.3,666.6c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8L716,681c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L716.3,666.6z"
        />
        <path d="M626.4,644.3c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.8,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2v-4.2h-6v4.2  c0,1.8,0.2,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.2-1.6,0.3-1.6,2v3.3h6v-3.3  c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V644.3z" />
        <path d="M629.4,657.7c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C630.7,657,630.2,657.6,629.4,657.7C629.4,657.7,629.4,657.7,629.4,657.7z" />
        <path d="M632.4,646.6l0.9-1.3c0.6-0.8,1.5-1.2,2.4-1.3c1.5-0.1,2.8,1.1,2.8,2.6c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.1-0.6,1.5  c-0.4,0.4-0.8,0.8-1.3,1.1c1.6,0.3,2.7,1.6,2.8,3.3c0,3.4-3.6,4.9-4.9,4.9c-0.7,0-1.4-0.3-1.9-0.7c-0.2-0.2-0.3-0.4-0.3-0.7  c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.3-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.3,0,2.2-1.1,2.2-3.1c0.2-1.3-0.8-2.5-2.1-2.7  c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.2,0.2l-0.1-0.7c1.6-0.6,2.9-1.4,2.9-3c0.2-0.9-0.5-1.8-1.4-2c-0.1,0-0.2,0-0.3,0  c-1,0.1-1.9,0.7-2.3,1.7L632.4,646.6z" />
        <path d="M641.4,646.6l0.9-1.3c0.6-0.8,1.5-1.2,2.4-1.2c1.5-0.1,2.8,1.1,2.8,2.6c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.1-0.6,1.5  c-0.4,0.4-0.8,0.8-1.3,1.1c1.6,0.3,2.7,1.6,2.8,3.3c0,3.4-3.6,4.9-4.9,4.9c-0.7,0-1.4-0.3-1.9-0.7c-0.2-0.2-0.3-0.4-0.3-0.7  c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.3-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.3,0,2.2-1.1,2.2-3.1c0.2-1.3-0.8-2.5-2.1-2.7  c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.2,0.3l-0.1-0.7c1.6-0.6,2.9-1.4,2.9-3c0.2-0.9-0.5-1.8-1.4-2c-0.1,0-0.2,0-0.3,0  c-1,0.1-1.8,0.8-2.3,1.7L641.4,646.6z" />
        <path
          style={{ fill: "#136736" }}
          d="M603.6,681.4c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c0,2.6,0.9,5.1,2.6,7L603.6,681.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M605.5,670.1l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L605.5,670.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M616.4,668.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S613.9,668.1,616.4,668.1z M616.4,668.7  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S617.7,668.7,616.4,668.7L616.4,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M624.2,668.1c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S621.7,668.1,624.2,668.1z M624.2,668.7  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S625.5,668.7,624.2,668.7L624.2,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M637.4,678.3v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5H633v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H637.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M648.5,676.1c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.8,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8  c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L648.5,676.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M650,666.6c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L650,666.6z"
        />
        <path d="M564.5,644.3c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.8,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2v-4.2h-6v4.2  c0,1.8,0.2,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.2-1.6,0.3-1.6,2v3.3h6v-3.3  c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V644.3z" />
        <path d="M567.5,657.7c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C568.8,657,568.3,657.6,567.5,657.7C567.6,657.7,567.6,657.7,567.5,657.7z" />
        <path d="M570.5,646.6l0.9-1.3c0.6-0.8,1.5-1.2,2.4-1.2c1.5-0.1,2.8,1.1,2.8,2.6c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.1-0.6,1.5  c-0.4,0.4-0.8,0.8-1.3,1.1c1.6,0.3,2.7,1.6,2.8,3.3c0,3.4-3.6,4.9-4.9,4.9c-0.7,0-1.4-0.3-1.9-0.7c-0.2-0.2-0.3-0.4-0.3-0.7  c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.3-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.3,0,2.2-1.1,2.2-3.1c0.2-1.3-0.8-2.5-2.1-2.7  c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.2,0.2l-0.1-0.7c1.6-0.6,2.9-1.4,2.9-3c0.2-0.9-0.5-1.8-1.4-2c-0.1,0-0.2,0-0.3,0  c-1,0.1-1.8,0.8-2.2,1.7L570.5,646.6z" />
        <path d="M585.3,653.6v1.5c0,1.4,0.1,1.5,1.4,1.6v0.6h-4.5v-0.6c1.4-0.2,1.4-0.3,1.4-1.7v-1.5h-4.8V653c1.8-2.9,3.7-6,5.5-8.8h0.9  v8.5h1.8v1.1L585.3,653.6z M583.7,646.4c-1.1,1.8-2.4,4-3.7,6.1h3.7V646.4z" />
        <path
          style={{ fill: "#136736" }}
          d="M541.8,681.4c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c0,2.6,0.9,5.1,2.6,7L541.8,681.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M543.6,670.1l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0  c-0.3,0-0.7,0.1-1,0.2L545,673c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L543.6,670.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M554.5,668.1c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S552,668.1,554.5,668.1z M554.5,668.7  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S555.9,668.7,554.5,668.7L554.5,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M562.3,668.1c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S559.9,668.1,562.3,668.1z M562.3,668.7  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S563.7,668.7,562.3,668.7L562.3,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M575.5,678.3v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H575.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M586.7,676.1c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.8,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8  c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L586.7,676.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M588.2,666.6c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L588.2,666.6z"
        />
        <path d="M500.7,644.3c-1.5,0.2-1.6,0.3-1.6,2v8.4c0,1.8,0.2,1.8,1.6,2v0.6h-5.1v-0.6c1.5-0.2,1.6-0.3,1.6-2v-4.2h-6v4.2  c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.2-1.6,0.3-1.6,2v3.3h6v-3.3  c0-1.8-0.2-1.9-1.6-2v-0.6h5.1V644.3z" />
        <path d="M503.7,657.7c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C505,657,504.5,657.6,503.7,657.7C503.8,657.7,503.7,657.7,503.7,657.7z" />
        <path d="M506.7,646.6l0.9-1.3c0.6-0.8,1.5-1.2,2.4-1.3c1.5-0.1,2.8,1.1,2.8,2.6c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.1-0.6,1.5  c-0.4,0.4-0.8,0.8-1.3,1.1c1.6,0.3,2.7,1.6,2.8,3.3c0,3.4-3.6,4.9-4.9,4.9c-0.7,0-1.4-0.3-1.9-0.7c-0.2-0.2-0.3-0.4-0.3-0.7  c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.3-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.3,0,2.1-1.1,2.1-3.1c0.2-1.3-0.8-2.5-2.1-2.7  c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.2,0.2l-0.1-0.7c1.6-0.6,2.9-1.4,2.9-3c0.2-0.9-0.5-1.8-1.4-2c-0.1,0-0.2,0-0.3,0  c-1,0.1-1.9,0.7-2.3,1.7L506.7,646.6z" />
        <path d="M522.3,644.3l0.1,0.2l-0.5,1.5h-4.3c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.5-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4  c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.9-2.1,1.4-3.3,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.2-0.2-0.4-0.5-0.3-0.7  c0-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.2,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2  c-0.6,0-1.3,0.1-1.8,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4L522.3,644.3z" />
        <path
          style={{ fill: "#136736" }}
          d="M477.9,681.4c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.4,0.4c-1.8,1.9-2.7,4.4-2.6,7  c0,2.6,0.9,5.1,2.6,7L477.9,681.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M479.8,670.1l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.8,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.1,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L479.8,670.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M490.7,668.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S488.2,668.1,490.7,668.1z M490.7,668.7  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S492,668.7,490.7,668.7L490.7,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M498.5,668.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S496,668.1,498.5,668.1z M498.5,668.7  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S499.9,668.7,498.5,668.7L498.5,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M511.7,678.3v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.4v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.8-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H511.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M522.8,676.1c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.8,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8  c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L522.8,676.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M524.4,666.6c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8L524,681c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L524.4,666.6z"
        />
        <path d="M438.9,644.3c-1.5,0.2-1.6,0.3-1.6,2v8.4c0,1.8,0.2,1.8,1.6,2v0.6h-5.1v-0.6c1.5-0.2,1.6-0.3,1.6-2v-4.2h-6v4.2  c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.2-1.6,0.3-1.6,2v3.3h6v-3.3  c0-1.8-0.2-1.9-1.6-2v-0.6h5.1V644.3z" />
        <path d="M441.9,657.7c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C443.1,657,442.6,657.6,441.9,657.7C441.9,657.7,441.9,657.7,441.9,657.7z" />
        <path d="M444.9,646.6l0.9-1.3c0.6-0.8,1.5-1.2,2.4-1.2c1.5-0.1,2.8,1.1,2.8,2.6c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.1-0.6,1.5  c-0.4,0.4-0.8,0.8-1.3,1.1c1.6,0.3,2.7,1.6,2.8,3.3c0,3.4-3.6,4.9-4.9,4.9c-0.7,0-1.4-0.2-1.9-0.7c-0.2-0.2-0.3-0.4-0.3-0.7  c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.3-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.3,0,2.1-1.1,2.1-3.1c0.2-1.3-0.8-2.5-2.1-2.7  c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.2,0.2l-0.1-0.7c1.6-0.6,2.9-1.4,2.9-3c0.2-0.9-0.5-1.8-1.4-2c-0.1,0-0.2,0-0.3,0  c-1,0.1-1.9,0.7-2.3,1.7L444.9,646.6z" />
        <path d="M456.8,649.5c0.4-0.2,0.8-0.4,1.2-0.4c2,0,3.3,1.7,3.3,3.9c0,2.6-1.6,4.7-3.9,4.7s-3.9-2-3.9-5.3c-0.1-3.4,1.9-6.5,5.1-7.9  c0.7-0.2,1.5-0.4,2.2-0.6l0.1,0.7c-0.7,0.2-1.4,0.4-2,0.7c-1.8,1-3,2.8-3.3,4.8L456.8,649.5z M457.1,650.3c-0.6,0-1.2,0.2-1.7,0.5  c-0.1,0.5-0.1,1-0.1,1.5c0,2.7,0.9,4.5,2.4,4.5s1.8-1.7,1.8-3.1C459.5,652,458.7,650.3,457.1,650.3z" />
        <path
          style={{ fill: "#136736" }}
          d="M416.1,681.4c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.4,0.4c-1.8,1.9-2.7,4.4-2.6,7  c0,2.6,0.9,5.1,2.6,7L416.1,681.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M418,670.1l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.8,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.1,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L418,670.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M428.8,668.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S426.4,668.1,428.8,668.1z M428.8,668.7  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S430.2,668.7,428.8,668.7L428.8,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M436.7,668.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S434.2,668.1,436.7,668.1z M436.7,668.7  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S438,668.7,436.7,668.7L436.7,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M449.9,678.3v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5H441v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.8-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H449.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M461,676.1c-0.2,0.7-0.5,1.6-0.6,2.2h-6.1v-0.4c0.9-0.8,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8  c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L461,676.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M462.5,666.6c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L462.5,666.6z"
        />
        <path d="M372.6,644.3c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.8,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.6-0.3,1.6-2v-4.2h-6v4.2  c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.2-1.6,0.3-1.6,2v3.3h6v-3.3  c0-1.8-0.2-1.9-1.6-2v-0.6h5.1V644.3z" />
        <path d="M375.6,657.7c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C376.9,657,376.4,657.6,375.6,657.7C375.6,657.7,375.6,657.7,375.6,657.7z" />
        <path d="M378.6,646.6l0.9-1.3c0.6-0.8,1.5-1.2,2.4-1.2c1.5-0.1,2.8,1.1,2.8,2.6c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.1-0.6,1.5  c-0.4,0.4-0.8,0.8-1.3,1.1c1.6,0.3,2.7,1.6,2.8,3.3c0,3.4-3.6,4.9-4.9,4.9c-0.7,0-1.4-0.3-1.9-0.7c-0.2-0.2-0.3-0.4-0.3-0.7  c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.3-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.3,0,2.1-1.1,2.1-3.1c0.2-1.3-0.8-2.5-2.1-2.7  c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.2,0.2l-0.1-0.7c1.6-0.6,2.9-1.4,2.9-3c0-1.3-0.7-2-1.7-2c-1,0.1-1.9,0.8-2.3,1.7L378.6,646.6z  " />
        <path d="M395.1,644.6c-1.8,4.3-3.5,8.5-5.3,12.9l-1.4,0.2l-0.1-0.2c1.9-3.6,3.9-7.8,5.4-11.4h-3.9c-1.2,0-1.3,0.2-1.8,1.6h-0.6  c0.2-1.3,0.3-2.3,0.4-3.3h7.2L395.1,644.6z" />
        <path
          style={{ fill: "#136736" }}
          d="M354,681.4c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L354,681.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M355.8,670.1l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.4,0.3-0.7,0.6-1.1,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L355.8,670.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M366.7,668.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S364.2,668.1,366.7,668.1z M366.7,668.7  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S368.1,668.7,366.7,668.7L366.7,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M374.5,668.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S372.1,668.1,374.5,668.1z M374.5,668.7  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S375.9,668.7,374.5,668.7L374.5,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M387.8,678.3v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5H379v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.7-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H387.8z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M398.9,676.1c-0.2,0.7-0.5,1.6-0.6,2.2h-6.1v-0.4c0.9-0.8,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8  c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L398.9,676.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M400.4,666.6c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8L400,681c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L400.4,666.6z"
        />
        <path d="M310.8,644.3c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.8,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.6-0.3,1.6-2v-4.2h-6v4.2  c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.2-1.6,0.3-1.6,2v3.3h6v-3.3  c0-1.8-0.2-1.9-1.6-2v-0.6h5.1V644.3z" />
        <path d="M313.7,657.7c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C315,657,314.5,657.6,313.7,657.7C313.8,657.7,313.7,657.7,313.7,657.7z" />
        <path d="M316.8,646.6l0.9-1.3c0.6-0.8,1.4-1.2,2.4-1.3c1.5-0.1,2.8,1.1,2.8,2.6c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.1-0.6,1.5  c-0.4,0.4-0.8,0.8-1.3,1.1c1.6,0.3,2.7,1.6,2.8,3.3c0,3.4-3.6,4.9-4.9,4.9c-0.7,0-1.4-0.3-1.9-0.7c-0.2-0.2-0.3-0.4-0.3-0.7  c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.3-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.3,0,2.1-1.1,2.1-3.1c0.2-1.3-0.8-2.5-2.1-2.7  c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.2,0.2l-0.1-0.7c1.6-0.6,2.9-1.4,2.9-3c0-1.3-0.7-2-1.7-2c-1,0.1-1.9,0.7-2.3,1.7L316.8,646.6z  " />
        <path d="M329.4,644c1.7,0,3,1.3,3.1,2.9c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.2-0.5,1.7c-0.5,0.5-1,0.9-1.5,1.3c1.3,0.8,2.5,1.8,2.5,3.7  c0,2.1-1.6,3.8-3.7,3.9c0,0-0.1,0-0.1,0c-1.9,0.1-3.5-1.3-3.6-3.1c0-0.1,0-0.2,0-0.4c0-0.8,0.3-1.5,0.8-2.1c0.5-0.5,1.1-0.9,1.7-1.3  c-1.2-0.7-2-1.9-2-3.3C326,645.6,327.4,644.1,329.4,644C329.3,644,329.4,644,329.4,644z M329.4,656.9c1.2,0,1.9-0.9,1.9-2.4  s-1.2-2.5-2.7-3.3c-0.9,0.6-1.5,1.7-1.4,2.8C327.1,656,328.2,657,329.4,656.9L329.4,656.9z M329.1,644.7c-1.1,0-1.6,1-1.6,2.1  c0,1.5,0.9,2.1,2.4,3c0.7-0.6,1.2-1.6,1.1-2.6C331,645.5,330.1,644.8,329.1,644.7L329.1,644.7z" />
        <path
          style={{ fill: "#136736" }}
          d="M292.1,681.4c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L292.1,681.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M294,670.1l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L294,670.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M304.9,668.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S302.4,668.1,304.9,668.1z M304.9,668.7  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S306.2,668.7,304.9,668.7L304.9,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M312.7,668.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S310.2,668.1,312.7,668.1z M312.7,668.7  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S314,668.7,312.7,668.7L312.7,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M325.9,678.3v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.8-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H325.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M337,676.1c-0.2,0.7-0.5,1.6-0.6,2.2h-6.1v-0.4c0.9-0.8,1.8-1.8,2.6-2.7c1.1-1,1.7-2.4,1.9-3.8  c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L337,676.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M338.5,666.6c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L338.5,666.6z"
        />
        <path d="M246.3,644.3c-1.5,0.2-1.6,0.3-1.6,2v8.4c0,1.8,0.2,1.8,1.6,2v0.6h-5.1v-0.6c1.5-0.2,1.6-0.3,1.6-2v-4.2h-6v4.2  c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.4,0.2-1.6,0.3-1.6,2v3.3h6v-3.3  c0-1.8-0.2-1.9-1.6-2v-0.6h5.1V644.3z" />
        <path d="M249.3,657.7c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C250.6,657,250.1,657.6,249.3,657.7C249.4,657.7,249.3,657.7,249.3,657.7z" />
        <path d="M252.3,646.6l0.9-1.3c0.6-0.8,1.5-1.2,2.4-1.2c1.5-0.1,2.8,1.1,2.8,2.6c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.1-0.6,1.5  c-0.4,0.4-0.8,0.8-1.3,1.1c1.6,0.3,2.7,1.6,2.8,3.3c0,3.4-3.6,4.9-4.9,4.9c-0.7,0-1.4-0.3-1.9-0.7c-0.2-0.2-0.3-0.4-0.3-0.7  c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.3-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.3,0,2.1-1.1,2.1-3.1c0.2-1.3-0.8-2.5-2.1-2.7  c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.2,0.3l-0.1-0.7c1.6-0.6,2.9-1.4,2.9-3c0.2-0.9-0.5-1.8-1.4-2c-0.1,0-0.2,0-0.3,0  c-1,0.1-1.9,0.7-2.3,1.7L252.3,646.6z" />
        <path d="M266.6,651.4l-1.2,0.7c-0.3,0.2-0.7,0.3-1.1,0.3c-2,0-3.4-1.8-3.4-4c0-2,1.4-4.4,3.9-4.4s3.8,2.4,3.8,5.3  c0.1,3.3-1.7,6.4-4.7,7.9c-0.7,0.3-1.5,0.5-2.3,0.6l-0.1-0.7C264.1,656.5,266,654.5,266.6,651.4z M264.6,644.8c-1.4,0-1.8,1.5-1.8,3  c0,1.9,0.9,3.4,2.4,3.4c0.6,0,1.1-0.2,1.5-0.5c0.1-0.5,0.1-0.9,0.1-1.4C266.8,647.3,266.3,644.8,264.6,644.8L264.6,644.8z" />
        <path
          style={{ fill: "#136736" }}
          d="M227.7,681.4c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.4,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L227.7,681.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M229.6,670.1l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.4,0.3-0.7,0.6-1.1,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.1,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.3c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L229.6,670.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M240.4,668.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S238,668.1,240.4,668.1z M240.4,668.7  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S241.8,668.7,240.4,668.7L240.4,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M248.3,668.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S245.8,668.1,248.3,668.1z M248.3,668.7  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S249.6,668.7,248.3,668.7L248.3,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M261.5,678.3v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5H257v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.8-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H261.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M272.6,676.1c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1-1.2,1.9-2.5,1.9-3.8  s-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L272.6,676.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M274.1,666.6c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L274.1,666.6z"
        />
        <path d="M169.1,644.3c-1.5,0.2-1.6,0.3-1.6,2v8.4c0,1.8,0.2,1.8,1.6,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2v-4.2h-6.1v4.2  c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.4,0.2-1.6,0.3-1.6,2v3.3h6.1v-3.3  c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V644.3z" />
        <path d="M172.1,657.7c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C173.4,657,172.9,657.6,172.1,657.7C172.1,657.7,172.1,657.7,172.1,657.7z" />
        <path d="M180.9,653.6v1.5c0,1.4,0.1,1.5,1.4,1.6v0.6h-4.5v-0.6c1.4-0.2,1.4-0.3,1.4-1.7v-1.5h-4.8V653c1.8-2.9,3.7-6,5.5-8.8h0.9  v8.5h1.8v1.1L180.9,653.6z M179.3,646.4c-1.1,1.8-2.4,4-3.6,6.1h3.6V646.4z" />
        <path d="M187.6,644c2.8,0,3.9,3.6,3.9,6.8c0,3.1-1,6.8-3.9,6.8s-3.9-3.5-3.9-6.8C183.7,647.8,184.8,644,187.6,644z M187.6,644.8  c-1.6,0-2,3.1-2,6s0.5,6.1,2,6.1s2-3.1,2-6.1S189.2,644.8,187.6,644.8L187.6,644.8z" />
        <path
          style={{ fill: "#136736" }}
          d="M150.5,681.4c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L150.5,681.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M152.4,670.1l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.8,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.3c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L152.4,670.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M163.4,668.1c1.4-0.1,2.6,0.9,2.7,2.3c0,0,0,0.1,0,0.1c0,0.5-0.2,0.9-0.5,1.3c-0.4,0.4-0.8,0.7-1.3,1  c1.1,0.6,2.2,1.4,2.2,2.8c-0.1,1.7-1.6,3-3.3,2.9c0,0,0,0,0,0c-1.8,0-3.2-1-3.2-2.7c0-0.6,0.3-1.2,0.7-1.6c0.3-0.2,0.9-0.6,1.4-1  c-1-0.4-1.7-1.4-1.8-2.5C160.5,669.3,161.7,668.1,163.4,668.1C163.3,668.1,163.3,668.1,163.4,668.1z M163.4,677.9  c0.9,0,1.6-0.6,1.7-1.5c0-0.1,0-0.2,0-0.3c0-1.3-1-1.9-2.4-2.5c-0.8,0.4-1.3,1.2-1.3,2.1c-0.1,1.1,0.7,2.1,1.8,2.2  C163.2,677.9,163.3,677.9,163.4,677.9z M163.1,668.7c-0.8,0-1.4,0.7-1.4,1.4c0,0.1,0,0.1,0,0.2c0,1.2,0.8,1.6,2.1,2.2  c0.6-0.4,1-1.2,1-1.9C164.8,669.3,163.9,668.7,163.1,668.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M173.8,668.3l0.1,0.1l-0.4,1.2h-3.7c-0.1,0.8-0.4,2.1-0.5,2.8c0.6-0.2,1.3-0.4,1.9-0.5c1.5-0.1,2.9,1,3,2.6  c0,0,0,0.1,0,0.1c0,1.1-0.5,2.1-1.4,2.7c-0.8,0.7-1.8,1.1-2.9,1.1c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.2-0.3-0.3-0.3-0.6  c0-0.2,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.6,1.2,0.9,2,0.9c1.3,0,1.9-1.1,1.9-2.3c0.1-1.3-0.9-2.4-2.2-2.4  c-0.1,0-0.3,0-0.4,0c-0.5,0-1.1,0.1-1.6,0.3l-0.1-0.1c0.3-1.3,0.6-3.5,0.8-4.8L173.8,668.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M184.3,678.3v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.4-0.7v1.3  c0.4-0.3,0.8-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L184.3,678.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M195.4,676.1c-0.2,0.8-0.5,1.6-0.6,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8  c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L195.4,676.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M196.9,666.6c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c3.5-4,3.5-10,0-14L196.9,666.6z"
        />
        <path d="M754.5,726.2c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.8,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2v-4.2h-6v4.2  c0,1.8,0.2,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.2-1.6,0.3-1.6,2v3.3h6v-3.3  c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V726.2z" />
        <path d="M757.5,739.6c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C758.8,738.9,758.3,739.5,757.5,739.6C757.6,739.6,757.5,739.6,757.5,739.6z" />
        <path d="M760.5,728.5l0.9-1.3c0.6-0.8,1.5-1.2,2.4-1.2c1.5-0.1,2.8,1.1,2.8,2.6c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.1-0.6,1.5  c-0.4,0.4-0.8,0.8-1.3,1.1c1.6,0.3,2.7,1.6,2.8,3.3c0,3.4-3.6,4.9-4.9,4.9c-0.7,0-1.4-0.3-1.9-0.7c-0.2-0.2-0.3-0.4-0.3-0.7  c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.3-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.3,0,2.2-1.1,2.2-3.1c0.2-1.3-0.8-2.5-2.1-2.7  c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.2,0.2l-0.1-0.7c1.7-0.6,2.9-1.4,2.9-3c0-1.3-0.7-2-1.7-2c-1,0.1-1.9,0.7-2.3,1.7L760.5,728.5z  " />
        <path d="M773,725.9c2.8,0,3.8,3.6,3.8,6.8c0,3.1-1,6.8-3.8,6.8s-3.9-3.5-3.9-6.8C769.1,729.7,770.2,725.9,773,725.9z M773,726.7  c-1.6,0-2,3.1-2,6s0.5,6.1,2,6.1s2-3.1,2-6.1S774.6,726.7,773,726.7L773,726.7z" />
        <path
          style={{ fill: "#136736" }}
          d="M731.7,763.3c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L731.7,763.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M733.6,752l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.3c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.4-1.5-1.1-1.6c-0.1,0-0.2,0-0.4,0c-0.8,0.1-1.6,0.5-2,1.3  L733.6,752z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M744.5,750c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S742,750,744.5,750z M744.5,750.6  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S745.8,750.6,744.5,750.6L744.5,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M752.3,750c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S749.8,750,752.3,750z M752.3,750.6  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S753.7,750.6,752.3,750.6L752.3,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M765.5,760.2v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.4v-0.5c1-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H765.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M776.7,758c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1-1.2,1.9-2.5,1.9-3.8s-0.7-2-1.8-2  c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3c0,1.3-0.5,2.3-2,3.8  c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L776.7,758z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M778.2,748.4c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L778.2,748.4z"
        />
        <path d="M692.7,726.2c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.8,1.7,2v0.6h-5v-0.6c1.5-0.2,1.7-0.3,1.7-2v-4.2h-6v4.2  c0,1.8,0.2,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.2-1.6,0.3-1.6,2v3.3h6v-3.3  c0-1.8-0.2-1.9-1.7-2v-0.6h5V726.2z" />
        <path d="M695.7,739.6c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C697,738.9,696.4,739.5,695.7,739.6C695.7,739.6,695.7,739.6,695.7,739.6z" />
        <path d="M706.1,736.4c-0.3,1-0.6,2.1-0.8,2.9h-7v-0.5c1-1.1,2.1-2.4,3-3.6c1.2-1.6,2.2-3.3,2.2-5s-0.8-2.7-2.1-2.7s-2,1.1-2.5,2  l-0.5-0.5l1.1-1.8c0.6-0.8,1.6-1.3,2.6-1.3c1.8,0,3.2,1.4,3.2,3.7c0,1.8-0.6,3-2.3,5.1c-0.8,0.9-1.9,2.2-2.7,3h3.4  c1,0,1.3-0.1,1.9-1.4L706.1,736.4z" />
        <path d="M713,733.3l-1.2,0.7c-0.3,0.2-0.7,0.3-1.1,0.3c-2,0-3.3-1.8-3.3-4c0-2,1.4-4.4,3.8-4.4s3.8,2.4,3.8,5.3  c0.1,3.3-1.7,6.4-4.7,7.9c-0.7,0.3-1.5,0.5-2.3,0.6l-0.1-0.7C710.5,738.4,712.4,736.4,713,733.3z M711,726.7c-1.3,0-1.8,1.5-1.8,3  c0,1.9,0.9,3.4,2.4,3.4c0.6,0,1.1-0.2,1.5-0.6c0.1-0.5,0.1-0.9,0.1-1.4C713.1,729.2,712.7,726.7,711,726.7L711,726.7z" />
        <path
          style={{ fill: "#136736" }}
          d="M669.9,763.3c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L669.9,763.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M671.8,752l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.8,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.3c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L671.8,752z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M682.7,750c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S680.2,750,682.7,750z M682.7,750.6  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S684,750.6,682.7,750.6L682.7,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M690.5,750c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S688,750,690.5,750z M690.5,750.6  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S691.8,750.6,690.5,750.6L690.5,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M703.7,760.2v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H703.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M714.8,758c-0.2,0.7-0.5,1.6-0.7,2.2H708v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8  c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L714.8,758z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M716.3,748.4c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L716.3,748.4z"
        />
        <path d="M626.4,726.2c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.8,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2v-4.2h-6v4.2  c0,1.8,0.2,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.2-1.6,0.3-1.6,2v3.3h6v-3.3  c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V726.2z" />
        <path d="M629.4,739.6c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C630.7,738.9,630.2,739.5,629.4,739.6C629.4,739.6,629.4,739.6,629.4,739.6z" />
        <path d="M639.8,736.4c-0.3,1-0.6,2.1-0.8,2.9h-7v-0.5c1-1.1,2.1-2.4,3-3.6c1.2-1.6,2.2-3.3,2.2-5s-0.8-2.7-2.1-2.7s-2,1.1-2.5,2  l-0.5-0.5l1.1-1.8c0.6-0.8,1.6-1.3,2.6-1.3c1.8,0,3.2,1.4,3.2,3.7c0,1.8-0.6,3-2.3,5.1c-0.8,0.9-1.9,2.2-2.7,3h3.4  c1,0,1.3-0.1,1.9-1.4L639.8,736.4z" />
        <path d="M645,725.9c1.7,0,3,1.3,3.1,2.9c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.2-0.5,1.7c-0.5,0.5-1,0.9-1.5,1.3c1.3,0.8,2.5,1.8,2.5,3.7  c0,2.1-1.6,3.8-3.7,3.9c0,0-0.1,0-0.1,0c-1.9,0.1-3.5-1.3-3.6-3.1c0-0.1,0-0.2,0-0.4c0-0.8,0.3-1.5,0.9-2.1c0.5-0.5,1.1-0.9,1.7-1.3  c-1.2-0.7-2-1.9-2-3.3C641.6,727.5,643,726,645,725.9C644.9,725.9,645,725.9,645,725.9z M645,738.8c1.2,0,1.9-0.9,1.9-2.4  s-1.2-2.5-2.7-3.3c-0.9,0.6-1.5,1.7-1.4,2.8C642.8,737.8,643.9,738.8,645,738.8z M644.8,726.6c-1.1,0-1.6,1-1.6,2.1  c0,1.5,0.9,2.1,2.3,3c0.8-0.6,1.2-1.6,1.1-2.5C646.6,727.3,645.7,726.6,644.8,726.6L644.8,726.6z" />
        <path
          style={{ fill: "#136736" }}
          d="M603.6,763.3c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L603.6,763.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M605.5,752l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.3c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L605.5,752z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M616.4,750c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S613.9,750,616.4,750z M616.4,750.6  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S617.7,750.6,616.4,750.6L616.4,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M624.2,750c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S621.7,750,624.2,750z M624.2,750.6  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S625.5,750.6,624.2,750.6L624.2,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M637.4,760.2v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5H633v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H637.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M648.5,758c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1-1.2,1.9-2.5,1.9-3.8s-0.7-2-1.8-2  c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3c0,1.3-0.5,2.3-2,3.8  c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L648.5,758z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M650,748.4c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L650,748.4z"
        />
        <path d="M564.5,726.2c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.8,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2v-4.2h-6v4.2  c0,1.8,0.2,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.2-1.6,0.3-1.6,2v3.3h6v-3.3  c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V726.2z" />
        <path d="M567.5,739.6c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C568.8,738.9,568.3,739.5,567.5,739.6C567.6,739.6,567.6,739.6,567.5,739.6z" />
        <path d="M577.9,736.4c-0.3,1-0.6,2.1-0.8,2.9h-7v-0.5c1-1.1,2.1-2.4,3-3.6c1.2-1.6,2.2-3.3,2.2-5s-0.8-2.7-2.1-2.7s-2,1.1-2.5,2  l-0.5-0.5l1.1-1.8c0.6-0.8,1.6-1.3,2.6-1.3c1.8,0,3.2,1.4,3.2,3.7c0,1.8-0.6,3-2.3,5.1c-0.8,0.9-1.9,2.2-2.7,3h3.4  c1,0,1.3-0.1,1.9-1.4L577.9,736.4z" />
        <path d="M587.1,726.4c-1.8,4.3-3.5,8.5-5.3,12.9l-1.4,0.2l-0.1-0.2c1.9-3.6,3.9-7.8,5.4-11.4h-3.9c-1.2,0-1.3,0.2-1.8,1.6h-0.6  c0.2-1.3,0.3-2.3,0.4-3.3h7.3L587.1,726.4z" />
        <path
          style={{ fill: "#136736" }}
          d="M541.8,763.3c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L541.8,763.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M543.6,752l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.3c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.4-1.5-1.1-1.6c-0.1,0-0.3,0-0.4,0c-0.8,0.1-1.6,0.5-2,1.3  L543.6,752z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M554.5,750c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S552,750,554.5,750z M554.5,750.6  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S555.9,750.6,554.5,750.6L554.5,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M562.3,750c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S559.9,750,562.3,750z M562.3,750.6  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S563.7,750.6,562.3,750.6L562.3,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M575.5,760.2v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H575.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M586.7,758c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1-1.2,1.9-2.5,1.9-3.8s-0.7-2-1.8-2  c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l1-1.4c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3c0,1.3-0.5,2.3-2,3.8  c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L586.7,758z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M588.2,748.4c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L588.2,748.4z"
        />
        <path d="M500.7,726.2c-1.5,0.2-1.6,0.3-1.6,2v8.4c0,1.8,0.2,1.8,1.6,2v0.6h-5.1v-0.6c1.5-0.2,1.6-0.3,1.6-2v-4.2h-6v4.2  c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.2-1.6,0.3-1.6,2v3.3h6v-3.3  c0-1.8-0.2-1.9-1.6-2v-0.6h5.1V726.2z" />
        <path d="M503.7,739.6c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C505,738.9,504.5,739.5,503.7,739.6C503.8,739.6,503.7,739.6,503.7,739.6z" />
        <path d="M514.1,736.4c-0.2,1-0.6,2.1-0.8,2.9h-7v-0.5c1-1.1,2.1-2.4,3-3.6c1.2-1.6,2.2-3.3,2.2-5s-0.8-2.7-2.1-2.7s-2,1.1-2.5,2  l-0.4-0.5l1.1-1.8c0.6-0.8,1.6-1.3,2.6-1.3c1.8,0,3.2,1.4,3.2,3.7c0,1.8-0.6,3-2.3,5.1c-0.8,0.9-1.9,2.2-2.7,3h3.4  c1,0,1.3-0.1,1.9-1.4L514.1,736.4z" />
        <path d="M518.6,731.3c0.4-0.2,0.8-0.4,1.2-0.4c2,0,3.3,1.7,3.3,3.9c0,2.6-1.6,4.7-3.9,4.7s-3.9-2-3.9-5.3c-0.1-3.4,1.9-6.5,5.1-7.9  c0.7-0.2,1.4-0.4,2.2-0.6l0.1,0.7c-0.7,0.2-1.4,0.4-2,0.7c-1.8,1-3,2.8-3.3,4.8L518.6,731.3z M519,732.2c-0.6,0-1.2,0.2-1.7,0.5  c-0.1,0.5-0.1,1-0.1,1.5c0,2.7,0.9,4.5,2.3,4.5s1.8-1.7,1.8-3.1C521.3,733.9,520.6,732.2,519,732.2z" />
        <path
          style={{ fill: "#136736" }}
          d="M481.9,763.3c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.4,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L481.9,763.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M483.8,752l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.8,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.1,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.3c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L483.8,752z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M494.7,750c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S492.2,750,494.7,750z M494.7,750.6  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S496,750.6,494.7,750.6L494.7,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M502.5,750c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S500,750,502.5,750z M502.5,750.6  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S503.9,750.6,502.5,750.6L502.5,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M515.7,760.2v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.4v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.8-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H515.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M526.8,758c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8  c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L526.8,758z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M528.4,748.4c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L528.4,748.4z"
        />
        <path d="M438.9,726.2c-1.5,0.2-1.6,0.3-1.6,2v8.4c0,1.8,0.2,1.8,1.6,2v0.6h-5.1v-0.6c1.5-0.2,1.6-0.3,1.6-2v-4.2h-6v4.2  c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.2-1.6,0.3-1.6,2v3.3h6v-3.3  c0-1.8-0.2-1.9-1.6-2v-0.6h5.1V726.2z" />
        <path d="M441.9,739.6c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C443.1,738.9,442.6,739.5,441.9,739.6C441.9,739.6,441.9,739.6,441.9,739.6z" />
        <path d="M452.3,736.4c-0.3,1-0.6,2.1-0.8,2.9h-7v-0.5c1-1.1,2.1-2.4,3-3.6c1.2-1.6,2.2-3.3,2.2-5s-0.8-2.7-2.1-2.7s-2,1.1-2.5,2  l-0.4-0.5l1.1-1.8c0.6-0.8,1.6-1.3,2.6-1.3c1.8,0,3.2,1.4,3.2,3.7c0,1.8-0.6,3-2.3,5.1c-0.8,0.9-1.9,2.2-2.7,3h3.4  c1,0,1.3-0.1,1.9-1.4L452.3,736.4z" />
        {/* <path d="M456.8,731.3c0.4-0.2,0.8-0.3,1.2-0.3c2,0,3.3,1.7,3.3,3.9c0,2.6-1.6,4.7-3.9,4.7s-3.9-2-3.9-5.3c-0.1-3.4,1.9-6.5,5.1-7.9  c0.7-0.2,1.5-0.4,2.2-0.6l0.1,0.7c-0.7,0.2-1.4,0.4-2,0.7c-1.8,1-3,2.8-3.3,4.8L456.8,731.3z M457.1,732.2c-0.6,0-1.2,0.2-1.7,0.5  c-0.1,0.5-0.1,1-0.1,1.5c0,2.7,0.9,4.5,2.4,4.5s1.8-1.7,1.8-3.1C459.5,733.9,458.7,732.2,457.1,732.2z" /> */}
        <path d="M460.5,726.6l0.1,0.2l-0.5,1.5h-4.3c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.5-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4  c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.9-2.1,1.4-3.3,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.2-0.2-0.4-0.5-0.3-0.7  c0-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.2,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2  c-0.6,0-1.3,0.1-1.8,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4L460.5,726.6z" />
        <path
          style={{ fill: "#136736" }}
          d="M416.1,763.3c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.4,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L416.1,763.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M418,752l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.8,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.1,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.3c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L418,752z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M428.8,750c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S426.4,750,428.8,750z M428.8,750.6  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S430.2,750.6,428.8,750.6L428.8,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M436.7,750c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S434.2,750,436.7,750z M436.7,750.6  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S438,750.6,436.7,750.6L436.7,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M449.9,760.2v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5H441v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.8-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H449.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M461,758c-0.2,0.7-0.5,1.6-0.6,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8c0-1.3-0.7-2-1.8-2  c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3c0,1.3-0.5,2.3-2,3.8  c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L461,758z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M462.5,748.4c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L462.5,748.4z"
        />
        <path d="M372.6,726.2c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.8,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.6-0.3,1.6-2v-4.2h-6v4.2  c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.2-1.6,0.3-1.6,2v3.3h6v-3.3  c0-1.8-0.2-1.9-1.6-2v-0.6h5.1V726.2z" />
        <path d="M375.6,739.6c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C376.9,738.9,376.4,739.5,375.6,739.6C375.6,739.6,375.6,739.6,375.6,739.6z" />
        <path d="M386,736.4c-0.3,1-0.6,2.1-0.8,2.9h-7v-0.5c1-1.1,2.1-2.4,3-3.6c1.2-1.6,2.2-3.3,2.2-5s-0.8-2.7-2.1-2.7s-2,1.1-2.5,2  l-0.4-0.5l1.1-1.8c0.6-0.8,1.6-1.3,2.6-1.3c1.8,0,3.2,1.4,3.2,3.7c0,1.8-0.6,3-2.3,5.1c-0.8,0.9-1.9,2.2-2.7,3h3.4  c1,0,1.3-0.1,1.9-1.4L386,736.4z" />
        <path d="M393.4,735.5v1.5c0,1.4,0.1,1.5,1.4,1.6v0.6h-4.5v-0.6c1.4-0.2,1.5-0.3,1.5-1.7v-1.5H387v-0.7c1.8-2.9,3.7-6,5.5-8.8h0.9  v8.5h1.8v1.1H393.4z M391.8,728.3c-1.1,1.8-2.4,4-3.6,6.1h3.6V728.3z" />
        <path
          style={{ fill: "#136736" }}
          d="M354,763.3c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L354,763.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M355.8,752l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.4,0.3-0.7,0.6-1.1,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.3c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L355.8,752z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M366.7,750c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S364.2,750,366.7,750z M366.7,750.6  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S368.1,750.6,366.7,750.6L366.7,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M374.5,750c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S372.1,750,374.5,750z M374.5,750.6  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S375.9,750.6,374.5,750.6L374.5,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M387.8,760.2v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5H379v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.7-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H387.8z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M398.9,758c-0.2,0.7-0.5,1.6-0.6,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1-1.2,1.9-2.5,1.9-3.8s-0.7-2-1.8-2  c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3c0,1.3-0.5,2.3-2,3.8  c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L398.9,758z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M400.4,748.4c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L400.4,748.4z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M292.1,763.3c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L292.1,763.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M294,752l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.3c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L294,752z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M304.9,750c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S302.4,750,304.9,750z M304.9,750.6  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S306.2,750.6,304.9,750.6L304.9,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M312.7,750c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S310.2,750,312.7,750z M312.7,750.6  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S314,750.6,312.7,750.6L312.7,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M325.9,760.2v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.8-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H325.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M337,758c-0.2,0.7-0.5,1.6-0.6,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.7-2.4,1.9-3.8c0-1.3-0.7-2-1.8-2  c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3c0,1.3-0.5,2.3-2,3.8  c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L337,758z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M338.5,748.4c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L338.5,748.4z"
        />
        <path d="M246.3,726.2c-1.5,0.2-1.6,0.3-1.6,2v8.4c0,1.8,0.2,1.8,1.6,2v0.6h-5.1v-0.6c1.5-0.2,1.6-0.3,1.6-2v-4.2h-6v4.2  c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.4,0.2-1.6,0.3-1.6,2v3.3h6v-3.3  c0-1.8-0.2-1.9-1.6-2v-0.6h5.1V726.2z" />
        <path d="M249.3,739.6c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C250.6,738.9,250.1,739.5,249.3,739.6C249.4,739.6,249.3,739.6,249.3,739.6z" />
        <path d="M259.7,736.4c-0.3,1-0.6,2.1-0.8,2.9h-7v-0.5c1-1.1,2.1-2.4,3-3.6c1.2-1.6,2.2-3.3,2.2-5s-0.8-2.7-2.1-2.7s-2,1.1-2.5,2  l-0.4-0.5l1.1-1.8c0.6-0.8,1.6-1.3,2.6-1.3c1.8,0,3.2,1.4,3.2,3.7c0,1.8-0.6,3-2.3,5.1c-0.8,0.9-1.9,2.2-2.7,3h3.4  c1,0,1.3-0.1,1.9-1.4L259.7,736.4z" />
        <path d="M268.7,736.4c-0.3,1-0.6,2.1-0.8,2.9h-7v-0.5c1-1.1,2.1-2.4,3-3.6c1.2-1.6,2.2-3.3,2.2-5s-0.8-2.7-2.1-2.7s-2,1.1-2.5,2  l-0.4-0.5l1.1-1.8c0.6-0.8,1.6-1.3,2.6-1.3c1.8,0,3.2,1.4,3.2,3.7c0,1.8-0.6,3-2.3,5.1c-0.8,0.9-1.9,2.2-2.7,3h3.4  c1,0,1.3-0.1,1.9-1.4L268.7,736.4z" />
        <path d="M310.8,726.2c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.8,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.6-0.3,1.6-2v-4.2h-6v4.2  c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.2-1.6,0.3-1.6,2v3.3h6v-3.3  c0-1.8-0.2-1.9-1.6-2v-0.6h5.1V726.2z" />
        <path d="M313.7,739.6c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C315,738.9,314.5,739.5,313.7,739.6C313.8,739.6,313.7,739.6,313.7,739.6z" />
        <path d="M324.1,736.4c-0.3,1-0.6,2.1-0.8,2.9h-7v-0.5c1-1.1,2.1-2.4,3-3.6c1.2-1.6,2.2-3.3,2.2-5s-0.8-2.7-2.1-2.7s-2,1.1-2.5,2  l-0.4-0.5l1.1-1.8c0.6-0.8,1.6-1.3,2.6-1.3c1.8,0,3.2,1.4,3.2,3.7c0,1.8-0.6,3-2.3,5.1c-0.8,0.9-1.9,2.2-2.7,3h3.4  c1,0,1.3-0.1,1.9-1.4L324.1,736.4z" />
        <path d="M325.7,728.5l0.9-1.3c0.6-0.8,1.5-1.2,2.4-1.2c1.5-0.1,2.8,1.1,2.8,2.6c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.1-0.6,1.5  c-0.4,0.4-0.8,0.8-1.3,1.1c1.6,0.3,2.7,1.6,2.8,3.3c0,3.4-3.6,4.9-4.9,4.9c-0.7,0-1.4-0.3-1.9-0.7c-0.2-0.2-0.3-0.4-0.3-0.7  c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.3-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.3,0,2.1-1.1,2.1-3.1c0.2-1.3-0.8-2.5-2.1-2.7  c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.2,0.2l-0.1-0.7c1.6-0.6,2.9-1.4,2.9-3c0.2-0.9-0.5-1.8-1.4-2c-0.1,0-0.2,0-0.3,0  c-1,0.1-1.9,0.7-2.3,1.7L325.7,728.5z" />
        <path
          style={{ fill: "#136736" }}
          d="M227.7,763.3c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.4,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L227.7,763.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M229.6,752l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.4,0.3-0.7,0.6-1.1,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.1,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.3c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L229.6,752z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M240.4,750c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S238,750,240.4,750z M240.4,750.6  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S241.8,750.6,240.4,750.6L240.4,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M248.3,750c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S245.8,750,248.3,750z M248.3,750.6  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S249.6,750.6,248.3,750.6L248.3,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M261.5,760.2v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5H257v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.8-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H261.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M272.6,758c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1-1.2,1.9-2.5,1.9-3.8s-0.7-2-1.8-2  c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3c0,1.3-0.5,2.3-2,3.8  c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L272.6,758z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M274.1,748.4c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L274.1,748.4z"
        />
        <path d="M169.1,726.2c-1.5,0.2-1.6,0.3-1.6,2v8.4c0,1.8,0.2,1.8,1.6,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2v-4.2h-6.1v4.2  c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.4,0.2-1.6,0.3-1.6,2v3.3h6.1v-3.3  c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V726.2z" />
        <path d="M172.1,739.6c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C173.4,738.9,172.9,739.5,172.1,739.6C172.1,739.6,172.1,739.6,172.1,739.6z" />
        <path d="M182.5,736.4c-0.3,1-0.6,2.1-0.8,2.9h-7v-0.5c1-1.1,2.1-2.4,3-3.6c1.2-1.6,2.2-3.3,2.2-5s-0.8-2.7-2.1-2.7s-2,1.1-2.5,2  l-0.4-0.5l1.1-1.8c0.6-0.8,1.6-1.3,2.6-1.3c1.8,0,3.2,1.4,3.2,3.7c0,1.8-0.6,3-2.3,5.1c-0.8,0.9-1.9,2.2-2.7,3h3.4  c1,0,1.3-0.1,1.9-1.4L182.5,736.4z" />
        <path d="M184.7,739.3v-0.6c2-0.1,2.1-0.3,2.1-1.8v-8c0-1.1-0.2-1.1-1-1.2l-0.8-0.1V727c1.2-0.2,2.4-0.5,3.5-1v11  c0,1.6,0.1,1.7,2.1,1.8v0.6L184.7,739.3z" />
        <path
          style={{ fill: "#136736" }}
          d="M150.5,763.3c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L150.5,763.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M158.1,750.2l0.1,0.1l-0.4,1.2H154c-0.1,0.8-0.4,2.1-0.5,2.8c0.6-0.2,1.3-0.4,1.9-0.5c1.5-0.1,2.9,1,3,2.6  c0,0,0,0.1,0,0.1c0,1.1-0.5,2.1-1.4,2.7c-0.8,0.7-1.8,1-2.9,1.1c-0.6,0-1.2-0.2-1.7-0.6c-0.3-0.2-0.3-0.3-0.3-0.6  c0-0.2,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.6,1.2,0.9,2,0.9c1.3,0,1.9-1.1,1.9-2.3c0.1-1.3-0.9-2.4-2.2-2.4  c-0.1,0-0.3,0-0.4,0c-0.5,0-1.1,0.1-1.6,0.3V755c0.3-1.3,0.6-3.5,0.8-4.8L158.1,750.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M160.7,760.2v-0.5c1.8-0.1,1.9-0.2,1.9-1.4v-6.1c0-0.8-0.1-0.8-0.8-0.9l-0.7-0.1v-0.4c1-0.2,2-0.4,3-0.8v8.3  c0,1.2,0.1,1.3,1.9,1.4v0.5H160.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M171.1,750c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S168.6,750,171.1,750z M171.1,750.6  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S172.4,750.6,171.1,750.6L171.1,750.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M184.3,760.2v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.8-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H184.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M195.4,758c-0.2,0.7-0.5,1.6-0.6,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8  c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L195.4,758z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M196.9,748.4c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L196.9,748.4z"
        />
        <path d="M690.8,422.9c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.9,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2v-4.2h-6v4.2  c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2V425c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.1-1.6,0.3-1.6,2v3.3h6V425  c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V422.9z" />
        <path d="M693.8,436.3c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C695.1,435.6,694.6,436.2,693.8,436.3C693.9,436.3,693.8,436.3,693.8,436.3z" />
        <path d="M703.4,422.9l0.1,0.2l-0.5,1.5h-4.3c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.5-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4  c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.9-2.1,1.4-3.3,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.3-0.3-0.4-0.5-0.3-0.7  c0-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2  c-0.6,0-1.3,0.1-1.8,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4L703.4,422.9z" />
        <path d="M706.5,436v-0.6c2-0.1,2.1-0.3,2.1-1.8v-8c0-1.1-0.2-1.1-1-1.2l-0.8-0.1v-0.6c1.2-0.2,2.4-0.5,3.5-1v11  c0,1.6,0.1,1.7,2.1,1.8v0.6H706.5z" />
        <path
          style={{ fill: "#136736" }}
          d="M668,460c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L668,460z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M669.9,448.7l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.5  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L669.9,448.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M680.8,446.7c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S678.3,446.7,680.8,446.7z M680.8,447.3  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S682.1,447.3,680.8,447.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M688.6,446.7c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S686.1,446.7,688.6,446.7z M688.6,447.3  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S690,447.3,688.6,447.3L688.6,447.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M701.8,456.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.4v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5H693v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H701.8z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M712.9,454.7c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8  c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.2  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L712.9,454.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M714.5,445.1c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L714.5,445.1z"
        />
        <path d="M690.8,508.8c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.8,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2V515h-6v4.2  c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.1-1.6,0.3-1.6,2v3.3h6v-3.3  c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V508.8z" />
        <path d="M693.8,522.2c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C695.1,521.4,694.6,522.1,693.8,522.2C693.9,522.1,693.8,522.1,693.8,522.2z" />
        <path d="M702.6,518v1.5c0,1.4,0.1,1.5,1.4,1.6v0.6h-4.5v-0.6c1.4-0.2,1.4-0.3,1.4-1.7V518h-4.8v-0.7c1.8-2.9,3.7-6,5.5-8.9h0.9v8.5  h1.8v1L702.6,518z M701,510.9c-1.1,1.8-2.4,4-3.7,6.1h3.7V510.9z" />
        <path d="M709.5,508.5c1.7,0,3,1.3,3.1,2.9c0,0.1,0,0.1,0,0.2c0,0.6-0.2,1.2-0.5,1.7c-0.5,0.5-1,0.9-1.5,1.3c1.3,0.8,2.5,1.8,2.5,3.7  c0,2.1-1.6,3.8-3.7,3.9c0,0-0.1,0-0.1,0c-1.9,0.1-3.5-1.3-3.6-3.1c0-0.1,0-0.2,0-0.4c0-0.8,0.3-1.5,0.8-2.1c0.5-0.5,1.1-0.9,1.7-1.3  c-1.2-0.7-2-1.9-2-3.3c-0.1-1.9,1.4-3.4,3.2-3.5C709.4,508.5,709.4,508.5,709.5,508.5z M709.5,521.4c1.2,0,1.9-0.9,1.9-2.4  s-1.2-2.5-2.7-3.3c-0.9,0.6-1.5,1.7-1.4,2.8C707.2,520.4,708.3,521.4,709.5,521.4z M709.2,509.2c-1.1,0-1.6,1-1.6,2.1  c0,1.5,0.9,2.1,2.3,3c0.7-0.6,1.2-1.6,1.1-2.5C711.1,509.9,710.1,509.2,709.2,509.2z" />
        <path
          style={{ fill: "#136736" }}
          d="M668,545.8c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L668,545.8z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M669.9,534.5l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.3,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.6  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L669.9,534.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M680.8,532.6c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S678.3,532.6,680.8,532.6z M680.8,533.2  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S682.1,533.2,680.8,533.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M688.6,532.6c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S686.1,532.6,688.6,532.6z M688.6,533.2  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S690,533.2,688.6,533.2L688.6,533.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M701.8,542.7v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.4v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5H693v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.7-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H701.8z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M712.9,540.5c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8  c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L712.9,540.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M714.5,531c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.3-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L714.5,531z"
        />
        <path d="M626.5,422.9c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.9,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2v-4.2h-6v4.2  c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2V425c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.1-1.6,0.3-1.6,2v3.3h6V425  c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V422.9z" />
        <path d="M629.5,436.3c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C630.7,435.6,630.2,436.2,629.5,436.3C629.5,436.3,629.5,436.3,629.5,436.3z" />
        <path d="M639.1,422.9l0.1,0.2l-0.5,1.5h-4.3c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.5-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4  c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.9-2.1,1.4-3.3,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.3-0.3-0.4-0.5-0.3-0.7  c0-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2  c-0.6,0-1.3,0.1-1.8,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4L639.1,422.9z" />
        <path d="M648.8,433.1c-0.3,1-0.6,2.1-0.8,2.9h-7v-0.5c1-1.1,2.1-2.4,3-3.6c1.2-1.6,2.2-3.2,2.2-5s-0.8-2.7-2.1-2.7s-2,1.1-2.5,2  l-0.5-0.5l1.1-1.8c0.6-0.8,1.6-1.3,2.6-1.3c1.8,0,3.2,1.4,3.2,3.7c0,1.8-0.6,3-2.3,5.1c-0.8,0.9-1.9,2.2-2.7,3h3.4  c1,0,1.3-0.1,1.9-1.4L648.8,433.1z" />
        <path
          style={{ fill: "#136736" }}
          d="M603.7,460c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L603.7,460z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M605.6,448.7l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.5  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c-0.1,0-0.1,0-0.2,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L605.6,448.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M616.4,446.7c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S614,446.7,616.4,446.7z M616.4,447.3  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S617.8,447.3,616.4,447.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M624.3,446.7c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S621.8,446.7,624.3,446.7z M624.3,447.3  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S625.6,447.3,624.3,447.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M637.5,456.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5H633v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H637.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M648.6,454.7c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8  c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5L642,449l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.2  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L648.6,454.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M650.1,445.1c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L650.1,445.1z"
        />
        <path d="M626.5,508.8c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.8,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2V515h-6v4.2  c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.1-1.6,0.3-1.6,2v3.3h6v-3.3  c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V508.8z" />
        <path d="M629.5,522.2c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C630.7,521.4,630.2,522.1,629.5,522.2C629.5,522.1,629.5,522.1,629.5,522.2z" />
        <path d="M638.3,518v1.5c0,1.4,0.1,1.5,1.4,1.6v0.6h-4.5v-0.6c1.4-0.2,1.4-0.3,1.4-1.7V518h-4.8v-0.7c1.8-2.9,3.7-6,5.5-8.9h0.9v8.5  h1.8v1L638.3,518z M636.7,510.9c-1.1,1.8-2.4,4-3.7,6.1h3.7V510.9z" />
        <path d="M649,509c-1.8,4.3-3.5,8.6-5.3,12.9l-1.4,0.2l-0.1-0.2c1.9-3.6,3.9-7.8,5.4-11.4h-3.9c-1.2,0-1.3,0.2-1.8,1.5h-0.6  c0.2-1.3,0.3-2.3,0.4-3.3h7.2L649,509z" />
        <path
          style={{ fill: "#136736" }}
          d="M603.7,545.8c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L603.7,545.8z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M605.6,534.5l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.8,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.6  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c-0.1,0-0.1,0-0.2,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L605.6,534.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M616.4,532.6c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S614,532.6,616.4,532.6z M616.4,533.2  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S617.8,533.2,616.4,533.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M624.3,532.6c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S621.8,532.6,624.3,532.6z M624.3,533.2  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.3,1.7-4.6S625.6,533.2,624.3,533.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M637.5,542.7v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5H633v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.7-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H637.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M648.6,540.5c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8  c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L648.6,540.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M650.1,531c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L650.1,531z"
        />
        <path d="M562.5,422.9c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.9,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2v-4.2h-6v4.2  c0,1.8,0.2,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2V425c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.1-1.6,0.3-1.6,2v3.3h6V425  c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V422.9z" />
        <path d="M565.4,436.3c-0.7,0-1.2-0.6-1.2-1.3c0,0,0-0.1,0-0.1c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0,0c0.7,0,1.3,0.7,1.2,1.4  c0,0,0,0,0,0C566.7,435.6,566.2,436.2,565.4,436.3C565.5,436.3,565.5,436.3,565.4,436.3z" />
        <path d="M575.1,422.9l0.1,0.2l-0.5,1.5h-4.3c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.4-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4  c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.9-2.1,1.4-3.3,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.3-0.3-0.4-0.5-0.3-0.7  c0-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.2,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2  c-0.6,0-1.3,0.1-1.8,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4L575.1,422.9z" />
        <path d="M577.5,425.2l0.9-1.4c0.6-0.8,1.5-1.2,2.4-1.2c1.5-0.1,2.8,1.1,2.8,2.6c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.1-0.6,1.6  c-0.4,0.4-0.8,0.8-1.3,1.1c1.6,0.3,2.7,1.6,2.8,3.3c0,3.4-3.6,4.9-4.9,4.9c-0.7,0-1.4-0.2-1.9-0.7c-0.2-0.2-0.3-0.5-0.3-0.7  c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.3-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.3,0,2.2-1.1,2.2-3.1c0.2-1.3-0.8-2.5-2.1-2.7  c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.2,0.2l-0.1-0.6c1.7-0.6,2.9-1.4,2.9-3c0-1.3-0.7-2-1.7-2c-1,0.1-1.9,0.7-2.3,1.6L577.5,425.2z  " />
        <path
          style={{ fill: "#136736" }}
          d="M539.7,460c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L539.7,460z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M541.5,448.7l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.5  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c-0.1,0-0.1,0-0.2,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L541.5,448.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M552.4,446.7c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S550,446.7,552.4,446.7z M552.4,447.3  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S553.8,447.3,552.4,447.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M560.3,446.7c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S557.8,446.7,560.3,446.7z M560.3,447.3  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S561.6,447.3,560.3,447.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M573.5,456.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5H569v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H573.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M584.6,454.7c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8  c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.2  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L584.6,454.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M586.1,445.1c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L586.1,445.1z"
        />
        <path d="M562.5,508.8c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.8,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2V515h-6v4.2  c0,1.8,0.2,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.1-1.6,0.3-1.6,2v3.3h6v-3.3  c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V508.8z" />
        <path d="M565.4,522.2c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C566.7,521.4,566.2,522.1,565.4,522.2C565.5,522.1,565.5,522.1,565.4,522.2z" />
        <path d="M574.3,518v1.5c0,1.4,0.1,1.5,1.4,1.6v0.6h-4.5v-0.6c1.4-0.2,1.4-0.3,1.4-1.7V518h-4.8v-0.7c1.8-2.9,3.7-6,5.5-8.9h0.9v8.5  h1.8v1L574.3,518z M572.7,510.9c-1.1,1.8-2.4,4-3.7,6.1h3.7V510.9z" />
        <path d="M580.4,513.9c0.4-0.2,0.8-0.4,1.2-0.4c2,0,3.3,1.7,3.3,3.9c0,2.5-1.6,4.7-3.9,4.7s-3.9-2-3.9-5.3c-0.1-3.4,1.9-6.5,5.1-7.9  c0.7-0.2,1.4-0.4,2.2-0.6l0.1,0.7c-0.7,0.2-1.4,0.4-2,0.7c-1.8,1-3,2.8-3.3,4.8L580.4,513.9z M580.7,514.8c-0.6,0-1.2,0.2-1.7,0.5  c-0.1,0.5-0.1,1-0.1,1.5c0,2.7,0.9,4.5,2.3,4.5s1.8-1.7,1.8-3.1C583.1,516.4,582.3,514.8,580.7,514.8z" />
        <path
          style={{ fill: "#136736" }}
          d="M539.7,545.8c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L539.7,545.8z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M541.5,534.5l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.8,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.6  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c-0.1,0-0.1,0-0.2,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L541.5,534.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M552.4,532.6c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S550,532.6,552.4,532.6z M552.4,533.2  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S553.8,533.2,552.4,533.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M560.3,532.6c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S557.8,532.6,560.3,532.6z M560.3,533.2  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.3,1.7-4.6S561.6,533.2,560.3,533.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M573.5,542.7v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5H569v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.7-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H573.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M584.6,540.5c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8  c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L584.6,540.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M586.1,531c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L586.1,531z"
        />
        <path d="M498.8,422.9c-1.5,0.2-1.6,0.3-1.6,2v8.4c0,1.8,0.2,1.9,1.6,2v0.6h-5.1v-0.6c1.5-0.2,1.6-0.3,1.6-2v-4.2h-6v4.2  c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2V425c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.1-1.6,0.3-1.6,2v3.3h6V425  c0-1.8-0.2-1.9-1.6-2v-0.6h5.1V422.9z" />
        <path d="M501.8,436.3c-0.7,0-1.2-0.6-1.2-1.3c0,0,0-0.1,0-0.1c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0,0c0.7,0,1.3,0.7,1.2,1.4  c0,0,0,0,0,0C503.1,435.6,502.6,436.2,501.8,436.3C501.9,436.3,501.8,436.3,501.8,436.3z" />
        <path d="M511.4,422.9l0.1,0.2l-0.5,1.5h-4.3c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.4-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4  c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.9-2.1,1.4-3.3,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.3-0.3-0.4-0.5-0.3-0.7  c0-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.2,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2  c-0.6,0-1.3,0.1-1.9,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4L511.4,422.9z" />
        <path d="M519.6,432.2v1.5c0,1.4,0.1,1.5,1.4,1.6v0.6h-4.5v-0.6c1.4-0.1,1.5-0.3,1.5-1.7v-1.5h-4.8v-0.7c1.8-2.9,3.7-6,5.5-8.9h0.9  v8.5h1.8v1L519.6,432.2z M518,425c-1.1,1.9-2.4,4-3.7,6.1h3.7V425z" />
        <path
          style={{ fill: "#136736" }}
          d="M476,460c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L476,460z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M477.9,448.7l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.5  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L477.9,448.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M488.8,446.7c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S486.3,446.7,488.8,446.7z M488.8,447.3  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S490.2,447.3,488.8,447.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M496.6,446.7c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S494.2,446.7,496.6,446.7z M496.6,447.3  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S498,447.3,496.6,447.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M509.8,456.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5H501v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.8-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H509.8z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M521,454.7c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8  c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.2  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L521,454.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M522.5,445.1c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L522.5,445.1z"
        />
        <path d="M498.8,508.8c-1.5,0.2-1.6,0.3-1.6,2v8.4c0,1.8,0.2,1.8,1.6,2v0.6h-5.1v-0.6c1.5-0.2,1.6-0.3,1.6-2V515h-6v4.2  c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.1-1.6,0.3-1.6,2v3.3h6v-3.3  c0-1.8-0.2-1.9-1.6-2v-0.6h5.1V508.8z" />
        <path d="M501.8,522.2c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C503.1,521.4,502.6,522.1,501.8,522.2C501.9,522.1,501.8,522.1,501.8,522.2z" />
        <path d="M510.6,518v1.5c0,1.4,0.1,1.5,1.4,1.6v0.6h-4.5v-0.6c1.4-0.2,1.4-0.3,1.4-1.7V518h-4.8v-0.7c1.8-2.9,3.7-6,5.5-8.9h0.9v8.5  h1.8v1L510.6,518z M509,510.9c-1.1,1.8-2.4,4-3.6,6.1h3.6V510.9z" />
        <path d="M520.4,508.7l0.1,0.2l-0.5,1.5h-4.3c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.5-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4  c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.9-2.1,1.4-3.3,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.2-0.2-0.4-0.5-0.3-0.7  c0-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.2,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2  c-0.6,0-1.3,0.1-1.8,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4L520.4,508.7z" />
        <path
          style={{ fill: "#136736" }}
          d="M476,545.8c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L476,545.8z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M477.9,534.5l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.4,0.3-0.7,0.6-1.2,0.9c1.5,0.3,2.4,1.3,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.6  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L477.9,534.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M488.8,532.6c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S486.3,532.6,488.8,532.6z M488.8,533.2  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S490.2,533.2,488.8,533.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M496.6,532.6c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S494.2,532.6,496.6,532.6z M496.6,533.2  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S498,533.2,496.6,533.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M509.8,542.7v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5H501v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.7-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H509.8z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M521,540.5c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8  c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L521,540.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M522.5,531c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L522.5,531z"
        />
        <path d="M437.5,422.9c-1.5,0.2-1.6,0.3-1.6,2v8.4c0,1.8,0.2,1.9,1.6,2v0.6h-5.1v-0.6c1.5-0.2,1.6-0.3,1.6-2v-4.2h-6v4.2  c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2V425c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.1-1.6,0.3-1.6,2v3.3h6V425  c0-1.8-0.2-1.9-1.6-2v-0.6h5.1V422.9z" />
        <path d="M440.5,436.3c-0.7,0-1.2-0.6-1.2-1.3c0,0,0-0.1,0-0.1c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4  c0,0,0,0,0,0C441.8,435.6,441.2,436.2,440.5,436.3C440.5,436.3,440.5,436.3,440.5,436.3z" />
        <path d="M450.1,422.9l0.1,0.2l-0.5,1.5h-4.3c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.4-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4  c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.9-2.1,1.4-3.3,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.2-0.2-0.4-0.5-0.3-0.7  c0-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.2,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2  c-0.6,0-1.3,0.1-1.9,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4L450.1,422.9z" />
        <path d="M459.1,422.9l0.1,0.2l-0.5,1.5h-4.3c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.5-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4  c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.9-2.1,1.4-3.3,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.3-0.3-0.4-0.5-0.3-0.7  c0-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2  c-0.6,0-1.3,0.1-1.9,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4L459.1,422.9z" />
        <path
          style={{ fill: "#136736" }}
          d="M414.7,460c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L414.7,460z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M416.6,448.7l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.4,0.3-0.7,0.6-1.1,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.5  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L416.6,448.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M427.5,446.7c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S425,446.7,427.5,446.7z M427.5,447.3  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S428.8,447.3,427.5,447.3L427.5,447.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M435.3,446.7c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S432.8,446.7,435.3,446.7z M435.3,447.3  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S436.6,447.3,435.3,447.3L435.3,447.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M448.5,456.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5H444v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.7-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H448.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M459.6,454.7c-0.2,0.8-0.5,1.6-0.6,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8  c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5L453,449l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.2  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L459.6,454.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M461.1,445.1c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L461.1,445.1z"
        />
        <path d="M437.5,508.8c-1.5,0.2-1.6,0.3-1.6,2v8.4c0,1.8,0.2,1.8,1.6,2v0.6h-5.1v-0.6c1.5-0.2,1.6-0.3,1.6-2V515h-6v4.2  c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.1-1.6,0.3-1.6,2v3.3h6v-3.3  c0-1.8-0.2-1.9-1.6-2v-0.6h5.1V508.8z" />
        <path d="M440.5,522.2c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C441.8,521.4,441.2,522.1,440.5,522.2C440.5,522.1,440.5,522.1,440.5,522.2z" />
        <path d="M449.3,518v1.5c0,1.4,0.1,1.5,1.4,1.6v0.6h-4.5v-0.6c1.4-0.2,1.5-0.3,1.5-1.7V518h-4.8v-0.7c1.8-2.9,3.7-6,5.5-8.9h0.9v8.5  h1.8v1L449.3,518z M447.7,510.9c-1.1,1.8-2.4,4-3.7,6.1h3.7V510.9z" />
        <path d="M458.3,518v1.5c0,1.4,0.1,1.5,1.4,1.6v0.6h-4.5v-0.6c1.4-0.2,1.5-0.3,1.5-1.7V518h-4.8v-0.7c1.8-2.9,3.7-6,5.5-8.9h0.9v8.5  h1.8v1L458.3,518z M456.7,510.9c-1.1,1.8-2.4,4-3.6,6.1h3.6V510.9z" />
        <path
          style={{ fill: "#136736" }}
          d="M414.7,545.8c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L414.7,545.8z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M416.6,534.5l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.3,0.3-0.7,0.6-1.1,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.6  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L416.6,534.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M427.5,532.6c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S425,532.6,427.5,532.6z M427.5,533.2  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S428.8,533.2,427.5,533.2L427.5,533.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M435.3,532.6c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S432.8,532.6,435.3,532.6z M435.3,533.2  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.3,1.7-4.6S436.6,533.2,435.3,533.2L435.3,533.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M448.5,542.7v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5H444v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.7-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.2,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H448.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M459.6,540.5c-0.2,0.7-0.5,1.6-0.6,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8  c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L459.6,540.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M461.1,531c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L461.1,531z"
        />
        <path d="M373.3,422.9c-1.5,0.2-1.6,0.3-1.6,2v8.4c0,1.8,0.2,1.9,1.6,2v0.6h-5.1v-0.6c1.5-0.2,1.6-0.3,1.6-2v-4.2h-6v4.2  c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2V425c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.1-1.6,0.3-1.6,2v3.3h6V425  c0-1.8-0.2-1.9-1.6-2v-0.6h5.1V422.9z" />
        <path d="M376.3,436.3c-0.7,0-1.2-0.6-1.2-1.3c0,0,0-0.1,0-0.1c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0,0c0.7,0,1.3,0.7,1.2,1.4  c0,0,0,0,0,0C377.6,435.6,377.1,436.2,376.3,436.3C376.3,436.3,376.3,436.3,376.3,436.3z" />
        <path d="M385.9,422.9l0.1,0.2l-0.5,1.5h-4.3c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.4-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4  c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.9-2.1,1.4-3.3,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.2-0.2-0.4-0.5-0.3-0.7  c0-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.2,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2  c-0.6,0-1.3,0.1-1.9,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4L385.9,422.9z" />
        <path d="M391.2,428c0.4-0.2,0.8-0.3,1.2-0.4c2,0,3.3,1.7,3.3,3.9c0,2.6-1.6,4.7-3.9,4.7s-3.9-2-3.9-5.3c-0.1-3.4,1.9-6.5,5.1-7.9  c0.7-0.2,1.4-0.4,2.2-0.6l0.1,0.7c-0.7,0.2-1.4,0.4-2,0.7c-1.8,1-3,2.8-3.3,4.8L391.2,428z M391.6,428.9c-0.6,0-1.2,0.2-1.7,0.5  c-0.1,0.5-0.1,1-0.1,1.5c0,2.7,0.9,4.5,2.4,4.5s1.8-1.7,1.8-3.1C393.9,430.6,393.1,428.9,391.6,428.9L391.6,428.9z" />
        <path
          style={{ fill: "#136736" }}
          d="M350.5,460c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L350.5,460z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M352.4,448.7l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.4,0.3-0.7,0.6-1.1,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.5  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L352.4,448.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M363.3,446.7c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S360.8,446.7,363.3,446.7z M363.3,447.3  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S364.6,447.3,363.3,447.3L363.3,447.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M371.1,446.7c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S368.6,446.7,371.1,446.7z M371.1,447.3  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S372.4,447.3,371.1,447.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M384.3,456.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.7-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H384.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M395.4,454.7c-0.2,0.8-0.5,1.6-0.6,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.7-2.4,1.9-3.8  c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.2  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L395.4,454.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M396.9,445.1c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L396.9,445.1z"
        />
        <path d="M373.3,508.8c-1.5,0.2-1.6,0.3-1.6,2v8.4c0,1.8,0.2,1.8,1.6,2v0.6h-5.1v-0.6c1.5-0.2,1.6-0.3,1.6-2V515h-6v4.2  c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.1-1.6,0.3-1.6,2v3.3h6v-3.3  c0-1.8-0.2-1.9-1.6-2v-0.6h5.1V508.8z" />
        <path d="M376.3,522.2c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C377.6,521.4,377.1,522.1,376.3,522.2C376.3,522.1,376.3,522.1,376.3,522.2z" />
        <path d="M385.1,518v1.5c0,1.4,0.1,1.5,1.4,1.6v0.6H382v-0.6c1.4-0.2,1.4-0.3,1.4-1.7V518h-4.8v-0.7c1.8-2.9,3.7-6,5.5-8.9h0.9v8.5  h1.8v1L385.1,518z M383.5,510.9c-1.1,1.8-2.4,4-3.6,6.1h3.6V510.9z" />
        <path d="M388.3,511l0.9-1.4c0.6-0.8,1.5-1.2,2.4-1.2c1.5-0.1,2.8,1.1,2.8,2.6c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.1-0.6,1.6  c-0.4,0.4-0.8,0.8-1.3,1.2c1.6,0.3,2.7,1.6,2.8,3.2c0,3.4-3.6,4.9-4.9,4.9c-0.7,0-1.4-0.2-1.9-0.7c-0.2-0.2-0.3-0.5-0.3-0.7  c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.3-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.3,0,2.1-1.1,2.1-3.1c0.2-1.3-0.8-2.5-2.1-2.7  c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.2,0.2l-0.1-0.7c1.6-0.6,2.9-1.4,2.9-3c0-1.3-0.7-2-1.7-2c-1,0.1-1.9,0.7-2.3,1.7L388.3,511z" />
        <path
          style={{ fill: "#136736" }}
          d="M350.5,545.8c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L350.5,545.8z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M352.4,534.5l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.4,0.3-0.7,0.6-1.1,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.6  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L352.4,534.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M363.3,532.6c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S360.8,532.6,363.3,532.6z M363.3,533.2  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.3,1.7-4.6S364.6,533.2,363.3,533.2L363.3,533.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M371.1,532.6c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S368.6,532.6,371.1,532.6z M371.1,533.2  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S372.4,533.2,371.1,533.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M384.3,542.7v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.7-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.2,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H384.3z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M395.4,540.5c-0.2,0.7-0.5,1.6-0.6,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.7-2.4,1.9-3.8  c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L395.4,540.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M396.9,531c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L396.9,531z"
        />
        <path d="M309.7,422.9c-1.5,0.2-1.6,0.3-1.6,2v8.4c0,1.8,0.2,1.9,1.6,2v0.6h-5.1v-0.6c1.5-0.2,1.6-0.3,1.6-2v-4.2h-6v4.2  c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2V425c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.1-1.6,0.3-1.6,2v3.3h6V425  c0-1.8-0.2-1.9-1.6-2v-0.6h5.1V422.9z" />
        <path d="M312.7,436.3c-0.7,0-1.2-0.6-1.2-1.3c0,0,0-0.1,0-0.1c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4  c0,0,0,0,0,0C314,435.6,313.5,436.2,312.7,436.3C312.7,436.3,312.7,436.3,312.7,436.3z" />
        <path d="M322.3,422.9l0.1,0.2l-0.5,1.5h-4.3c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.5-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4  c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.9-2.1,1.4-3.3,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.2-0.2-0.4-0.5-0.3-0.7  c0-0.3,0.2-0.5,0.4-0.7c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.2,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2  c-0.6,0-1.3,0.1-1.9,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4L322.3,422.9z" />
        <path d="M332.2,423.1c-1.8,4.3-3.5,8.6-5.3,12.9l-1.4,0.2l-0.1-0.2c1.9-3.6,3.9-7.9,5.4-11.4h-3.9c-1.2,0-1.3,0.2-1.8,1.5h-0.6  c0.2-1.3,0.3-2.3,0.4-3.3h7.2L332.2,423.1z" />
        <path
          style={{ fill: "#136736" }}
          d="M286.9,460c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L286.9,460z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M293.9,454v1.2c0,1,0.1,1.1,1.2,1.2v0.5h-3.9v-0.5c1.2-0.1,1.3-0.2,1.3-1.3V454h-4.2v-0.5  c1.5-2.2,3.3-4.6,4.8-6.7h0.8v6.4h1.5v0.8H293.9z M292.4,448.6c-1,1.4-2.1,3-3.2,4.6h3.2V448.6z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M303,454.7c-0.2,0.8-0.5,1.6-0.6,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8  c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.2  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L303,454.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M310.2,446.9l0.1,0.1l-0.4,1.1h-3.7c-0.1,0.9-0.4,2.1-0.5,2.8c0.6-0.2,1.3-0.4,1.9-0.5c1.5-0.1,2.9,1,3,2.6  c0,0,0,0.1,0,0.1c0,1.1-0.5,2.1-1.4,2.7c-0.8,0.7-1.8,1.1-2.9,1.1c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.2-0.3-0.4-0.3-0.6  c0-0.2,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.6,1.2,0.9,2,0.9c1.3,0,1.9-1.1,1.9-2.3c0.1-1.3-0.9-2.3-2.1-2.4  c-0.1,0-0.3,0-0.4,0c-0.5,0-1.1,0.1-1.6,0.3l0-0.1c0.3-1.3,0.6-3.5,0.8-4.8L310.2,446.9z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M320.7,456.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.7-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H320.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M331.8,454.7c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8  c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L331.8,454.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M333.4,445.1c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L333.4,445.1z"
        />
        <path d="M309.7,508.8c-1.5,0.2-1.6,0.3-1.6,2v8.4c0,1.8,0.2,1.8,1.6,2v0.6h-5.1v-0.6c1.5-0.2,1.6-0.3,1.6-2V515h-6v4.2  c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.1-1.6,0.3-1.6,2v3.3h6v-3.3  c0-1.8-0.2-1.9-1.6-2v-0.6h5.1V508.8z" />
        <path d="M312.7,522.2c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C314,521.4,313.5,522.1,312.7,522.2C312.7,522.1,312.7,522.1,312.7,522.2z" />
        <path d="M321.5,518v1.5c0,1.4,0.1,1.5,1.4,1.6v0.6h-4.5v-0.6c1.4-0.2,1.5-0.3,1.5-1.7V518h-4.8v-0.7c1.8-2.9,3.7-6,5.5-8.9h0.9v8.5  h1.8v1L321.5,518z M319.9,510.9c-1.1,1.8-2.4,4-3.7,6.1h3.7V510.9z" />
        <path d="M332.1,518.9c-0.3,1-0.6,2.1-0.8,2.9h-7v-0.5c1-1.1,2.1-2.4,3-3.6c1.2-1.6,2.2-3.3,2.2-5s-0.8-2.7-2.1-2.7s-2,1.1-2.5,2  l-0.4-0.5l1.1-1.8c0.6-0.8,1.6-1.3,2.6-1.3c1.8,0,3.2,1.4,3.2,3.7c0,1.8-0.6,3-2.3,5.1c-0.8,0.9-1.9,2.2-2.7,3h3.4  c1,0,1.3-0.1,1.9-1.4L332.1,518.9z" />
        <path
          style={{ fill: "#136736" }}
          d="M286.9,545.8c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c-0.1,2.6,0.9,5.1,2.6,7L286.9,545.8z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M288.8,534.5l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.3,0.3-0.7,0.6-1.1,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.6  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L288.8,534.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M299.7,532.6c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S297.2,532.6,299.7,532.6z M299.7,533.2  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S301,533.2,299.7,533.2L299.7,533.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M307.5,532.6c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S305,532.6,307.5,532.6z M307.5,533.2  c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S308.9,533.2,307.5,533.2L307.5,533.2z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M320.7,542.7v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.5,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.7-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.2,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H320.7z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M331.8,540.5c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8  c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.2  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L331.8,540.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M333.4,531c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L333.4,531z"
        />
        <path d="M237,508.8c-1.5,0.2-1.6,0.3-1.6,2v8.4c0,1.8,0.2,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.7-0.3,1.7-2V515h-6v4.2  c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.4,0.1-1.6,0.3-1.6,2v3.3h6v-3.3  c0-1.8-0.2-1.9-1.7-2v-0.6h5V508.8z" />
        <path d="M240,522.2c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0  C241.3,521.4,240.8,522.1,240,522.2C240.1,522.1,240,522.1,240,522.2z" />
        <path d="M248.8,518v1.5c0,1.4,0.1,1.5,1.4,1.6v0.6h-4.5v-0.6c1.4-0.2,1.4-0.3,1.4-1.7V518h-4.8v-0.7c1.8-2.9,3.7-6,5.5-8.9h0.9v8.5  h1.8v1L248.8,518z M247.2,510.9c-1.1,1.8-2.4,4-3.6,6.1h3.6V510.9z" />
        <path d="M252.7,521.9v-0.6c2-0.1,2.1-0.3,2.1-1.8v-8c0-1.1-0.2-1.1-1-1.2L253,510v-0.6c1.2-0.2,2.4-0.5,3.5-1v11  c0,1.6,0.1,1.7,2.1,1.8v0.6L252.7,521.9z" />
        <path
          style={{ fill: "#136736" }}
          d="M214.2,545.8c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7  c0,2.6,0.9,5.1,2.6,7L214.2,545.8z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M216.1,534.5l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2  c-0.3,0.3-0.7,0.6-1.1,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.1,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.6  c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0  c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3  L216.1,534.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M227.1,532.6c1.4-0.1,2.6,0.9,2.7,2.3c0,0,0,0.1,0,0.1c0,0.5-0.2,0.9-0.5,1.3c-0.4,0.4-0.8,0.7-1.3,1  c1.1,0.6,2.2,1.4,2.2,2.8c-0.1,1.7-1.6,3-3.3,2.9c0,0,0,0,0,0c-1.8,0-3.2-1-3.2-2.7c0-0.6,0.2-1.2,0.7-1.6c0.5-0.4,0.9-0.7,1.4-1  c-1-0.4-1.7-1.4-1.8-2.5c0-1.5,1.3-2.7,2.8-2.7C227,532.6,227.1,532.6,227.1,532.6z M227.1,542.3c0.9,0,1.7-0.7,1.7-1.5  c0-0.1,0-0.2,0-0.3c0-1.3-1-1.9-2.4-2.5c-0.8,0.4-1.3,1.2-1.3,2.1c-0.1,1.1,0.7,2.1,1.8,2.2C227,542.3,227.1,542.3,227.1,542.3z   M226.9,533.1c-0.8,0-1.4,0.6-1.4,1.4c0,0.1,0,0.1,0,0.2c0,1.2,0.8,1.6,2,2.2c0.6-0.4,1-1.2,1-1.9  C228.5,533.7,227.7,533.1,226.9,533.1L226.9,533.1z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M237.5,532.8l0.1,0.1l-0.4,1.2h-3.7c-0.1,0.8-0.4,2.1-0.5,2.8c0.6-0.2,1.3-0.4,1.9-0.5c1.5-0.1,2.9,1,3,2.6  c0,0,0,0.1,0,0.1c0,1.1-0.5,2.1-1.4,2.7c-0.8,0.7-1.8,1.1-2.9,1.1c-0.6,0-1.2-0.2-1.7-0.6c-0.3-0.2-0.3-0.3-0.3-0.6  c0-0.2,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.6,1.2,0.9,2,0.9c1.3,0,1.9-1.1,1.9-2.3c0.1-1.3-0.9-2.4-2.2-2.4  c-0.1,0-0.3,0-0.4,0c-0.6,0-1.1,0.1-1.6,0.3l-0.1-0.1c0.3-1.3,0.6-3.5,0.8-4.8L237.5,532.8z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M248,542.7v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7  v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.4v-0.5c1-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8  c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3  c0.4-0.3,0.7-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4  c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H248z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M259.1,540.5c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8  c0-1.3-0.7-2-1.8-2c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3  c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L259.1,540.5z"
        />
        <path
          style={{ fill: "#136736" }}
          d="M260.7,531c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7  c0.1-2.6-0.9-5.1-2.6-7L260.7,531z"
        />
        <g id="_1-3">
          <path d="M114.7,904c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.8,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2v-4.2h-6v4.2   c0,1.8,0.2,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2V906c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.2-1.6,0.3-1.6,2v3.3h6V906   c0-1.8-0.2-1.9-1.7-2v-0.6h5.1L114.7,904z" />
          <path d="M117.6,917.4c-0.7,0-1.2-0.6-1.2-1.3c0,0,0-0.1,0-0.1c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0,0c0.7,0,1.3,0.7,1.2,1.4   c0,0,0,0,0,0C118.9,916.6,118.4,917.3,117.6,917.4C117.7,917.4,117.7,917.4,117.6,917.4z" />
          <path d="M128,914.1c-0.2,1-0.6,2.1-0.8,2.9h-7v-0.5c1-1.1,2.1-2.4,3-3.6c1.2-1.6,2.2-3.2,2.2-5s-0.8-2.7-2.1-2.7s-2,1.1-2.5,2   l-0.4-0.5l1.1-1.8c0.6-0.8,1.6-1.3,2.6-1.3c1.8,0,3.2,1.4,3.2,3.7c0,1.8-0.6,3-2.3,5.1c-0.8,0.9-1.9,2.2-2.7,3h3.4   c1,0,1.3-0.1,1.9-1.4L128,914.1z" />
          <path d="M133.2,903.7c2.8,0,3.9,3.6,3.9,6.8s-1,6.8-3.9,6.8s-3.9-3.5-3.9-6.8C129.3,907.4,130.3,903.7,133.2,903.7z M133.2,904.4   c-1.6,0-2,3.1-2,6s0.5,6.1,2,6.1s2-3.1,2-6.1S134.7,904.4,133.2,904.4L133.2,904.4z" />
          <path d="M192.6,904c-1.5,0.2-1.6,0.3-1.6,2v8.4c0,1.8,0.2,1.8,1.6,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2v-4.2h-6v4.2   c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2V906c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.2-1.6,0.3-1.6,2v3.3h6V906   c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V904z" />
          <path d="M195.6,917.3c-0.8-0.1-1.3-0.9-1.2-1.7c0.1-0.6,0.6-1,1.2-1.2c0.8,0.1,1.3,0.8,1.2,1.6   C196.7,916.8,196.2,917.3,195.6,917.3z" />
          <path d="M199.3,917.1v-0.6c2-0.1,2.1-0.3,2.1-1.8v-8c0-1.1-0.2-1.1-1-1.2l-0.8-0.1v-0.6c1.2-0.2,2.4-0.5,3.5-1v11   c0,1.6,0.1,1.7,2.1,1.8v0.6L199.3,917.1z" />
          <path d="M212.9,911l-1.2,0.7c-0.3,0.2-0.7,0.3-1.1,0.3c-2,0-3.4-1.8-3.4-4c0-2,1.4-4.4,3.9-4.4s3.8,2.4,3.8,5.3   c0.4,4.2-2.7,8-6.9,8.4c0,0-0.1,0-0.1,0l-0.1-0.7C210.4,916.1,212.3,914.2,212.9,911z M210.9,904.4c-1.4,0-1.8,1.5-1.8,3   c0,1.8,0.9,3.4,2.4,3.4c0.6,0,1.1-0.2,1.5-0.5c0.1-0.5,0.1-0.9,0.1-1.4C213,906.9,212.6,904.4,210.9,904.4L210.9,904.4z" />
          <path d="M255.7,903.4c-1.5,0.2-1.6,0.3-1.6,2v8.4c0,1.8,0.2,1.8,1.6,2v0.6h-5.1v-0.6c1.5-0.2,1.6-0.3,1.6-2v-4.2h-6v4.2   c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.4,0.2-1.6,0.3-1.6,2v3.3h6v-3.3   c0-1.8-0.2-1.9-1.6-2v-0.6h5.1V903.4z" />
          <path d="M258.7,916.8c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0   C260,916.1,259.5,916.7,258.7,916.8C258.7,916.8,258.7,916.8,258.7,916.8z" />
          <path d="M262.4,916.5v-0.6c2-0.1,2.1-0.3,2.1-1.8v-8c0-1.1-0.2-1.1-1-1.2l-0.8-0.1v-0.6c1.2-0.2,2.4-0.5,3.5-1V914   c0,1.6,0.1,1.7,2.1,1.8v0.6L262.4,916.5z" />
          <path d="M274.4,903.1c1.7,0,3,1.3,3.1,2.9c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.2-0.5,1.7c-0.5,0.5-1,0.9-1.5,1.3   c1.3,0.8,2.5,1.8,2.5,3.7c0,2.1-1.6,3.8-3.7,3.9c0,0,0,0-0.1,0c-1.9,0.1-3.5-1.3-3.6-3.1c0-0.1,0-0.2,0-0.4c0-0.8,0.3-1.5,0.8-2.1   c0.5-0.5,1.1-0.9,1.6-1.3c-1.2-0.7-2-1.9-2-3.3C270.9,904.7,272.4,903.2,274.4,903.1C274.3,903.1,274.3,903.1,274.4,903.1z    M274.4,916c1.2,0,1.9-0.9,1.9-2.4s-1.2-2.5-2.7-3.3c-0.9,0.6-1.5,1.7-1.4,2.8C272.1,915,273.2,916,274.4,916L274.4,916z    M274.1,903.8c-1.1,0-1.6,1-1.6,2.1c0,1.5,0.9,2.1,2.3,3c0.8-0.6,1.2-1.6,1.1-2.6C276,904.5,275,903.8,274.1,903.8L274.1,903.8z" />
          <path d="M318.9,903.6c-1.5,0.2-1.6,0.3-1.6,2v8.4c0,1.8,0.2,1.8,1.6,2v0.6h-5.1V916c1.5-0.2,1.6-0.3,1.6-2v-4.1h-6v4.2   c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2V903h5v0.6c-1.5,0.2-1.6,0.3-1.6,2v3.4h6v-3.3   c0-1.8-0.2-1.9-1.6-2V903h5.1V903.6z" />
          <path d="M321.9,917c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0   C323.2,916.3,322.7,916.9,321.9,917C322,917,321.9,917,321.9,917z" />
          <path d="M325.6,916.7v-0.6c2-0.1,2.1-0.3,2.1-1.8v-8c0-1.1-0.2-1.1-1-1.2l-0.8-0.1v-0.6c1.2-0.2,2.4-0.5,3.5-1v11   c0,1.6,0.1,1.7,2.1,1.8v0.6L325.6,916.7z" />
          <path d="M341.5,903.8c-1.8,4.3-3.5,8.5-5.3,12.9l-1.4,0.2l-0.1-0.2c1.9-3.6,3.9-7.8,5.4-11.4h-3.9c-1.2,0-1.3,0.2-1.8,1.6h-0.6   c0.2-1.3,0.3-2.3,0.4-3.3h7.2L341.5,903.8z" />
          <path d="M382.3,903.3c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.8,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.6-0.3,1.6-2v-4.2h-6.1v4.2   c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.2-1.6,0.3-1.6,2v3.3h6v-3.3   c0-1.8-0.2-1.9-1.6-2v-0.6h5.1L382.3,903.3z" />
          <path d="M385.2,916.7c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0   C386.5,916,386,916.6,385.2,916.7C385.3,916.7,385.3,916.7,385.2,916.7z" />
          <path d="M388.9,916.4v-0.6c2-0.1,2.1-0.3,2.1-1.8v-8c0-1.1-0.2-1.1-1-1.2l-0.8-0.1V904c1.2-0.2,2.4-0.5,3.5-1v11   c0,1.6,0.1,1.7,2.1,1.8v0.6L388.9,916.4z" />
          <path d="M400.1,908.4c0.4-0.2,0.8-0.4,1.2-0.4c2,0,3.3,1.7,3.3,3.9c0,2.6-1.6,4.7-3.9,4.7s-3.9-2-3.9-5.3c-0.1-3.4,1.9-6.5,5.1-7.9   c0.7-0.2,1.5-0.4,2.2-0.6l0.1,0.7c-0.7,0.2-1.4,0.4-2,0.7c-1.8,1-3,2.8-3.3,4.8L400.1,908.4z M400.5,909.3c-0.6,0-1.2,0.2-1.7,0.5   c-0.1,0.5-0.1,1-0.1,1.5c0,2.7,0.9,4.5,2.4,4.5s1.8-1.7,1.8-3.1C402.9,911,402.1,909.3,400.5,909.3L400.5,909.3z" />
          <path d="M445.7,903.7c-1.5,0.2-1.6,0.3-1.6,2v8.4c0,1.8,0.2,1.8,1.6,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2V910h-6v4.2   c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.2-1.6,0.3-1.6,2v3.3h6v-3.3   c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V903.7z" />
          <path d="M448.7,917.1c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0   C450,916.4,449.5,917,448.7,917.1C448.7,917.1,448.7,917.1,448.7,917.1z" />
          <path d="M452.3,916.8v-0.6c2-0.1,2.1-0.3,2.1-1.8v-8c0-1.1-0.2-1.1-1-1.2l-0.8-0.1v-0.6c1.2-0.2,2.4-0.5,3.5-1v11   c0,1.6,0.1,1.7,2.1,1.8v0.6L452.3,916.8z" />
          <path d="M467.3,903.7l0.1,0.2l-0.5,1.5h-4.3c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.4-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4   c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.9-2.1,1.4-3.3,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.2-0.2-0.4-0.5-0.3-0.7   c0-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2   c-0.6,0-1.3,0.1-1.9,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4L467.3,903.7z" />
          <path d="M509,903.4c-1.5,0.2-1.6,0.3-1.6,2v8.4c0,1.8,0.2,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.7-0.3,1.7-2v-4.2h-6v4.2   c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.2-1.6,0.3-1.6,2v3.3h6v-3.3   c0-1.8-0.2-1.9-1.7-2v-0.6h5V903.4z" />
          <path d="M512,916.8c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.5-1.3,1.2-1.4c0,0,0,0,0,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0   C513.3,916.1,512.7,916.7,512,916.8C512,916.8,512,916.8,512,916.8z" />
          <path d="M515.7,916.5v-0.6c2-0.1,2.1-0.3,2.1-1.8v-8c0-1.1-0.2-1.1-1-1.2l-0.8-0.1v-0.6c1.2-0.2,2.4-0.5,3.5-1V914   c0,1.6,0.1,1.7,2.1,1.8v0.6L515.7,916.5z" />
          <path d="M529.8,912.7v1.5c0,1.4,0.1,1.5,1.4,1.6v0.6h-4.5v-0.6c1.4-0.2,1.4-0.3,1.4-1.7v-1.5h-4.8V912c1.8-2.9,3.7-6,5.5-8.8h0.9   v8.5h1.8v1.1L529.8,912.7z M528.2,905.5c-1.1,1.8-2.4,4-3.7,6.1h3.7V905.5z" />
          <path d="M572.5,903.4c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.8,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2v-4.2h-6v4.2   c0,1.8,0.2,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.2-1.6,0.3-1.6,2v3.3h6v-3.3   c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V903.4z" />
          <path d="M575.4,916.8c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0   C576.7,916.1,576.2,916.7,575.4,916.8C575.5,916.8,575.5,916.8,575.4,916.8z" />
          <path d="M579.1,916.5v-0.6c2-0.1,2.1-0.3,2.1-1.8v-8c0-1.1-0.2-1.1-1-1.2l-0.8-0.1v-0.6c1.2-0.2,2.4-0.5,3.5-1V914   c0,1.6,0.1,1.7,2.1,1.8v0.6L579.1,916.5z" />
          <path d="M587.5,905.7l0.9-1.3c0.6-0.8,1.5-1.2,2.4-1.2c1.5-0.1,2.8,1.1,2.8,2.6c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.1-0.6,1.5   c-0.4,0.4-0.8,0.8-1.3,1.1c1.6,0.3,2.7,1.6,2.8,3.3c0,3.4-3.6,4.9-4.9,4.9c-0.7,0-1.4-0.3-1.9-0.7c-0.2-0.2-0.3-0.4-0.3-0.7   c0-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.3-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.3,0,2.2-1.1,2.2-3.1c0.2-1.3-0.8-2.5-2.1-2.7   c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.2,0.2l-0.1-0.7c1.6-0.6,2.9-1.4,2.9-3c0.2-0.9-0.5-1.8-1.4-2c-0.1,0-0.2,0-0.3,0   c-1,0.1-1.9,0.7-2.3,1.7L587.5,905.7z" />
          <path d="M635.5,903.4c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.8,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2v-4.2h-6v4.2   c0,1.8,0.2,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.2-1.6,0.3-1.6,2v3.3h6v-3.3   c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V903.4z" />
          <path d="M638.4,916.8c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0   C639.7,916.1,639.2,916.7,638.4,916.8C638.5,916.8,638.5,916.8,638.4,916.8z" />
          <path d="M642.1,916.5v-0.6c2-0.1,2.1-0.3,2.1-1.8v-8c0-1.1-0.2-1.1-1-1.2l-0.8-0.1v-0.6c1.2-0.2,2.4-0.5,3.5-1V914   c0,1.6,0.1,1.7,2.1,1.8v0.6L642.1,916.5z" />
          <path d="M657.8,913.6c-0.3,1-0.6,2.1-0.8,2.9h-7V916c1-1.1,2.1-2.4,3-3.6c1.2-1.6,2.2-3.3,2.2-5s-0.8-2.7-2.1-2.7s-2,1.1-2.5,2   l-0.5-0.5l1.1-1.8c0.6-0.8,1.6-1.3,2.6-1.3c1.8,0,3.2,1.4,3.2,3.7c0,1.8-0.6,3-2.3,5.1c-0.8,0.9-1.9,2.2-2.7,3h3.4   c1,0,1.3-0.1,1.9-1.4L657.8,913.6z" />
          <path d="M694.6,903.5c-1.5,0.2-1.7,0.3-1.7,2v8.5c0,1.8,0.2,1.8,1.7,2v0.6h-5.1V916c1.5-0.2,1.7-0.3,1.7-2v-4.2h-6v4.2   c0,1.8,0.2,1.8,1.6,2v0.6h-5V916c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2V903h5v0.6c-1.5,0.2-1.6,0.3-1.6,2v3.3h6v-3.3   c0-1.8-0.2-1.9-1.7-2V903h5.1V903.5z" />
          <path d="M697.6,916.9c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0   C698.9,916.2,698.4,916.8,697.6,916.9C697.7,916.9,697.6,916.9,697.6,916.9z" />
          <path d="M701.3,916.6V916c2-0.2,2.1-0.3,2.1-1.8v-8c0-1.1-0.2-1.1-1-1.2l-0.8-0.1v-0.6c1.2-0.2,2.4-0.5,3.5-1v11   c0,1.6,0.1,1.7,2.1,1.8v0.6L701.3,916.6z" />
          <path d="M710.3,916.6V916c2-0.2,2.1-0.3,2.1-1.8v-8c0-1.1-0.2-1.1-1-1.2l-0.8-0.1v-0.6c1.2-0.2,2.4-0.5,3.5-1v11   c0,1.6,0.1,1.7,2.1,1.8v0.6L710.3,916.6z" />
        </g>
        <g id="_1-4">
          <path d="M919.9,101.6c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.8,1.7,2v0.6h-5.1V114c1.5-0.2,1.7-0.3,1.7-2v-4.1h-6v4.2   c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2V101h5v0.6c-1.5,0.2-1.6,0.3-1.6,2v3.4h6v-3.3   c0-1.8-0.2-1.9-1.7-2V101h5.1V101.6z" />
          <path d="M922.9,115c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0   C924.2,114.3,923.6,114.9,922.9,115C922.9,115,922.9,115,922.9,115z" />
          <path d="M926.5,114.7v-0.6c2-0.1,2.1-0.3,2.1-1.8v-8c0-1.1-0.2-1.1-1-1.2l-0.8-0.1v-0.6c1.2-0.2,2.4-0.5,3.5-1v11   c0,1.6,0.1,1.7,2.1,1.8v0.6L926.5,114.7z" />
          <path d="M919.9,174.4c-1.5,0.2-1.7,0.3-1.7,2.1v8.4c0,1.8,0.2,1.8,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2v-4.2h-6v4.2   c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2.1v-0.6h5v0.6c-1.5,0.1-1.6,0.3-1.6,2.1v3.3h6   v-3.3c0-1.8-0.2-1.9-1.7-2.1v-0.6h5.1V174.4z" />
          <path d="M922.9,187.8c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0   C924.2,187.1,923.6,187.7,922.9,187.8C922.9,187.8,922.9,187.8,922.9,187.8z" />
          <path d="M933.3,184.6c-0.3,1-0.6,2.1-0.8,2.9h-7V187c1-1.1,2.1-2.4,3-3.6c1.2-1.6,2.2-3.3,2.2-5s-0.8-2.7-2.1-2.7s-2,1.1-2.5,1.9   l-0.4-0.5l1.1-1.8c0.6-0.8,1.6-1.3,2.6-1.3c1.8,0,3.2,1.4,3.2,3.7c0,1.8-0.6,3-2.3,5.1c-0.8,0.9-1.9,2.2-2.7,3h3.4   c1,0,1.3-0.1,1.9-1.4L933.3,184.6z" />
          <path d="M919.9,236.9c-1.5,0.2-1.7,0.3-1.7,2.1v8.4c0,1.8,0.2,1.9,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2v-4.2h-6v4.2   c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2V239c0-1.8-0.2-1.9-1.6-2.1v-0.6h5v0.6c-1.5,0.1-1.6,0.3-1.6,2.1v3.3h6V239   c0-1.8-0.2-1.9-1.7-2.1v-0.6h5.1V236.9z" />
          <path d="M922.9,250.3c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0   C924.2,249.6,923.6,250.3,922.9,250.3C922.9,250.3,922.9,250.3,922.9,250.3z" />
          <path d="M925.9,239.2l0.9-1.4c0.6-0.8,1.5-1.2,2.4-1.2c1.5-0.1,2.8,1.1,2.8,2.6c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.1-0.6,1.6   c-0.4,0.4-0.8,0.8-1.3,1.1c1.6,0.3,2.7,1.6,2.8,3.2c0,3.4-3.6,4.9-4.9,4.9c-0.7,0-1.4-0.3-1.9-0.7c-0.2-0.2-0.3-0.5-0.3-0.7   c0-0.3,0.2-0.6,0.4-0.9c0.1-0.1,0.3-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.3,0,2.2-1.1,2.2-3.1c0.2-1.3-0.8-2.5-2.1-2.7   c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1.2,0.2l-0.1-0.7c1.7-0.6,2.9-1.4,2.9-3c0.2-0.9-0.5-1.8-1.4-2c-0.1,0-0.2,0-0.3,0   c-1,0.1-1.9,0.8-2.3,1.7L925.9,239.2z" />
          <path d="M919.9,300.4c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.8,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2v-4.2h-6v4.2   c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.1-1.6,0.3-1.6,2v3.3h6v-3.3   c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V300.4z" />
          <path d="M922.9,313.8c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0   C924.2,313.1,923.6,313.7,922.9,313.8C922.9,313.8,922.9,313.8,922.9,313.8z" />
          <path d="M931.7,309.7v1.5c0,1.4,0.1,1.5,1.4,1.6v0.6h-4.5v-0.6c1.4-0.1,1.4-0.3,1.4-1.7v-1.5h-4.8V309c1.8-2.9,3.7-6,5.5-8.9h0.9   v8.5h1.8v1.1H931.7z M930.1,302.5c-1.1,1.9-2.4,4-3.7,6.1h3.7V302.5z" />
          <path d="M919.9,363.4c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.8,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2v-4.2h-6v4.2   c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.1-1.6,0.3-1.6,2v3.3h6v-3.3   c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V363.4z" />
          <path d="M922.9,376.8c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0   C924.2,376.1,923.6,376.7,922.9,376.8C922.9,376.8,922.9,376.8,922.9,376.8z" />
          <path d="M932.5,363.3l0.1,0.2l-0.5,1.5h-4.3c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.5-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4   c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.9-2.1,1.4-3.3,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.2-0.2-0.4-0.5-0.3-0.7   c0-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.1,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2   c-0.6,0-1.3,0.1-1.8,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4L932.5,363.3z" />
          <path d="M919.9,427.3c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.9,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2v-4.2h-6v4.2   c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.1-1.6,0.3-1.6,2v3.3h6v-3.3   c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V427.3z" />
          <path d="M922.9,440.6c-0.7,0-1.2-0.6-1.2-1.3c0,0,0-0.1,0-0.1c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0,0c0.7,0,1.3,0.7,1.2,1.4   c0,0,0,0,0,0C924.2,439.9,923.7,440.6,922.9,440.6C922.9,440.6,922.9,440.6,922.9,440.6z" />
          <path d="M928.8,432.4c0.4-0.2,0.8-0.4,1.2-0.4c2,0,3.3,1.7,3.3,3.9c0,2.5-1.6,4.7-3.9,4.7s-3.9-2-3.9-5.3c-0.1-3.4,1.9-6.5,5.1-7.9   c0.7-0.2,1.4-0.4,2.2-0.6l0.1,0.7c-0.7,0.2-1.4,0.4-2,0.7c-1.8,1-3,2.8-3.3,4.8L928.8,432.4z M929.2,433.3c-0.6,0-1.2,0.2-1.7,0.5   c-0.1,0.5-0.1,1-0.1,1.5c0,2.7,0.9,4.5,2.3,4.5s1.8-1.7,1.8-3.1C931.5,434.9,930.8,433.3,929.2,433.3z" />
          <path d="M919.9,490.3c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.9,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2v-4.2h-6v4.2   c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.1-1.6,0.3-1.6,2v3.3h6v-3.3   c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V490.3z" />
          <path d="M922.9,503.6c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0   C924.2,502.9,923.6,503.6,922.9,503.6C922.9,503.6,922.9,503.6,922.9,503.6z" />
          <path d="M933.4,490.5c-1.8,4.3-3.5,8.6-5.3,12.9l-1.4,0.2l-0.1-0.2c1.9-3.6,3.9-7.9,5.4-11.4h-3.9c-1.2,0-1.3,0.2-1.8,1.5h-0.6   c0.2-1.3,0.3-2.3,0.4-3.3h7.2L933.4,490.5z" />
          <path d="M919.9,553.7c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.8,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2V560h-6v4.2   c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.2-1.6,0.3-1.6,2v3.2h6v-3.3   c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V553.7z" />
          <path d="M922.9,567.1c-0.7,0-1.2-0.6-1.2-1.4c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4c0,0,0,0,0,0   C924.2,566.4,923.6,567,922.9,567.1C922.9,567.1,922.9,567.1,922.9,567.1z" />
          <path d="M929.5,553.4c1.7,0,3,1.3,3.1,2.9c0,0.1,0,0.2,0,0.2c0,0.6-0.2,1.2-0.5,1.7c-0.5,0.5-1,0.9-1.5,1.3   c1.3,0.8,2.5,1.8,2.5,3.7c0,2.1-1.6,3.8-3.7,3.9c0,0-0.1,0-0.1,0c-1.9,0.1-3.5-1.3-3.6-3.1c0-0.1,0-0.2,0-0.4   c0-0.8,0.3-1.5,0.8-2.1c0.5-0.5,1.1-0.9,1.7-1.3c-1.2-0.7-2-1.9-2-3.3C926.1,555,927.6,553.4,929.5,553.4   C929.5,553.4,929.5,553.4,929.5,553.4z M929.5,566.3c1.2,0,1.9-0.9,1.9-2.4s-1.2-2.5-2.7-3.3c-0.9,0.6-1.5,1.7-1.4,2.8   C927.3,565.3,928.4,566.3,929.5,566.3z M929.3,554.1c-1.1,0-1.6,1-1.6,2.1c0,1.5,0.9,2.1,2.3,3c0.8-0.6,1.2-1.6,1.1-2.5   C931.1,554.8,930.2,554.1,929.3,554.1z" />
          <path d="M919.9,616.7c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.8,1.7,2v0.6h-5.1v-0.6c1.5-0.2,1.7-0.3,1.7-2V623h-6v4.2   c0,1.8,0.1,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.2-1.6,0.3-1.6,2v3.2h6v-3.3   c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V616.7z" />
          <path d="M922.9,630.1c-0.8-0.1-1.3-0.9-1.2-1.7c0.1-0.6,0.6-1,1.2-1.2c0.8,0.1,1.3,0.8,1.2,1.6C924,629.5,923.5,630,922.9,630.1z" />
          <path d="M931.2,623.7l-1.2,0.7c-0.3,0.2-0.7,0.3-1.1,0.3c-2,0-3.3-1.8-3.3-4c0-2,1.4-4.4,3.8-4.4s3.8,2.4,3.8,5.3   c0.4,4.2-2.7,8-6.9,8.4c0,0-0.1,0-0.1,0l-0.2-0.7C928.7,628.9,930.6,626.9,931.2,623.7z M929.2,617.1c-1.4,0-1.8,1.5-1.8,3   c0,1.8,0.9,3.4,2.4,3.4c0.6,0,1.1-0.2,1.5-0.5c0.1-0.5,0.1-0.9,0.1-1.4C931.3,619.7,930.9,617.1,929.2,617.1L929.2,617.1z" />
          <path d="M916.1,680.1c-1.5,0.2-1.7,0.3-1.7,2v8.4c0,1.8,0.2,1.8,1.7,2v0.6H911v-0.6c1.5-0.2,1.7-0.3,1.7-2v-4.1h-6v4.2   c0,1.8,0.2,1.8,1.6,2v0.6h-5v-0.6c1.5-0.2,1.6-0.3,1.6-2v-8.4c0-1.8-0.2-1.9-1.6-2v-0.6h5v0.6c-1.5,0.2-1.6,0.3-1.6,2v3.3h6v-3.3   c0-1.8-0.2-1.9-1.7-2v-0.6h5.1V680.1z" />
          <path d="M919.1,693.5c-0.7,0-1.2-0.6-1.2-1.3c0,0,0-0.1,0-0.1c-0.1-0.7,0.4-1.3,1.1-1.4c0,0,0,0,0.1,0c0.7,0,1.3,0.7,1.2,1.4   c0,0,0,0,0,0C920.4,692.8,919.9,693.4,919.1,693.5C919.1,693.5,919.1,693.5,919.1,693.5z" />
          <path d="M922.8,693.2v-0.6c2-0.1,2.1-0.3,2.1-1.8v-8c0-1.1-0.2-1.1-1-1.2l-0.8-0.1v-0.6c1.2-0.2,2.4-0.5,3.5-1v11   c0,1.6,0.1,1.7,2.1,1.8v0.6L922.8,693.2z" />
          <path d="M934.6,679.8c2.8,0,3.8,3.6,3.8,6.8s-1,6.8-3.8,6.8s-3.9-3.5-3.9-6.8C930.7,683.6,931.8,679.8,934.6,679.8z M934.6,680.6   c-1.6,0-2,3.1-2,6s0.5,6.1,2,6.1s2-3.1,2-6.1S936.2,680.6,934.6,680.6z" />
          <path
            style={{ fill: "#136736" }}
            d="M897.4,144.4c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7   c-0.1,2.6,0.9,5.1,2.6,7L897.4,144.4z"
          />
          <path
            style={{ fill: "#136736" }}
            d="M899.3,133l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2   c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.6   c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0   c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3   L899.3,133z"
          />
          <path
            style={{ fill: "#136736" }}
            d="M913.7,131.5c-1.5,3.3-3,6.5-4.6,9.8l-1.2,0.1l-0.1-0.2c1.7-2.8,3.3-5.7,4.7-8.6H909c-1,0-1.1,0.1-1.6,1.2H907   c0.2-1,0.3-1.8,0.3-2.5h6.3L913.7,131.5z"
          />
          <path
            style={{ fill: "#136736" }}
            d="M920.7,131.3l0.1,0.1l-0.4,1.2h-3.7c-0.1,0.8-0.3,2.1-0.5,2.8c0.6-0.2,1.3-0.4,1.9-0.5c1.5-0.1,2.9,1,3,2.6   c0,0,0,0.1,0,0.1c0,1.1-0.5,2.1-1.4,2.7c-0.8,0.7-1.8,1.1-2.9,1.1c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.2-0.3-0.4-0.3-0.6   c0-0.2,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.6,1.2,0.9,2,0.9c1.3,0,1.9-1.1,1.9-2.3c0.1-1.3-0.9-2.4-2.2-2.4   c-0.1,0-0.3,0-0.4,0c-0.5,0-1.1,0.1-1.6,0.3l-0.1-0.1c0.3-1.3,0.6-3.5,0.8-4.8L920.7,131.3z"
          />
          <path
            style={{ fill: "#136736" }}
            d="M931.2,141.3v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8   c0,0.2,0,0.5,0,0.7v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8   c-0.6,0.1-1.2,0.3-1.7,0.8v3.8c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4   c0.8-0.1,1.6-0.3,2.3-0.6v1.3c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9   c0.4-0.3,0.8-0.4,1.3-0.4c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L931.2,141.3z"
          />
          <path
            style={{ fill: "#136736" }}
            d="M942.3,139c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8   c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.2   c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L942.3,139z"
          />
          <path
            style={{ fill: "#136736" }}
            d="M943.8,129.5c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7   c0.1-2.6-0.9-5.1-2.6-7L943.8,129.5z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M897.4,213c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7   c-0.1,2.6,0.9,5.1,2.6,7L897.4,213z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M899.3,201.7l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2   c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.6   c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0   c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3   L899.3,201.7z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M910.2,199.8c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S907.7,199.8,910.2,199.8z M910.2,200.4   c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S911.5,200.4,910.2,200.4z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M918,199.8c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S915.5,199.8,918,199.8z M918,200.4   c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S919.3,200.4,918,200.4z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M931.2,209.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8   c0,0.2,0,0.5,0,0.7v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.1,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8   c-0.6,0.1-1.2,0.3-1.7,0.8v3.8c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4   c0.8-0.1,1.6-0.3,2.3-0.6v1.3c0.4-0.3,0.7-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9   c0.4-0.3,0.8-0.4,1.3-0.4c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H931.2z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M942.3,207.7c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8   c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3   c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L942.3,207.7z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M943.8,198.2c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7   c0.1-2.6-0.9-5.1-2.6-7L943.8,198.2z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M897.4,276.7c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7   c-0.1,2.6,0.9,5.1,2.6,7L897.4,276.7z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M899.3,265.4l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.8,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2   c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5   c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1   c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0   c-0.8,0.1-1.6,0.5-2,1.3L899.3,265.4z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M910.2,263.4c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S907.7,263.4,910.2,263.4z M910.2,264   c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S911.5,264,910.2,264L910.2,264z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M918,263.4c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S915.5,263.4,918,263.4z M918,264   c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S919.3,264,918,264L918,264z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M931.2,273.6v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8   c0,0.2,0,0.5,0,0.7v3.2c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8   c-0.6,0.1-1.2,0.3-1.7,0.8v3.8c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.4c0-1-0.1-1.1-0.9-1.2V267   c0.8-0.1,1.6-0.3,2.3-0.6v1.3c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.4,0.8-0.6,1.2-0.9   c0.4-0.3,0.8-0.4,1.3-0.4c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L931.2,273.6z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M942.3,271.4c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8   c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3   c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L942.3,271.4z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M943.8,261.9c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c3.5-4,3.5-10,0-14L943.8,261.9z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M897.4,339.7c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7   c-0.1,2.6,0.9,5.1,2.6,7L897.4,339.7z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M899.3,328.4l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.8,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2   c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5   c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1   c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0   c-0.8,0.1-1.6,0.5-2,1.3L899.3,328.4z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M910.2,326.4c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S907.7,326.4,910.2,326.4z M910.2,327   c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S911.5,327,910.2,327L910.2,327z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M918,326.4c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S915.5,326.4,918,326.4z M918,327   c-1.4,0-1.8,2.4-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S919.3,327,918,327L918,327z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M931.2,336.6v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8   c0,0.2,0,0.5,0,0.7v3.2c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8   c-0.6,0.1-1.2,0.3-1.7,0.8v3.8c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.4c0-1-0.1-1.1-0.9-1.2V330   c0.8-0.1,1.6-0.3,2.3-0.6v1.3c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.4,0.8-0.6,1.2-0.9   c0.4-0.3,0.8-0.4,1.3-0.4c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L931.2,336.6z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M942.3,334.4c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8   c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3   c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L942.3,334.4z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M943.8,324.9c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c3.5-4,3.5-10,0-14L943.8,324.9z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M897.4,403.3c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7   c-0.1,2.6,0.9,5.1,2.6,7L897.4,403.3z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M899.3,392l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2   c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.5   c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0   c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3   L899.3,392z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M910.2,390.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2C906.8,392.9,907.7,390.1,910.2,390.1z    M910.2,390.7c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S911.5,390.7,910.2,390.7L910.2,390.7z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M918,390.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2C914.6,392.9,915.5,390.1,918,390.1z    M918,390.7c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S919.3,390.7,918,390.7L918,390.7z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M931.2,400.3v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8   c0,0.2,0,0.5,0,0.7v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.1,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8   c-0.6,0.1-1.2,0.3-1.7,0.8v3.8c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4   c0.8-0.1,1.6-0.3,2.3-0.6v1.3c0.4-0.3,0.7-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9   c0.4-0.3,0.8-0.4,1.3-0.4c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H931.2z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M942.3,398c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8   c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3   c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L942.3,398z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M943.8,388.5c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7   c0.1-2.6-0.9-5.1-2.6-7L943.8,388.5z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M897.4,466.3c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7   c-0.1,2.6,0.9,5.1,2.6,7L897.4,466.3z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M899.3,455l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2   c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.5   c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0   c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3   L899.3,455z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M910.2,453.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2C906.8,455.9,907.7,453.1,910.2,453.1z    M910.2,453.7c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S911.5,453.7,910.2,453.7L910.2,453.7z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M918,453.1c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2C914.6,455.9,915.5,453.1,918,453.1z    M918,453.7c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S919.3,453.7,918,453.7L918,453.7z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M931.2,463.3v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8   c0,0.2,0,0.5,0,0.7v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.1,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8   c-0.6,0.1-1.2,0.3-1.7,0.8v3.8c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4   c0.8-0.1,1.6-0.3,2.3-0.6v1.3c0.4-0.3,0.7-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9   c0.4-0.3,0.8-0.4,1.3-0.4c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H931.2z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M942.3,461c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8   c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.4l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3   c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L942.3,461z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M943.8,451.5c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7   c0.1-2.6-0.9-5.1-2.6-7L943.8,451.5z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M897.4,530c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7   c-0.1,2.6,0.9,5.1,2.6,7L897.4,530z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M899.3,518.7l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.8,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2   c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5   c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.3c0-1.1-0.8-2-1.9-2.1   c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0   c-0.8,0.1-1.6,0.5-2,1.3L899.3,518.7z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M910.2,516.7c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S907.7,516.7,910.2,516.7z M910.2,517.3   c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S911.5,517.3,910.2,517.3z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M918,516.7c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S915.5,516.7,918,516.7z M918,517.3   c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S919.3,517.3,918,517.3z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M931.2,526.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8   c0,0.2,0,0.5,0,0.7v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8   c-0.6,0.1-1.2,0.3-1.7,0.8v3.8c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4   c0.8-0.1,1.6-0.3,2.3-0.6v1.3c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9   c0.4-0.3,0.8-0.4,1.3-0.4c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H931.2z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M942.3,524.7c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8   c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3   c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L942.3,524.7z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M943.8,515.2c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c3.5-4,3.5-10,0-14L943.8,515.2z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M897.4,593.4c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7   c-0.1,2.6,0.9,5.1,2.6,7L897.4,593.4z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M899.3,582.1l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2   c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.5   c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0   c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3   L899.3,582.1z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M910.2,580.2c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S907.7,580.2,910.2,580.2z M910.2,580.8   c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S911.5,580.8,910.2,580.8z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M918,580.2c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S915.5,580.2,918,580.2z M918,580.8   c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.7,1.8,4.7s1.7-2.4,1.7-4.6S919.3,580.8,918,580.8z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M931.2,590.3v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8   c0,0.2,0,0.5,0,0.7v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8   c-0.6,0.1-1.2,0.3-1.7,0.8v3.8c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4   c0.8-0.1,1.6-0.3,2.3-0.6v1.3c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9   c0.4-0.3,0.8-0.4,1.3-0.4c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H931.2z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M942.3,588.1c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1V590c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8   c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.2   c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L942.3,588.1z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M943.8,578.6c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7   c0.1-2.6-0.9-5.1-2.6-7L943.8,578.6z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M897.4,656.4c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7   c-0.1,2.6,0.9,5.1,2.6,7L897.4,656.4z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M899.3,645.1l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2   c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.5   c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0   c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3   L899.3,645.1z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M910.2,643.2c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S907.7,643.2,910.2,643.2z M910.2,643.8   c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S911.5,643.8,910.2,643.8z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M918,643.2c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S915.5,643.2,918,643.2z M918,643.8   c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.7,1.8,4.7s1.7-2.4,1.7-4.6S919.3,643.8,918,643.8z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M931.2,653.3v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8   c0,0.2,0,0.5,0,0.7v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8   c-0.6,0.1-1.2,0.3-1.7,0.8v3.8c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4   c0.8-0.1,1.6-0.3,2.3-0.6v1.3c0.4-0.3,0.7-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9   c0.4-0.3,0.8-0.4,1.3-0.4c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H931.2z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M942.3,651.1c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1V653c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8   c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3   c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L942.3,651.1z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M943.8,641.6c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7   c0.1-2.6-0.9-5.1-2.6-7L943.8,641.6z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M897.4,719.7c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7   c-0.1,2.6,0.9,5.1,2.6,7L897.4,719.7z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M899.3,708.3l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.8,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2   c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.6   c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0   c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3   L899.3,708.3z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M910.2,706.4c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S907.7,706.4,910.2,706.4z M910.2,707   c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S911.5,707,910.2,707L910.2,707z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M918,706.4c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S915.5,706.4,918,706.4z M918,707   c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S919.3,707,918,707L918,707z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M931.2,716.6v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8   c0,0.2,0,0.5,0,0.7v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8   c-0.6,0.1-1.2,0.3-1.7,0.8v3.8c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4   c0.8-0.1,1.6-0.3,2.3-0.6v1.3c0.4-0.3,0.7-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9   c0.4-0.3,0.8-0.4,1.3-0.4c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H931.2z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M942.3,714.3c-0.2,0.7-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8   c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3   c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L942.3,714.3z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M943.8,704.8c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7   c0.1-2.6-0.9-5.1-2.6-7L943.8,704.8z"
          />
          <path
            style={{ fill: "#136736" }}
            d="M100,946.7c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7   c-0.1,2.6,0.9,5.1,2.6,7L100,946.7z"
          />
          <path
            style={{ fill: "#136736" }}
            d="M101.9,935.4l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2   c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.5   c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0   c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3   L101.9,935.4z"
          />
          <path
            style={{ fill: "#136736" }}
            d="M116.3,933.8c-1.5,3.3-3.1,6.5-4.6,9.8l-1.2,0.1l-0.1-0.2c1.7-2.8,3.3-5.7,4.7-8.7h-3.4   c-1.1,0-1.1,0.1-1.6,1.2h-0.5c0.2-1,0.3-1.8,0.3-2.5h6.3L116.3,933.8z"
          />
          <path
            style={{ fill: "#136736" }}
            d="M123.3,933.6l0.1,0.1l-0.4,1.2h-3.7c-0.1,0.8-0.3,2.1-0.5,2.8c0.6-0.2,1.3-0.4,1.9-0.5c1.5-0.1,2.9,1,3,2.6   c0,0,0,0.1,0,0.1c0,1.1-0.5,2.1-1.4,2.7c-0.8,0.7-1.8,1.1-2.9,1.1c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.2-0.3-0.3-0.3-0.6   c0-0.2,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.6,1.2,0.9,2,0.9c1.3,0,1.9-1.1,1.9-2.3c0.1-1.3-0.9-2.4-2.2-2.4   c-0.1,0-0.3,0-0.4,0c-0.5,0-1.1,0.1-1.6,0.3l-0.1-0.1c0.3-1.3,0.6-3.5,0.8-4.8L123.3,933.6z"
          />
          <path
            style={{ fill: "#136736" }}
            d="M133.8,943.6v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8   c0,0.2,0,0.5,0.1,0.7v3.2c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8   c-0.6,0.1-1.2,0.3-1.7,0.8v3.8c0,1,0.1,1.1,1,1.2v0.5H125v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2V937   c0.8-0.1,1.6-0.3,2.3-0.6v1.3c0.4-0.3,0.7-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9   c0.4-0.3,0.8-0.4,1.3-0.4c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L133.8,943.6z"
          />
          <path
            style={{ fill: "#136736" }}
            d="M144.9,941.4c-0.2,0.8-0.5,1.6-0.6,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8   c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3   c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L144.9,941.4z"
          />
          <path
            style={{ fill: "#136736" }}
            d="M146.4,931.8c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7   c0.1-2.6-0.9-5.1-2.6-7L146.4,931.8z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M170.7,944.7c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7   c-0.1,2.6,0.9,5.1,2.6,7L170.7,944.7z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M172.6,933.3l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2   c-0.4,0.3-0.7,0.6-1.1,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.1,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5   c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.3c0-1.1-0.9-2-2-2c0,0-0.1,0-0.1,0   c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3   L172.6,933.3z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M183.5,931.4c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S181,931.4,183.5,931.4z M183.5,932   c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S184.8,932,183.5,932L183.5,932z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M191.3,931.4c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S188.8,931.4,191.3,931.4z M191.3,932   c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S192.6,932,191.3,932L191.3,932z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M204.5,941.5v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8   c0,0.2,0,0.4,0,0.7v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.4v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8   c-0.6,0.1-1.2,0.3-1.7,0.8v3.8c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4   c0.8-0.1,1.6-0.3,2.3-0.6v1.3c0.4-0.3,0.8-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9   c0.4-0.3,0.8-0.4,1.3-0.4c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H204.5z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M215.6,939.3c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1-1.2,1.9-2.5,1.9-3.8   s-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3   c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L215.6,939.3z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M217.1,929.8c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7   c0.1-2.6-0.9-5.1-2.6-7L217.1,929.8z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M233.8,945.2c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7   c0,2.6,0.9,5.1,2.6,7L233.8,945.2z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M235.7,933.9l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2   c-0.4,0.3-0.7,0.6-1.1,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.1,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.5   c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0   c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0-0.8-0.6-1.4-1.3-1.5c-0.1,0-0.2,0-0.3,0c-0.8,0.1-1.6,0.5-2,1.3   L235.7,933.9z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M246.6,931.9c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S244.1,931.9,246.6,931.9z M246.6,932.5   c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S247.9,932.5,246.6,932.5L246.6,932.5z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M254.4,931.9c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S251.9,931.9,254.4,931.9z M254.4,932.5   c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S255.8,932.5,254.4,932.5L254.4,932.5z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M267.6,942.1v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8   c0,0.2,0,0.4,0,0.7v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8   c-0.6,0.1-1.2,0.3-1.7,0.8v3.8c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4   c0.8-0.1,1.6-0.3,2.3-0.6v1.3c0.4-0.3,0.8-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9   c0.4-0.3,0.8-0.4,1.3-0.4c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H267.6z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M278.7,939.9c-0.2,0.8-0.5,1.6-0.7,2.2H272v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8   c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.2   c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L278.7,939.9z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M280.3,930.4c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7   c0.1-2.6-0.9-5.1-2.6-7L280.3,930.4z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M297,945c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7   c-0.1,2.6,0.9,5.1,2.6,7L297,945z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M298.9,933.7l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2   c-0.4,0.3-0.7,0.6-1.1,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5   c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.3c0-1.1-0.8-2-1.9-2.1   c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0   c-0.8,0.1-1.6,0.5-2,1.3L298.9,933.7z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M309.8,931.7c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S307.3,931.7,309.8,931.7z M309.8,932.3   c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S311.1,932.3,309.8,932.3L309.8,932.3z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M317.6,931.7c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S315.1,931.7,317.6,931.7z M317.6,932.3   c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S319,932.3,317.6,932.3L317.6,932.3z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M330.8,941.9v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8   c0,0.2,0,0.5,0,0.7v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8   c-0.6,0.1-1.2,0.3-1.7,0.8v3.8c0,1,0.1,1.1,1,1.2v0.5H322v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1,0-1.1-0.9-1.2v-0.4   c0.8-0.1,1.6-0.3,2.3-0.6v1.3c0.4-0.3,0.7-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9   c0.4-0.3,0.8-0.4,1.3-0.4c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L330.8,941.9z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M341.9,939.7c-0.2,0.8-0.5,1.6-0.6,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8   c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.2   c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L341.9,939.7z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M343.5,930.1c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7   c0.1-2.6-0.9-5.1-2.6-7L343.5,930.1z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M360.4,945.3c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.4,0.4c-1.8,1.9-2.7,4.4-2.6,7   c-0.1,2.6,0.9,5.1,2.6,7L360.4,945.3z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M362.2,934l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2   c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.1,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.5   c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1   c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0   c-0.8,0.1-1.6,0.5-2,1.3L362.2,934z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M373.1,932c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S370.6,932,373.1,932z M373.1,932.6   c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S374.5,932.6,373.1,932.6z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M381,932c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S378.5,932,381,932z M381,932.6   c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S382.3,932.6,381,932.6z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M394.2,942.2v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8   c0,0.2,0,0.4,0,0.7v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8   c-0.6,0.1-1.2,0.3-1.7,0.8v3.8c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4   c0.8-0.1,1.6-0.3,2.3-0.6v1.3c0.4-0.3,0.8-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9   c0.4-0.3,0.8-0.4,1.3-0.4c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H394.2z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M405.3,940c-0.2,0.8-0.5,1.6-0.6,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8   c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.2   c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L405.3,940z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M406.8,930.5c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7   c0.1-2.6-0.9-5.1-2.6-7L406.8,930.5z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M423.8,944.9c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7   c0,2.6,0.9,5.1,2.6,7L423.8,944.9z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M425.7,933.6l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2   c-0.4,0.3-0.7,0.6-1.1,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.1,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5   c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.3c0-1.1-0.8-2-1.9-2.1   c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.1,0-0.2,0   c-0.8,0.1-1.6,0.5-2,1.3L425.7,933.6z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M436.6,931.6c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S434.1,931.6,436.6,931.6z M436.6,932.2   c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S437.9,932.2,436.6,932.2L436.6,932.2z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M444.4,931.6c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S441.9,931.6,444.4,931.6z M444.4,932.2   c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S445.7,932.2,444.4,932.2L444.4,932.2z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M457.6,941.8v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8   c0,0.2,0,0.4,0,0.7v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8   c-0.6,0.1-1.2,0.3-1.7,0.8v3.8c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4   c0.8-0.1,1.6-0.3,2.3-0.6v1.3c0.4-0.3,0.8-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9   c0.4-0.3,0.8-0.4,1.3-0.4c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H457.6z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M468.7,939.5c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1-1.2,1.9-2.5,1.9-3.8   s-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3   c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L468.7,939.5z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M470.2,930c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7   c0.1-2.6-0.9-5.1-2.6-7L470.2,930z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M486.1,938.7c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.4,0.4c-1.8,1.9-2.7,4.4-2.6,7   c-0.1,2.6,0.9,5.1,2.6,7L486.1,938.7z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M488,927.3l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.8,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2   c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.1,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.6c-0.2-0.1-0.3-0.3-0.3-0.5   c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.3c0-1.1-0.8-2-1.9-2.1   c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0   c-0.8,0.1-1.6,0.5-2,1.3L488,927.3z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M498.9,925.4c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S496.4,925.4,498.9,925.4z M498.9,926   c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S500.3,926,498.9,926L498.9,926z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M506.7,925.4c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S504.3,925.4,506.7,925.4z M506.7,926   c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S508.1,926,506.7,926L506.7,926z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M519.9,935.6v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8   c0,0.2,0,0.4,0,0.7v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8   c-0.6,0.1-1.2,0.3-1.7,0.8v3.8c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4   c0.8-0.1,1.6-0.3,2.3-0.6v1.3c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9   c0.4-0.3,0.8-0.4,1.3-0.4c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H519.9z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M531,933.3c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8   c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3   c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.7-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L531,933.3z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M532.6,923.8c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7   c0.1-2.6-0.9-5.1-2.6-7L532.6,923.8z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M550.6,945.2c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7   c-0.1,2.6,0.9,5.1,2.6,7L550.6,945.2z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M552.4,933.9l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2   c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.5   c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0   c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3   L552.4,933.9z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M563.3,931.9c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S560.8,931.9,563.3,931.9z M563.3,932.5   c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S564.7,932.5,563.3,932.5L563.3,932.5z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M571.2,931.9c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S568.7,931.9,571.2,931.9z M571.2,932.5   c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S572.5,932.5,571.2,932.5z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M584.4,942.1v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8   c0,0.2,0,0.5,0,0.7v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8   c-0.6,0.1-1.2,0.3-1.7,0.8v3.8c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4   c0.8-0.1,1.6-0.3,2.3-0.6v1.3c0.4-0.3,0.7-0.6,1.1-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9   c0.4-0.3,0.8-0.4,1.3-0.4c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L584.4,942.1z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M595.5,939.9c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.7-2.4,1.9-3.8   c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3   c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L595.5,939.9z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M597,930.4c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7   c0.1-2.6-0.9-5.1-2.6-7L597,930.4z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M613.6,945.2c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7   c-0.1,2.6,0.9,5.1,2.6,7L613.6,945.2z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M615.4,933.9l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2   c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.5   c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.9,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1c0,0-0.1,0-0.1,0   c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0c-0.8,0.1-1.6,0.5-2,1.3   L615.4,933.9z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M626.3,931.9c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S623.8,931.9,626.3,931.9z M626.3,932.5   c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S627.7,932.5,626.3,932.5z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M634.2,931.9c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S631.7,931.9,634.2,931.9z M634.2,932.5   c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S635.5,932.5,634.2,932.5z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M647.4,942.1v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8   c0,0.2,0,0.5,0,0.7v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8   c-0.6,0.1-1.2,0.3-1.7,0.8v3.8c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2v-3.3c0-1-0.1-1.1-0.9-1.2v-0.4   c0.8-0.1,1.6-0.3,2.3-0.6v1.3c0.4-0.3,0.7-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9   c0.4-0.3,0.8-0.4,1.3-0.4c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5L647.4,942.1z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M658.5,939.9c-0.2,0.8-0.5,1.6-0.7,2.2h-6.1v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8   c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.3   c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L658.5,939.9z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M660,930.4c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7   c0.1-2.6-0.9-5.1-2.6-7L660,930.4z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M676.9,945.1c-4.1-3.1-4.9-8.9-1.8-13c0.5-0.7,1.1-1.3,1.8-1.8l0.3,0.4c-1.8,1.9-2.7,4.4-2.6,7   c-0.1,2.6,0.9,5.1,2.6,7L676.9,945.1z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M678.8,933.8l0.8-1c0.5-0.6,1.3-0.9,2.1-0.9c1.2-0.1,2.3,0.7,2.4,2c0,0.1,0,0.1,0,0.2c0,0.4-0.2,0.9-0.5,1.2   c-0.4,0.3-0.7,0.6-1.2,0.9c1.3,0.1,2.3,1.2,2.4,2.5c0,2.6-3.2,3.7-4.3,3.7c-0.6,0-1.2-0.2-1.7-0.5c-0.2-0.1-0.3-0.3-0.3-0.5   c0-0.3,0.2-0.5,0.4-0.6c0.1-0.1,0.3-0.1,0.5,0c0.5,0.5,1.2,0.8,2,0.9c1.1,0,1.9-0.8,1.9-2.4c0-1.1-0.8-2-1.9-2.1   c-0.1,0-0.1,0-0.2,0c-0.3,0-0.7,0.1-1,0.2l-0.1-0.5c1.4-0.5,2.5-1.1,2.5-2.3c0.1-0.8-0.5-1.4-1.3-1.5c-0.1,0-0.2,0-0.2,0   c-0.8,0.1-1.6,0.5-2,1.3L678.8,933.8z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M689.7,931.8c2.5,0,3.4,2.7,3.4,5.2s-0.9,5.2-3.4,5.2s-3.4-2.7-3.4-5.2S687.2,931.8,689.7,931.8z M689.7,932.4   c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S691,932.4,689.7,932.4L689.7,932.4z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M697.5,931.8c2.5,0,3.3,2.7,3.3,5.2s-0.9,5.2-3.3,5.2s-3.4-2.7-3.4-5.2S695,931.8,697.5,931.8z M697.5,932.4   c-1.4,0-1.8,2.3-1.8,4.6s0.4,4.6,1.8,4.6s1.7-2.4,1.7-4.6S698.8,932.4,697.5,932.4L697.5,932.4z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M710.7,942v-0.5c0.9-0.1,1-0.2,1-1.3v-2.7c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8c0,0.2,0,0.4,0,0.7   v3.1c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c0.9-0.1,1.1-0.2,1.1-1.2v-2.8c0-1.2-0.4-1.8-1.3-1.8c-0.6,0.1-1.2,0.3-1.7,0.8v3.8   c0,1,0.1,1.1,1,1.2v0.5h-3.5v-0.5c1-0.1,1.1-0.2,1.1-1.2V937c0-1-0.1-1.1-0.9-1.2v-0.4c0.8-0.1,1.6-0.3,2.3-0.6v1.3   c0.4-0.3,0.8-0.6,1.2-0.8c0.4-0.3,0.8-0.4,1.3-0.4c0.8,0,1.5,0.5,1.8,1.3c0.4-0.3,0.8-0.6,1.2-0.9c0.4-0.3,0.8-0.4,1.3-0.4   c1.3,0,2,0.9,2,2.5v3.1c0,1,0.1,1.1,1,1.2v0.5H710.7z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M721.8,939.8c-0.2,0.8-0.5,1.6-0.7,2.2H715v-0.4c0.9-0.9,1.8-1.8,2.6-2.7c1.1-1,1.8-2.4,1.9-3.8   c0-1.3-0.7-2.1-1.8-2.1c-0.9,0.1-1.8,0.6-2.2,1.5l-0.4-0.3l0.9-1.3c0.6-0.6,1.4-1,2.2-1c1.5-0.1,2.7,1.1,2.8,2.5c0,0.1,0,0.2,0,0.2   c0,1.3-0.5,2.3-2,3.8c-0.7,0.7-1.7,1.6-2.3,2.3h3c0.9,0,1.1-0.1,1.7-1.1L721.8,939.8z"
          />
          <path
            style={{ fill: "#626466" }}
            d="M723.3,930.3c4.1,3.1,4.9,8.9,1.8,13c-0.5,0.7-1.1,1.3-1.8,1.8l-0.4-0.4c1.8-1.9,2.7-4.4,2.6-7   c0.1-2.6-0.9-5.1-2.6-7L723.3,930.3z"
          />
        </g>
        <g>
          <path
            style={{ fill: "#F8D732" }}
            d="M159.3,473.6c0.4-1.2,1.3-3,2.3-4.7c1.5-2.4,3-3.7,4.8-4.2c1.4-0.5,3.1-0.2,4.8,0.8c2.1,1.3,3.1,3.6,2.7,6.1   l0.1,0c1.6-1.5,4.6-2.5,7.8-0.5c1.9,1.2,2.9,2.7,3.3,4.3c0.6,2.2-0.1,4.7-2,7.7c-1,1.7-2,2.9-2.6,3.6L159.3,473.6z M169.6,476.8   l1.4-2.2c1.6-2.6,1-5-1.1-6.3c-2.5-1.6-4.6-0.5-6.1,1.9c-0.7,1.1-1,1.8-1.1,2.3L169.6,476.8z M179.9,483.2c0.4-0.4,0.9-1.1,1.4-1.9   c1.6-2.5,2-5.5-1.2-7.4c-2.9-1.8-5.5-0.4-7.2,2.2l-1.3,2L179.9,483.2z"
          />
          <path
            style={{ fill: "#F8D732" }}
            d="M187.6,471.9c-1.2-0.7-1.6-2-1-2.9s1.9-1.1,3-0.4c1.1,0.7,1.6,1.9,1,2.9C190,472.5,188.8,472.6,187.6,471.9z"
          />
          <path
            style={{ fill: "#F8D732" }}
            d="M173.3,450.6l9,5.6l5.7-9l-9-5.6l1.5-2.4l21.5,13.5l-1.5,2.4l-10.1-6.3l-5.7,9l10.1,6.3l-1.5,2.4l-21.5-13.5   L173.3,450.6z"
          />
          <path
            style={{ fill: "#F8D732" }}
            d="M204.1,449.3l-1.7-1.1l-0.9-3.3c-2.2-7.8-3.5-11.5-6.6-13.5c-2.1-1.3-4.6-1.7-6.3,1c-1,1.6-0.9,3.6-0.6,4.9   l-2.5-0.5c-0.4-2-0.2-4.3,1.1-6.4c2.4-3.9,6.5-3.6,9.5-1.7c3.8,2.4,5.4,6.8,7.2,13.2l0.6,2.4l0.1,0l5.1-8.1l2.3,1.5L204.1,449.3z"
          />
        </g>
        <g>
          <path
            style={{ fill: "#F8D732" }}
            d="M479.3,153.9c0.8-0.9,2.3-2,3.9-3c2.3-1.4,4-1.8,5.7-1.5c1.4,0.2,2.7,1.1,3.7,2.7c1.2,2,1.1,4.4-0.2,6.3l0,0.1   c2-0.5,4.9-0.2,6.8,2.8c1.1,1.7,1.3,3.4,1,5c-0.4,2-2.1,3.9-4.9,5.6c-1.6,1-2.8,1.6-3.7,1.9L479.3,153.9z M486.7,160.8l2.1-1.3   c2.4-1.5,2.9-3.8,1.7-5.7c-1.5-2.4-3.6-2.3-5.9-0.9c-1,0.6-1.6,1.1-1.9,1.4L486.7,160.8z M492.7,170.5c0.5-0.2,1.2-0.6,2-1.1   c2.4-1.4,3.9-3.8,2.1-6.7c-1.7-2.8-4.5-2.6-7-1.1l-1.9,1.2L492.7,170.5z"
          />
          <path
            style={{ fill: "#F8D732" }}
            d="M503.9,164.2c-0.7-1.1-0.5-2.3,0.4-2.9s2-0.2,2.7,0.9c0.7,1.1,0.6,2.3-0.4,2.9   C505.7,165.7,504.5,165.3,503.9,164.2z"
          />
          <path
            style={{ fill: "#F8D732" }}
            d="M500.7,140.4l5.2,8.5l8.5-5.2l-5.2-8.5l2.3-1.4L524,154l-2.3,1.4l-5.8-9.5l-8.5,5.2l5.8,9.5l-2.3,1.4   l-12.5-20.3L500.7,140.4z"
          />
          <path
            style={{ fill: "#F8D732" }}
            d="M521.6,131.9L521.6,131.9l-1.9,3.7l-1.7-1.7l2.3-4.6l2-1.2l12,19.5l-2.2,1.4L521.6,131.9z"
          />
        </g>
        <g>
          <path
            style={{ fill: "#AFD9AA" }}
            d="M879.2,32.9c1.1-0.5,2.9-0.9,4.8-1.2c2.6-0.4,4.4-0.1,5.9,0.9c1.2,0.7,2,2,2.3,3.9c0.3,2.3-0.7,4.5-2.7,5.7   l0,0.1c2,0.3,4.6,1.8,5.1,5.3c0.3,2-0.2,3.7-1,5c-1.2,1.7-3.4,2.8-6.7,3.3c-1.8,0.3-3.2,0.3-4.1,0.3L879.2,32.9z M883.2,42.1   l2.4-0.4c2.8-0.4,4.2-2.3,3.8-4.6c-0.4-2.8-2.4-3.6-5.1-3.2c-1.2,0.2-1.9,0.4-2.3,0.6L883.2,42.1z M884.9,53.4   c0.5,0,1.3-0.1,2.2-0.2c2.7-0.4,5.1-1.9,4.6-5.4c-0.5-3.2-3.1-4.2-6-3.8l-2.2,0.3L884.9,53.4z"
          />
          <path
            style={{ fill: "#AFD9AA" }}
            d="M897.7,52c-0.2-1.3,0.4-2.3,1.5-2.5c1.1-0.2,1.9,0.6,2.1,1.9c0.2,1.3-0.4,2.3-1.5,2.5   C898.7,54.1,897.8,53.3,897.7,52z"
          />
          <path
            style={{ fill: "#AFD9AA" }}
            d="M904.1,28.9l3.5,23.5l-2.6,0.4l-3.5-23.5L904.1,28.9z"
          />
          <path
            style={{ fill: "#AFD9AA" }}
            d="M911.3,27.8l1.5,9.8l9.9-1.5l-1.5-9.8l2.7-0.4l3.5,23.5l-2.7,0.4l-1.6-11l-9.9,1.5l1.6,11l-2.6,0.4l-3.5-23.5   L911.3,27.8z"
          />
        </g>
        <g>
          <path
            style={{ fill: "#F8D732" }}
            d="M63.4,702.5c0.1-1.3,0.4-3.2,0.9-5.1c0.8-2.7,1.8-4.3,3.5-5.3c1.3-0.8,2.9-1.1,4.8-0.5c2.4,0.7,4,2.6,4.3,5.1   l0.1,0c1.2-1.8,3.8-3.7,7.4-2.6c2.1,0.6,3.5,1.8,4.4,3.2c1.1,1.9,1.2,4.5,0.2,8c-0.5,1.9-1.1,3.3-1.5,4.2L63.4,702.5z M74.2,702.6   l0.7-2.5c0.8-2.9-0.4-5.1-2.8-5.8c-2.9-0.8-4.5,0.7-5.3,3.5c-0.4,1.3-0.5,2-0.5,2.5L74.2,702.6z M85.9,706c0.3-0.5,0.5-1.3,0.8-2.3   c0.8-2.8,0.4-5.8-3.2-6.8c-3.3-1-5.4,1.1-6.3,4.1l-0.7,2.3L85.9,706z"
          />
          <path
            style={{ fill: "#F8D732" }}
            d="M90.2,693c-1.3-0.4-2.1-1.4-1.7-2.5s1.5-1.6,2.8-1.2c1.3,0.4,2.1,1.4,1.7,2.5C92.7,692.9,91.5,693.3,90.2,693z   "
          />
          <path
            style={{ fill: "#F8D732" }}
            d="M70.6,676.5l10.2,2.9l3-10.3l-10.2-2.9l0.8-2.8l24.4,7l-0.8,2.8L86.5,670l-3,10.3l11.4,3.3l-0.8,2.7l-24.4-7   L70.6,676.5z"
          />
          <path
            style={{ fill: "#F8D732" }}
            d="M96.3,665c0.8-0.6,2.2-2.2,2.8-4.1c1-3.6-1.3-5.4-3.2-5.9c-3.3-0.9-5.5,1.3-6.2,3.9l-0.4,1.5l-2.4-0.7l0.4-1.5   c0.6-2,0.1-4.9-2.7-5.7c-1.9-0.5-3.8,0-4.6,2.6c-0.5,1.6-0.1,3.4,0.4,4.5l-2.5,0.1c-0.6-1.3-0.9-3.7-0.3-5.9c1.2-4,4.4-5.1,7.3-4.2   c2.4,0.7,4.1,2.6,4.4,5.4l0.1,0c1.3-2.4,4.1-3.8,7.4-2.8c3.8,1.1,6.3,4.6,4.9,9.5c-0.7,2.3-2.1,4.1-3.1,4.9L96.3,665z"
          />
        </g>
        <g>
          <path
            style={{ fill: "#ED1F24" }}
            d="M821.5,42.9c-0.8-2.9-2-5.9-6.2-5.9c-3.3,0-4.6,2.4-4.6,4.8c0,3,1.9,4.5,5.7,6.5c5.3,2.7,7.7,4.8,7.7,9.1   c0,4.8-4.1,8.4-10,8.4c-2.7,0-5.1-0.8-7-1.5c-0.3-1.2-0.9-4.5-1.2-6.9l1.5-0.4c0.9,3,3,7,7.6,7c3.1,0,5-2.1,5-5   c0-3.3-1.8-4.7-5.7-6.8c-4.7-2.5-7.4-4.7-7.4-9c0-4.1,3.4-7.9,9.4-7.9c2.6,0,5,0.7,6,0.8c0.2,1.6,0.4,3.5,0.7,6.4L821.5,42.9z"
          />
          <path
            style={{ fill: "#ED1F24" }}
            d="M846.5,58.8c-0.6,2.1-1.4,4.5-1.8,6.2h-17.1v-1.1c2.4-2.4,5.1-5,7.3-7.6c2.9-3.4,5.4-6.9,5.4-10.7   c0-3.6-1.9-5.8-5.1-5.8c-3,0-4.9,2.3-6.2,4.1l-1.1-1l2.6-3.7c1.4-1.6,3.6-2.7,6.3-2.7c4.3,0,7.7,2.9,7.7,7.8   c0,3.7-1.4,6.4-5.7,10.7c-1.9,1.9-4.7,4.6-6.5,6.4c0.3,0,8,0,8.4,0c2.5,0,3.1-0.2,4.7-3L846.5,58.8z"
          />
        </g>
        <g>
          <path
            style={{ fill: "#ED1F24" }}
            d="M115.8,579.4c-0.7-2.6-1.8-5.1-5.4-5.1c-2.9,0-4,2.1-4,4.2c0,2.6,1.7,3.9,4.9,5.6c4.6,2.4,6.7,4.2,6.7,7.9   c0,4.2-3.6,7.3-8.7,7.3c-2.4,0-4.4-0.7-6.1-1.3c-0.3-1.1-0.8-4-1.1-6l1.3-0.3c0.8,2.6,2.6,6.1,6.6,6.1c2.7,0,4.4-1.9,4.4-4.4   c0-2.9-1.6-4.1-5-5.9c-4.1-2.2-6.4-4.1-6.4-7.8c0-3.6,2.9-6.8,8.2-6.8c2.3,0,4.4,0.6,5.2,0.7c0.2,1.4,0.3,3,0.6,5.6L115.8,579.4z"
          />
          <path
            style={{ fill: "#ED1F24" }}
            d="M128.1,583.9c0.8-0.4,1.8-0.7,2.5-0.7c4.1,0,7,3.2,7,7.2c0,4.7-3.4,8.6-8.2,8.6c-4.7,0-8.2-3.8-8.2-9.8   c0-8,6.1-13,10.7-14.6c0.9-0.3,3-0.8,4.7-1l0.2,1.3c-1.2,0.3-3,0.8-4.2,1.3c-2.3,1.1-5.9,3.6-7,8.8L128.1,583.9z M128.9,585.6   c-1.4,0-2.7,0.5-3.5,1c-0.2,0.7-0.2,1.8-0.2,2.7c0,4.9,1.8,8.4,5,8.4c2.9,0,3.8-3.2,3.8-5.7C133.9,588.6,132.3,585.6,128.9,585.6z"
          />
        </g>
        <g>
          <path
            style={{ fill: "#ED1F24" }}
            d="M51.2,821.2c-0.7-2.8-1.9-5.6-5.9-5.6c-3.2,0-4.4,2.3-4.4,4.5c0,2.8,1.8,4.3,5.4,6.1c5,2.6,7.3,4.6,7.3,8.6   c0,4.6-3.9,8-9.5,8c-2.6,0-4.8-0.7-6.6-1.4c-0.3-1.1-0.9-4.3-1.2-6.5l1.4-0.3c0.8,2.8,2.8,6.7,7.2,6.7c3,0,4.8-2,4.8-4.8   c0-3.1-1.7-4.5-5.4-6.5c-4.4-2.4-7-4.5-7-8.5c0-3.9,3.2-7.5,8.9-7.5c2.5,0,4.8,0.6,5.7,0.8c0.2,1.5,0.4,3.3,0.7,6.1L51.2,821.2z"
          />
          <path
            style={{ fill: "#ED1F24" }}
            d="M71.3,834.5v3.1c0,2.8,0.2,3,3.2,3.3v1.3H64.2v-1.3c3.1-0.3,3.3-0.6,3.3-3.3v-3.1H56.4v-1.4   c4.1-5.8,8.6-12.1,12.7-17.8h2.1v17h4.1v2.1H71.3z M67.6,820.1c-2.6,3.7-5.5,8-8.4,12.3h8.4V820.1z"
          />
        </g>
        <g>
          <path
            style={{ fill: "#ED1F24" }}
            d="M271.1,359.7c-0.7-2.6-1.8-5.1-5.4-5.1c-2.9,0-4,2.1-4,4.2c0,2.6,1.7,3.9,4.9,5.6c4.6,2.4,6.7,4.2,6.7,7.9   c0,4.2-3.6,7.3-8.7,7.3c-2.4,0-4.4-0.7-6.1-1.3c-0.3-1.1-0.8-4-1.1-6l1.3-0.3c0.8,2.6,2.6,6.1,6.6,6.1c2.7,0,4.4-1.9,4.4-4.4   c0-2.9-1.6-4.1-5-5.9c-4.1-2.2-6.4-4.1-6.4-7.8c0-3.6,2.9-6.8,8.2-6.8c2.3,0,4.4,0.6,5.2,0.7c0.2,1.4,0.3,3,0.6,5.6L271.1,359.7z"
          />
          <path
            style={{ fill: "#ED1F24" }}
            d="M291.2,354.8l0.2,0.3l-1.1,2.8h-9.1c-0.4,2.1-0.9,5-1.2,6.9c1.6-0.6,3.7-1.1,4.7-1.1c4.8,0,7.4,3.4,7.4,6.6   c0,2.3-1,4.6-3.4,6.6c-2.1,1.7-4.9,2.7-7,2.7c-1.8,0-3.3-0.7-4.1-1.5c-0.6-0.5-0.7-0.9-0.7-1.4c0-0.4,0.3-1,0.9-1.5   c0.4-0.2,0.7-0.3,1.2,0c1.1,1,2.5,2.2,4.9,2.2c3.1,0,4.6-2.7,4.6-5.6c0-3.4-2.1-5.9-6.3-5.9c-1.6,0-2.9,0.4-3.9,0.7l-0.1-0.2   c0.6-3.1,1.5-8.5,1.9-11.7H291.2z"
          />
        </g>
      </svg>
    </div>
  );
}
export default HZone;
