import React, { useEffect, useState } from 'react';
import {PieChart, Pie, Cell,} from 'recharts';
import axios from 'axios'
import moment from 'moment'

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#dc3545', '#17a2b8'];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent, index, data
  }) => {
     const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${days[index].name} ${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  let days = []
function GenderVisits(props) {
    const [visits, setVisits] = useState(0)
    const [visitGroup, setVisitGroup] = useState([])
    const [monthsVisits, setMonthsVisits] = useState([])

    useEffect(() => {
        if (props.currentTab === 'monthlyLeadVisits') {
            const token = localStorage.getItem("token");
            axios.defaults.headers.common = {Authorization: `Bearer ${token}`};
            axios.get(`${process.env.REACT_APP_URL}/LeadVisits/GetLeadVisitReport3?startDate=${props.startDate}&endDate=${props.endDate}`)
                .then((resp) => {
                    setMonthsVisits(resp.data)
                });
        }
      }, [props.startDate, props.endDate, props.currentTab]);

    useEffect(() => {
        days = []
        monthsVisits.map((visits, key) => {
           days.push({name: moment(visits[0].visitDate).format('ddd'), value: visits.length})
        })
    }, [monthsVisits])
    // for (let x in visitGroup) {
    //     days.push({name: x, value: visitGroup[x].length})
    //   }
    // console.log(monthsVisits, days)
    return (
        <div>
            <PieChart width={400} height={400}>
                <Pie
                    data={days}
                    cx={200}
                    cy={200}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={150}
                    fill="#8884d8"
                    dataKey="value"
                >
                {
                    days.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                }
                </Pie>
            </PieChart>
        </div>
    )
}

export default GenderVisits
