import React, { useEffect } from "react";
import moment from "moment";
import axios from "axios";

function ArabicTemplate(props) {
  const [prePaymentText, setPrePaymentText] = React.useState(0);
  const [keyRecieveText, setKeyRecieveText] = React.useState(0);
  const [unitPrice, setUnitPrice] = React.useState(0);
  const [pageNumberText, setPageNumberText] = React.useState(0);
  const [areaText, setAreaText] = React.useState("");

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
      .get(
        `${process.env.REACT_APP_URL}/Dashboard/NumberToTextAr?number=${props.data.prePaymentPrice}`
      )
      .then((resp) => {
        setPrePaymentText(resp.data);
      })
      .catch((error) => {
        console.log(error.response.status);
      });
    axios
      .get(
        `${process.env.REACT_APP_URL}/Dashboard/NumberToTextAr?number=${props.data.keyRecevePrice}`
      )
      .then((resp) => {
        setKeyRecieveText(resp.data);
      })
      .catch((error) => {
        console.log(error.response.status);
      });
    axios
      .get(
        `${process.env.REACT_APP_URL}/Dashboard/NumberToTextAr?number=${props.data.buyingPrice}`
      )
      .then((resp) => {
        setUnitPrice(resp.data);
      })
      .catch((error) => {
        console.log(error.response.status);
      });
    axios
      .get(
        `${process.env.REACT_APP_URL}/Dashboard/NumberToTextAr?number=${props.pageNumber}&suffix=false`
      )
      .then((resp) => {
        setPageNumberText(resp.data);
      })
      .catch((error) => {
        console.log(error.response.status);
      });
  }, [props]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    console.log(props.data.property);
    props.data.property &&
      axios
        .get(
          `${
            process.env.REACT_APP_URL
          }/Dashboard/NumberToTextAr?number=${props.data.property.area
            .replace(/\s+/g, "")
            .replace("m2", "")}&suffix=false`
        )
        .then((resp) => {
          setAreaText(resp.data);
        });
  }, [props.data.property]);
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });

  function numToOrderString(num) {
    switch (num) {
      case 1:
        return "الأول";
        break;
      case 2:
        return "الثاني";
        break;
      case 3:
        return "الثالث";
        break;
      case 4:
        return "الرابع";
        break;
      case 5:
        return "الخامس";
        break;
      case 6:
        return "السادس";
        break;
      case 7:
        return "السابع";
        break;
      case 8:
        return "الثامن";
        break;
      case 9:
        return "التاسع";
        break;
      case 10:
        return "العاشر";
        break;
      case 11:
        return "احدة عشر";
        break;
      case 12:
        return "اثنا عشر";
        break;
      case 13:
        return "ثلاثة عشر";
        break;
      case 14:
        return "اربعة عشر";
        break;
      case 15:
        return "خمسة عشر";
        break;
      case 16:
        return "ستة عشر";
        break;
      case 17:
        return "سبعة عشر";
        break;
      case 18:
        return "ثمانية عشر";
        break;
      case 19:
        return "تسعة عشر";
        break;
      case 20:
        return "عشرون";
        break;
      case 20:
        return "عشرون";
        break;
      case 21:
        return "واحد وعشرون";
        break;
      case 22:
        return "اثنان وعشرون";
        break;
      case 23:
        return "ثلاثة وعشرون";
        break;
      case 24:
        return "أربعة وعشرون";
        break;
      case 25:
        return "خمسة وعشرون";
        break;
      case 26:
        return "ستة وعشرون";
        break;
      case 27:
        return "سبعة وعشرون";
        break;
      case 28:
        return "ثمانية وعشرون";
        break;
      case 29:
        return "تسعة وعشرون";
        break;
      case 30:
        return "ثلاثون";
        break;
      case 31:
        return "واحد وثلاثون";
        break;
      case 32:
        return "اثنان وثلاثون";
        break;
      case 33:
        return "ثلاثة وثلاثون";
        break;
      case 34:
        return "أربعة وثلاثون";
        break;
      case 35:
        return "خمسة وثلاثون";
        break;
      case 36:
        return "ستة وثلاثون";
        break;
      case 37:
        return "سبعة وثلاثون";
        break;
      case 38:
        return "ثمانية وثلاثون";
        break;
      case 39:
        return "تسعة وثلاثون";
        break;
      case 40:
        return "أربعون";
        break;
      case 41:
        return "واحد وأربعون";
        break;
      case 42:
        return "اثنان وأربعون";
        break;
      case 43:
        return "ثلاثة وأربعون";
        break;
      case 44:
        return "أربعة وأربعون";
        break;
      case 45:
        return "خمسة وأربعون";
        break;
      case 46:
        return "ستة وأربعون";
        break;
      case 47:
        return "سبعة وأربعون";
        break;
      case 48:
        return "ثمانية وأربعون";
        break;
      case 49:
        return "تسعة وأربعون";
        break;
      case 50:
        return "خمسون";
        break;
      case 51:
        return "واحد وخمسون";
        break;
      case 52:
        return "اثنان وخمسون";
        break;
      case 53:
        return "ثلاثة وخمسون";
        break;
      case 54:
        return "أربعة وخمسون";
        break;
      case 55:
        return "خمسة وخمسون";
        break;
      case 56:
        return "ستة وخمسون";
        break;
      case 57:
        return "سبعة وخمسون";
        break;
      case 58:
        return "ثمانية وخمسون";
        break;
      case 59:
        return "تسعة وخمسون";
        break;
      case 60:
        return "ستون";
        break;
      default:
        return "ستون +";
    }
  }

  var bulletclasses = [
    "ا",
    "ب",
    "پ",
    "ت",
    "ث",
    "ج",
    "چ",
    "ح",
    "خ",
    "د",
    "ذ",
    "ر",
    "ز",
    "ژ",
    "ص",
    "ض",
    "ط",
    "ظ",
    "ع",
    "غ",
    "ف",
    "ق",
    "ک",
    "گ",
    "ل",
    "م",
    "ن",
    "و",
    "ه",
    "ی",
  ];
  return (
    <div
      style={{
        textAlign: "right",
        lineHeight: 2,
      }}
    >
      <p
        style={{ lineHeight: 2 }}
        style={{ textAlign: "center" }}
        class="p1"
        dir="rtl"
      >
        <span class="s1">
          <strong>/</strong>
        </span>
        <strong>عقد</strong> <strong>بيع</strong> <strong>و</strong>{" "}
        <strong>شراء</strong> <strong>الوحدات</strong> <strong>السكنية</strong>
      </p>
      <p style={{ lineHeight: 2 }} class="p2" dir="rtl">
        &nbsp;
      </p>
      <b>
        <p style={{ lineHeight: 2 }} class="p3" dir="rtl">
          <span class="s2">رقم</span> <span class="s2">العقد</span>
          <span class="s3">:{" " + props.data.contractNumber}</span>
          <span class="Apple-converted-space">
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp;{" "}
          </span>
          <span class="s2">التأريخ</span>
          <span class="s3">
            : {moment(props.data.buyingDate).format("YYYY/MM/DD")}
          </span>
        </p>
        <p style={{ lineHeight: 2 }} class="p4" dir="rtl">
          <span class="s4">
            <strong>أولاً</strong>
          </span>{" "}
          <span class="s3">/ </span>الأطراف
        </p>
      </b>
      <p style={{ lineHeight: 2 }} class="p5" dir="rtl">
        ألطرف الأول<span class="s6"> (</span>البائع
        <span class="s6">
          ) :<span class="Apple-converted-space">&nbsp; </span>
        </span>
        شركة شجرة الزيتون للتجارة العامة و الاستثمارات العقارية و الصناعية
        <span class="s6"> .</span>
      </p>
      <p style={{ lineHeight: 2 }} class="p6" dir="rtl">
        <span class="s7">قسم</span> <span class="s7">المبيعات</span> :{" "}
        <span class="s7">رقم</span> <span class="s7">الموبايل</span> :
        <span class="Apple-converted-space">&nbsp; </span>07503005003 -
        07503005004
      </p>
      <p style={{ lineHeight: 2 }} class="p5" dir="rtl">
        ألطرف الثاني<span class="s6"> (</span>المشتري
        <span class="s6">
          ) :&nbsp;{" "}
          {props.data.customer &&
            props.data.customer.firstName +
              " " +
              props.data.customer.middleName +
              " " +
              props.data.customer.lastName}{" "}
        </span>
      </p>
      <p style={{ lineHeight: 2 }} class="p5" dir="rtl">
        ألعنوان
        <span class="s6">
          {" "}
          : {props.data.customer && props.data.customer.address}
        </span>
      </p>
      <p style={{ lineHeight: 2 }} class="p5" dir="rtl">
        رقم الموبايل
        <span class="s6">
          {" "}
          :{" "}
          {props.data.customer &&
            `${props.data.customer.phone} - ${
              props.data.customer.phone2 && props.data.customer.phone2
            }`}
        </span>
      </p>
      <p style={{ lineHeight: 2 }} class="p5" dir="rtl">
        الوثائق<span class="s6">:</span>
      </p>
      <p style={{ lineHeight: 2 }} class="p5" dir="rtl">
        رقم هوية الأحوال المدنية
        <span class="s6">
          {" "}
          : {props.data.customer && props.data.customer.idcard}
        </span>
      </p>
      <p style={{ lineHeight: 2 }} class="p5" dir="rtl">
        رقم الجنسية العراقية
        <span class="s6">
          {" "}
          :{props.data.customer && props.data.customer.jnsia}
          <span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;</span>
        </span>
      </p>
      <p style={{ lineHeight: 2 }} class="p5" dir="rtl">
        رقم بطاقة المعلومات
        <span class="s6">
          {" "}
          : {props.data.customer && props.data.customer.informationCard}
          <span class="Apple-converted-space">&nbsp; &nbsp; &nbsp; &nbsp;</span>
        </span>
      </p>
      <p style={{ lineHeight: 2 }} class="p5" dir="rtl">
        رقم البطاقة التموينية
        <span class="s6">
          <span class="Apple-converted-space">&nbsp; </span>:{" "}
          {props.data.customer && props.data.customer.foodCard}
          <span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;</span>
        </span>
      </p>
      <p style={{ lineHeight: 2 }} class="p5" dir="rtl">
        رقم بطاقة الإقامة
        <span class="s6">
          {" "}
          : {props.data.customer && props.data.customer.placementCard}
        </span>
      </p>
      <p style={{ lineHeight: 2 }} class="p7" dir="rtl">
        تأييد الآسايش
        <span class="s6">
          {" "}
          :&nbsp;{props.data.customer && props.data.customer.securitySupport}
        </span>
      </p>
      <p style={{ lineHeight: 2, marginBottom: 116 }} class="p7" dir="rtl">
        أربع<span class="s6"> (4) </span>صورشخصية ملونة
        <span class="s6"> : </span>
      </p>
      <b>
        <p style={{ lineHeight: 2 }} class="p7" dir="rtl">
          <span class="s4">
            <strong>ثانياً</strong>
          </span>
          <span class="s6"> / </span>معلومات المشروع<span class="s6">:</span>
        </p>
      </b>
      <ul class="ul1">
        <li class="li7" dir="rtl">
          ١- اسم المشروع
          <span class="s6">
            :<span class="Apple-converted-space">&nbsp; </span>(
          </span>
          مشروع زيتون بلس ستي<span class="s6">).</span>
        </li>
        <li class="li8" dir="rtl">
          <span class="s7">٢- رقم إجازة الاستثمار</span> :
          <span class="Apple-converted-space">&nbsp; </span>(221){" "}
          <span class="s7">في</span> (29/12/2009).
        </li>
      </ul>
      <p style={{ lineHeight: 2 }} class="p9" dir="rtl">
        موقع المشروع
        <span class="s6">
          :<span class="Apple-converted-space">&nbsp; </span>
        </span>
        محافظة أربيل<span class="s6"> / </span>ناحية دارتو
        <span class="s6">.</span>
      </p>
      <b>
        <p style={{ lineHeight: 2 }} class="p7" dir="rtl">
          <span class="s4">
            <strong>ثالثاً</strong>
          </span>
          <span class="s6"> / </span>موضوع العقد<span class="s6"> :</span>
        </p>
      </b>
      <p style={{ lineHeight: 2 }} class="p7" dir="rtl">
        شركة<span class="s6"> (</span>شجرة الزيتون للتجارة العامة و الإستثمار
        العقاري و الصناعي<span class="s6">) </span>و حسب إجازة الإستثمار المرقمة
        <span class="s6"> (221) </span>في<span class="s6"> (29/12/2009) </span>
        الصادرة من هيئة الإستثمار لحكومة إقليم كوردستان
        <span class="s6">, </span>و كذلك إستناداً إلى قرار المجلس الأعلى
        للإستثمار المرقم<span class="s6"> (499) </span>بتأريخ{" "}
        <span class="s8">(23/4/2019) </span>تقوم ببناء الوحدات السكنية في الموقع
        المخصص للمشروع بمحافظة أربيل<span class="s6"> / </span>ناحية دارتو
        <span class="s6">, </span>و بيعها للمواطنين و بناءاً على رغبة الطرف
        الثاني و موافقته بعد معاينتها و النظر إلى تصميم الوحدة و كذلك خارطة موقع
        المشروع<span class="s6"> (Site Plan) </span>وقطعة الأرض و قيمة الوحدة و
        آلية دفع الكلفة و موافقة الطرف الأول على هذا البيع
        <span class="s6">, </span>و إتفق الطرفان الرسميان للعقد على الإلتزام
        القانوني بمضمون العقد و بالشكل التالي<span class="s6">:</span>
      </p>
      <ul class="ul1">
        <li class="li7" dir="rtl">
          قام الطرف الأول ببيع الوحدة السكنية المرقمة
          <span class="s6">
            {" "}
            ({props.data.property && props.data.property.name}){" "}
          </span>
          وفي زون
          <span class="s6">
            ({props.data.property && props.data.property.zone}){" "}
          </span>
          على شـــــــــــــارع
          <span class="s6">
            {" "}
            ({props.data.property && props.data.property.streetNumber}){" "}
          </span>
          بلوك
          <span class="s6">
            {" "}
            ({props.data.property && props.data.property.block}){" "}
          </span>
          الى الطرف الثاني و التي تم إنشاءها في مشروع<span class="s6"> (</span>
          مدينة زيتون بلس
          <span class="s6">) </span>و التي تقع علــــى قطعة من قطع أراضي مدينة
          الزيتون بمساحة
          <span class="s6">
            {" "}
            (
            <span class="Apple-converted-space">
              {props.data.property && props.data.property.area}
            </span>
            ) {areaText}
          </span>{" "}
          متر مربع و مكونة من طابقين كما ورد في تصميم البناء المرفق
          <span class="s6">, </span>و سيتم تثبيت رقم الملكية بشكل نهائي بعد
          إكمال الإفراز و التوحيد و تسجيلها لدى الدائرة المختصة للتسجيل العقاري
          بشكل رسمي<span class="s6">.</span>
        </li>
      </ul>
      <b>
        <p style={{ lineHeight: 2 }} class="p9" dir="rtl">
          <strong>مواصفات</strong> <strong>الوحدة</strong>{" "}
          <strong>السكنية</strong>
          <span class="s6">
            <strong>:</strong>
          </span>
        </p>
      </b>
      <ul class="ul1">
        <li class="li11" dir="rtl">
          <span class="s2">ا</span>- Raft foundation
        </li>
        <li class="li12" dir="rtl">
          ب<span class="s1">- </span>الشبابيك مصنوعة من المينيوم
          <span class="s6"> .</span>
        </li>
        <li class="li12" dir="rtl">
          ج<span class="s1">- </span>سقف<span class="s6"> (</span>جبسن بورد
          <span class="s6">).</span>
        </li>
        <li class="li12" dir="rtl">
          د<span class="s1">- </span>واجهة فوكا<span class="s6"> .</span>
        </li>
        <li class="li12" dir="rtl">
          ه<span class="s1">- </span>الارضية بورسيلين<span class="s6"> .</span>
        </li>
        <li class="li12" dir="rtl">
          و<span class="s1">- </span>ابواب صناعة تركية<span class="s6"> .</span>
        </li>
      </ul>
      <ul class="ul1">
        <li class="li12" dir="rtl">
          2- يجب على المشترى دفع اجور الخدمات وبخلاف ذلك يحق للشركة قطع الخدمات
          <span class="s6"> .</span>
        </li>
        <li class="li12" dir="rtl">
          3- لايحق للمشتري القيام بأي تغير بالشكل خارجي الوحدة السكنية.
          <span class="s6">.</span>
        </li>
        <li class="li13" dir="rtl">
          4- سعر الوحــــــــدة
          <span class="s6">
            {" "}
            (
            {props.data &&
              `${formatter.format(props.data.buyingPrice)} ${unitPrice}`}
            )
          </span>{" "}
          <span class="s6">, </span>و يدفع الطرف الثاني المبلغ إلى الطرف الأول
          على النحو اللتالي<span class="s6">:-</span>
        </li>
      </ul>
      <b>
        <p style={{ lineHeight: 2 }} class="p9" dir="rtl">
          <span class="s4">
            <strong>رابعاً</strong>
          </span>
          <span class="s6"> / </span>شروط الدفـــــع<span class="s6"> :</span>
        </p>
      </b>
      <ul class="ul1">
        <li class="li7" dir="rtl">
          دفع مبلغ
          <span class="s6">
            {" "}
            ({formatter.format(props.data.prePaymentPrice)}){" "}
          </span>
          {prePaymentText} عند توقيع عقد البيع و الشراء بين الطرفين
          <span class="s6">, </span>أي يدفع الطرف الثاني إلى الطرف الأول
          <span class="s6"> </span>
          <span class="s10">بتأريخ</span>
          <span class="s11">
            {" "}
            {moment(props.data.prePaymentDate).format("YYYY/MM/DD")}.{" "}
          </span>
        </li>
        {props.data.paymentProcess &&
          props.data.paymentProcess
            .filter((el) => el.isItKeyPayment === false)
            .map((el, key) => {
              return (
                <li class="li15" dir="rtl">
                  <br />
                  {bulletclasses[key]}) القسط {numToOrderString(key + 1)}
                  {props.removeAfterMonth !== true &&
                    ` 
                 بعد 
                  ${Math.ceil(
                    moment(el.currentPayDate).diff(
                      moment(props.data.prePaymentDate),
                      "months",
                      true
                    )
                  )}
                  شهرا بعد التوقيع على العقد 
                  `}
                  &nbsp; حيث يبلغ
                  <span class="s6">
                    {" "}
                    ({formatter.format(el.currentPayPrice)}){" "}
                  </span>{" "}
                  {el.arPriceText} بتأريخ
                  <span class="s6">
                    {" "}
                    {moment(el.currentPayDate).format("YYYY-MM-DD")}{" "}
                  </span>{" "}
                  <br />
                </li>
              );
            })}
      </ul>
      <p style={{ lineHeight: 2 }} class="p15" dir="rtl">
        عند انتهاء الوحدة السكنية بمدة أقصر وأقل من المدة المحددة عندها يجب على
        الطرف الثاني ومع استلامه المفتاح الوحدة السكنية (فيلا) تسديد مبلغ
        المتبقي وقدره
        <span class="s6">
          {" "}
          ({formatter.format(props.data.keyRecevePrice)}){" "}
        </span>
        {keyRecieveText} الى الطرف الاول
        <span class="s6"> ( </span>الشركة
        <span class="s6"> ) </span>وايضا على الطرف الثاني الالتزام بتسديد جميع
        الاقساط المتبقية الى الطرف الاول والموضحة في الفقرة
        <span class="s6"> (الرابعة) , </span>اي يجب أن لايبقى اية مستحقات في ذمة
        الطرف الثاني<span class="s6"> . </span>
      </p>
      <p class="li7" dir="rtl">
        5) هذا العقد هو بمثابة وثيقة ضمان للطرف الثاني حيث يمنحه الحق و الملكية
        في وحدة السكنية.
        <span class="s6">.</span>
      </p>
      <p class="li7" dir="rtl">
        6) يكون تسليم الوحدة السكنية بعد (
        {props.data && props.data.property && props.data.property.zone === "I"
          ? "36"
          : "30"}
        ) ثلاثون شهرا ( ايام عمل فقط) من تأريخ 1/1/2020
      </p>
      <p class="li7" dir="rtl">
        7) في حالة تأخر الطرف الثاني من دفع القسط المطلوب حسب جدول هذا العقد و
        بعد إشعاره من قبل الشركة<span class="s6">, </span>يجب على الطرف الثاني
        دفع مبلغ<span class="s6"> (100$) </span>مئة دولار أمريكي عن كل يوم تأخير
        إلى الطرف الأول<span class="s6">, </span>و يستمر الحال على هذا النحو
        لمدة شهر واحد و بعد هذه المدة يحق للطرف الأول ان يقوم بإشعار الطرف
        الثاني بفسخ العقد و ابلاغ المديرية العامة للإستثمار/اربيل بذالك, و يحق
        الطرف الاول بيع الوحدة لشخص ثالث و هذة متفق عليه من قبل الطرفين هذه
        العقد.
      </p>
      <p class="li7" dir="rtl">
        8) في حالة طلب فسخ العقد من قبل الطرف الثاني لأي سبب كان
        <span class="s6">, </span>يجب على الطرف الثاني دفع
        <span class="s6"> (30%) </span>ثلاثون بالمئة من سعر الوحدة إلى الطرف
        الأول كغرامة بغرض التعويض عن المصروفات الإدارية و الخدمات المتعلقة
        بإجراءات العقد<span class="s6">, </span>و يتم إرجاع المبلغ المتبقي إليه
        بعد بيع الوحدة السكنية إلى شخص آخر<span class="s6">.</span>
      </p>
      <p class="li7" dir="rtl">
        9) في حالة عدم دفع الطرف الثاني المسحقات التي وقعت على عاتقه في غضون شهر
        واحد سيتم اتخاذ الإجراءات القانونية بحقه و يعتبر العقد باطلاَ, و في هذه
        الحالة يقوم الطرف الاول بغرامة مالية الى الطرف الثاني و قدره (30%)
        ثلاثين بالمئة من سعر الإجمالى الوحدة السكنية كتعويض عن المصاريف الإدارية
        و الأضرار التي لحقت بالطرف الأول جراء ذلك و يتم إرجاع متبقي المبلغ إلى
        الطرف الثاني بعد بيع الوحدة إلى مشتري جديد , و للطرف الأول (الشركة)
        إتخاذ تلك الإجراءات.
      </p>
      <p class="li7" dir="rtl">
        10) في حالة تأخر الطرف الأول<span class="s6"> (</span>الشركة
        <span class="s6">) </span>عن تسليم الوحدة السكنية للطرف الثاني
        <span class="s6"> (</span>المشتري<span class="s6">) </span>في الموعد
        المحدد لهذا الغرض<span class="s6">, </span>كما ورد في النقطة
        <span class="s6"> (6) السادسة </span>من هذا العقد و كان الإهمال بسبب
        الطرف الأول
        <span class="s6">, </span>ولكن ماعدا في حالة الظروف القاهرة
        <span class="s6">, </span>عندها يكون الطرف الأول ملزم بدفع مبلغ
        <span class="s6"> (100$) </span>مئة دولار أمريكي يومياً الى الطرف الثاني
        <span class="s6"> (</span>المشتري<span class="s6">) </span>لحين بلوغ
        نسبة<span class="s6"> (5%) </span>من قيمة الوحدة و يتم تسوية هذه الغرامة
        عند دفع القسط الأخير<span class="s6">.</span>
      </p>
      <p class="li7" dir="rtl">
        11) يقوم الطرف الأول بتسليم الوحدة السكنية إلى الطرف الثاني (المشتري)
        حسب بنود هذا العقد و الطرف الثاني ملزم بإستلام الوحدة و التوقيع على
        إستمارة إستلام الوحدة قبل موعد إنتهاء مدة هذه العقد, و اتمام دفع جميع
        المستحقات المتبقية من قيمة الوحدة كما هو وارد في هذا العقد , مع مراعاتهم
        للتعليمات العامة للمديرية العامة للإستثمار/اربيل .
      </p>
      <p class="li7" dir="rtl">
        12) يأخذ الطرف الثاني على عاتقه كافة المصروفات و الرسومات و الأتعاب فيما
        تخص الدائرة المختصة لتسجيل العقاري و الدوائر و المؤسسات المعنية ضمن إطار
        هذا العقد<span class="s6">.</span>
      </p>
      <p class="li7" dir="rtl">
        13) لا يحق للطرف الثاني التنازل عن هذا العقد لطرف ثالث دون إشعار و
        الحصول على موافقة سبقية من الطرف الأول (الشركة ) و بشكل خطي, و على الطرف
        الثاني أن يكون قد دفع قيمة وحدته كاملة إلى الطرف الأول (الشركة).
      </p>
      <p class="li7" dir="rtl">
        14) لا يتم تسجيل الوحدة السكنية بإسم الطرف الثاني إلا بعد إكمال بناء
        الوحدة و دفع كامل قيمة الوحدة من قبل الطرف الثاني
        <span class="s6"> (</span>المشتري<span class="s6">) </span>إلى الطرف
        الأول<span class="s6">.</span>
      </p>
      <p class="li7" dir="rtl">
        15) تكون مدة صيانة الوحدة السكنية سنة واحدة من تأريخ إكمال الوحدة
        السكنية من قبل الطرف الأول, و يكون الطرف الأول مسؤولاً عن الأضرار التي
        تلحق بالوحدة السكنية (البناء) جراء التنفيذ, و لا تتحمل الشركة الاضرار
        التي تلحق بالوحدة (بسبب الكوارث الطبيعية) او سبب سوء استخدام الطرف
        الثاني بالوحدة و في هذه الحالة يقوم الطرف الثاني هو المسؤل عنه و لا
        يتحمل الطرف الاول (الشركة) اي مصاريف.
      </p>
      <p class="li7" dir="rtl">
        16) في حالة حدوث أي نوع من الخلافات بين طرفي هذا العقد الرسميين
        <span class="s6">, </span>فيتم أولاً حسمه بطريقة ودية
        <span class="s6">, </span>ولكن إن لم يتم حسمه فستكون محكمة بداءة أربيل
        هي الجهة المختصة و الحاسمة لتسوية الخلاف بين الطرفين الرسميين لهذا العقد
        <span class="s6">.</span>
      </p>
      <p style={{ lineHeight: 2 }} class="p7" dir="rtl">
        يتكون هذا العقد من إسم و عنوان و مقدمة و<span class="s6"> (16) </span>
        ستة عشرة نقطة ({props.pageNumber} {pageNumberText}) صفحات
        <span class="s6">, </span>حيث تعتبر كل من الصفحات
        <span class="s6">
          <span class="Apple-converted-space">&nbsp; </span>
        </span>
        ({props.pageNumber} {pageNumberText}) أصلية و تمتلك نفس القوة القانونية
        قانوناً
        <span class="s6">, </span>وكذلك بعد القراءة الدقيقة و الفهم من قبل
        الطرفين الرسميين لهذا العقد على مجمل محتوى هذا العقد
        <span class="s6">, </span>تم توقيع العقد بين الطرفين في مدينة أربيل
        بتأريخ
        <span class="s6">
          {" "}
          ({moment(props.data.buyingDate).format("YYYY-MM-DD")}).
        </span>
      </p>
      <p style={{ lineHeight: 2 }} class="p10" dir="rtl">
        &nbsp;
      </p>
      <p style={{ lineHeight: 2 }} class="p10" dir="rtl">
        &nbsp;
      </p>
      <p style={{ lineHeight: 2 }} class="p10" dir="rtl">
        &nbsp;
      </p>
      <div
        style={{ display: "flex", justifyContent: "space-around", height: 120 }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            justifyContent: "space-between",
          }}
        >
          <b>
            <span class="s2">الطرف الأول</span>
          </b>
          <span class="s2">شركة شجرة الزيتون</span>{" "}
          <span class="s2">للتجارة العامة و الإستثمار العقاري و الصناعي</span>
          <span class="s2">:التوقيع</span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <b>
            <span class="s2">الطرف الثاني</span>
          </b>
          <span class="s2">
            {props.data.customer &&
              `ألإسم: ${props.data.customer.firstName} ${props.data.customer.middleName} ${props.data.customer.lastName}`}{" "}
          </span>{" "}
          <span class="s2">
            العنوان: {props.data.customer && props.data.customer.address}
          </span>
          <span class="s2">:التوقيع</span>
        </div>
      </div>
      {/* <p style={{ lineHeight: 2 }} class="p10" dir="rtl">
        &nbsp;
      </p>
      <p style={{ lineHeight: 2 }} class="p10" dir="rtl">
        &nbsp;
      </p>
      <p style={{ lineHeight: 2 }} class="p10" dir="rtl">
        &nbsp;
      </p>
      <p style={{ lineHeight: 2 }} class="p16" dir="rtl">
        <strong>الطرف</strong> <strong>الأول</strong> <strong>الطرف</strong>{" "}
        <strong>الثاني</strong>
      </p>
      <p style={{ lineHeight: 2 }} class="p7" dir="rtl">
        ألإسم<span class="s6">: </span>
        <strong>شركة</strong> <strong>شجرة</strong> <strong>الزيتون</strong>
      </p>
      <p style={{ lineHeight: 2 }} class="p7" dir="rtl">
        العنوان<span class="s6">: </span>
        <strong>للتجارة</strong> <strong>العامة</strong> <strong>و</strong>{" "}
        <strong>الإستثمار</strong> <strong>العقاري</strong> <strong>و</strong>{" "}
        <strong>الصناعي</strong>
      </p>
      <p style={{ lineHeight: 2 }} class="p7" dir="rtl">
        التوقيع<span class="s6">: </span>التوقيع<span class="s6">:</span>
      </p>
      <p style={{ lineHeight: 2 }} class="p10" dir="rtl">
        &nbsp;
      </p> */}
      <br />
      <br />
      <br />
      <br />
      <b>
        <p style={{ lineHeight: 2 }} class="p7" dir="rtl">
          <span class="s12">
            <strong>المرفقات</strong>
          </span>
          <span class="s13">
            <strong>:</strong>
          </span>
        </p>
      </b>
      <ul class="ul1">
        <li class="li7" dir="rtl">
          خارطة المشروع<span class="s6">.</span>
        </li>
        <li class="li7" dir="rtl">
          خارطة<span class="s6"> (</span>تصميم<span class="s6">) </span>الوحدة
          <span class="s6">.</span>
        </li>
        <li class="li7" dir="rtl">
          المستمسكات<span class="s6"> (</span>هوية الأحوال المدنية
          <span class="s6">, </span>الجنسية العراقية<span class="s6">, </span>
          بطاقة المعلومات<span class="s6">, </span>البطاقة التموينية
          <span class="s6"> , </span>جواز السفر<span class="s6">, </span>بطاقة
          الإقامة<span class="s6">, </span>تأييد الآسايش
          <span class="s6">, </span>تأييد المختار<span class="s6">) .</span>
        </li>
      </ul>
      <br />
      <br />
      <div style={{ marginRight: "30%" }}>
        <p style={{ lineHeight: 2 }} class="p17" dir="rtl">
          المديرية العامة الإستثمار<span class="s6"> / </span>أربيل
        </p>
        <p style={{ lineHeight: 2 }} class="p18" dir="rtl">
          ( <span class="s7">التصديق</span> ) <span class="s7">بتأريخ</span>
          <span class="Apple-converted-space">&nbsp; &nbsp; &nbsp; </span>/
          <span class="Apple-converted-space">&nbsp; &nbsp; </span>/ 2020
        </p>
      </div>
    </div>
  );
}

export default ArabicTemplate;
