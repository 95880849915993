import React, { PureComponent, useEffect, useState } from 'react';
import {
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#dc3545', '#17a2b8'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
                                   cx, cy, midAngle, innerRadius, outerRadius, percent, index, data
                               }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

function PerSourcesChart(props) {
    const [visits, setVisits] = useState(0)
    const [visitGroup, setVisitGroup] = useState([])
    const [data, setData] = useState([]);


    useEffect(() => {
        if (props.currentTab === 'perSources') {
            const token = localStorage.getItem("token");
            axios.defaults.headers.common = {Authorization: `Bearer ${token}`};
            axios.get(`${process.env.REACT_APP_URL}/LeadVisits/GetLeadVisitReport2?startDate=${props.startDate}&endDate=${props.endDate}&_end=1000`)
                .then((resp) => {
                    let data1 = [];
                    resp.data.map((value) => {
                        data1.push({
                            name: value[0].sourceName,
                            value: value.map(visit => visit.females + visit.males).reduce((a,b) => a + b, 0)
                        })
                    })
                    setData(data1)
                });
        }
    }, [props.startDate, props.endDate, props.currentTab]);

    return (
        <div>
            <BarChart
                width={1500}
                height={300}
                data={data}
                margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                {/* <Bar dataKey="pv" fill="#8884d8" /> */}
                <Bar dataKey="value" fill="#82ca9d" />
            </BarChart>
        </div>
    )
}

export default PerSourcesChart
