import React, { PureComponent, useState } from 'react';
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import axios from 'axios'

function GenderVisits(props) {
    const [males, setMales] = useState(0)
    const [females, setFemales] = useState(0)

    React.useEffect(() => {
        if (props.currentTab === 'leadAnalyzes') {
            const token = localStorage.getItem("token");
            axios.defaults.headers.common = {Authorization: `Bearer ${token}`};
            axios.get(`${process.env.REACT_APP_URL}/LeadVisits?startDate=${props.startDate}&endDate=${props.endDate}&_end=1000`).then((resp) => {
                setMales(resp.data.map(el => el.males).reduce((a, b) => a + b, 0))
                setFemales(resp.data.map(el => el.females).reduce((a, b) => a + b, 0))
            });
        }
      }, [props.startDate, props.endDate, props.currentTab]);

    const data = [
        {
        name: 'Gender', male: males, female: females
        }
    ];

    return (
        <div>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5, right: 30, left: 20, bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend verticalAlign="top" height={20}/>
          <Bar dataKey="female" fill="#8884d8" />
          <Bar dataKey="male" fill="#82ca9d" />
        </BarChart>
        </div>
    )
}

export default GenderVisits
