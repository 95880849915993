import React, { useEffect, useState } from "react";
import "../invoice.css";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";

function KZone(props) {
  const [properties, setProperties] = useState([]);
  useEffect(() => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
      .get(process.env.REACT_APP_URL + "/Dashboard/K")
      .then((res) => {
        setProperties(res.data)
        props.setLoader()
      });
  }, []);

  const soldProperties = properties.filter(
    (property) => property.isSold === true
  ).length;
  const remainedProperties = properties.filter(
    (property) => property.isSold === false
  ).length;
  const holdedProperties = properties.filter(
    (property) => property.isHijz === true
  ).length;

  const role = localStorage.getItem("role");
  role !== 'Seller' && properties.map((el) => {
    document.getElementById(`${el.name}`) && document.getElementById(`${el.name}`).addEventListener("click", () => {
      el.isSold
        ? window.open(`#/BuyContracts/${el.contractId}/show`, "_self")
        : window.open(
            `#/Properties?filter=%7B"name"%3A"${el.name}"%7D&order=ASC&page=1&perPage=10&sort=id`,
            "_self"
          );
    });
  });

  properties
    .filter((el) => el.isSold === false)
    .map((el) => {
      document.getElementById(`${el.name}`) && (document.getElementById(`${el.name}`).style.fill = "#ffc107");
    });

  properties
    .filter((el) => el.isSold === true)
    .map((el) => {
      document.getElementById(`${el.name}`) && (document.getElementById(`${el.name}`).style.fill = "#17a2b8");
    });

  properties
    .filter((el) => el.isHijz === true)
    .map((el) => {
      document.getElementById(`${el.name}`) && (document.getElementById(`${el.name}`).style.fill = "#dc3545");
    });

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "80%",
        }}
      >
        <Button
          onClick={() => {
            props.resetZone();
          }}
        >
          Back
        </Button>
        <Button variant="info">
          Sold Properties <Badge variant="light">{soldProperties}</Badge>
          <span className="sr-only">unread messages</span>
        </Button>
        <Button variant="warning">
          Remained Properties{" "}
          <Badge variant="light">{remainedProperties}</Badge>
          <span className="sr-only">unread messages</span>
        </Button>
        <Button variant="danger">
          On Hold Properties <Badge variant="light">{holdedProperties}</Badge>
          <span className="sr-only">unread messages</span>
        </Button>
      </div>
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1024 1024"
      >
        <title>K-Last </title>
        <polygon
          id="K1"
          points="1004.89 919.08 1004.89 838.1 921.47 838.1 921.47 914.39 963.18 917.95 1004.89 919.08"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K2"
          x="931.76"
          y="764.31"
          width="62.85"
          height="83.42"
          transform="translate(1769.2 -157.16) rotate(90)"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K3"
          x="931.76"
          y="701.48"
          width="62.85"
          height="83.42"
          transform="translate(1706.37 -219.99) rotate(90)"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K4"
          x="931.76"
          y="638.26"
          width="62.85"
          height="83.42"
          transform="translate(1643.15 -283.21) rotate(90)"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K5"
          x="931.76"
          y="575.03"
          width="62.85"
          height="83.42"
          transform="translate(1579.92 -346.44) rotate(90)"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K6"
          x="931.76"
          y="511.63"
          width="62.85"
          height="83.42"
          transform="translate(1516.52 -409.84) rotate(90)"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K7"
          x="931.76"
          y="448.48"
          width="62.85"
          height="83.42"
          transform="translate(1453.38 -472.98) rotate(90)"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K8"
          x="931.76"
          y="385.04"
          width="62.85"
          height="83.42"
          transform="translate(1389.93 -536.43) rotate(90)"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K9"
          x="931.76"
          y="321.87"
          width="62.85"
          height="83.42"
          transform="translate(1326.77 -599.6) rotate(90)"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K10"
          x="931.76"
          y="258.58"
          width="62.85"
          height="83.42"
          transform="translate(1263.48 -662.89) rotate(90)"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K11"
          x="710.12"
          y="36.08"
          width="62.85"
          height="83.42"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K12"
          x="646.46"
          y="36.08"
          width="62.85"
          height="83.42"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K13"
          x="583.61"
          y="36.08"
          width="62.85"
          height="83.42"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K14"
          x="519.95"
          y="36.08"
          width="62.85"
          height="83.42"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K15"
          x="457.1"
          y="36.08"
          width="62.85"
          height="83.42"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K16"
          x="393.44"
          y="36.08"
          width="62.85"
          height="83.42"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K17"
          x="330.45"
          y="36.08"
          width="62.85"
          height="83.42"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K18"
          x="266.79"
          y="36.08"
          width="62.85"
          height="83.42"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K19"
          x="203.77"
          y="36.08"
          width="62.85"
          height="83.42"
          style={{ fill: "#eff7ed" }}
        />
        <polygon
          id="K20"
          points="202.96 36.08 122.39 36.08 123.83 81.07 127.63 119.5 202.96 119.5 202.96 36.08"
          style={{ fill: "#eff7ed" }}
        />
        <polygon
          id="K21"
          points="260.56 211.82 142.83 211.82 154.24 266.04 162.83 298.36 260.56 298.29 260.56 211.82"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K22"
          x="259.45"
          y="211.82"
          width="63.95"
          height="86.47"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K23"
          x="323.59"
          y="211.82"
          width="63.95"
          height="86.47"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K24"
          x="387"
          y="211.82"
          width="63.95"
          height="86.47"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K25"
          x="449.88"
          y="211.82"
          width="63.39"
          height="86.47"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K26"
          x="513.32"
          y="211.82"
          width="63.24"
          height="86.47"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K27"
          x="576.56"
          y="211.82"
          width="63.95"
          height="86.47"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K28"
          x="639.98"
          y="211.82"
          width="62.93"
          height="86.47"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K29"
          x="702.9"
          y="211.82"
          width="63.13"
          height="86.47"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K30"
          x="766.48"
          y="211.82"
          width="62.85"
          height="86.47"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K31"
          x="766.52"
          y="298.36"
          width="62.85"
          height="82.77"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K32"
          x="702.94"
          y="298.36"
          width="63.13"
          height="82.77"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K33"
          x="640.02"
          y="298.36"
          width="62.93"
          height="82.77"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K34"
          x="576.6"
          y="298.36"
          width="63.95"
          height="82.77"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K35"
          x="513.36"
          y="298.36"
          width="63.24"
          height="82.77"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K36"
          x="449.92"
          y="298.36"
          width="63.39"
          height="82.77"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K37"
          x="387.04"
          y="298.36"
          width="63.95"
          height="82.77"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K38"
          x="323.63"
          y="298.36"
          width="63.95"
          height="82.77"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K39"
          x="259.49"
          y="298.36"
          width="63.95"
          height="82.77"
          style={{ fill: "#eff7ed" }}
        />
        <polygon
          id="K40"
          points="259.49 298.36 162.83 298.36 178.87 342.81 194.51 381.13 259.49 381.13 259.49 298.36"
          style={{ fill: "#eff7ed" }}
        />
        <polygon
          id="K41"
          points="321.1 432.67 216.66 432.67 242.06 479.72 264.61 517.67 321.1 517.67 321.1 432.67"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K42"
          x="320.45"
          y="432.67"
          width="64.09"
          height="85"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K43"
          x="384.18"
          y="432.63"
          width="64.09"
          height="85"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K44"
          x="446.65"
          y="432.63"
          width="64.09"
          height="85"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K45"
          x="510.08"
          y="432.63"
          width="64.09"
          height="85"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K46"
          x="574.17"
          y="432.63"
          width="64.09"
          height="85"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K47"
          x="636.71"
          y="432.63"
          width="64.09"
          height="85"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K48"
          x="699.98"
          y="432.63"
          width="64.09"
          height="85"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K49"
          x="763.42"
          y="432.63"
          width="64.09"
          height="85"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K50"
          x="763.46"
          y="516.94"
          width="64.09"
          height="85"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K51"
          x="700.02"
          y="516.94"
          width="64.09"
          height="85"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K52"
          x="636.75"
          y="516.94"
          width="64.09"
          height="85"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K53"
          x="574.21"
          y="516.94"
          width="64.09"
          height="85"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K54"
          x="510.12"
          y="516.94"
          width="64.09"
          height="85"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K55"
          x="446.69"
          y="516.94"
          width="64.09"
          height="85"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K56"
          x="384.22"
          y="516.94"
          width="64.09"
          height="85"
          style={{ fill: "#eff7ed" }}
        />
        <path
          id="K57"
          d="M383.57,516.94h-119s21.47,34.11,34.78,50.35c7.08,8.63,28.19,34.65,28.19,34.65h56.06Z"
          style={{ fill: "#eff7ed" }}
        />
        <path
          id="K58"
          d="M510.23,652H373.29s44.51,45.15,68.47,63.15c21.75,17.42,68.47,51.61,68.47,51.61Z"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K59"
          x="510.63"
          y="652.04"
          width="63.07"
          height="85"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K60"
          x="573.88"
          y="652.04"
          width="63.07"
          height="85"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K61"
          x="637.26"
          y="652.04"
          width="63.07"
          height="85"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K62"
          x="700.54"
          y="652.04"
          width="63.07"
          height="85"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K63"
          x="763.46"
          y="652.04"
          width="63.07"
          height="85"
          style={{ fill: "#eff7ed" }}
        />
        <rect
          id="K64"
          x="699.91"
          y="736.46"
          width="126.63"
          height="63.07"
          style={{ fill: "#eff7ed" }}
        />
        <polygon
          id="K65"
          points="826.68 800.1 763.61 800.1 763.75 887.63 796.85 895.58 826.82 901.49 826.68 800.1"
          style={{ fill: "#eff7ed" }}
        />
        <polygon
          id="K66"
          points="763.61 800.1 700.54 800.1 700.54 867.2 732.07 877.9 763.61 887.1 763.61 800.1"
          style={{ fill: "#eff7ed" }}
        />
        <polygon
          id="K67"
          points="700.33 737.45 637.26 737.45 637.26 840.21 700.33 867.2 700.33 737.45"
          style={{ fill: "#eff7ed" }}
        />
        <path
          id="K68"
          d="M637,737.45H510V766.8s47,30.44,63.22,39.78c15.6,9,63.73,33.63,63.73,33.63Z"
          style={{ fill: "#eff7ed" }}
        />
        <g style={{ opacity: "0.6900000000000001" }}>
          <g id="_1" data-name="1">
            <rect
              x="921.47"
              y="260.63"
              width="83.67"
              height="7.11"
              style={{ fill: "#139b48" }}
            />
            <rect x="1004.37" y="267.39" width="1.31" height="652.49" />
            <path
              d="M1005.14,187.44H922a9.9,9.9,0,0,0-9.9,9.9V917.29a19.48,19.48,0,0,0,18.07,19.45c22.2,1.61,58.88,4.07,74.84,4.07"
              style={{
                fill: "none",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <path
              d="M1005.39,209.37H921.47v704.8s32.48,4.91,83.56,4.91"
              style={{
                fill: "none",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <rect x="921.47" y="837.45" width="83.67" height="1.31" />
            <rect x="921.47" y="773.96" width="83.67" height="1.31" />
            <rect x="921.47" y="710.74" width="83.67" height="1.31" />
            <rect x="921.47" y="647.51" width="83.67" height="1.31" />
            <rect x="921.47" y="584.11" width="83.67" height="1.31" />
            <rect x="921.47" y="520.97" width="83.67" height="1.31" />
            <rect x="921.47" y="457.52" width="83.67" height="1.31" />
            <rect x="921.47" y="394.35" width="83.67" height="1.31" />
            <rect x="921.47" y="331.06" width="83.67" height="1.31" />
            <rect x="921.47" y="267.39" width="83.67" height="1.31" />
            <rect x="921.47" y="259.63" width="83.67" height="1.31" />
          </g>
          <g id="_1-2" data-name="1">
            <rect
              x="735.37"
              y="74.04"
              width="83.81"
              height="7.11"
              transform="translate(854.88 -699.68) rotate(90)"
              style={{ fill: "#139b48" }}
            />
            <path
              d="M100.66,35.72c0,15.86,2.48,53.09,4.08,75.54a19.49,19.49,0,0,0,19.45,18.09H844.13a9.9,9.9,0,0,0,9.9-9.9V35.83"
              style={{
                fill: "none",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <path
              d="M832.1,119.5V36.69s-628.33-.61-709.71-1a712.5,712.5,0,0,0,4.9,83.78Z"
              style={{
                fill: "none",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <rect x="202.71" y="36.08" width="1.31" height="83.43" />
            <rect x="266.2" y="36.08" width="1.31" height="83.43" />
            <rect x="329.41" y="36.08" width="1.31" height="83.43" />
            <rect x="392.65" y="36.08" width="1.31" height="83.43" />
            <rect x="456.04" y="36.08" width="1.31" height="83.43" />
            <rect x="519.19" y="36.08" width="1.31" height="83.43" />
            <rect x="582.63" y="36.08" width="1.31" height="83.43" />
            <rect x="645.8" y="36.08" width="1.31" height="83.43" />
            <rect x="709.09" y="36.08" width="1.31" height="83.43" />
            <rect x="772.76" y="35.69" width="1.31" height="83.81" />
            <rect x="780.53" y="35.69" width="1.31" height="83.81" />
          </g>
          <g id="B.H3">
            <path
              d="M135.64,204.34h693a9.72,9.72,0,0,1,9.72,9.72V382.14a9.72,9.72,0,0,1-9.72,9.72H191a26.84,26.84,0,0,1-25.36-18C158,352.05,145.75,316.38,141.08,301c-4.83-16-14.69-53.57-20.73-76.88A15.79,15.79,0,0,1,135.64,204.34Z"
              style={{
                fill: "none",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <path
              d="M142.83,211.23H829.37V381.36H194.24s-25-61.2-31.28-82C156.38,277.62,142.83,211.23,142.83,211.23Z"
              style={{
                fill: "none",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <rect x="765.82" y="211.39" width="1.31" height="170.18" />
            <rect x="702.47" y="211.39" width="1.31" height="170.18" />
            <rect x="639.32" y="211.23" width="1.31" height="170.18" />
            <rect x="575.97" y="211.23" width="1.31" height="170.18" />
            <rect x="512.51" y="211.23" width="1.31" height="170.18" />
            <rect x="449.23" y="211.23" width="1.31" height="170.18" />
            <rect x="386.13" y="211.15" width="1.31" height="170.18" />
            <rect x="322.93" y="211.15" width="1.31" height="170.18" />
            <rect x="259.45" y="211.15" width="1.31" height="170.18" />
            <rect x="162.83" y="297.7" width="667.15" height="1.31" />
          </g>
          <g id="B.H2">
            <path
              d="M201.16,425.16H827.8a8.18,8.18,0,0,1,8.18,8.18v169a8.17,8.17,0,0,1-8.18,8.17H321.57a30.46,30.46,0,0,1-25.69-14.1s-38.81-50.45-50.3-68.26c-14.15-21.9-51.05-91-51.05-91A7.86,7.86,0,0,1,201.16,425.16Z"
              style={{
                fill: "none",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <path
              d="M216.66,432.71H827.55V602H327.28s-32.16-37-59.94-80.06C242.7,483.74,216.66,432.71,216.66,432.71Z"
              style={{
                fill: "none",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <rect x="763.46" y="432.79" width="1.31" height="169.15" />
            <rect x="700.02" y="432.79" width="1.31" height="169.15" />
            <rect x="636.75" y="432.79" width="1.31" height="169.15" />
            <rect x="573.56" y="432.79" width="1.31" height="169.15" />
            <rect x="510.13" y="432.77" width="1.31" height="169.15" />
            <rect x="447.04" y="432.79" width="1.31" height="169.15" />
            <rect x="383.56" y="432.79" width="1.31" height="169.15" />
            <rect x="320.45" y="432.81" width="1.31" height="84.72" />
            <polygon points="264.95 518.25 264.54 516.94 827.55 516.94 827.55 518.25 264.95 518.25" />
          </g>
          <g id="B.H1">
            <path
              d="M373.29,652.11H826.53V901.49C557.91,854.24,373.29,652.11,373.29,652.11Z"
              style={{
                fill: "none",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <path
              d="M357.14,642.93H823.23a12.81,12.81,0,0,1,12.82,12.81V907.91a12.85,12.85,0,0,1-15.34,12.59C584,873.81,407.78,722.14,348.26,665A12.81,12.81,0,0,1,357.14,642.93Z"
              style={{
                fill: "none",
                stroke: "#000",
                strokeMiterlimit: 10,
                strokeWidth: 0.5,
              }}
            />
            <rect x="763.1" y="799.44" width="1.31" height="88.18" />
            <rect x="700.92" y="799.44" width="126.18" height="1.31" />
            <rect x="699.91" y="652.55" width="1.31" height="214.65" />
            <rect x="763.1" y="652.49" width="1.31" height="84.1" />
            <rect x="510.66" y="736.14" width="316.1" height="1.31" />
            <polygon points="637.75 652.47 636.44 654.14 636.44 840.21 637.75 840.21 637.75 652.47" />
            <polygon points="511.32 652.47 510 652.47 510 766.05 511.32 766.8 511.32 652.47" />
            <polygon points="574.53 652.47 573.22 652.47 573.22 736.28 574.53 736.84 574.53 652.47" />
          </g>
          <rect
            x="869.08"
            y="539.79"
            width="10.17"
            height="372.96"
            rx="5.09"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="869.08"
            y="220.58"
            width="10.17"
            height="278.93"
            rx="5.09"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="309.77"
            y="-19.52"
            width="10.17"
            height="372.96"
            rx="5.09"
            transform="translate(481.81 -147.9) rotate(90)"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <rect
            x="676.13"
            y="27.36"
            width="10.17"
            height="279.2"
            rx="5.09"
            transform="translate(848.17 -514.26) rotate(90)"
            style={{
              fill: "#129b48",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <line
            x1="202.71"
            y1="407.12"
            x2="835.98"
            y2="407.12"
            style={{
              fill: "#fff",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
          <line
            x1="351.52"
            y1="623.76"
            x2="835.98"
            y2="623.76"
            style={{
              fill: "#fff",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 0.5,
            }}
          />
        </g>
        <path d="M569.29,754.92h5v.62c-1.41.16-1.66.3-1.66,2.16v3.62a2.64,2.64,0,0,0,1.34-1c1.07-1.17,2.23-2.55,3-3.6.53-.71.46-1-.34-1.12l-.48-.06v-.62h4.53v.62a3.8,3.8,0,0,0-3.09,1.75c-.58.66-1.92,2.17-3,3.45,1.35,1.89,3.28,4.51,4.32,5.84.89,1.13,1.13,1.3,2.23,1.45v.63h-3.07c-1.29-1.73-2.81-3.87-4.41-6.09-.42-.6-.73-.66-1-.63v4c0,1.83.16,2,1.66,2.12v.63h-5V768c1.48-.15,1.63-.29,1.63-2.12V757.7c0-1.88-.14-2-1.63-2.16Z" />
        <path d="M583.91,768.94a1.26,1.26,0,0,1-1.19-1.42,1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.28,1.28,0,0,1,583.91,768.94Z" />
        <path d="M589.84,760.66a2.45,2.45,0,0,1,1.18-.37c2,0,3.31,1.72,3.31,3.93,0,2.56-1.61,4.69-3.86,4.69s-3.89-2-3.89-5.31a8.5,8.5,0,0,1,5.07-7.92,14,14,0,0,1,2.2-.57l.11.72a9.86,9.86,0,0,0-2,.73,6.49,6.49,0,0,0-3.3,4.78Zm.38.9a3.31,3.31,0,0,0-1.68.52,9.08,9.08,0,0,0-.11,1.49c0,2.67.85,4.55,2.35,4.55s1.79-1.72,1.79-3.12C592.57,763.21,591.81,761.56,590.22,761.56Z" />
        <path d="M599.57,755.24a3,3,0,0,1,3.07,3.15,2.77,2.77,0,0,1-.53,1.7,16.9,16.9,0,0,1-1.49,1.3c1.27.81,2.5,1.82,2.5,3.66a3.77,3.77,0,0,1-3.77,3.86,3.39,3.39,0,0,1-3.63-3.5,2.62,2.62,0,0,1,.84-2.06,19.6,19.6,0,0,1,1.65-1.3,3.9,3.9,0,0,1-2-3.31A3.38,3.38,0,0,1,599.57,755.24Zm0,12.92c1.2,0,1.93-.9,1.93-2.41s-1.17-2.46-2.72-3.33a3.3,3.3,0,0,0-1.44,2.82C597.34,767.2,598.4,768.16,599.57,768.16ZM599.33,756c-1.06,0-1.62.95-1.62,2.11,0,1.52.9,2.11,2.34,3a3.13,3.13,0,0,0,1.12-2.55C601.17,756.7,600.25,756,599.33,756Z" />
        <path
          d="M561.35,792.61a9.3,9.3,0,0,1,0-14.83l.35.41a9.86,9.86,0,0,0-2.63,7,10.11,10.11,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M568.28,786.63v1.17c0,1,.07,1.14,1.22,1.24v.48h-3.88V789c1.18-.1,1.26-.22,1.26-1.26v-1.15h-4.21v-.51c1.55-2.19,3.25-4.57,4.81-6.71h.8v6.42h1.56v.8Zm-1.4-5.45c-1,1.4-2.09,3-3.19,4.65h3.19Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M575.66,784.93l-1,.52a2.06,2.06,0,0,1-1,.24,2.86,2.86,0,0,1-2.92-3,3.33,3.33,0,0,1,3.36-3.33c2.09,0,3.27,1.83,3.27,4a6.36,6.36,0,0,1-4.08,6,8.17,8.17,0,0,1-2,.43l-.13-.49A5.39,5.39,0,0,0,575.66,784.93Zm-1.76-5c-1.18,0-1.6,1.1-1.6,2.27,0,1.4.78,2.57,2.09,2.57a2.3,2.3,0,0,0,1.34-.42,8.07,8.07,0,0,0,.07-1.06C575.8,781.85,575.39,779.93,573.9,779.94Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M581.94,779.36c2.46,0,3.35,2.72,3.35,5.17s-.89,5.18-3.35,5.18-3.4-2.68-3.4-5.17C578.54,782.2,579.46,779.36,581.94,779.36Zm0,.58c-1.41,0-1.76,2.34-1.76,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S583.29,779.94,581.93,779.94Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M595.15,789.52v-.47c.89-.09,1-.16,1-1.25v-2.69c0-1.17-.43-1.81-1.33-1.81a2.74,2.74,0,0,0-1.67.78c0,.19,0,.39,0,.68v3.13c0,1,.13,1.07,1,1.16v.47h-3.45v-.47c.94-.09,1.07-.15,1.07-1.19v-2.78c0-1.17-.39-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48v-.47c1-.09,1.12-.16,1.12-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.19,2.19,0,0,1,1.28-.43,1.86,1.86,0,0,1,1.75,1.29c.44-.34.84-.6,1.24-.87a2.38,2.38,0,0,1,1.27-.42c1.27,0,2,.9,2,2.48v3.09c0,1,.1,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M606.26,787.3c-.22.74-.5,1.61-.66,2.22h-6.11v-.39c.88-.86,1.83-1.81,2.62-2.72a6.3,6.3,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.83-2.05A2.79,2.79,0,0,0,600,782l-.38-.35.93-1.34a3,3,0,0,1,2.24-1,2.64,2.64,0,0,1,2.76,2.78c0,1.34-.49,2.29-2,3.84-.67.69-1.66,1.64-2.33,2.27h3c.89,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M607.79,777.78a9.3,9.3,0,0,1,0,14.83l-.36-.41a9.89,9.89,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M716.76,817.23h5v.62c-1.42.16-1.66.3-1.66,2.16v3.62a2.81,2.81,0,0,0,1.34-1c1.07-1.17,2.22-2.56,3-3.6.54-.71.47-1-.33-1.12l-.48-.06v-.62h4.52v.62a3.79,3.79,0,0,0-3.09,1.75l-3,3.44c1.35,1.9,3.28,4.52,4.32,5.84.89,1.14,1.13,1.3,2.23,1.46V831h-3.07c-1.3-1.72-2.81-3.86-4.41-6.08-.42-.6-.73-.66-1-.63v4c0,1.83.16,2,1.66,2.13V831h-5v-.62c1.49-.16,1.64-.3,1.64-2.13V820c0-1.88-.14-2-1.64-2.16Z" />
        <path d="M731.38,831.25c-.69,0-1.18-.58-1.18-1.43a1.28,1.28,0,0,1,1.18-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,731.38,831.25Z" />
        <path d="M737.31,823a2.41,2.41,0,0,1,1.18-.37c2,0,3.32,1.72,3.32,3.92,0,2.56-1.62,4.7-3.86,4.7s-3.89-2.05-3.89-5.31a8.51,8.51,0,0,1,5.07-7.93,14.17,14.17,0,0,1,2.2-.56l.11.72a9.86,9.86,0,0,0-2,.73,6.48,6.48,0,0,0-3.31,4.78Zm.38.89a3.24,3.24,0,0,0-1.67.53,9.08,9.08,0,0,0-.11,1.49c0,2.66.85,4.55,2.35,4.55s1.79-1.72,1.79-3.12C740.05,825.51,739.29,823.86,737.69,823.86Z" />
        <path d="M746.3,823a2.38,2.38,0,0,1,1.18-.37c2,0,3.31,1.72,3.31,3.92,0,2.56-1.61,4.7-3.86,4.7s-3.88-2.05-3.88-5.31a8.49,8.49,0,0,1,5.07-7.93,14,14,0,0,1,2.19-.56l.11.72a9.56,9.56,0,0,0-2,.73,6.48,6.48,0,0,0-3.31,4.78Zm.38.89a3.24,3.24,0,0,0-1.67.53,8,8,0,0,0-.12,1.49c0,2.66.86,4.55,2.35,4.55s1.79-1.72,1.79-3.12C749,825.51,748.27,823.86,746.68,823.86Z" />
        <path
          d="M709.83,854.92a9.29,9.29,0,0,1,0-14.84l.34.42a9.88,9.88,0,0,0-2.62,7,10.07,10.07,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M718.12,849.6c-.22.75-.49,1.62-.65,2.23h-6.12v-.4c.88-.86,1.83-1.8,2.62-2.71a6.27,6.27,0,0,0,1.93-3.83c0-1.29-.68-2.06-1.83-2.06a2.78,2.78,0,0,0-2.2,1.48l-.39-.35.93-1.33a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.83-.68.7-1.67,1.65-2.34,2.28h3c.88,0,1.11-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M726.1,842.07c-1.54,3.24-3.05,6.48-4.58,9.78l-1.23.13-.12-.18a92.1,92.1,0,0,0,4.7-8.66h-3.41c-1.05,0-1.13.14-1.56,1.18h-.52c.17-1,.25-1.77.33-2.47H726Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M730.41,841.66c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.68-3.39-5.17S727.94,841.66,730.41,841.66Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.6S731.77,842.24,730.41,842.24Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M743.62,851.83v-.47c.89-.1,1-.16,1-1.25v-2.69c0-1.18-.43-1.81-1.33-1.81a2.74,2.74,0,0,0-1.67.77,5.81,5.81,0,0,1,0,.69v3.13c0,1,.12,1.06,1,1.16v.47h-3.46v-.47c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.84,2.84,0,0,0-1.66.77v3.79c0,1,.11,1.09,1,1.19v.47h-3.48v-.47c1-.1,1.11-.16,1.11-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a9.72,9.72,0,0,0,2.34-.64v1.28a12,12,0,0,1,1.14-.8,2.29,2.29,0,0,1,1.28-.43,1.86,1.86,0,0,1,1.76,1.3c.43-.34.83-.61,1.23-.88a2.5,2.5,0,0,1,1.27-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M754.73,849.6c-.21.75-.49,1.62-.65,2.23H748v-.4c.87-.86,1.83-1.8,2.62-2.71a6.3,6.3,0,0,0,1.92-3.83c0-1.29-.67-2.06-1.83-2.06a2.8,2.8,0,0,0-2.2,1.48l-.39-.35.93-1.33a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.83-.68.7-1.67,1.65-2.33,2.28h3c.88,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M756.26,840.08a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.83,9.83,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M779.13,817.23h5v.62c-1.41.16-1.66.3-1.66,2.16v3.62a2.81,2.81,0,0,0,1.34-1c1.07-1.17,2.22-2.56,3-3.6.53-.71.46-1-.34-1.12l-.48-.06v-.62h4.53v.62a3.78,3.78,0,0,0-3.09,1.75l-3,3.44c1.35,1.9,3.28,4.52,4.32,5.84.89,1.14,1.13,1.3,2.23,1.46V831h-3.07c-1.3-1.72-2.81-3.86-4.41-6.08-.42-.6-.73-.66-1-.63v4c0,1.83.16,2,1.66,2.13V831h-5v-.62c1.49-.16,1.64-.3,1.64-2.13V820c0-1.88-.14-2-1.64-2.16Z" />
        <path d="M793.76,831.25c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.31,1.31,0,0,1,1.22,1.42A1.29,1.29,0,0,1,793.76,831.25Z" />
        <path d="M799.68,823a2.41,2.41,0,0,1,1.19-.37c2,0,3.31,1.72,3.31,3.92,0,2.56-1.61,4.7-3.86,4.7s-3.89-2.05-3.89-5.31A8.51,8.51,0,0,1,801.5,818a14.17,14.17,0,0,1,2.2-.56l.11.72a9.86,9.86,0,0,0-2,.73,6.45,6.45,0,0,0-3.3,4.78Zm.39.89a3.28,3.28,0,0,0-1.68.53,9.08,9.08,0,0,0-.11,1.49c0,2.66.85,4.55,2.35,4.55s1.79-1.72,1.79-3.12C802.42,825.51,801.66,823.86,800.07,823.86Z" />
        <path d="M812.37,817.8l.11.17-.49,1.52H807.7c-.17,1.12-.41,2.72-.58,3.73a7.87,7.87,0,0,1,2.22-.62,3.44,3.44,0,0,1,3.49,3.58,4.7,4.7,0,0,1-1.63,3.57,5.35,5.35,0,0,1-3.3,1.47,2.6,2.6,0,0,1-1.91-.81.82.82,0,0,1-.33-.74,1.29,1.29,0,0,1,.42-.79.38.38,0,0,1,.55,0,3.14,3.14,0,0,0,2.33,1.18c1.47,0,2.17-1.45,2.17-3,0-1.86-1-3.18-3-3.18a4.82,4.82,0,0,0-1.85.38l-.07-.1c.3-1.69.71-4.62.92-6.36Z" />
        <path
          d="M773.2,854.92a9.31,9.31,0,0,1,0-14.84l.34.42a9.88,9.88,0,0,0-2.62,7,10.12,10.12,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M775.07,843.61l.76-1a2.86,2.86,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.71,1.71,0,0,1-.51,1.18,6.84,6.84,0,0,1-1.15.86,2.64,2.64,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.6,2.6,0,0,1-1.68-.56.72.72,0,0,1-.27-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.35a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16l-.11-.5c1.43-.47,2.53-1.09,2.53-2.25a1.39,1.39,0,0,0-1.5-1.49,2.55,2.55,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M782.9,843.61l.76-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.71,1.71,0,0,1-.51,1.18,6.84,6.84,0,0,1-1.15.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.16,3.71-4.27,3.71a2.63,2.63,0,0,1-1.69-.56.7.7,0,0,1-.26-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.35a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16l-.11-.5c1.43-.47,2.53-1.09,2.53-2.25a1.39,1.39,0,0,0-1.51-1.49,2.54,2.54,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M793.79,841.66c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17S791.31,841.66,793.79,841.66Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.6S795.14,842.24,793.78,842.24Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M807,851.83v-.47c.89-.1,1-.16,1-1.25v-2.69c0-1.18-.43-1.81-1.33-1.81a2.7,2.7,0,0,0-1.67.77c0,.19,0,.4,0,.69v3.13c0,1,.13,1.06,1,1.16v.47h-3.46v-.47c1-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.38-1.78-1.28-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.09,1,1.19v.47h-3.48v-.47c1-.1,1.11-.16,1.11-1.2v-3.33c0-1,0-1.08-.92-1.23v-.42a9.52,9.52,0,0,0,2.33-.64v1.28a13.82,13.82,0,0,1,1.15-.8,2.24,2.24,0,0,1,1.28-.43,1.86,1.86,0,0,1,1.75,1.3c.44-.34.84-.61,1.24-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.1,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M818.11,849.6c-.22.75-.5,1.62-.66,2.23h-6.11v-.4c.87-.86,1.83-1.8,2.62-2.71,1-1.22,1.93-2.46,1.93-3.83s-.68-2.06-1.83-2.06a2.82,2.82,0,0,0-2.21,1.48l-.39-.35.94-1.33a2.92,2.92,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.5,2.29-2,3.83-.67.7-1.66,1.65-2.33,2.28h3c.89,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M819.63,840.08a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.87,9.87,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M751.7,754.92h5v.62c-1.41.16-1.66.3-1.66,2.16v3.62a2.69,2.69,0,0,0,1.35-1c1.06-1.17,2.22-2.55,3-3.6.53-.71.47-1-.33-1.12l-.49-.06v-.62h4.53v.62a3.82,3.82,0,0,0-3.09,1.75c-.58.66-1.92,2.17-3,3.45,1.36,1.89,3.29,4.51,4.32,5.84.9,1.13,1.13,1.3,2.24,1.45v.63H760.5c-1.3-1.73-2.81-3.87-4.42-6.09-.41-.6-.72-.66-1-.63v4c0,1.83.16,2,1.67,2.12v.63h-5V768c1.48-.15,1.64-.29,1.64-2.12V757.7c0-1.88-.14-2-1.64-2.16Z" />
        <path d="M766.32,768.94a1.26,1.26,0,0,1-1.19-1.42,1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.28,1.28,0,0,1,766.32,768.94Z" />
        <path d="M772.25,760.66a2.52,2.52,0,0,1,1.18-.37c2,0,3.32,1.72,3.32,3.93,0,2.56-1.62,4.69-3.87,4.69s-3.88-2-3.88-5.31a8.5,8.5,0,0,1,5.07-7.92,13.89,13.89,0,0,1,2.19-.57l.11.72a9.77,9.77,0,0,0-2,.73,6.5,6.5,0,0,0-3.31,4.78Zm.38.9a3.24,3.24,0,0,0-1.67.52,8,8,0,0,0-.12,1.49c0,2.67.86,4.55,2.35,4.55S775,766.4,775,765C775,763.21,774.23,761.56,772.63,761.56Z" />
        <path d="M784.14,764.84v1.55c0,1.37.08,1.5,1.4,1.64v.63h-4.46V768c1.36-.14,1.45-.3,1.45-1.67v-1.52H777.7v-.68c1.78-2.88,3.73-6,5.52-8.85h.92v8.48h1.78v1.05Zm-1.61-7.19c-1.11,1.85-2.39,4-3.65,6.14h3.65Z" />
        <path
          d="M743.76,792.61a9.3,9.3,0,0,1,0-14.83l.35.41a9.86,9.86,0,0,0-2.63,7,10.11,10.11,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M750.69,786.63v1.17c0,1,.07,1.14,1.22,1.24v.48H748V789c1.18-.1,1.26-.22,1.26-1.26v-1.15h-4.21v-.51c1.55-2.19,3.25-4.57,4.81-6.71h.8v6.42h1.56v.8Zm-1.4-5.45c-1,1.4-2.08,3-3.18,4.65h3.18Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M759.22,779.55l.09.13-.43,1.15h-3.74c-.14.85-.35,2.06-.5,2.82a7.89,7.89,0,0,1,1.94-.47,2.8,2.8,0,0,1,3,2.72,3.35,3.35,0,0,1-1.42,2.7,5,5,0,0,1-2.87,1.11,2.46,2.46,0,0,1-1.67-.61c-.24-.21-.31-.35-.28-.56a.89.89,0,0,1,.37-.6.37.37,0,0,1,.48,0,2.87,2.87,0,0,0,2,.9c1.28,0,1.89-1.1,1.89-2.29a2.3,2.3,0,0,0-2.59-2.42,4.88,4.88,0,0,0-1.61.29l-.05-.07c.25-1.29.61-3.5.79-4.82Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M764.35,779.36c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18S761,787,761,784.54C761,782.2,761.88,779.36,764.35,779.36Zm0,.58c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S765.7,779.94,764.35,779.94Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M777.56,789.52v-.47c.89-.09,1-.16,1-1.25v-2.69c0-1.17-.43-1.81-1.33-1.81a2.74,2.74,0,0,0-1.67.78,5.92,5.92,0,0,1,0,.68v3.13c0,1,.13,1.07,1,1.16v.47h-3.46v-.47c.94-.09,1.07-.15,1.07-1.19v-2.78c0-1.17-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48v-.47c1-.09,1.11-.16,1.11-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.21,2.21,0,0,1,1.28-.43,1.88,1.88,0,0,1,1.76,1.29c.43-.34.83-.6,1.23-.87a2.38,2.38,0,0,1,1.27-.42c1.27,0,2,.9,2,2.48v3.09c0,1,.09,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M788.67,787.3c-.22.74-.49,1.61-.65,2.22H781.9v-.39c.88-.86,1.83-1.81,2.62-2.72a6.24,6.24,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.83-2.05a2.76,2.76,0,0,0-2.2,1.48l-.39-.35.93-1.34a3,3,0,0,1,2.24-1,2.64,2.64,0,0,1,2.76,2.78c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.34,2.27h3c.89,0,1.12-.08,1.68-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M790.2,777.78a9.3,9.3,0,0,1,0,14.83l-.36-.41a9.89,9.89,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M654.52,754.92h5v.62c-1.41.16-1.66.3-1.66,2.16v3.62a2.69,2.69,0,0,0,1.35-1c1.06-1.17,2.22-2.55,3-3.6.53-.71.47-1-.33-1.12l-.49-.06v-.62H666v.62a3.82,3.82,0,0,0-3.09,1.75c-.58.66-1.92,2.17-3,3.45,1.36,1.89,3.29,4.51,4.33,5.84.89,1.13,1.12,1.3,2.23,1.45v.63h-3.07c-1.3-1.73-2.81-3.87-4.42-6.09-.41-.6-.72-.66-1-.63v4c0,1.83.16,2,1.67,2.12v.63h-5V768c1.48-.15,1.64-.29,1.64-2.12V757.7c0-1.88-.14-2-1.64-2.16Z" />
        <path d="M669.14,768.94c-.7,0-1.18-.58-1.18-1.42a1.28,1.28,0,0,1,1.18-1.43,1.31,1.31,0,0,1,1.22,1.43A1.28,1.28,0,0,1,669.14,768.94Z" />
        <path d="M675.07,760.66a2.52,2.52,0,0,1,1.18-.37c2,0,3.32,1.72,3.32,3.93,0,2.56-1.62,4.69-3.87,4.69s-3.88-2-3.88-5.31a8.5,8.5,0,0,1,5.07-7.92,13.89,13.89,0,0,1,2.19-.57l.11.72a10,10,0,0,0-2,.73,6.5,6.5,0,0,0-3.31,4.78Zm.38.9a3.24,3.24,0,0,0-1.67.52,9.08,9.08,0,0,0-.11,1.49c0,2.67.85,4.55,2.34,4.55s1.79-1.72,1.79-3.12C677.8,763.21,677.05,761.56,675.45,761.56Z" />
        <path d="M688.7,755.77c-1.77,4.28-3.5,8.56-5.26,12.92l-1.41.17-.13-.24c1.93-3.58,3.92-7.85,5.39-11.43h-3.92c-1.2,0-1.29.18-1.79,1.55H681c.2-1.27.28-2.33.38-3.25h7.21Z" />
        <path
          d="M644.59,792.61a9.28,9.28,0,0,1,0-14.83l.34.41a9.9,9.9,0,0,0-2.62,7,10.1,10.1,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M651.52,786.63v1.17c0,1,.06,1.14,1.22,1.24v.48h-3.89V789c1.19-.1,1.26-.22,1.26-1.26v-1.15H645.9v-.51c1.56-2.19,3.25-4.57,4.81-6.71h.81v6.42h1.55v.8Zm-1.41-5.45c-1,1.4-2.08,3-3.18,4.65h3.18Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M654.84,789.52V789c1.76-.1,1.86-.2,1.86-1.39v-6.08c0-.82-.15-.86-.84-.92l-.73-.08v-.43a14.49,14.49,0,0,0,3-.78v8.29c0,1.21.1,1.29,1.86,1.39v.48Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M665.17,779.36c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.39-2.68-3.39-5.17C661.78,782.2,662.7,779.36,665.17,779.36Zm0,.58c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S666.53,779.94,665.17,779.94Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M678.38,789.52v-.47c.89-.09,1-.16,1-1.25v-2.69c0-1.17-.43-1.81-1.33-1.81a2.76,2.76,0,0,0-1.67.78,5.62,5.62,0,0,1,0,.68v3.13c0,1,.12,1.07,1,1.16v.47H674v-.47c.94-.09,1.07-.15,1.07-1.19v-2.78c0-1.17-.38-1.78-1.29-1.78a2.84,2.84,0,0,0-1.66.77v3.79c0,1,.1,1.1,1,1.19v.47h-3.48v-.47c1-.09,1.11-.16,1.11-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10.17,10.17,0,0,0,2.34-.63v1.28a9.23,9.23,0,0,1,1.14-.8,2.21,2.21,0,0,1,1.28-.43,1.87,1.87,0,0,1,1.76,1.29c.43-.34.83-.6,1.23-.87a2.38,2.38,0,0,1,1.27-.42c1.28,0,2,.9,2,2.48v3.09c0,1,.09,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M689.49,787.3c-.21.74-.49,1.61-.65,2.22h-6.11v-.39c.87-.86,1.83-1.81,2.62-2.72a6.28,6.28,0,0,0,1.92-3.83c0-1.29-.67-2.05-1.83-2.05a2.76,2.76,0,0,0-2.2,1.48l-.39-.35.93-1.34a3,3,0,0,1,2.24-1,2.64,2.64,0,0,1,2.76,2.78c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.33,2.27h3c.88,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M691,777.78a9.3,9.3,0,0,1,0,14.83l-.36-.41a9.85,9.85,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M651.52,674.21h5v.63c-1.41.15-1.66.29-1.66,2.15v3.62a2.72,2.72,0,0,0,1.35-1c1.06-1.18,2.22-2.56,3-3.61.53-.71.47-1-.33-1.11l-.49-.06v-.63H663v.63a3.76,3.76,0,0,0-3.09,1.75c-.58.65-1.92,2.16-3,3.44,1.36,1.89,3.29,4.52,4.33,5.84.89,1.13,1.12,1.3,2.23,1.45V688h-3.07c-1.3-1.73-2.81-3.87-4.42-6.09-.41-.6-.72-.66-1-.63v4c0,1.83.16,2,1.67,2.12V688h-5v-.63c1.48-.15,1.64-.29,1.64-2.12V677c0-1.87-.14-2-1.64-2.15Z" />
        <path d="M666.14,688.23c-.7,0-1.18-.58-1.18-1.42a1.28,1.28,0,0,1,1.18-1.43,1.31,1.31,0,0,1,1.22,1.43A1.28,1.28,0,0,1,666.14,688.23Z" />
        <path d="M672.07,680a2.5,2.5,0,0,1,1.18-.36c2,0,3.32,1.72,3.32,3.92,0,2.56-1.62,4.69-3.87,4.69s-3.88-2.05-3.88-5.31a8.5,8.5,0,0,1,5.07-7.92,13.89,13.89,0,0,1,2.19-.57l.11.73a9.36,9.36,0,0,0-2,.72,6.5,6.5,0,0,0-3.31,4.78Zm.38.9a3.15,3.15,0,0,0-1.67.53,9,9,0,0,0-.11,1.48c0,2.67.85,4.55,2.34,4.55s1.79-1.71,1.79-3.12C674.8,682.5,674.05,680.85,672.45,680.85Z" />
        <path d="M678.8,688v-.63c2-.14,2.12-.27,2.12-1.84v-8c0-1.08-.16-1.13-1-1.22l-.84-.1v-.56a14.61,14.61,0,0,0,3.49-1v11c0,1.6.11,1.7,2.14,1.84V688Z" />
        <path
          d="M646.59,711.91a9.29,9.29,0,0,1,0-14.84l.34.42a9.86,9.86,0,0,0-2.62,7,10.09,10.09,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M648.46,700.59l.76-1a2.84,2.84,0,0,1,2.11-.93,2.21,2.21,0,0,1,2.45,2.14,1.7,1.7,0,0,1-.5,1.18,7.26,7.26,0,0,1-1.15.87,2.64,2.64,0,0,1,2.4,2.46c0,2.59-3.16,3.7-4.27,3.7a2.64,2.64,0,0,1-1.69-.55.73.73,0,0,1-.27-.55,1,1,0,0,1,.38-.64.33.33,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2,4.29,4.29,0,0,0-1,.16l-.11-.49c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.48,2.56,2.56,0,0,0-2,1.25Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M659.35,698.65c2.45,0,3.35,2.72,3.35,5.17s-.9,5.18-3.35,5.18-3.4-2.68-3.4-5.17S656.87,698.65,659.35,698.65Zm0,.58c-1.42,0-1.76,2.35-1.76,4.57s.42,4.62,1.77,4.62,1.73-2.37,1.73-4.61S660.7,699.23,659.34,699.23Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M667.17,698.65c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.39-2.68-3.39-5.17S664.7,698.65,667.17,698.65Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.61S668.53,699.23,667.17,699.23Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M680.38,708.81v-.46c.89-.1,1-.16,1-1.26V704.4c0-1.17-.43-1.8-1.33-1.8a2.74,2.74,0,0,0-1.67.77,5.62,5.62,0,0,1,0,.68v3.13c0,1,.12,1.07,1,1.17v.46H676v-.46c.94-.1,1.07-.16,1.07-1.19v-2.79c0-1.17-.38-1.78-1.29-1.78a2.84,2.84,0,0,0-1.66.77v3.8c0,1,.1,1.09,1,1.19v.46h-3.48v-.46c1-.1,1.11-.17,1.11-1.21v-3.32c0-1-.06-1.08-.93-1.23v-.42a9.72,9.72,0,0,0,2.34-.64v1.28a12,12,0,0,1,1.14-.8,2.21,2.21,0,0,1,1.28-.43,1.87,1.87,0,0,1,1.76,1.29c.43-.34.83-.6,1.23-.87a2.38,2.38,0,0,1,1.27-.42c1.28,0,2,.9,2,2.48v3.1c0,1,.09,1.08,1,1.19v.46Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M691.49,706.59c-.21.75-.49,1.62-.65,2.22h-6.11v-.39c.87-.86,1.83-1.8,2.62-2.72a6.28,6.28,0,0,0,1.92-3.82c0-1.3-.67-2.06-1.83-2.06a2.76,2.76,0,0,0-2.2,1.48l-.39-.35.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.78c0,1.35-.49,2.3-2,3.84-.68.69-1.67,1.65-2.33,2.27h3c.88,0,1.12-.08,1.67-1.08Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M693,697.07a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.85,9.85,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M714.14,674.21h5v.63c-1.41.15-1.66.29-1.66,2.15v3.62a2.66,2.66,0,0,0,1.34-1c1.07-1.18,2.23-2.56,3-3.61.53-.71.47-1-.33-1.11l-.49-.06v-.63h4.53v.63a3.76,3.76,0,0,0-3.09,1.75c-.58.65-1.92,2.16-3,3.44,1.35,1.89,3.28,4.52,4.32,5.84.89,1.13,1.13,1.3,2.23,1.45V688h-3.06c-1.3-1.73-2.81-3.87-4.42-6.09-.42-.6-.73-.66-1-.63v4c0,1.83.16,2,1.67,2.12V688h-5v-.63c1.48-.15,1.63-.29,1.63-2.12V677c0-1.87-.13-2-1.63-2.15Z" />
        <path d="M728.76,688.23a1.26,1.26,0,0,1-1.19-1.42,1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.28,1.28,0,0,1,728.76,688.23Z" />
        <path d="M734.69,680a2.46,2.46,0,0,1,1.18-.36c2,0,3.31,1.72,3.31,3.92,0,2.56-1.61,4.69-3.86,4.69s-3.88-2.05-3.88-5.31a8.49,8.49,0,0,1,5.07-7.92,13.89,13.89,0,0,1,2.19-.57l.11.73a9,9,0,0,0-2,.72,6.5,6.5,0,0,0-3.31,4.78Zm.38.9a3.15,3.15,0,0,0-1.67.53,7.9,7.9,0,0,0-.12,1.48c0,2.67.86,4.55,2.35,4.55s1.79-1.71,1.79-3.12C737.42,682.5,736.66,680.85,735.07,680.85Z" />
        <path d="M748.14,685c-.24,1-.56,2.14-.75,2.94h-7v-.52c1-1.14,2.1-2.38,3-3.59,1.2-1.6,2.22-3.24,2.22-5.05s-.78-2.71-2.1-2.71-2,1.09-2.53,1.95l-.45-.46,1.07-1.77a3.2,3.2,0,0,1,2.57-1.27c1.76,0,3.17,1.38,3.17,3.68,0,1.77-.57,3-2.33,5.06-.78.92-1.92,2.17-2.68,3h3.43c1,0,1.28-.1,1.92-1.43Z" />
        <path
          d="M709.2,711.91a9.31,9.31,0,0,1,0-14.84l.35.42a9.82,9.82,0,0,0-2.63,7,10.1,10.1,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M711.08,700.59l.76-1a2.84,2.84,0,0,1,2.11-.93,2.21,2.21,0,0,1,2.45,2.14,1.7,1.7,0,0,1-.5,1.18,7.4,7.4,0,0,1-1.16.87,2.63,2.63,0,0,1,2.4,2.46c0,2.59-3.15,3.7-4.27,3.7a2.61,2.61,0,0,1-1.68-.55.73.73,0,0,1-.27-.55,1,1,0,0,1,.38-.64.33.33,0,0,1,.46,0,3,3,0,0,0,2,.88c1.1,0,1.88-.82,1.88-2.36a2,2,0,0,0-2.1-2,4.28,4.28,0,0,0-1,.16l-.12-.49c1.44-.47,2.53-1.09,2.53-2.26a1.38,1.38,0,0,0-1.5-1.48,2.54,2.54,0,0,0-2,1.25Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M722,698.65c2.46,0,3.36,2.72,3.36,5.17S724.42,709,722,709s-3.39-2.68-3.39-5.17S719.49,698.65,722,698.65Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.61S723.32,699.23,722,699.23Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M729.79,698.65c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.4-2.68-3.4-5.17S727.31,698.65,729.79,698.65Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.61S731.14,699.23,729.79,699.23Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M743,708.81v-.46c.89-.1,1-.16,1-1.26V704.4c0-1.17-.43-1.8-1.33-1.8a2.72,2.72,0,0,0-1.67.77c0,.19,0,.39,0,.68v3.13c0,1,.13,1.07,1,1.17v.46h-3.46v-.46c.94-.1,1.07-.16,1.07-1.19v-2.79c0-1.17-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.8c0,1,.11,1.09,1,1.19v.46h-3.48v-.46c1-.1,1.11-.17,1.11-1.21v-3.32c0-1-.06-1.08-.93-1.23v-.42a9.52,9.52,0,0,0,2.33-.64v1.28a13.82,13.82,0,0,1,1.15-.8,2.19,2.19,0,0,1,1.28-.43,1.88,1.88,0,0,1,1.76,1.29c.43-.34.83-.6,1.23-.87a2.38,2.38,0,0,1,1.27-.42c1.27,0,2,.9,2,2.48v3.1c0,1,.09,1.08,1,1.19v.46Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M754.11,706.59c-.22.75-.49,1.62-.65,2.22h-6.12v-.39c.88-.86,1.83-1.8,2.62-2.72a6.24,6.24,0,0,0,1.93-3.82c0-1.3-.68-2.06-1.83-2.06a2.76,2.76,0,0,0-2.2,1.48l-.39-.35.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.78c0,1.35-.49,2.3-2,3.84-.68.69-1.67,1.65-2.34,2.27h3c.89,0,1.12-.08,1.68-1.08Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M755.64,697.07a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.89,9.89,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M777.14,674.21h5v.63c-1.41.15-1.66.29-1.66,2.15v3.62a2.66,2.66,0,0,0,1.34-1c1.07-1.18,2.23-2.56,3-3.61.53-.71.47-1-.33-1.11l-.49-.06v-.63h4.53v.63a3.76,3.76,0,0,0-3.09,1.75c-.58.65-1.92,2.16-3,3.44,1.35,1.89,3.28,4.52,4.32,5.84.89,1.13,1.13,1.3,2.23,1.45V688h-3.06c-1.3-1.73-2.81-3.87-4.42-6.09-.42-.6-.73-.66-1-.63v4c0,1.83.16,2,1.67,2.12V688h-5v-.63c1.48-.15,1.63-.29,1.63-2.12V677c0-1.87-.13-2-1.63-2.15Z" />
        <path d="M791.76,688.23a1.26,1.26,0,0,1-1.19-1.42,1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.28,1.28,0,0,1,791.76,688.23Z" />
        <path d="M797.69,680a2.46,2.46,0,0,1,1.18-.36c2,0,3.31,1.72,3.31,3.92,0,2.56-1.61,4.69-3.86,4.69s-3.88-2.05-3.88-5.31a8.49,8.49,0,0,1,5.07-7.92,13.89,13.89,0,0,1,2.19-.57l.11.73a9,9,0,0,0-2,.72,6.5,6.5,0,0,0-3.31,4.78Zm.38.9a3.15,3.15,0,0,0-1.67.53,7.9,7.9,0,0,0-.12,1.48c0,2.67.86,4.55,2.35,4.55s1.79-1.71,1.79-3.12C800.42,682.5,799.66,680.85,798.07,680.85Z" />
        <path d="M803.77,677.1l.88-1.35a3.09,3.09,0,0,1,2.42-1.22,2.71,2.71,0,0,1,2.82,2.83,2.48,2.48,0,0,1-.58,1.56,8.67,8.67,0,0,1-1.33,1.14,3.32,3.32,0,0,1,2.76,3.24c0,3.43-3.62,4.9-4.9,4.9a2.83,2.83,0,0,1-1.94-.73,1,1,0,0,1-.3-.73,1.26,1.26,0,0,1,.43-.84.35.35,0,0,1,.53,0,3.28,3.28,0,0,0,2.3,1.17c1.25,0,2.15-1.08,2.15-3.12a2.42,2.42,0,0,0-2.4-2.7,4,4,0,0,0-1.19.21l-.13-.65c1.64-.62,2.9-1.44,2.9-3,0-1.27-.71-2-1.73-2a2.93,2.93,0,0,0-2.28,1.66Z" />
        <path
          d="M772.2,711.91a9.31,9.31,0,0,1,0-14.84l.34.42a9.86,9.86,0,0,0-2.62,7,10.14,10.14,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M774.07,700.59l.76-1a2.86,2.86,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.14,1.67,1.67,0,0,1-.51,1.18,6.82,6.82,0,0,1-1.15.87,2.63,2.63,0,0,1,2.4,2.46c0,2.59-3.15,3.7-4.27,3.7a2.61,2.61,0,0,1-1.68-.55.73.73,0,0,1-.27-.55.93.93,0,0,1,.38-.64.33.33,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2,4.37,4.37,0,0,0-1,.16l-.11-.49c1.44-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.5-1.48,2.53,2.53,0,0,0-2,1.25Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M785,698.65c2.46,0,3.36,2.72,3.36,5.17S787.42,709,785,709s-3.4-2.68-3.4-5.17S782.48,698.65,785,698.65Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.61S786.31,699.23,785,699.23Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M792.79,698.65c2.46,0,3.35,2.72,3.35,5.17s-.89,5.18-3.35,5.18-3.4-2.68-3.4-5.17S790.31,698.65,792.79,698.65Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.61S794.14,699.23,792.78,699.23Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M806,708.81v-.46c.88-.1,1-.16,1-1.26V704.4c0-1.17-.43-1.8-1.33-1.8a2.7,2.7,0,0,0-1.67.77c0,.19,0,.39,0,.68v3.13c0,1,.13,1.07,1,1.17v.46h-3.45v-.46c.94-.1,1.07-.16,1.07-1.19v-2.79c0-1.17-.39-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.8c0,1,.11,1.09,1,1.19v.46h-3.48v-.46c1-.1,1.12-.17,1.12-1.21v-3.32c0-1-.06-1.08-.93-1.23v-.42a9.52,9.52,0,0,0,2.33-.64v1.28a13.82,13.82,0,0,1,1.15-.8,2.19,2.19,0,0,1,1.28-.43,1.86,1.86,0,0,1,1.75,1.29c.44-.34.84-.6,1.24-.87a2.38,2.38,0,0,1,1.27-.42c1.27,0,2,.9,2,2.48v3.1c0,1,.1,1.08,1,1.19v.46Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M817.11,706.59c-.22.75-.5,1.62-.66,2.22h-6.11v-.39c.88-.86,1.83-1.8,2.62-2.72a6.3,6.3,0,0,0,1.93-3.82c0-1.3-.68-2.06-1.83-2.06a2.79,2.79,0,0,0-2.21,1.48l-.38-.35.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.78c0,1.35-.49,2.3-2,3.84-.67.69-1.66,1.65-2.33,2.27h3c.89,0,1.12-.08,1.67-1.08Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M818.63,697.07a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.89,9.89,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M587.87,674.21h5v.63c-1.41.15-1.66.29-1.66,2.15v3.62a2.66,2.66,0,0,0,1.34-1c1.07-1.18,2.23-2.56,3-3.61.53-.71.47-1-.33-1.11l-.49-.06v-.63h4.53v.63a3.76,3.76,0,0,0-3.09,1.75c-.58.65-1.92,2.16-3,3.44,1.35,1.89,3.28,4.52,4.32,5.84.89,1.13,1.13,1.3,2.23,1.45V688h-3.06c-1.3-1.73-2.81-3.87-4.42-6.09-.42-.6-.72-.66-1-.63v4c0,1.83.16,2,1.67,2.12V688h-5v-.63c1.48-.15,1.63-.29,1.63-2.12V677c0-1.87-.13-2-1.63-2.15Z" />
        <path d="M602.49,688.23a1.26,1.26,0,0,1-1.19-1.42,1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.28,1.28,0,0,1,602.49,688.23Z" />
        <path d="M608.42,680a2.46,2.46,0,0,1,1.18-.36c2,0,3.32,1.72,3.32,3.92,0,2.56-1.62,4.69-3.87,4.69s-3.88-2.05-3.88-5.31a8.49,8.49,0,0,1,5.07-7.92,13.89,13.89,0,0,1,2.19-.57l.11.73a9,9,0,0,0-2,.72,6.5,6.5,0,0,0-3.31,4.78Zm.38.9a3.15,3.15,0,0,0-1.67.53,7.9,7.9,0,0,0-.12,1.48c0,2.67.86,4.55,2.35,4.55s1.79-1.71,1.79-3.12C611.15,682.5,610.39,680.85,608.8,680.85Z" />
        <path d="M618,674.53c2.83,0,3.86,3.59,3.86,6.82s-1,6.85-3.86,6.85-3.89-3.54-3.89-6.82C614.12,678.28,615.17,674.53,618,674.53Zm0,.77c-1.63,0-2,3.09-2,6s.49,6.11,2,6.11,2-3.13,2-6.08S619.57,675.3,618,675.3Z" />
        <path
          d="M582.93,711.91a9.31,9.31,0,0,1,0-14.84l.35.42a9.82,9.82,0,0,0-2.63,7,10.1,10.1,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M584.8,700.59l.76-1a2.88,2.88,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.14,1.7,1.7,0,0,1-.5,1.18,7.4,7.4,0,0,1-1.16.87,2.63,2.63,0,0,1,2.4,2.46c0,2.59-3.15,3.7-4.27,3.7a2.61,2.61,0,0,1-1.68-.55.73.73,0,0,1-.27-.55,1,1,0,0,1,.38-.64.33.33,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.88-.82,1.88-2.36a2,2,0,0,0-2.1-2,4.28,4.28,0,0,0-1,.16l-.12-.49c1.44-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.5-1.48,2.53,2.53,0,0,0-2,1.25Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M595.69,698.65c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.4-2.68-3.4-5.17S593.21,698.65,595.69,698.65Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.61S597,699.23,595.69,699.23Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M603.52,698.65c2.46,0,3.35,2.72,3.35,5.17S606,709,603.52,709s-3.4-2.68-3.4-5.17S601,698.65,603.52,698.65Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.61S604.87,699.23,603.51,699.23Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M616.73,708.81v-.46c.89-.1,1-.16,1-1.26V704.4c0-1.17-.43-1.8-1.33-1.8a2.72,2.72,0,0,0-1.67.77c0,.19,0,.39,0,.68v3.13c0,1,.13,1.07,1,1.17v.46H612.3v-.46c.94-.1,1.07-.16,1.07-1.19v-2.79c0-1.17-.39-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.8c0,1,.11,1.09,1,1.19v.46h-3.49v-.46c1-.1,1.12-.17,1.12-1.21v-3.32c0-1-.06-1.08-.93-1.23v-.42a9.52,9.52,0,0,0,2.33-.64v1.28a13.82,13.82,0,0,1,1.15-.8,2.19,2.19,0,0,1,1.28-.43,1.86,1.86,0,0,1,1.75,1.29c.44-.34.84-.6,1.24-.87a2.38,2.38,0,0,1,1.27-.42c1.27,0,2,.9,2,2.48v3.1c0,1,.1,1.08,1,1.19v.46Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M627.84,706.59c-.22.75-.5,1.62-.66,2.22h-6.11v-.39c.88-.86,1.83-1.8,2.62-2.72a6.3,6.3,0,0,0,1.93-3.82c0-1.3-.68-2.06-1.83-2.06a2.79,2.79,0,0,0-2.21,1.48l-.38-.35.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.78c0,1.35-.49,2.3-2,3.84-.67.69-1.66,1.65-2.33,2.27h3c.89,0,1.12-.08,1.67-1.08Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M629.37,697.07a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.89,9.89,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M523.11,674.21h5v.63c-1.42.15-1.66.29-1.66,2.15v3.62a2.7,2.7,0,0,0,1.34-1c1.07-1.18,2.22-2.56,3-3.61.53-.71.46-1-.34-1.11l-.48-.06v-.63h4.52v.63a3.75,3.75,0,0,0-3.08,1.75c-.59.65-1.92,2.16-3,3.44,1.35,1.89,3.28,4.52,4.32,5.84.89,1.13,1.13,1.3,2.23,1.45V688h-3.07c-1.3-1.73-2.81-3.87-4.41-6.09-.42-.6-.73-.66-1-.63v4c0,1.83.16,2,1.66,2.12V688h-5v-.63c1.49-.15,1.64-.29,1.64-2.12V677c0-1.87-.14-2-1.64-2.15Z" />
        <path d="M537.74,688.23a1.26,1.26,0,0,1-1.19-1.42,1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.28,1.28,0,0,1,537.74,688.23Z" />
        <path d="M547.37,674.78l.11.18-.5,1.52h-4.29c-.16,1.11-.4,2.72-.57,3.72a7.82,7.82,0,0,1,2.21-.62,3.44,3.44,0,0,1,3.49,3.59,4.7,4.7,0,0,1-1.63,3.57,5.37,5.37,0,0,1-3.29,1.46,2.64,2.64,0,0,1-1.92-.8.81.81,0,0,1-.32-.74,1.19,1.19,0,0,1,.42-.79.37.37,0,0,1,.55,0,3.11,3.11,0,0,0,2.33,1.19c1.47,0,2.17-1.46,2.17-3,0-1.87-1-3.18-3-3.18a5.08,5.08,0,0,0-1.85.37l-.06-.09c.29-1.7.7-4.63.91-6.37Z" />
        <path d="M555,681.88l-1.18.69a2.06,2.06,0,0,1-1.09.33c-2,0-3.35-1.75-3.35-4,0-2,1.37-4.41,3.85-4.41S557,677,557,679.83a8.45,8.45,0,0,1-4.68,7.87,8,8,0,0,1-2.28.56l-.15-.64C552.58,687,554.45,685,555,681.88Zm-2-6.59c-1.36,0-1.84,1.47-1.84,3,0,1.86.89,3.4,2.4,3.4a2.45,2.45,0,0,0,1.54-.55,11.72,11.72,0,0,0,.08-1.4C555.2,677.83,554.73,675.29,553,675.29Z" />
        <path
          d="M518.18,711.91a9.29,9.29,0,0,1,0-14.84l.34.42a9.86,9.86,0,0,0-2.62,7,10.09,10.09,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M520.05,700.59l.76-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.14,1.67,1.67,0,0,1-.51,1.18,6.82,6.82,0,0,1-1.15.87,2.64,2.64,0,0,1,2.4,2.46c0,2.59-3.16,3.7-4.27,3.7a2.64,2.64,0,0,1-1.69-.55.72.72,0,0,1-.26-.55.93.93,0,0,1,.38-.64.33.33,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2,4.29,4.29,0,0,0-1,.16l-.11-.49c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.48,2.56,2.56,0,0,0-2,1.25Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M530.94,698.65c2.46,0,3.35,2.72,3.35,5.17s-.89,5.18-3.35,5.18-3.4-2.68-3.4-5.17S528.46,698.65,530.94,698.65Zm0,.58c-1.42,0-1.76,2.35-1.76,4.57s.42,4.62,1.77,4.62,1.74-2.37,1.74-4.61S532.29,699.23,530.93,699.23Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M538.77,698.65c2.45,0,3.35,2.72,3.35,5.17s-.9,5.18-3.35,5.18-3.4-2.68-3.4-5.17S536.29,698.65,538.77,698.65Zm0,.58c-1.42,0-1.76,2.35-1.76,4.57s.42,4.62,1.77,4.62,1.73-2.37,1.73-4.61S540.12,699.23,538.76,699.23Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M552,708.81v-.46c.89-.1,1-.16,1-1.26V704.4c0-1.17-.42-1.8-1.33-1.8a2.74,2.74,0,0,0-1.67.77,5.62,5.62,0,0,1,0,.68v3.13c0,1,.13,1.07,1,1.17v.46h-3.46v-.46c.94-.1,1.07-.16,1.07-1.19v-2.79c0-1.17-.38-1.78-1.29-1.78a2.86,2.86,0,0,0-1.66.77v3.8c0,1,.11,1.09,1,1.19v.46h-3.48v-.46c1-.1,1.11-.17,1.11-1.21v-3.32c0-1,0-1.08-.92-1.23v-.42a9.77,9.77,0,0,0,2.33-.64v1.28a13.5,13.5,0,0,1,1.14-.8,2.22,2.22,0,0,1,1.29-.43,1.87,1.87,0,0,1,1.75,1.29c.44-.34.83-.6,1.24-.87a2.37,2.37,0,0,1,1.26-.42c1.28,0,2,.9,2,2.48v3.1c0,1,.09,1.08,1,1.19v.46Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M563.08,706.59c-.21.75-.49,1.62-.65,2.22h-6.11v-.39c.87-.86,1.83-1.8,2.62-2.72a6.3,6.3,0,0,0,1.93-3.82c0-1.3-.68-2.06-1.84-2.06a2.79,2.79,0,0,0-2.2,1.48l-.39-.35.94-1.34a2.94,2.94,0,0,1,2.23-1,2.65,2.65,0,0,1,2.77,2.78c0,1.35-.5,2.3-2,3.84-.68.69-1.66,1.65-2.33,2.27h3c.89,0,1.12-.08,1.67-1.08Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M564.61,697.07a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.89,9.89,0,0,0,2.63-7,10.05,10.05,0,0,0-2.63-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M448,674.21h5v.63c-1.42.15-1.66.29-1.66,2.15v3.62a2.7,2.7,0,0,0,1.34-1c1.07-1.18,2.22-2.56,3-3.61.53-.71.46-1-.34-1.11l-.48-.06v-.63h4.52v.63a3.78,3.78,0,0,0-3.09,1.75c-.58.65-1.91,2.16-3,3.44,1.35,1.89,3.28,4.52,4.32,5.84.89,1.13,1.13,1.3,2.23,1.45V688h-3.07c-1.3-1.73-2.81-3.87-4.41-6.09-.42-.6-.73-.66-1-.63v4c0,1.83.16,2,1.66,2.12V688h-5v-.63c1.49-.15,1.64-.29,1.64-2.12V677c0-1.87-.14-2-1.64-2.15Z" />
        <path d="M462.59,688.23a1.26,1.26,0,0,1-1.18-1.42,1.29,1.29,0,0,1,1.18-1.43,1.32,1.32,0,0,1,1.23,1.43A1.28,1.28,0,0,1,462.59,688.23Z" />
        <path d="M472.23,674.78l.11.18-.5,1.52h-4.29c-.17,1.11-.4,2.72-.57,3.72a7.73,7.73,0,0,1,2.21-.62,3.45,3.45,0,0,1,3.49,3.59,4.7,4.7,0,0,1-1.63,3.57,5.37,5.37,0,0,1-3.29,1.46,2.64,2.64,0,0,1-1.92-.8.81.81,0,0,1-.32-.74,1.19,1.19,0,0,1,.42-.79.37.37,0,0,1,.55,0,3.11,3.11,0,0,0,2.33,1.19c1.47,0,2.17-1.46,2.17-3,0-1.87-1-3.18-3-3.18a5.08,5.08,0,0,0-1.85.37l-.06-.09c.29-1.7.7-4.63.91-6.37Z" />
        <path d="M478.26,674.53a3,3,0,0,1,3.07,3.15,2.77,2.77,0,0,1-.53,1.7,17.26,17.26,0,0,1-1.5,1.31c1.28.8,2.51,1.81,2.51,3.65A3.78,3.78,0,0,1,478,688.2a3.38,3.38,0,0,1-3.63-3.5,2.62,2.62,0,0,1,.84-2.06,16.61,16.61,0,0,1,1.65-1.29,3.93,3.93,0,0,1-2-3.32A3.38,3.38,0,0,1,478.26,674.53Zm0,12.92c1.2,0,1.93-.89,1.92-2.41s-1.16-2.45-2.71-3.32a3.25,3.25,0,0,0-1.44,2.81C476,686.49,477.08,687.45,478.26,687.45ZM478,675.26c-1.07,0-1.62,1-1.62,2.12,0,1.51.9,2.1,2.34,3a3.14,3.14,0,0,0,1.12-2.55C479.86,676,478.94,675.26,478,675.26Z" />
        <path
          d="M443,711.91a9.29,9.29,0,0,1,0-14.84l.34.42a9.86,9.86,0,0,0-2.62,7,10.09,10.09,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M450,705.92v1.18c0,1,.06,1.13,1.22,1.24v.47H447.3v-.47c1.19-.11,1.26-.23,1.26-1.27v-1.15h-4.2v-.51c1.55-2.19,3.25-4.57,4.8-6.7H450v6.42h1.55v.79Zm-1.41-5.44c-1,1.4-2.08,3-3.18,4.65h3.18Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M455.92,698.65a2.48,2.48,0,0,1,2.67,2.39,1.87,1.87,0,0,1-.46,1.28,13.59,13.59,0,0,1-1.3,1c1.11.61,2.18,1.38,2.18,2.77a3.1,3.1,0,0,1-3.28,2.92c-1.85,0-3.17-1-3.17-2.65a1.88,1.88,0,0,1,.73-1.56,15.32,15.32,0,0,1,1.45-1A2.93,2.93,0,0,1,453,701.3,2.76,2.76,0,0,1,455.92,698.65Zm0,9.79a1.61,1.61,0,0,0,1.67-1.83c0-1.25-1-1.86-2.36-2.52a2.4,2.4,0,0,0-1.26,2.13A2,2,0,0,0,455.92,708.44Zm-.21-9.24a1.43,1.43,0,0,0-1.41,1.6c0,1.15.78,1.6,2,2.24a2.24,2.24,0,0,0,1-1.93C457.31,699.76,456.51,699.2,455.71,699.2Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M463.63,698.65c2.45,0,3.35,2.72,3.35,5.17s-.9,5.18-3.35,5.18-3.4-2.68-3.4-5.17S461.15,698.65,463.63,698.65Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.61S465,699.23,463.62,699.23Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M476.83,708.81v-.46c.89-.1,1-.16,1-1.26V704.4c0-1.17-.42-1.8-1.33-1.8a2.74,2.74,0,0,0-1.67.77,5.62,5.62,0,0,1,.05.68v3.13c0,1,.12,1.07,1,1.17v.46H472.4v-.46c.94-.1,1.07-.16,1.07-1.19v-2.79c0-1.17-.38-1.78-1.29-1.78a2.84,2.84,0,0,0-1.66.77v3.8c0,1,.11,1.09,1,1.19v.46H468v-.46c1-.1,1.11-.17,1.11-1.21v-3.32c0-1-.05-1.08-.92-1.23v-.42a9.77,9.77,0,0,0,2.33-.64v1.28a13.5,13.5,0,0,1,1.14-.8,2.22,2.22,0,0,1,1.29-.43,1.87,1.87,0,0,1,1.75,1.29c.44-.34.83-.6,1.23-.87a2.4,2.4,0,0,1,1.27-.42c1.28,0,2,.9,2,2.48v3.1c0,1,.09,1.08,1,1.19v.46Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M487.94,706.59c-.21.75-.49,1.62-.65,2.22h-6.11v-.39c.87-.86,1.83-1.8,2.62-2.72a6.3,6.3,0,0,0,1.93-3.82c0-1.3-.68-2.06-1.84-2.06a2.78,2.78,0,0,0-2.2,1.48l-.39-.35.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.78c0,1.35-.49,2.3-2,3.84-.68.69-1.66,1.65-2.33,2.27h3c.89,0,1.12-.08,1.67-1.08Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M489.47,697.07a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.89,9.89,0,0,0,2.63-7,10.05,10.05,0,0,0-2.63-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M779.14,541.24h5v.63c-1.41.16-1.66.3-1.66,2.16v3.62a2.76,2.76,0,0,0,1.34-1c1.07-1.18,2.23-2.56,3-3.61.53-.7.47-1-.33-1.11l-.49-.06v-.63h4.53v.63a3.79,3.79,0,0,0-3.09,1.75l-3,3.44c1.35,1.89,3.28,4.52,4.32,5.84.89,1.14,1.13,1.3,2.23,1.45V555h-3.06c-1.3-1.72-2.81-3.87-4.42-6.09-.42-.6-.73-.65-1-.62v4c0,1.83.16,2,1.67,2.12V555h-5v-.63c1.48-.15,1.63-.29,1.63-2.12V544c0-1.88-.13-2-1.63-2.16Z" />
        <path d="M793.76,555.27a1.27,1.27,0,0,1-1.19-1.43,1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.29,1.29,0,0,1,793.76,555.27Z" />
        <path d="M803.39,541.81l.11.18-.5,1.52h-4.29c-.16,1.12-.4,2.72-.57,3.73a7.47,7.47,0,0,1,2.22-.62,3.43,3.43,0,0,1,3.48,3.58,4.7,4.7,0,0,1-1.62,3.57,5.39,5.39,0,0,1-3.3,1.46,2.64,2.64,0,0,1-1.92-.8.81.81,0,0,1-.32-.74,1.19,1.19,0,0,1,.42-.79.38.38,0,0,1,.55,0,3.15,3.15,0,0,0,2.33,1.19c1.47,0,2.17-1.46,2.17-3,0-1.87-1-3.18-3-3.18a5.12,5.12,0,0,0-1.85.37l-.06-.09c.29-1.7.7-4.63.92-6.37Z" />
        <path d="M809.28,541.56c2.83,0,3.85,3.59,3.85,6.83,0,3.08-1,6.84-3.85,6.84s-3.9-3.54-3.9-6.82C805.38,545.31,806.44,541.56,809.28,541.56Zm0,.77c-1.63,0-2,3.1-2,6s.49,6.1,2,6.1,2-3.13,2-6.08S810.84,542.33,809.28,542.33Z" />
        <path
          d="M772.2,578.94a9.31,9.31,0,0,1,0-14.84l.34.42a9.86,9.86,0,0,0-2.62,7,10.12,10.12,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M774.07,567.63l.76-1a2.86,2.86,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.67,1.67,0,0,1-.51,1.17,6.82,6.82,0,0,1-1.15.87,2.63,2.63,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.6,2.6,0,0,1-1.68-.56.73.73,0,0,1-.27-.55,1,1,0,0,1,.38-.64.33.33,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.36a2,2,0,0,0-2.09-2.05,3.9,3.9,0,0,0-1,.17l-.11-.5c1.44-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.5-1.48,2.53,2.53,0,0,0-2,1.25Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M785,565.68c2.46,0,3.36,2.72,3.36,5.17S787.42,576,785,576s-3.4-2.69-3.4-5.17S782.48,565.68,785,565.68Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.61S786.31,566.26,785,566.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M792.79,565.68c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.69-3.4-5.17S790.31,565.68,792.79,565.68Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.61S794.14,566.26,792.78,566.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M806,575.84v-.46c.88-.1,1-.16,1-1.26v-2.69c0-1.17-.43-1.8-1.33-1.8a2.7,2.7,0,0,0-1.67.77c0,.19,0,.39,0,.68v3.13c0,1,.13,1.07,1,1.17v.46h-3.45v-.46c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.39-1.78-1.29-1.78a2.94,2.94,0,0,0-1.67.76v3.8c0,1,.11,1.09,1,1.19v.46h-3.48v-.46c1-.1,1.12-.17,1.12-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a9.52,9.52,0,0,0,2.33-.64v1.28a13.82,13.82,0,0,1,1.15-.8,2.26,2.26,0,0,1,1.28-.43,1.85,1.85,0,0,1,1.75,1.3c.44-.35.84-.61,1.24-.88a2.38,2.38,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.1,1.09,1,1.19v.46Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M817.11,573.62c-.22.75-.5,1.62-.66,2.22h-6.11v-.39c.88-.86,1.83-1.8,2.62-2.71,1-1.22,1.93-2.46,1.93-3.83s-.68-2.06-1.83-2.06a2.79,2.79,0,0,0-2.21,1.48l-.38-.35.93-1.33a2.92,2.92,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.78c0,1.35-.49,2.3-2,3.84-.67.7-1.66,1.65-2.33,2.27h3c.89,0,1.12-.07,1.67-1.08Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M818.63,564.1a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.89,9.89,0,0,0,2.64-7,10.07,10.07,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M778.14,455.41h5V456c-1.41.16-1.66.3-1.66,2.16v3.62a2.76,2.76,0,0,0,1.34-1c1.07-1.17,2.23-2.56,3-3.6.53-.71.47-1-.33-1.12L785,456v-.62h4.53V456a3.79,3.79,0,0,0-3.09,1.75l-3,3.44c1.35,1.9,3.28,4.52,4.32,5.84.89,1.14,1.13,1.3,2.23,1.46v.62h-3.06c-1.3-1.72-2.81-3.86-4.42-6.08-.42-.6-.73-.66-1-.63v4c0,1.83.16,2,1.67,2.13v.62h-5v-.62c1.48-.16,1.63-.3,1.63-2.13v-8.2c0-1.88-.13-2-1.63-2.16Z" />
        <path d="M792.76,469.43c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42A1.3,1.3,0,0,1,794,468,1.29,1.29,0,0,1,792.76,469.43Z" />
        <path d="M801.59,465.33v1.55c0,1.37.08,1.49,1.4,1.64v.62h-4.46v-.62c1.36-.15,1.45-.3,1.45-1.67v-1.52h-4.83v-.68c1.78-2.89,3.73-6,5.52-8.85h.92v8.47h1.79v1.06ZM800,458.14c-1.11,1.85-2.39,4-3.65,6.13H800Z" />
        <path d="M810.06,463.08l-1.17.69a2.2,2.2,0,0,1-1.1.32c-2,0-3.35-1.75-3.35-4,0-2,1.38-4.41,3.85-4.41s3.76,2.43,3.76,5.3a8.43,8.43,0,0,1-4.68,7.87,8,8,0,0,1-2.28.57l-.14-.65C807.61,468.21,809.48,466.23,810.06,463.08Zm-2-6.59c-1.35,0-1.84,1.46-1.84,3,0,1.86.9,3.4,2.41,3.4a2.5,2.5,0,0,0,1.54-.56c.05-.48.07-1,.07-1.4C810.22,459,809.76,456.48,808,456.49Z" />
        <path
          d="M772.2,493.1a9.31,9.31,0,0,1,0-14.84l.34.42a9.88,9.88,0,0,0-2.62,7,10.12,10.12,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M774.07,481.79l.76-1a2.86,2.86,0,0,1,2.11-.93A2.22,2.22,0,0,1,779.4,482a1.71,1.71,0,0,1-.51,1.18,6.84,6.84,0,0,1-1.15.86,2.64,2.64,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.6,2.6,0,0,1-1.68-.56.72.72,0,0,1-.27-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.35a2,2,0,0,0-2.09-2.05,3.92,3.92,0,0,0-1,.16l-.11-.5c1.44-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.5-1.48,2.51,2.51,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M785,479.84c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.4-2.68-3.4-5.17C781.56,482.69,782.48,479.84,785,479.84Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.6S786.31,480.42,785,480.42Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M792.79,479.84c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17C789.39,482.69,790.31,479.84,792.79,479.84Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.6S794.14,480.42,792.78,480.42Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M806,490v-.47c.88-.1,1-.16,1-1.25V485.6c0-1.18-.43-1.81-1.33-1.81a2.7,2.7,0,0,0-1.67.77c0,.19,0,.39,0,.69v3.13c0,1,.13,1.06,1,1.16V490h-3.45v-.47c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.39-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.09,1,1.19V490h-3.48v-.47c1-.1,1.12-.16,1.12-1.2V485c0-1-.06-1.08-.93-1.23v-.42a9.52,9.52,0,0,0,2.33-.64V484a13.82,13.82,0,0,1,1.15-.8,2.26,2.26,0,0,1,1.28-.43,1.85,1.85,0,0,1,1.75,1.3c.44-.34.84-.61,1.24-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.1,1.09,1,1.19V490Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M817.11,487.78c-.22.75-.5,1.62-.66,2.23h-6.11v-.4c.88-.86,1.83-1.8,2.62-2.71,1-1.22,1.93-2.46,1.93-3.83s-.68-2.05-1.83-2.05a2.78,2.78,0,0,0-2.21,1.47l-.38-.35.93-1.33a2.92,2.92,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.83-.67.7-1.66,1.65-2.33,2.28h3c.89,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M818.63,478.26a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.87,9.87,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M782.85,319.83h5v.62c-1.41.16-1.66.3-1.66,2.16v3.62a2.7,2.7,0,0,0,1.34-1c1.07-1.17,2.23-2.56,3-3.6.53-.71.47-1-.33-1.12l-.49-.06v-.62h4.53v.62a3.79,3.79,0,0,0-3.09,1.75l-3,3.44c1.35,1.9,3.28,4.52,4.32,5.84.89,1.14,1.13,1.31,2.23,1.46v.62h-3.06c-1.3-1.72-2.81-3.86-4.42-6.08-.42-.6-.73-.66-1-.63v4c0,1.84.16,2,1.67,2.13v.62h-5v-.62c1.48-.15,1.63-.29,1.63-2.13v-8.2c0-1.88-.13-2-1.63-2.16Z" />
        <path d="M797.47,333.85c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,797.47,333.85Z" />
        <path d="M800.5,322.71l.87-1.34a3.12,3.12,0,0,1,2.42-1.23,2.71,2.71,0,0,1,2.82,2.84,2.4,2.4,0,0,1-.58,1.55,8,8,0,0,1-1.32,1.15,3.31,3.31,0,0,1,2.75,3.24c0,3.43-3.62,4.9-4.9,4.9a2.8,2.8,0,0,1-1.93-.73,1,1,0,0,1-.31-.73,1.37,1.37,0,0,1,.44-.85.35.35,0,0,1,.53,0,3.25,3.25,0,0,0,2.29,1.16c1.26,0,2.15-1.07,2.15-3.11a2.41,2.41,0,0,0-2.4-2.71,3.77,3.77,0,0,0-1.19.22l-.13-.66c1.65-.62,2.9-1.44,2.9-3,0-1.26-.7-2-1.72-2a2.91,2.91,0,0,0-2.29,1.66Z" />
        <path d="M810.12,333.56v-.62c2-.14,2.13-.27,2.13-1.84v-8c0-1.07-.17-1.12-1-1.21l-.84-.11v-.56a14.16,14.16,0,0,0,3.49-1v11c0,1.59.11,1.7,2.14,1.84v.62Z" />
        <path
          d="M774.91,357.52a9.3,9.3,0,0,1,0-14.83l.34.41a9.9,9.9,0,0,0-2.62,7,10.15,10.15,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M776.78,346.21l.76-1a2.86,2.86,0,0,1,2.11-.92,2.22,2.22,0,0,1,2.46,2.14,1.69,1.69,0,0,1-.51,1.18,6.34,6.34,0,0,1-1.15.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.66,2.66,0,0,1-1.68-.55.73.73,0,0,1-.27-.55.91.91,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2.05,3.92,3.92,0,0,0-1,.16l-.11-.49c1.44-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.5-1.49,2.54,2.54,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M787.67,344.27c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.4-2.68-3.4-5.17C784.27,347.11,785.19,344.27,787.67,344.27Zm0,.58c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S789,344.85,787.67,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M795.5,344.27c2.46,0,3.35,2.72,3.35,5.17s-.89,5.18-3.35,5.18-3.4-2.68-3.4-5.17C792.1,347.11,793,344.27,795.5,344.27Zm0,.58c-1.41,0-1.76,2.34-1.76,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S796.85,344.85,795.49,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M808.71,354.43V354c.88-.09,1-.16,1-1.25V350c0-1.17-.43-1.81-1.33-1.81a2.72,2.72,0,0,0-1.67.78c0,.19,0,.39,0,.68v3.13c0,1,.13,1.07,1,1.16v.47h-3.45V354c.94-.09,1.07-.15,1.07-1.19V350c0-1.18-.39-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48V354c1-.1,1.12-.16,1.12-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.19,2.19,0,0,1,1.28-.43,1.86,1.86,0,0,1,1.75,1.29c.44-.34.84-.6,1.24-.88a2.46,2.46,0,0,1,1.27-.41c1.27,0,2,.9,2,2.48v3.09c0,1,.1,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M819.82,352.21c-.22.74-.5,1.61-.66,2.22h-6.11V354c.88-.86,1.83-1.81,2.62-2.72a6.3,6.3,0,0,0,1.93-3.82c0-1.3-.68-2.06-1.83-2.06a2.79,2.79,0,0,0-2.21,1.48l-.38-.35.93-1.34a3,3,0,0,1,2.24-1,2.64,2.64,0,0,1,2.76,2.78c0,1.34-.49,2.29-2,3.84-.67.69-1.66,1.64-2.33,2.27h3c.89,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M821.34,342.69a9.28,9.28,0,0,1,0,14.83l-.35-.41a9.89,9.89,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M718,319.83h5v.62c-1.41.16-1.66.3-1.66,2.16v3.62a2.7,2.7,0,0,0,1.34-1c1.07-1.17,2.23-2.56,3-3.6.53-.71.46-1-.34-1.12l-.48-.06v-.62h4.53v.62a3.79,3.79,0,0,0-3.09,1.75l-3,3.44c1.35,1.9,3.28,4.52,4.32,5.84.89,1.14,1.13,1.31,2.23,1.46v.62h-3.07c-1.29-1.72-2.8-3.86-4.41-6.08-.42-.6-.73-.66-1-.63v4c0,1.84.16,2,1.66,2.13v.62h-5v-.62c1.48-.15,1.63-.29,1.63-2.13v-8.2c0-1.88-.13-2-1.63-2.16Z" />
        <path d="M732.62,333.85c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,732.62,333.85Z" />
        <path d="M735.65,322.71l.87-1.34a3.12,3.12,0,0,1,2.42-1.23,2.71,2.71,0,0,1,2.82,2.84,2.4,2.4,0,0,1-.58,1.55,8,8,0,0,1-1.32,1.15,3.31,3.31,0,0,1,2.75,3.24c0,3.43-3.62,4.9-4.9,4.9a2.8,2.8,0,0,1-1.93-.73,1,1,0,0,1-.31-.73,1.37,1.37,0,0,1,.44-.85.34.34,0,0,1,.52,0,3.27,3.27,0,0,0,2.3,1.16c1.26,0,2.15-1.07,2.15-3.11a2.41,2.41,0,0,0-2.4-2.71,3.77,3.77,0,0,0-1.19.22l-.13-.66c1.65-.62,2.9-1.44,2.9-3a1.72,1.72,0,0,0-1.73-2,2.9,2.9,0,0,0-2.28,1.66Z" />
        <path d="M752,330.63c-.24,1-.56,2.13-.75,2.93h-7v-.51c1-1.14,2.1-2.39,3-3.59,1.2-1.6,2.22-3.25,2.22-5s-.78-2.72-2.11-2.72-2,1.1-2.52,1.95l-.45-.45,1.07-1.77a3.21,3.21,0,0,1,2.57-1.28c1.76,0,3.17,1.39,3.17,3.68,0,1.77-.57,3-2.34,5.07-.77.92-1.91,2.17-2.67,3h3.43c1,0,1.28-.1,1.92-1.44Z" />
        <path
          d="M711.07,357.52a9.28,9.28,0,0,1,0-14.83l.34.41a9.86,9.86,0,0,0-2.63,7,10.11,10.11,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M712.94,346.21l.76-1a2.84,2.84,0,0,1,2.11-.92,2.21,2.21,0,0,1,2.45,2.14,1.72,1.72,0,0,1-.5,1.18,6.45,6.45,0,0,1-1.16.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.66,2.66,0,0,1-1.68-.55.73.73,0,0,1-.27-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16l-.11-.49c1.43-.47,2.52-1.09,2.52-2.26a1.38,1.38,0,0,0-1.5-1.49,2.55,2.55,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M723.82,344.27c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.39-2.68-3.39-5.17C720.43,347.11,721.35,344.27,723.82,344.27Zm0,.58c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S725.18,344.85,723.82,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M731.65,344.27c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.4-2.68-3.4-5.17C728.25,347.11,729.18,344.27,731.65,344.27Zm0,.58c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S733,344.85,731.65,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M744.86,354.43V354c.89-.09,1-.16,1-1.25V350c0-1.17-.43-1.81-1.33-1.81a2.74,2.74,0,0,0-1.67.78,5.62,5.62,0,0,1,0,.68v3.13c0,1,.12,1.07,1,1.16v.47h-3.46V354c.94-.09,1.07-.15,1.07-1.19V350c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47H736V354c1-.1,1.11-.16,1.11-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.21,2.21,0,0,1,1.28-.43,1.88,1.88,0,0,1,1.76,1.29c.43-.34.83-.6,1.23-.88a2.46,2.46,0,0,1,1.27-.41c1.27,0,2,.9,2,2.48v3.09c0,1,.09,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M756,352.21c-.22.74-.49,1.61-.65,2.22h-6.11V354c.87-.86,1.82-1.81,2.61-2.72a6.24,6.24,0,0,0,1.93-3.82c0-1.3-.68-2.06-1.83-2.06a2.76,2.76,0,0,0-2.2,1.48l-.39-.35.93-1.34a3,3,0,0,1,2.24-1,2.64,2.64,0,0,1,2.76,2.78c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.34,2.27h3c.88,0,1.11-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M757.5,342.69a9.3,9.3,0,0,1,0,14.83l-.36-.41a9.89,9.89,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M654.67,319.83h5v.62c-1.41.16-1.66.3-1.66,2.16v3.62a2.75,2.75,0,0,0,1.35-1c1.06-1.17,2.22-2.56,3-3.6.53-.71.47-1-.33-1.12l-.49-.06v-.62h4.53v.62A3.79,3.79,0,0,0,663,322.2l-3,3.44c1.35,1.9,3.29,4.52,4.32,5.84.89,1.14,1.13,1.31,2.24,1.46v.62h-3.07c-1.3-1.72-2.81-3.86-4.42-6.08-.41-.6-.72-.66-1-.63v4c0,1.84.16,2,1.67,2.13v.62h-5v-.62c1.48-.15,1.64-.29,1.64-2.13v-8.2c0-1.88-.14-2-1.64-2.16Z" />
        <path d="M669.29,333.85c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,669.29,333.85Z" />
        <path d="M672.32,322.71l.87-1.34a3.12,3.12,0,0,1,2.42-1.23,2.71,2.71,0,0,1,2.82,2.84,2.4,2.4,0,0,1-.58,1.55,8,8,0,0,1-1.32,1.15,3.31,3.31,0,0,1,2.75,3.24c0,3.43-3.62,4.9-4.9,4.9a2.82,2.82,0,0,1-1.93-.73,1,1,0,0,1-.31-.73,1.37,1.37,0,0,1,.44-.85.35.35,0,0,1,.53,0,3.27,3.27,0,0,0,2.29,1.16c1.26,0,2.15-1.07,2.15-3.11a2.41,2.41,0,0,0-2.4-2.71,3.77,3.77,0,0,0-1.19.22l-.13-.66c1.65-.62,2.9-1.44,2.9-3,0-1.26-.7-2-1.72-2a2.92,2.92,0,0,0-2.29,1.66Z" />
        <path d="M681.3,322.71l.88-1.34a3.12,3.12,0,0,1,2.42-1.23,2.71,2.71,0,0,1,2.82,2.84,2.45,2.45,0,0,1-.58,1.55,8.15,8.15,0,0,1-1.33,1.15,3.32,3.32,0,0,1,2.76,3.24c0,3.43-3.62,4.9-4.9,4.9a2.83,2.83,0,0,1-1.94-.73,1,1,0,0,1-.3-.73,1.36,1.36,0,0,1,.43-.85.35.35,0,0,1,.53,0,3.27,3.27,0,0,0,2.3,1.16c1.25,0,2.15-1.07,2.15-3.11a2.42,2.42,0,0,0-2.4-2.71,3.71,3.71,0,0,0-1.19.22l-.13-.66c1.64-.62,2.9-1.44,2.9-3a1.72,1.72,0,0,0-1.73-2,2.9,2.9,0,0,0-2.28,1.66Z" />
        <path
          d="M647.74,357.52a9.28,9.28,0,0,1,0-14.83l.34.41a9.9,9.9,0,0,0-2.62,7,10.1,10.1,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M649.61,346.21l.76-1a2.84,2.84,0,0,1,2.11-.92,2.21,2.21,0,0,1,2.45,2.14,1.72,1.72,0,0,1-.5,1.18,6.71,6.71,0,0,1-1.15.86,2.66,2.66,0,0,1,2.4,2.46c0,2.6-3.16,3.71-4.27,3.71a2.7,2.7,0,0,1-1.69-.55.72.72,0,0,1-.26-.55.94.94,0,0,1,.37-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16l-.11-.49c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.49,2.57,2.57,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M660.5,344.27c2.45,0,3.35,2.72,3.35,5.17s-.9,5.18-3.35,5.18-3.4-2.68-3.4-5.17C657.1,347.11,658,344.27,660.5,344.27Zm0,.58c-1.42,0-1.76,2.34-1.76,4.56s.42,4.63,1.77,4.63,1.73-2.37,1.73-4.61S661.85,344.85,660.49,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M668.32,344.27c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.39-2.68-3.39-5.17C664.93,347.11,665.85,344.27,668.32,344.27Zm0,.58c-1.42,0-1.77,2.34-1.77,4.56S667,354,668.33,354s1.73-2.37,1.73-4.61S669.68,344.85,668.32,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M681.53,354.43V354c.89-.09,1-.16,1-1.25V350c0-1.17-.43-1.81-1.33-1.81a2.76,2.76,0,0,0-1.67.78,5.62,5.62,0,0,1,.05.68v3.13c0,1,.12,1.07,1,1.16v.47H677.1V354c.94-.09,1.07-.15,1.07-1.19V350c0-1.18-.38-1.78-1.29-1.78a2.84,2.84,0,0,0-1.66.77v3.79c0,1,.11,1.1,1,1.19v.47H672.7V354c1-.1,1.11-.16,1.11-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10.17,10.17,0,0,0,2.34-.63v1.28a9.23,9.23,0,0,1,1.14-.8,2.21,2.21,0,0,1,1.28-.43,1.87,1.87,0,0,1,1.76,1.29c.43-.34.83-.6,1.23-.88a2.49,2.49,0,0,1,1.27-.41c1.28,0,2,.9,2,2.48v3.09c0,1,.09,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M692.64,352.21c-.21.74-.49,1.61-.65,2.22h-6.11V354c.87-.86,1.83-1.81,2.62-2.72a6.28,6.28,0,0,0,1.92-3.82c0-1.3-.67-2.06-1.83-2.06a2.78,2.78,0,0,0-2.2,1.48l-.39-.35.93-1.34a3,3,0,0,1,2.24-1,2.64,2.64,0,0,1,2.76,2.78c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.33,2.27h3c.88,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M694.17,342.69a9.3,9.3,0,0,1,0,14.83l-.36-.41a9.85,9.85,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M592.86,319.83h5v.62c-1.42.16-1.66.3-1.66,2.16v3.62a2.74,2.74,0,0,0,1.34-1c1.07-1.17,2.22-2.56,3-3.6.53-.71.46-1-.34-1.12l-.48-.06v-.62h4.52v.62a3.77,3.77,0,0,0-3.08,1.75l-3,3.44c1.35,1.9,3.28,4.52,4.32,5.84.89,1.14,1.13,1.31,2.23,1.46v.62h-3.07c-1.3-1.72-2.81-3.86-4.41-6.08-.42-.6-.73-.66-1-.63v4c0,1.84.16,2,1.66,2.13v.62h-5v-.62c1.49-.15,1.64-.29,1.64-2.13v-8.2c0-1.88-.14-2-1.64-2.16Z" />
        <path d="M607.49,333.85c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.31,1.31,0,0,1,1.22,1.42A1.29,1.29,0,0,1,607.49,333.85Z" />
        <path d="M610.51,322.71l.88-1.34a3.12,3.12,0,0,1,2.42-1.23,2.71,2.71,0,0,1,2.82,2.84,2.45,2.45,0,0,1-.58,1.55,8.15,8.15,0,0,1-1.33,1.15,3.32,3.32,0,0,1,2.76,3.24c0,3.43-3.62,4.9-4.9,4.9a2.83,2.83,0,0,1-1.94-.73,1,1,0,0,1-.3-.73,1.36,1.36,0,0,1,.43-.85.35.35,0,0,1,.53,0,3.27,3.27,0,0,0,2.3,1.16c1.25,0,2.15-1.07,2.15-3.11a2.42,2.42,0,0,0-2.4-2.71,3.71,3.71,0,0,0-1.19.22l-.13-.66c1.64-.62,2.9-1.44,2.9-3a1.72,1.72,0,0,0-1.73-2,2.9,2.9,0,0,0-2.28,1.66Z" />
        <path d="M625.3,329.75v1.55c0,1.37.08,1.49,1.41,1.64v.62h-4.46v-.62c1.36-.15,1.44-.3,1.44-1.67v-1.52h-4.83v-.68c1.79-2.88,3.73-6,5.52-8.85h.92v8.48h1.79v1.05Zm-1.61-7.19c-1.11,1.85-2.39,4-3.65,6.14h3.65Z" />
        <path
          d="M584.93,357.52a9.3,9.3,0,0,1,0-14.83l.35.41a9.86,9.86,0,0,0-2.63,7,10.11,10.11,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M586.8,346.21l.76-1a2.87,2.87,0,0,1,2.11-.92,2.22,2.22,0,0,1,2.46,2.14,1.72,1.72,0,0,1-.5,1.18,6.84,6.84,0,0,1-1.16.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.66,2.66,0,0,1-1.68-.55.73.73,0,0,1-.27-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.88-.82,1.88-2.36a2,2,0,0,0-2.1-2.05,3.84,3.84,0,0,0-1,.16l-.12-.49c1.44-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.5-1.49,2.54,2.54,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M597.69,344.27c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.4-2.68-3.4-5.17C594.29,347.11,595.21,344.27,597.69,344.27Zm0,.58c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S599,344.85,597.69,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M605.52,344.27c2.46,0,3.35,2.72,3.35,5.17s-.89,5.18-3.35,5.18-3.4-2.68-3.4-5.17C602.12,347.11,603,344.27,605.52,344.27Zm0,.58c-1.41,0-1.76,2.34-1.76,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S606.87,344.85,605.51,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M618.73,354.43V354c.89-.09,1-.16,1-1.25V350c0-1.17-.43-1.81-1.33-1.81a2.74,2.74,0,0,0-1.67.78c0,.19,0,.39,0,.68v3.13c0,1,.13,1.07,1,1.16v.47H614.3V354c.94-.09,1.07-.15,1.07-1.19V350c0-1.18-.39-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.49V354c1-.1,1.12-.16,1.12-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.19,2.19,0,0,1,1.28-.43,1.86,1.86,0,0,1,1.75,1.29c.44-.34.84-.6,1.24-.88a2.46,2.46,0,0,1,1.27-.41c1.27,0,2,.9,2,2.48v3.09c0,1,.1,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M629.84,352.21c-.22.74-.5,1.61-.66,2.22h-6.11V354c.88-.86,1.83-1.81,2.62-2.72a6.3,6.3,0,0,0,1.93-3.82c0-1.3-.68-2.06-1.83-2.06a2.79,2.79,0,0,0-2.21,1.48l-.38-.35.93-1.34a3,3,0,0,1,2.24-1,2.64,2.64,0,0,1,2.76,2.78c0,1.34-.49,2.29-2,3.84-.67.69-1.66,1.64-2.33,2.27h3c.89,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M631.37,342.69a9.3,9.3,0,0,1,0,14.83l-.36-.41a9.89,9.89,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M528,319.83h5v.62c-1.42.16-1.66.3-1.66,2.16v3.62a2.74,2.74,0,0,0,1.34-1c1.07-1.17,2.22-2.56,3-3.6.53-.71.47-1-.33-1.12l-.48-.06v-.62h4.52v.62a3.79,3.79,0,0,0-3.09,1.75c-.58.66-1.92,2.17-3,3.44,1.35,1.9,3.28,4.52,4.32,5.84.89,1.14,1.13,1.31,2.23,1.46v.62h-3.07c-1.3-1.72-2.81-3.86-4.42-6.08-.41-.6-.72-.66-1-.63v4c0,1.84.15,2,1.66,2.13v.62h-5v-.62c1.49-.15,1.64-.29,1.64-2.13v-8.2c0-1.88-.14-2-1.64-2.16Z" />
        <path d="M542.66,333.85c-.69,0-1.18-.58-1.18-1.43a1.28,1.28,0,0,1,1.18-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,542.66,333.85Z" />
        <path d="M545.69,322.71l.87-1.34a3.14,3.14,0,0,1,2.43-1.23A2.71,2.71,0,0,1,551.8,323a2.45,2.45,0,0,1-.57,1.55,8.6,8.6,0,0,1-1.33,1.15,3.32,3.32,0,0,1,2.76,3.24c0,3.43-3.62,4.9-4.91,4.9a2.82,2.82,0,0,1-1.93-.73,1,1,0,0,1-.3-.73,1.31,1.31,0,0,1,.43-.85.35.35,0,0,1,.53,0,3.27,3.27,0,0,0,2.3,1.16c1.25,0,2.15-1.07,2.15-3.11a2.42,2.42,0,0,0-2.4-2.71,3.83,3.83,0,0,0-1.2.22l-.12-.66c1.64-.62,2.9-1.44,2.9-3a1.72,1.72,0,0,0-1.73-2,2.9,2.9,0,0,0-2.28,1.66Z" />
        <path d="M561.28,320.4l.11.17-.5,1.53H556.6c-.16,1.11-.4,2.71-.57,3.72a7.78,7.78,0,0,1,2.22-.62,3.44,3.44,0,0,1,3.48,3.58,4.69,4.69,0,0,1-1.62,3.57,5.35,5.35,0,0,1-3.3,1.47,2.64,2.64,0,0,1-1.92-.8.83.83,0,0,1-.32-.75,1.24,1.24,0,0,1,.42-.79.38.38,0,0,1,.55,0,3.15,3.15,0,0,0,2.33,1.19c1.47,0,2.17-1.46,2.17-3,0-1.87-1-3.19-3-3.19a5.11,5.11,0,0,0-1.85.38l-.06-.09c.29-1.7.7-4.63.92-6.37Z" />
        <path
          d="M521.11,357.52a9.28,9.28,0,0,1,0-14.83l.34.41a9.9,9.9,0,0,0-2.62,7,10.1,10.1,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M523,346.21l.76-1a2.84,2.84,0,0,1,2.11-.92,2.21,2.21,0,0,1,2.45,2.14,1.72,1.72,0,0,1-.5,1.18,6.45,6.45,0,0,1-1.16.86c1.52.33,2.41,1.29,2.41,2.46,0,2.6-3.16,3.71-4.27,3.71a2.67,2.67,0,0,1-1.69-.55.73.73,0,0,1-.27-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16l-.11-.49c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.49,2.57,2.57,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M533.87,344.27c2.45,0,3.35,2.72,3.35,5.17s-.9,5.18-3.35,5.18-3.4-2.68-3.4-5.17C530.47,347.11,531.39,344.27,533.87,344.27Zm0,.58c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S535.22,344.85,533.86,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M541.69,344.27c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.39-2.68-3.39-5.17C538.3,347.11,539.22,344.27,541.69,344.27Zm0,.58c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S543.05,344.85,541.69,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M554.9,354.43V354c.89-.09,1-.16,1-1.25V350c0-1.17-.43-1.81-1.33-1.81a2.74,2.74,0,0,0-1.67.78,5.62,5.62,0,0,1,.05.68v3.13c0,1,.12,1.07,1,1.16v.47h-3.46V354c.94-.09,1.07-.15,1.07-1.19V350c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48V354c1-.1,1.11-.16,1.11-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.21,2.21,0,0,1,1.28-.43,1.88,1.88,0,0,1,1.76,1.29c.43-.34.83-.6,1.23-.88a2.46,2.46,0,0,1,1.27-.41c1.27,0,2,.9,2,2.48v3.09c0,1,.09,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M566,352.21c-.21.74-.49,1.61-.65,2.22h-6.11V354c.87-.86,1.83-1.81,2.62-2.72a6.28,6.28,0,0,0,1.92-3.82c0-1.3-.67-2.06-1.83-2.06a2.76,2.76,0,0,0-2.2,1.48l-.39-.35.93-1.34a3,3,0,0,1,2.24-1,2.64,2.64,0,0,1,2.76,2.78c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.33,2.27h3c.88,0,1.11-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M567.54,342.69a9.3,9.3,0,0,1,0,14.83l-.36-.41a9.89,9.89,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M466.19,319.83h5v.62c-1.41.16-1.66.3-1.66,2.16v3.62a2.75,2.75,0,0,0,1.35-1c1.06-1.17,2.22-2.56,3-3.6.53-.71.47-1-.33-1.12l-.49-.06v-.62h4.53v.62a3.79,3.79,0,0,0-3.09,1.75l-3,3.44c1.36,1.9,3.29,4.52,4.33,5.84.89,1.14,1.12,1.31,2.23,1.46v.62H475c-1.3-1.72-2.81-3.86-4.42-6.08-.41-.6-.72-.66-1-.63v4c0,1.84.16,2,1.67,2.13v.62h-5v-.62c1.48-.15,1.64-.29,1.64-2.13v-8.2c0-1.88-.14-2-1.64-2.16Z" />
        <path d="M480.81,333.85c-.7,0-1.18-.58-1.18-1.43a1.28,1.28,0,0,1,1.18-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,480.81,333.85Z" />
        <path d="M483.84,322.71l.87-1.34a3.12,3.12,0,0,1,2.43-1.23A2.71,2.71,0,0,1,490,323a2.4,2.4,0,0,1-.58,1.55,8,8,0,0,1-1.32,1.15,3.31,3.31,0,0,1,2.75,3.24c0,3.43-3.62,4.9-4.9,4.9a2.82,2.82,0,0,1-1.93-.73,1,1,0,0,1-.31-.73,1.37,1.37,0,0,1,.44-.85.35.35,0,0,1,.53,0,3.27,3.27,0,0,0,2.3,1.16c1.25,0,2.15-1.07,2.15-3.11a2.42,2.42,0,0,0-2.41-2.71,3.77,3.77,0,0,0-1.19.22l-.13-.66c1.65-.62,2.91-1.44,2.91-3a1.72,1.72,0,0,0-1.73-2,2.9,2.9,0,0,0-2.28,1.66Z" />
        <path d="M495.73,325.57a2.35,2.35,0,0,1,1.18-.37c2,0,3.31,1.72,3.31,3.92,0,2.56-1.61,4.7-3.86,4.7s-3.89-2.05-3.89-5.31a8.5,8.5,0,0,1,5.07-7.92,14,14,0,0,1,2.2-.57l.11.72a9.86,9.86,0,0,0-2,.73,6.47,6.47,0,0,0-3.3,4.78Zm.38.89a3.28,3.28,0,0,0-1.68.53,9.08,9.08,0,0,0-.11,1.49c0,2.67.85,4.55,2.35,4.55s1.79-1.72,1.79-3.12C498.46,328.12,497.7,326.46,496.11,326.46Z" />
        <path
          d="M458.26,357.52a9.28,9.28,0,0,1,0-14.83l.34.41a9.9,9.9,0,0,0-2.62,7,10.1,10.1,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M460.13,346.21l.76-1a2.84,2.84,0,0,1,2.11-.92,2.21,2.21,0,0,1,2.45,2.14,1.72,1.72,0,0,1-.5,1.18,6.45,6.45,0,0,1-1.16.86c1.52.33,2.41,1.29,2.41,2.46,0,2.6-3.16,3.71-4.27,3.71a2.7,2.7,0,0,1-1.69-.55.73.73,0,0,1-.27-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16l-.11-.49c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.49,2.57,2.57,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M471,344.27c2.45,0,3.35,2.72,3.35,5.17s-.9,5.18-3.35,5.18-3.4-2.68-3.4-5.17C467.62,347.11,468.54,344.27,471,344.27Zm0,.58c-1.42,0-1.76,2.34-1.76,4.56S469.67,354,471,354s1.73-2.37,1.73-4.61S472.37,344.85,471,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M478.84,344.27c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.39-2.68-3.39-5.17C475.45,347.11,476.37,344.27,478.84,344.27Zm0,.58c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S480.2,344.85,478.84,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M492.05,354.43V354c.89-.09,1-.16,1-1.25V350c0-1.17-.43-1.81-1.33-1.81a2.76,2.76,0,0,0-1.67.78,5.62,5.62,0,0,1,.05.68v3.13c0,1,.12,1.07,1,1.16v.47h-3.46V354c.94-.09,1.07-.15,1.07-1.19V350c0-1.18-.38-1.78-1.29-1.78a2.84,2.84,0,0,0-1.66.77v3.79c0,1,.1,1.1,1,1.19v.47h-3.48V354c1-.1,1.11-.16,1.11-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10.17,10.17,0,0,0,2.34-.63v1.28a9.23,9.23,0,0,1,1.14-.8,2.21,2.21,0,0,1,1.28-.43,1.87,1.87,0,0,1,1.76,1.29c.43-.34.83-.6,1.23-.88a2.46,2.46,0,0,1,1.27-.41c1.28,0,2,.9,2,2.48v3.09c0,1,.09,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M503.16,352.21c-.21.74-.49,1.61-.65,2.22H496.4V354c.87-.86,1.83-1.81,2.62-2.72a6.28,6.28,0,0,0,1.92-3.82c0-1.3-.67-2.06-1.83-2.06a2.76,2.76,0,0,0-2.2,1.48l-.39-.35.93-1.34a3,3,0,0,1,2.24-1,2.64,2.64,0,0,1,2.76,2.78c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.33,2.27h3c.88,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M504.69,342.69a9.3,9.3,0,0,1,0,14.83l-.36-.41a9.85,9.85,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M402.91,319.83h5v.62c-1.41.16-1.66.3-1.66,2.16v3.62a2.75,2.75,0,0,0,1.35-1c1.06-1.17,2.22-2.56,3-3.6.53-.71.47-1-.33-1.12l-.49-.06v-.62h4.53v.62a3.79,3.79,0,0,0-3.09,1.75l-3,3.44c1.36,1.9,3.29,4.52,4.32,5.84.89,1.14,1.13,1.31,2.24,1.46v.62h-3.07c-1.3-1.72-2.81-3.86-4.42-6.08-.41-.6-.72-.66-1-.63v4c0,1.84.16,2,1.67,2.13v.62h-5v-.62c1.48-.15,1.64-.29,1.64-2.13v-8.2c0-1.88-.14-2-1.64-2.16Z" />
        <path d="M417.53,333.85c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,417.53,333.85Z" />
        <path d="M420.56,322.71l.87-1.34a3.12,3.12,0,0,1,2.42-1.23,2.71,2.71,0,0,1,2.82,2.84,2.4,2.4,0,0,1-.58,1.55,8,8,0,0,1-1.32,1.15,3.31,3.31,0,0,1,2.75,3.24c0,3.43-3.62,4.9-4.9,4.9a2.82,2.82,0,0,1-1.93-.73,1,1,0,0,1-.31-.73,1.37,1.37,0,0,1,.44-.85.35.35,0,0,1,.53,0,3.27,3.27,0,0,0,2.29,1.16c1.26,0,2.15-1.07,2.15-3.11a2.41,2.41,0,0,0-2.4-2.71,3.77,3.77,0,0,0-1.19.22l-.13-.66c1.65-.62,2.9-1.44,2.9-3,0-1.26-.7-2-1.72-2a2.92,2.92,0,0,0-2.29,1.66Z" />
        <path d="M437.09,320.68c-1.77,4.28-3.5,8.56-5.26,12.92l-1.41.17-.13-.24c1.93-3.58,3.92-7.86,5.39-11.43h-3.92c-1.2,0-1.29.18-1.79,1.55h-.59c.2-1.28.28-2.34.38-3.25H437Z" />
        <path
          d="M395.13,357.52a9.3,9.3,0,0,1,0-14.83l.34.41a9.9,9.9,0,0,0-2.62,7,10.15,10.15,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M397,346.21l.76-1a2.84,2.84,0,0,1,2.11-.92,2.22,2.22,0,0,1,2.46,2.14,1.69,1.69,0,0,1-.51,1.18,6.34,6.34,0,0,1-1.15.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.72,2.72,0,0,1-1.69-.55.72.72,0,0,1-.26-.55.91.91,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16l-.11-.49c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.49,2.57,2.57,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M407.89,344.27c2.46,0,3.35,2.72,3.35,5.17s-.89,5.18-3.35,5.18-3.4-2.68-3.4-5.17C404.49,347.11,405.41,344.27,407.89,344.27Zm0,.58c-1.41,0-1.76,2.34-1.76,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S409.24,344.85,407.88,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M415.72,344.27c2.45,0,3.35,2.72,3.35,5.17s-.9,5.18-3.35,5.18-3.4-2.68-3.4-5.17C412.32,347.11,413.24,344.27,415.72,344.27Zm0,.58c-1.42,0-1.76,2.34-1.76,4.56s.42,4.63,1.77,4.63,1.74-2.37,1.74-4.61S417.07,344.85,415.71,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M428.92,354.43V354c.89-.09,1-.16,1-1.25V350c0-1.17-.42-1.81-1.33-1.81a2.76,2.76,0,0,0-1.67.78,5.62,5.62,0,0,1,.05.68v3.13c0,1,.13,1.07,1,1.16v.47h-3.46V354c.94-.09,1.07-.15,1.07-1.19V350c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.66.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48V354c1-.1,1.11-.16,1.11-1.2v-3.33c0-1-.05-1.08-.92-1.23v-.42a10.09,10.09,0,0,0,2.33-.63v1.28a9.41,9.41,0,0,1,1.15-.8,2.17,2.17,0,0,1,1.28-.43,1.87,1.87,0,0,1,1.75,1.29c.44-.34.84-.6,1.24-.88a2.45,2.45,0,0,1,1.26-.41c1.28,0,2,.9,2,2.48v3.09c0,1,.09,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M440,352.21c-.22.74-.5,1.61-.66,2.22h-6.11V354c.87-.86,1.83-1.81,2.62-2.72a6.3,6.3,0,0,0,1.93-3.82c0-1.3-.68-2.06-1.84-2.06a2.79,2.79,0,0,0-2.2,1.48l-.39-.35.94-1.34a2.94,2.94,0,0,1,2.23-1,2.65,2.65,0,0,1,2.77,2.78c0,1.34-.5,2.29-2,3.84-.67.69-1.66,1.64-2.33,2.27h3c.89,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M441.56,342.69a9.28,9.28,0,0,1,0,14.83l-.35-.41a9.89,9.89,0,0,0,2.63-7,10.05,10.05,0,0,0-2.63-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M339.06,319.83h5v.62c-1.41.16-1.66.3-1.66,2.16v3.62a2.7,2.7,0,0,0,1.34-1c1.07-1.17,2.23-2.56,3-3.6.53-.71.47-1-.33-1.12l-.49-.06v-.62h4.53v.62a3.79,3.79,0,0,0-3.09,1.75l-3,3.44c1.35,1.9,3.28,4.52,4.32,5.84.89,1.14,1.13,1.31,2.23,1.46v.62h-3.06c-1.3-1.72-2.81-3.86-4.42-6.08-.42-.6-.73-.66-1-.63v4c0,1.84.16,2,1.66,2.13v.62h-5v-.62c1.48-.15,1.63-.29,1.63-2.13v-8.2c0-1.88-.13-2-1.63-2.16Z" />
        <path d="M353.68,333.85c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,353.68,333.85Z" />
        <path d="M356.71,322.71l.87-1.34a3.12,3.12,0,0,1,2.42-1.23,2.71,2.71,0,0,1,2.82,2.84,2.4,2.4,0,0,1-.58,1.55,8,8,0,0,1-1.32,1.15,3.31,3.31,0,0,1,2.75,3.24c0,3.43-3.62,4.9-4.9,4.9a2.8,2.8,0,0,1-1.93-.73,1,1,0,0,1-.31-.73,1.37,1.37,0,0,1,.44-.85.35.35,0,0,1,.53,0,3.25,3.25,0,0,0,2.29,1.16c1.26,0,2.15-1.07,2.15-3.11a2.41,2.41,0,0,0-2.4-2.71,3.77,3.77,0,0,0-1.19.22l-.13-.66c1.65-.62,2.9-1.44,2.9-3,0-1.26-.7-2-1.72-2a2.91,2.91,0,0,0-2.29,1.66Z" />
        <path d="M369.35,320.14a3,3,0,0,1,3.06,3.16,2.71,2.71,0,0,1-.53,1.7,16.9,16.9,0,0,1-1.49,1.3c1.27.8,2.5,1.82,2.5,3.65a3.77,3.77,0,0,1-3.77,3.87,3.39,3.39,0,0,1-3.63-3.51,2.63,2.63,0,0,1,.84-2.06A18.64,18.64,0,0,1,368,327a3.89,3.89,0,0,1-2-3.31A3.39,3.39,0,0,1,369.35,320.14Zm0,12.93c1.19,0,1.92-.9,1.92-2.41s-1.17-2.46-2.72-3.33a3.29,3.29,0,0,0-1.44,2.82C367.11,332.1,368.17,333.07,369.35,333.07Zm-.25-12.19c-1.06,0-1.62.95-1.62,2.11,0,1.52.9,2.11,2.35,3a3.15,3.15,0,0,0,1.11-2.55C370.94,321.61,370,320.88,369.1,320.88Z" />
        <path
          d="M333.28,357.52a9.28,9.28,0,0,1,0-14.83l.34.41a9.9,9.9,0,0,0-2.62,7,10.1,10.1,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M335.15,346.21l.76-1a2.84,2.84,0,0,1,2.11-.92,2.22,2.22,0,0,1,2.46,2.14,1.73,1.73,0,0,1-.51,1.18,6.71,6.71,0,0,1-1.15.86,2.66,2.66,0,0,1,2.4,2.46c0,2.6-3.16,3.71-4.27,3.71a2.7,2.7,0,0,1-1.69-.55.72.72,0,0,1-.26-.55.91.91,0,0,1,.38-.64.33.33,0,0,1,.45,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16l-.11-.49c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.49,2.57,2.57,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M346,344.27c2.46,0,3.35,2.72,3.35,5.17s-.89,5.18-3.35,5.18-3.4-2.68-3.4-5.17C342.64,347.11,343.56,344.27,346,344.27Zm0,.58c-1.42,0-1.76,2.34-1.76,4.56S344.69,354,346,354s1.74-2.37,1.74-4.61S347.39,344.85,346,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M353.87,344.27c2.45,0,3.35,2.72,3.35,5.17s-.9,5.18-3.35,5.18-3.4-2.68-3.4-5.17C350.47,347.11,351.39,344.27,353.87,344.27Zm0,.58c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S355.22,344.85,353.86,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M367.07,354.43V354c.89-.09,1-.16,1-1.25V350c0-1.17-.42-1.81-1.33-1.81a2.76,2.76,0,0,0-1.67.78,5.62,5.62,0,0,1,.05.68v3.13c0,1,.13,1.07,1,1.16v.47h-3.46V354c.94-.09,1.07-.15,1.07-1.19V350c0-1.18-.38-1.78-1.29-1.78a2.86,2.86,0,0,0-1.66.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48V354c1-.1,1.11-.16,1.11-1.2v-3.33c0-1-.05-1.08-.92-1.23v-.42a10.23,10.23,0,0,0,2.33-.63v1.28a10.06,10.06,0,0,1,1.14-.8,2.22,2.22,0,0,1,1.29-.43,1.87,1.87,0,0,1,1.75,1.29c.44-.34.83-.6,1.23-.88a2.49,2.49,0,0,1,1.27-.41c1.28,0,2,.9,2,2.48v3.09c0,1,.09,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M378.18,352.21c-.21.74-.49,1.61-.65,2.22h-6.11V354c.87-.86,1.83-1.81,2.62-2.72A6.3,6.3,0,0,0,376,347.5c0-1.3-.68-2.06-1.84-2.06a2.78,2.78,0,0,0-2.2,1.48l-.39-.35.93-1.34a3,3,0,0,1,2.24-1,2.64,2.64,0,0,1,2.76,2.78c0,1.34-.49,2.29-2,3.84-.68.69-1.66,1.64-2.33,2.27h3c.89,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M379.71,342.69a9.28,9.28,0,0,1,0,14.83l-.35-.41a9.89,9.89,0,0,0,2.63-7,10.05,10.05,0,0,0-2.63-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M274.64,319.83h5v.62c-1.42.16-1.66.3-1.66,2.16v3.62a2.74,2.74,0,0,0,1.34-1c1.07-1.17,2.22-2.56,3-3.6.53-.71.46-1-.34-1.12l-.48-.06v-.62h4.52v.62A3.8,3.8,0,0,0,283,322.2l-3,3.44c1.35,1.9,3.28,4.52,4.32,5.84.89,1.14,1.13,1.31,2.23,1.46v.62h-3.07c-1.3-1.72-2.81-3.86-4.41-6.08-.42-.6-.73-.66-1-.63v4c0,1.84.16,2,1.66,2.13v.62h-5v-.62c1.49-.15,1.64-.29,1.64-2.13v-8.2c0-1.88-.14-2-1.64-2.16Z" />
        <path d="M289.26,333.85c-.69,0-1.18-.58-1.18-1.43a1.28,1.28,0,0,1,1.18-1.42,1.31,1.31,0,0,1,1.23,1.42A1.29,1.29,0,0,1,289.26,333.85Z" />
        <path d="M292.29,322.71l.88-1.34a3.12,3.12,0,0,1,2.42-1.23,2.71,2.71,0,0,1,2.82,2.84,2.45,2.45,0,0,1-.58,1.55,8.15,8.15,0,0,1-1.33,1.15,3.32,3.32,0,0,1,2.76,3.24c0,3.43-3.62,4.9-4.9,4.9a2.83,2.83,0,0,1-1.94-.73,1,1,0,0,1-.3-.73,1.36,1.36,0,0,1,.43-.85.35.35,0,0,1,.53,0,3.27,3.27,0,0,0,2.3,1.16c1.25,0,2.15-1.07,2.15-3.11a2.42,2.42,0,0,0-2.4-2.71,3.71,3.71,0,0,0-1.19.22l-.13-.66c1.64-.62,2.9-1.44,2.9-3a1.72,1.72,0,0,0-1.73-2,2.9,2.9,0,0,0-2.28,1.66Z" />
        <path d="M306.57,327.5l-1.18.69a2.14,2.14,0,0,1-1.09.32c-2,0-3.36-1.75-3.36-4,0-2,1.38-4.41,3.86-4.41s3.76,2.43,3.76,5.3a8.43,8.43,0,0,1-4.68,7.87,8.05,8.05,0,0,1-2.28.57l-.15-.65C304.11,332.63,306,330.65,306.57,327.5Zm-2-6.59c-1.36,0-1.84,1.47-1.84,3,0,1.86.89,3.4,2.4,3.4a2.4,2.4,0,0,0,1.54-.56,11.72,11.72,0,0,0,.08-1.4C306.73,323.44,306.26,320.91,304.55,320.91Z" />
        <path
          d="M268.86,357.52a9.31,9.31,0,0,1,0-14.84l.35.42a9.86,9.86,0,0,0-2.63,7,10.12,10.12,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M270.74,346.21l.76-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.45,2.15,1.72,1.72,0,0,1-.5,1.18,6.84,6.84,0,0,1-1.16.86,2.64,2.64,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.6,2.6,0,0,1-1.68-.56.72.72,0,0,1-.27-.55,1,1,0,0,1,.38-.64.35.35,0,0,1,.46,0,3,3,0,0,0,2,.89c1.1,0,1.88-.82,1.88-2.36a2,2,0,0,0-2.1-2.05,3.84,3.84,0,0,0-1,.16l-.12-.5c1.44-.47,2.53-1.09,2.53-2.25a1.38,1.38,0,0,0-1.5-1.49,2.53,2.53,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M281.62,344.26c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.68-3.39-5.17C278.23,347.11,279.15,344.26,281.62,344.26Zm0,.59c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S283,344.85,281.62,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M289.45,344.26c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.4-2.68-3.4-5.17C286.05,347.11,287,344.26,289.45,344.26Zm0,.59c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S290.8,344.85,289.45,344.85Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M302.66,354.43V354c.89-.09,1-.16,1-1.25V350c0-1.17-.43-1.81-1.33-1.81a2.72,2.72,0,0,0-1.67.77c0,.2,0,.4,0,.69v3.13c0,1,.13,1.07,1,1.16v.47h-3.46V354c.94-.09,1.07-.16,1.07-1.19V350c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48V354c1-.1,1.11-.16,1.11-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a11.71,11.71,0,0,1,1.15-.81,2.26,2.26,0,0,1,1.28-.43,1.87,1.87,0,0,1,1.76,1.3c.43-.34.83-.6,1.23-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M313.77,352.21c-.22.74-.49,1.61-.65,2.22H307V354c.88-.85,1.83-1.8,2.62-2.71a6.24,6.24,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.83-2.05a2.77,2.77,0,0,0-2.2,1.47l-.39-.34.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.34,2.27h3c.89,0,1.12-.08,1.68-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M315.3,342.68a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.85,9.85,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M197.43,319.83h5v.62c-1.41.16-1.66.3-1.66,2.16v3.62a2.7,2.7,0,0,0,1.34-1c1.07-1.17,2.23-2.56,3-3.6.53-.71.46-1-.34-1.12l-.48-.06v-.62h4.53v.62a3.79,3.79,0,0,0-3.09,1.75l-3,3.44c1.35,1.9,3.28,4.52,4.32,5.84.89,1.14,1.13,1.31,2.23,1.46v.62h-3.07c-1.29-1.72-2.8-3.86-4.41-6.08-.42-.6-.73-.66-1-.63v4c0,1.84.16,2,1.66,2.13v.62h-5v-.62c1.48-.15,1.63-.29,1.63-2.13v-8.2c0-1.88-.14-2-1.63-2.16Z" />
        <path d="M212.05,333.85c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,212.05,333.85Z" />
        <path d="M220.88,329.75v1.55c0,1.37.08,1.49,1.4,1.64v.62h-4.46v-.62c1.36-.15,1.45-.3,1.45-1.67v-1.52h-4.83v-.68c1.78-2.88,3.73-6,5.52-8.85h.92v8.48h1.79v1.05Zm-1.61-7.19c-1.11,1.85-2.39,4-3.65,6.14h3.65Z" />
        <path d="M227.57,320.14c2.83,0,3.85,3.6,3.85,6.83s-1,6.85-3.85,6.85-3.9-3.54-3.9-6.83C223.67,323.9,224.73,320.14,227.57,320.14Zm0,.77c-1.63,0-2,3.1-2,6s.49,6.11,2,6.11,2-3.13,2-6.09S229.13,320.91,227.57,320.91Z" />
        <path
          d="M191.65,357.52a9.29,9.29,0,0,1,0-14.84l.34.42a9.9,9.9,0,0,0-2.62,7,10.12,10.12,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M193.52,346.21l.76-1a2.84,2.84,0,0,1,2.11-.93,2.21,2.21,0,0,1,2.45,2.15,1.72,1.72,0,0,1-.5,1.18,6.71,6.71,0,0,1-1.15.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.16,3.71-4.27,3.71a2.63,2.63,0,0,1-1.69-.56.7.7,0,0,1-.26-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.45,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16l-.11-.5c1.43-.47,2.53-1.09,2.53-2.25a1.39,1.39,0,0,0-1.51-1.49,2.54,2.54,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M204.53,344.26a2.48,2.48,0,0,1,2.67,2.39,1.93,1.93,0,0,1-.46,1.29,13.59,13.59,0,0,1-1.3,1c1.11.61,2.18,1.37,2.18,2.76a3.1,3.1,0,0,1-3.29,2.93c-1.84,0-3.16-1-3.16-2.65a1.92,1.92,0,0,1,.73-1.57c.27-.22.9-.63,1.44-1a3,3,0,0,1-1.77-2.51A2.76,2.76,0,0,1,204.53,344.26Zm0,9.79a1.61,1.61,0,0,0,1.67-1.82c0-1.25-1-1.86-2.36-2.52a2.4,2.4,0,0,0-1.26,2.13A2,2,0,0,0,204.53,354.05Zm-.21-9.23a1.42,1.42,0,0,0-1.41,1.6c0,1.15.78,1.6,2,2.24a2.25,2.25,0,0,0,1-1.93C205.92,345.37,205.12,344.82,204.32,344.82Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M214.93,344.46l.09.13-.43,1.15h-3.74c-.14.85-.35,2.06-.5,2.82a7.89,7.89,0,0,1,1.94-.47,2.8,2.8,0,0,1,3,2.72,3.37,3.37,0,0,1-1.42,2.7,5,5,0,0,1-2.87,1.11,2.46,2.46,0,0,1-1.67-.61c-.24-.21-.3-.35-.28-.56a.89.89,0,0,1,.37-.6.37.37,0,0,1,.48,0,2.87,2.87,0,0,0,2,.9c1.28,0,1.89-1.1,1.89-2.3a2.3,2.3,0,0,0-2.59-2.41,4.88,4.88,0,0,0-1.61.29l-.05-.07c.25-1.29.61-3.51.79-4.82Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M225.44,354.43V354c.89-.09,1-.16,1-1.25V350c0-1.17-.43-1.81-1.33-1.81a2.74,2.74,0,0,0-1.67.77,6,6,0,0,1,.05.69v3.13c0,1,.12,1.07,1,1.16v.47H221V354c.94-.09,1.07-.16,1.07-1.19V350c0-1.18-.38-1.78-1.29-1.78a2.84,2.84,0,0,0-1.66.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48V354c1-.1,1.11-.16,1.11-1.2v-3.33c0-1-.05-1.08-.93-1.23v-.42a10.17,10.17,0,0,0,2.34-.63v1.28a10.4,10.4,0,0,1,1.14-.81,2.29,2.29,0,0,1,1.28-.43,1.86,1.86,0,0,1,1.76,1.3c.44-.34.83-.6,1.23-.88a2.5,2.5,0,0,1,1.27-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M236.55,352.21c-.21.74-.49,1.61-.65,2.22h-6.11V354c.87-.85,1.83-1.8,2.62-2.71a6.28,6.28,0,0,0,1.92-3.83c0-1.29-.67-2.05-1.83-2.05a2.79,2.79,0,0,0-2.2,1.47l-.39-.34.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.33,2.27h3c.88,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M238.08,342.68a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.8,9.8,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M782.85,238h5v.62c-1.41.16-1.66.3-1.66,2.16v3.62a2.7,2.7,0,0,0,1.34-1c1.07-1.17,2.23-2.55,3-3.6.53-.71.47-1-.33-1.12l-.49-.06V238h4.53v.62a3.79,3.79,0,0,0-3.09,1.75l-3,3.44c1.35,1.9,3.29,4.52,4.32,5.85.89,1.13,1.13,1.3,2.23,1.45v.62h-3.06c-1.3-1.72-2.81-3.86-4.42-6.08-.41-.6-.72-.66-1-.63v4c0,1.83.16,2,1.67,2.12v.62h-5v-.62c1.48-.15,1.64-.29,1.64-2.12v-8.21c0-1.88-.14-2-1.64-2.16Z" />
        <path d="M797.47,252c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,797.47,252Z" />
        <path d="M800.5,240.85l.87-1.35a3.12,3.12,0,0,1,2.42-1.23,2.71,2.71,0,0,1,2.82,2.84,2.4,2.4,0,0,1-.58,1.55,8,8,0,0,1-1.32,1.15,3.31,3.31,0,0,1,2.75,3.24c0,3.43-3.62,4.9-4.9,4.9a2.82,2.82,0,0,1-1.93-.73,1,1,0,0,1-.31-.73,1.37,1.37,0,0,1,.44-.85.35.35,0,0,1,.53,0,3.27,3.27,0,0,0,2.29,1.16c1.26,0,2.15-1.07,2.15-3.11a2.41,2.41,0,0,0-2.4-2.71,4.07,4.07,0,0,0-1.19.22l-.13-.66c1.65-.62,2.9-1.43,2.9-3,0-1.26-.7-2-1.72-2a2.91,2.91,0,0,0-2.29,1.66Z" />
        <path d="M813,238.27c2.82,0,3.85,3.6,3.85,6.83s-1,6.85-3.85,6.85-3.9-3.54-3.9-6.83C809.1,242,810.15,238.27,813,238.27Zm0,.78c-1.63,0-2,3.09-2,6s.5,6.11,2,6.11,2-3.13,2-6.09S814.55,239.05,813,239.05Z" />
        <path
          d="M774.91,275.65a9.31,9.31,0,0,1,0-14.84l.35.42a9.86,9.86,0,0,0-2.63,7,10.11,10.11,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M776.78,264.34l.76-1a2.89,2.89,0,0,1,2.12-.93,2.22,2.22,0,0,1,2.45,2.15,1.72,1.72,0,0,1-.5,1.18,6.84,6.84,0,0,1-1.16.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.66,2.66,0,0,1-1.68-.55.75.75,0,0,1-.27-.56A1,1,0,0,1,777,271a.35.35,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.88-.82,1.88-2.36a2,2,0,0,0-2.1-2.05,3.84,3.84,0,0,0-1,.16l-.12-.49c1.44-.48,2.53-1.1,2.53-2.26a1.39,1.39,0,0,0-1.5-1.49,2.51,2.51,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M787.67,262.39c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.4-2.68-3.4-5.17C784.27,265.24,785.2,262.39,787.67,262.39Zm0,.59c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S789,263,787.67,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M795.5,262.39c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17C792.1,265.24,793,262.39,795.5,262.39Zm0,.59c-1.41,0-1.76,2.34-1.76,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S796.85,263,795.49,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M808.71,272.56v-.47c.89-.09,1-.16,1-1.25v-2.69c0-1.17-.43-1.81-1.33-1.81a2.78,2.78,0,0,0-1.67.77c0,.2,0,.4,0,.69v3.13c0,1,.13,1.07,1,1.16v.47h-3.45v-.47c.94-.09,1.07-.15,1.07-1.19v-2.78c0-1.18-.39-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.49v-.47c1-.1,1.12-.16,1.12-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.2,2.2,0,0,1,1.28-.44,1.87,1.87,0,0,1,1.75,1.3c.44-.34.84-.6,1.24-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.1,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M819.82,270.34c-.22.74-.49,1.61-.65,2.22h-6.12v-.39c.88-.86,1.83-1.81,2.62-2.72a6.3,6.3,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.83-2.05a2.81,2.81,0,0,0-2.21,1.47l-.38-.34.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.34,2.27h3c.89,0,1.12-.08,1.68-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M821.35,260.81a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.85,9.85,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M721,238h5v.62c-1.41.16-1.66.3-1.66,2.16v3.62a2.7,2.7,0,0,0,1.34-1c1.07-1.17,2.23-2.55,3-3.6.53-.71.46-1-.34-1.12l-.48-.06V238h4.53v.62a3.79,3.79,0,0,0-3.09,1.75l-3,3.44c1.35,1.9,3.28,4.52,4.32,5.85.89,1.13,1.13,1.3,2.23,1.45v.62h-3.07c-1.29-1.72-2.8-3.86-4.41-6.08-.42-.6-.73-.66-1-.63v4c0,1.83.16,2,1.66,2.12v.62h-5v-.62c1.48-.15,1.63-.29,1.63-2.12v-8.21c0-1.88-.13-2-1.63-2.16Z" />
        <path d="M735.62,252c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,735.62,252Z" />
        <path d="M746,248.76c-.25,1-.57,2.13-.75,2.93h-7v-.51c1-1.14,2.1-2.38,3-3.59,1.2-1.6,2.21-3.25,2.21-5.05s-.78-2.72-2.1-2.72-2,1.1-2.53,2l-.45-.45,1.07-1.77a3.21,3.21,0,0,1,2.57-1.28c1.77,0,3.17,1.39,3.17,3.68,0,1.77-.56,3-2.33,5.07-.78.92-1.91,2.17-2.68,3h3.43c1,0,1.29-.1,1.92-1.43Z" />
        <path d="M752.92,245.63l-1.17.69a2.1,2.1,0,0,1-1.1.32c-2,0-3.35-1.74-3.35-4,0-2,1.38-4.41,3.85-4.41s3.76,2.43,3.76,5.3a8.43,8.43,0,0,1-4.68,7.87A7.73,7.73,0,0,1,748,252l-.14-.65C750.47,250.76,752.33,248.78,752.92,245.63Zm-2-6.59c-1.35,0-1.84,1.47-1.84,3,0,1.86.9,3.4,2.41,3.4a2.44,2.44,0,0,0,1.53-.55,11.88,11.88,0,0,0,.08-1.41C753.08,241.57,752.62,239,750.9,239Z" />
        <path
          d="M711.07,275.65a9.29,9.29,0,0,1,0-14.84l.34.42a9.86,9.86,0,0,0-2.63,7,10.11,10.11,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M712.94,264.34l.76-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.45,2.15,1.72,1.72,0,0,1-.5,1.18,6.45,6.45,0,0,1-1.16.86A2.65,2.65,0,0,1,719,269c0,2.6-3.15,3.71-4.27,3.71a2.66,2.66,0,0,1-1.68-.55.75.75,0,0,1-.27-.56,1,1,0,0,1,.38-.64.35.35,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16l-.11-.49c1.43-.48,2.52-1.1,2.52-2.26a1.38,1.38,0,0,0-1.5-1.49,2.53,2.53,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M723.82,262.39c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.68-3.39-5.17C720.43,265.24,721.35,262.39,723.82,262.39Zm0,.59c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S725.18,263,723.82,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M731.65,262.39c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.4-2.68-3.4-5.17C728.25,265.24,729.18,262.39,731.65,262.39Zm0,.59c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S733,263,731.65,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M744.86,272.56v-.47c.89-.09,1-.16,1-1.25v-2.69c0-1.17-.43-1.81-1.33-1.81a2.78,2.78,0,0,0-1.67.77,6,6,0,0,1,0,.69v3.13c0,1,.12,1.07,1,1.16v.47h-3.46v-.47c.94-.09,1.07-.15,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47H736v-.47c1-.1,1.11-.16,1.11-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.22,2.22,0,0,1,1.28-.44,1.89,1.89,0,0,1,1.76,1.3c.43-.34.83-.6,1.23-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M756,270.34c-.22.74-.49,1.61-.65,2.22h-6.11v-.39c.87-.86,1.82-1.81,2.61-2.72a6.24,6.24,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.83-2.05a2.77,2.77,0,0,0-2.2,1.47l-.39-.34.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.34,2.27h3c.88,0,1.11-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M757.5,260.81a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.85,9.85,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M654.72,238h5v.62c-1.41.16-1.66.3-1.66,2.16v3.62a2.7,2.7,0,0,0,1.34-1c1.07-1.17,2.23-2.55,3-3.6.53-.71.46-1-.34-1.12l-.48-.06V238h4.53v.62a3.78,3.78,0,0,0-3.09,1.75l-3,3.44c1.35,1.9,3.28,4.52,4.32,5.85.89,1.13,1.13,1.3,2.23,1.45v.62h-3.07c-1.3-1.72-2.81-3.86-4.41-6.08-.42-.6-.73-.66-1-.63v4c0,1.83.16,2,1.66,2.12v.62h-5v-.62c1.48-.15,1.63-.29,1.63-2.12v-8.21c0-1.88-.14-2-1.63-2.16Z" />
        <path d="M669.34,252c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,669.34,252Z" />
        <path d="M679.74,248.76c-.25,1-.57,2.13-.75,2.93h-7v-.51c1-1.14,2.1-2.38,3-3.59,1.19-1.6,2.21-3.25,2.21-5.05s-.78-2.72-2.1-2.72-2,1.1-2.53,2l-.45-.45,1.07-1.77a3.21,3.21,0,0,1,2.57-1.28c1.76,0,3.17,1.39,3.17,3.68,0,1.77-.56,3-2.33,5.07-.78.92-1.91,2.17-2.68,3h3.43c1,0,1.29-.1,1.92-1.43Z" />
        <path d="M685,238.27a3,3,0,0,1,3.07,3.16,2.77,2.77,0,0,1-.53,1.7,16.9,16.9,0,0,1-1.49,1.3c1.27.81,2.5,1.82,2.5,3.66a3.77,3.77,0,0,1-3.77,3.86,3.39,3.39,0,0,1-3.63-3.5,2.66,2.66,0,0,1,.84-2.07,18.26,18.26,0,0,1,1.65-1.29,3.9,3.9,0,0,1-2-3.31A3.39,3.39,0,0,1,685,238.27Zm0,12.93c1.2,0,1.93-.9,1.93-2.41s-1.17-2.46-2.72-3.33a3.29,3.29,0,0,0-1.44,2.82C682.77,250.23,683.83,251.2,685,251.2ZM684.76,239c-1.06,0-1.62,1-1.62,2.11,0,1.52.9,2.11,2.34,3a3.13,3.13,0,0,0,1.12-2.55C686.6,239.74,685.68,239,684.76,239Z" />
        <path
          d="M647.78,275.65a9.31,9.31,0,0,1,0-14.84l.35.42a9.86,9.86,0,0,0-2.63,7,10.11,10.11,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M649.66,264.34l.76-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.45,2.15,1.72,1.72,0,0,1-.5,1.18,6.84,6.84,0,0,1-1.16.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.66,2.66,0,0,1-1.68-.55.75.75,0,0,1-.27-.56,1,1,0,0,1,.38-.64.35.35,0,0,1,.46,0,3,3,0,0,0,2,.89c1.1,0,1.88-.82,1.88-2.36a2,2,0,0,0-2.1-2.05,3.84,3.84,0,0,0-1,.16l-.12-.49c1.44-.48,2.53-1.1,2.53-2.26a1.38,1.38,0,0,0-1.5-1.49,2.53,2.53,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M660.54,262.39c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.68-3.39-5.17C657.15,265.24,658.07,262.39,660.54,262.39Zm0,.59c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S661.9,263,660.54,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M668.37,262.39c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.4-2.68-3.4-5.17C665,265.24,665.89,262.39,668.37,262.39Zm0,.59c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S669.72,263,668.37,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M681.58,272.56v-.47c.89-.09,1-.16,1-1.25v-2.69c0-1.17-.43-1.81-1.33-1.81a2.78,2.78,0,0,0-1.67.77c0,.2,0,.4,0,.69v3.13c0,1,.13,1.07,1,1.16v.47h-3.46v-.47c.94-.09,1.07-.15,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48v-.47c1-.1,1.11-.16,1.11-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.2,2.2,0,0,1,1.28-.44,1.89,1.89,0,0,1,1.76,1.3c.43-.34.83-.6,1.23-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M692.69,270.34c-.22.74-.49,1.61-.65,2.22h-6.12v-.39c.88-.86,1.83-1.81,2.62-2.72a6.24,6.24,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.83-2.05a2.77,2.77,0,0,0-2.2,1.47l-.39-.34.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.34,2.27h3c.89,0,1.12-.08,1.68-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M694.22,260.81a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.85,9.85,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M592.86,238h5v.62c-1.42.16-1.66.3-1.66,2.16v3.62a2.74,2.74,0,0,0,1.34-1c1.07-1.17,2.22-2.55,3-3.6.53-.71.46-1-.34-1.12l-.48-.06V238h4.52v.62a3.77,3.77,0,0,0-3.08,1.75l-3,3.44c1.35,1.9,3.28,4.52,4.32,5.85.89,1.13,1.13,1.3,2.23,1.45v.62h-3.07c-1.3-1.72-2.81-3.86-4.41-6.08-.42-.6-.73-.66-1-.63v4c0,1.83.16,2,1.66,2.12v.62h-5v-.62c1.49-.15,1.64-.29,1.64-2.12v-8.21c0-1.88-.14-2-1.64-2.16Z" />
        <path d="M607.49,252c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.31,1.31,0,0,1,1.22,1.42A1.29,1.29,0,0,1,607.49,252Z" />
        <path d="M617.89,248.76c-.25,1-.57,2.13-.76,2.93h-7v-.51c1-1.14,2.1-2.38,3-3.59,1.19-1.6,2.21-3.25,2.21-5.05s-.78-2.72-2.1-2.72-2,1.1-2.53,2l-.45-.45,1.07-1.77a3.21,3.21,0,0,1,2.57-1.28c1.76,0,3.17,1.39,3.17,3.68,0,1.77-.57,3-2.33,5.07-.78.92-1.91,2.17-2.68,3h3.43c1,0,1.29-.1,1.92-1.43Z" />
        <path d="M627,238.81c-1.76,4.28-3.49,8.56-5.26,12.92l-1.4.17-.14-.24c1.93-3.58,3.92-7.85,5.4-11.43h-3.92c-1.21,0-1.29.18-1.79,1.55h-.6c.2-1.27.29-2.34.38-3.25h7.22Z" />
        <path
          d="M584.93,275.65a9.31,9.31,0,0,1,0-14.84l.35.42a9.86,9.86,0,0,0-2.63,7,10.11,10.11,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M586.8,264.34l.76-1a2.88,2.88,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.72,1.72,0,0,1-.5,1.18,6.84,6.84,0,0,1-1.16.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.66,2.66,0,0,1-1.68-.55.75.75,0,0,1-.27-.56A1,1,0,0,1,587,271a.35.35,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.88-.82,1.88-2.36a2,2,0,0,0-2.1-2.05,3.84,3.84,0,0,0-1,.16l-.12-.49c1.44-.48,2.53-1.1,2.53-2.26a1.39,1.39,0,0,0-1.5-1.49,2.51,2.51,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M597.69,262.39c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.4-2.68-3.4-5.17C594.29,265.24,595.21,262.39,597.69,262.39Zm0,.59c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S599,263,597.69,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M605.52,262.39c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17C602.12,265.24,603,262.39,605.52,262.39Zm0,.59c-1.41,0-1.76,2.34-1.76,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S606.87,263,605.51,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M618.73,272.56v-.47c.89-.09,1-.16,1-1.25v-2.69c0-1.17-.43-1.81-1.33-1.81a2.78,2.78,0,0,0-1.67.77c0,.2,0,.4,0,.69v3.13c0,1,.13,1.07,1,1.16v.47H614.3v-.47c.94-.09,1.07-.15,1.07-1.19v-2.78c0-1.18-.39-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.49v-.47c1-.1,1.12-.16,1.12-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.2,2.2,0,0,1,1.28-.44,1.87,1.87,0,0,1,1.75,1.3c.44-.34.84-.6,1.24-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.1,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M629.84,270.34c-.22.74-.5,1.61-.66,2.22h-6.11v-.39c.88-.86,1.83-1.81,2.62-2.72a6.3,6.3,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.83-2.05a2.81,2.81,0,0,0-2.21,1.47l-.38-.34.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.84-.67.69-1.66,1.64-2.33,2.27h3c.89,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M631.37,260.81a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.85,9.85,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M529,238h5v.62c-1.42.16-1.66.3-1.66,2.16v3.62a2.74,2.74,0,0,0,1.34-1c1.07-1.17,2.22-2.55,3-3.6.53-.71.47-1-.33-1.12l-.48-.06V238h4.52v.62a3.79,3.79,0,0,0-3.09,1.75c-.58.66-1.92,2.17-3,3.44,1.35,1.9,3.28,4.52,4.32,5.85.89,1.13,1.13,1.3,2.23,1.45v.62h-3.07c-1.3-1.72-2.81-3.86-4.42-6.08-.41-.6-.72-.66-1-.63v4c0,1.83.15,2,1.66,2.12v.62h-5v-.62c1.49-.15,1.64-.29,1.64-2.12v-8.21c0-1.88-.14-2-1.64-2.16Z" />
        <path d="M543.66,252c-.69,0-1.18-.58-1.18-1.43a1.28,1.28,0,0,1,1.18-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,543.66,252Z" />
        <path d="M554.06,248.76c-.24,1-.56,2.13-.75,2.93h-7v-.51c1-1.14,2.1-2.38,3-3.59,1.2-1.6,2.22-3.25,2.22-5.05s-.78-2.72-2.11-2.72-2,1.1-2.53,2l-.44-.45,1.07-1.77a3.21,3.21,0,0,1,2.57-1.28c1.76,0,3.17,1.39,3.17,3.68,0,1.77-.57,3-2.34,5.07-.77.92-1.91,2.17-2.67,3h3.43c1,0,1.28-.1,1.92-1.43Z" />
        <path d="M558.58,243.7a2.38,2.38,0,0,1,1.18-.37c2,0,3.31,1.72,3.31,3.93,0,2.55-1.61,4.69-3.86,4.69s-3.88-2-3.88-5.31a8.49,8.49,0,0,1,5.07-7.92,13.89,13.89,0,0,1,2.19-.57l.11.72a9.56,9.56,0,0,0-2,.73,6.47,6.47,0,0,0-3.31,4.78Zm.38.9a3.24,3.24,0,0,0-1.67.52,8,8,0,0,0-.12,1.49c0,2.67.86,4.55,2.35,4.55s1.79-1.72,1.79-3.12C561.31,246.25,560.55,244.6,559,244.6Z" />
        <path
          d="M523.11,275.65a9.29,9.29,0,0,1,0-14.84l.34.42a9.9,9.9,0,0,0-2.62,7,10.1,10.1,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M525,264.34l.76-1a2.84,2.84,0,0,1,2.11-.93,2.21,2.21,0,0,1,2.45,2.15,1.72,1.72,0,0,1-.5,1.18,6.45,6.45,0,0,1-1.16.86c1.52.33,2.41,1.29,2.41,2.46,0,2.6-3.16,3.71-4.27,3.71a2.67,2.67,0,0,1-1.69-.55.75.75,0,0,1-.27-.56,1,1,0,0,1,.38-.64.35.35,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16l-.11-.49c1.43-.48,2.53-1.1,2.53-2.26a1.39,1.39,0,0,0-1.51-1.49,2.54,2.54,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M535.87,262.39c2.45,0,3.35,2.72,3.35,5.17s-.9,5.19-3.35,5.19-3.4-2.68-3.4-5.17C532.47,265.24,533.39,262.39,535.87,262.39Zm0,.59c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S537.22,263,535.86,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M543.69,262.39c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.68-3.39-5.17C540.3,265.24,541.22,262.39,543.69,262.39Zm0,.59c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S545.05,263,543.69,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M556.9,272.56v-.47c.89-.09,1-.16,1-1.25v-2.69c0-1.17-.43-1.81-1.33-1.81a2.78,2.78,0,0,0-1.67.77,6,6,0,0,1,.05.69v3.13c0,1,.12,1.07,1,1.16v.47h-3.46v-.47c.94-.09,1.07-.15,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48v-.47c1-.1,1.11-.16,1.11-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.22,2.22,0,0,1,1.28-.44,1.89,1.89,0,0,1,1.76,1.3c.43-.34.83-.6,1.23-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M568,270.34c-.21.74-.49,1.61-.65,2.22h-6.11v-.39c.87-.86,1.83-1.81,2.62-2.72a6.28,6.28,0,0,0,1.92-3.83c0-1.29-.67-2.05-1.83-2.05a2.77,2.77,0,0,0-2.2,1.47l-.39-.34.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.33,2.27h3c.88,0,1.11-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M569.54,260.81a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.85,9.85,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M467.72,238h5v.62c-1.41.16-1.66.3-1.66,2.16v3.62a2.71,2.71,0,0,0,1.35-1c1.07-1.17,2.22-2.55,3-3.6.53-.71.47-1-.33-1.12l-.49-.06V238h4.53v.62a3.79,3.79,0,0,0-3.09,1.75l-3,3.44c1.36,1.9,3.29,4.52,4.33,5.85.89,1.13,1.12,1.3,2.23,1.45v.62h-3.07c-1.3-1.72-2.81-3.86-4.42-6.08-.41-.6-.72-.66-1-.63v4c0,1.83.16,2,1.67,2.12v.62h-5v-.62c1.48-.15,1.64-.29,1.64-2.12v-8.21c0-1.88-.14-2-1.64-2.16Z" />
        <path d="M482.34,252c-.7,0-1.18-.58-1.18-1.43a1.28,1.28,0,0,1,1.18-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,482.34,252Z" />
        <path d="M492.74,248.76c-.25,1-.57,2.13-.75,2.93h-7v-.51c1-1.14,2.1-2.38,3-3.59,1.2-1.6,2.22-3.25,2.22-5.05s-.78-2.72-2.11-2.72-2,1.1-2.53,2l-.44-.45,1.07-1.77a3.21,3.21,0,0,1,2.57-1.28c1.76,0,3.17,1.39,3.17,3.68,0,1.77-.57,3-2.34,5.07-.77.92-1.91,2.17-2.67,3h3.43c1,0,1.28-.1,1.92-1.43Z" />
        {/* <path d="M497.26,243.7a2.35,2.35,0,0,1,1.18-.37c2,0,3.31,1.72,3.31,3.93,0,2.55-1.61,4.69-3.86,4.69s-3.89-2-3.89-5.31a8.5,8.5,0,0,1,5.07-7.92,14,14,0,0,1,2.2-.57l.11.72a9.86,9.86,0,0,0-2,.73,6.49,6.49,0,0,0-3.3,4.78Zm.38.9a3.31,3.31,0,0,0-1.68.52,9.08,9.08,0,0,0-.11,1.49c0,2.67.85,4.55,2.35,4.55S500,249.44,500,248C500,246.25,499.23,244.6,497.64,244.6Z" /> */}
        <path d="M500.5,238.6l0.1,0.2l-0.5,1.5h-4.3c-0.2,1.1-0.4,2.7-0.6,3.7c0.7-0.3,1.5-0.5,2.2-0.6c1.9,0,3.5,1.5,3.5,3.4  c0,0.1,0,0.1,0,0.2c0,1.4-0.6,2.7-1.6,3.6c-0.9,0.9-2.1,1.4-3.3,1.5c-0.7,0-1.4-0.3-1.9-0.8c-0.2-0.2-0.4-0.5-0.3-0.7  c0-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.4-0.2,0.5,0c0,0,0,0,0,0c0.6,0.7,1.4,1.2,2.3,1.2c1.5,0,2.2-1.5,2.2-3c0-1.9-1-3.2-3-3.2  c-0.6,0-1.3,0.1-1.8,0.4l-0.1-0.1c0.3-1.7,0.7-4.6,0.9-6.4L500.5,238.6z" />
        <path
          d="M459.26,275.65a9.29,9.29,0,0,1,0-14.84l.34.42a9.86,9.86,0,0,0-2.63,7,10.11,10.11,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M461.13,264.34l.76-1a2.84,2.84,0,0,1,2.11-.93,2.21,2.21,0,0,1,2.45,2.15,1.72,1.72,0,0,1-.5,1.18,6.45,6.45,0,0,1-1.16.86c1.52.33,2.41,1.29,2.41,2.46,0,2.6-3.16,3.71-4.28,3.71a2.66,2.66,0,0,1-1.68-.55.75.75,0,0,1-.27-.56,1,1,0,0,1,.38-.64.35.35,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16l-.11-.49c1.43-.48,2.52-1.1,2.52-2.26a1.38,1.38,0,0,0-1.5-1.49,2.53,2.53,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M472,262.39c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.68-3.39-5.17C468.62,265.24,469.54,262.39,472,262.39Zm0,.59c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S473.37,263,472,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M479.84,262.39c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.68-3.39-5.17C476.45,265.24,477.37,262.39,479.84,262.39Zm0,.59c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S481.2,263,479.84,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M493.05,272.56v-.47c.89-.09,1-.16,1-1.25v-2.69c0-1.17-.43-1.81-1.33-1.81a2.78,2.78,0,0,0-1.67.77,6,6,0,0,1,.05.69v3.13c0,1,.12,1.07,1,1.16v.47h-3.46v-.47c.94-.09,1.07-.15,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48v-.47c1-.1,1.11-.16,1.11-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.22,2.22,0,0,1,1.28-.44,1.89,1.89,0,0,1,1.76,1.3c.43-.34.83-.6,1.23-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M504.16,270.34c-.21.74-.49,1.61-.65,2.22H497.4v-.39c.87-.86,1.82-1.81,2.61-2.72a6.24,6.24,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.83-2.05a2.77,2.77,0,0,0-2.2,1.47l-.39-.34.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.34,2.27h3c.88,0,1.11-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M505.69,260.81a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.85,9.85,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M400.91,238h5v.62c-1.41.16-1.66.3-1.66,2.16v3.62a2.75,2.75,0,0,0,1.35-1c1.06-1.17,2.22-2.55,3-3.6.53-.71.47-1-.33-1.12l-.49-.06V238h4.53v.62a3.79,3.79,0,0,0-3.09,1.75l-3,3.44c1.36,1.9,3.29,4.52,4.32,5.85.89,1.13,1.13,1.3,2.24,1.45v.62h-3.07c-1.3-1.72-2.81-3.86-4.42-6.08-.41-.6-.72-.66-1-.63v4c0,1.83.16,2,1.67,2.12v.62h-5v-.62c1.48-.15,1.64-.29,1.64-2.12v-8.21c0-1.88-.14-2-1.64-2.16Z" />
        <path d="M415.53,252c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,415.53,252Z" />
        <path d="M425.93,248.76c-.25,1-.57,2.13-.75,2.93h-7v-.51c1-1.14,2.1-2.38,3-3.59,1.2-1.6,2.21-3.25,2.21-5.05s-.77-2.72-2.1-2.72-2,1.1-2.53,2l-.44-.45,1.07-1.77a3.19,3.19,0,0,1,2.57-1.28c1.76,0,3.17,1.39,3.17,3.68,0,1.77-.57,3-2.34,5.07-.78.92-1.91,2.17-2.68,3h3.44c1,0,1.28-.1,1.92-1.43Z" />
        <path d="M433.35,247.88v1.55c0,1.37.08,1.49,1.4,1.64v.62h-4.46v-.62c1.36-.15,1.45-.3,1.45-1.67v-1.52h-4.83v-.68c1.78-2.88,3.73-6,5.52-8.85h.92v8.48h1.78v1Zm-1.61-7.19c-1.11,1.85-2.39,4-3.66,6.14h3.66Z" />
        <path
          d="M395.13,275.65a9.31,9.31,0,0,1,0-14.84l.34.42a9.9,9.9,0,0,0-2.62,7,10.15,10.15,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M397,264.34l.76-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.69,1.69,0,0,1-.51,1.18,6.34,6.34,0,0,1-1.15.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.72,2.72,0,0,1-1.69-.55.74.74,0,0,1-.26-.56,1,1,0,0,1,.38-.64.35.35,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16l-.11-.49c1.43-.48,2.53-1.1,2.53-2.26a1.39,1.39,0,0,0-1.51-1.49,2.54,2.54,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M407.89,262.39c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17C404.49,265.24,405.41,262.39,407.89,262.39Zm0,.59c-1.41,0-1.76,2.34-1.76,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S409.24,263,407.88,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M415.72,262.39c2.45,0,3.35,2.72,3.35,5.17s-.9,5.19-3.35,5.19-3.4-2.68-3.4-5.17C412.32,265.24,413.24,262.39,415.72,262.39Zm0,.59c-1.42,0-1.76,2.34-1.76,4.56s.42,4.63,1.77,4.63,1.74-2.37,1.74-4.61S417.07,263,415.71,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M428.92,272.56v-.47c.89-.09,1-.16,1-1.25v-2.69c0-1.17-.42-1.81-1.33-1.81a2.8,2.8,0,0,0-1.67.77,6,6,0,0,1,.05.69v3.13c0,1,.13,1.07,1,1.16v.47h-3.46v-.47c.94-.09,1.07-.15,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.66.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48v-.47c1-.1,1.11-.16,1.11-1.2v-3.33c0-1-.05-1.08-.92-1.23v-.42a10.09,10.09,0,0,0,2.33-.63v1.28a9.41,9.41,0,0,1,1.15-.8,2.18,2.18,0,0,1,1.28-.44,1.88,1.88,0,0,1,1.75,1.3c.44-.34.84-.6,1.24-.88a2.46,2.46,0,0,1,1.26-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M440,270.34c-.22.74-.5,1.61-.66,2.22h-6.11v-.39c.87-.86,1.83-1.81,2.62-2.72a6.3,6.3,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.84-2.05a2.81,2.81,0,0,0-2.2,1.47l-.39-.34.94-1.34a3,3,0,0,1,2.23-1,2.66,2.66,0,0,1,2.77,2.79c0,1.34-.5,2.29-2,3.84-.67.69-1.66,1.64-2.33,2.27h3c.89,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M441.56,260.81a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.84,9.84,0,0,0,2.63-7,10.05,10.05,0,0,0-2.63-7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M333.28,275.65a9.29,9.29,0,0,1,0-14.84l.34.42a9.9,9.9,0,0,0-2.62,7,10.1,10.1,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M335.15,264.34l.76-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.73,1.73,0,0,1-.51,1.18,6.71,6.71,0,0,1-1.15.86,2.66,2.66,0,0,1,2.4,2.46c0,2.6-3.16,3.71-4.27,3.71a2.7,2.7,0,0,1-1.69-.55.74.74,0,0,1-.26-.56,1,1,0,0,1,.38-.64.34.34,0,0,1,.45,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16l-.11-.49c1.43-.48,2.53-1.1,2.53-2.26a1.39,1.39,0,0,0-1.51-1.49,2.54,2.54,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M346,262.39c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17C342.64,265.24,343.56,262.39,346,262.39Zm0,.59c-1.42,0-1.76,2.34-1.76,4.56s.42,4.63,1.77,4.63,1.74-2.37,1.74-4.61S347.39,263,346,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M353.87,262.39c2.45,0,3.35,2.72,3.35,5.17s-.9,5.19-3.35,5.19-3.4-2.68-3.4-5.17C350.47,265.24,351.39,262.39,353.87,262.39Zm0,.59c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S355.22,263,353.86,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M367.07,272.56v-.47c.89-.09,1-.16,1-1.25v-2.69c0-1.17-.42-1.81-1.33-1.81a2.8,2.8,0,0,0-1.67.77,6,6,0,0,1,.05.69v3.13c0,1,.13,1.07,1,1.16v.47h-3.46v-.47c.94-.09,1.07-.15,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.86,2.86,0,0,0-1.66.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48v-.47c1-.1,1.11-.16,1.11-1.2v-3.33c0-1-.05-1.08-.92-1.23v-.42a10.23,10.23,0,0,0,2.33-.63v1.28a10.06,10.06,0,0,1,1.14-.8,2.23,2.23,0,0,1,1.29-.44,1.88,1.88,0,0,1,1.75,1.3c.44-.34.83-.6,1.23-.88a2.5,2.5,0,0,1,1.27-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M378.18,270.34c-.21.74-.49,1.61-.65,2.22h-6.11v-.39c.87-.86,1.83-1.81,2.62-2.72a6.3,6.3,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.84-2.05a2.79,2.79,0,0,0-2.2,1.47l-.39-.34.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.84-.68.69-1.66,1.64-2.33,2.27h3c.89,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M379.71,260.81a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.84,9.84,0,0,0,2.63-7,10.05,10.05,0,0,0-2.63-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M275.64,238h5v.62c-1.42.16-1.66.3-1.66,2.16v3.62a2.74,2.74,0,0,0,1.34-1c1.07-1.17,2.22-2.55,3-3.6.53-.71.46-1-.34-1.12l-.48-.06V238h4.52v.62a3.8,3.8,0,0,0-3.09,1.75l-3,3.44c1.35,1.9,3.28,4.52,4.32,5.85.89,1.13,1.13,1.3,2.23,1.45v.62h-3.07c-1.3-1.72-2.81-3.86-4.41-6.08-.42-.6-.73-.66-1-.63v4c0,1.83.16,2,1.66,2.12v.62h-5v-.62c1.49-.15,1.64-.29,1.64-2.12v-8.21c0-1.88-.14-2-1.64-2.16Z" />
        <path d="M290.26,252c-.69,0-1.18-.58-1.18-1.43a1.28,1.28,0,0,1,1.18-1.42,1.31,1.31,0,0,1,1.23,1.42A1.29,1.29,0,0,1,290.26,252Z" />
        <path d="M300.67,248.76c-.25,1-.57,2.13-.76,2.93h-7v-.51c1-1.14,2.1-2.38,3-3.59,1.19-1.6,2.21-3.25,2.21-5.05s-.78-2.72-2.1-2.72-2,1.1-2.53,2l-.45-.45,1.07-1.77a3.21,3.21,0,0,1,2.57-1.28c1.76,0,3.17,1.39,3.17,3.68,0,1.77-.57,3-2.33,5.07-.78.92-1.92,2.17-2.68,3h3.43c1,0,1.28-.1,1.92-1.43Z" />
        <path d="M309.65,248.76c-.25,1-.57,2.13-.75,2.93h-7v-.51c1-1.14,2.1-2.38,3-3.59,1.2-1.6,2.21-3.25,2.21-5.05s-.77-2.72-2.1-2.72-2,1.1-2.53,2l-.44-.45,1.07-1.77a3.19,3.19,0,0,1,2.57-1.28c1.76,0,3.17,1.39,3.17,3.68,0,1.77-.57,3-2.34,5.07-.78.92-1.91,2.17-2.68,3h3.44c1,0,1.28-.1,1.91-1.43Z" />
        <path d="M338.06,238h5v.62c-1.41.16-1.66.3-1.66,2.16v3.62a2.75,2.75,0,0,0,1.35-1c1.06-1.17,2.22-2.55,3-3.6.53-.71.47-1-.33-1.12l-.49-.06V238h4.53v.62a3.79,3.79,0,0,0-3.09,1.75l-3,3.44c1.36,1.9,3.29,4.52,4.32,5.85.89,1.13,1.13,1.3,2.24,1.45v.62h-3.07c-1.3-1.72-2.81-3.86-4.42-6.08-.41-.6-.72-.66-1-.63v4c0,1.83.16,2,1.67,2.12v.62h-5v-.62c1.48-.15,1.64-.29,1.64-2.12v-8.21c0-1.88-.14-2-1.64-2.16Z" />
        {/* <path d="M355.83,238.58c-1.41.19-1.56.32-1.56,2.09v7.67c0,2.73-.35,4.29-1.67,5.62a6,6,0,0,1-1.81,1.24l-.3-.67a3.61,3.61,0,0,0,1.83-2.75,28,28,0,0,0,.18-4v-7.08c0-1.77-.17-1.92-1.66-2.09V238h5Z" /> */}
        <path d="M357.72,252c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.31,1.31,0,0,1,1.22,1.42A1.29,1.29,0,0,1,357.72,252Z" />
        <path d="M368.12,248.76c-.25,1-.57,2.13-.76,2.93h-7v-.51c1-1.14,2.1-2.38,3-3.59,1.19-1.6,2.21-3.25,2.21-5.05s-.78-2.72-2.1-2.72-2,1.1-2.53,2l-.45-.45,1.07-1.77a3.21,3.21,0,0,1,2.57-1.28c1.76,0,3.17,1.39,3.17,3.68,0,1.77-.57,3-2.33,5.07-.78.92-1.92,2.17-2.68,3h3.43c1,0,1.28-.1,1.92-1.43Z" />
        <path d="M369.73,240.85l.87-1.35a3.12,3.12,0,0,1,2.42-1.23,2.71,2.71,0,0,1,2.82,2.84,2.4,2.4,0,0,1-.58,1.55,8,8,0,0,1-1.32,1.15,3.31,3.31,0,0,1,2.75,3.24c0,3.43-3.62,4.9-4.9,4.9a2.82,2.82,0,0,1-1.93-.73,1,1,0,0,1-.31-.73,1.37,1.37,0,0,1,.44-.85.35.35,0,0,1,.53,0,3.27,3.27,0,0,0,2.29,1.16c1.26,0,2.15-1.07,2.15-3.11a2.41,2.41,0,0,0-2.4-2.71,4.07,4.07,0,0,0-1.19.22l-.13-.66c1.65-.62,2.9-1.43,2.9-3,0-1.26-.7-2-1.72-2a2.92,2.92,0,0,0-2.29,1.66Z" />
        <path
          d="M268.86,275.65a9.31,9.31,0,0,1,0-14.84l.35.42a9.86,9.86,0,0,0-2.63,7,10.11,10.11,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M270.74,264.34l.76-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.45,2.15,1.72,1.72,0,0,1-.5,1.18,6.84,6.84,0,0,1-1.16.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.66,2.66,0,0,1-1.68-.55.75.75,0,0,1-.27-.56A1,1,0,0,1,271,271a.35.35,0,0,1,.46,0,3,3,0,0,0,2,.89c1.1,0,1.88-.82,1.88-2.36a2,2,0,0,0-2.1-2.05,3.84,3.84,0,0,0-1,.16l-.12-.49c1.44-.48,2.53-1.1,2.53-2.26a1.38,1.38,0,0,0-1.5-1.49,2.53,2.53,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M281.62,262.39c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.68-3.39-5.17C278.23,265.24,279.15,262.39,281.62,262.39Zm0,.59c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S283,263,281.62,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M289.45,262.39c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.4-2.68-3.4-5.17C286.05,265.24,287,262.39,289.45,262.39Zm0,.59c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S290.8,263,289.45,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M302.66,272.56v-.47c.89-.09,1-.16,1-1.25v-2.69c0-1.17-.43-1.81-1.33-1.81a2.78,2.78,0,0,0-1.67.77c0,.2,0,.4,0,.69v3.13c0,1,.13,1.07,1,1.16v.47h-3.46v-.47c.94-.09,1.07-.15,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48v-.47c1-.1,1.11-.16,1.11-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.2,2.2,0,0,1,1.28-.44,1.89,1.89,0,0,1,1.76,1.3c.43-.34.83-.6,1.23-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M313.77,270.34c-.22.74-.49,1.61-.65,2.22H307v-.39c.88-.86,1.83-1.81,2.62-2.72a6.24,6.24,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.83-2.05a2.77,2.77,0,0,0-2.2,1.47l-.39-.34.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.34,2.27h3c.89,0,1.12-.08,1.68-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M315.3,260.81a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.85,9.85,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M198.43,238h5v.62c-1.41.16-1.66.3-1.66,2.16v3.62a2.7,2.7,0,0,0,1.34-1c1.07-1.17,2.23-2.55,3-3.6.53-.71.46-1-.34-1.12l-.48-.06V238h4.53v.62a3.79,3.79,0,0,0-3.09,1.75l-3,3.44c1.35,1.9,3.28,4.52,4.32,5.85.89,1.13,1.13,1.3,2.23,1.45v.62h-3.07c-1.29-1.72-2.8-3.86-4.41-6.08-.42-.6-.73-.66-1-.63v4c0,1.83.16,2,1.66,2.12v.62h-5v-.62c1.48-.15,1.63-.29,1.63-2.12v-8.21c0-1.88-.14-2-1.63-2.16Z" />
        <path d="M213.05,252c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,213.05,252Z" />
        <path d="M223.45,248.76c-.25,1-.57,2.13-.75,2.93h-7v-.51c1-1.14,2.1-2.38,3-3.59,1.19-1.6,2.21-3.25,2.21-5.05s-.78-2.72-2.1-2.72-2,1.1-2.53,2l-.45-.45,1.07-1.77a3.21,3.21,0,0,1,2.57-1.28c1.77,0,3.17,1.39,3.17,3.68,0,1.77-.56,3-2.33,5.07-.78.92-1.91,2.17-2.68,3h3.43c1,0,1.29-.1,1.92-1.43Z" />
        <path d="M225.7,251.69v-.62c2-.14,2.13-.27,2.13-1.84v-8c0-1.07-.17-1.12-1-1.21l-.84-.11v-.56a13.75,13.75,0,0,0,3.49-1v11c0,1.59.11,1.7,2.13,1.84v.62Z" />
        <path
          d="M191.65,275.65a9.29,9.29,0,0,1,0-14.84l.34.42a9.9,9.9,0,0,0-2.62,7,10.1,10.1,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M199.27,262.59l.1.13-.44,1.15H195.2c-.15.85-.36,2.06-.5,2.82a7.73,7.73,0,0,1,1.93-.47,2.8,2.8,0,0,1,3,2.72,3.39,3.39,0,0,1-1.42,2.7,5,5,0,0,1-2.87,1.11,2.46,2.46,0,0,1-1.67-.61c-.25-.21-.31-.35-.28-.56a.91.91,0,0,1,.36-.6.37.37,0,0,1,.48,0,2.87,2.87,0,0,0,2,.9c1.28,0,1.89-1.1,1.89-2.3a2.29,2.29,0,0,0-2.58-2.41,5,5,0,0,0-1.62.29l-.05-.07c.26-1.29.61-3.51.8-4.82Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M201.91,272.56v-.48c1.75-.1,1.85-.2,1.85-1.39V264.6c0-.81-.15-.85-.84-.92l-.73-.08v-.42a13.88,13.88,0,0,0,3-.79v8.3c0,1.21.1,1.29,1.86,1.39v.48Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M212.23,262.39c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.68-3.39-5.17C208.84,265.24,209.76,262.39,212.23,262.39Zm0,.59c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S213.59,263,212.23,263Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M225.44,272.56v-.47c.89-.09,1-.16,1-1.25v-2.69c0-1.17-.43-1.81-1.33-1.81a2.8,2.8,0,0,0-1.67.77,6,6,0,0,1,.05.69v3.13c0,1,.12,1.07,1,1.16v.47H221v-.47c.94-.09,1.07-.15,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.84,2.84,0,0,0-1.66.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48v-.47c1-.1,1.11-.16,1.11-1.2v-3.33c0-1-.05-1.08-.93-1.23v-.42a10.17,10.17,0,0,0,2.34-.63v1.28a9.23,9.23,0,0,1,1.14-.8,2.22,2.22,0,0,1,1.28-.44,1.88,1.88,0,0,1,1.76,1.3c.44-.34.83-.6,1.23-.88a2.5,2.5,0,0,1,1.27-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.47Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M236.55,270.34c-.21.74-.49,1.61-.65,2.22h-6.11v-.39c.87-.86,1.83-1.81,2.62-2.72a6.28,6.28,0,0,0,1.92-3.83c0-1.29-.67-2.05-1.83-2.05a2.79,2.79,0,0,0-2.2,1.47l-.39-.34.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.33,2.27h3c.88,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M238.08,260.81a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.8,9.8,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M716.14,541.24h5v.63c-1.41.16-1.66.3-1.66,2.16v3.62a2.82,2.82,0,0,0,1.35-1c1.06-1.18,2.22-2.56,3-3.61.53-.7.47-1-.33-1.11l-.49-.06v-.63h4.53v.63a3.79,3.79,0,0,0-3.09,1.75l-3,3.44c1.36,1.89,3.29,4.52,4.32,5.84.9,1.14,1.13,1.3,2.24,1.45V555h-3.07c-1.3-1.72-2.81-3.87-4.42-6.09-.41-.6-.72-.65-1-.62v4c0,1.83.16,2,1.67,2.12V555h-5v-.63c1.48-.15,1.64-.29,1.64-2.12V544c0-1.88-.14-2-1.64-2.16Z" />
        <path d="M730.76,555.27a1.27,1.27,0,0,1-1.19-1.43,1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.29,1.29,0,0,1,730.76,555.27Z" />
        <path d="M740.39,541.81l.11.18-.5,1.52h-4.28c-.17,1.12-.41,2.72-.58,3.73a7.47,7.47,0,0,1,2.22-.62,3.43,3.43,0,0,1,3.48,3.58,4.7,4.7,0,0,1-1.62,3.57,5.39,5.39,0,0,1-3.3,1.46,2.6,2.6,0,0,1-1.91-.8.82.82,0,0,1-.33-.74,1.24,1.24,0,0,1,.42-.79.38.38,0,0,1,.55,0,3.15,3.15,0,0,0,2.33,1.19c1.47,0,2.17-1.46,2.17-3,0-1.87-1-3.18-3-3.18a5.14,5.14,0,0,0-1.86.37l-.06-.09c.3-1.7.71-4.63.92-6.37Z" />
        <path d="M743.42,555v-.63c2-.14,2.12-.27,2.12-1.84v-8c0-1.08-.16-1.13-1-1.22l-.84-.1v-.56a14.16,14.16,0,0,0,3.49-1v11c0,1.6.11,1.7,2.14,1.84V555Z" />
        <path
          d="M709.2,578.94a9.31,9.31,0,0,1,0-14.84l.35.42a9.82,9.82,0,0,0-2.63,7,10.08,10.08,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M711.08,567.63l.76-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.45,2.15,1.7,1.7,0,0,1-.5,1.17,7.4,7.4,0,0,1-1.16.87,2.63,2.63,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.6,2.6,0,0,1-1.68-.56.73.73,0,0,1-.27-.55,1,1,0,0,1,.38-.64.33.33,0,0,1,.46,0,3,3,0,0,0,2,.88c1.1,0,1.88-.81,1.88-2.36a2,2,0,0,0-2.1-2.05,3.82,3.82,0,0,0-1,.17l-.12-.5c1.44-.47,2.53-1.09,2.53-2.26a1.38,1.38,0,0,0-1.5-1.48,2.54,2.54,0,0,0-2,1.25Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M722,565.68c2.46,0,3.36,2.72,3.36,5.17S724.42,576,722,576s-3.39-2.69-3.39-5.17S719.49,565.68,722,565.68Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.61S723.32,566.26,722,566.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M729.79,565.68c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.4-2.69-3.4-5.17S727.31,565.68,729.79,565.68Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.61S731.14,566.26,729.79,566.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M743,575.84v-.46c.89-.1,1-.16,1-1.26v-2.69c0-1.17-.43-1.8-1.33-1.8a2.72,2.72,0,0,0-1.67.77c0,.19,0,.39,0,.68v3.13c0,1,.13,1.07,1,1.17v.46h-3.46v-.46c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.94,2.94,0,0,0-1.67.76v3.8c0,1,.11,1.09,1,1.19v.46h-3.48v-.46c1-.1,1.11-.17,1.11-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a9.52,9.52,0,0,0,2.33-.64v1.28a13.82,13.82,0,0,1,1.15-.8,2.26,2.26,0,0,1,1.28-.43,1.87,1.87,0,0,1,1.76,1.3c.43-.35.83-.61,1.23-.88a2.38,2.38,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.46Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M754.11,573.62c-.22.75-.49,1.62-.65,2.22h-6.12v-.39c.88-.86,1.83-1.8,2.62-2.71a6.27,6.27,0,0,0,1.93-3.83c0-1.29-.68-2.06-1.83-2.06a2.76,2.76,0,0,0-2.2,1.48l-.39-.35.93-1.33a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.78c0,1.35-.49,2.3-2,3.84-.68.7-1.67,1.65-2.34,2.27h3c.89,0,1.12-.07,1.68-1.08Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M755.64,564.1a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.89,9.89,0,0,0,2.64-7,10.07,10.07,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M715.14,455.41h5V456c-1.41.16-1.66.3-1.66,2.16v3.62a2.82,2.82,0,0,0,1.35-1c1.06-1.17,2.22-2.56,3-3.6.53-.71.47-1-.33-1.12L722,456v-.62h4.53V456a3.79,3.79,0,0,0-3.09,1.75l-3,3.44c1.36,1.9,3.29,4.52,4.32,5.84.9,1.14,1.13,1.3,2.24,1.46v.62h-3.07c-1.3-1.72-2.81-3.86-4.42-6.08-.41-.6-.72-.66-1-.63v4c0,1.83.16,2,1.67,2.13v.62h-5v-.62c1.48-.16,1.64-.3,1.64-2.13v-8.2c0-1.88-.14-2-1.64-2.16Z" />
        <path d="M729.76,469.43c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42A1.3,1.3,0,0,1,731,468,1.29,1.29,0,0,1,729.76,469.43Z" />
        <path d="M738.59,465.33v1.55c0,1.37.08,1.49,1.41,1.64v.62h-4.46v-.62c1.36-.15,1.44-.3,1.44-1.67v-1.52h-4.83v-.68c1.79-2.89,3.73-6,5.52-8.85h.92v8.47h1.79v1.06ZM737,458.14c-1.11,1.85-2.39,4-3.65,6.13H737Z" />
        <path d="M745.43,455.72a3,3,0,0,1,3.07,3.16,2.69,2.69,0,0,1-.54,1.69,15,15,0,0,1-1.49,1.31c1.27.8,2.51,1.81,2.51,3.65a3.79,3.79,0,0,1-3.78,3.87,3.38,3.38,0,0,1-3.62-3.51,2.63,2.63,0,0,1,.83-2.06,18.64,18.64,0,0,1,1.66-1.29,3.9,3.9,0,0,1-2-3.31A3.38,3.38,0,0,1,745.43,455.72Zm0,12.93c1.19,0,1.92-.9,1.92-2.41s-1.17-2.46-2.72-3.33a3.29,3.29,0,0,0-1.44,2.82C743.19,467.68,744.25,468.65,745.43,468.65Zm-.24-12.2c-1.07,0-1.63,1-1.63,2.12,0,1.52.91,2.11,2.35,3A3.15,3.15,0,0,0,747,459C747,457.18,746.1,456.45,745.19,456.45Z" />
        <path
          d="M709.2,493.1a9.31,9.31,0,0,1,0-14.84l.35.42a9.84,9.84,0,0,0-2.63,7,10.08,10.08,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M711.08,481.79l.76-1a2.84,2.84,0,0,1,2.11-.93A2.22,2.22,0,0,1,716.4,482a1.74,1.74,0,0,1-.5,1.18,7.42,7.42,0,0,1-1.16.86,2.64,2.64,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.6,2.6,0,0,1-1.68-.56.72.72,0,0,1-.27-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.88c1.1,0,1.88-.81,1.88-2.35a2,2,0,0,0-2.1-2.05,3.84,3.84,0,0,0-1,.16l-.12-.5c1.44-.47,2.53-1.09,2.53-2.26a1.38,1.38,0,0,0-1.5-1.48,2.53,2.53,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M722,479.84c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.68-3.39-5.17C718.57,482.69,719.49,479.84,722,479.84Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.6S723.32,480.42,722,480.42Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M729.79,479.84c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.4-2.68-3.4-5.17C726.39,482.69,727.31,479.84,729.79,479.84Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.6S731.14,480.42,729.79,480.42Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M743,490v-.47c.89-.1,1-.16,1-1.25V485.6c0-1.18-.43-1.81-1.33-1.81a2.72,2.72,0,0,0-1.67.77c0,.19,0,.39,0,.69v3.13c0,1,.13,1.06,1,1.16V490h-3.46v-.47c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.09,1,1.19V490h-3.48v-.47c1-.1,1.11-.16,1.11-1.2V485c0-1-.06-1.08-.93-1.23v-.42a9.52,9.52,0,0,0,2.33-.64V484a13.82,13.82,0,0,1,1.15-.8,2.26,2.26,0,0,1,1.28-.43,1.87,1.87,0,0,1,1.76,1.3c.43-.34.83-.61,1.23-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19V490Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M754.11,487.78c-.22.75-.49,1.62-.65,2.23h-6.12v-.4c.88-.86,1.83-1.8,2.62-2.71a6.27,6.27,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.83-2.05a2.75,2.75,0,0,0-2.2,1.47l-.39-.35.93-1.33a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.83-.68.7-1.67,1.65-2.34,2.28h3c.89,0,1.12-.08,1.68-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M755.64,478.26a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.87,9.87,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M651.79,541.24h5v.63c-1.41.16-1.66.3-1.66,2.16v3.62a2.82,2.82,0,0,0,1.35-1c1.06-1.18,2.22-2.56,3-3.61.53-.7.47-1-.33-1.11l-.49-.06v-.63h4.53v.63a3.79,3.79,0,0,0-3.09,1.75l-3,3.44c1.36,1.89,3.29,4.52,4.32,5.84.9,1.14,1.13,1.3,2.24,1.45V555h-3.07c-1.3-1.72-2.81-3.87-4.42-6.09-.41-.6-.72-.65-1-.62v4c0,1.83.16,2,1.67,2.12V555h-5v-.63c1.48-.15,1.64-.29,1.64-2.12V544c0-1.88-.14-2-1.64-2.16Z" />
        <path d="M666.41,555.27a1.27,1.27,0,0,1-1.19-1.43,1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.29,1.29,0,0,1,666.41,555.27Z" />
        <path d="M676,541.81l.11.18-.49,1.52h-4.29c-.17,1.12-.41,2.72-.58,3.73a7.47,7.47,0,0,1,2.22-.62,3.44,3.44,0,0,1,3.49,3.58,4.72,4.72,0,0,1-1.63,3.57,5.39,5.39,0,0,1-3.3,1.46,2.6,2.6,0,0,1-1.91-.8.82.82,0,0,1-.33-.74,1.24,1.24,0,0,1,.42-.79.38.38,0,0,1,.55,0,3.15,3.15,0,0,0,2.33,1.19c1.47,0,2.17-1.46,2.17-3,0-1.87-1-3.18-3-3.18a5,5,0,0,0-1.85.37l-.07-.09c.3-1.7.71-4.63.92-6.37Z" />
        <path d="M685.8,552.05c-.25,1-.57,2.13-.75,2.93h-7v-.52c1-1.13,2.1-2.38,3-3.58,1.19-1.61,2.21-3.25,2.21-5s-.78-2.72-2.1-2.72-2,1.1-2.53,1.95l-.45-.46,1.07-1.76a3.21,3.21,0,0,1,2.57-1.28c1.76,0,3.17,1.39,3.17,3.68,0,1.77-.56,3-2.33,5.06-.78.92-1.91,2.18-2.68,3h3.43c1,0,1.29-.1,1.92-1.43Z" />
        <path
          d="M644.86,578.94a9.29,9.29,0,0,1,0-14.84l.34.42a9.86,9.86,0,0,0-2.62,7,10.07,10.07,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M646.73,567.63l.76-1a2.84,2.84,0,0,1,2.11-.93,2.21,2.21,0,0,1,2.45,2.15,1.7,1.7,0,0,1-.5,1.17,7,7,0,0,1-1.16.87,2.64,2.64,0,0,1,2.41,2.46c0,2.6-3.16,3.71-4.27,3.71a2.61,2.61,0,0,1-1.69-.56.73.73,0,0,1-.27-.55,1,1,0,0,1,.38-.64.33.33,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.36a2,2,0,0,0-2.09-2.05,3.83,3.83,0,0,0-1,.17l-.11-.5c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.48,2.56,2.56,0,0,0-2,1.25Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M657.62,565.68c2.45,0,3.35,2.72,3.35,5.17s-.9,5.19-3.35,5.19-3.4-2.69-3.4-5.17S655.14,565.68,657.62,565.68Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.61S659,566.26,657.61,566.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M665.44,565.68c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.69-3.39-5.17S663,565.68,665.44,565.68Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.61S666.8,566.26,665.44,566.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M678.65,575.84v-.46c.89-.1,1-.16,1-1.26v-2.69c0-1.17-.43-1.8-1.33-1.8a2.72,2.72,0,0,0-1.67.77,5.62,5.62,0,0,1,.05.68v3.13c0,1,.12,1.07,1,1.17v.46h-3.46v-.46c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.94,2.94,0,0,0-1.67.76v3.8c0,1,.11,1.09,1,1.19v.46h-3.48v-.46c1-.1,1.11-.17,1.11-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a9.52,9.52,0,0,0,2.33-.64v1.28a13.82,13.82,0,0,1,1.15-.8,2.29,2.29,0,0,1,1.28-.43,1.87,1.87,0,0,1,1.76,1.3c.43-.35.83-.61,1.23-.88a2.38,2.38,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.46Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M689.76,573.62c-.21.75-.49,1.62-.65,2.22H683v-.39c.87-.86,1.83-1.8,2.62-2.71a6.3,6.3,0,0,0,1.92-3.83c0-1.29-.67-2.06-1.83-2.06a2.76,2.76,0,0,0-2.2,1.48l-.39-.35.93-1.33a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.78c0,1.35-.49,2.3-2,3.84-.68.7-1.67,1.65-2.33,2.27h3c.88,0,1.11-.07,1.67-1.08Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M691.29,564.1a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.89,9.89,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M650.79,455.41h5V456c-1.41.16-1.66.3-1.66,2.16v3.62a2.82,2.82,0,0,0,1.35-1c1.06-1.17,2.22-2.56,3-3.6.53-.71.47-1-.33-1.12l-.49-.06v-.62h4.53V456a3.79,3.79,0,0,0-3.09,1.75l-3,3.44c1.36,1.9,3.29,4.52,4.32,5.84.9,1.14,1.13,1.3,2.24,1.46v.62h-3.07c-1.3-1.72-2.81-3.86-4.42-6.08-.41-.6-.72-.66-1-.63v4c0,1.83.16,2,1.67,2.13v.62h-5v-.62c1.48-.16,1.64-.3,1.64-2.13v-8.2c0-1.88-.14-2-1.64-2.16Z" />
        <path d="M665.41,469.43c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,665.41,469.43Z" />
        <path d="M674.25,465.33v1.55c0,1.37.07,1.49,1.4,1.64v.62h-4.46v-.62c1.36-.15,1.44-.3,1.44-1.67v-1.52H667.8v-.68c1.79-2.89,3.74-6,5.52-8.85h.93v8.47H676v1.06Zm-1.62-7.19c-1.11,1.85-2.39,4-3.65,6.13h3.65Z" />
        <path d="M685,456.26c-1.77,4.28-3.5,8.55-5.26,12.92l-1.41.16-.13-.24c1.93-3.58,3.92-7.85,5.39-11.43h-3.92c-1.2,0-1.29.19-1.79,1.56h-.59c.2-1.28.28-2.34.38-3.25h7.21Z" />
        <path
          d="M644.86,493.1a9.29,9.29,0,0,1,0-14.84l.34.42a9.88,9.88,0,0,0-2.62,7,10.07,10.07,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M646.73,481.79l.76-1a2.84,2.84,0,0,1,2.11-.93,2.21,2.21,0,0,1,2.45,2.15,1.74,1.74,0,0,1-.5,1.18,7,7,0,0,1-1.16.86,2.65,2.65,0,0,1,2.41,2.46c0,2.6-3.16,3.71-4.27,3.71a2.61,2.61,0,0,1-1.69-.56.72.72,0,0,1-.27-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.35A2,2,0,0,0,649.2,485a3.85,3.85,0,0,0-1,.16l-.11-.5c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.48,2.54,2.54,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M657.62,479.84c2.45,0,3.35,2.72,3.35,5.17s-.9,5.19-3.35,5.19-3.4-2.68-3.4-5.17C654.22,482.69,655.14,479.84,657.62,479.84Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.6S659,480.42,657.61,480.42Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M665.44,479.84c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.68-3.39-5.17C662.05,482.69,663,479.84,665.44,479.84Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.6S666.8,480.42,665.44,480.42Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M678.65,490v-.47c.89-.1,1-.16,1-1.25V485.6c0-1.18-.43-1.81-1.33-1.81a2.72,2.72,0,0,0-1.67.77,5.65,5.65,0,0,1,.05.69v3.13c0,1,.12,1.06,1,1.16V490h-3.46v-.47c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.09,1,1.19V490h-3.48v-.47c1-.1,1.11-.16,1.11-1.2V485c0-1-.06-1.08-.93-1.23v-.42a9.52,9.52,0,0,0,2.33-.64V484a13.82,13.82,0,0,1,1.15-.8,2.29,2.29,0,0,1,1.28-.43,1.87,1.87,0,0,1,1.76,1.3c.43-.34.83-.61,1.23-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19V490Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M689.76,487.78c-.21.75-.49,1.62-.65,2.23H683v-.4c.87-.86,1.83-1.8,2.62-2.71a6.3,6.3,0,0,0,1.92-3.83c0-1.29-.67-2.05-1.83-2.05a2.75,2.75,0,0,0-2.2,1.47l-.39-.35.93-1.33a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.83-.68.7-1.67,1.65-2.33,2.28h3c.88,0,1.11-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M691.29,478.26a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.87,9.87,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M587.78,541.24h5v.63c-1.42.16-1.66.3-1.66,2.16v3.62a2.81,2.81,0,0,0,1.34-1c1.07-1.18,2.22-2.56,3-3.61.54-.7.47-1-.33-1.11l-.48-.06v-.63h4.52v.63a3.79,3.79,0,0,0-3.09,1.75l-3,3.44c1.35,1.89,3.28,4.52,4.32,5.84.89,1.14,1.13,1.3,2.23,1.45V555h-3.07c-1.3-1.72-2.81-3.87-4.41-6.09-.42-.6-.73-.65-1-.62v4c0,1.83.15,2,1.66,2.12V555h-5v-.63c1.49-.15,1.64-.29,1.64-2.12V544c0-1.88-.14-2-1.64-2.16Z" />
        <path d="M602.4,555.27a1.27,1.27,0,0,1-1.18-1.43,1.29,1.29,0,0,1,1.18-1.43,1.31,1.31,0,0,1,1.22,1.43A1.29,1.29,0,0,1,602.4,555.27Z" />
        <path d="M612,541.81l.11.18-.49,1.52h-4.29c-.17,1.12-.41,2.72-.57,3.73a7.42,7.42,0,0,1,2.21-.62,3.44,3.44,0,0,1,3.49,3.58,4.72,4.72,0,0,1-1.63,3.57,5.37,5.37,0,0,1-3.29,1.46,2.64,2.64,0,0,1-1.92-.8.78.78,0,0,1-.32-.74,1.22,1.22,0,0,1,.41-.79.38.38,0,0,1,.55,0,3.15,3.15,0,0,0,2.34,1.19c1.47,0,2.16-1.46,2.16-3,0-1.87-1-3.18-3-3.18a5.08,5.08,0,0,0-1.85.37l-.06-.09c.29-1.7.7-4.63.91-6.37Z" />
        <path d="M614.42,544.13l.87-1.34a3.09,3.09,0,0,1,2.42-1.23,2.71,2.71,0,0,1,2.82,2.83A2.41,2.41,0,0,1,620,546a7.58,7.58,0,0,1-1.32,1.14,3.32,3.32,0,0,1,2.75,3.25c0,3.43-3.62,4.89-4.9,4.89a2.76,2.76,0,0,1-1.93-.73,1,1,0,0,1-.31-.73,1.31,1.31,0,0,1,.44-.84.36.36,0,0,1,.53,0,3.22,3.22,0,0,0,2.29,1.17c1.26,0,2.15-1.08,2.15-3.11a2.41,2.41,0,0,0-2.4-2.71,4.09,4.09,0,0,0-1.19.21l-.13-.65c1.65-.62,2.9-1.44,2.9-3,0-1.26-.7-2-1.72-2a2.91,2.91,0,0,0-2.29,1.66Z" />
        <path
          d="M580.85,578.94a9.29,9.29,0,0,1,0-14.84l.34.42a9.86,9.86,0,0,0-2.62,7,10.07,10.07,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M582.72,567.63l.76-1a2.84,2.84,0,0,1,2.11-.93,2.21,2.21,0,0,1,2.45,2.15,1.7,1.7,0,0,1-.5,1.17,7,7,0,0,1-1.16.87,2.64,2.64,0,0,1,2.41,2.46c0,2.6-3.16,3.71-4.27,3.71a2.61,2.61,0,0,1-1.69-.56.73.73,0,0,1-.27-.55,1,1,0,0,1,.38-.64.33.33,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.36a2,2,0,0,0-2.09-2.05,3.83,3.83,0,0,0-1,.17l-.11-.5c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.48,2.56,2.56,0,0,0-2,1.25Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M593.61,565.68c2.45,0,3.35,2.72,3.35,5.17s-.9,5.19-3.35,5.19-3.4-2.69-3.4-5.17S591.13,565.68,593.61,565.68Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.61S595,566.26,593.6,566.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M601.43,565.68c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.69-3.39-5.17S599,565.68,601.43,565.68Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.61S602.79,566.26,601.43,566.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M614.64,575.84v-.46c.89-.1,1-.16,1-1.26v-2.69c0-1.17-.43-1.8-1.33-1.8a2.72,2.72,0,0,0-1.67.77,5.62,5.62,0,0,1,.05.68v3.13c0,1,.12,1.07,1,1.17v.46h-3.46v-.46c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.88,2.88,0,0,0-1.66.76v3.8c0,1,.1,1.09,1,1.19v.46h-3.48v-.46c1-.1,1.11-.17,1.11-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a9.72,9.72,0,0,0,2.34-.64v1.28a12,12,0,0,1,1.14-.8,2.29,2.29,0,0,1,1.28-.43,1.87,1.87,0,0,1,1.76,1.3c.43-.35.83-.61,1.23-.88a2.38,2.38,0,0,1,1.27-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.46Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M625.75,573.62c-.21.75-.49,1.62-.65,2.22H619v-.39c.87-.86,1.83-1.8,2.62-2.71a6.3,6.3,0,0,0,1.92-3.83c0-1.29-.67-2.06-1.83-2.06a2.76,2.76,0,0,0-2.2,1.48l-.39-.35.93-1.33a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.78c0,1.35-.49,2.3-2,3.84-.68.7-1.67,1.65-2.33,2.27h3c.88,0,1.12-.07,1.67-1.08Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M627.28,564.1a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.89,9.89,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M587.78,455.41h5V456c-1.42.16-1.66.3-1.66,2.16v3.62a2.81,2.81,0,0,0,1.34-1c1.07-1.17,2.22-2.56,3-3.6.54-.71.47-1-.33-1.12l-.48-.06v-.62h4.52V456a3.79,3.79,0,0,0-3.09,1.75l-3,3.44c1.35,1.9,3.28,4.52,4.32,5.84.89,1.14,1.13,1.3,2.23,1.46v.62h-3.07c-1.3-1.72-2.81-3.86-4.41-6.08-.42-.6-.73-.66-1-.63v4c0,1.83.15,2,1.66,2.13v.62h-5v-.62c1.49-.16,1.64-.3,1.64-2.13v-8.2c0-1.88-.14-2-1.64-2.16Z" />
        <path d="M602.4,469.43c-.69,0-1.18-.58-1.18-1.43a1.28,1.28,0,0,1,1.18-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,602.4,469.43Z" />
        <path d="M611.24,465.33v1.55c0,1.37.07,1.49,1.4,1.64v.62h-4.46v-.62c1.36-.15,1.44-.3,1.44-1.67v-1.52H604.8v-.68c1.78-2.89,3.73-6,5.51-8.85h.93v8.47H613v1.06Zm-1.62-7.19c-1.1,1.85-2.39,4-3.65,6.13h3.65Z" />
        <path d="M617.32,461.15a2.38,2.38,0,0,1,1.18-.37c2,0,3.31,1.72,3.31,3.92,0,2.56-1.61,4.7-3.86,4.7s-3.88-2-3.88-5.31a8.49,8.49,0,0,1,5.07-7.93,14,14,0,0,1,2.19-.56l.11.72a9.56,9.56,0,0,0-2,.73,6.45,6.45,0,0,0-3.31,4.78Zm.38.89a3.24,3.24,0,0,0-1.67.53,8,8,0,0,0-.12,1.49c0,2.67.86,4.55,2.35,4.55s1.79-1.72,1.79-3.12C620.05,463.69,619.29,462,617.7,462Z" />
        <path
          d="M581.85,493.1a9.29,9.29,0,0,1,0-14.84l.34.42a9.88,9.88,0,0,0-2.62,7,10.07,10.07,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M583.72,481.79l.76-1a2.84,2.84,0,0,1,2.11-.93A2.21,2.21,0,0,1,589,482a1.74,1.74,0,0,1-.5,1.18,7,7,0,0,1-1.16.86,2.65,2.65,0,0,1,2.41,2.46c0,2.6-3.16,3.71-4.27,3.71a2.61,2.61,0,0,1-1.69-.56.72.72,0,0,1-.27-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.35a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16l-.11-.5c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.48,2.54,2.54,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M594.61,479.84c2.45,0,3.35,2.72,3.35,5.17s-.9,5.19-3.35,5.19-3.4-2.68-3.4-5.17C591.21,482.69,592.13,479.84,594.61,479.84Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.6S596,480.42,594.6,480.42Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M602.43,479.84c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19S599,487.52,599,485C599,482.69,600,479.84,602.43,479.84Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.6S603.79,480.42,602.43,480.42Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M615.64,490v-.47c.89-.1,1-.16,1-1.25V485.6c0-1.18-.43-1.81-1.33-1.81a2.72,2.72,0,0,0-1.67.77,5.65,5.65,0,0,1,.05.69v3.13c0,1,.12,1.06,1,1.16V490h-3.46v-.47c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.84,2.84,0,0,0-1.66.77v3.79c0,1,.1,1.09,1,1.19V490h-3.48v-.47c1-.1,1.11-.16,1.11-1.2V485c0-1-.06-1.08-.93-1.23v-.42a9.72,9.72,0,0,0,2.34-.64V484a12,12,0,0,1,1.14-.8,2.29,2.29,0,0,1,1.28-.43,1.87,1.87,0,0,1,1.76,1.3c.43-.34.83-.61,1.23-.88a2.46,2.46,0,0,1,1.27-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19V490Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M626.75,487.78c-.21.75-.49,1.62-.65,2.23H620v-.4c.87-.86,1.83-1.8,2.62-2.71a6.3,6.3,0,0,0,1.92-3.83c0-1.29-.67-2.05-1.83-2.05a2.75,2.75,0,0,0-2.2,1.47l-.39-.35.93-1.33a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.83-.68.7-1.67,1.65-2.33,2.28h3c.88,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M628.28,478.26a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.87,9.87,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M524.15,541.24h5v.63c-1.41.16-1.66.3-1.66,2.16v3.62a2.78,2.78,0,0,0,1.35-1c1.07-1.18,2.22-2.56,3-3.61.53-.7.47-1-.33-1.11l-.49-.06v-.63h4.53v.63a3.79,3.79,0,0,0-3.09,1.75l-3,3.44c1.36,1.89,3.29,4.52,4.33,5.84.89,1.14,1.13,1.3,2.23,1.45V555H533c-1.3-1.72-2.81-3.87-4.42-6.09-.41-.6-.72-.65-1-.62v4c0,1.83.16,2,1.67,2.12V555h-5v-.63c1.48-.15,1.64-.29,1.64-2.12V544c0-1.88-.14-2-1.64-2.16Z" />
        <path d="M538.77,555.27a1.27,1.27,0,0,1-1.18-1.43,1.29,1.29,0,0,1,1.18-1.43,1.31,1.31,0,0,1,1.22,1.43A1.29,1.29,0,0,1,538.77,555.27Z" />
        <path d="M548.4,541.81l.11.18-.49,1.52h-4.29c-.17,1.12-.41,2.72-.57,3.73a7.42,7.42,0,0,1,2.21-.62,3.44,3.44,0,0,1,3.49,3.58,4.72,4.72,0,0,1-1.63,3.57,5.37,5.37,0,0,1-3.29,1.46,2.62,2.62,0,0,1-1.92-.8.82.82,0,0,1-.33-.74,1.24,1.24,0,0,1,.42-.79.38.38,0,0,1,.55,0,3.15,3.15,0,0,0,2.34,1.19c1.47,0,2.16-1.46,2.16-3,0-1.87-1-3.18-3-3.18a5.08,5.08,0,0,0-1.85.37l-.06-.09c.29-1.7.7-4.63.91-6.37Z" />
        <path d="M556.59,551.16v1.56c0,1.37.08,1.49,1.4,1.63V555h-4.46v-.63c1.36-.14,1.45-.29,1.45-1.67v-1.52h-4.83v-.67c1.78-2.89,3.73-6,5.52-8.86h.92v8.48h1.79v1ZM555,544c-1.11,1.85-2.39,4-3.65,6.13H555Z" />
        <path
          d="M517.22,578.94a9.29,9.29,0,0,1,0-14.84l.34.42a9.86,9.86,0,0,0-2.62,7,10.07,10.07,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M519.09,567.63l.76-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.71,1.71,0,0,1-.51,1.17,7.26,7.26,0,0,1-1.15.87,2.64,2.64,0,0,1,2.4,2.46c0,2.6-3.16,3.71-4.27,3.71a2.63,2.63,0,0,1-1.69-.56.72.72,0,0,1-.26-.55,1,1,0,0,1,.38-.64.32.32,0,0,1,.45,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.36a2,2,0,0,0-2.09-2.05,3.83,3.83,0,0,0-1,.17l-.11-.5c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.48,2.56,2.56,0,0,0-2,1.25Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M530,565.68c2.45,0,3.35,2.72,3.35,5.17S532.43,576,530,576s-3.4-2.69-3.4-5.17S527.5,565.68,530,565.68Zm0,.58c-1.42,0-1.76,2.35-1.76,4.57s.42,4.62,1.77,4.62,1.73-2.37,1.73-4.61S531.33,566.26,530,566.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M537.8,565.68c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.69-3.39-5.17S535.33,565.68,537.8,565.68Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.61S539.16,566.26,537.8,566.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M551,575.84v-.46c.89-.1,1-.16,1-1.26v-2.69c0-1.17-.43-1.8-1.33-1.8a2.74,2.74,0,0,0-1.67.77,5.62,5.62,0,0,1,0,.68v3.13c0,1,.12,1.07,1,1.17v.46h-3.46v-.46c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.88,2.88,0,0,0-1.66.76v3.8c0,1,.11,1.09,1,1.19v.46h-3.48v-.46c1-.1,1.11-.17,1.11-1.2v-3.33c0-1,0-1.08-.92-1.23v-.42a9.77,9.77,0,0,0,2.33-.64v1.28a13.5,13.5,0,0,1,1.14-.8,2.29,2.29,0,0,1,1.29-.43,1.86,1.86,0,0,1,1.75,1.3c.44-.35.83-.61,1.23-.88a2.4,2.4,0,0,1,1.27-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.46Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M562.12,573.62c-.21.75-.49,1.62-.65,2.22h-6.11v-.39c.87-.86,1.83-1.8,2.62-2.71,1-1.22,1.93-2.46,1.93-3.83s-.68-2.06-1.84-2.06a2.78,2.78,0,0,0-2.2,1.48l-.39-.35.93-1.33a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.78c0,1.35-.49,2.3-2,3.84-.68.7-1.67,1.65-2.33,2.27h3c.88,0,1.12-.07,1.67-1.08Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M563.65,564.1a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.85,9.85,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M524.15,455.41h5V456c-1.41.16-1.66.3-1.66,2.16v3.62a2.82,2.82,0,0,0,1.35-1c1.06-1.17,2.22-2.56,3-3.6.53-.71.47-1-.33-1.12l-.49-.06v-.62h4.53V456a3.79,3.79,0,0,0-3.09,1.75l-3,3.44c1.36,1.9,3.29,4.52,4.33,5.84.89,1.14,1.12,1.3,2.23,1.46v.62H533c-1.3-1.72-2.81-3.86-4.42-6.08-.41-.6-.72-.66-1-.63v4c0,1.83.16,2,1.67,2.13v.62h-5v-.62c1.48-.16,1.64-.3,1.64-2.13v-8.2c0-1.88-.14-2-1.64-2.16Z" />
        <path d="M538.77,469.43c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42A1.3,1.3,0,0,1,540,468,1.29,1.29,0,0,1,538.77,469.43Z" />
        <path d="M547.61,465.33v1.55c0,1.37.07,1.49,1.4,1.64v.62h-4.46v-.62c1.36-.15,1.44-.3,1.44-1.67v-1.52h-4.82v-.68c1.78-2.89,3.73-6,5.51-8.85h.93v8.47h1.78v1.06ZM546,458.14c-1.11,1.85-2.39,4-3.65,6.13H546Z" />
        <path d="M557.39,456l.11.17-.5,1.52h-4.29c-.16,1.12-.4,2.72-.57,3.73a7.78,7.78,0,0,1,2.22-.62,3.44,3.44,0,0,1,3.48,3.58,4.68,4.68,0,0,1-1.63,3.57,5.3,5.3,0,0,1-3.29,1.47,2.64,2.64,0,0,1-1.92-.81.81.81,0,0,1-.32-.74,1.22,1.22,0,0,1,.42-.79.38.38,0,0,1,.55,0,3.1,3.1,0,0,0,2.33,1.18c1.47,0,2.17-1.45,2.17-3,0-1.86-1-3.18-3-3.18a4.91,4.91,0,0,0-1.85.38l-.06-.1c.29-1.69.7-4.62.91-6.36Z" />
        <path
          d="M518.22,493.1a9.29,9.29,0,0,1,0-14.84l.34.42a9.88,9.88,0,0,0-2.62,7,10.07,10.07,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M520.09,481.79l.76-1a2.84,2.84,0,0,1,2.11-.93,2.21,2.21,0,0,1,2.45,2.15,1.74,1.74,0,0,1-.5,1.18,7,7,0,0,1-1.16.86,2.65,2.65,0,0,1,2.41,2.46c0,2.6-3.16,3.71-4.27,3.71a2.63,2.63,0,0,1-1.69-.56.72.72,0,0,1-.27-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.35a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16l-.11-.5c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.48,2.54,2.54,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M531,479.84c2.45,0,3.35,2.72,3.35,5.17s-.9,5.19-3.35,5.19-3.4-2.68-3.4-5.17C527.58,482.69,528.5,479.84,531,479.84Zm0,.58c-1.42,0-1.76,2.35-1.76,4.57s.42,4.62,1.77,4.62,1.73-2.37,1.73-4.6S532.33,480.42,531,480.42Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M538.8,479.84c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.68-3.39-5.17C535.41,482.69,536.33,479.84,538.8,479.84Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.6S540.16,480.42,538.8,480.42Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M552,490v-.47c.89-.1,1-.16,1-1.25V485.6c0-1.18-.43-1.81-1.33-1.81a2.74,2.74,0,0,0-1.67.77,5.65,5.65,0,0,1,0,.69v3.13c0,1,.12,1.06,1,1.16V490h-3.46v-.47c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.84,2.84,0,0,0-1.66.77v3.79c0,1,.1,1.09,1,1.19V490h-3.48v-.47c1-.1,1.11-.16,1.11-1.2V485c0-1-.06-1.08-.93-1.23v-.42a9.72,9.72,0,0,0,2.34-.64V484a12,12,0,0,1,1.14-.8,2.29,2.29,0,0,1,1.28-.43,1.86,1.86,0,0,1,1.76,1.3c.43-.34.83-.61,1.23-.88a2.46,2.46,0,0,1,1.27-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19V490Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M563.12,487.78c-.21.75-.49,1.62-.65,2.23h-6.11v-.4c.87-.86,1.83-1.8,2.62-2.71a6.3,6.3,0,0,0,1.92-3.83c0-1.29-.67-2.05-1.83-2.05a2.75,2.75,0,0,0-2.2,1.47l-.39-.35.93-1.33a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.83-.68.7-1.67,1.65-2.33,2.28h3c.88,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M564.65,478.26a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.87,9.87,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M462.79,541.24h5v.63c-1.42.16-1.66.3-1.66,2.16v3.62a2.81,2.81,0,0,0,1.34-1c1.07-1.18,2.22-2.56,3-3.61.54-.7.47-1-.33-1.11l-.48-.06v-.63h4.52v.63a3.8,3.8,0,0,0-3.09,1.75l-3,3.44c1.35,1.89,3.28,4.52,4.32,5.84.89,1.14,1.13,1.3,2.23,1.45V555h-3.07c-1.3-1.72-2.81-3.87-4.41-6.09-.42-.6-.73-.65-1-.62v4c0,1.83.16,2,1.66,2.12V555h-5v-.63c1.49-.15,1.64-.29,1.64-2.12V544c0-1.88-.14-2-1.64-2.16Z" />
        <path d="M477.41,555.27a1.27,1.27,0,0,1-1.18-1.43,1.29,1.29,0,0,1,1.18-1.43,1.31,1.31,0,0,1,1.22,1.43A1.28,1.28,0,0,1,477.41,555.27Z" />
        <path d="M487,541.81l.12.18-.5,1.52h-4.29c-.17,1.12-.41,2.72-.57,3.73a7.42,7.42,0,0,1,2.21-.62,3.44,3.44,0,0,1,3.49,3.58,4.68,4.68,0,0,1-1.63,3.57,5.37,5.37,0,0,1-3.29,1.46,2.64,2.64,0,0,1-1.92-.8.81.81,0,0,1-.32-.74,1.22,1.22,0,0,1,.41-.79.39.39,0,0,1,.56,0,3.11,3.11,0,0,0,2.33,1.19c1.47,0,2.17-1.46,2.17-3,0-1.87-1-3.18-3-3.18a5.08,5.08,0,0,0-1.85.37l-.06-.09c.29-1.7.7-4.63.91-6.37Z" />
        <path d="M496,541.81l.11.18-.5,1.52h-4.29c-.16,1.12-.4,2.72-.57,3.73a7.47,7.47,0,0,1,2.22-.62,3.43,3.43,0,0,1,3.48,3.58,4.7,4.7,0,0,1-1.62,3.57,5.39,5.39,0,0,1-3.3,1.46,2.64,2.64,0,0,1-1.92-.8.81.81,0,0,1-.32-.74,1.19,1.19,0,0,1,.42-.79.38.38,0,0,1,.55,0,3.15,3.15,0,0,0,2.33,1.19c1.47,0,2.17-1.46,2.17-3,0-1.87-1-3.18-3-3.18a5.12,5.12,0,0,0-1.85.37l-.06-.09c.29-1.7.71-4.63.92-6.37Z" />
        <path
          d="M455.86,578.94a9.31,9.31,0,0,1,0-14.84l.34.42a9.86,9.86,0,0,0-2.62,7,10.07,10.07,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M457.73,567.63l.76-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.67,1.67,0,0,1-.51,1.17,6.82,6.82,0,0,1-1.15.87,2.63,2.63,0,0,1,2.4,2.46c0,2.6-3.16,3.71-4.27,3.71a2.63,2.63,0,0,1-1.69-.56.72.72,0,0,1-.26-.55,1,1,0,0,1,.38-.64.33.33,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.36a2,2,0,0,0-2.09-2.05,3.83,3.83,0,0,0-1,.17l-.11-.5c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.48,2.56,2.56,0,0,0-2,1.25Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M468.62,565.68c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.69-3.4-5.17S466.14,565.68,468.62,565.68Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.61S470,566.26,468.61,566.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M476.45,565.68c2.45,0,3.35,2.72,3.35,5.17s-.9,5.19-3.35,5.19-3.4-2.69-3.4-5.17S474,565.68,476.45,565.68Zm0,.58c-1.42,0-1.76,2.35-1.76,4.57s.42,4.62,1.77,4.62,1.73-2.37,1.73-4.61S477.8,566.26,476.44,566.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M489.65,575.84v-.46c.89-.1,1-.16,1-1.26v-2.69c0-1.17-.42-1.8-1.33-1.8a2.74,2.74,0,0,0-1.67.77,5.62,5.62,0,0,1,.05.68v3.13c0,1,.13,1.07,1,1.17v.46h-3.46v-.46c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.94,2.94,0,0,0-1.66.76v3.8c0,1,.11,1.09,1,1.19v.46h-3.48v-.46c1-.1,1.11-.17,1.11-1.2v-3.33c0-1-.05-1.08-.92-1.23v-.42a9.65,9.65,0,0,0,2.33-.64v1.28a12.32,12.32,0,0,1,1.15-.8,2.24,2.24,0,0,1,1.28-.43,1.86,1.86,0,0,1,1.75,1.3c.44-.35.84-.61,1.24-.88a2.37,2.37,0,0,1,1.26-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.46Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M500.77,573.62c-.22.75-.5,1.62-.66,2.22H494v-.39c.87-.86,1.83-1.8,2.62-2.71,1-1.22,1.93-2.46,1.93-3.83s-.68-2.06-1.84-2.06a2.79,2.79,0,0,0-2.2,1.48l-.39-.35.94-1.33a2.91,2.91,0,0,1,2.23-1,2.65,2.65,0,0,1,2.77,2.78c0,1.35-.5,2.3-2,3.84-.68.7-1.66,1.65-2.33,2.27h3c.89,0,1.12-.07,1.67-1.08Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M502.29,564.1a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.89,9.89,0,0,0,2.63-7,10.07,10.07,0,0,0-2.63-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M461.79,455.41h5V456c-1.42.16-1.66.3-1.66,2.16v3.62a2.81,2.81,0,0,0,1.34-1c1.07-1.17,2.22-2.56,3-3.6.53-.71.46-1-.34-1.12l-.48-.06v-.62h4.52V456a3.8,3.8,0,0,0-3.09,1.75l-3,3.44c1.35,1.9,3.28,4.52,4.32,5.84.89,1.14,1.13,1.3,2.23,1.46v.62h-3.07c-1.3-1.72-2.81-3.86-4.41-6.08-.42-.6-.73-.66-1-.63v4c0,1.83.16,2,1.66,2.13v.62h-5v-.62c1.49-.16,1.64-.3,1.64-2.13v-8.2c0-1.88-.14-2-1.64-2.16Z" />
        <path d="M476.42,469.43c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.31,1.31,0,0,1,1.22,1.42A1.29,1.29,0,0,1,476.42,469.43Z" />
        <path d="M485.25,465.33v1.55c0,1.37.08,1.49,1.4,1.64v.62h-4.46v-.62c1.36-.15,1.45-.3,1.45-1.67v-1.52h-4.83v-.68c1.78-2.89,3.73-6,5.52-8.85h.92v8.47H487v1.06Zm-1.61-7.19c-1.11,1.85-2.39,4-3.66,6.13h3.66Z" />
        <path d="M494.23,465.33v1.55c0,1.37.08,1.49,1.41,1.64v.62h-4.47v-.62c1.37-.15,1.45-.3,1.45-1.67v-1.52h-4.83v-.68c1.79-2.89,3.73-6,5.52-8.85h.92v8.47H496v1.06Zm-1.61-7.19c-1.11,1.85-2.39,4-3.65,6.13h3.65Z" />
        <path
          d="M455.86,493.1a9.31,9.31,0,0,1,0-14.84l.34.42a9.88,9.88,0,0,0-2.62,7,10.12,10.12,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M457.73,481.79l.76-1a2.86,2.86,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.71,1.71,0,0,1-.51,1.18,6.84,6.84,0,0,1-1.15.86,2.64,2.64,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.66,2.66,0,0,1-1.69-.56.7.7,0,0,1-.26-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.35A2,2,0,0,0,460.2,485a3.85,3.85,0,0,0-1,.16l-.11-.5c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.48,2.54,2.54,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M468.62,479.84c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17C465.22,482.69,466.14,479.84,468.62,479.84Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.6S470,480.42,468.61,480.42Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M476.45,479.84c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17C473.05,482.69,474,479.84,476.45,479.84Zm0,.58c-1.42,0-1.76,2.35-1.76,4.57s.42,4.62,1.77,4.62,1.74-2.37,1.74-4.6S477.8,480.42,476.44,480.42Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M489.65,490v-.47c.89-.1,1-.16,1-1.25V485.6c0-1.18-.43-1.81-1.33-1.81a2.7,2.7,0,0,0-1.67.77c0,.19,0,.39,0,.69v3.13c0,1,.13,1.06,1,1.16V490h-3.46v-.47c.95-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.66.77v3.79c0,1,.11,1.09,1,1.19V490h-3.48v-.47c1-.1,1.11-.16,1.11-1.2V485c0-1-.05-1.08-.92-1.23v-.42a9.52,9.52,0,0,0,2.33-.64V484a12.32,12.32,0,0,1,1.15-.8,2.24,2.24,0,0,1,1.28-.43,1.86,1.86,0,0,1,1.75,1.3c.44-.34.84-.61,1.24-.88a2.46,2.46,0,0,1,1.26-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19V490Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M500.77,487.78c-.22.75-.5,1.62-.66,2.23H494v-.4c.87-.86,1.83-1.8,2.62-2.71,1-1.22,1.93-2.46,1.93-3.83s-.68-2.05-1.84-2.05a2.78,2.78,0,0,0-2.2,1.47l-.39-.35.94-1.33a2.91,2.91,0,0,1,2.23-1,2.66,2.66,0,0,1,2.77,2.79c0,1.34-.5,2.29-2,3.83-.67.7-1.66,1.65-2.33,2.28h3c.89,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M502.29,478.26a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.87,9.87,0,0,0,2.63-7,10,10,0,0,0-2.63-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M398.6,541.24h5v.63c-1.42.16-1.66.3-1.66,2.16v3.62a2.81,2.81,0,0,0,1.34-1c1.07-1.18,2.22-2.56,3-3.61.54-.7.47-1-.33-1.11l-.48-.06v-.63H410v.63a3.79,3.79,0,0,0-3.09,1.75l-3,3.44c1.35,1.89,3.28,4.52,4.32,5.84.89,1.14,1.13,1.3,2.23,1.45V555H407.4c-1.3-1.72-2.81-3.87-4.41-6.09-.42-.6-.73-.65-1-.62v4c0,1.83.16,2,1.66,2.12V555h-5v-.63c1.49-.15,1.64-.29,1.64-2.12V544c0-1.88-.14-2-1.64-2.16Z" />
        <path d="M413.22,555.27a1.27,1.27,0,0,1-1.18-1.43,1.29,1.29,0,0,1,1.18-1.43,1.31,1.31,0,0,1,1.22,1.43A1.29,1.29,0,0,1,413.22,555.27Z" />
        <path d="M422.85,541.81l.11.18-.49,1.52h-4.29c-.17,1.12-.41,2.72-.57,3.73a7.42,7.42,0,0,1,2.21-.62,3.44,3.44,0,0,1,3.49,3.58,4.72,4.72,0,0,1-1.63,3.57,5.37,5.37,0,0,1-3.29,1.46,2.64,2.64,0,0,1-1.92-.8.81.81,0,0,1-.32-.74,1.22,1.22,0,0,1,.41-.79.39.39,0,0,1,.56,0,3.11,3.11,0,0,0,2.33,1.19c1.47,0,2.17-1.46,2.17-3,0-1.87-1-3.18-3-3.18a5.08,5.08,0,0,0-1.85.37l-.06-.09c.29-1.7.7-4.63.91-6.37Z" />
        <path d="M428.14,547a2.38,2.38,0,0,1,1.18-.37c2,0,3.31,1.72,3.31,3.92,0,2.56-1.61,4.69-3.86,4.69s-3.88-2-3.88-5.3A8.49,8.49,0,0,1,430,542a13.89,13.89,0,0,1,2.19-.57l.11.73a9.52,9.52,0,0,0-2,.72,6.47,6.47,0,0,0-3.31,4.79Zm.38.89a3.24,3.24,0,0,0-1.67.53,8,8,0,0,0-.12,1.48c0,2.67.86,4.56,2.35,4.56s1.79-1.72,1.79-3.12C430.87,549.53,430.11,547.88,428.52,547.88Z" />
        <path
          d="M391.67,578.94a9.29,9.29,0,0,1,0-14.84l.34.42a9.86,9.86,0,0,0-2.62,7,10.07,10.07,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M393.54,567.63l.76-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.67,1.67,0,0,1-.51,1.17,6.82,6.82,0,0,1-1.15.87,2.64,2.64,0,0,1,2.4,2.46c0,2.6-3.16,3.71-4.27,3.71a2.63,2.63,0,0,1-1.69-.56.72.72,0,0,1-.26-.55,1,1,0,0,1,.38-.64.33.33,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.36a2,2,0,0,0-2.09-2.05,3.83,3.83,0,0,0-1,.17l-.11-.5c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.48,2.56,2.56,0,0,0-2,1.25Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M404.43,565.68c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.69-3.4-5.17S402,565.68,404.43,565.68Zm0,.58c-1.42,0-1.76,2.35-1.76,4.57s.42,4.62,1.77,4.62,1.74-2.37,1.74-4.61S405.78,566.26,404.42,566.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M412.26,565.68c2.45,0,3.35,2.72,3.35,5.17s-.9,5.19-3.35,5.19-3.4-2.69-3.4-5.17S409.78,565.68,412.26,565.68Zm0,.58c-1.42,0-1.76,2.35-1.76,4.57s.42,4.62,1.77,4.62,1.73-2.37,1.73-4.61S413.61,566.26,412.25,566.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M425.46,575.84v-.46c.89-.1,1-.16,1-1.26v-2.69c0-1.17-.42-1.8-1.33-1.8a2.74,2.74,0,0,0-1.67.77,5.62,5.62,0,0,1,.05.68v3.13c0,1,.13,1.07,1,1.17v.46H421v-.46c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.91,2.91,0,0,0-1.66.76v3.8c0,1,.11,1.09,1,1.19v.46h-3.48v-.46c1-.1,1.11-.17,1.11-1.2v-3.33c0-1-.05-1.08-.92-1.23v-.42a9.65,9.65,0,0,0,2.33-.64v1.28a13.5,13.5,0,0,1,1.14-.8,2.29,2.29,0,0,1,1.29-.43,1.86,1.86,0,0,1,1.75,1.3c.44-.35.84-.61,1.24-.88a2.37,2.37,0,0,1,1.26-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.46Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M436.58,573.62c-.22.75-.5,1.62-.66,2.22h-6.11v-.39c.87-.86,1.83-1.8,2.62-2.71,1-1.22,1.93-2.46,1.93-3.83s-.68-2.06-1.84-2.06a2.79,2.79,0,0,0-2.2,1.48l-.39-.35.94-1.33a2.91,2.91,0,0,1,2.23-1,2.65,2.65,0,0,1,2.77,2.78c0,1.35-.5,2.3-2,3.84-.68.7-1.66,1.65-2.33,2.27h3c.89,0,1.12-.07,1.67-1.08Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M438.1,564.1a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.89,9.89,0,0,0,2.63-7,10.07,10.07,0,0,0-2.63-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M398.6,455.41h5V456c-1.42.16-1.66.3-1.66,2.16v3.62a2.81,2.81,0,0,0,1.34-1c1.07-1.17,2.22-2.56,3-3.6.54-.71.47-1-.33-1.12l-.48-.06v-.62H410V456a3.79,3.79,0,0,0-3.09,1.75l-3,3.44c1.35,1.9,3.28,4.52,4.32,5.84.89,1.14,1.13,1.3,2.23,1.46v.62H407.4c-1.3-1.72-2.81-3.86-4.41-6.08-.42-.6-.73-.66-1-.63v4c0,1.83.16,2,1.66,2.13v.62h-5v-.62c1.49-.16,1.64-.3,1.64-2.13v-8.2c0-1.88-.14-2-1.64-2.16Z" />
        <path d="M413.22,469.43c-.69,0-1.18-.58-1.18-1.43a1.28,1.28,0,0,1,1.18-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,413.22,469.43Z" />
        <path d="M422.06,465.33v1.55c0,1.37.07,1.49,1.4,1.64v.62H419v-.62c1.36-.15,1.44-.3,1.44-1.67v-1.52h-4.82v-.68c1.78-2.89,3.73-6,5.51-8.85h.93v8.47h1.78v1.06Zm-1.62-7.19c-1.1,1.85-2.39,4-3.65,6.13h3.65Z" />
        <path d="M425.24,458.29l.87-1.34a3.09,3.09,0,0,1,2.42-1.23,2.71,2.71,0,0,1,2.82,2.84,2.4,2.4,0,0,1-.58,1.55,7.58,7.58,0,0,1-1.32,1.14,3.32,3.32,0,0,1,2.75,3.25c0,3.43-3.62,4.9-4.9,4.9a2.76,2.76,0,0,1-1.93-.74,1,1,0,0,1-.31-.72,1.37,1.37,0,0,1,.44-.85.35.35,0,0,1,.53,0,3.25,3.25,0,0,0,2.29,1.16c1.26,0,2.15-1.08,2.15-3.11a2.41,2.41,0,0,0-2.4-2.71,3.77,3.77,0,0,0-1.19.22l-.13-.66c1.65-.62,2.9-1.44,2.9-3,0-1.26-.7-2-1.72-2a2.91,2.91,0,0,0-2.29,1.66Z" />
        <path
          d="M392.67,493.1a9.29,9.29,0,0,1,0-14.84l.34.42a9.88,9.88,0,0,0-2.62,7,10.07,10.07,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M394.54,481.79l.76-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.71,1.71,0,0,1-.51,1.18,6.84,6.84,0,0,1-1.15.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.16,3.71-4.27,3.71a2.63,2.63,0,0,1-1.69-.56.7.7,0,0,1-.26-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.35A2,2,0,0,0,397,485a3.85,3.85,0,0,0-1,.16l-.11-.5c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.48,2.54,2.54,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M405.43,479.84c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19S402,487.52,402,485C402,482.69,403,479.84,405.43,479.84Zm0,.58c-1.42,0-1.76,2.35-1.76,4.57s.42,4.62,1.77,4.62,1.74-2.37,1.74-4.6S406.78,480.42,405.42,480.42Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M413.26,479.84c2.45,0,3.35,2.72,3.35,5.17s-.9,5.19-3.35,5.19-3.4-2.68-3.4-5.17C409.86,482.69,410.78,479.84,413.26,479.84Zm0,.58c-1.42,0-1.76,2.35-1.76,4.57s.42,4.62,1.77,4.62S415,487.24,415,485,414.61,480.42,413.25,480.42Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M426.46,490v-.47c.89-.1,1-.16,1-1.25V485.6c0-1.18-.42-1.81-1.33-1.81a2.74,2.74,0,0,0-1.67.77,5.65,5.65,0,0,1,.05.69v3.13c0,1,.13,1.06,1,1.16V490H422v-.47c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.86,2.86,0,0,0-1.66.77v3.79c0,1,.11,1.09,1,1.19V490h-3.48v-.47c1-.1,1.11-.16,1.11-1.2V485c0-1-.05-1.08-.92-1.23v-.42a9.65,9.65,0,0,0,2.33-.64V484a13.5,13.5,0,0,1,1.14-.8,2.29,2.29,0,0,1,1.29-.43,1.86,1.86,0,0,1,1.75,1.3c.44-.34.84-.61,1.24-.88a2.46,2.46,0,0,1,1.26-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19V490Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M437.58,487.78c-.22.75-.5,1.62-.66,2.23h-6.11v-.4c.87-.86,1.83-1.8,2.62-2.71,1-1.22,1.93-2.46,1.93-3.83s-.68-2.05-1.84-2.05a2.78,2.78,0,0,0-2.2,1.47l-.39-.35.94-1.33a2.91,2.91,0,0,1,2.23-1,2.66,2.66,0,0,1,2.77,2.79c0,1.34-.5,2.29-2,3.83-.68.7-1.66,1.65-2.33,2.28h3c.89,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M439.1,478.26a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.87,9.87,0,0,0,2.63-7,10,10,0,0,0-2.63-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M322,541.24h5v.63c-1.41.16-1.66.3-1.66,2.16v3.62a2.76,2.76,0,0,0,1.34-1c1.07-1.18,2.23-2.56,3-3.61.53-.7.46-1-.34-1.11l-.48-.06v-.63h4.53v.63a3.78,3.78,0,0,0-3.09,1.75l-3,3.44c1.35,1.89,3.28,4.52,4.32,5.84.89,1.14,1.13,1.3,2.23,1.45V555h-3.07c-1.29-1.72-2.81-3.87-4.41-6.09-.42-.6-.73-.65-1-.62v4c0,1.83.16,2,1.66,2.12V555h-5v-.63c1.48-.15,1.63-.29,1.63-2.12V544c0-1.88-.14-2-1.63-2.16Z" />
        <path d="M336.65,555.27a1.27,1.27,0,0,1-1.19-1.43,1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.29,1.29,0,0,1,336.65,555.27Z" />
        <path d="M346.28,541.81l.11.18-.5,1.52H341.6c-.16,1.12-.4,2.72-.57,3.73a7.47,7.47,0,0,1,2.22-.62,3.43,3.43,0,0,1,3.48,3.58,4.68,4.68,0,0,1-1.63,3.57,5.34,5.34,0,0,1-3.29,1.46,2.64,2.64,0,0,1-1.92-.8.81.81,0,0,1-.32-.74,1.19,1.19,0,0,1,.42-.79.38.38,0,0,1,.55,0,3.11,3.11,0,0,0,2.33,1.19c1.47,0,2.17-1.46,2.17-3,0-1.87-1-3.18-3-3.18a5.12,5.12,0,0,0-1.85.37l-.06-.09c.29-1.7.7-4.63.92-6.37Z" />
        <path d="M356.2,542.1c-1.76,4.28-3.49,8.55-5.25,12.91l-1.41.17-.13-.24c1.92-3.58,3.91-7.85,5.39-11.43h-3.92c-1.21,0-1.29.18-1.79,1.56h-.59c.19-1.28.28-2.34.37-3.26h7.22Z" />
        <path
          d="M320.09,578.94a9.31,9.31,0,0,1,0-14.84l.35.42a9.82,9.82,0,0,0-2.63,7,10.08,10.08,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M327,573v1.18c0,1,.07,1.13,1.22,1.24v.47h-3.88v-.47c1.18-.11,1.26-.23,1.26-1.27V573h-4.21v-.51c1.55-2.19,3.25-4.56,4.81-6.7h.8v6.42h1.56V573Zm-1.4-5.44c-1,1.4-2.09,3-3.19,4.65h3.19Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M336.21,573.62c-.21.75-.49,1.62-.65,2.22h-6.11v-.39c.87-.86,1.83-1.8,2.62-2.71,1-1.22,1.93-2.46,1.93-3.83s-.68-2.06-1.84-2.06a2.78,2.78,0,0,0-2.2,1.48l-.39-.35.93-1.33a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.78c0,1.35-.49,2.3-2,3.84-.68.7-1.66,1.65-2.33,2.27h3c.89,0,1.12-.07,1.67-1.08Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M343.37,565.87l.1.13-.43,1.16H339.3c-.15.84-.35,2.06-.5,2.82a7.4,7.4,0,0,1,1.93-.47,2.8,2.8,0,0,1,3,2.71,3.37,3.37,0,0,1-1.42,2.71,5,5,0,0,1-2.87,1.11,2.46,2.46,0,0,1-1.67-.61c-.25-.22-.31-.35-.28-.56a.89.89,0,0,1,.36-.6.36.36,0,0,1,.48,0,2.87,2.87,0,0,0,2,.9c1.28,0,1.89-1.11,1.89-2.3a2.29,2.29,0,0,0-2.58-2.41,5.13,5.13,0,0,0-1.61.28l-.06-.06c.26-1.29.61-3.51.8-4.83Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M353.89,575.84v-.46c.89-.1,1-.16,1-1.26v-2.69c0-1.17-.43-1.8-1.33-1.8a2.72,2.72,0,0,0-1.67.77c0,.19,0,.39,0,.68v3.13c0,1,.13,1.07,1,1.17v.46h-3.45v-.46c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.39-1.78-1.29-1.78a2.94,2.94,0,0,0-1.67.76v3.8c0,1,.11,1.09,1,1.19v.46h-3.49v-.46c1-.1,1.12-.17,1.12-1.2v-3.33c0-1-.06-1.08-.93-1.23v-.42a9.52,9.52,0,0,0,2.33-.64v1.28a13.82,13.82,0,0,1,1.15-.8,2.26,2.26,0,0,1,1.28-.43,1.85,1.85,0,0,1,1.75,1.3c.44-.35.84-.61,1.24-.88a2.38,2.38,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.1,1.09,1,1.19v.46Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M365,573.62c-.22.75-.5,1.62-.66,2.22h-6.11v-.39c.88-.86,1.83-1.8,2.62-2.71,1-1.22,1.93-2.46,1.93-3.83s-.68-2.06-1.83-2.06a2.79,2.79,0,0,0-2.21,1.48l-.38-.35.93-1.33a2.92,2.92,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.78c0,1.35-.49,2.3-2,3.84-.67.7-1.66,1.65-2.33,2.27h3c.89,0,1.12-.07,1.67-1.08Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M366.53,564.1a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.89,9.89,0,0,0,2.64-7,10.07,10.07,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M334.57,455.41h5V456c-1.41.16-1.66.3-1.66,2.16v3.62a2.82,2.82,0,0,0,1.35-1c1.06-1.17,2.22-2.56,3-3.6.53-.71.47-1-.33-1.12l-.49-.06v-.62H346V456a3.79,3.79,0,0,0-3.09,1.75l-3,3.44c1.36,1.9,3.29,4.52,4.32,5.84.89,1.14,1.13,1.3,2.24,1.46v.62h-3.07c-1.3-1.72-2.81-3.86-4.42-6.08-.41-.6-.72-.66-1-.63v4c0,1.83.16,2,1.67,2.13v.62h-5v-.62c1.48-.16,1.64-.3,1.64-2.13v-8.2c0-1.88-.14-2-1.64-2.16Z" />
        <path d="M349.19,469.43c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,349.19,469.43Z" />
        <path d="M358,465.33v1.55c0,1.37.08,1.49,1.41,1.64v.62H355v-.62c1.37-.15,1.45-.3,1.45-1.67v-1.52h-4.83v-.68c1.79-2.89,3.73-6,5.52-8.85H358v8.47h1.79v1.06Zm-1.61-7.19c-1.11,1.85-2.39,4-3.65,6.13h3.65Z" />
        <path d="M368.58,466.21c-.25,1-.57,2.13-.75,2.93h-7v-.52c1-1.13,2.1-2.38,3-3.58,1.19-1.6,2.21-3.25,2.21-5.05s-.78-2.72-2.1-2.72-2,1.1-2.53,2l-.45-.46L362,457a3.21,3.21,0,0,1,2.57-1.28c1.76,0,3.17,1.39,3.17,3.68,0,1.77-.56,3-2.33,5.07-.78.91-1.91,2.17-2.68,3h3.43c1,0,1.29-.11,1.92-1.44Z" />
        <path
          d="M328.09,493.1a9.31,9.31,0,0,1,0-14.84l.34.42a9.88,9.88,0,0,0-2.62,7,10.12,10.12,0,0,0,2.62,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M330,481.79l.76-1a2.86,2.86,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.71,1.71,0,0,1-.51,1.18,6.84,6.84,0,0,1-1.15.86,2.64,2.64,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.6,2.6,0,0,1-1.68-.56.72.72,0,0,1-.27-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.35a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16l-.11-.5c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.48,2.54,2.54,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M340.85,479.84c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17C337.45,482.69,338.37,479.84,340.85,479.84Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.6S342.2,480.42,340.84,480.42Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M348.68,479.84c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17C345.28,482.69,346.2,479.84,348.68,479.84Zm0,.58c-1.42,0-1.76,2.35-1.76,4.57s.42,4.62,1.77,4.62,1.74-2.37,1.74-4.6S350,480.42,348.67,480.42Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M361.88,490v-.47c.89-.1,1-.16,1-1.25V485.6c0-1.18-.43-1.81-1.33-1.81a2.7,2.7,0,0,0-1.67.77c0,.19,0,.39,0,.69v3.13c0,1,.13,1.06,1,1.16V490h-3.46v-.47c.95-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.38-1.78-1.28-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.09,1,1.19V490h-3.48v-.47c1-.1,1.11-.16,1.11-1.2V485c0-1-.05-1.08-.92-1.23v-.42a9.52,9.52,0,0,0,2.33-.64V484a13.82,13.82,0,0,1,1.15-.8,2.24,2.24,0,0,1,1.28-.43,1.86,1.86,0,0,1,1.75,1.3c.44-.34.84-.61,1.24-.88a2.46,2.46,0,0,1,1.26-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19V490Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M373,487.78c-.22.75-.5,1.62-.66,2.23h-6.11v-.4c.87-.86,1.83-1.8,2.62-2.71,1-1.22,1.93-2.46,1.93-3.83S370.1,481,369,481a2.78,2.78,0,0,0-2.21,1.47l-.39-.35.94-1.33a2.92,2.92,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.5,2.29-2,3.83-.67.7-1.66,1.65-2.33,2.28h3c.89,0,1.12-.08,1.67-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M374.52,478.26a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.87,9.87,0,0,0,2.63-7,10,10,0,0,0-2.63-7Z"
          style={{ fill: "#136736" }}
        />
        <path d="M265.35,455.41h5V456c-1.41.16-1.66.3-1.66,2.16v3.62a2.76,2.76,0,0,0,1.34-1c1.07-1.17,2.23-2.56,3-3.6.53-.71.46-1-.34-1.12l-.48-.06v-.62h4.53V456a3.79,3.79,0,0,0-3.09,1.75l-3,3.44c1.35,1.9,3.28,4.52,4.32,5.84.89,1.14,1.13,1.3,2.23,1.46v.62h-3.07c-1.29-1.72-2.81-3.86-4.41-6.08-.42-.6-.73-.66-1-.63v4c0,1.83.16,2,1.66,2.13v.62h-5v-.62c1.48-.16,1.63-.3,1.63-2.13v-8.2c0-1.88-.14-2-1.63-2.16Z" />
        <path d="M280,469.43c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,280,469.43Z" />
        <path d="M288.8,465.33v1.55c0,1.37.08,1.49,1.4,1.64v.62h-4.46v-.62c1.36-.15,1.45-.3,1.45-1.67v-1.52h-4.83v-.68c1.78-2.89,3.73-6,5.52-8.85h.92v8.47h1.79v1.06Zm-1.61-7.19c-1.11,1.85-2.39,4-3.65,6.13h3.65Z" />
        <path d="M292.62,469.14v-.62c2-.14,2.13-.27,2.13-1.85v-8c0-1.08-.17-1.13-1-1.21l-.84-.11v-.56a14.16,14.16,0,0,0,3.49-1v10.95c0,1.6.11,1.71,2.13,1.85v.62Z" />
        <path
          d="M255.41,493.1a9.31,9.31,0,0,1,0-14.84l.35.42a9.84,9.84,0,0,0-2.63,7,10.08,10.08,0,0,0,2.63,7Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M257.28,481.79l.76-1a2.89,2.89,0,0,1,2.12-.93,2.22,2.22,0,0,1,2.45,2.15,1.74,1.74,0,0,1-.5,1.18A7.42,7.42,0,0,1,261,484a2.64,2.64,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.6,2.6,0,0,1-1.68-.56.72.72,0,0,1-.27-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.88-.81,1.88-2.35a2,2,0,0,0-2.1-2.05,3.84,3.84,0,0,0-1,.16l-.12-.5c1.44-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.5-1.48,2.51,2.51,0,0,0-2,1.26Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M268.29,479.84a2.48,2.48,0,0,1,2.68,2.39,1.92,1.92,0,0,1-.47,1.29,12.33,12.33,0,0,1-1.3,1c1.11.6,2.19,1.37,2.19,2.76a3.11,3.11,0,0,1-3.29,2.93c-1.84,0-3.16-1-3.16-2.66a1.87,1.87,0,0,1,.73-1.56,15.18,15.18,0,0,1,1.44-1,3,3,0,0,1-1.77-2.51A2.75,2.75,0,0,1,268.29,479.84Zm0,9.79a1.61,1.61,0,0,0,1.68-1.82c0-1.25-1-1.87-2.37-2.52a2.4,2.4,0,0,0-1.25,2.13A2,2,0,0,0,268.29,489.63Zm-.21-9.23a1.43,1.43,0,0,0-1.41,1.6c0,1.15.79,1.6,2,2.24a2.28,2.28,0,0,0,1-1.94C269.69,481,268.88,480.4,268.08,480.4Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M278.69,480l.1.14-.43,1.15h-3.74c-.15.84-.35,2.06-.5,2.82a7.4,7.4,0,0,1,1.93-.47,2.8,2.8,0,0,1,3,2.72,3.37,3.37,0,0,1-1.42,2.7,5,5,0,0,1-2.87,1.11,2.46,2.46,0,0,1-1.67-.61c-.25-.22-.31-.35-.28-.56a.89.89,0,0,1,.36-.6.37.37,0,0,1,.48,0,2.87,2.87,0,0,0,2,.9c1.28,0,1.89-1.11,1.89-2.3a2.29,2.29,0,0,0-2.58-2.41,4.83,4.83,0,0,0-1.61.29l-.06-.07c.26-1.29.61-3.51.8-4.83Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M289.21,490v-.47c.89-.1,1-.16,1-1.25V485.6c0-1.18-.43-1.81-1.33-1.81a2.72,2.72,0,0,0-1.67.77c0,.19,0,.39,0,.69v3.13c0,1,.13,1.06,1,1.16V490h-3.45v-.47c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.39-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.09,1,1.19V490h-3.49v-.47c1-.1,1.12-.16,1.12-1.2V485c0-1-.06-1.08-.93-1.23v-.42a9.52,9.52,0,0,0,2.33-.64V484a13.82,13.82,0,0,1,1.15-.8,2.26,2.26,0,0,1,1.28-.43,1.85,1.85,0,0,1,1.75,1.3c.44-.34.84-.61,1.24-.88a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.91,2,2.49v3.09c0,1,.1,1.09,1,1.19V490Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M300.32,487.78c-.22.75-.49,1.62-.65,2.23h-6.12v-.4c.88-.86,1.83-1.8,2.62-2.71,1-1.22,1.93-2.46,1.93-3.83s-.68-2.05-1.83-2.05a2.78,2.78,0,0,0-2.21,1.47l-.38-.35.93-1.33a2.93,2.93,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.83-.68.7-1.67,1.65-2.34,2.28h3c.89,0,1.12-.08,1.68-1.09Z"
          style={{ fill: "#136736" }}
        />
        <path
          d="M301.85,478.26a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.87,9.87,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
          style={{ fill: "#136736" }}
        />
        <g id="_1-3" data-name="1">
          <path d="M954.32,856.88h5v.63c-1.41.15-1.66.29-1.66,2.16v3.62a2.82,2.82,0,0,0,1.35-1c1.06-1.18,2.22-2.56,3-3.61.53-.7.47-1-.33-1.11l-.49-.06v-.63h4.53v.63a3.76,3.76,0,0,0-3.09,1.75c-.58.66-1.92,2.16-3,3.44,1.36,1.89,3.29,4.52,4.32,5.84.89,1.14,1.13,1.3,2.24,1.45v.63h-3.07c-1.3-1.73-2.81-3.87-4.42-6.09-.41-.6-.72-.65-1-.63v4c0,1.83.16,2,1.67,2.12v.63h-5V870c1.48-.15,1.64-.29,1.64-2.12v-8.2c0-1.88-.14-2-1.64-2.16Z" />
          <path d="M968.94,870.91a1.27,1.27,0,0,1-1.19-1.43,1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.29,1.29,0,0,1,968.94,870.91Z" />
          <path d="M972.61,870.62V870c2-.14,2.13-.27,2.13-1.84v-8c0-1.08-.17-1.13-1-1.22l-.83-.1v-.56a14.89,14.89,0,0,0,3.49-1v10.95c0,1.6.11,1.7,2.13,1.84v.63Z" />
          <path d="M954.32,788.19h5v.63c-1.41.16-1.66.3-1.66,2.16v3.62a2.82,2.82,0,0,0,1.35-1c1.06-1.17,2.22-2.56,3-3.6.53-.71.47-1-.33-1.12l-.49-.06v-.63h4.53v.63a3.79,3.79,0,0,0-3.09,1.75l-3,3.44c1.36,1.9,3.29,4.52,4.32,5.84.89,1.14,1.13,1.3,2.24,1.45v.63h-3.07c-1.3-1.72-2.81-3.86-4.42-6.09-.41-.59-.72-.65-1-.62v4c0,1.83.16,2,1.67,2.12v.63h-5v-.63c1.48-.15,1.64-.29,1.64-2.12V791c0-1.88-.14-2-1.64-2.16Z" />
          <path d="M968.94,802.22c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,968.94,802.22Z" />
          <path d="M979.34,799c-.25,1-.57,2.13-.75,2.93h-7v-.52c1-1.13,2.1-2.38,3-3.58,1.2-1.6,2.21-3.25,2.21-5.05s-.77-2.72-2.1-2.72-2,1.1-2.53,2l-.44-.46,1.07-1.76a3.19,3.19,0,0,1,2.57-1.28c1.76,0,3.17,1.39,3.17,3.68,0,1.77-.57,3-2.34,5.07-.77.91-1.91,2.17-2.68,3H977c1,0,1.28-.11,1.92-1.44Z" />
          <path d="M953.32,724.54h5v.63c-1.41.15-1.66.29-1.66,2.15v3.62a2.72,2.72,0,0,0,1.35-1c1.06-1.18,2.22-2.56,3-3.61.53-.71.47-1-.33-1.11l-.49-.06v-.63h4.53v.63a3.76,3.76,0,0,0-3.09,1.75c-.58.65-1.92,2.16-3,3.44,1.36,1.89,3.29,4.52,4.32,5.84.89,1.13,1.13,1.3,2.24,1.45v.63h-3.07c-1.3-1.73-2.81-3.87-4.42-6.09-.41-.6-.72-.66-1-.63v4c0,1.83.16,2,1.67,2.12v.63h-5v-.63c1.48-.15,1.64-.29,1.64-2.12v-8.21c0-1.87-.14-2-1.64-2.15Z" />
          <path d="M967.94,738.56a1.26,1.26,0,0,1-1.19-1.42,1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.28,1.28,0,0,1,967.94,738.56Z" />
          <path d="M971,727.43l.87-1.35a3.11,3.11,0,0,1,2.42-1.22,2.71,2.71,0,0,1,2.82,2.83,2.43,2.43,0,0,1-.58,1.56,8.53,8.53,0,0,1-1.32,1.14,3.31,3.31,0,0,1,2.75,3.24c0,3.43-3.62,4.9-4.9,4.9a2.82,2.82,0,0,1-1.93-.73,1,1,0,0,1-.31-.73,1.31,1.31,0,0,1,.44-.84.35.35,0,0,1,.53,0,3.28,3.28,0,0,0,2.29,1.17c1.26,0,2.16-1.08,2.16-3.12a2.42,2.42,0,0,0-2.41-2.7,4.09,4.09,0,0,0-1.19.21l-.13-.65c1.65-.62,2.9-1.44,2.9-3,0-1.27-.7-2-1.72-2a2.94,2.94,0,0,0-2.29,1.66Z" />
          <path d="M953.32,661.54h5v.63c-1.41.15-1.66.29-1.66,2.15v3.62a2.72,2.72,0,0,0,1.35-1c1.06-1.18,2.22-2.56,3-3.61.53-.71.47-1-.33-1.11l-.49-.06v-.63h4.53v.63a3.76,3.76,0,0,0-3.09,1.75c-.58.65-1.92,2.16-3,3.44,1.36,1.89,3.29,4.52,4.32,5.84.89,1.13,1.13,1.3,2.24,1.45v.63h-3.07c-1.3-1.73-2.81-3.87-4.42-6.09-.41-.6-.72-.66-1-.63v4c0,1.83.16,2,1.67,2.12v.63h-5v-.63c1.48-.15,1.64-.29,1.64-2.12v-8.21c0-1.87-.14-2-1.64-2.15Z" />
          <path d="M967.94,675.56a1.26,1.26,0,0,1-1.19-1.42,1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.28,1.28,0,0,1,967.94,675.56Z" />
          <path d="M976.77,671.46V673c0,1.38.08,1.5,1.41,1.64v.63h-4.47v-.63c1.37-.14,1.45-.3,1.45-1.67v-1.52h-4.83v-.67c1.79-2.89,3.73-6,5.52-8.86h.92v8.48h1.79v1.05Zm-1.61-7.19c-1.11,1.85-2.39,4-3.65,6.14h3.65Z" />
          <path d="M953.32,597.88h5v.63c-1.41.16-1.66.3-1.66,2.16v3.62a2.82,2.82,0,0,0,1.35-1c1.06-1.17,2.22-2.56,3-3.6.53-.71.47-1-.33-1.12l-.49-.06v-.63h4.53v.63a3.79,3.79,0,0,0-3.09,1.75l-3,3.44c1.36,1.89,3.29,4.52,4.32,5.84.89,1.14,1.13,1.3,2.24,1.45v.63h-3.07c-1.3-1.72-2.81-3.87-4.42-6.09-.41-.6-.72-.65-1-.62v4c0,1.83.16,2,1.67,2.12v.63h-5V611c1.48-.15,1.64-.29,1.64-2.12v-8.2c0-1.88-.14-2-1.64-2.16Z" />
          <path d="M967.94,611.91c-.7,0-1.19-.58-1.19-1.43a1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.29,1.29,0,0,1,967.94,611.91Z" />
          <path d="M977.57,598.46l.11.17-.5,1.52H972.9c-.17,1.12-.41,2.72-.58,3.73a7.47,7.47,0,0,1,2.22-.62,3.44,3.44,0,0,1,3.48,3.58,4.7,4.7,0,0,1-1.62,3.57,5.39,5.39,0,0,1-3.3,1.46,2.6,2.6,0,0,1-1.91-.8.82.82,0,0,1-.33-.74,1.26,1.26,0,0,1,.42-.79.38.38,0,0,1,.55,0,3.15,3.15,0,0,0,2.33,1.19c1.47,0,2.17-1.46,2.17-3,0-1.87-1-3.18-3-3.18a4.92,4.92,0,0,0-1.86.38l-.06-.1c.3-1.69.71-4.62.92-6.36Z" />
          <path d="M952.32,534.88h5v.63c-1.41.16-1.66.3-1.66,2.16v3.62a2.82,2.82,0,0,0,1.35-1c1.06-1.17,2.22-2.56,3-3.6.53-.71.47-1-.33-1.12l-.49-.06v-.63h4.53v.63a3.79,3.79,0,0,0-3.09,1.75l-3,3.44c1.36,1.89,3.29,4.52,4.32,5.84.89,1.14,1.13,1.3,2.24,1.45v.63h-3.07c-1.3-1.72-2.81-3.87-4.42-6.09-.41-.6-.72-.65-1-.62v4c0,1.83.16,2,1.67,2.12v.63h-5V548c1.48-.15,1.64-.29,1.64-2.12v-8.2c0-1.88-.14-2-1.64-2.16Z" />
          <path d="M966.94,548.91c-.7,0-1.19-.58-1.19-1.43a1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.29,1.29,0,0,1,966.94,548.91Z" />
          <path d="M972.87,540.63a2.38,2.38,0,0,1,1.18-.37c2,0,3.32,1.72,3.32,3.92,0,2.56-1.62,4.69-3.87,4.69s-3.88-2-3.88-5.3a8.51,8.51,0,0,1,5.07-7.93,13.89,13.89,0,0,1,2.19-.57l.11.73a10.12,10.12,0,0,0-2,.72,6.5,6.5,0,0,0-3.31,4.79Zm.38.89a3.24,3.24,0,0,0-1.67.53,8,8,0,0,0-.12,1.49c0,2.66.86,4.55,2.35,4.55s1.79-1.72,1.79-3.12C975.6,543.17,974.85,541.52,973.25,541.52Z" />
          <path d="M953.2,471.23h5v.63c-1.42.15-1.66.29-1.66,2.15v3.62c.37-.05.79-.39,1.34-1,1.07-1.17,2.22-2.55,3-3.6.54-.71.47-1-.33-1.11l-.48-.06v-.63h4.52v.63a3.76,3.76,0,0,0-3.09,1.75c-.58.65-1.91,2.16-3,3.44,1.35,1.89,3.28,4.52,4.32,5.84.89,1.13,1.13,1.3,2.23,1.45V485H962c-1.3-1.73-2.81-3.87-4.42-6.09-.41-.6-.72-.66-1-.63v4c0,1.83.15,2,1.66,2.12V485h-5v-.63c1.49-.15,1.64-.29,1.64-2.12V474c0-1.88-.14-2-1.64-2.15Z" />
          <path d="M967.82,485.25a1.26,1.26,0,0,1-1.18-1.42,1.29,1.29,0,0,1,1.18-1.43,1.31,1.31,0,0,1,1.22,1.43A1.28,1.28,0,0,1,967.82,485.25Z" />
          <path d="M978.39,472.08c-1.76,4.28-3.49,8.56-5.25,12.92l-1.41.17-.13-.24c1.92-3.58,3.91-7.85,5.39-11.43h-3.92c-1.21,0-1.29.18-1.79,1.55h-.59c.19-1.27.28-2.33.37-3.25h7.22Z" />
          <path d="M950.32,407.79h5v.63c-1.41.15-1.66.29-1.66,2.16v3.62a2.82,2.82,0,0,0,1.35-1c1.06-1.18,2.22-2.56,3-3.61.53-.7.47-1-.33-1.11l-.49-.06v-.63h4.53v.63a3.76,3.76,0,0,0-3.09,1.75c-.58.66-1.92,2.16-3,3.44,1.36,1.89,3.29,4.52,4.32,5.84.89,1.14,1.13,1.3,2.24,1.45v.63h-3.07c-1.3-1.73-2.81-3.87-4.42-6.09-.41-.6-.72-.66-1-.63v4c0,1.83.16,2,1.67,2.12v.63h-5v-.63c1.48-.15,1.64-.29,1.64-2.12v-8.2c0-1.88-.14-2-1.64-2.16Z" />
          <path d="M964.94,421.82a1.27,1.27,0,0,1-1.19-1.43,1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.29,1.29,0,0,1,964.94,421.82Z" />
          <path d="M971.62,408.11a3,3,0,0,1,3.07,3.15,2.75,2.75,0,0,1-.53,1.7,15.62,15.62,0,0,1-1.5,1.31c1.28.8,2.51,1.81,2.51,3.65a3.78,3.78,0,0,1-3.77,3.86,3.38,3.38,0,0,1-3.63-3.5,2.62,2.62,0,0,1,.84-2.06,16.61,16.61,0,0,1,1.65-1.29,3.93,3.93,0,0,1-2-3.32A3.38,3.38,0,0,1,971.62,408.11Zm0,12.92c1.2,0,1.93-.89,1.92-2.4s-1.16-2.46-2.71-3.33a3.27,3.27,0,0,0-1.44,2.81C969.39,420.07,970.44,421,971.62,421Zm-.24-12.19c-1.07,0-1.62,1-1.62,2.12,0,1.51.9,2.11,2.34,3a3.14,3.14,0,0,0,1.12-2.55C973.22,409.57,972.3,408.84,971.38,408.84Z" />
          <path d="M951.32,344.79h5v.63c-1.41.16-1.66.3-1.66,2.16v3.62a2.82,2.82,0,0,0,1.35-1c1.06-1.18,2.22-2.56,3-3.6.53-.71.47-1-.33-1.12l-.49-.06v-.63h4.53v.63a3.79,3.79,0,0,0-3.09,1.75l-3,3.44c1.36,1.89,3.29,4.52,4.32,5.84.89,1.14,1.13,1.3,2.24,1.45v.63h-3.07c-1.3-1.72-2.81-3.86-4.42-6.09-.41-.6-.72-.65-1-.62v4c0,1.83.16,2,1.67,2.12v.63h-5v-.63c1.48-.15,1.64-.29,1.64-2.12v-8.2c0-1.88-.14-2-1.64-2.16Z" />
          <path d="M965.94,358.82a1.45,1.45,0,0,1,0-2.85,1.44,1.44,0,0,1,0,2.85Z" />
          <path d="M974.26,352.47l-1.18.68a2.06,2.06,0,0,1-1.09.33c-2,0-3.36-1.75-3.36-4,0-2,1.38-4.41,3.86-4.41s3.76,2.43,3.76,5.3a8.42,8.42,0,0,1-4.69,7.87,8.26,8.26,0,0,1-2.27.57l-.15-.65C971.8,357.6,973.67,355.62,974.26,352.47Zm-2-6.59c-1.36,0-1.84,1.46-1.84,3,0,1.85.89,3.39,2.4,3.39a2.45,2.45,0,0,0,1.54-.55,11.72,11.72,0,0,0,.08-1.4C974.42,348.41,974,345.87,972.24,345.88Z" />
          <path d="M947.43,281.58h5v.62c-1.41.16-1.66.3-1.66,2.16V288a2.76,2.76,0,0,0,1.34-1c1.07-1.17,2.23-2.56,3-3.6.53-.71.47-1-.33-1.12l-.49-.06v-.62h4.53v.62a3.79,3.79,0,0,0-3.09,1.75l-3,3.44c1.35,1.9,3.29,4.52,4.32,5.84.89,1.14,1.13,1.3,2.23,1.46v.62h-3.06c-1.3-1.72-2.81-3.86-4.42-6.08-.41-.6-.72-.66-1-.63v4c0,1.83.16,2,1.67,2.13v.62h-5v-.62c1.48-.16,1.64-.3,1.64-2.13v-8.2c0-1.88-.14-2-1.64-2.16Z" />
          <path d="M962.05,295.6c-.7,0-1.19-.58-1.19-1.43a1.28,1.28,0,0,1,1.19-1.42,1.3,1.3,0,0,1,1.22,1.42A1.29,1.29,0,0,1,962.05,295.6Z" />
          <path d="M965.72,295.31v-.62c2-.14,2.13-.27,2.13-1.85v-8c0-1.08-.17-1.13-1-1.21l-.84-.11v-.56a14.38,14.38,0,0,0,3.5-1v10.95c0,1.6.11,1.71,2.13,1.85v.62Z" />
          <path d="M977.58,281.89c2.82,0,3.85,3.59,3.85,6.83,0,3.08-1,6.85-3.85,6.85s-3.9-3.55-3.9-6.83C973.68,285.65,974.73,281.89,977.58,281.89Zm0,.77c-1.63,0-2,3.1-2,6s.5,6.11,2,6.11,2-3.13,2-6.09S979.13,282.66,977.57,282.66Z" />
          <path
            d="M940.57,900.23a9.31,9.31,0,0,1,0-14.84l.34.42a9.9,9.9,0,0,0-2.62,7,10.1,10.1,0,0,0,2.62,7Z"
            style={{ fill: "#136736" }}
          />
          <path
            d="M942.44,888.92l.76-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.69,1.69,0,0,1-.51,1.18,6.34,6.34,0,0,1-1.15.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.16,3.71-4.27,3.71a2.7,2.7,0,0,1-1.69-.55.74.74,0,0,1-.26-.56,1,1,0,0,1,.38-.64.35.35,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2,3.85,3.85,0,0,0-1,.16l-.11-.49c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.49,2.54,2.54,0,0,0-2,1.26Z"
            style={{ fill: "#136736" }}
          />
          <path
            d="M956.84,887.38c-1.54,3.24-3.05,6.48-4.58,9.78l-1.23.13-.11-.18a94.46,94.46,0,0,0,4.7-8.66H952.2c-1.05,0-1.12.14-1.56,1.18h-.51c.17-1,.24-1.77.32-2.46h6.29Z"
            style={{ fill: "#136736" }}
          />
          <path
            d="M963.85,887.17l.1.13-.44,1.15h-3.73c-.15.85-.36,2.06-.5,2.82a7.73,7.73,0,0,1,1.93-.47,2.8,2.8,0,0,1,3,2.72,3.38,3.38,0,0,1-1.41,2.7,5.09,5.09,0,0,1-2.87,1.11,2.42,2.42,0,0,1-1.67-.61c-.25-.21-.31-.35-.29-.56a.93.93,0,0,1,.37-.6.37.37,0,0,1,.48,0,2.87,2.87,0,0,0,2,.9c1.28,0,1.89-1.1,1.89-2.29a2.3,2.3,0,0,0-2.59-2.42,4.93,4.93,0,0,0-1.61.29l-.05-.07c.25-1.29.61-3.51.8-4.82Z"
            style={{ fill: "#136736" }}
          />
          <path
            d="M974.36,897.14v-.47c.89-.09,1-.16,1-1.25v-2.69c0-1.17-.42-1.81-1.33-1.81a2.76,2.76,0,0,0-1.67.78,5.62,5.62,0,0,1,0,.68v3.13c0,1,.13,1.07,1,1.16v.47h-3.46v-.47c.94-.09,1.07-.15,1.07-1.19V892.7c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.66.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48v-.47c1-.1,1.11-.16,1.11-1.2v-3.33c0-1,0-1.08-.92-1.23v-.42a10.09,10.09,0,0,0,2.33-.63v1.28a9.41,9.41,0,0,1,1.15-.8,2.18,2.18,0,0,1,1.28-.44,1.86,1.86,0,0,1,1.75,1.3c.44-.34.84-.6,1.24-.88a2.46,2.46,0,0,1,1.26-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.47Z"
            style={{ fill: "#136736" }}
          />
          <path
            d="M985.48,894.92c-.22.74-.5,1.61-.66,2.22h-6.11v-.39c.87-.86,1.83-1.81,2.62-2.72a6.3,6.3,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.84-2.05a2.79,2.79,0,0,0-2.2,1.48l-.39-.35.94-1.34a3,3,0,0,1,2.23-1,2.66,2.66,0,0,1,2.77,2.79c0,1.34-.5,2.29-2,3.84-.68.69-1.66,1.64-2.33,2.27h3c.89,0,1.12-.08,1.67-1.09Z"
            style={{ fill: "#136736" }}
          />
          <path
            d="M987,885.39a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.84,9.84,0,0,0,2.63-7,10.05,10.05,0,0,0-2.63-7Z"
            style={{ fill: "#136736" }}
          />
          <path
            d="M938.57,827.47a9.31,9.31,0,0,1,0-14.84l.34.42a9.9,9.9,0,0,0-2.62,7,10.1,10.1,0,0,0,2.62,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M940.44,816.16l.76-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.69,1.69,0,0,1-.51,1.18,6.34,6.34,0,0,1-1.15.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.16,3.71-4.27,3.71a2.7,2.7,0,0,1-1.69-.55.74.74,0,0,1-.26-.56,1,1,0,0,1,.38-.64.35.35,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2,3.85,3.85,0,0,0-1,.16l-.11-.49c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.49,2.54,2.54,0,0,0-2,1.26Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M951.33,814.21c2.46,0,3.35,2.73,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17S948.85,814.21,951.33,814.21Zm0,.59c-1.41,0-1.76,2.34-1.76,4.56S950,824,951.33,824s1.74-2.37,1.74-4.61S952.68,814.8,951.32,814.8Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M959.16,814.21c2.45,0,3.35,2.73,3.35,5.17s-.9,5.19-3.35,5.19-3.4-2.68-3.4-5.17S956.68,814.21,959.16,814.21Zm0,.59c-1.42,0-1.76,2.34-1.76,4.56s.42,4.63,1.77,4.63,1.73-2.37,1.73-4.61S960.51,814.8,959.15,814.8Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M972.36,824.38v-.47c.89-.09,1-.16,1-1.25V820c0-1.17-.42-1.81-1.33-1.81a2.76,2.76,0,0,0-1.67.78,5.62,5.62,0,0,1,0,.68v3.13c0,1,.13,1.07,1,1.16v.47h-3.46v-.47c.94-.09,1.07-.15,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.66.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48v-.47c1-.1,1.11-.16,1.11-1.2v-3.33c0-1,0-1.08-.92-1.23v-.42a10.09,10.09,0,0,0,2.33-.63v1.28a9.41,9.41,0,0,1,1.15-.8,2.18,2.18,0,0,1,1.28-.44,1.88,1.88,0,0,1,1.75,1.3c.44-.34.84-.6,1.24-.88a2.46,2.46,0,0,1,1.26-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.47Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M983.48,822.16c-.22.74-.5,1.61-.66,2.22h-6.11V824c.87-.86,1.83-1.81,2.62-2.72a6.3,6.3,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.84-2.05a2.79,2.79,0,0,0-2.2,1.48l-.39-.35.94-1.34a3,3,0,0,1,2.23-1,2.66,2.66,0,0,1,2.77,2.79c0,1.34-.5,2.29-2,3.84-.68.69-1.66,1.64-2.33,2.27h3c.89,0,1.12-.08,1.67-1.09Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M985,812.63a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.84,9.84,0,0,0,2.63-7,10.05,10.05,0,0,0-2.63-7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M938.57,764.91a9.31,9.31,0,0,1,0-14.84l.34.42a9.88,9.88,0,0,0-2.62,7,10.12,10.12,0,0,0,2.62,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M940.44,753.6l.76-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.69,1.69,0,0,1-.51,1.18,6.84,6.84,0,0,1-1.15.86,2.64,2.64,0,0,1,2.4,2.46c0,2.6-3.16,3.71-4.27,3.71a2.63,2.63,0,0,1-1.69-.56.7.7,0,0,1-.26-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.35a2,2,0,0,0-2.09-2,3.85,3.85,0,0,0-1,.16l-.11-.5c1.43-.47,2.53-1.09,2.53-2.25a1.39,1.39,0,0,0-1.51-1.49,2.54,2.54,0,0,0-2,1.26Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M951.33,751.65c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17S948.85,751.65,951.33,751.65Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.6S952.68,752.23,951.32,752.23Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M959.16,751.65c2.45,0,3.35,2.72,3.35,5.17s-.9,5.19-3.35,5.19-3.4-2.68-3.4-5.17S956.68,751.65,959.16,751.65Zm0,.58c-1.42,0-1.76,2.35-1.76,4.57s.42,4.62,1.77,4.62,1.73-2.37,1.73-4.6S960.51,752.23,959.15,752.23Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M972.36,761.82v-.47c.89-.1,1-.16,1-1.25v-2.69c0-1.18-.42-1.81-1.33-1.81a2.74,2.74,0,0,0-1.67.77,5.81,5.81,0,0,1,0,.69v3.13c0,1,.13,1.06,1,1.16v.47h-3.46v-.47c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.66.77v3.79c0,1,.11,1.09,1,1.19v.47h-3.48v-.47c1-.1,1.11-.16,1.11-1.2v-3.33c0-1,0-1.08-.92-1.23v-.42a10.09,10.09,0,0,0,2.33-.63v1.27a12.32,12.32,0,0,1,1.15-.8,2.24,2.24,0,0,1,1.28-.43,1.86,1.86,0,0,1,1.75,1.3c.44-.34.84-.61,1.24-.88a2.46,2.46,0,0,1,1.26-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.47Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M983.48,759.59c-.22.75-.5,1.62-.66,2.23h-6.11v-.4c.87-.86,1.83-1.8,2.62-2.71,1-1.22,1.93-2.46,1.93-3.83s-.68-2-1.84-2a2.81,2.81,0,0,0-2.2,1.47l-.39-.34.94-1.34a2.91,2.91,0,0,1,2.23-1,2.66,2.66,0,0,1,2.77,2.79c0,1.34-.5,2.29-2,3.83-.68.7-1.66,1.65-2.33,2.28h3c.89,0,1.12-.08,1.67-1.09Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M985,750.07a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.87,9.87,0,0,0,2.63-7,10,10,0,0,0-2.63-7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M938.57,701.47a9.31,9.31,0,0,1,0-14.84l.34.42a9.9,9.9,0,0,0-2.62,7,10.1,10.1,0,0,0,2.62,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M940.44,690.16l.76-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.69,1.69,0,0,1-.51,1.18,6.34,6.34,0,0,1-1.15.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.16,3.71-4.27,3.71a2.7,2.7,0,0,1-1.69-.55.74.74,0,0,1-.26-.56,1,1,0,0,1,.38-.64.35.35,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2,3.85,3.85,0,0,0-1,.16l-.11-.49c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.49,2.54,2.54,0,0,0-2,1.26Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M951.33,688.21c2.46,0,3.35,2.73,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17S948.85,688.21,951.33,688.21Zm0,.59c-1.41,0-1.76,2.34-1.76,4.56S950,698,951.33,698s1.74-2.37,1.74-4.61S952.68,688.8,951.32,688.8Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M959.16,688.21c2.45,0,3.35,2.73,3.35,5.17s-.9,5.19-3.35,5.19-3.4-2.68-3.4-5.17S956.68,688.21,959.16,688.21Zm0,.59c-1.42,0-1.76,2.34-1.76,4.56s.42,4.63,1.77,4.63,1.73-2.37,1.73-4.61S960.51,688.8,959.15,688.8Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M972.36,698.38v-.47c.89-.09,1-.16,1-1.25V694c0-1.17-.42-1.81-1.33-1.81a2.76,2.76,0,0,0-1.67.78,5.62,5.62,0,0,1,0,.68v3.13c0,1,.13,1.07,1,1.16v.47h-3.46v-.47c.94-.09,1.07-.15,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.66.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48v-.47c1-.1,1.11-.16,1.11-1.2v-3.33c0-1,0-1.08-.92-1.23v-.42a10.09,10.09,0,0,0,2.33-.63v1.28a9.41,9.41,0,0,1,1.15-.8,2.18,2.18,0,0,1,1.28-.44,1.88,1.88,0,0,1,1.75,1.3c.44-.34.84-.6,1.24-.88a2.46,2.46,0,0,1,1.26-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.47Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M983.48,696.16c-.22.74-.5,1.61-.66,2.22h-6.11V698c.87-.86,1.83-1.81,2.62-2.72a6.3,6.3,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.84-2.05a2.79,2.79,0,0,0-2.2,1.48l-.39-.35.94-1.34a3,3,0,0,1,2.23-1,2.66,2.66,0,0,1,2.77,2.79c0,1.34-.5,2.29-2,3.84-.68.69-1.66,1.64-2.33,2.27h3c.89,0,1.12-.08,1.67-1.09Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M985,686.63a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.84,9.84,0,0,0,2.63-7,10.05,10.05,0,0,0-2.63-7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M938.57,638.47a9.31,9.31,0,0,1,0-14.84l.34.42a9.9,9.9,0,0,0-2.62,7,10.1,10.1,0,0,0,2.62,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M940.44,627.16l.76-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.69,1.69,0,0,1-.51,1.18,6.34,6.34,0,0,1-1.15.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.16,3.71-4.27,3.71a2.7,2.7,0,0,1-1.69-.55.74.74,0,0,1-.26-.56,1,1,0,0,1,.38-.64.35.35,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2,3.85,3.85,0,0,0-1,.16l-.11-.49c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.49,2.54,2.54,0,0,0-2,1.26Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M951.33,625.21c2.46,0,3.35,2.73,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17S948.85,625.21,951.33,625.21Zm0,.59c-1.41,0-1.76,2.34-1.76,4.56S950,635,951.33,635s1.74-2.37,1.74-4.61S952.68,625.8,951.32,625.8Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M959.16,625.21c2.45,0,3.35,2.73,3.35,5.17s-.9,5.19-3.35,5.19-3.4-2.68-3.4-5.17S956.68,625.21,959.16,625.21Zm0,.59c-1.42,0-1.76,2.34-1.76,4.56s.42,4.63,1.77,4.63,1.73-2.37,1.73-4.61S960.51,625.8,959.15,625.8Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M972.36,635.38v-.47c.89-.09,1-.16,1-1.25V631c0-1.17-.42-1.81-1.33-1.81a2.76,2.76,0,0,0-1.67.78,5.62,5.62,0,0,1,0,.68v3.13c0,1,.13,1.07,1,1.16v.47h-3.46v-.47c.94-.09,1.07-.15,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.66.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48v-.47c1-.1,1.11-.16,1.11-1.2v-3.33c0-1,0-1.08-.92-1.23v-.42a10.09,10.09,0,0,0,2.33-.63v1.28a9.41,9.41,0,0,1,1.15-.8,2.18,2.18,0,0,1,1.28-.44,1.88,1.88,0,0,1,1.75,1.3c.44-.34.84-.6,1.24-.88a2.46,2.46,0,0,1,1.26-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.47Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M983.48,633.16c-.22.74-.5,1.61-.66,2.22h-6.11V635c.87-.86,1.83-1.81,2.62-2.72a6.3,6.3,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.84-2.05a2.79,2.79,0,0,0-2.2,1.48l-.39-.35.94-1.34a3,3,0,0,1,2.23-1,2.66,2.66,0,0,1,2.77,2.79c0,1.34-.5,2.29-2,3.84-.68.69-1.66,1.64-2.33,2.27h3c.89,0,1.12-.08,1.67-1.09Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M985,623.63a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.84,9.84,0,0,0,2.63-7,10.05,10.05,0,0,0-2.63-7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M938.57,574.6a9.31,9.31,0,0,1,0-14.84l.34.42a9.88,9.88,0,0,0-2.62,7,10.07,10.07,0,0,0,2.62,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M940.44,563.29l.76-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.71,1.71,0,0,1-.51,1.18,6.84,6.84,0,0,1-1.15.86,2.64,2.64,0,0,1,2.4,2.46c0,2.6-3.16,3.71-4.27,3.71a2.63,2.63,0,0,1-1.69-.56.7.7,0,0,1-.26-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.35a2,2,0,0,0-2.09-2,3.85,3.85,0,0,0-1,.16l-.11-.5c1.43-.47,2.53-1.09,2.53-2.25a1.39,1.39,0,0,0-1.51-1.49,2.54,2.54,0,0,0-2,1.26Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M951.33,561.34c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17S948.85,561.34,951.33,561.34Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.6S952.68,561.92,951.32,561.92Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M959.16,561.34c2.45,0,3.35,2.72,3.35,5.17s-.9,5.19-3.35,5.19-3.4-2.68-3.4-5.17S956.68,561.34,959.16,561.34Zm0,.58c-1.42,0-1.76,2.35-1.76,4.57s.42,4.62,1.77,4.62,1.73-2.37,1.73-4.6S960.51,561.92,959.15,561.92Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M972.36,571.51V571c.89-.1,1-.16,1-1.25v-2.7c0-1.17-.42-1.8-1.33-1.8a2.74,2.74,0,0,0-1.67.77,5.81,5.81,0,0,1,0,.69v3.13c0,1,.13,1.06,1,1.16v.47h-3.46V571c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.66.77v3.79c0,1,.11,1.09,1,1.19v.47h-3.48V571c1-.1,1.11-.16,1.11-1.2v-3.33c0-1,0-1.08-.92-1.23v-.42a9.65,9.65,0,0,0,2.33-.64v1.28a12.32,12.32,0,0,1,1.15-.8,2.24,2.24,0,0,1,1.28-.43,1.86,1.86,0,0,1,1.75,1.3c.44-.34.84-.61,1.24-.88a2.46,2.46,0,0,1,1.26-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.47Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M983.48,569.28c-.22.75-.5,1.62-.66,2.23h-6.11v-.4c.87-.86,1.83-1.8,2.62-2.71,1-1.22,1.93-2.46,1.93-3.83s-.68-2.06-1.84-2.06a2.79,2.79,0,0,0-2.2,1.48l-.39-.35.94-1.33a2.91,2.91,0,0,1,2.23-1,2.66,2.66,0,0,1,2.77,2.79c0,1.34-.5,2.29-2,3.83-.68.7-1.66,1.65-2.33,2.28h3c.89,0,1.12-.08,1.67-1.09Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M985,559.76a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.87,9.87,0,0,0,2.63-7,10,10,0,0,0-2.63-7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M938.57,511.6a9.31,9.31,0,0,1,0-14.84l.34.42a9.88,9.88,0,0,0-2.62,7,10.07,10.07,0,0,0,2.62,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M940.44,500.29l.76-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.71,1.71,0,0,1-.51,1.18,6.84,6.84,0,0,1-1.15.86,2.64,2.64,0,0,1,2.4,2.46c0,2.6-3.16,3.71-4.27,3.71a2.63,2.63,0,0,1-1.69-.56.7.7,0,0,1-.26-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.35a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16l-.11-.5c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.48,2.54,2.54,0,0,0-2,1.26Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M951.33,498.34c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17C947.93,501.19,948.85,498.34,951.33,498.34Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.6S952.68,498.92,951.32,498.92Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M959.16,498.34c2.45,0,3.35,2.72,3.35,5.17s-.9,5.19-3.35,5.19-3.4-2.68-3.4-5.17C955.76,501.19,956.68,498.34,959.16,498.34Zm0,.58c-1.42,0-1.76,2.35-1.76,4.57s.42,4.62,1.77,4.62,1.73-2.37,1.73-4.6S960.51,498.92,959.15,498.92Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M972.36,508.51V508c.89-.1,1-.16,1-1.25V504.1c0-1.18-.42-1.81-1.33-1.81a2.74,2.74,0,0,0-1.67.77,5.65,5.65,0,0,1,0,.69v3.13c0,1,.13,1.06,1,1.16v.47h-3.46V508c.94-.1,1.07-.16,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.66.77v3.79c0,1,.11,1.09,1,1.19v.47h-3.48V508c1-.1,1.11-.16,1.11-1.2v-3.33c0-1,0-1.08-.92-1.23v-.42a9.65,9.65,0,0,0,2.33-.64v1.28a12.32,12.32,0,0,1,1.15-.8,2.24,2.24,0,0,1,1.28-.43,1.86,1.86,0,0,1,1.75,1.3c.44-.34.84-.61,1.24-.88a2.46,2.46,0,0,1,1.26-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.47Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M983.48,506.28c-.22.75-.5,1.62-.66,2.23h-6.11v-.4c.87-.86,1.83-1.8,2.62-2.71,1-1.22,1.93-2.46,1.93-3.83s-.68-2.05-1.84-2.05a2.78,2.78,0,0,0-2.2,1.47l-.39-.35.94-1.33a2.91,2.91,0,0,1,2.23-1,2.66,2.66,0,0,1,2.77,2.79c0,1.34-.5,2.29-2,3.83-.68.7-1.66,1.65-2.33,2.28h3c.89,0,1.12-.08,1.67-1.09Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M985,496.76a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.87,9.87,0,0,0,2.63-7,10,10,0,0,0-2.63-7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M938.57,448.16a9.31,9.31,0,0,1,0-14.84l.34.42a9.9,9.9,0,0,0-2.62,7,10.1,10.1,0,0,0,2.62,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M940.44,436.85l.76-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.15,1.69,1.69,0,0,1-.51,1.18,6.34,6.34,0,0,1-1.15.86,2.65,2.65,0,0,1,2.4,2.46c0,2.6-3.16,3.71-4.27,3.71a2.7,2.7,0,0,1-1.69-.55.74.74,0,0,1-.26-.56,1,1,0,0,1,.38-.64.35.35,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16l-.11-.49c1.43-.48,2.53-1.1,2.53-2.26a1.39,1.39,0,0,0-1.51-1.49,2.54,2.54,0,0,0-2,1.26Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M951.33,434.9c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17C947.93,437.75,948.85,434.9,951.33,434.9Zm0,.59c-1.41,0-1.76,2.34-1.76,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S952.68,435.49,951.32,435.49Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M959.16,434.9c2.45,0,3.35,2.72,3.35,5.17s-.9,5.19-3.35,5.19-3.4-2.68-3.4-5.17C955.76,437.75,956.68,434.9,959.16,434.9Zm0,.59c-1.42,0-1.76,2.34-1.76,4.56s.42,4.63,1.77,4.63,1.73-2.37,1.73-4.61S960.51,435.49,959.15,435.49Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M972.36,445.07v-.47c.89-.09,1-.16,1-1.25v-2.69c0-1.17-.42-1.81-1.33-1.81a2.8,2.8,0,0,0-1.67.77,6,6,0,0,1,0,.69v3.13c0,1,.13,1.07,1,1.16v.47h-3.46v-.47c.94-.09,1.07-.16,1.07-1.19v-2.78c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.66.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48v-.47c1-.1,1.11-.16,1.11-1.2v-3.33c0-1,0-1.08-.92-1.23v-.42a10.09,10.09,0,0,0,2.33-.63v1.28a9.41,9.41,0,0,1,1.15-.8,2.18,2.18,0,0,1,1.28-.44,1.86,1.86,0,0,1,1.75,1.3c.44-.34.84-.6,1.24-.88a2.46,2.46,0,0,1,1.26-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.47Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M983.48,442.85c-.22.74-.5,1.61-.66,2.22h-6.11v-.39c.87-.86,1.83-1.81,2.62-2.72a6.3,6.3,0,0,0,1.93-3.83c0-1.29-.68-2.05-1.84-2.05a2.81,2.81,0,0,0-2.2,1.47l-.39-.34.94-1.34a3,3,0,0,1,2.23-1,2.66,2.66,0,0,1,2.77,2.79c0,1.34-.5,2.29-2,3.84-.68.69-1.66,1.64-2.33,2.27h3c.89,0,1.12-.08,1.67-1.09Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M985,433.32a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.84,9.84,0,0,0,2.63-7,10.05,10.05,0,0,0-2.63-7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M938.57,385.16a9.3,9.3,0,0,1,0-14.83l.34.41a9.91,9.91,0,0,0-2.62,7,10.09,10.09,0,0,0,2.62,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M940.44,373.85l.76-1a2.81,2.81,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.14,1.69,1.69,0,0,1-.51,1.18,6.82,6.82,0,0,1-1.15.87,2.63,2.63,0,0,1,2.4,2.45c0,2.6-3.16,3.71-4.27,3.71a2.7,2.7,0,0,1-1.69-.55.72.72,0,0,1-.26-.55.91.91,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2.05,3.85,3.85,0,0,0-1,.16l-.11-.49c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.49,2.57,2.57,0,0,0-2,1.26Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M951.33,371.91c2.46,0,3.35,2.72,3.35,5.17s-.89,5.18-3.35,5.18-3.4-2.68-3.4-5.17C947.93,374.75,948.85,371.91,951.33,371.91Zm0,.58c-1.41,0-1.76,2.34-1.76,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S952.68,372.49,951.32,372.49Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M959.16,371.91c2.45,0,3.35,2.72,3.35,5.17s-.9,5.18-3.35,5.18-3.4-2.68-3.4-5.17C955.76,374.75,956.68,371.91,959.16,371.91Zm0,.58c-1.42,0-1.76,2.34-1.76,4.56s.42,4.63,1.77,4.63,1.73-2.37,1.73-4.61S960.51,372.49,959.15,372.49Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M972.36,382.07v-.47c.89-.09,1-.16,1-1.25v-2.69c0-1.17-.42-1.81-1.33-1.81a2.76,2.76,0,0,0-1.67.78,5.62,5.62,0,0,1,0,.68v3.13c0,1,.13,1.07,1,1.16v.47h-3.46v-.47c.94-.09,1.07-.15,1.07-1.19v-2.78c0-1.17-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.66.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48v-.47c1-.09,1.11-.16,1.11-1.2v-3.33c0-1,0-1.08-.92-1.23v-.42a10.09,10.09,0,0,0,2.33-.63v1.28a9.41,9.41,0,0,1,1.15-.8,2.17,2.17,0,0,1,1.28-.43,1.87,1.87,0,0,1,1.75,1.29c.44-.34.84-.6,1.24-.87a2.37,2.37,0,0,1,1.26-.42c1.28,0,2,.9,2,2.48v3.09c0,1,.09,1.09,1,1.19v.47Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M983.48,379.85c-.22.75-.5,1.61-.66,2.22h-6.11v-.39c.87-.86,1.83-1.81,2.62-2.72a6.3,6.3,0,0,0,1.93-3.82c0-1.3-.68-2.06-1.84-2.06a2.79,2.79,0,0,0-2.2,1.48l-.39-.35.94-1.34a2.94,2.94,0,0,1,2.23-1,2.65,2.65,0,0,1,2.77,2.78c0,1.34-.5,2.29-2,3.84-.68.69-1.66,1.64-2.33,2.27h3c.89,0,1.12-.08,1.67-1.08Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M985,370.33a9.28,9.28,0,0,1,0,14.83l-.35-.41a9.89,9.89,0,0,0,2.63-7,10.05,10.05,0,0,0-2.63-7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M938.57,321.73a9.31,9.31,0,0,1,0-14.84l.34.42a9.86,9.86,0,0,0-2.62,7,10.09,10.09,0,0,0,2.62,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M940.44,310.41l.76-1a2.84,2.84,0,0,1,2.11-.93,2.22,2.22,0,0,1,2.46,2.14,1.67,1.67,0,0,1-.51,1.18,6.82,6.82,0,0,1-1.15.87,2.63,2.63,0,0,1,2.4,2.46c0,2.59-3.16,3.7-4.27,3.7a2.64,2.64,0,0,1-1.69-.55.72.72,0,0,1-.26-.55.93.93,0,0,1,.38-.64.33.33,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.36a2,2,0,0,0-2.09-2.05,4.29,4.29,0,0,0-1,.16l-.11-.49c1.43-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.51-1.48,2.56,2.56,0,0,0-2,1.25Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M951.33,308.47c2.46,0,3.35,2.72,3.35,5.17s-.89,5.18-3.35,5.18-3.4-2.68-3.4-5.17C947.93,311.31,948.85,308.47,951.33,308.47Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.61S952.68,309.05,951.32,309.05Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M959.16,308.47c2.45,0,3.35,2.72,3.35,5.17s-.9,5.18-3.35,5.18-3.4-2.68-3.4-5.17C955.76,311.31,956.68,308.47,959.16,308.47Zm0,.58c-1.42,0-1.76,2.35-1.76,4.57s.42,4.62,1.77,4.62,1.73-2.37,1.73-4.61S960.51,309.05,959.15,309.05Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M972.36,318.63v-.46c.89-.1,1-.16,1-1.26v-2.69c0-1.17-.42-1.8-1.33-1.8a2.74,2.74,0,0,0-1.67.77,5.62,5.62,0,0,1,0,.68V317c0,1,.13,1.07,1,1.17v.46h-3.46v-.46c.94-.1,1.07-.16,1.07-1.19v-2.79c0-1.17-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.66.77V317c0,1,.11,1.09,1,1.19v.46h-3.48v-.46c1-.1,1.11-.17,1.11-1.21v-3.32c0-1,0-1.08-.92-1.23V312a9.65,9.65,0,0,0,2.33-.64v1.28a12.32,12.32,0,0,1,1.15-.8,2.17,2.17,0,0,1,1.28-.43,1.87,1.87,0,0,1,1.75,1.29c.44-.34.84-.6,1.24-.87a2.37,2.37,0,0,1,1.26-.42c1.28,0,2,.9,2,2.48V317c0,1,.09,1.08,1,1.19v.46Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M983.48,316.41c-.22.75-.5,1.62-.66,2.22h-6.11v-.39c.87-.86,1.83-1.8,2.62-2.72a6.3,6.3,0,0,0,1.93-3.82c0-1.3-.68-2.06-1.84-2.06a2.79,2.79,0,0,0-2.2,1.48l-.39-.35.94-1.34a2.94,2.94,0,0,1,2.23-1,2.65,2.65,0,0,1,2.77,2.78c0,1.35-.5,2.3-2,3.84-.68.69-1.66,1.65-2.33,2.27h3c.89,0,1.12-.07,1.67-1.08Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M985,306.89a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.89,9.89,0,0,0,2.63-7,10.05,10.05,0,0,0-2.63-7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M141.16,98.56a9.28,9.28,0,0,1,0-14.83l.34.41a9.9,9.9,0,0,0-2.62,7,10.1,10.1,0,0,0,2.62,7Z"
            style={{ fill: "#136736" }}
          />
          <path
            d="M143,87.25l.76-1a2.84,2.84,0,0,1,2.11-.93,2.21,2.21,0,0,1,2.45,2.15,1.72,1.72,0,0,1-.5,1.18,6.71,6.71,0,0,1-1.15.86A2.66,2.66,0,0,1,149.1,92c0,2.6-3.16,3.71-4.27,3.71a2.7,2.7,0,0,1-1.69-.55.72.72,0,0,1-.26-.56,1,1,0,0,1,.37-.64.35.35,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2,3.85,3.85,0,0,0-1,.16l-.11-.49c1.43-.48,2.53-1.1,2.53-2.26a1.39,1.39,0,0,0-1.51-1.49,2.54,2.54,0,0,0-2,1.26Z"
            style={{ fill: "#136736" }}
          />
          <path
            d="M157.43,85.71c-1.54,3.24-3.05,6.48-4.58,9.78l-1.23.13-.12-.18a92.1,92.1,0,0,0,4.7-8.66h-3.41c-1,0-1.12.14-1.56,1.18h-.52c.17-1,.25-1.77.33-2.46h6.29Z"
            style={{ fill: "#136736" }}
          />
          <path
            d="M164.44,85.5l.09.13-.43,1.15h-3.74c-.14.85-.35,2.06-.5,2.82a7.89,7.89,0,0,1,1.94-.47,2.8,2.8,0,0,1,3,2.72,3.37,3.37,0,0,1-1.42,2.7,5,5,0,0,1-2.87,1.11,2.46,2.46,0,0,1-1.67-.61c-.24-.21-.3-.35-.28-.56a.89.89,0,0,1,.37-.6.37.37,0,0,1,.48,0,2.87,2.87,0,0,0,2,.9c1.28,0,1.89-1.1,1.89-2.29a2.3,2.3,0,0,0-2.59-2.42,4.88,4.88,0,0,0-1.61.29l0-.07c.25-1.29.61-3.51.79-4.82Z"
            style={{ fill: "#136736" }}
          />
          <path
            d="M175,95.47V95c.89-.09,1-.16,1-1.25V91.06c0-1.17-.43-1.81-1.33-1.81A2.8,2.8,0,0,0,173,90a6,6,0,0,1,.05.69v3.13c0,1,.12,1.07,1,1.16v.47h-3.46V95c.94-.09,1.07-.15,1.07-1.19V91c0-1.18-.38-1.78-1.29-1.78a2.84,2.84,0,0,0-1.66.77v3.79c0,1,.11,1.1,1,1.19v.47h-3.48V95c1-.1,1.11-.16,1.11-1.2V90.47c0-1-.06-1.08-.93-1.23v-.42a10.17,10.17,0,0,0,2.34-.63v1.28a9.23,9.23,0,0,1,1.14-.8,2.22,2.22,0,0,1,1.28-.44,1.88,1.88,0,0,1,1.76,1.3c.43-.34.83-.6,1.23-.88a2.5,2.5,0,0,1,1.27-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19v.47Z"
            style={{ fill: "#136736" }}
          />
          <path
            d="M186.06,93.25c-.21.74-.49,1.61-.65,2.22H179.3v-.39c.87-.86,1.83-1.81,2.62-2.72a6.28,6.28,0,0,0,1.92-3.83c0-1.29-.67-2-1.83-2a2.79,2.79,0,0,0-2.2,1.47l-.39-.34.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.33,2.27h3c.88,0,1.12-.08,1.67-1.09Z"
            style={{ fill: "#136736" }}
          />
          <path
            d="M187.59,83.73a9.3,9.3,0,0,1,0,14.83l-.36-.41a9.85,9.85,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
            style={{ fill: "#136736" }}
          />
          <path
            d="M211.88,96.53a9.31,9.31,0,0,1,0-14.84l.35.42a9.82,9.82,0,0,0-2.63,7,10.1,10.1,0,0,0,2.63,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M213.75,85.22l.77-1a2.84,2.84,0,0,1,2.11-.93,2.21,2.21,0,0,1,2.45,2.15,1.7,1.7,0,0,1-.5,1.17,7.4,7.4,0,0,1-1.16.87,2.63,2.63,0,0,1,2.4,2.46c0,2.6-3.15,3.71-4.27,3.71a2.6,2.6,0,0,1-1.68-.56.73.73,0,0,1-.27-.55,1,1,0,0,1,.38-.64.33.33,0,0,1,.46,0,3,3,0,0,0,2,.88c1.1,0,1.88-.81,1.88-2.35a2,2,0,0,0-2.1-2.06,4.28,4.28,0,0,0-1,.16l-.12-.49c1.44-.47,2.53-1.09,2.53-2.26a1.38,1.38,0,0,0-1.5-1.48,2.53,2.53,0,0,0-2,1.25Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M224.64,83.27c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.69-3.39-5.17S222.17,83.27,224.64,83.27Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57S223.3,93,224.65,93s1.73-2.37,1.73-4.61S226,83.85,224.64,83.85Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M232.47,83.27c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.4-2.69-3.4-5.17S230,83.27,232.47,83.27Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.61S233.82,83.85,232.46,83.85Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M245.68,93.43V93c.89-.1,1-.16,1-1.26V89c0-1.17-.43-1.8-1.33-1.8a2.72,2.72,0,0,0-1.67.77c0,.19,0,.39,0,.69V91.8c0,1,.13,1.07,1,1.17v.46h-3.46V93c.94-.1,1.07-.16,1.07-1.19V89c0-1.18-.38-1.78-1.29-1.78a2.94,2.94,0,0,0-1.67.76v3.8c0,1,.11,1.09,1,1.19v.46h-3.49V93c1-.1,1.12-.17,1.12-1.2V88.44c0-1-.06-1.08-.93-1.23v-.42a9.52,9.52,0,0,0,2.33-.64v1.28a13.82,13.82,0,0,1,1.15-.8,2.26,2.26,0,0,1,1.28-.43,1.86,1.86,0,0,1,1.75,1.29c.44-.34.84-.6,1.24-.87a2.46,2.46,0,0,1,1.27-.42c1.27,0,2,.9,2,2.49v3.09c0,1,.1,1.09,1,1.19v.46Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M256.79,91.21c-.22.75-.49,1.62-.65,2.22H250V93c.88-.86,1.83-1.8,2.62-2.72a6.22,6.22,0,0,0,1.93-3.82c0-1.29-.68-2.06-1.83-2.06a2.76,2.76,0,0,0-2.2,1.48l-.39-.35.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.83-.68.7-1.67,1.65-2.34,2.27h3c.89,0,1.12-.07,1.68-1.08Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M258.32,81.69a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.89,9.89,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M275,97.07a9.3,9.3,0,0,1,0-14.83l.35.41a9.87,9.87,0,0,0-2.63,7,10.1,10.1,0,0,0,2.63,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M276.86,85.76l.77-1a2.81,2.81,0,0,1,2.11-.93A2.21,2.21,0,0,1,282.19,86a1.72,1.72,0,0,1-.5,1.18,7.4,7.4,0,0,1-1.16.87,2.63,2.63,0,0,1,2.4,2.45c0,2.6-3.15,3.71-4.27,3.71a2.66,2.66,0,0,1-1.68-.55.73.73,0,0,1-.27-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.88-.82,1.88-2.36a2,2,0,0,0-2.1-2,3.84,3.84,0,0,0-1,.16l-.12-.49c1.44-.47,2.53-1.09,2.53-2.26a1.39,1.39,0,0,0-1.5-1.49,2.54,2.54,0,0,0-2,1.26Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M287.75,83.82c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.4-2.68-3.4-5.17S285.28,83.82,287.75,83.82Zm0,.58c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S289.1,84.4,287.75,84.4Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M295.58,83.82c2.46,0,3.35,2.72,3.35,5.17s-.89,5.18-3.35,5.18-3.4-2.68-3.4-5.17S293.1,83.82,295.58,83.82Zm0,.58c-1.41,0-1.76,2.34-1.76,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S296.93,84.4,295.57,84.4Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M308.79,94v-.47c.89-.09,1-.16,1-1.25V89.57c0-1.17-.43-1.8-1.33-1.8a2.72,2.72,0,0,0-1.67.77c0,.19,0,.39,0,.68v3.13c0,1,.13,1.07,1,1.16V94h-3.45v-.47c.94-.09,1.07-.15,1.07-1.19V89.54c0-1.17-.39-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19V94H300v-.47c1-.09,1.12-.16,1.12-1.2V89c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63V88a11.78,11.78,0,0,1,1.15-.8,2.19,2.19,0,0,1,1.28-.43A1.86,1.86,0,0,1,306.65,88c.44-.34.84-.6,1.24-.87a2.38,2.38,0,0,1,1.27-.42c1.27,0,2,.9,2,2.48v3.09c0,1,.1,1.09,1,1.19V94Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M319.9,91.76c-.22.75-.49,1.61-.65,2.22h-6.12v-.39c.88-.86,1.83-1.81,2.62-2.72a6.3,6.3,0,0,0,1.93-3.82c0-1.3-.68-2.06-1.83-2.06a2.79,2.79,0,0,0-2.21,1.48l-.38-.35.93-1.34a3,3,0,0,1,2.24-1,2.64,2.64,0,0,1,2.76,2.78c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.34,2.27h3c.89,0,1.12-.08,1.68-1.08Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M321.43,82.24a9.3,9.3,0,0,1,0,14.83l-.36-.41a9.89,9.89,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M338.21,96.85a9.3,9.3,0,0,1,0-14.83l.34.42a9.86,9.86,0,0,0-2.62,7,10.14,10.14,0,0,0,2.62,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M340.08,85.54l.76-1A2.81,2.81,0,0,1,343,83.6a2.22,2.22,0,0,1,2.46,2.14,1.69,1.69,0,0,1-.51,1.18,6.82,6.82,0,0,1-1.15.87,2.63,2.63,0,0,1,2.4,2.46c0,2.59-3.15,3.7-4.27,3.7a2.66,2.66,0,0,1-1.69-.55.72.72,0,0,1-.26-.55.93.93,0,0,1,.38-.64.33.33,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2,4.29,4.29,0,0,0-1,.16l-.11-.49c1.43-.47,2.53-1.09,2.53-2.26a1.38,1.38,0,0,0-1.51-1.48,2.56,2.56,0,0,0-2,1.25Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M351,83.6c2.46,0,3.35,2.72,3.35,5.17S353.43,94,351,94s-3.4-2.68-3.4-5.17S348.49,83.6,351,83.6Zm0,.58c-1.41,0-1.76,2.34-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.61S352.32,84.18,351,84.18Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M358.8,83.6c2.45,0,3.35,2.72,3.35,5.17S361.25,94,358.8,94s-3.4-2.68-3.4-5.17S356.32,83.6,358.8,83.6Zm0,.58c-1.42,0-1.76,2.34-1.76,4.57s.42,4.62,1.77,4.62,1.74-2.37,1.74-4.61S360.15,84.18,358.79,84.18Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M372,93.76V93.3c.89-.1,1-.16,1-1.26V89.35c0-1.17-.42-1.8-1.33-1.8a2.74,2.74,0,0,0-1.67.77,5.62,5.62,0,0,1,.05.68v3.13c0,1,.13,1.07,1,1.17v.46h-3.46V93.3c.94-.1,1.07-.16,1.07-1.2V89.32c0-1.17-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.66.77V92.1c0,1,.11,1.1,1,1.2v.46h-3.48V93.3c1-.1,1.11-.17,1.11-1.21V88.77c0-1,0-1.08-.92-1.24v-.42a9.61,9.61,0,0,0,2.33-.63v1.28a12.32,12.32,0,0,1,1.15-.8,2.17,2.17,0,0,1,1.28-.43,1.87,1.87,0,0,1,1.75,1.29c.44-.34.84-.6,1.24-.87a2.37,2.37,0,0,1,1.26-.42c1.28,0,2,.9,2,2.48V92.1c0,1,.09,1.09,1,1.2v.46Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M383.12,91.54c-.22.75-.5,1.61-.66,2.22h-6.11v-.39c.87-.86,1.83-1.8,2.62-2.72a6.3,6.3,0,0,0,1.93-3.82c0-1.3-.68-2.06-1.84-2.06a2.79,2.79,0,0,0-2.2,1.48l-.39-.35.94-1.34a2.94,2.94,0,0,1,2.23-1,2.65,2.65,0,0,1,2.77,2.78c0,1.34-.5,2.3-2,3.84-.67.69-1.66,1.64-2.33,2.27h3c.89,0,1.12-.08,1.67-1.08Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M384.64,82a9.28,9.28,0,0,1,0,14.83l-.35-.41a9.89,9.89,0,0,0,2.63-7,10.05,10.05,0,0,0-2.63-7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M401.54,97.18a9.28,9.28,0,0,1,0-14.83l.34.41a9.91,9.91,0,0,0-2.63,7,10.1,10.1,0,0,0,2.63,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M403.41,85.87l.76-1a2.84,2.84,0,0,1,2.11-.92,2.21,2.21,0,0,1,2.45,2.14,1.72,1.72,0,0,1-.5,1.18,6.45,6.45,0,0,1-1.16.86c1.52.33,2.41,1.29,2.41,2.46,0,2.6-3.16,3.71-4.27,3.71a2.67,2.67,0,0,1-1.69-.55.73.73,0,0,1-.27-.55,1,1,0,0,1,.38-.64.34.34,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2,3.85,3.85,0,0,0-1,.16l-.11-.49c1.43-.47,2.52-1.09,2.52-2.26a1.38,1.38,0,0,0-1.5-1.49,2.57,2.57,0,0,0-2,1.26Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M414.29,83.93c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.39-2.68-3.39-5.17S411.82,83.93,414.29,83.93Zm0,.58c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63S416,91.33,416,89.09,415.65,84.51,414.29,84.51Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M422.12,83.93c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.39-2.68-3.39-5.17S419.65,83.93,422.12,83.93Zm0,.58c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S423.48,84.51,422.12,84.51Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M435.33,94.09v-.47c.89-.09,1-.16,1-1.25V89.68c0-1.17-.43-1.81-1.33-1.81a2.74,2.74,0,0,0-1.67.78,5.62,5.62,0,0,1,.05.68v3.13c0,1,.12,1.07,1,1.16v.47H430.9v-.47c.94-.09,1.07-.15,1.07-1.19V89.65c0-1.17-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77v3.79c0,1,.11,1.1,1,1.19v.47H426.5v-.47c1-.1,1.11-.16,1.11-1.2V89.09c0-1-.06-1.08-.93-1.23v-.42a10,10,0,0,0,2.33-.63v1.28a10.26,10.26,0,0,1,1.15-.8,2.21,2.21,0,0,1,1.28-.43,1.88,1.88,0,0,1,1.76,1.29c.43-.34.83-.6,1.23-.88a2.46,2.46,0,0,1,1.27-.41c1.27,0,2,.9,2,2.48v3.09c0,1,.09,1.09,1,1.19v.47Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M446.44,91.87c-.21.74-.49,1.61-.65,2.22h-6.11V93.7c.87-.86,1.82-1.81,2.61-2.72a6.24,6.24,0,0,0,1.93-3.82c0-1.3-.68-2.06-1.83-2.06a2.76,2.76,0,0,0-2.2,1.48l-.39-.35.93-1.34a3,3,0,0,1,2.24-1,2.64,2.64,0,0,1,2.76,2.78c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.33,2.27h3c.88,0,1.11-.08,1.67-1.09Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M448,82.35a9.3,9.3,0,0,1,0,14.83l-.36-.41a9.89,9.89,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M465,96.75a9.31,9.31,0,0,1,0-14.84l.35.42a9.82,9.82,0,0,0-2.63,7,10.1,10.1,0,0,0,2.63,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M466.84,85.43l.77-1a2.81,2.81,0,0,1,2.11-.93,2.21,2.21,0,0,1,2.45,2.14,1.72,1.72,0,0,1-.5,1.18,7.4,7.4,0,0,1-1.16.87,2.63,2.63,0,0,1,2.4,2.46c0,2.59-3.15,3.7-4.27,3.7a2.61,2.61,0,0,1-1.68-.55.73.73,0,0,1-.27-.55,1,1,0,0,1,.38-.64.33.33,0,0,1,.46,0,3,3,0,0,0,2,.88c1.1,0,1.88-.81,1.88-2.36a2,2,0,0,0-2.1-2,4.28,4.28,0,0,0-1,.16l-.12-.49c1.44-.47,2.53-1.09,2.53-2.26a1.38,1.38,0,0,0-1.5-1.48,2.53,2.53,0,0,0-2,1.25Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M477.73,83.49c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.4-2.68-3.4-5.17S475.26,83.49,477.73,83.49Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.61S479.08,84.07,477.73,84.07Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M485.56,83.49c2.46,0,3.36,2.72,3.36,5.17s-.9,5.18-3.36,5.18-3.4-2.68-3.4-5.17S483.08,83.49,485.56,83.49Zm0,.58c-1.41,0-1.76,2.35-1.76,4.57s.43,4.62,1.77,4.62,1.74-2.37,1.74-4.61S486.91,84.07,485.55,84.07Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M498.77,93.65v-.46c.89-.1,1-.16,1-1.26V89.24c0-1.17-.43-1.8-1.33-1.8a2.72,2.72,0,0,0-1.67.77c0,.19,0,.39,0,.68V92c0,1,.13,1.07,1,1.17v.46h-3.45v-.46c.94-.1,1.07-.16,1.07-1.19V89.21c0-1.17-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.67.77V92c0,1,.11,1.09,1,1.19v.46h-3.49v-.46c1-.1,1.12-.17,1.12-1.21V88.66c0-1-.06-1.08-.93-1.23V87a9.52,9.52,0,0,0,2.33-.64v1.28a13.82,13.82,0,0,1,1.15-.8,2.19,2.19,0,0,1,1.28-.43,1.86,1.86,0,0,1,1.75,1.29c.44-.34.84-.6,1.24-.87a2.38,2.38,0,0,1,1.27-.42c1.27,0,2,.9,2,2.48V92c0,1,.1,1.08,1,1.19v.46Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M509.88,91.43c-.22.75-.49,1.62-.65,2.22h-6.12v-.39c.88-.86,1.83-1.8,2.62-2.72a6.24,6.24,0,0,0,1.93-3.82c0-1.3-.68-2.06-1.83-2.06a2.76,2.76,0,0,0-2.2,1.48l-.39-.35.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.78c0,1.34-.49,2.3-2,3.84-.68.69-1.67,1.65-2.34,2.27h3c.89,0,1.12-.07,1.68-1.08Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M511.41,81.91a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.89,9.89,0,0,0,2.64-7,10.06,10.06,0,0,0-2.64-7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M527.32,96.54a9.29,9.29,0,0,1,0-14.84l.34.42a9.82,9.82,0,0,0-2.63,7,10.1,10.1,0,0,0,2.63,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M529.19,85.23l.76-1a2.84,2.84,0,0,1,2.11-.93,2.21,2.21,0,0,1,2.45,2.15,1.7,1.7,0,0,1-.5,1.17,7,7,0,0,1-1.16.87,2.63,2.63,0,0,1,2.4,2.46c0,2.59-3.15,3.71-4.27,3.71a2.6,2.6,0,0,1-1.68-.56.73.73,0,0,1-.27-.55,1,1,0,0,1,.38-.64.33.33,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.35a2,2,0,0,0-2.09-2.06,4.29,4.29,0,0,0-1,.16l-.11-.49c1.43-.47,2.52-1.09,2.52-2.26a1.38,1.38,0,0,0-1.5-1.48,2.54,2.54,0,0,0-2,1.25Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M540.07,83.28c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.69-3.39-5.17S537.6,83.28,540.07,83.28Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.61S541.43,83.86,540.07,83.86Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M547.9,83.28c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.4-2.69-3.4-5.17S545.43,83.28,547.9,83.28Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.61S549.25,83.86,547.9,83.86Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M561.11,93.44V93c.89-.1,1-.16,1-1.26V89c0-1.17-.43-1.8-1.33-1.8a2.72,2.72,0,0,0-1.67.77,5.65,5.65,0,0,1,0,.69v3.12c0,1,.12,1.07,1,1.17v.46h-3.46V93c.94-.1,1.07-.16,1.07-1.19V89c0-1.18-.38-1.78-1.29-1.78a2.88,2.88,0,0,0-1.67.76v3.8c0,1,.11,1.09,1,1.19v.46h-3.48V93c1-.1,1.11-.17,1.11-1.21V88.45c0-1-.06-1.08-.93-1.23V86.8a9.52,9.52,0,0,0,2.33-.64v1.28a13.82,13.82,0,0,1,1.15-.8,2.29,2.29,0,0,1,1.28-.43A1.88,1.88,0,0,1,559,87.5c.43-.34.83-.6,1.23-.87a2.38,2.38,0,0,1,1.27-.42c1.27,0,2,.9,2,2.48v3.1c0,1,.09,1.09,1,1.19v.46Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M572.22,91.22c-.22.75-.49,1.62-.65,2.22h-6.11v-.39c.87-.86,1.82-1.8,2.61-2.72A6.22,6.22,0,0,0,570,86.51c0-1.29-.68-2.06-1.83-2.06a2.76,2.76,0,0,0-2.2,1.48l-.39-.35.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.78c0,1.35-.49,2.3-2,3.84-.68.7-1.67,1.65-2.34,2.27h3c.88,0,1.11-.07,1.67-1.08Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M573.75,81.7a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.89,9.89,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M591.74,97.07a9.29,9.29,0,0,1,0-14.84l.34.42a9.9,9.9,0,0,0-2.62,7,10.1,10.1,0,0,0,2.62,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M593.61,85.76l.76-1a2.84,2.84,0,0,1,2.11-.93A2.22,2.22,0,0,1,598.94,86a1.69,1.69,0,0,1-.51,1.18,6.34,6.34,0,0,1-1.15.86,2.66,2.66,0,0,1,2.4,2.46c0,2.6-3.16,3.71-4.27,3.71a2.7,2.7,0,0,1-1.69-.55.74.74,0,0,1-.26-.56,1,1,0,0,1,.38-.64.35.35,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2,3.85,3.85,0,0,0-1,.16l-.11-.49c1.43-.48,2.53-1.1,2.53-2.26A1.39,1.39,0,0,0,596,84.84a2.54,2.54,0,0,0-2,1.26Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M604.5,83.81c2.46,0,3.35,2.72,3.35,5.17s-.89,5.19-3.35,5.19-3.4-2.68-3.4-5.17S602,83.81,604.5,83.81Zm0,.59c-1.41,0-1.76,2.34-1.76,4.56s.43,4.63,1.77,4.63,1.74-2.37,1.74-4.61S605.85,84.4,604.49,84.4Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M612.33,83.81c2.45,0,3.35,2.72,3.35,5.17s-.9,5.19-3.35,5.19-3.4-2.68-3.4-5.17S609.85,83.81,612.33,83.81Zm0,.59c-1.42,0-1.76,2.34-1.76,4.56s.42,4.63,1.77,4.63,1.73-2.37,1.73-4.61S613.68,84.4,612.32,84.4Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M625.53,94v-.47c.89-.09,1-.16,1-1.25V89.57c0-1.17-.42-1.81-1.33-1.81a2.8,2.8,0,0,0-1.67.77,6,6,0,0,1,.05.69v3.13c0,1,.13,1.07,1,1.16V94H621.1v-.47c.94-.09,1.07-.16,1.07-1.19V89.54c0-1.18-.38-1.78-1.29-1.78a2.89,2.89,0,0,0-1.66.77v3.79c0,1,.11,1.1,1,1.19V94H616.7v-.47c1-.1,1.11-.16,1.11-1.2V89c0-1,0-1.08-.92-1.23v-.42a10.09,10.09,0,0,0,2.33-.63V88a9.41,9.41,0,0,1,1.15-.8,2.18,2.18,0,0,1,1.28-.44A1.88,1.88,0,0,1,623.4,88c.44-.34.84-.6,1.24-.88a2.46,2.46,0,0,1,1.26-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19V94Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M636.65,91.76c-.22.74-.5,1.61-.66,2.22h-6.11v-.39c.87-.86,1.83-1.81,2.62-2.72A6.3,6.3,0,0,0,634.43,87c0-1.29-.68-2.05-1.84-2.05a2.81,2.81,0,0,0-2.2,1.47l-.39-.34.94-1.34a3,3,0,0,1,2.23-1,2.66,2.66,0,0,1,2.77,2.79c0,1.34-.5,2.29-2,3.84-.68.69-1.66,1.64-2.33,2.27h3c.89,0,1.12-.08,1.67-1.09Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M638.17,82.23a9.29,9.29,0,0,1,0,14.84l-.35-.42a9.84,9.84,0,0,0,2.63-7,10.05,10.05,0,0,0-2.63-7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M654.74,97.07a9.29,9.29,0,0,1,0-14.84l.34.42a9.9,9.9,0,0,0-2.62,7,10.1,10.1,0,0,0,2.62,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M656.61,85.76l.76-1a2.84,2.84,0,0,1,2.11-.93A2.21,2.21,0,0,1,661.93,86a1.72,1.72,0,0,1-.5,1.18,6.71,6.71,0,0,1-1.15.86,2.66,2.66,0,0,1,2.4,2.46c0,2.6-3.16,3.71-4.27,3.71a2.7,2.7,0,0,1-1.69-.55.74.74,0,0,1-.26-.56,1,1,0,0,1,.37-.64.35.35,0,0,1,.46,0,3,3,0,0,0,2,.89c1.09,0,1.87-.82,1.87-2.36a2,2,0,0,0-2.09-2,3.85,3.85,0,0,0-1,.16l-.11-.49c1.43-.48,2.53-1.1,2.53-2.26A1.39,1.39,0,0,0,659,84.84a2.54,2.54,0,0,0-2,1.26Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M667.5,83.81c2.45,0,3.35,2.72,3.35,5.17s-.9,5.19-3.35,5.19-3.4-2.68-3.4-5.17S665,83.81,667.5,83.81Zm0,.59c-1.42,0-1.76,2.34-1.76,4.56s.42,4.63,1.77,4.63,1.73-2.37,1.73-4.61S668.85,84.4,667.49,84.4Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M675.32,83.81c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.68-3.39-5.17S672.85,83.81,675.32,83.81Zm0,.59c-1.42,0-1.77,2.34-1.77,4.56s.43,4.63,1.78,4.63,1.73-2.37,1.73-4.61S676.68,84.4,675.32,84.4Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M688.53,94v-.47c.89-.09,1-.16,1-1.25V89.57c0-1.17-.43-1.81-1.33-1.81a2.8,2.8,0,0,0-1.67.77,6,6,0,0,1,.05.69v3.13c0,1,.12,1.07,1,1.16V94H684.1v-.47c.94-.09,1.07-.16,1.07-1.19V89.54c0-1.18-.38-1.78-1.29-1.78a2.84,2.84,0,0,0-1.66.77v3.79c0,1,.11,1.1,1,1.19V94H679.7v-.47c1-.1,1.11-.16,1.11-1.2V89c0-1-.06-1.08-.93-1.23v-.42a10.17,10.17,0,0,0,2.34-.63V88a9.23,9.23,0,0,1,1.14-.8,2.22,2.22,0,0,1,1.28-.44A1.88,1.88,0,0,1,686.4,88c.43-.34.83-.6,1.23-.88a2.5,2.5,0,0,1,1.27-.42c1.28,0,2,.91,2,2.49v3.09c0,1,.09,1.09,1,1.19V94Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M699.64,91.76c-.21.74-.49,1.61-.65,2.22h-6.11v-.39c.87-.86,1.83-1.81,2.62-2.72A6.28,6.28,0,0,0,697.42,87c0-1.29-.67-2.05-1.83-2.05a2.79,2.79,0,0,0-2.2,1.47l-.39-.34.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.79c0,1.34-.49,2.29-2,3.84-.68.69-1.67,1.64-2.33,2.27h3c.88,0,1.12-.08,1.67-1.09Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M701.17,82.23a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.8,9.8,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M718.07,98.13a9.29,9.29,0,0,1,0-14.84l.34.42a9.82,9.82,0,0,0-2.63,7,10.1,10.1,0,0,0,2.63,7Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M719.94,86.81l.76-1a2.84,2.84,0,0,1,2.11-.93A2.21,2.21,0,0,1,725.26,87a1.7,1.7,0,0,1-.5,1.17,7,7,0,0,1-1.16.87,2.63,2.63,0,0,1,2.4,2.46c0,2.59-3.15,3.71-4.27,3.71a2.6,2.6,0,0,1-1.68-.56.73.73,0,0,1-.27-.55,1,1,0,0,1,.38-.64.33.33,0,0,1,.46,0,3,3,0,0,0,2,.88c1.09,0,1.87-.81,1.87-2.36a2,2,0,0,0-2.09-2,4.29,4.29,0,0,0-1,.16l-.11-.49c1.43-.47,2.52-1.09,2.52-2.26a1.38,1.38,0,0,0-1.5-1.48,2.54,2.54,0,0,0-2,1.25Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M730.82,84.87c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.39-2.69-3.39-5.17S728.35,84.87,730.82,84.87Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.61S732.18,85.45,730.82,85.45Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M738.65,84.87c2.46,0,3.36,2.72,3.36,5.17s-.9,5.19-3.36,5.19-3.4-2.69-3.4-5.17S736.18,84.87,738.65,84.87Zm0,.58c-1.42,0-1.77,2.35-1.77,4.57s.43,4.62,1.78,4.62,1.73-2.37,1.73-4.61S740,85.45,738.65,85.45Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M751.86,95v-.46c.89-.1,1-.16,1-1.26V90.62c0-1.17-.43-1.8-1.33-1.8a2.72,2.72,0,0,0-1.67.77,5.62,5.62,0,0,1,0,.68V93.4c0,1,.12,1.07,1,1.17V95h-3.46v-.46c.94-.1,1.07-.16,1.07-1.19V90.6c0-1.18-.38-1.79-1.29-1.79a2.89,2.89,0,0,0-1.67.77v3.8c0,1,.11,1.09,1,1.19V95H743v-.46c1-.1,1.11-.17,1.11-1.21V90c0-1-.06-1.08-.93-1.23v-.42a9.52,9.52,0,0,0,2.33-.64V89a13.82,13.82,0,0,1,1.15-.8A2.29,2.29,0,0,1,748,87.8a1.88,1.88,0,0,1,1.76,1.29c.43-.34.83-.6,1.23-.87a2.38,2.38,0,0,1,1.27-.42c1.27,0,2,.9,2,2.48v3.1c0,1,.09,1.09,1,1.19V95Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M763,92.81c-.22.75-.49,1.62-.65,2.22h-6.11v-.39c.87-.86,1.82-1.8,2.61-2.72a6.22,6.22,0,0,0,1.93-3.82c0-1.29-.68-2.06-1.83-2.06a2.76,2.76,0,0,0-2.2,1.48l-.39-.35.93-1.34a3,3,0,0,1,2.24-1,2.65,2.65,0,0,1,2.76,2.78c0,1.35-.49,2.3-2,3.84-.68.7-1.67,1.65-2.34,2.27h3c.88,0,1.11-.07,1.67-1.08Z"
            style={{ fill: "#626466" }}
          />
          <path
            d="M764.5,83.29a9.31,9.31,0,0,1,0,14.84l-.36-.42a9.89,9.89,0,0,0,2.64-7,10,10,0,0,0-2.64-7Z"
            style={{ fill: "#626466" }}
          />
          <g id="_1-4" data-name="1">
            <path d="M146,55.21h5v.63c-1.42.15-1.67.29-1.67,2.15v3.62c.38,0,.8-.39,1.35-1,1.07-1.17,2.22-2.55,3-3.6.53-.7.47-1-.33-1.11l-.48-.06v-.63h4.52v.63a3.76,3.76,0,0,0-3.09,1.75c-.58.65-1.92,2.16-3,3.44,1.35,1.89,3.28,4.52,4.32,5.84.89,1.13,1.13,1.3,2.23,1.45V69h-3.07c-1.3-1.73-2.81-3.87-4.42-6.09-.41-.6-.72-.66-1-.63v4c0,1.83.16,2,1.67,2.12V69h-5v-.63c1.49-.15,1.64-.29,1.64-2.12V58c0-1.88-.14-2-1.64-2.15Z" />
            <path d="M160.59,69.23a1.26,1.26,0,0,1-1.18-1.42,1.29,1.29,0,0,1,1.18-1.43,1.31,1.31,0,0,1,1.22,1.43A1.28,1.28,0,0,1,160.59,69.23Z" />
            <path d="M171,66c-.24,1-.56,2.14-.75,2.94h-7v-.52c1-1.14,2.1-2.38,3-3.59,1.2-1.6,2.22-3.24,2.22-5.05s-.78-2.71-2.11-2.71-2,1.09-2.53,2l-.44-.46,1.07-1.77A3.2,3.2,0,0,1,167,55.53c1.76,0,3.17,1.38,3.17,3.68,0,1.77-.57,3-2.34,5.06-.77.92-1.91,2.17-2.67,3h3.43c1,0,1.28-.1,1.92-1.43Z" />
            <path d="M176.12,55.53c2.82,0,3.85,3.59,3.85,6.82s-1,6.85-3.85,6.85-3.9-3.54-3.9-6.83C172.22,59.28,173.28,55.53,176.12,55.53Zm0,.77c-1.62,0-2,3.09-2,6s.49,6.11,2,6.11,2-3.13,2-6.08S177.67,56.3,176.11,56.3Z" />
            <path d="M217.92,55.2h5v.63c-1.41.15-1.66.29-1.66,2.16v3.62a2.76,2.76,0,0,0,1.34-1c1.07-1.18,2.23-2.56,3-3.61.53-.7.46-1-.34-1.11l-.48-.06V55.2h4.53v.63a3.76,3.76,0,0,0-3.09,1.75c-.58.66-1.92,2.16-3,3.44,1.35,1.89,3.28,4.52,4.32,5.84.89,1.14,1.13,1.3,2.23,1.45v.63h-3.07c-1.29-1.73-2.8-3.87-4.41-6.09-.42-.6-.73-.66-1-.63v4c0,1.83.16,2,1.66,2.12v.63h-5v-.63c1.48-.15,1.63-.29,1.63-2.12V58c0-1.88-.13-2-1.63-2.16Z" />
            <path d="M232.54,69.23a1.27,1.27,0,0,1-1.19-1.43,1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.29,1.29,0,0,1,232.54,69.23Z" />
            <path d="M236.21,68.94v-.63c2-.14,2.12-.27,2.12-1.84v-8c0-1.08-.16-1.13-1-1.22l-.84-.1v-.56a14.61,14.61,0,0,0,3.49-1V66.47c0,1.6.11,1.7,2.14,1.84v.63Z" />
            <path d="M249.84,62.87l-1.17.69a2.12,2.12,0,0,1-1.1.33c-2,0-3.35-1.75-3.35-4,0-2,1.38-4.41,3.85-4.41s3.76,2.42,3.76,5.3a7.71,7.71,0,0,1-7,8.44l-.14-.65C247.39,68,249.25,66,249.84,62.87Zm-2-6.58c-1.35,0-1.84,1.46-1.84,3,0,1.85.9,3.39,2.41,3.39a2.44,2.44,0,0,0,1.53-.55,11.72,11.72,0,0,0,.08-1.4C250,58.82,249.54,56.28,247.82,56.29Z" />
            <path d="M281,54.65h5v.63c-1.42.16-1.66.3-1.66,2.16v3.62a2.81,2.81,0,0,0,1.34-1c1.07-1.18,2.22-2.56,3-3.6.53-.71.46-1-.34-1.12l-.48-.06v-.63h4.53v.63A3.78,3.78,0,0,0,289.37,57l-3,3.44c1.35,1.89,3.28,4.52,4.32,5.84.89,1.14,1.13,1.3,2.23,1.45v.63h-3.07c-1.3-1.72-2.81-3.87-4.41-6.09-.42-.6-.73-.65-1-.62v4c0,1.83.16,2,1.66,2.12v.63h-5v-.63c1.49-.15,1.64-.29,1.64-2.12v-8.2c0-1.88-.14-2-1.64-2.16Z" />
            <path d="M295.65,68.68a1.27,1.27,0,0,1-1.19-1.43,1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.29,1.29,0,0,1,295.65,68.68Z" />
            <path d="M299.32,68.39v-.63c2-.14,2.12-.26,2.12-1.84v-8c0-1.08-.16-1.13-1-1.22l-.84-.1V56a14.61,14.61,0,0,0,3.49-1v11c0,1.6.11,1.7,2.14,1.84v.63Z" />
            <path d="M311.31,55a3,3,0,0,1,3.07,3.15,2.75,2.75,0,0,1-.53,1.7,15.62,15.62,0,0,1-1.5,1.31c1.28.8,2.51,1.81,2.51,3.65a3.78,3.78,0,0,1-3.77,3.86,3.38,3.38,0,0,1-3.63-3.5,2.62,2.62,0,0,1,.84-2.06A16.61,16.61,0,0,1,310,61.79a3.92,3.92,0,0,1-2-3.32A3.38,3.38,0,0,1,311.31,55Zm0,12.93c1.2,0,1.93-.9,1.92-2.41s-1.16-2.46-2.71-3.33A3.27,3.27,0,0,0,309.08,65C309.08,66.93,310.13,67.9,311.31,67.9Zm-.24-12.2c-1.07,0-1.62,1-1.62,2.12,0,1.51.9,2.11,2.34,3a3.1,3.1,0,0,0,1.12-2.55C312.91,56.43,312,55.7,311.07,55.7Z" />
            <path d="M344.24,54.87h5v.63c-1.42.16-1.66.3-1.66,2.16v3.62a2.81,2.81,0,0,0,1.34-1c1.07-1.18,2.22-2.56,3-3.6.54-.71.47-1-.33-1.12l-.48-.06v-.63h4.52v.63a3.8,3.8,0,0,0-3.09,1.75l-3,3.44c1.35,1.89,3.28,4.52,4.32,5.84.89,1.14,1.13,1.3,2.23,1.45v.63H353c-1.3-1.72-2.81-3.86-4.41-6.09-.42-.6-.73-.65-1-.62v4c0,1.83.16,2,1.66,2.12v.63h-5V68c1.49-.15,1.64-.29,1.64-2.12v-8.2c0-1.88-.14-2-1.64-2.16Z" />
            <path d="M358.86,68.9a1.45,1.45,0,0,1,0-2.85,1.3,1.3,0,0,1,1.22,1.42A1.28,1.28,0,0,1,358.86,68.9Z" />
            <path d="M362.53,68.61V68c2-.14,2.13-.26,2.13-1.84v-8c0-1.08-.16-1.13-1-1.21l-.84-.11v-.56a14.16,14.16,0,0,0,3.49-1v11c0,1.6.11,1.7,2.14,1.84v.63Z" />
            <path d="M378.42,55.73c-1.77,4.28-3.49,8.55-5.26,12.92l-1.41.16-.13-.24c1.93-3.58,3.92-7.85,5.4-11.43H373.1c-1.21,0-1.29.18-1.79,1.56h-.6c.2-1.28.29-2.34.38-3.26h7.22Z" />
            <path d="M407.57,54.54h5v.63c-1.41.16-1.66.3-1.66,2.16V61a2.82,2.82,0,0,0,1.35-1c1.06-1.18,2.22-2.56,3-3.6.53-.71.47-1-.33-1.12l-.49-.06v-.63H419v.63a3.79,3.79,0,0,0-3.09,1.75l-3,3.44c1.36,1.89,3.29,4.52,4.32,5.84.89,1.14,1.13,1.3,2.24,1.45v.63h-3.07c-1.3-1.72-2.81-3.86-4.42-6.09-.41-.6-.72-.65-1-.62v4c0,1.83.16,2,1.67,2.12v.63h-5v-.63c1.48-.15,1.64-.29,1.64-2.12v-8.2c0-1.88-.14-2-1.64-2.16Z" />
            <path d="M422.19,68.57a1.45,1.45,0,0,1,0-2.85,1.44,1.44,0,0,1,0,2.85Z" />
            <path d="M425.86,68.28v-.63c2-.14,2.13-.26,2.13-1.84v-8c0-1.08-.17-1.13-1-1.22l-.83-.1V55.9a14.43,14.43,0,0,0,3.49-1v11c0,1.6.11,1.7,2.13,1.84v.63Z" />
            <path d="M437.1,60.29a2.41,2.41,0,0,1,1.19-.37c1.95,0,3.31,1.72,3.31,3.92,0,2.56-1.61,4.69-3.86,4.69s-3.89-2-3.89-5.3a8.51,8.51,0,0,1,5.07-7.93,14,14,0,0,1,2.2-.57l.11.73a10.45,10.45,0,0,0-2,.72,6.49,6.49,0,0,0-3.3,4.79Zm.39.89a3.28,3.28,0,0,0-1.68.53,9,9,0,0,0-.11,1.48c0,2.67.85,4.56,2.35,4.56s1.79-1.72,1.79-3.12C439.84,62.83,439.08,61.18,437.49,61.18Z" />
            <path d="M471,55h5v.63c-1.41.16-1.66.3-1.66,2.16v3.62a2.76,2.76,0,0,0,1.34-1c1.07-1.18,2.23-2.56,3-3.6.53-.71.46-1-.34-1.12l-.48-.06V55h4.53v.63a3.79,3.79,0,0,0-3.09,1.75l-3,3.44c1.35,1.89,3.28,4.52,4.32,5.84.89,1.14,1.13,1.3,2.23,1.45v.63H479.8c-1.29-1.72-2.8-3.87-4.41-6.09-.42-.6-.73-.65-1-.62v4c0,1.83.16,2,1.66,2.12v.63h-5v-.63c1.48-.15,1.63-.29,1.63-2.12v-8.2c0-1.88-.14-2-1.63-2.16Z" />
            <path d="M485.63,69a1.27,1.27,0,0,1-1.19-1.43,1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.29,1.29,0,0,1,485.63,69Z" />
            <path d="M489.3,68.72v-.63c2-.14,2.12-.26,2.12-1.84v-8c0-1.08-.16-1.13-1-1.22l-.84-.1v-.56a14.61,14.61,0,0,0,3.49-1v11c0,1.6.11,1.7,2.14,1.84v.63Z" />
            <path d="M504.24,55.55l.11.18-.49,1.52h-4.29c-.17,1.12-.41,2.72-.57,3.73a7.48,7.48,0,0,1,2.21-.63,3.45,3.45,0,0,1,3.49,3.59,4.72,4.72,0,0,1-1.63,3.57A5.37,5.37,0,0,1,499.78,69a2.64,2.64,0,0,1-1.92-.8.78.78,0,0,1-.32-.74,1.22,1.22,0,0,1,.41-.79.38.38,0,0,1,.55,0,3.15,3.15,0,0,0,2.34,1.19c1.47,0,2.16-1.45,2.16-3,0-1.87-1-3.18-3-3.18a5.08,5.08,0,0,0-1.85.37l-.06-.09c.29-1.7.7-4.63.91-6.37Z" />
            <path d="M534.33,54.65h5v.63c-1.41.16-1.66.3-1.66,2.16v3.62a2.76,2.76,0,0,0,1.34-1c1.07-1.18,2.22-2.56,3-3.6.53-.71.46-1-.34-1.12l-.48-.06v-.63h4.53v.63A3.78,3.78,0,0,0,542.68,57l-3,3.44C541,62.36,542.93,65,544,66.31c.89,1.14,1.13,1.3,2.23,1.45v.63h-3.07c-1.3-1.72-2.81-3.87-4.41-6.09-.42-.6-.73-.65-1-.62v4c0,1.83.16,2,1.66,2.12v.63h-5v-.63c1.49-.15,1.64-.29,1.64-2.12v-8.2c0-1.88-.14-2-1.64-2.16Z" />
            <path d="M549,68.68a1.27,1.27,0,0,1-1.19-1.43A1.29,1.29,0,0,1,549,65.82a1.31,1.31,0,0,1,1.22,1.43A1.29,1.29,0,0,1,549,68.68Z" />
            <path d="M552.63,68.39v-.63c2-.14,2.12-.26,2.12-1.84v-8c0-1.08-.16-1.13-1-1.22l-.84-.1V56a14.61,14.61,0,0,0,3.49-1v11c0,1.6.11,1.7,2.14,1.84v.63Z" />
            <path d="M566.78,64.57v1.56c0,1.37.07,1.49,1.4,1.63v.63h-4.46v-.63c1.36-.14,1.44-.29,1.44-1.67V64.57h-4.83V63.9c1.79-2.89,3.74-6,5.52-8.85h.93v8.47h1.78v1Zm-1.62-7.18c-1.11,1.84-2.39,4-3.65,6.13h3.65Z" />
            <path d="M597.77,54.65h5v.63c-1.41.16-1.66.3-1.66,2.16v3.62a2.82,2.82,0,0,0,1.35-1c1.06-1.18,2.22-2.56,3-3.6.53-.71.47-1-.33-1.12l-.49-.06v-.63h4.53v.63A3.79,3.79,0,0,0,606.11,57l-3,3.44c1.36,1.89,3.29,4.52,4.33,5.84.89,1.14,1.12,1.3,2.23,1.45v.63h-3.07c-1.3-1.72-2.81-3.87-4.42-6.09-.41-.6-.72-.65-1-.62v4c0,1.83.16,2,1.67,2.12v.63h-5v-.63c1.48-.15,1.64-.29,1.64-2.12v-8.2c0-1.88-.14-2-1.64-2.16Z" />
            <path d="M612.39,68.68c-.7,0-1.18-.59-1.18-1.43a1.28,1.28,0,0,1,1.18-1.43,1.31,1.31,0,0,1,1.22,1.43A1.29,1.29,0,0,1,612.39,68.68Z" />
            <path d="M616.06,68.39v-.63c2-.14,2.13-.26,2.13-1.84v-8c0-1.08-.17-1.13-1-1.22l-.83-.1V56a14.75,14.75,0,0,0,3.49-1v11c0,1.6.11,1.7,2.13,1.84v.63Z" />
            <path d="M624.41,57.54l.87-1.34A3.09,3.09,0,0,1,627.7,55a2.71,2.71,0,0,1,2.82,2.83,2.41,2.41,0,0,1-.58,1.56,8,8,0,0,1-1.32,1.14,3.32,3.32,0,0,1,2.75,3.25c0,3.43-3.62,4.89-4.9,4.89a2.76,2.76,0,0,1-1.93-.73,1,1,0,0,1-.31-.73,1.31,1.31,0,0,1,.44-.84.35.35,0,0,1,.52,0,3.24,3.24,0,0,0,2.3,1.17c1.25,0,2.15-1.08,2.15-3.11a2.41,2.41,0,0,0-2.4-2.71,4.09,4.09,0,0,0-1.19.21l-.13-.65c1.64-.62,2.9-1.44,2.9-3a1.72,1.72,0,0,0-1.73-2A2.9,2.9,0,0,0,624.81,58Z" />
            <path d="M660.77,54.65h5v.63c-1.41.16-1.66.3-1.66,2.16v3.62a2.82,2.82,0,0,0,1.35-1c1.06-1.18,2.22-2.56,3-3.6.53-.71.47-1-.33-1.12l-.49-.06v-.63h4.53v.63A3.79,3.79,0,0,0,669.11,57l-3,3.44c1.36,1.89,3.29,4.52,4.33,5.84.89,1.14,1.12,1.3,2.23,1.45v.63h-3.07c-1.3-1.72-2.81-3.87-4.42-6.09-.41-.6-.72-.65-1-.62v4c0,1.83.16,2,1.67,2.12v.63h-5v-.63c1.48-.15,1.64-.29,1.64-2.12v-8.2c0-1.88-.14-2-1.64-2.16Z" />
            <path d="M675.39,68.68c-.7,0-1.18-.59-1.18-1.43a1.28,1.28,0,0,1,1.18-1.43,1.31,1.31,0,0,1,1.22,1.43A1.29,1.29,0,0,1,675.39,68.68Z" />
            <path d="M679.06,68.39v-.63c2-.14,2.13-.26,2.13-1.84v-8c0-1.08-.17-1.13-1-1.22l-.83-.1V56a14.75,14.75,0,0,0,3.49-1v11c0,1.6.11,1.7,2.13,1.84v.63Z" />
            <path d="M694.78,65.46c-.25,1-.57,2.13-.75,2.93h-7v-.52c1-1.13,2.1-2.38,3-3.58,1.19-1.61,2.21-3.25,2.21-5.06s-.78-2.71-2.1-2.71-2,1.1-2.53,1.95l-.45-.46,1.07-1.76A3.21,3.21,0,0,1,690.79,55c1.76,0,3.17,1.39,3.17,3.68,0,1.77-.56,3-2.33,5.06-.78.92-1.91,2.18-2.68,3h3.43c1,0,1.29-.1,1.92-1.43Z" />
            <path d="M726.59,55.21h5v.63C730.2,56,730,56.13,730,58v3.62a2.69,2.69,0,0,0,1.35-1c1.06-1.17,2.22-2.55,3-3.6.53-.7.47-1-.33-1.11l-.49-.06v-.63H738v.63a3.76,3.76,0,0,0-3.09,1.75c-.58.65-1.92,2.16-3,3.44,1.36,1.89,3.29,4.52,4.32,5.84.9,1.13,1.13,1.3,2.24,1.45V69h-3.07c-1.3-1.73-2.81-3.87-4.42-6.09-.41-.6-.72-.66-1-.63v4c0,1.83.16,2,1.67,2.12V69h-5v-.63c1.48-.15,1.64-.29,1.64-2.12V58c0-1.88-.14-2-1.64-2.15Z" />
            <path d="M741.21,69.23A1.26,1.26,0,0,1,740,67.81a1.29,1.29,0,0,1,1.19-1.43,1.31,1.31,0,0,1,1.22,1.43A1.28,1.28,0,0,1,741.21,69.23Z" />
            <path d="M744.88,69v-.63c2-.14,2.13-.27,2.13-1.84v-8c0-1.08-.17-1.13-1-1.22l-.83-.1v-.56a14.75,14.75,0,0,0,3.49-1v11c0,1.6.11,1.7,2.13,1.84V69Z" />
            <path d="M753.87,69v-.63c2-.14,2.12-.27,2.12-1.84v-8c0-1.08-.16-1.13-1-1.22l-.84-.1v-.56a14.61,14.61,0,0,0,3.49-1v11c0,1.6.11,1.7,2.14,1.84V69Z" />
          </g>
        </g>
        <path
          d="M82.31,153.59c-.76-3.26-2-6.55-6-6.55-3.2,0-4.45,2.68-4.45,5.3,0,3.3,1.84,5,5.46,7.16,5.07,3,7.44,5.33,7.44,10.07,0,5.33-4,9.35-9.65,9.35a17,17,0,0,1-6.72-1.62c-.33-1.34-.88-5-1.19-7.65l1.4-.41c.87,3.29,2.86,7.8,7.3,7.8,3,0,4.86-2.37,4.86-5.59,0-3.65-1.73-5.21-5.49-7.57-4.53-2.82-7.14-5.21-7.14-9.93,0-4.55,3.25-8.71,9.09-8.71a25,25,0,0,1,5.78.93c.17,1.8.37,3.89.71,7.1Z"
          style={{ fill: "#ed1f24" }}
        />
        <path
          d="M89.15,152.71l2-3.15a7.26,7.26,0,0,1,5.69-2.88,6.36,6.36,0,0,1,6.61,6.65,5.64,5.64,0,0,1-1.36,3.65A18.5,18.5,0,0,1,99,159.66c4.09,1,6.47,4,6.47,7.62,0,8.05-8.5,11.49-11.5,11.49a6.53,6.53,0,0,1-4.54-1.72,2.36,2.36,0,0,1-.72-1.7,3.11,3.11,0,0,1,1-2,.83.83,0,0,1,1.24.06,7.67,7.67,0,0,0,5.4,2.74c2.94,0,5-2.53,5-7.31,0-4.57-2.94-6.35-5.64-6.35A9.33,9.33,0,0,0,93,163l-.3-1.54c3.86-1.45,6.81-3.37,6.81-7,0-3-1.66-4.61-4.06-4.61s-4.16,1.93-5.36,3.9Z"
          style={{ fill: "#ed1f24" }}
        />
        <path
          d="M307.56,624.07c-.75-3.26-2-6.56-6-6.56-3.21,0-4.46,2.69-4.46,5.3,0,3.3,1.84,5,5.46,7.16,5.08,3,7.44,5.33,7.44,10.08,0,5.33-4,9.35-9.65,9.35a17,17,0,0,1-6.71-1.62c-.33-1.35-.89-5-1.19-7.66l1.4-.4c.86,3.29,2.85,7.8,7.29,7.8,3,0,4.86-2.38,4.86-5.59,0-3.65-1.72-5.22-5.49-7.57-4.52-2.82-7.14-5.22-7.14-9.93,0-4.56,3.26-8.72,9.09-8.72a24.7,24.7,0,0,1,5.78.94c.17,1.79.37,3.89.71,7.09Z"
          style={{ fill: "#ed1f24" }}
        />
        <path
          d="M326.82,634.42,324.07,636a5,5,0,0,1-2.58.76c-4.72,0-7.87-4.11-7.87-9.3,0-4.78,3.24-10.35,9.05-10.35,5.64,0,8.82,5.69,8.82,12.44,0,9.1-5,15.82-11,18.47a18.8,18.8,0,0,1-5.35,1.34l-.34-1.52C321.06,646.46,325.45,641.82,326.82,634.42ZM322.08,619c-3.18,0-4.31,3.44-4.31,7,0,4.35,2.09,8,5.64,8a5.73,5.73,0,0,0,3.61-1.3,27.71,27.71,0,0,0,.18-3.29C327.2,624.9,326.11,618.94,322.08,619Z"
          style={{ fill: "#ed1f24" }}
        />
        <path
          d="M146.62,401.57c-.75-3.26-2-6.55-6-6.55-3.21,0-4.46,2.68-4.46,5.3,0,3.29,1.84,5,5.46,7.15,5.08,3,7.45,5.33,7.45,10.08,0,5.33-4,9.35-9.66,9.35a16.86,16.86,0,0,1-6.71-1.62c-.33-1.34-.89-5-1.19-7.65l1.4-.41c.86,3.29,2.85,7.8,7.29,7.8,3,0,4.87-2.37,4.87-5.59,0-3.65-1.73-5.21-5.5-7.57-4.52-2.82-7.14-5.22-7.14-9.93,0-4.55,3.26-8.71,9.09-8.71a24.83,24.83,0,0,1,5.78.93c.17,1.8.37,3.89.72,7.09Z"
          style={{ fill: "#ed1f24" }}
        />
        <path
          d="M155,426.16v-1.47c4.73-.33,5-.63,5-4.33V401.51c0-2.53-.38-2.65-2.26-2.86l-2-.24V397.1a34,34,0,0,0,8.19-2.44v25.7c0,3.75.26,4,5,4.33v1.47Z"
          style={{ fill: "#ed1f24" }}
        />
        <path
          d="M182.8,394.66c6.63,0,9,8.43,9,16,0,7.24-2.41,16.07-9,16.07s-9.15-8.31-9.15-16C173.65,403.47,176.13,394.66,182.8,394.66Zm0,1.8c-3.82,0-4.76,7.27-4.76,14.15s1.16,14.33,4.78,14.33,4.68-7.34,4.68-14.28S186.45,396.46,182.79,396.46Z"
          style={{ fill: "#ed1f24" }}
        />
        <path
          d="M865.37,931.05c-.75-3.26-2-6.55-6-6.55-3.21,0-4.46,2.68-4.46,5.3,0,3.29,1.84,5,5.46,7.15,5.08,3,7.45,5.33,7.45,10.08,0,5.33-4,9.35-9.66,9.35a16.86,16.86,0,0,1-6.71-1.62c-.33-1.34-.89-5-1.19-7.65l1.4-.41c.86,3.29,2.85,7.8,7.29,7.8,3,0,4.87-2.37,4.87-5.59,0-3.65-1.73-5.21-5.5-7.57-4.52-2.82-7.14-5.22-7.14-9.93,0-4.56,3.26-8.71,9.09-8.71a24.83,24.83,0,0,1,5.78.93c.17,1.79.37,3.89.71,7.09Z"
          style={{ fill: "#ed1f24" }}
        />
        <path
          d="M873.72,955.64v-1.47c4.73-.33,5-.63,5-4.33V931c0-2.53-.38-2.65-2.26-2.86l-2-.24v-1.31a34,34,0,0,0,8.19-2.44v25.7c0,3.75.26,4,5,4.33v1.47Z"
          style={{ fill: "#ed1f24" }}
        />
        <text
          transform="matrix(0.22, 0.97, 1.12, -0.26, 122.88, 272.54)"
          style={{
            fontSize: "31.315839767456055px",
            fill: "#afd9aa",
            fontFamily: "MyriadPro-Regular, Myriad Pro",
          }}
        >
          <tspan style={{ letterSpacing: "-0.012972668241270725em" }}>B</tspan>
          <tspan x="16.57" y="0">
            .K3
          </tspan>
        </text>
        <text
          transform="translate(66.62 45.29) rotate(87.22) scale(1 1.15)"
          style={{
            fontSize: "31.315839767456055px",
            fill: "#b0daab",
            fontFamily: "MyriadPro-Regular, Myriad Pro",
          }}
        >
          <tspan style={{ letterSpacing: "-0.012972668241270725em" }}>B</tspan>
          <tspan x="16.57" y="0">
            .KH
          </tspan>
        </text>
        <text
          transform="translate(941.81 969.35) rotate(5.03) scale(1 1.15)"
          style={{
            fontSize: "31.315839767456055px",
            fill: "#b0daab",
            fontFamily: "MyriadPro-Regular, Myriad Pro",
          }}
        >
          <tspan style={{ letterSpacing: "-0.012972668241270725em" }}>B</tspan>
          <tspan x="16.57" y="0">
            .
          </tspan>
          <tspan
            x="23.05"
            y="0"
            style={{ letterSpacing: "0.03801366006276206em" }}
          >
            K
          </tspan>
          <tspan x="41.21" y="0">
            J
          </tspan>
        </text>
        <text
          transform="translate(200.91 526.86) rotate(56.9) scale(1 1.15)"
          style={{
            fontSize: "31.315839767456055px",
            fill: "#afd9aa",
            fontFamily: "MyriadPro-Regular, Myriad Pro",
          }}
        >
          <tspan style={{ letterSpacing: "-0.012972668241270725em" }}>B</tspan>
          <tspan x="16.57" y="0">
            .K2
          </tspan>
        </text>
        <text
          transform="matrix(0.86, 0.51, -0.59, 0.99, 511.85, 834.83)"
          style={{
            fontSize: "31.315839767456055px",
            fill: "#afd9aa",
            fontFamily: "MyriadPro-Regular, Myriad Pro",
          }}
        >
          <tspan style={{ letterSpacing: "-0.012972668241270725em" }}>B</tspan>
          <tspan x="16.57" y="0">
            .K1
          </tspan>
        </text>
      </svg>
    </div>
  );
}
export default KZone;
