import * as React from "react";
import { AppBar, UserMenu, MenuItemLink, Layout } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import moment from 'moment'

const ConfigurationMenu = React.forwardRef(({ onClick }, ref) => (
    <div>
        <p style={{marginLeft: 10, color: '#999', marginRight: 10, fontSize: 12}}>Last Logout: {moment(localStorage.getItem('lastLogout')).format('ddd hh:mm a')}</p>
        <MenuItemLink
            ref={ref}
            to={`/Customers/GetUserAndPass/${1}/show`}
            primaryText="Change Password"
            leftIcon={<SettingsIcon />}
            onClick={onClick} // close the menu on click
        />
    </div>
));

const MyUserMenu = props => (
    <UserMenu {...props}>
        <ConfigurationMenu />
    </UserMenu>
);

const MyAppBar = props => <AppBar {...props} elevation={1} userMenu={<MyUserMenu />}>
    <div style={{flex: 1}}> </div>
    <div style={{order: 1, alignSelf: 'center'}}>{localStorage.getItem('name')}</div>
</AppBar>;

const MyLayout = props => <Layout {...props} appBar={MyAppBar} />;

export default MyLayout